/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Api Documentation
 * Api Documentation
 *
 * OpenAPI spec version: 1.0
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://api.growyze.com".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface Activity
 */
export interface Activity {
    /**
     * 
     * @type {Date}
     * @memberof Activity
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Activity
     */
    id?: string;
    /**
     * 
     * @type {Message}
     * @memberof Activity
     */
    message?: Message;
    /**
     * 
     * @type {Array<string>}
     * @memberof Activity
     */
    organizations?: Array<string>;
}

/**
 * 
 * @export
 * @interface ActivityWithOrgsNames
 */
export interface ActivityWithOrgsNames {
    /**
     * 
     * @type {Date}
     * @memberof ActivityWithOrgsNames
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof ActivityWithOrgsNames
     */
    id?: string;
    /**
     * 
     * @type {Message}
     * @memberof ActivityWithOrgsNames
     */
    message?: Message;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityWithOrgsNames
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ActivityWithOrgsNames
     */
    organizationsNames?: Array<string>;
}

/**
 * 
 * @export
 * @interface AdditionalContacts
 */
export interface AdditionalContacts {
    /**
     * 
     * @type {DeliveriesContacts}
     * @memberof AdditionalContacts
     */
    deliveriesContacts?: DeliveriesContacts;
    /**
     * 
     * @type {InvoicesContacts}
     * @memberof AdditionalContacts
     */
    invoicesContacts?: InvoicesContacts;
    /**
     * 
     * @type {OrdersContacts}
     * @memberof AdditionalContacts
     */
    ordersContacts?: OrdersContacts;
}

/**
 * 
 * @export
 * @interface Address
 */
export interface Address {
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof Address
     */
    postCode?: string;
}

/**
 * 
 * @export
 * @interface Address0
 */
export interface Address0 {
    /**
     * 
     * @type {string}
     * @memberof Address0
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof Address0
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof Address0
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof Address0
     */
    country?: Address0.CountryEnum;
    /**
     * 
     * @type {string}
     * @memberof Address0
     */
    postCode?: string;
}

/**
 * @export
 * @namespace Address0
 */
export namespace Address0 {
    /**
     * @export
     * @enum {string}
     */
    export enum CountryEnum {
        OTHER = <any> 'OTHER',
        UK = <any> 'UK',
        USA = <any> 'USA'
    }
}

/**
 * 
 * @export
 * @interface AddressWithZoneId
 */
export interface AddressWithZoneId {
    /**
     * 
     * @type {string}
     * @memberof AddressWithZoneId
     */
    addressLine1?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressWithZoneId
     */
    addressLine2?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressWithZoneId
     */
    city?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressWithZoneId
     */
    country?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressWithZoneId
     */
    postCode?: string;
    /**
     * 
     * @type {string}
     * @memberof AddressWithZoneId
     */
    zoneId?: string;
}

/**
 * 
 * @export
 * @interface AdminLoginSession
 */
export interface AdminLoginSession {
    /**
     * 
     * @type {Date}
     * @memberof AdminLoginSession
     */
    expiryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof AdminLoginSession
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AdminLoginSession
     */
    orgIds?: Array<string>;
    /**
     * 
     * @type {User}
     * @memberof AdminLoginSession
     */
    user?: User;
}

/**
 * 
 * @export
 * @interface Annotations
 */
export interface Annotations {
    /**
     * 
     * @type {Array<ItemProperty>}
     * @memberof Annotations
     */
    emailReceiver?: Array<ItemProperty>;
    /**
     * 
     * @type {Array<ItemProperty>}
     * @memberof Annotations
     */
    emailSender?: Array<ItemProperty>;
    /**
     * 
     * @type {Array<ItemProperty>}
     * @memberof Annotations
     */
    emailSubject?: Array<ItemProperty>;
    /**
     * 
     * @type {Array<ItemProperty>}
     * @memberof Annotations
     */
    invoiceDate?: Array<ItemProperty>;
    /**
     * 
     * @type {Array<ItemProperty>}
     * @memberof Annotations
     */
    purchaseOrderNumber?: Array<ItemProperty>;
}

/**
 * 
 * @export
 * @interface ApiProps
 */
export interface ApiProps {
    /**
     * 
     * @type {string}
     * @memberof ApiProps
     */
    clientId?: string;
}

/**
 * 
 * @export
 * @interface ApproveOrderDto
 */
export interface ApproveOrderDto {
    /**
     * 
     * @type {string}
     * @memberof ApproveOrderDto
     */
    token?: string;
}

/**
 * 
 * @export
 * @interface Approver
 */
export interface Approver {
    /**
     * 
     * @type {string}
     * @memberof Approver
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Approver
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Approver
     */
    username?: string;
}

/**
 * 
 * @export
 * @interface AssignGroupsPayload
 */
export interface AssignGroupsPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignGroupsPayload
     */
    groupsIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AssignGroupsPayload
     */
    mainOrgId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AssignGroupsPayload
     */
    subOrgsIds?: Array<string>;
}

/**
 * 
 * @export
 * @interface AuthEmailToOrg
 */
export interface AuthEmailToOrg {
    /**
     * 
     * @type {string}
     * @memberof AuthEmailToOrg
     */
    accountNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthEmailToOrg
     */
    emails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof AuthEmailToOrg
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthEmailToOrg
     */
    orgId?: string;
}

/**
 * 
 * @export
 * @interface AuthenticateAdminResponse
 */
export interface AuthenticateAdminResponse {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AuthenticateAdminResponse
     */
    orgIdToOrgName?: { [key: string]: string; };
}

/**
 * 
 * @export
 * @interface AuthenticateUserResponse
 */
export interface AuthenticateUserResponse {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AuthenticateUserResponse
     */
    orgIdToOrgName?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof AuthenticateUserResponse
     */
    sessionId?: string;
}

/**
 * 
 * @export
 * @interface Business
 */
export interface Business {
    /**
     * 
     * @type {Array<BusinessLocation>}
     * @memberof Business
     */
    businessLocations?: Array<BusinessLocation>;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    businessName?: string;
}

/**
 * 
 * @export
 * @interface BusinessLocation
 */
export interface BusinessLocation {
    /**
     * 
     * @type {number}
     * @memberof BusinessLocation
     */
    blID?: number;
    /**
     * 
     * @type {string}
     * @memberof BusinessLocation
     */
    blName?: string;
}

/**
 * 
 * @export
 * @interface COGSReport
 */
export interface COGSReport {
    /**
     * 
     * @type {Array<MainCategorisedProducts>}
     * @memberof COGSReport
     */
    categorisedProducts?: Array<MainCategorisedProducts>;
    /**
     * 
     * @type {string}
     * @memberof COGSReport
     */
    closingStockTakeReportId?: string;
    /**
     * 
     * @type {Date}
     * @memberof COGSReport
     */
    createdAt?: Date;
    /**
     * 
     * @type {FilterCategories}
     * @memberof COGSReport
     */
    filterCategories?: FilterCategories;
    /**
     * 
     * @type {string}
     * @memberof COGSReport
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof COGSReport
     */
    openingStockTakeReportId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof COGSReport
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof COGSReport
     */
    periodFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof COGSReport
     */
    periodTo?: Date;
    /**
     * 
     * @type {Sales}
     * @memberof COGSReport
     */
    sales?: Sales;
    /**
     * 
     * @type {number}
     * @memberof COGSReport
     */
    totalCOGS?: number;
    /**
     * 
     * @type {number}
     * @memberof COGSReport
     */
    totalCOGSPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof COGSReport
     */
    totalCostClosingStock?: number;
    /**
     * 
     * @type {number}
     * @memberof COGSReport
     */
    totalSoldValue?: number;
    /**
     * 
     * @type {CategorisedProducts}
     * @memberof COGSReport
     */
    uncategorisedProducts?: CategorisedProducts;
    /**
     * 
     * @type {Date}
     * @memberof COGSReport
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface COGSReportWithoutProductsDto
 */
export interface COGSReportWithoutProductsDto {
    /**
     * 
     * @type {string}
     * @memberof COGSReportWithoutProductsDto
     */
    closingStockTakeReportId?: string;
    /**
     * 
     * @type {Date}
     * @memberof COGSReportWithoutProductsDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof COGSReportWithoutProductsDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof COGSReportWithoutProductsDto
     */
    openingStockTakeReportId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof COGSReportWithoutProductsDto
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof COGSReportWithoutProductsDto
     */
    periodFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof COGSReportWithoutProductsDto
     */
    periodTo?: Date;
    /**
     * 
     * @type {number}
     * @memberof COGSReportWithoutProductsDto
     */
    totalCOGS?: number;
    /**
     * 
     * @type {number}
     * @memberof COGSReportWithoutProductsDto
     */
    totalCOGSPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof COGSReportWithoutProductsDto
     */
    totalCostClosingStock?: number;
    /**
     * 
     * @type {number}
     * @memberof COGSReportWithoutProductsDto
     */
    totalSoldValue?: number;
    /**
     * 
     * @type {Date}
     * @memberof COGSReportWithoutProductsDto
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface CategorisedProducts
 */
export interface CategorisedProducts {
    /**
     * 
     * @type {CategoryTotals}
     * @memberof CategorisedProducts
     */
    categoryTotals?: CategoryTotals;
    /**
     * 
     * @type {Array<Product>}
     * @memberof CategorisedProducts
     */
    products?: Array<Product>;
}

/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {boolean}
     * @memberof Category
     */
    includeUncategorised?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Category
     */
    subCategories?: Array<string>;
}

/**
 * 
 * @export
 * @interface CategoryTotals
 */
export interface CategoryTotals {
    /**
     * 
     * @type {string}
     * @memberof CategoryTotals
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof CategoryTotals
     */
    totalCOGS?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoryTotals
     */
    totalCOGSPercentage?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoryTotals
     */
    totalCostClosingStock?: number;
    /**
     * 
     * @type {number}
     * @memberof CategoryTotals
     */
    totalSoldValue?: number;
}

/**
 * 
 * @export
 * @interface CloverPosIntegration
 */
export interface CloverPosIntegration {
    /**
     * 
     * @type {Date}
     * @memberof CloverPosIntegration
     */
    expiresAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof CloverPosIntegration
     */
    id?: string;
    /**
     * 
     * @type {Merchant}
     * @memberof CloverPosIntegration
     */
    merchant?: Merchant;
    /**
     * 
     * @type {string}
     * @memberof CloverPosIntegration
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CloverPosIntegration
     */
    organizations?: Array<string>;
}

/**
 * 
 * @export
 * @interface CloverPosIntegrationCreatePayload
 */
export interface CloverPosIntegrationCreatePayload {
    /**
     * 
     * @type {string}
     * @memberof CloverPosIntegrationCreatePayload
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CloverPosIntegrationCreatePayload
     */
    merchantId?: string;
    /**
     * 
     * @type {string}
     * @memberof CloverPosIntegrationCreatePayload
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CloverPosIntegrationCreatePayload
     */
    organizations?: Array<string>;
}

/**
 * 
 * @export
 * @interface CloverRefreshTokenPayload
 */
export interface CloverRefreshTokenPayload {
    /**
     * 
     * @type {string}
     * @memberof CloverRefreshTokenPayload
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof CloverRefreshTokenPayload
     */
    merchantId?: string;
}

/**
 * Resources of AdminLoginSession
 * @export
 * @interface CollectionModelAdminLoginSession
 */
export interface CollectionModelAdminLoginSession {
    /**
     * 
     * @type {EmbeddedCollectionAdminLoginSession}
     * @memberof CollectionModelAdminLoginSession
     */
    embedded: EmbeddedCollectionAdminLoginSession;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelAdminLoginSession
     */
    links: { [key: string]: Link; };
}

/**
 * Resources of AuthEmailToOrg
 * @export
 * @interface CollectionModelAuthEmailToOrg
 */
export interface CollectionModelAuthEmailToOrg {
    /**
     * 
     * @type {EmbeddedCollectionAuthEmailToOrg}
     * @memberof CollectionModelAuthEmailToOrg
     */
    embedded: EmbeddedCollectionAuthEmailToOrg;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelAuthEmailToOrg
     */
    links: { [key: string]: Link; };
}

/**
 * Resources of Feature
 * @export
 * @interface CollectionModelFeature
 */
export interface CollectionModelFeature {
    /**
     * 
     * @type {EmbeddedCollectionFeature}
     * @memberof CollectionModelFeature
     */
    embedded: EmbeddedCollectionFeature;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelFeature
     */
    links: { [key: string]: Link; };
}

/**
 * Resources of OnboardingOrgSteps
 * @export
 * @interface CollectionModelOnboardingOrgSteps
 */
export interface CollectionModelOnboardingOrgSteps {
    /**
     * 
     * @type {EmbeddedCollectionOnboardingOrgSteps}
     * @memberof CollectionModelOnboardingOrgSteps
     */
    embedded: EmbeddedCollectionOnboardingOrgSteps;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelOnboardingOrgSteps
     */
    links: { [key: string]: Link; };
}

/**
 * Resources of PasswordResetToken
 * @export
 * @interface CollectionModelPasswordResetToken
 */
export interface CollectionModelPasswordResetToken {
    /**
     * 
     * @type {EmbeddedCollectionPasswordResetToken}
     * @memberof CollectionModelPasswordResetToken
     */
    embedded: EmbeddedCollectionPasswordResetToken;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelPasswordResetToken
     */
    links: { [key: string]: Link; };
}

/**
 * Resources of PaymentPlan
 * @export
 * @interface CollectionModelPaymentPlan
 */
export interface CollectionModelPaymentPlan {
    /**
     * 
     * @type {EmbeddedCollectionPaymentPlan}
     * @memberof CollectionModelPaymentPlan
     */
    embedded: EmbeddedCollectionPaymentPlan;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelPaymentPlan
     */
    links: { [key: string]: Link; };
}

/**
 * Resources of TokenSigningKey
 * @export
 * @interface CollectionModelTokenSigningKey
 */
export interface CollectionModelTokenSigningKey {
    /**
     * 
     * @type {EmbeddedCollectionTokenSigningKey}
     * @memberof CollectionModelTokenSigningKey
     */
    embedded: EmbeddedCollectionTokenSigningKey;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelTokenSigningKey
     */
    links: { [key: string]: Link; };
}

/**
 * Resources of User
 * @export
 * @interface CollectionModelUser
 */
export interface CollectionModelUser {
    /**
     * 
     * @type {EmbeddedCollectionUser}
     * @memberof CollectionModelUser
     */
    embedded: EmbeddedCollectionUser;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelUser
     */
    links: { [key: string]: Link; };
}

/**
 * Resources of UserInvitation
 * @export
 * @interface CollectionModelUserInvitation
 */
export interface CollectionModelUserInvitation {
    /**
     * 
     * @type {EmbeddedCollectionUserInvitation}
     * @memberof CollectionModelUserInvitation
     */
    embedded: EmbeddedCollectionUserInvitation;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelUserInvitation
     */
    links: { [key: string]: Link; };
}

/**
 * Resources of UserInviteToken
 * @export
 * @interface CollectionModelUserInviteToken
 */
export interface CollectionModelUserInviteToken {
    /**
     * 
     * @type {EmbeddedCollectionUserInviteToken}
     * @memberof CollectionModelUserInviteToken
     */
    embedded: EmbeddedCollectionUserInviteToken;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelUserInviteToken
     */
    links: { [key: string]: Link; };
}

/**
 * Resources of UserLoginSession
 * @export
 * @interface CollectionModelUserLoginSession
 */
export interface CollectionModelUserLoginSession {
    /**
     * 
     * @type {EmbeddedCollectionUserLoginSession}
     * @memberof CollectionModelUserLoginSession
     */
    embedded: EmbeddedCollectionUserLoginSession;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelUserLoginSession
     */
    links: { [key: string]: Link; };
}

/**
 * Resources of UserRequestsCounter
 * @export
 * @interface CollectionModelUserRequestsCounter
 */
export interface CollectionModelUserRequestsCounter {
    /**
     * 
     * @type {EmbeddedCollectionUserRequestsCounter}
     * @memberof CollectionModelUserRequestsCounter
     */
    embedded: EmbeddedCollectionUserRequestsCounter;
    /**
     * Link collection
     * @type {{ [key: string]: Link; }}
     * @memberof CollectionModelUserRequestsCounter
     */
    links: { [key: string]: Link; };
}

/**
 * 
 * @export
 * @interface Connection
 */
export interface Connection {
    /**
     * 
     * @type {string}
     * @memberof Connection
     */
    tenantName?: string;
}

/**
 * 
 * @export
 * @interface ConnectionsDto
 */
export interface ConnectionsDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ConnectionsDto
     */
    connectionIdToTenantName?: { [key: string]: string; };
}

/**
 * 
 * @export
 * @interface ContactDetails
 */
export interface ContactDetails {
    /**
     * 
     * @type {string}
     * @memberof ContactDetails
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof ContactDetails
     */
    telephone?: string;
}

/**
 * 
 * @export
 * @interface ContactInfo
 */
export interface ContactInfo {
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    contactName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ContactInfo
     */
    emails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ContactInfo
     */
    telephone?: string;
}

/**
 * 
 * @export
 * @interface ContactRequestModel
 */
export interface ContactRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ContactRequestModel
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestModel
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof ContactRequestModel
     */
    name: string;
}

/**
 * 
 * @export
 * @interface Creator
 */
export interface Creator {
    /**
     * 
     * @type {string}
     * @memberof Creator
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof Creator
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Creator
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof Creator
     */
    username?: string;
}

/**
 * 
 * @export
 * @interface CustomerRequest
 */
export interface CustomerRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerRequest
     */
    stripeToken: string;
}

/**
 * 
 * @export
 * @interface CustomerSubscribeRequest
 */
export interface CustomerSubscribeRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerSubscribeRequest
     */
    stripePriceId: string;
}

/**
 * 
 * @export
 * @interface DNProcessingResult
 */
export interface DNProcessingResult {
    /**
     * 
     * @type {Date}
     * @memberof DNProcessingResult
     */
    deliveryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DNProcessingResult
     */
    deliveryNoteNumber?: string;
    /**
     * 
     * @type {Array<DNProcessingResultItem>}
     * @memberof DNProcessingResult
     */
    items?: Array<DNProcessingResultItem>;
    /**
     * 
     * @type {string}
     * @memberof DNProcessingResult
     */
    poNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DNProcessingResult
     */
    status?: string;
    /**
     * 
     * @type {DNProcessingResultSupplier}
     * @memberof DNProcessingResult
     */
    supplier?: DNProcessingResultSupplier;
}

/**
 * 
 * @export
 * @interface DNProcessingResultItem
 */
export interface DNProcessingResultItem {
    /**
     * 
     * @type {string}
     * @memberof DNProcessingResultItem
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof DNProcessingResultItem
     */
    desc?: string;
    /**
     * 
     * @type {number}
     * @memberof DNProcessingResultItem
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof DNProcessingResultItem
     */
    qty?: number;
}

/**
 * 
 * @export
 * @interface DNProcessingResultSupplier
 */
export interface DNProcessingResultSupplier {
    /**
     * 
     * @type {string}
     * @memberof DNProcessingResultSupplier
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof DNProcessingResultSupplier
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof DNProcessingResultSupplier
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DNProcessingResultSupplier
     */
    telephone?: string;
}

/**
 * 
 * @export
 * @interface DNProcessingResultWithFile
 */
export interface DNProcessingResultWithFile {
    /**
     * 
     * @type {Date}
     * @memberof DNProcessingResultWithFile
     */
    deliveryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DNProcessingResultWithFile
     */
    deliveryNoteNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DNProcessingResultWithFile
     */
    fileId?: string;
    /**
     * 
     * @type {string}
     * @memberof DNProcessingResultWithFile
     */
    fileName?: string;
    /**
     * 
     * @type {Array<DNProcessingResultItem>}
     * @memberof DNProcessingResultWithFile
     */
    items?: Array<DNProcessingResultItem>;
    /**
     * 
     * @type {string}
     * @memberof DNProcessingResultWithFile
     */
    poNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof DNProcessingResultWithFile
     */
    status?: string;
    /**
     * 
     * @type {DNProcessingResultSupplier}
     * @memberof DNProcessingResultWithFile
     */
    supplier?: DNProcessingResultSupplier;
}

/**
 * 
 * @export
 * @interface Data
 */
export interface Data {
    /**
     * 
     * @type {DataObject}
     * @memberof Data
     */
    object?: DataObject;
}

/**
 * 
 * @export
 * @interface DataObject
 */
export interface DataObject {
    /**
     * 
     * @type {Payment}
     * @memberof DataObject
     */
    payment?: Payment;
}

/**
 * 
 * @export
 * @interface DeliveredProduct
 */
export interface DeliveredProduct {
    /**
     * 
     * @type {string}
     * @memberof DeliveredProduct
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveredProduct
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveredProduct
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveredProduct
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveredProduct
     */
    dnQty?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveredProduct
     */
    isConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveredProduct
     */
    measure?: DeliveredProduct.MeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof DeliveredProduct
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveredProduct
     */
    orderCaseSize?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveredProduct
     */
    orderQty?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveredProduct
     */
    orderQtyInCase?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeliveredProduct
     */
    price?: number;
    /**
     * 
     * @type {DeliveryNoteProductDiscrepancies}
     * @memberof DeliveredProduct
     */
    productDiscrepancies?: DeliveryNoteProductDiscrepancies;
    /**
     * 
     * @type {number}
     * @memberof DeliveredProduct
     */
    receivedQty?: number;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveredProduct
     */
    receivedQtyInCase?: boolean;
    /**
     * 
     * @type {number}
     * @memberof DeliveredProduct
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveredProduct
     */
    subCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveredProduct
     */
    unit?: DeliveredProduct.UnitEnum;
}

/**
 * @export
 * @namespace DeliveredProduct
 */
export namespace DeliveredProduct {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum UnitEnum {
        Bottle = <any> 'Bottle',
        Can = <any> 'Can',
        Keg = <any> 'Keg',
        Other = <any> 'Other'
    }
}

/**
 * 
 * @export
 * @interface DeliveredProductDto
 */
export interface DeliveredProductDto {
    /**
     * 
     * @type {string}
     * @memberof DeliveredProductDto
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveredProductDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveredProductDto
     */
    qty?: number;
}

/**
 * 
 * @export
 * @interface DeliveredProductSortedDto
 */
export interface DeliveredProductSortedDto {
    /**
     * 
     * @type {string}
     * @memberof DeliveredProductSortedDto
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveredProductSortedDto
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveredProductSortedDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof DeliveredProductSortedDto
     */
    qty?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliveredProductSortedDto
     */
    subCategory?: string;
}

/**
 * 
 * @export
 * @interface DeliveriesContacts
 */
export interface DeliveriesContacts {
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveriesContacts
     */
    approvedEmails?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveriesContacts
     */
    queriedEmails?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveriesContacts
     */
    rejectedEmails?: Array<string>;
}

/**
 * 
 * @export
 * @interface DeliveryNote
 */
export interface DeliveryNote {
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNote
     */
    approvedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNote
     */
    completedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNote
     */
    dateOfScanning?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNote
     */
    deliveryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNote
     */
    deliveryNoteNumber?: string;
    /**
     * 
     * @type {DeliveryNoteGrowyzeFile}
     * @memberof DeliveryNote
     */
    extractedFile?: DeliveryNoteGrowyzeFile;
    /**
     * 
     * @type {Array<DeliveryNoteGrowyzeFile>}
     * @memberof DeliveryNote
     */
    files?: Array<DeliveryNoteGrowyzeFile>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryNote
     */
    globalDiscrepancies?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNote
     */
    hasDNQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNote
     */
    hasNoDeliveredProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNote
     */
    hasNoOrderedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNote
     */
    hasReceivedOrderQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNote
     */
    hasReceivedQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNote
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNote
     */
    inQueryDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNote
     */
    isCreatedManuallyWithoutOrder?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNote
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNote
     */
    messageQueryToSupplier?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryNote
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNote
     */
    po?: string;
    /**
     * 
     * @type {Array<DeliveredProduct>}
     * @memberof DeliveryNote
     */
    products?: Array<DeliveredProduct>;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNote
     */
    rejectedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNote
     */
    status?: DeliveryNote.StatusEnum;
    /**
     * 
     * @type {DeliveryNoteSupplier}
     * @memberof DeliveryNote
     */
    supplier?: DeliveryNoteSupplier;
}

/**
 * @export
 * @namespace DeliveryNote
 */
export namespace DeliveryNote {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        APPROVED = <any> 'APPROVED',
        COMPLETED = <any> 'COMPLETED',
        DRAFT = <any> 'DRAFT',
        INQUERY = <any> 'IN_QUERY',
        REJECTED = <any> 'REJECTED'
    }
}

/**
 * 
 * @export
 * @interface DeliveryNoteCreateDto
 */
export interface DeliveryNoteCreateDto {
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteCreateDto
     */
    approvedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteCreateDto
     */
    completedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteCreateDto
     */
    dateOfScanning?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteCreateDto
     */
    deliveryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteCreateDto
     */
    deliveryNoteNumber?: string;
    /**
     * 
     * @type {DeliveryNoteGrowyzeFile}
     * @memberof DeliveryNoteCreateDto
     */
    extractedFile?: DeliveryNoteGrowyzeFile;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryNoteCreateDto
     */
    globalDiscrepancies?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteCreateDto
     */
    hasDNQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteCreateDto
     */
    hasNoDeliveredProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteCreateDto
     */
    hasNoOrderedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteCreateDto
     */
    hasReceivedOrderQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteCreateDto
     */
    hasReceivedQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteCreateDto
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteCreateDto
     */
    inQueryDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteCreateDto
     */
    isCreatedManuallyWithoutOrder?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteCreateDto
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteCreateDto
     */
    messageQueryToSupplier?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryNoteCreateDto
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteCreateDto
     */
    po?: string;
    /**
     * 
     * @type {Array<DeliveredProduct>}
     * @memberof DeliveryNoteCreateDto
     */
    products?: Array<DeliveredProduct>;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteCreateDto
     */
    rejectedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteCreateDto
     */
    status?: DeliveryNoteCreateDto.StatusEnum;
    /**
     * 
     * @type {DeliveryNoteSupplier}
     * @memberof DeliveryNoteCreateDto
     */
    supplier?: DeliveryNoteSupplier;
}

/**
 * @export
 * @namespace DeliveryNoteCreateDto
 */
export namespace DeliveryNoteCreateDto {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        APPROVED = <any> 'APPROVED',
        COMPLETED = <any> 'COMPLETED',
        DRAFT = <any> 'DRAFT',
        INQUERY = <any> 'IN_QUERY',
        REJECTED = <any> 'REJECTED'
    }
}

/**
 * 
 * @export
 * @interface DeliveryNoteFilesDto
 */
export interface DeliveryNoteFilesDto {
    /**
     * 
     * @type {Array<DeliveryNoteGrowyzeFile>}
     * @memberof DeliveryNoteFilesDto
     */
    files?: Array<DeliveryNoteGrowyzeFile>;
}

/**
 * 
 * @export
 * @interface DeliveryNoteGrowyzeFile
 */
export interface DeliveryNoteGrowyzeFile {
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteGrowyzeFile
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteGrowyzeFile
     */
    fileId?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteGrowyzeFile
     */
    fileName?: string;
}

/**
 * 
 * @export
 * @interface DeliveryNotePatchDto
 */
export interface DeliveryNotePatchDto {
    /**
     * 
     * @type {string}
     * @memberof DeliveryNotePatchDto
     */
    status?: DeliveryNotePatchDto.StatusEnum;
}

/**
 * @export
 * @namespace DeliveryNotePatchDto
 */
export namespace DeliveryNotePatchDto {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        APPROVED = <any> 'APPROVED',
        COMPLETED = <any> 'COMPLETED',
        DRAFT = <any> 'DRAFT',
        INQUERY = <any> 'IN_QUERY',
        REJECTED = <any> 'REJECTED'
    }
}

/**
 * 
 * @export
 * @interface DeliveryNoteProductDiscrepancies
 */
export interface DeliveryNoteProductDiscrepancies {
    /**
     * 
     * @type {number}
     * @memberof DeliveryNoteProductDiscrepancies
     */
    deltaDNQty?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryNoteProductDiscrepancies
     */
    deltaReceivedOrderedQty?: number;
    /**
     * 
     * @type {number}
     * @memberof DeliveryNoteProductDiscrepancies
     */
    deltaReceivedQty?: number;
}

/**
 * 
 * @export
 * @interface DeliveryNoteSupplier
 */
export interface DeliveryNoteSupplier {
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteSupplier
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteSupplier
     */
    email?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryNoteSupplier
     */
    emails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteSupplier
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteSupplier
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface DeliveryNoteUpdateDto
 */
export interface DeliveryNoteUpdateDto {
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteUpdateDto
     */
    approvedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteUpdateDto
     */
    completedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteUpdateDto
     */
    dateOfScanning?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteUpdateDto
     */
    deliveryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteUpdateDto
     */
    deliveryNoteNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryNoteUpdateDto
     */
    globalDiscrepancies?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteUpdateDto
     */
    hasDNQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteUpdateDto
     */
    hasNoDeliveredProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteUpdateDto
     */
    hasNoOrderedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteUpdateDto
     */
    hasReceivedOrderQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteUpdateDto
     */
    hasReceivedQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteUpdateDto
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteUpdateDto
     */
    inQueryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteUpdateDto
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteUpdateDto
     */
    messageQueryToSupplier?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryNoteUpdateDto
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteUpdateDto
     */
    po?: string;
    /**
     * 
     * @type {Array<DeliveredProduct>}
     * @memberof DeliveryNoteUpdateDto
     */
    products?: Array<DeliveredProduct>;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteUpdateDto
     */
    rejectedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteUpdateDto
     */
    status?: DeliveryNoteUpdateDto.StatusEnum;
    /**
     * 
     * @type {DeliveryNoteSupplier}
     * @memberof DeliveryNoteUpdateDto
     */
    supplier?: DeliveryNoteSupplier;
}

/**
 * @export
 * @namespace DeliveryNoteUpdateDto
 */
export namespace DeliveryNoteUpdateDto {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        APPROVED = <any> 'APPROVED',
        COMPLETED = <any> 'COMPLETED',
        DRAFT = <any> 'DRAFT',
        INQUERY = <any> 'IN_QUERY',
        REJECTED = <any> 'REJECTED'
    }
}

/**
 * 
 * @export
 * @interface DeliveryNoteWithIsInvoicedFlag
 */
export interface DeliveryNoteWithIsInvoicedFlag {
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    approvedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    completedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    dateOfScanning?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    deliveryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    deliveryNoteNumber?: string;
    /**
     * 
     * @type {DeliveryNoteGrowyzeFile}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    extractedFile?: DeliveryNoteGrowyzeFile;
    /**
     * 
     * @type {Array<DeliveryNoteGrowyzeFile>}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    files?: Array<DeliveryNoteGrowyzeFile>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    globalDiscrepancies?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    hasDNQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    hasNoDeliveredProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    hasNoOrderedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    hasReceivedOrderQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    hasReceivedQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    inQueryDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    isCreatedManuallyWithoutOrder?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    isInvoiced?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    messageQueryToSupplier?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    organizationsNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    po?: string;
    /**
     * 
     * @type {Array<DeliveredProduct>}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    products?: Array<DeliveredProduct>;
    /**
     * 
     * @type {Date}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    rejectedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    status?: DeliveryNoteWithIsInvoicedFlag.StatusEnum;
    /**
     * 
     * @type {DeliveryNoteSupplier}
     * @memberof DeliveryNoteWithIsInvoicedFlag
     */
    supplier?: DeliveryNoteSupplier;
}

/**
 * @export
 * @namespace DeliveryNoteWithIsInvoicedFlag
 */
export namespace DeliveryNoteWithIsInvoicedFlag {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        APPROVED = <any> 'APPROVED',
        COMPLETED = <any> 'COMPLETED',
        DRAFT = <any> 'DRAFT',
        INQUERY = <any> 'IN_QUERY',
        REJECTED = <any> 'REJECTED'
    }
}

/**
 * 
 * @export
 * @interface DiscrepancyReport
 */
export interface DiscrepancyReport {
    /**
     * 
     * @type {Array<MainCategorisedProducts>}
     * @memberof DiscrepancyReport
     */
    categorisedProducts?: Array<MainCategorisedProducts>;
    /**
     * 
     * @type {Date}
     * @memberof DiscrepancyReport
     */
    createdAt?: Date;
    /**
     * 
     * @type {FilterCategories}
     * @memberof DiscrepancyReport
     */
    filterCategories?: FilterCategories;
    /**
     * 
     * @type {string}
     * @memberof DiscrepancyReport
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscrepancyReport
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscrepancyReport
     */
    openingStockTakeReportId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DiscrepancyReport
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof DiscrepancyReport
     */
    periodFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DiscrepancyReport
     */
    periodTo?: Date;
    /**
     * 
     * @type {Sales}
     * @memberof DiscrepancyReport
     */
    sales?: Sales;
    /**
     * 
     * @type {string}
     * @memberof DiscrepancyReport
     */
    stockTakeReportId?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscrepancyReport
     */
    totalDiscrepancyAmount?: number;
    /**
     * 
     * @type {CategorisedProducts}
     * @memberof DiscrepancyReport
     */
    uncategorisedProducts?: CategorisedProducts;
    /**
     * 
     * @type {Date}
     * @memberof DiscrepancyReport
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface DiscrepancyReportRow
 */
export interface DiscrepancyReportRow {
    /**
     * 
     * @type {string}
     * @memberof DiscrepancyReportRow
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscrepancyReportRow
     */
    category?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscrepancyReportRow
     */
    currentQty?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscrepancyReportRow
     */
    deliveryQty?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscrepancyReportRow
     */
    discrepancyAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscrepancyReportRow
     */
    discrepancyQty?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DiscrepancyReportRow
     */
    dishesPosIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DiscrepancyReportRow
     */
    expectedClosingQty?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscrepancyReportRow
     */
    measure?: DiscrepancyReportRow.MeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof DiscrepancyReportRow
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscrepancyReportRow
     */
    openingQty?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscrepancyReportRow
     */
    posId?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscrepancyReportRow
     */
    price?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof DiscrepancyReportRow
     */
    recipesPosIds?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof DiscrepancyReportRow
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscrepancyReportRow
     */
    soldQty?: number;
    /**
     * 
     * @type {string}
     * @memberof DiscrepancyReportRow
     */
    subCategory?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscrepancyReportRow
     */
    transferredQty?: number;
    /**
     * 
     * @type {number}
     * @memberof DiscrepancyReportRow
     */
    wasteQty?: number;
}

/**
 * @export
 * @namespace DiscrepancyReportRow
 */
export namespace DiscrepancyReportRow {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
}

/**
 * 
 * @export
 * @interface DiscrepancyReportWithoutProductsDto
 */
export interface DiscrepancyReportWithoutProductsDto {
    /**
     * 
     * @type {Date}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {FilterCategories}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    filterCategories?: FilterCategories;
    /**
     * 
     * @type {string}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    includeProductsContainCustomMainCategory?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    includeProductsContainNullMainCategory?: boolean;
    /**
     * 
     * @type {Array<Category>}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    mainCategories?: Array<Category>;
    /**
     * 
     * @type {string}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    openingStockTakeReportId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    periodFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    periodTo?: Date;
    /**
     * 
     * @type {Sales}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    sales?: Sales;
    /**
     * 
     * @type {string}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    stockTakeReportId?: string;
    /**
     * 
     * @type {number}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    totalDiscrepancyAmount?: number;
    /**
     * 
     * @type {Date}
     * @memberof DiscrepancyReportWithoutProductsDto
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface Dish
 */
export interface Dish {
    /**
     * 
     * @type {Array<string>}
     * @memberof Dish
     */
    allergens?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Dish
     */
    category?: string;
    /**
     * 
     * @type {Date}
     * @memberof Dish
     */
    createdDate?: Date;
    /**
     * 
     * @type {GrowyzeFile}
     * @memberof Dish
     */
    featuredFile?: GrowyzeFile;
    /**
     * 
     * @type {Array<GrowyzeFile>}
     * @memberof Dish
     */
    files?: Array<GrowyzeFile>;
    /**
     * 
     * @type {FolderDto}
     * @memberof Dish
     */
    folder?: FolderDto;
    /**
     * 
     * @type {string}
     * @memberof Dish
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Dish
     */
    mayContainAllergens?: Array<string>;
    /**
     * 
     * @type {Array<DishMenuDto>}
     * @memberof Dish
     */
    menus?: Array<DishMenuDto>;
    /**
     * 
     * @type {string}
     * @memberof Dish
     */
    method?: string;
    /**
     * 
     * @type {string}
     * @memberof Dish
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Dish
     */
    notes?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Dish
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Dish
     */
    posId?: string;
    /**
     * 
     * @type {number}
     * @memberof Dish
     */
    profit?: number;
    /**
     * 
     * @type {number}
     * @memberof Dish
     */
    profitPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof Dish
     */
    salePrice?: number;
    /**
     * 
     * @type {Array<Section>}
     * @memberof Dish
     */
    sections?: Array<Section>;
    /**
     * 
     * @type {string}
     * @memberof Dish
     */
    status?: Dish.StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Dish
     */
    suggestedSalePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Dish
     */
    targetMarginPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof Dish
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof Dish
     */
    totalCostPercent?: number;
    /**
     * 
     * @type {Date}
     * @memberof Dish
     */
    updatedDate?: Date;
    /**
     * 
     * @type {ModelYield}
     * @memberof Dish
     */
    weight?: ModelYield;
}

/**
 * @export
 * @namespace Dish
 */
export namespace Dish {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        DRAFT = <any> 'DRAFT',
        PUBLISHED = <any> 'PUBLISHED'
    }
}

/**
 * 
 * @export
 * @interface DishIdListDto
 */
export interface DishIdListDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DishIdListDto
     */
    dishesIds?: Array<string>;
}

/**
 * 
 * @export
 * @interface DishMenuDto
 */
export interface DishMenuDto {
    /**
     * 
     * @type {string}
     * @memberof DishMenuDto
     */
    id?: string;
}

/**
 * 
 * @export
 * @interface DishRecipeDto
 */
export interface DishRecipeDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof DishRecipeDto
     */
    allergens?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DishRecipeDto
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof DishRecipeDto
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DishRecipeDto
     */
    mayContainAllergens?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DishRecipeDto
     */
    name?: string;
    /**
     * 
     * @type {PortionDto}
     * @memberof DishRecipeDto
     */
    portion?: PortionDto;
    /**
     * 
     * @type {string}
     * @memberof DishRecipeDto
     */
    posId?: string;
    /**
     * 
     * @type {number}
     * @memberof DishRecipeDto
     */
    totalCost?: number;
    /**
     * 
     * @type {ModelYield}
     * @memberof DishRecipeDto
     */
    _yield?: ModelYield;
}

/**
 * Embedded collection of AdminLoginSession
 * @export
 * @interface EmbeddedCollectionAdminLoginSession
 */
export interface EmbeddedCollectionAdminLoginSession {
    /**
     * Resource collection
     * @type {Array<AdminLoginSession>}
     * @memberof EmbeddedCollectionAdminLoginSession
     */
    adminLoginSessions: Array<AdminLoginSession>;
}

/**
 * Embedded collection of AuthEmailToOrg
 * @export
 * @interface EmbeddedCollectionAuthEmailToOrg
 */
export interface EmbeddedCollectionAuthEmailToOrg {
    /**
     * Resource collection
     * @type {Array<AuthEmailToOrg>}
     * @memberof EmbeddedCollectionAuthEmailToOrg
     */
    authEmailToOrgs: Array<AuthEmailToOrg>;
}

/**
 * Embedded collection of Feature
 * @export
 * @interface EmbeddedCollectionFeature
 */
export interface EmbeddedCollectionFeature {
    /**
     * Resource collection
     * @type {Array<Feature>}
     * @memberof EmbeddedCollectionFeature
     */
    features: Array<Feature>;
}

/**
 * Embedded collection of OnboardingOrgSteps
 * @export
 * @interface EmbeddedCollectionOnboardingOrgSteps
 */
export interface EmbeddedCollectionOnboardingOrgSteps {
    /**
     * Resource collection
     * @type {Array<OnboardingOrgSteps>}
     * @memberof EmbeddedCollectionOnboardingOrgSteps
     */
    onboardingOrgStepses: Array<OnboardingOrgSteps>;
}

/**
 * Embedded collection of PasswordResetToken
 * @export
 * @interface EmbeddedCollectionPasswordResetToken
 */
export interface EmbeddedCollectionPasswordResetToken {
    /**
     * Resource collection
     * @type {Array<PasswordResetToken>}
     * @memberof EmbeddedCollectionPasswordResetToken
     */
    passwordResetTokens: Array<PasswordResetToken>;
}

/**
 * Embedded collection of PaymentPlan
 * @export
 * @interface EmbeddedCollectionPaymentPlan
 */
export interface EmbeddedCollectionPaymentPlan {
    /**
     * Resource collection
     * @type {Array<PaymentPlan>}
     * @memberof EmbeddedCollectionPaymentPlan
     */
    paymentPlans: Array<PaymentPlan>;
}

/**
 * Embedded collection of TokenSigningKey
 * @export
 * @interface EmbeddedCollectionTokenSigningKey
 */
export interface EmbeddedCollectionTokenSigningKey {
    /**
     * Resource collection
     * @type {Array<TokenSigningKey>}
     * @memberof EmbeddedCollectionTokenSigningKey
     */
    tokenSigningKeys: Array<TokenSigningKey>;
}

/**
 * Embedded collection of User
 * @export
 * @interface EmbeddedCollectionUser
 */
export interface EmbeddedCollectionUser {
    /**
     * Resource collection
     * @type {Array<User>}
     * @memberof EmbeddedCollectionUser
     */
    users: Array<User>;
}

/**
 * Embedded collection of UserInvitation
 * @export
 * @interface EmbeddedCollectionUserInvitation
 */
export interface EmbeddedCollectionUserInvitation {
    /**
     * Resource collection
     * @type {Array<UserInvitation>}
     * @memberof EmbeddedCollectionUserInvitation
     */
    userInvitations: Array<UserInvitation>;
}

/**
 * Embedded collection of UserInviteToken
 * @export
 * @interface EmbeddedCollectionUserInviteToken
 */
export interface EmbeddedCollectionUserInviteToken {
    /**
     * Resource collection
     * @type {Array<UserInviteToken>}
     * @memberof EmbeddedCollectionUserInviteToken
     */
    userInviteTokens: Array<UserInviteToken>;
}

/**
 * Embedded collection of UserLoginSession
 * @export
 * @interface EmbeddedCollectionUserLoginSession
 */
export interface EmbeddedCollectionUserLoginSession {
    /**
     * Resource collection
     * @type {Array<UserLoginSession>}
     * @memberof EmbeddedCollectionUserLoginSession
     */
    userLoginSessions: Array<UserLoginSession>;
}

/**
 * Embedded collection of UserRequestsCounter
 * @export
 * @interface EmbeddedCollectionUserRequestsCounter
 */
export interface EmbeddedCollectionUserRequestsCounter {
    /**
     * Resource collection
     * @type {Array<UserRequestsCounter>}
     * @memberof EmbeddedCollectionUserRequestsCounter
     */
    userRequestsCounters: Array<UserRequestsCounter>;
}

/**
 * 
 * @export
 * @interface EntityModelAdminLoginSession
 */
export interface EntityModelAdminLoginSession {
    /**
     * 
     * @type {Date}
     * @memberof EntityModelAdminLoginSession
     */
    expiryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EntityModelAdminLoginSession
     */
    id?: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelAdminLoginSession
     */
    links?: Links;
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityModelAdminLoginSession
     */
    orgIds?: Array<string>;
    /**
     * 
     * @type {User}
     * @memberof EntityModelAdminLoginSession
     */
    user?: User;
}

/**
 * 
 * @export
 * @interface EntityModelAuthEmailToOrg
 */
export interface EntityModelAuthEmailToOrg {
    /**
     * 
     * @type {string}
     * @memberof EntityModelAuthEmailToOrg
     */
    accountNumber?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityModelAuthEmailToOrg
     */
    emails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelAuthEmailToOrg
     */
    id?: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelAuthEmailToOrg
     */
    links?: Links;
    /**
     * 
     * @type {string}
     * @memberof EntityModelAuthEmailToOrg
     */
    orgId?: string;
}

/**
 * 
 * @export
 * @interface EntityModelFeature
 */
export interface EntityModelFeature {
    /**
     * 
     * @type {string}
     * @memberof EntityModelFeature
     */
    category: EntityModelFeature.CategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelFeature
     */
    id?: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelFeature
     */
    links?: Links;
    /**
     * 
     * @type {string}
     * @memberof EntityModelFeature
     */
    name: string;
    /**
     * 
     * @type {Permissions}
     * @memberof EntityModelFeature
     */
    permissions: Permissions;
}

/**
 * @export
 * @namespace EntityModelFeature
 */
export namespace EntityModelFeature {
    /**
     * @export
     * @enum {string}
     */
    export enum CategoryEnum {
        BUSINESSINSIGHTS = <any> 'BUSINESS_INSIGHTS',
        COMPANYSETTINGS = <any> 'COMPANY_SETTINGS',
        PRODUCTS = <any> 'PRODUCTS',
        PURCHASES = <any> 'PURCHASES',
        SALES = <any> 'SALES',
        STOCK = <any> 'STOCK'
    }
}

/**
 * 
 * @export
 * @interface EntityModelOnboardingOrgSteps
 */
export interface EntityModelOnboardingOrgSteps {
    /**
     * 
     * @type {boolean}
     * @memberof EntityModelOnboardingOrgSteps
     */
    hasAddedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EntityModelOnboardingOrgSteps
     */
    hasAddedRecipe?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EntityModelOnboardingOrgSteps
     */
    hasAddedStocktake?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EntityModelOnboardingOrgSteps
     */
    hasAddedWaste?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof EntityModelOnboardingOrgSteps
     */
    hasCheckedCorrectPricesAndBarcodes?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EntityModelOnboardingOrgSteps
     */
    id?: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelOnboardingOrgSteps
     */
    links?: Links;
    /**
     * 
     * @type {string}
     * @memberof EntityModelOnboardingOrgSteps
     */
    orgId?: string;
}

/**
 * 
 * @export
 * @interface EntityModelPasswordResetToken
 */
export interface EntityModelPasswordResetToken {
    /**
     * 
     * @type {Date}
     * @memberof EntityModelPasswordResetToken
     */
    expiryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPasswordResetToken
     */
    id?: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelPasswordResetToken
     */
    links?: Links;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPasswordResetToken
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPasswordResetToken
     */
    username?: string;
}

/**
 * 
 * @export
 * @interface EntityModelPaymentPlan
 */
export interface EntityModelPaymentPlan {
    /**
     * 
     * @type {Date}
     * @memberof EntityModelPaymentPlan
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof EntityModelPaymentPlan
     */
    expirationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPaymentPlan
     */
    id?: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelPaymentPlan
     */
    links?: Links;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPaymentPlan
     */
    orgId?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPaymentPlan
     */
    stripeCustomerId?: string;
    /**
     * 
     * @type {PaymentRecurring}
     * @memberof EntityModelPaymentPlan
     */
    stripePaymentRecurring?: PaymentRecurring;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPaymentPlan
     */
    stripeSubscriptionId?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelPaymentPlan
     */
    type?: EntityModelPaymentPlan.TypeEnum;
}

/**
 * @export
 * @namespace EntityModelPaymentPlan
 */
export namespace EntityModelPaymentPlan {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        DEFAULT = <any> 'DEFAULT',
        ENTERPRISE = <any> 'ENTERPRISE',
        ENTERPRISEPLUSSCANNING = <any> 'ENTERPRISE_PLUS_SCANNING',
        STARTER = <any> 'STARTER'
    }
}

/**
 * 
 * @export
 * @interface EntityModelTokenSigningKey
 */
export interface EntityModelTokenSigningKey {
    /**
     * 
     * @type {string}
     * @memberof EntityModelTokenSigningKey
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelTokenSigningKey
     */
    keyBytes?: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelTokenSigningKey
     */
    links?: Links;
    /**
     * 
     * @type {number}
     * @memberof EntityModelTokenSigningKey
     */
    version?: number;
}

/**
 * 
 * @export
 * @interface EntityModelUser
 */
export interface EntityModelUser {
    /**
     * 
     * @type {boolean}
     * @memberof EntityModelUser
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUser
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUser
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUser
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof EntityModelUser
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUser
     */
    lastName: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelUser
     */
    links?: Links;
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityModelUser
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Array<OrgAuth>}
     * @memberof EntityModelUser
     */
    orgsAuths?: Array<OrgAuth>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUser
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUser
     */
    roleOfUser?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof EntityModelUser
     */
    roles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUser
     */
    username: string;
}

/**
 * 
 * @export
 * @interface EntityModelUserInvitation
 */
export interface EntityModelUserInvitation {
    /**
     * 
     * @type {boolean}
     * @memberof EntityModelUserInvitation
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserInvitation
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserInvitation
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof EntityModelUserInvitation
     */
    invitationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserInvitation
     */
    lastName?: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelUserInvitation
     */
    links?: Links;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserInvitation
     */
    organization?: string;
    /**
     * 
     * @type {Date}
     * @memberof EntityModelUserInvitation
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserInvitation
     */
    username?: string;
}

/**
 * 
 * @export
 * @interface EntityModelUserInviteToken
 */
export interface EntityModelUserInviteToken {
    /**
     * 
     * @type {Date}
     * @memberof EntityModelUserInviteToken
     */
    expiryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserInviteToken
     */
    id?: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelUserInviteToken
     */
    links?: Links;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserInviteToken
     */
    organization?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserInviteToken
     */
    roleOfUser?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserInviteToken
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserInviteToken
     */
    username?: string;
}

/**
 * 
 * @export
 * @interface EntityModelUserLoginSession
 */
export interface EntityModelUserLoginSession {
    /**
     * 
     * @type {Date}
     * @memberof EntityModelUserLoginSession
     */
    expiryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserLoginSession
     */
    id?: string;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelUserLoginSession
     */
    links?: Links;
    /**
     * 
     * @type {User}
     * @memberof EntityModelUserLoginSession
     */
    user?: User;
}

/**
 * 
 * @export
 * @interface EntityModelUserRequestsCounter
 */
export interface EntityModelUserRequestsCounter {
    /**
     * 
     * @type {number}
     * @memberof EntityModelUserRequestsCounter
     */
    deliveryNotesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelUserRequestsCounter
     */
    deliveryNotesScanningCount?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserRequestsCounter
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelUserRequestsCounter
     */
    invoiceCount?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelUserRequestsCounter
     */
    invoicesScanningCount?: number;
    /**
     * 
     * @type {Links}
     * @memberof EntityModelUserRequestsCounter
     */
    links?: Links;
    /**
     * 
     * @type {number}
     * @memberof EntityModelUserRequestsCounter
     */
    ordersCount?: number;
    /**
     * 
     * @type {number}
     * @memberof EntityModelUserRequestsCounter
     */
    supplierScanningCount?: number;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserRequestsCounter
     */
    userPlan?: EntityModelUserRequestsCounter.UserPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof EntityModelUserRequestsCounter
     */
    username?: string;
    /**
     * 
     * @type {number}
     * @memberof EntityModelUserRequestsCounter
     */
    version?: number;
}

/**
 * @export
 * @namespace EntityModelUserRequestsCounter
 */
export namespace EntityModelUserRequestsCounter {
    /**
     * @export
     * @enum {string}
     */
    export enum UserPlanEnum {
        LIMITED = <any> 'LIMITED',
        UNLIMITED = <any> 'UNLIMITED'
    }
}

/**
 * 
 * @export
 * @interface Event
 */
export interface Event {
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Event
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {any}
     * @memberof Event
     */
    propNameToValue?: any;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    referenceId?: string;
    /**
     * 
     * @type {string}
     * @memberof Event
     */
    type?: Event.TypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof Event
     */
    updatedAt?: Date;
}

/**
 * @export
 * @namespace Event
 */
export namespace Event {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        PRODUCT = <any> 'PRODUCT',
        RECIPE = <any> 'RECIPE',
        SUPPLIER = <any> 'SUPPLIER'
    }
}

/**
 * 
 * @export
 * @interface EventIdListDto
 */
export interface EventIdListDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof EventIdListDto
     */
    eventsIds?: Array<string>;
}

/**
 * 
 * @export
 * @interface EventListDto
 */
export interface EventListDto {
    /**
     * 
     * @type {Array<Event>}
     * @memberof EventListDto
     */
    events?: Array<Event>;
}

/**
 * 
 * @export
 * @interface ExportInvoiceProductsPayload
 */
export interface ExportInvoiceProductsPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExportInvoiceProductsPayload
     */
    groupsIds?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExportInvoiceProductsPayload
     */
    suppliersIds?: Array<string>;
}

/**
 * 
 * @export
 * @interface ExtendedProduct
 */
export interface ExtendedProduct {
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtendedProduct
     */
    allergens?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedProduct
     */
    autoGenBarcode?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExtendedProduct
     */
    avgWeeklyConsumption?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtendedProduct
     */
    awaitingDelivery?: number;
    /**
     * 
     * @type {string}
     * @memberof ExtendedProduct
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedProduct
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedProduct
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof ExtendedProduct
     */
    countOfProductsInGroup?: number;
    /**
     * 
     * @type {string}
     * @memberof ExtendedProduct
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedProduct
     */
    favourite?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ExtendedProduct
     */
    groupId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedProduct
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtendedProduct
     */
    mayContainAllergens?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExtendedProduct
     */
    measure?: ExtendedProduct.MeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof ExtendedProduct
     */
    minQtyInStock?: number;
    /**
     * 
     * @type {string}
     * @memberof ExtendedProduct
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedProduct
     */
    notes?: string;
    /**
     * 
     * @type {OrderedIn}
     * @memberof ExtendedProduct
     */
    orderedIn?: OrderedIn;
    /**
     * 
     * @type {Array<string>}
     * @memberof ExtendedProduct
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof ExtendedProduct
     */
    posId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ExtendedProduct
     */
    preferred?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ExtendedProduct
     */
    price?: number;
    /**
     * 
     * @type {ModelCase}
     * @memberof ExtendedProduct
     */
    productCase?: ModelCase;
    /**
     * 
     * @type {number}
     * @memberof ExtendedProduct
     */
    size?: number;
    /**
     * 
     * @type {number}
     * @memberof ExtendedProduct
     */
    stockOnHand?: number;
    /**
     * 
     * @type {Date}
     * @memberof ExtendedProduct
     */
    stockTakeDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof ExtendedProduct
     */
    subCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedProduct
     */
    supplierId?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedProduct
     */
    supplierName?: string;
    /**
     * 
     * @type {string}
     * @memberof ExtendedProduct
     */
    unit?: ExtendedProduct.UnitEnum;
}

/**
 * @export
 * @namespace ExtendedProduct
 */
export namespace ExtendedProduct {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum UnitEnum {
        Bottle = <any> 'Bottle',
        Can = <any> 'Can',
        Keg = <any> 'Keg',
        Other = <any> 'Other'
    }
}

/**
 * 
 * @export
 * @interface Feature
 */
export interface Feature {
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    category: Feature.CategoryEnum;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Feature
     */
    name: string;
    /**
     * 
     * @type {Permissions}
     * @memberof Feature
     */
    permissions: Permissions;
}

/**
 * @export
 * @namespace Feature
 */
export namespace Feature {
    /**
     * @export
     * @enum {string}
     */
    export enum CategoryEnum {
        BUSINESSINSIGHTS = <any> 'BUSINESS_INSIGHTS',
        COMPANYSETTINGS = <any> 'COMPANY_SETTINGS',
        PRODUCTS = <any> 'PRODUCTS',
        PURCHASES = <any> 'PURCHASES',
        SALES = <any> 'SALES',
        STOCK = <any> 'STOCK'
    }
}

/**
 * 
 * @export
 * @interface FeatureRequestDto
 */
export interface FeatureRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FeatureRequestDto
     */
    id: string;
    /**
     * 
     * @type {Permissions}
     * @memberof FeatureRequestDto
     */
    permissions: Permissions;
}

/**
 * 
 * @export
 * @interface Features
 */
export interface Features {
    /**
     * 
     * @type {Array<Feature>}
     * @memberof Features
     */
    permissionsPerFeatures?: Array<Feature>;
}

/**
 * 
 * @export
 * @interface FeaturesPayload
 */
export interface FeaturesPayload {
    /**
     * 
     * @type {Array<FeatureRequestDto>}
     * @memberof FeaturesPayload
     */
    permissionsPerFeatures: Array<FeatureRequestDto>;
}

/**
 * 
 * @export
 * @interface Feedback
 */
export interface Feedback {
    /**
     * 
     * @type {Annotations}
     * @memberof Feedback
     */
    annotations?: Annotations;
    /**
     * 
     * @type {Array<LineElement>}
     * @memberof Feedback
     */
    lines?: Array<LineElement>;
}

/**
 * 
 * @export
 * @interface FilesDto
 */
export interface FilesDto {
    /**
     * 
     * @type {Array<GrowyzeFile>}
     * @memberof FilesDto
     */
    files?: Array<GrowyzeFile>;
}

/**
 * 
 * @export
 * @interface FilterCategories
 */
export interface FilterCategories {
    /**
     * 
     * @type {boolean}
     * @memberof FilterCategories
     */
    includeUncategorised?: boolean;
    /**
     * 
     * @type {Array<Category>}
     * @memberof FilterCategories
     */
    mainCategories?: Array<Category>;
}

/**
 * 
 * @export
 * @interface Folder
 */
export interface Folder {
    /**
     * 
     * @type {Array<string>}
     * @memberof Folder
     */
    contentIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Folder
     */
    contentType?: Folder.ContentTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof Folder
     */
    createdDate?: Date;
    /**
     * 
     * @type {Array<FolderDto>}
     * @memberof Folder
     */
    folders?: Array<FolderDto>;
    /**
     * 
     * @type {string}
     * @memberof Folder
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Folder
     */
    isRoot?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Folder
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Folder
     */
    organizations?: Array<string>;
}

/**
 * @export
 * @namespace Folder
 */
export namespace Folder {
    /**
     * @export
     * @enum {string}
     */
    export enum ContentTypeEnum {
        DISH = <any> 'DISH',
        MENU = <any> 'MENU',
        RECIPE = <any> 'RECIPE'
    }
}

/**
 * 
 * @export
 * @interface FolderDto
 */
export interface FolderDto {
    /**
     * 
     * @type {string}
     * @memberof FolderDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof FolderDto
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface FolderPayload
 */
export interface FolderPayload {
    /**
     * 
     * @type {string}
     * @memberof FolderPayload
     */
    contentType?: FolderPayload.ContentTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof FolderPayload
     */
    name?: string;
}

/**
 * @export
 * @namespace FolderPayload
 */
export namespace FolderPayload {
    /**
     * @export
     * @enum {string}
     */
    export enum ContentTypeEnum {
        DISH = <any> 'DISH',
        MENU = <any> 'MENU',
        RECIPE = <any> 'RECIPE'
    }
}

/**
 * 
 * @export
 * @interface GPCategoryUpdatePayload
 */
export interface GPCategoryUpdatePayload {
    /**
     * 
     * @type {number}
     * @memberof GPCategoryUpdatePayload
     */
    expectedGP?: number;
    /**
     * 
     * @type {number}
     * @memberof GPCategoryUpdatePayload
     */
    expectedGPPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof GPCategoryUpdatePayload
     */
    soldValue?: number;
}

/**
 * 
 * @export
 * @interface GPProductUpdatePayload
 */
export interface GPProductUpdatePayload {
    /**
     * 
     * @type {number}
     * @memberof GPProductUpdatePayload
     */
    soldQty?: number;
    /**
     * 
     * @type {number}
     * @memberof GPProductUpdatePayload
     */
    soldValue?: number;
}

/**
 * 
 * @export
 * @interface GPReport
 */
export interface GPReport {
    /**
     * 
     * @type {Array<MainCategorisedProducts>}
     * @memberof GPReport
     */
    categorisedProducts?: Array<MainCategorisedProducts>;
    /**
     * 
     * @type {string}
     * @memberof GPReport
     */
    closingStockTakeReportId?: string;
    /**
     * 
     * @type {Date}
     * @memberof GPReport
     */
    createdAt?: Date;
    /**
     * 
     * @type {FilterCategories}
     * @memberof GPReport
     */
    filterCategories?: FilterCategories;
    /**
     * 
     * @type {string}
     * @memberof GPReport
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GPReport
     */
    isManuallyEdited?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GPReport
     */
    openingStockTakeReportId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GPReport
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof GPReport
     */
    periodFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GPReport
     */
    periodTo?: Date;
    /**
     * 
     * @type {Sales}
     * @memberof GPReport
     */
    sales?: Sales;
    /**
     * 
     * @type {Totals}
     * @memberof GPReport
     */
    totals?: Totals;
    /**
     * 
     * @type {CategorisedProducts}
     * @memberof GPReport
     */
    uncategorisedProducts?: CategorisedProducts;
    /**
     * 
     * @type {Date}
     * @memberof GPReport
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface GPReportWithoutProductsDto
 */
export interface GPReportWithoutProductsDto {
    /**
     * 
     * @type {string}
     * @memberof GPReportWithoutProductsDto
     */
    closingStockTakeReportId?: string;
    /**
     * 
     * @type {Date}
     * @memberof GPReportWithoutProductsDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof GPReportWithoutProductsDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GPReportWithoutProductsDto
     */
    openingStockTakeReportId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GPReportWithoutProductsDto
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof GPReportWithoutProductsDto
     */
    periodFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof GPReportWithoutProductsDto
     */
    periodTo?: Date;
    /**
     * 
     * @type {Sales}
     * @memberof GPReportWithoutProductsDto
     */
    sales?: Sales;
    /**
     * 
     * @type {Totals}
     * @memberof GPReportWithoutProductsDto
     */
    totals?: Totals;
    /**
     * 
     * @type {Date}
     * @memberof GPReportWithoutProductsDto
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface GenericAccountingIntegration
 */
export interface GenericAccountingIntegration {
    /**
     * 
     * @type {string}
     * @memberof GenericAccountingIntegration
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericAccountingIntegration
     */
    status?: GenericAccountingIntegration.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GenericAccountingIntegration
     */
    type?: GenericAccountingIntegration.TypeEnum;
}

/**
 * @export
 * @namespace GenericAccountingIntegration
 */
export namespace GenericAccountingIntegration {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        ACTIVE = <any> 'ACTIVE',
        INACTIVE = <any> 'INACTIVE'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        XERO = <any> 'XERO'
    }
}

/**
 * 
 * @export
 * @interface GenericPosIntegration
 */
export interface GenericPosIntegration {
    /**
     * 
     * @type {string}
     * @memberof GenericPosIntegration
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GenericPosIntegration
     */
    status?: GenericPosIntegration.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof GenericPosIntegration
     */
    type?: GenericPosIntegration.TypeEnum;
}

/**
 * @export
 * @namespace GenericPosIntegration
 */
export namespace GenericPosIntegration {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        ACTIVE = <any> 'ACTIVE',
        INACTIVE = <any> 'INACTIVE'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        CLOVER = <any> 'CLOVER',
        GOEPOS = <any> 'GOEPOS',
        LIGHTSPEED = <any> 'LIGHTSPEED',
        NEWBRIDGE = <any> 'NEWBRIDGE',
        SQUARE = <any> 'SQUARE',
        WOOCOMMERCE = <any> 'WOOCOMMERCE'
    }
}

/**
 * 
 * @export
 * @interface GlobalProduct
 */
export interface GlobalProduct {
    /**
     * 
     * @type {string}
     * @memberof GlobalProduct
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalProduct
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalProduct
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalProduct
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalProduct
     */
    measure?: GlobalProduct.MeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof GlobalProduct
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalProduct
     */
    notes?: string;
    /**
     * 
     * @type {number}
     * @memberof GlobalProduct
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof GlobalProduct
     */
    subCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof GlobalProduct
     */
    unit?: GlobalProduct.UnitEnum;
}

/**
 * @export
 * @namespace GlobalProduct
 */
export namespace GlobalProduct {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum UnitEnum {
        Bottle = <any> 'Bottle',
        Can = <any> 'Can',
        Keg = <any> 'Keg',
        Other = <any> 'Other'
    }
}

/**
 * 
 * @export
 * @interface GoEposIntegrationCreatePayload
 */
export interface GoEposIntegrationCreatePayload {
    /**
     * 
     * @type {string}
     * @memberof GoEposIntegrationCreatePayload
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GoEposIntegrationCreatePayload
     */
    organizations?: Array<string>;
}

/**
 * 
 * @export
 * @interface GoEposPosIntegration
 */
export interface GoEposPosIntegration {
    /**
     * 
     * @type {string}
     * @memberof GoEposPosIntegration
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof GoEposPosIntegration
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GoEposPosIntegration
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof GoEposPosIntegration
     */
    status?: GoEposPosIntegration.StatusEnum;
}

/**
 * @export
 * @namespace GoEposPosIntegration
 */
export namespace GoEposPosIntegration {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        CONNECTED = <any> 'CONNECTED',
        PENDING = <any> 'PENDING'
    }
}

/**
 * 
 * @export
 * @interface GoEposSalesPayload
 */
export interface GoEposSalesPayload {
    /**
     * 
     * @type {Array<GoEposSalesPayloadSaleItem>}
     * @memberof GoEposSalesPayload
     */
    items?: Array<GoEposSalesPayloadSaleItem>;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof GoEposSalesPayload
     */
    metadata?: { [key: string]: string; };
    /**
     * 
     * @type {Array<string>}
     * @memberof GoEposSalesPayload
     */
    organizations?: Array<string>;
}

/**
 * 
 * @export
 * @interface GoEposSalesPayloadSaleItem
 */
export interface GoEposSalesPayloadSaleItem {
    /**
     * 
     * @type {string}
     * @memberof GoEposSalesPayloadSaleItem
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof GoEposSalesPayloadSaleItem
     */
    posId?: string;
    /**
     * 
     * @type {number}
     * @memberof GoEposSalesPayloadSaleItem
     */
    soldQty?: number;
    /**
     * 
     * @type {string}
     * @memberof GoEposSalesPayloadSaleItem
     */
    status?: GoEposSalesPayloadSaleItem.StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof GoEposSalesPayloadSaleItem
     */
    totalValue?: number;
}

/**
 * @export
 * @namespace GoEposSalesPayloadSaleItem
 */
export namespace GoEposSalesPayloadSaleItem {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        Refund = <any> 'refund',
        Sale = <any> 'sale'
    }
}

/**
 * 
 * @export
 * @interface GoEposSalesResponse
 */
export interface GoEposSalesResponse {
    /**
     * 
     * @type {string}
     * @memberof GoEposSalesResponse
     */
    growyzeOrderId?: string;
}

/**
 * 
 * @export
 * @interface GrowyzeFile
 */
export interface GrowyzeFile {
    /**
     * 
     * @type {Date}
     * @memberof GrowyzeFile
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof GrowyzeFile
     */
    fileId?: string;
    /**
     * 
     * @type {string}
     * @memberof GrowyzeFile
     */
    fileName?: string;
}

/**
 * 
 * @export
 * @interface ImageSupportRequestModel
 */
export interface ImageSupportRequestModel {
    /**
     * 
     * @type {string}
     * @memberof ImageSupportRequestModel
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ImageSupportRequestModel
     */
    fileName?: string;
}

/**
 * 
 * @export
 * @interface Ingredient
 */
export interface Ingredient {
    /**
     * 
     * @type {number}
     * @memberof Ingredient
     */
    cost?: number;
    /**
     * 
     * @type {string}
     * @memberof Ingredient
     */
    measure?: Ingredient.MeasureEnum;
    /**
     * 
     * @type {IngredientProductDto}
     * @memberof Ingredient
     */
    product?: IngredientProductDto;
    /**
     * 
     * @type {string}
     * @memberof Ingredient
     */
    pureMeasure?: Ingredient.PureMeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof Ingredient
     */
    pureQty?: number;
    /**
     * 
     * @type {number}
     * @memberof Ingredient
     */
    usedQty?: number;
    /**
     * 
     * @type {number}
     * @memberof Ingredient
     */
    usedQtyInProductMeasure?: number;
    /**
     * 
     * @type {string}
     * @memberof Ingredient
     */
    wasteMeasure?: Ingredient.WasteMeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof Ingredient
     */
    wasteQty?: number;
}

/**
 * @export
 * @namespace Ingredient
 */
export namespace Ingredient {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        Full = <any> 'full',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        Percentage = <any> 'percentage',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PureMeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        Full = <any> 'full',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        Percentage = <any> 'percentage',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum WasteMeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        Full = <any> 'full',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        Percentage = <any> 'percentage',
        PtUK = <any> 'pt_UK'
    }
}

/**
 * 
 * @export
 * @interface IngredientProductDto
 */
export interface IngredientProductDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof IngredientProductDto
     */
    allergens?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IngredientProductDto
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof IngredientProductDto
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof IngredientProductDto
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof IngredientProductDto
     */
    isDeleted?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof IngredientProductDto
     */
    mayContainAllergens?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof IngredientProductDto
     */
    measure?: IngredientProductDto.MeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof IngredientProductDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof IngredientProductDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof IngredientProductDto
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof IngredientProductDto
     */
    subCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof IngredientProductDto
     */
    supplierId?: string;
    /**
     * 
     * @type {string}
     * @memberof IngredientProductDto
     */
    supplierName?: string;
    /**
     * 
     * @type {string}
     * @memberof IngredientProductDto
     */
    unit?: IngredientProductDto.UnitEnum;
}

/**
 * @export
 * @namespace IngredientProductDto
 */
export namespace IngredientProductDto {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum UnitEnum {
        Bottle = <any> 'Bottle',
        Can = <any> 'Can',
        Keg = <any> 'Keg',
        Other = <any> 'Other'
    }
}

/**
 * 
 * @export
 * @interface Integrations
 */
export interface Integrations {
    /**
     * 
     * @type {Array<GenericAccountingIntegration>}
     * @memberof Integrations
     */
    accountingIntegrations?: Array<GenericAccountingIntegration>;
    /**
     * 
     * @type {Array<GenericPosIntegration>}
     * @memberof Integrations
     */
    posIntegrations?: Array<GenericPosIntegration>;
}

/**
 * 
 * @export
 * @interface Invoice
 */
export interface Invoice {
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    approvedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    dateOfIssue?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    dateOfScanning?: Date;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    deliveryNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    deliveryNoteNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    deltaInvoicedExpectedTotalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    deltaTotalCost?: number;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    expectedDeliveryNoteNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    expectedPo?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    expectedTotalCost?: number;
    /**
     * 
     * @type {InvoiceGrowyzeFile}
     * @memberof Invoice
     */
    extractedFile?: InvoiceGrowyzeFile;
    /**
     * 
     * @type {Array<InvoiceGrowyzeFile>}
     * @memberof Invoice
     */
    files?: Array<InvoiceGrowyzeFile>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Invoice
     */
    globalDiscrepancies?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    grossTotalCost?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    hasNoInvoicedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    hasNoOrderedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    hasNoReceivedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    hasProductPriceDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    hasQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Invoice
     */
    hasTotalCostDiscrepancy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    inQueryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    invoicedTotalCost?: number;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    messageQueryToSupplier?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Invoice
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    po?: string;
    /**
     * 
     * @type {Array<InvoiceProduct>}
     * @memberof Invoice
     */
    products?: Array<InvoiceProduct>;
    /**
     * 
     * @type {Date}
     * @memberof Invoice
     */
    rejectedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Invoice
     */
    status?: Invoice.StatusEnum;
    /**
     * 
     * @type {InvoiceSupplier}
     * @memberof Invoice
     */
    supplier?: InvoiceSupplier;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof Invoice
     */
    totalVat?: number;
    /**
     * 
     * @type {XeroInvoice}
     * @memberof Invoice
     */
    xeroInvoice?: XeroInvoice;
}

/**
 * @export
 * @namespace Invoice
 */
export namespace Invoice {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        APPROVED = <any> 'APPROVED',
        DRAFT = <any> 'DRAFT',
        INQUERY = <any> 'IN_QUERY',
        REJECTED = <any> 'REJECTED',
        REVIEW = <any> 'REVIEW'
    }
}

/**
 * 
 * @export
 * @interface InvoiceContractFitDTO
 */
export interface InvoiceContractFitDTO {
    /**
     * 
     * @type {InvoiceContractFitDTOData}
     * @memberof InvoiceContractFitDTO
     */
    data?: InvoiceContractFitDTOData;
    /**
     * 
     * @type {string}
     * @memberof InvoiceContractFitDTO
     */
    event?: string;
}

/**
 * 
 * @export
 * @interface InvoiceContractFitDTOAnnotations
 */
export interface InvoiceContractFitDTOAnnotations {
    /**
     * 
     * @type {Array<InvoiceContractFitDTOItemProperty>}
     * @memberof InvoiceContractFitDTOAnnotations
     */
    accountNumber?: Array<InvoiceContractFitDTOItemProperty>;
    /**
     * 
     * @type {Array<InvoiceContractFitDTOItemProperty>}
     * @memberof InvoiceContractFitDTOAnnotations
     */
    dnNumber?: Array<InvoiceContractFitDTOItemProperty>;
    /**
     * 
     * @type {Array<InvoiceContractFitDTOItemProperty>}
     * @memberof InvoiceContractFitDTOAnnotations
     */
    dueDate?: Array<InvoiceContractFitDTOItemProperty>;
    /**
     * 
     * @type {Array<InvoiceContractFitDTOItemProperty>}
     * @memberof InvoiceContractFitDTOAnnotations
     */
    emailSubject?: Array<InvoiceContractFitDTOItemProperty>;
    /**
     * 
     * @type {Array<InvoiceContractFitDTOItemProperty>}
     * @memberof InvoiceContractFitDTOAnnotations
     */
    grossAmount?: Array<InvoiceContractFitDTOItemProperty>;
    /**
     * 
     * @type {Array<InvoiceContractFitDTOItemProperty>}
     * @memberof InvoiceContractFitDTOAnnotations
     */
    invoiceDate?: Array<InvoiceContractFitDTOItemProperty>;
    /**
     * 
     * @type {Array<InvoiceContractFitDTOItemProperty>}
     * @memberof InvoiceContractFitDTOAnnotations
     */
    invoiceNumber?: Array<InvoiceContractFitDTOItemProperty>;
    /**
     * 
     * @type {Array<InvoiceContractFitDTOItemProperty>}
     * @memberof InvoiceContractFitDTOAnnotations
     */
    netAmount?: Array<InvoiceContractFitDTOItemProperty>;
    /**
     * 
     * @type {Array<InvoiceContractFitDTOItemProperty>}
     * @memberof InvoiceContractFitDTOAnnotations
     */
    orderNumber?: Array<InvoiceContractFitDTOItemProperty>;
    /**
     * 
     * @type {Array<InvoiceContractFitDTOItemProperty>}
     * @memberof InvoiceContractFitDTOAnnotations
     */
    receiverEmail?: Array<InvoiceContractFitDTOItemProperty>;
    /**
     * 
     * @type {Array<InvoiceContractFitDTOItemProperty>}
     * @memberof InvoiceContractFitDTOAnnotations
     */
    senderVAT?: Array<InvoiceContractFitDTOItemProperty>;
    /**
     * 
     * @type {Array<InvoiceContractFitDTOItemProperty>}
     * @memberof InvoiceContractFitDTOAnnotations
     */
    senderEmail?: Array<InvoiceContractFitDTOItemProperty>;
    /**
     * 
     * @type {Array<InvoiceContractFitDTOItemProperty>}
     * @memberof InvoiceContractFitDTOAnnotations
     */
    senderName?: Array<InvoiceContractFitDTOItemProperty>;
    /**
     * 
     * @type {Array<InvoiceContractFitDTOItemProperty>}
     * @memberof InvoiceContractFitDTOAnnotations
     */
    taxAmount?: Array<InvoiceContractFitDTOItemProperty>;
}

/**
 * 
 * @export
 * @interface InvoiceContractFitDTOData
 */
export interface InvoiceContractFitDTOData {
    /**
     * 
     * @type {InvoiceContractFitDTOFeedback}
     * @memberof InvoiceContractFitDTOData
     */
    feedback?: InvoiceContractFitDTOFeedback;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceContractFitDTOData
     */
    flagForReview?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceContractFitDTOData
     */
    id?: string;
    /**
     * 
     * @type {InvoiceContractFitDTOPrediction}
     * @memberof InvoiceContractFitDTOData
     */
    prediction?: InvoiceContractFitDTOPrediction;
}

/**
 * 
 * @export
 * @interface InvoiceContractFitDTOFeedback
 */
export interface InvoiceContractFitDTOFeedback {
    /**
     * 
     * @type {InvoiceContractFitDTOAnnotations}
     * @memberof InvoiceContractFitDTOFeedback
     */
    annotations?: InvoiceContractFitDTOAnnotations;
    /**
     * 
     * @type {Array<InvoiceContractFitDTOLineElement>}
     * @memberof InvoiceContractFitDTOFeedback
     */
    lines?: Array<InvoiceContractFitDTOLineElement>;
}

/**
 * 
 * @export
 * @interface InvoiceContractFitDTOItemProperty
 */
export interface InvoiceContractFitDTOItemProperty {
    /**
     * 
     * @type {string}
     * @memberof InvoiceContractFitDTOItemProperty
     */
    text?: string;
}

/**
 * 
 * @export
 * @interface InvoiceContractFitDTOLineElement
 */
export interface InvoiceContractFitDTOLineElement {
    /**
     * 
     * @type {InvoiceContractFitDTOLineItem}
     * @memberof InvoiceContractFitDTOLineElement
     */
    lineElements?: InvoiceContractFitDTOLineItem;
}

/**
 * 
 * @export
 * @interface InvoiceContractFitDTOLineItem
 */
export interface InvoiceContractFitDTOLineItem {
    /**
     * 
     * @type {InvoiceContractFitDTOItemProperty}
     * @memberof InvoiceContractFitDTOLineItem
     */
    description?: InvoiceContractFitDTOItemProperty;
    /**
     * 
     * @type {InvoiceContractFitDTOItemProperty}
     * @memberof InvoiceContractFitDTOLineItem
     */
    quantity?: InvoiceContractFitDTOItemProperty;
    /**
     * 
     * @type {InvoiceContractFitDTOItemProperty}
     * @memberof InvoiceContractFitDTOLineItem
     */
    sellerElementNumber?: InvoiceContractFitDTOItemProperty;
    /**
     * 
     * @type {InvoiceContractFitDTOItemProperty}
     * @memberof InvoiceContractFitDTOLineItem
     */
    totalAmount?: InvoiceContractFitDTOItemProperty;
    /**
     * 
     * @type {InvoiceContractFitDTOItemProperty}
     * @memberof InvoiceContractFitDTOLineItem
     */
    unit?: InvoiceContractFitDTOItemProperty;
    /**
     * 
     * @type {InvoiceContractFitDTOItemProperty}
     * @memberof InvoiceContractFitDTOLineItem
     */
    unitPrice?: InvoiceContractFitDTOItemProperty;
}

/**
 * 
 * @export
 * @interface InvoiceContractFitDTOLines
 */
export interface InvoiceContractFitDTOLines {
    /**
     * 
     * @type {Array<InvoiceContractFitDTOLineItem>}
     * @memberof InvoiceContractFitDTOLines
     */
    lineItems?: Array<InvoiceContractFitDTOLineItem>;
}

/**
 * 
 * @export
 * @interface InvoiceContractFitDTOPrediction
 */
export interface InvoiceContractFitDTOPrediction {
    /**
     * 
     * @type {InvoiceContractFitDTOAnnotations}
     * @memberof InvoiceContractFitDTOPrediction
     */
    annotations?: InvoiceContractFitDTOAnnotations;
    /**
     * 
     * @type {InvoiceContractFitDTOLines}
     * @memberof InvoiceContractFitDTOPrediction
     */
    lines?: InvoiceContractFitDTOLines;
}

/**
 * 
 * @export
 * @interface InvoiceCreateDto
 */
export interface InvoiceCreateDto {
    /**
     * 
     * @type {Date}
     * @memberof InvoiceCreateDto
     */
    approvedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceCreateDto
     */
    dateOfIssue?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceCreateDto
     */
    dateOfScanning?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    deliveryNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    deliveryNoteNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCreateDto
     */
    deltaInvoicedExpectedTotalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCreateDto
     */
    deltaTotalCost?: number;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceCreateDto
     */
    dueDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCreateDto
     */
    expectedTotalCost?: number;
    /**
     * 
     * @type {InvoiceGrowyzeFile}
     * @memberof InvoiceCreateDto
     */
    extractedFile?: InvoiceGrowyzeFile;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceCreateDto
     */
    globalDiscrepancies?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCreateDto
     */
    grossTotalCost?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceCreateDto
     */
    hasNoInvoicedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceCreateDto
     */
    hasNoOrderedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceCreateDto
     */
    hasNoReceivedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceCreateDto
     */
    hasProductPriceDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceCreateDto
     */
    hasQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceCreateDto
     */
    hasTotalCostDiscrepancy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceCreateDto
     */
    inQueryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCreateDto
     */
    invoicedTotalCost?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    messageQueryToSupplier?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceCreateDto
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    po?: string;
    /**
     * 
     * @type {Array<InvoiceProduct>}
     * @memberof InvoiceCreateDto
     */
    products?: Array<InvoiceProduct>;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceCreateDto
     */
    rejectedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceCreateDto
     */
    status?: InvoiceCreateDto.StatusEnum;
    /**
     * 
     * @type {InvoiceSupplier}
     * @memberof InvoiceCreateDto
     */
    supplier?: InvoiceSupplier;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCreateDto
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceCreateDto
     */
    totalVat?: number;
}

/**
 * @export
 * @namespace InvoiceCreateDto
 */
export namespace InvoiceCreateDto {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        APPROVED = <any> 'APPROVED',
        DRAFT = <any> 'DRAFT',
        INQUERY = <any> 'IN_QUERY',
        REJECTED = <any> 'REJECTED',
        REVIEW = <any> 'REVIEW'
    }
}

/**
 * 
 * @export
 * @interface InvoiceDiscrepanciesResponseDto
 */
export interface InvoiceDiscrepanciesResponseDto {
    /**
     * 
     * @type {Array<InvoiceDiscrepanciesResponseDtoItemDiscrepancy>}
     * @memberof InvoiceDiscrepanciesResponseDto
     */
    itemsDiscrepancies?: Array<InvoiceDiscrepanciesResponseDtoItemDiscrepancy>;
}

/**
 * 
 * @export
 * @interface InvoiceDiscrepanciesResponseDtoItemDiscrepancy
 */
export interface InvoiceDiscrepanciesResponseDtoItemDiscrepancy {
    /**
     * 
     * @type {string}
     * @memberof InvoiceDiscrepanciesResponseDtoItemDiscrepancy
     */
    id?: string;
    /**
     * 
     * @type {InvoiceDiscrepanciesResponseDtoLineItem}
     * @memberof InvoiceDiscrepanciesResponseDtoItemDiscrepancy
     */
    lineItem?: InvoiceDiscrepanciesResponseDtoLineItem;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDiscrepanciesResponseDtoItemDiscrepancy
     */
    status?: InvoiceDiscrepanciesResponseDtoItemDiscrepancy.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDiscrepanciesResponseDtoItemDiscrepancy
     */
    type?: InvoiceDiscrepanciesResponseDtoItemDiscrepancy.TypeEnum;
}

/**
 * @export
 * @namespace InvoiceDiscrepanciesResponseDtoItemDiscrepancy
 */
export namespace InvoiceDiscrepanciesResponseDtoItemDiscrepancy {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        PENDING = <any> 'PENDING',
        RESOLVED = <any> 'RESOLVED'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        CASESIZEUNKNOWN = <any> 'CASE_SIZE_UNKNOWN',
        MISSINGPRODUCT = <any> 'MISSING_PRODUCT',
        MISSINGSKUCODE = <any> 'MISSING_SKU_CODE',
        MISSINGUNIT = <any> 'MISSING_UNIT',
        PRICEUNKNOWN = <any> 'PRICE_UNKNOWN',
        QTYUNKNOWN = <any> 'QTY_UNKNOWN'
    }
}

/**
 * 
 * @export
 * @interface InvoiceDiscrepanciesResponseDtoLineItem
 */
export interface InvoiceDiscrepanciesResponseDtoLineItem {
    /**
     * 
     * @type {string}
     * @memberof InvoiceDiscrepanciesResponseDtoLineItem
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDiscrepanciesResponseDtoLineItem
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDiscrepanciesResponseDtoLineItem
     */
    price?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDiscrepanciesResponseDtoLineItem
     */
    qty?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceDiscrepanciesResponseDtoLineItem
     */
    unit?: string;
}

/**
 * 
 * @export
 * @interface InvoiceDiscrepancyPatchDto
 */
export interface InvoiceDiscrepancyPatchDto {
    /**
     * 
     * @type {string}
     * @memberof InvoiceDiscrepancyPatchDto
     */
    status?: InvoiceDiscrepancyPatchDto.StatusEnum;
}

/**
 * @export
 * @namespace InvoiceDiscrepancyPatchDto
 */
export namespace InvoiceDiscrepancyPatchDto {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        PENDING = <any> 'PENDING',
        RESOLVED = <any> 'RESOLVED'
    }
}

/**
 * 
 * @export
 * @interface InvoiceFilesDto
 */
export interface InvoiceFilesDto {
    /**
     * 
     * @type {Array<InvoiceGrowyzeFile>}
     * @memberof InvoiceFilesDto
     */
    files?: Array<InvoiceGrowyzeFile>;
}

/**
 * 
 * @export
 * @interface InvoiceGrowyzeFile
 */
export interface InvoiceGrowyzeFile {
    /**
     * 
     * @type {Date}
     * @memberof InvoiceGrowyzeFile
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceGrowyzeFile
     */
    fileId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceGrowyzeFile
     */
    fileName?: string;
}

/**
 * 
 * @export
 * @interface InvoiceProcessingResult
 */
export interface InvoiceProcessingResult {
    /**
     * 
     * @type {Date}
     * @memberof InvoiceProcessingResult
     */
    dateOfIssue?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResult
     */
    deliveryNoteNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResult
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {Array<InvoiceProcessingResultItem>}
     * @memberof InvoiceProcessingResult
     */
    items?: Array<InvoiceProcessingResultItem>;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResult
     */
    poNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResult
     */
    status?: string;
    /**
     * 
     * @type {InvoiceProcessingResultSupplier}
     * @memberof InvoiceProcessingResult
     */
    supplier?: InvoiceProcessingResultSupplier;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProcessingResult
     */
    totalCost?: number;
}

/**
 * 
 * @export
 * @interface InvoiceProcessingResultItem
 */
export interface InvoiceProcessingResultItem {
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResultItem
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResultItem
     */
    desc?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProcessingResultItem
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProcessingResultItem
     */
    qty?: number;
}

/**
 * 
 * @export
 * @interface InvoiceProcessingResultSupplier
 */
export interface InvoiceProcessingResultSupplier {
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResultSupplier
     */
    address?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResultSupplier
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResultSupplier
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResultSupplier
     */
    telephone?: string;
}

/**
 * 
 * @export
 * @interface InvoiceProcessingResultWithFile
 */
export interface InvoiceProcessingResultWithFile {
    /**
     * 
     * @type {Date}
     * @memberof InvoiceProcessingResultWithFile
     */
    dateOfIssue?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResultWithFile
     */
    deliveryNoteNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResultWithFile
     */
    fileId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResultWithFile
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResultWithFile
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {Array<InvoiceProcessingResultItem>}
     * @memberof InvoiceProcessingResultWithFile
     */
    items?: Array<InvoiceProcessingResultItem>;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResultWithFile
     */
    poNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProcessingResultWithFile
     */
    status?: string;
    /**
     * 
     * @type {InvoiceProcessingResultSupplier}
     * @memberof InvoiceProcessingResultWithFile
     */
    supplier?: InvoiceProcessingResultSupplier;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProcessingResultWithFile
     */
    totalCost?: number;
}

/**
 * 
 * @export
 * @interface InvoiceProduct
 */
export interface InvoiceProduct {
    /**
     * 
     * @type {string}
     * @memberof InvoiceProduct
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProduct
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProduct
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProduct
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProduct
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProduct
     */
    dnReceivedQty?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProduct
     */
    expectedTotalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProduct
     */
    invoicePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProduct
     */
    invoiceQty?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceProduct
     */
    invoiceQtyInCase?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProduct
     */
    invoicedTotalCost?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceProduct
     */
    isAcceptedPrice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceProduct
     */
    isConfirmed?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceProduct
     */
    isDuplicated?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProduct
     */
    measure?: InvoiceProduct.MeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProduct
     */
    orderCaseSize?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProduct
     */
    orderPrice?: number;
    /**
     * 
     * @type {InvoiceProductDiscrepancies}
     * @memberof InvoiceProduct
     */
    productDiscrepancies?: InvoiceProductDiscrepancies;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceProduct
     */
    receivedQtyInCase?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProduct
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProduct
     */
    subCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceProduct
     */
    unit?: InvoiceProduct.UnitEnum;
    /**
     * 
     * @type {InvoiceXeroProduct}
     * @memberof InvoiceProduct
     */
    xero?: InvoiceXeroProduct;
}

/**
 * @export
 * @namespace InvoiceProduct
 */
export namespace InvoiceProduct {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum UnitEnum {
        Bottle = <any> 'Bottle',
        Can = <any> 'Can',
        Keg = <any> 'Keg',
        Other = <any> 'Other'
    }
}

/**
 * 
 * @export
 * @interface InvoiceProductDiscrepancies
 */
export interface InvoiceProductDiscrepancies {
    /**
     * 
     * @type {number}
     * @memberof InvoiceProductDiscrepancies
     */
    deltaInvoicedExpectedTotalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProductDiscrepancies
     */
    deltaPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceProductDiscrepancies
     */
    deltaQty?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceProductDiscrepancies
     */
    notOrdered?: boolean;
}

/**
 * 
 * @export
 * @interface InvoiceSupplier
 */
export interface InvoiceSupplier {
    /**
     * 
     * @type {string}
     * @memberof InvoiceSupplier
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSupplier
     */
    currency?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSupplier
     */
    email?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceSupplier
     */
    emails?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSupplier
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceSupplier
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface InvoiceUpdateDto
 */
export interface InvoiceUpdateDto {
    /**
     * 
     * @type {Date}
     * @memberof InvoiceUpdateDto
     */
    approvedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceUpdateDto
     */
    dateOfIssue?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceUpdateDto
     */
    dateOfScanning?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    deliveryNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    deliveryNoteNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceUpdateDto
     */
    deltaInvoicedExpectedTotalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceUpdateDto
     */
    deltaTotalCost?: number;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceUpdateDto
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    expectedDeliveryNoteNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    expectedPo?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceUpdateDto
     */
    expectedTotalCost?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceUpdateDto
     */
    globalDiscrepancies?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof InvoiceUpdateDto
     */
    grossTotalCost?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceUpdateDto
     */
    hasNoInvoicedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceUpdateDto
     */
    hasNoOrderedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceUpdateDto
     */
    hasNoReceivedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceUpdateDto
     */
    hasProductPriceDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceUpdateDto
     */
    hasQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceUpdateDto
     */
    hasTotalCostDiscrepancy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceUpdateDto
     */
    inQueryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceUpdateDto
     */
    invoicedTotalCost?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    messageQueryToSupplier?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceUpdateDto
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    po?: string;
    /**
     * 
     * @type {Array<InvoiceProduct>}
     * @memberof InvoiceUpdateDto
     */
    products?: Array<InvoiceProduct>;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceUpdateDto
     */
    rejectedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceUpdateDto
     */
    status?: InvoiceUpdateDto.StatusEnum;
    /**
     * 
     * @type {InvoiceSupplier}
     * @memberof InvoiceUpdateDto
     */
    supplier?: InvoiceSupplier;
    /**
     * 
     * @type {number}
     * @memberof InvoiceUpdateDto
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceUpdateDto
     */
    totalVat?: number;
}

/**
 * @export
 * @namespace InvoiceUpdateDto
 */
export namespace InvoiceUpdateDto {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        APPROVED = <any> 'APPROVED',
        DRAFT = <any> 'DRAFT',
        INQUERY = <any> 'IN_QUERY',
        REJECTED = <any> 'REJECTED',
        REVIEW = <any> 'REVIEW'
    }
}

/**
 * 
 * @export
 * @interface InvoiceWithOrgsNames
 */
export interface InvoiceWithOrgsNames {
    /**
     * 
     * @type {Date}
     * @memberof InvoiceWithOrgsNames
     */
    approvedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceWithOrgsNames
     */
    dateOfIssue?: Date;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceWithOrgsNames
     */
    dateOfScanning?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceWithOrgsNames
     */
    deliveryNoteId?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceWithOrgsNames
     */
    deliveryNoteNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceWithOrgsNames
     */
    deltaInvoicedExpectedTotalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceWithOrgsNames
     */
    deltaTotalCost?: number;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceWithOrgsNames
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceWithOrgsNames
     */
    expectedDeliveryNoteNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceWithOrgsNames
     */
    expectedPo?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceWithOrgsNames
     */
    expectedTotalCost?: number;
    /**
     * 
     * @type {InvoiceGrowyzeFile}
     * @memberof InvoiceWithOrgsNames
     */
    extractedFile?: InvoiceGrowyzeFile;
    /**
     * 
     * @type {Array<InvoiceGrowyzeFile>}
     * @memberof InvoiceWithOrgsNames
     */
    files?: Array<InvoiceGrowyzeFile>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceWithOrgsNames
     */
    globalDiscrepancies?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof InvoiceWithOrgsNames
     */
    grossTotalCost?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceWithOrgsNames
     */
    hasNoInvoicedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceWithOrgsNames
     */
    hasNoOrderedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceWithOrgsNames
     */
    hasNoReceivedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceWithOrgsNames
     */
    hasProductPriceDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceWithOrgsNames
     */
    hasQtyDiscrepancies?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InvoiceWithOrgsNames
     */
    hasTotalCostDiscrepancy?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InvoiceWithOrgsNames
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceWithOrgsNames
     */
    inQueryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceWithOrgsNames
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoiceWithOrgsNames
     */
    invoicedTotalCost?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoiceWithOrgsNames
     */
    message?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoiceWithOrgsNames
     */
    messageQueryToSupplier?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceWithOrgsNames
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoiceWithOrgsNames
     */
    organizationsNames?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof InvoiceWithOrgsNames
     */
    po?: string;
    /**
     * 
     * @type {Array<InvoiceProduct>}
     * @memberof InvoiceWithOrgsNames
     */
    products?: Array<InvoiceProduct>;
    /**
     * 
     * @type {Date}
     * @memberof InvoiceWithOrgsNames
     */
    rejectedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof InvoiceWithOrgsNames
     */
    status?: InvoiceWithOrgsNames.StatusEnum;
    /**
     * 
     * @type {InvoiceSupplier}
     * @memberof InvoiceWithOrgsNames
     */
    supplier?: InvoiceSupplier;
    /**
     * 
     * @type {number}
     * @memberof InvoiceWithOrgsNames
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoiceWithOrgsNames
     */
    totalVat?: number;
    /**
     * 
     * @type {XeroInvoice}
     * @memberof InvoiceWithOrgsNames
     */
    xeroInvoice?: XeroInvoice;
}

/**
 * @export
 * @namespace InvoiceWithOrgsNames
 */
export namespace InvoiceWithOrgsNames {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        APPROVED = <any> 'APPROVED',
        DRAFT = <any> 'DRAFT',
        INQUERY = <any> 'IN_QUERY',
        REJECTED = <any> 'REJECTED',
        REVIEW = <any> 'REVIEW'
    }
}

/**
 * 
 * @export
 * @interface InvoiceXeroProduct
 */
export interface InvoiceXeroProduct {
    /**
     * 
     * @type {XeroAccount}
     * @memberof InvoiceXeroProduct
     */
    account?: XeroAccount;
}

/**
 * 
 * @export
 * @interface InvoicedProductDto
 */
export interface InvoicedProductDto {
    /**
     * 
     * @type {string}
     * @memberof InvoicedProductDto
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoicedProductDto
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoicedProductDto
     */
    measure?: InvoicedProductDto.MeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof InvoicedProductDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoicedProductDto
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof InvoicedProductDto
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoicedProductDto
     */
    subCategory?: string;
}

/**
 * @export
 * @namespace InvoicedProductDto
 */
export namespace InvoicedProductDto {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
}

/**
 * 
 * @export
 * @interface InvoicedProductSortedDto
 */
export interface InvoicedProductSortedDto {
    /**
     * 
     * @type {string}
     * @memberof InvoicedProductSortedDto
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoicedProductSortedDto
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof InvoicedProductSortedDto
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof InvoicedProductSortedDto
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof InvoicedProductSortedDto
     */
    subCategory?: string;
}

/**
 * 
 * @export
 * @interface InvoicesContacts
 */
export interface InvoicesContacts {
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoicesContacts
     */
    approvedEmails?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoicesContacts
     */
    queriedEmails?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof InvoicesContacts
     */
    rejectedEmails?: Array<string>;
}

/**
 * 
 * @export
 * @interface ItemProperty
 */
export interface ItemProperty {
    /**
     * 
     * @type {string}
     * @memberof ItemProperty
     */
    text?: string;
}

/**
 * 
 * @export
 * @interface LightspeedGetBusinessesResponse
 */
export interface LightspeedGetBusinessesResponse {
    /**
     * 
     * @type {Array<Business>}
     * @memberof LightspeedGetBusinessesResponse
     */
    embedded?: Array<Business>;
}

/**
 * 
 * @export
 * @interface LightspeedPosIntegration
 */
export interface LightspeedPosIntegration {
    /**
     * 
     * @type {BusinessLocation}
     * @memberof LightspeedPosIntegration
     */
    businessLocation?: BusinessLocation;
    /**
     * 
     * @type {string}
     * @memberof LightspeedPosIntegration
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof LightspeedPosIntegration
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LightspeedPosIntegration
     */
    organizations?: Array<string>;
}

/**
 * 
 * @export
 * @interface LightspeedPosIntegrationCreatePayload
 */
export interface LightspeedPosIntegrationCreatePayload {
    /**
     * 
     * @type {string}
     * @memberof LightspeedPosIntegrationCreatePayload
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof LightspeedPosIntegrationCreatePayload
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof LightspeedPosIntegrationCreatePayload
     */
    organizations?: Array<string>;
}

/**
 * 
 * @export
 * @interface LineElement
 */
export interface LineElement {
    /**
     * 
     * @type {LineItem}
     * @memberof LineElement
     */
    lineElements?: LineItem;
}

/**
 * 
 * @export
 * @interface LineItem
 */
export interface LineItem {
    /**
     * 
     * @type {ItemProperty}
     * @memberof LineItem
     */
    description?: ItemProperty;
    /**
     * 
     * @type {ItemProperty}
     * @memberof LineItem
     */
    quantity?: ItemProperty;
    /**
     * 
     * @type {ItemProperty}
     * @memberof LineItem
     */
    sellerElementNumber?: ItemProperty;
    /**
     * 
     * @type {ItemProperty}
     * @memberof LineItem
     */
    unit?: ItemProperty;
    /**
     * 
     * @type {ItemProperty}
     * @memberof LineItem
     */
    unitPrice?: ItemProperty;
}

/**
 * 
 * @export
 * @interface Lines
 */
export interface Lines {
    /**
     * 
     * @type {Array<LineItem>}
     * @memberof Lines
     */
    lineItems?: Array<LineItem>;
}

/**
 * 
 * @export
 * @interface Link
 */
export interface Link {
    /**
     * 
     * @type {string}
     * @memberof Link
     */
    href?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Link
     */
    templated?: boolean;
}

/**
 * 
 * @export
 * @interface LinkRelation
 */
export interface LinkRelation {
}

/**
 * 
 * @export
 * @interface Links
 */
export interface Links {
    /**
     * 
     * @type {boolean}
     * @memberof Links
     */
    empty?: boolean;
}

/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface LocationsDto
 */
export interface LocationsDto {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof LocationsDto
     */
    locationIdToLocationName?: { [key: string]: string; };
}

/**
 * 
 * @export
 * @interface MainCategorisedProducts
 */
export interface MainCategorisedProducts {
    /**
     * 
     * @type {Array<CategorisedProducts>}
     * @memberof MainCategorisedProducts
     */
    categorisedProducts?: Array<CategorisedProducts>;
    /**
     * 
     * @type {CategoryTotals}
     * @memberof MainCategorisedProducts
     */
    categoryTotals?: CategoryTotals;
}

/**
 * 
 * @export
 * @interface Merchant
 */
export interface Merchant {
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Merchant
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface MergeGroupsPayload
 */
export interface MergeGroupsPayload {
    /**
     * 
     * @type {Array<string>}
     * @memberof MergeGroupsPayload
     */
    otherGroupsIds?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof MergeGroupsPayload
     */
    preferredGroupId?: string;
}

/**
 * 
 * @export
 * @interface Message
 */
export interface Message {
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    text: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    type?: Message.TypeEnum;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    urlToDocument?: string;
    /**
     * 
     * @type {string}
     * @memberof Message
     */
    userFullName?: string;
}

/**
 * @export
 * @namespace Message
 */
export namespace Message {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        DISCREPANCYREPORT = <any> 'DISCREPANCY_REPORT',
        DN = <any> 'DN',
        INVOICE = <any> 'INVOICE',
        ORDER = <any> 'ORDER',
        SALES = <any> 'SALES',
        STOCKTAKE = <any> 'STOCK_TAKE',
        WASTE = <any> 'WASTE'
    }
}

/**
 * 
 * @export
 * @interface MinOrderConfig
 */
export interface MinOrderConfig {
    /**
     * 
     * @type {number}
     * @memberof MinOrderConfig
     */
    minOrderValue?: number;
}

/**
 * 
 * @export
 * @interface ModelAndView
 */
export interface ModelAndView {
    /**
     * 
     * @type {boolean}
     * @memberof ModelAndView
     */
    empty?: boolean;
    /**
     * 
     * @type {any}
     * @memberof ModelAndView
     */
    model?: any;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof ModelAndView
     */
    modelMap?: { [key: string]: any; };
    /**
     * 
     * @type {boolean}
     * @memberof ModelAndView
     */
    reference?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelAndView
     */
    status?: ModelAndView.StatusEnum;
    /**
     * 
     * @type {View}
     * @memberof ModelAndView
     */
    view?: View;
    /**
     * 
     * @type {string}
     * @memberof ModelAndView
     */
    viewName?: string;
}

/**
 * @export
 * @namespace ModelAndView
 */
export namespace ModelAndView {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        ACCEPTED = <any> 'ACCEPTED',
        ALREADYREPORTED = <any> 'ALREADY_REPORTED',
        BADGATEWAY = <any> 'BAD_GATEWAY',
        BADREQUEST = <any> 'BAD_REQUEST',
        BANDWIDTHLIMITEXCEEDED = <any> 'BANDWIDTH_LIMIT_EXCEEDED',
        CHECKPOINT = <any> 'CHECKPOINT',
        CONFLICT = <any> 'CONFLICT',
        CONTINUE = <any> 'CONTINUE',
        CREATED = <any> 'CREATED',
        DESTINATIONLOCKED = <any> 'DESTINATION_LOCKED',
        EXPECTATIONFAILED = <any> 'EXPECTATION_FAILED',
        FAILEDDEPENDENCY = <any> 'FAILED_DEPENDENCY',
        FORBIDDEN = <any> 'FORBIDDEN',
        FOUND = <any> 'FOUND',
        GATEWAYTIMEOUT = <any> 'GATEWAY_TIMEOUT',
        GONE = <any> 'GONE',
        HTTPVERSIONNOTSUPPORTED = <any> 'HTTP_VERSION_NOT_SUPPORTED',
        IMUSED = <any> 'IM_USED',
        INSUFFICIENTSPACEONRESOURCE = <any> 'INSUFFICIENT_SPACE_ON_RESOURCE',
        INSUFFICIENTSTORAGE = <any> 'INSUFFICIENT_STORAGE',
        INTERNALSERVERERROR = <any> 'INTERNAL_SERVER_ERROR',
        IAMATEAPOT = <any> 'I_AM_A_TEAPOT',
        LENGTHREQUIRED = <any> 'LENGTH_REQUIRED',
        LOCKED = <any> 'LOCKED',
        LOOPDETECTED = <any> 'LOOP_DETECTED',
        METHODFAILURE = <any> 'METHOD_FAILURE',
        METHODNOTALLOWED = <any> 'METHOD_NOT_ALLOWED',
        MOVEDPERMANENTLY = <any> 'MOVED_PERMANENTLY',
        MOVEDTEMPORARILY = <any> 'MOVED_TEMPORARILY',
        MULTIPLECHOICES = <any> 'MULTIPLE_CHOICES',
        MULTISTATUS = <any> 'MULTI_STATUS',
        NETWORKAUTHENTICATIONREQUIRED = <any> 'NETWORK_AUTHENTICATION_REQUIRED',
        NONAUTHORITATIVEINFORMATION = <any> 'NON_AUTHORITATIVE_INFORMATION',
        NOTACCEPTABLE = <any> 'NOT_ACCEPTABLE',
        NOTEXTENDED = <any> 'NOT_EXTENDED',
        NOTFOUND = <any> 'NOT_FOUND',
        NOTIMPLEMENTED = <any> 'NOT_IMPLEMENTED',
        NOTMODIFIED = <any> 'NOT_MODIFIED',
        NOCONTENT = <any> 'NO_CONTENT',
        OK = <any> 'OK',
        PARTIALCONTENT = <any> 'PARTIAL_CONTENT',
        PAYLOADTOOLARGE = <any> 'PAYLOAD_TOO_LARGE',
        PAYMENTREQUIRED = <any> 'PAYMENT_REQUIRED',
        PERMANENTREDIRECT = <any> 'PERMANENT_REDIRECT',
        PRECONDITIONFAILED = <any> 'PRECONDITION_FAILED',
        PRECONDITIONREQUIRED = <any> 'PRECONDITION_REQUIRED',
        PROCESSING = <any> 'PROCESSING',
        PROXYAUTHENTICATIONREQUIRED = <any> 'PROXY_AUTHENTICATION_REQUIRED',
        REQUESTEDRANGENOTSATISFIABLE = <any> 'REQUESTED_RANGE_NOT_SATISFIABLE',
        REQUESTENTITYTOOLARGE = <any> 'REQUEST_ENTITY_TOO_LARGE',
        REQUESTHEADERFIELDSTOOLARGE = <any> 'REQUEST_HEADER_FIELDS_TOO_LARGE',
        REQUESTTIMEOUT = <any> 'REQUEST_TIMEOUT',
        REQUESTURITOOLONG = <any> 'REQUEST_URI_TOO_LONG',
        RESETCONTENT = <any> 'RESET_CONTENT',
        SEEOTHER = <any> 'SEE_OTHER',
        SERVICEUNAVAILABLE = <any> 'SERVICE_UNAVAILABLE',
        SWITCHINGPROTOCOLS = <any> 'SWITCHING_PROTOCOLS',
        TEMPORARYREDIRECT = <any> 'TEMPORARY_REDIRECT',
        TOOEARLY = <any> 'TOO_EARLY',
        TOOMANYREQUESTS = <any> 'TOO_MANY_REQUESTS',
        UNAUTHORIZED = <any> 'UNAUTHORIZED',
        UNAVAILABLEFORLEGALREASONS = <any> 'UNAVAILABLE_FOR_LEGAL_REASONS',
        UNPROCESSABLEENTITY = <any> 'UNPROCESSABLE_ENTITY',
        UNSUPPORTEDMEDIATYPE = <any> 'UNSUPPORTED_MEDIA_TYPE',
        UPGRADEREQUIRED = <any> 'UPGRADE_REQUIRED',
        URITOOLONG = <any> 'URI_TOO_LONG',
        USEPROXY = <any> 'USE_PROXY',
        VARIANTALSONEGOTIATES = <any> 'VARIANT_ALSO_NEGOTIATES'
    }
}

/**
 * 
 * @export
 * @interface ModelCase
 */
export interface ModelCase {
    /**
     * 
     * @type {string}
     * @memberof ModelCase
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof ModelCase
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof ModelCase
     */
    size?: number;
}

/**
 * 
 * @export
 * @interface ModelYield
 */
export interface ModelYield {
    /**
     * 
     * @type {string}
     * @memberof ModelYield
     */
    measure?: ModelYield.MeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof ModelYield
     */
    size?: number;
}

/**
 * @export
 * @namespace ModelYield
 */
export namespace ModelYield {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
}

/**
 * 
 * @export
 * @interface NewbridgePosIntegration
 */
export interface NewbridgePosIntegration {
    /**
     * 
     * @type {string}
     * @memberof NewbridgePosIntegration
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof NewbridgePosIntegration
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof NewbridgePosIntegration
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewbridgePosIntegration
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Site}
     * @memberof NewbridgePosIntegration
     */
    site?: Site;
}

/**
 * 
 * @export
 * @interface NewbridgePosIntegrationCreatePayload
 */
export interface NewbridgePosIntegrationCreatePayload {
    /**
     * 
     * @type {string}
     * @memberof NewbridgePosIntegrationCreatePayload
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof NewbridgePosIntegrationCreatePayload
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof NewbridgePosIntegrationCreatePayload
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof NewbridgePosIntegrationCreatePayload
     */
    secret?: string;
    /**
     * 
     * @type {Site}
     * @memberof NewbridgePosIntegrationCreatePayload
     */
    site?: Site;
}

/**
 * 
 * @export
 * @interface Notification
 */
export interface Notification {
    /**
     * 
     * @type {Date}
     * @memberof Notification
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Notification
     */
    id?: string;
    /**
     * 
     * @type {Message}
     * @memberof Notification
     */
    message?: Message;
    /**
     * 
     * @type {Array<string>}
     * @memberof Notification
     */
    organizations?: Array<string>;
}

/**
 * 
 * @export
 * @interface NotificationWithOrgsNames
 */
export interface NotificationWithOrgsNames {
    /**
     * 
     * @type {Date}
     * @memberof NotificationWithOrgsNames
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof NotificationWithOrgsNames
     */
    id?: string;
    /**
     * 
     * @type {Message}
     * @memberof NotificationWithOrgsNames
     */
    message?: Message;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationWithOrgsNames
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof NotificationWithOrgsNames
     */
    organizationsNames?: Array<string>;
}

/**
 * 
 * @export
 * @interface OnboardingOrgSteps
 */
export interface OnboardingOrgSteps {
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingOrgSteps
     */
    hasAddedProducts?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingOrgSteps
     */
    hasAddedRecipe?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingOrgSteps
     */
    hasAddedStocktake?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingOrgSteps
     */
    hasAddedWaste?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OnboardingOrgSteps
     */
    hasCheckedCorrectPricesAndBarcodes?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OnboardingOrgSteps
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OnboardingOrgSteps
     */
    orgId?: string;
}

/**
 * 
 * @export
 * @interface Order
 */
export interface Order {
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    canceledDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    completedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    createdDate?: Date;
    /**
     * 
     * @type {Address}
     * @memberof Order
     */
    deliveryAddress?: Address;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    expectedDeliveryDate?: Date;
    /**
     * 
     * @type {GrowyzeFile}
     * @memberof Order
     */
    extractedFile?: GrowyzeFile;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Order
     */
    isSentToSupplier?: boolean;
    /**
     * 
     * @type {Array<OrderItem>}
     * @memberof Order
     */
    items?: Array<OrderItem>;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    notes?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Order
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof Order
     */
    placedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    po?: string;
    /**
     * 
     * @type {string}
     * @memberof Order
     */
    status?: Order.StatusEnum;
    /**
     * 
     * @type {Supplier}
     * @memberof Order
     */
    supplier?: Supplier;
    /**
     * 
     * @type {number}
     * @memberof Order
     */
    totalCost?: number;
}

/**
 * @export
 * @namespace Order
 */
export namespace Order {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        CANCELED = <any> 'CANCELED',
        COMPLETED = <any> 'COMPLETED',
        DRAFT = <any> 'DRAFT',
        PENDINGAPPROVAL = <any> 'PENDING_APPROVAL',
        PLACED = <any> 'PLACED',
        REVIEW = <any> 'REVIEW'
    }
}

/**
 * 
 * @export
 * @interface OrderApprovals
 */
export interface OrderApprovals {
    /**
     * 
     * @type {number}
     * @memberof OrderApprovals
     */
    aboveTotalValue?: number;
    /**
     * 
     * @type {Array<OrderApprover>}
     * @memberof OrderApprovals
     */
    approvers?: Array<OrderApprover>;
    /**
     * 
     * @type {boolean}
     * @memberof OrderApprovals
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderApprovals
     */
    shouldApproveAllOrders?: boolean;
}

/**
 * 
 * @export
 * @interface OrderApprover
 */
export interface OrderApprover {
    /**
     * 
     * @type {string}
     * @memberof OrderApprover
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderApprover
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderApprover
     */
    username?: string;
}

/**
 * 
 * @export
 * @interface OrderContractFitDTO
 */
export interface OrderContractFitDTO {
    /**
     * 
     * @type {Data}
     * @memberof OrderContractFitDTO
     */
    data?: Data;
    /**
     * 
     * @type {string}
     * @memberof OrderContractFitDTO
     */
    event?: string;
}

/**
 * 
 * @export
 * @interface OrderDiscrepanciesResponseDto
 */
export interface OrderDiscrepanciesResponseDto {
    /**
     * 
     * @type {Array<OrderDiscrepanciesResponseDtoItemDiscrepancy>}
     * @memberof OrderDiscrepanciesResponseDto
     */
    discrepancies?: Array<OrderDiscrepanciesResponseDtoItemDiscrepancy>;
}

/**
 * 
 * @export
 * @interface OrderDiscrepanciesResponseDtoItemDiscrepancy
 */
export interface OrderDiscrepanciesResponseDtoItemDiscrepancy {
    /**
     * 
     * @type {string}
     * @memberof OrderDiscrepanciesResponseDtoItemDiscrepancy
     */
    id?: string;
    /**
     * 
     * @type {OrderDiscrepanciesResponseDtoLineItem}
     * @memberof OrderDiscrepanciesResponseDtoItemDiscrepancy
     */
    lineItem?: OrderDiscrepanciesResponseDtoLineItem;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscrepanciesResponseDtoItemDiscrepancy
     */
    status?: OrderDiscrepanciesResponseDtoItemDiscrepancy.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscrepanciesResponseDtoItemDiscrepancy
     */
    type?: OrderDiscrepanciesResponseDtoItemDiscrepancy.TypeEnum;
}

/**
 * @export
 * @namespace OrderDiscrepanciesResponseDtoItemDiscrepancy
 */
export namespace OrderDiscrepanciesResponseDtoItemDiscrepancy {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        PENDING = <any> 'PENDING',
        RESOLVED = <any> 'RESOLVED'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        CASESIZEUNKNOWN = <any> 'CASE_SIZE_UNKNOWN',
        MISSINGPRODUCT = <any> 'MISSING_PRODUCT',
        MISSINGSKUCODE = <any> 'MISSING_SKU_CODE',
        MISSINGUNIT = <any> 'MISSING_UNIT',
        QTYUNKNOWN = <any> 'QTY_UNKNOWN'
    }
}

/**
 * 
 * @export
 * @interface OrderDiscrepanciesResponseDtoLineItem
 */
export interface OrderDiscrepanciesResponseDtoLineItem {
    /**
     * 
     * @type {string}
     * @memberof OrderDiscrepanciesResponseDtoLineItem
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscrepanciesResponseDtoLineItem
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscrepanciesResponseDtoLineItem
     */
    price?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscrepanciesResponseDtoLineItem
     */
    qty?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderDiscrepanciesResponseDtoLineItem
     */
    unit?: string;
}

/**
 * 
 * @export
 * @interface OrderDiscrepancyPatchDto
 */
export interface OrderDiscrepancyPatchDto {
    /**
     * 
     * @type {string}
     * @memberof OrderDiscrepancyPatchDto
     */
    status: OrderDiscrepancyPatchDto.StatusEnum;
}

/**
 * @export
 * @namespace OrderDiscrepancyPatchDto
 */
export namespace OrderDiscrepancyPatchDto {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        PENDING = <any> 'PENDING',
        RESOLVED = <any> 'RESOLVED'
    }
}

/**
 * 
 * @export
 * @interface OrderForApproval
 */
export interface OrderForApproval {
    /**
     * 
     * @type {Approver}
     * @memberof OrderForApproval
     */
    approver?: Approver;
    /**
     * 
     * @type {Date}
     * @memberof OrderForApproval
     */
    creationDate?: Date;
    /**
     * 
     * @type {Creator}
     * @memberof OrderForApproval
     */
    creator?: Creator;
    /**
     * 
     * @type {string}
     * @memberof OrderForApproval
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderForApproval
     */
    orderId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderForApproval
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof OrderForApproval
     */
    po?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderForApproval
     */
    supplierName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderForApproval
     */
    token?: string;
}

/**
 * 
 * @export
 * @interface OrderItem
 */
export interface OrderItem {
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    code?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    estimatedCost?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    measure?: OrderItem.MeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderItem
     */
    orderInCase?: boolean;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    price?: number;
    /**
     * 
     * @type {ModelCase}
     * @memberof OrderItem
     */
    productCase?: ModelCase;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    productId?: string;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderItem
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    subCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderItem
     */
    unit?: OrderItem.UnitEnum;
}

/**
 * @export
 * @namespace OrderItem
 */
export namespace OrderItem {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum UnitEnum {
        Bottle = <any> 'Bottle',
        Can = <any> 'Can',
        Keg = <any> 'Keg',
        Other = <any> 'Other'
    }
}

/**
 * 
 * @export
 * @interface OrderPatchDto
 */
export interface OrderPatchDto {
    /**
     * 
     * @type {string}
     * @memberof OrderPatchDto
     */
    status?: OrderPatchDto.StatusEnum;
}

/**
 * @export
 * @namespace OrderPatchDto
 */
export namespace OrderPatchDto {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        CANCELED = <any> 'CANCELED',
        COMPLETED = <any> 'COMPLETED',
        PLACED = <any> 'PLACED'
    }
}

/**
 * 
 * @export
 * @interface OrderReminder
 */
export interface OrderReminder {
    /**
     * 
     * @type {string}
     * @memberof OrderReminder
     */
    deliveryDay?: OrderReminder.DeliveryDayEnum;
    /**
     * 
     * @type {boolean}
     * @memberof OrderReminder
     */
    notifyMe?: boolean;
    /**
     * 
     * @type {string}
     * @memberof OrderReminder
     */
    orderDay?: OrderReminder.OrderDayEnum;
    /**
     * 
     * @type {string}
     * @memberof OrderReminder
     */
    orderTillTime?: string;
}

/**
 * @export
 * @namespace OrderReminder
 */
export namespace OrderReminder {
    /**
     * @export
     * @enum {string}
     */
    export enum DeliveryDayEnum {
        FRIDAY = <any> 'FRIDAY',
        MONDAY = <any> 'MONDAY',
        SATURDAY = <any> 'SATURDAY',
        SUNDAY = <any> 'SUNDAY',
        THURSDAY = <any> 'THURSDAY',
        TUESDAY = <any> 'TUESDAY',
        WEDNESDAY = <any> 'WEDNESDAY'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum OrderDayEnum {
        FRIDAY = <any> 'FRIDAY',
        MONDAY = <any> 'MONDAY',
        SATURDAY = <any> 'SATURDAY',
        SUNDAY = <any> 'SUNDAY',
        THURSDAY = <any> 'THURSDAY',
        TUESDAY = <any> 'TUESDAY',
        WEDNESDAY = <any> 'WEDNESDAY'
    }
}

/**
 * 
 * @export
 * @interface OrderResponseDto
 */
export interface OrderResponseDto {
    /**
     * 
     * @type {Array<OrderApprover>}
     * @memberof OrderResponseDto
     */
    approvers?: Array<OrderApprover>;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponseDto
     */
    canceledDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponseDto
     */
    completedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponseDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {Address}
     * @memberof OrderResponseDto
     */
    deliveryAddress?: Address;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponseDto
     */
    expectedDeliveryDate?: Date;
    /**
     * 
     * @type {GrowyzeFile}
     * @memberof OrderResponseDto
     */
    extractedFile?: GrowyzeFile;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrderResponseDto
     */
    isSentToSupplier?: boolean;
    /**
     * 
     * @type {Array<OrderItem>}
     * @memberof OrderResponseDto
     */
    items?: Array<OrderItem>;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    notes?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrderResponseDto
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof OrderResponseDto
     */
    placedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    po?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderResponseDto
     */
    status?: OrderResponseDto.StatusEnum;
    /**
     * 
     * @type {Supplier}
     * @memberof OrderResponseDto
     */
    supplier?: Supplier;
    /**
     * 
     * @type {number}
     * @memberof OrderResponseDto
     */
    totalCost?: number;
}

/**
 * @export
 * @namespace OrderResponseDto
 */
export namespace OrderResponseDto {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        CANCELED = <any> 'CANCELED',
        COMPLETED = <any> 'COMPLETED',
        DRAFT = <any> 'DRAFT',
        PENDINGAPPROVAL = <any> 'PENDING_APPROVAL',
        PLACED = <any> 'PLACED',
        REVIEW = <any> 'REVIEW'
    }
}

/**
 * 
 * @export
 * @interface OrderedIn
 */
export interface OrderedIn {
    /**
     * 
     * @type {boolean}
     * @memberof OrderedIn
     */
    both?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderedIn
     */
    pack?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrderedIn
     */
    single?: boolean;
}

/**
 * 
 * @export
 * @interface OrdersContacts
 */
export interface OrdersContacts {
    /**
     * 
     * @type {Array<string>}
     * @memberof OrdersContacts
     */
    placedEmails?: Array<string>;
}

/**
 * 
 * @export
 * @interface OrdersResponseDto
 */
export interface OrdersResponseDto {
    /**
     * 
     * @type {Array<OrderApprover>}
     * @memberof OrdersResponseDto
     */
    approvers?: Array<OrderApprover>;
    /**
     * 
     * @type {Date}
     * @memberof OrdersResponseDto
     */
    canceledDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrdersResponseDto
     */
    completedDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof OrdersResponseDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {Address}
     * @memberof OrdersResponseDto
     */
    deliveryAddress?: Address;
    /**
     * 
     * @type {Date}
     * @memberof OrdersResponseDto
     */
    expectedDeliveryDate?: Date;
    /**
     * 
     * @type {GrowyzeFile}
     * @memberof OrdersResponseDto
     */
    extractedFile?: GrowyzeFile;
    /**
     * 
     * @type {string}
     * @memberof OrdersResponseDto
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof OrdersResponseDto
     */
    isDelivered?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OrdersResponseDto
     */
    isSentToSupplier?: boolean;
    /**
     * 
     * @type {Array<OrderItem>}
     * @memberof OrdersResponseDto
     */
    items?: Array<OrderItem>;
    /**
     * 
     * @type {string}
     * @memberof OrdersResponseDto
     */
    notes?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrdersResponseDto
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrdersResponseDto
     */
    organizationsNames?: Array<string>;
    /**
     * 
     * @type {Date}
     * @memberof OrdersResponseDto
     */
    placedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof OrdersResponseDto
     */
    po?: string;
    /**
     * 
     * @type {string}
     * @memberof OrdersResponseDto
     */
    status?: OrdersResponseDto.StatusEnum;
    /**
     * 
     * @type {Supplier}
     * @memberof OrdersResponseDto
     */
    supplier?: Supplier;
    /**
     * 
     * @type {number}
     * @memberof OrdersResponseDto
     */
    totalCost?: number;
}

/**
 * @export
 * @namespace OrdersResponseDto
 */
export namespace OrdersResponseDto {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        CANCELED = <any> 'CANCELED',
        COMPLETED = <any> 'COMPLETED',
        DRAFT = <any> 'DRAFT',
        PENDINGAPPROVAL = <any> 'PENDING_APPROVAL',
        PLACED = <any> 'PLACED',
        REVIEW = <any> 'REVIEW'
    }
}

/**
 * 
 * @export
 * @interface OrgAuth
 */
export interface OrgAuth {
    /**
     * 
     * @type {string}
     * @memberof OrgAuth
     */
    orgId?: string;
    /**
     * 
     * @type {Array<Feature>}
     * @memberof OrgAuth
     */
    permissionsPerFeatures?: Array<Feature>;
}

/**
 * 
 * @export
 * @interface Organization
 */
export interface Organization {
    /**
     * 
     * @type {AddressWithZoneId}
     * @memberof Organization
     */
    address?: AddressWithZoneId;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    companyName?: string;
    /**
     * 
     * @type {Address}
     * @memberof Organization
     */
    deliveryAddress?: Address;
    /**
     * 
     * @type {string}
     * @memberof Organization
     */
    id?: string;
}

/**
 * 
 * @export
 * @interface OrganizationDto
 */
export interface OrganizationDto {
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationDto
     */
    type?: OrganizationDto.TypeEnum;
}

/**
 * @export
 * @namespace OrganizationDto
 */
export namespace OrganizationDto {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        DEMO = <any> 'DEMO',
        MAIN = <any> 'MAIN',
        STANDARD = <any> 'STANDARD',
        SUB = <any> 'SUB'
    }
}

/**
 * 
 * @export
 * @interface OrganizationPatchDto
 */
export interface OrganizationPatchDto {
    /**
     * 
     * @type {Address0}
     * @memberof OrganizationPatchDto
     */
    address?: Address0;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPatchDto
     */
    businessType?: OrganizationPatchDto.BusinessTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPatchDto
     */
    companyName?: string;
    /**
     * 
     * @type {ContactDetails}
     * @memberof OrganizationPatchDto
     */
    contactDetails?: ContactDetails;
    /**
     * 
     * @type {Address0}
     * @memberof OrganizationPatchDto
     */
    deliveryAddress?: Address0;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPatchDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPatchDto
     */
    employeesNumberRange?: OrganizationPatchDto.EmployeesNumberRangeEnum;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPatchDto
     */
    logo?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganizationPatchDto
     */
    website?: string;
}

/**
 * @export
 * @namespace OrganizationPatchDto
 */
export namespace OrganizationPatchDto {
    /**
     * @export
     * @enum {string}
     */
    export enum BusinessTypeEnum {
        COFFEESHOP = <any> 'COFFEE_SHOP',
        CONVENIENCESTORE = <any> 'CONVENIENCE_STORE',
        HOTEL = <any> 'HOTEL',
        OTHER = <any> 'OTHER',
        PUBBAR = <any> 'PUB_BAR',
        RESTAURANT = <any> 'RESTAURANT'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum EmployeesNumberRangeEnum {
        ENTERPRISE = <any> 'ENTERPRISE',
        MIDLEVEL = <any> 'MID_LEVEL',
        SMALL = <any> 'SMALL'
    }
}

/**
 * 
 * @export
 * @interface OtherIngredient
 */
export interface OtherIngredient {
    /**
     * 
     * @type {number}
     * @memberof OtherIngredient
     */
    cost?: number;
    /**
     * 
     * @type {string}
     * @memberof OtherIngredient
     */
    measure?: OtherIngredient.MeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof OtherIngredient
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof OtherIngredient
     */
    usedQty?: number;
}

/**
 * @export
 * @namespace OtherIngredient
 */
export namespace OtherIngredient {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
}

/**
 * 
 * @export
 * @interface PageActivityWithOrgsNames
 */
export interface PageActivityWithOrgsNames {
    /**
     * 
     * @type {Array<ActivityWithOrgsNames>}
     * @memberof PageActivityWithOrgsNames
     */
    content?: Array<ActivityWithOrgsNames>;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityWithOrgsNames
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityWithOrgsNames
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageActivityWithOrgsNames
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageActivityWithOrgsNames
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageActivityWithOrgsNames
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageActivityWithOrgsNames
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageActivityWithOrgsNames
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageActivityWithOrgsNames
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageActivityWithOrgsNames
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageActivityWithOrgsNames
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageCOGSReportWithoutProductsDto
 */
export interface PageCOGSReportWithoutProductsDto {
    /**
     * 
     * @type {Array<COGSReportWithoutProductsDto>}
     * @memberof PageCOGSReportWithoutProductsDto
     */
    content?: Array<COGSReportWithoutProductsDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageCOGSReportWithoutProductsDto
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCOGSReportWithoutProductsDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCOGSReportWithoutProductsDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCOGSReportWithoutProductsDto
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCOGSReportWithoutProductsDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageCOGSReportWithoutProductsDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageCOGSReportWithoutProductsDto
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageCOGSReportWithoutProductsDto
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageCOGSReportWithoutProductsDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCOGSReportWithoutProductsDto
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageCategory
 */
export interface PageCategory {
    /**
     * 
     * @type {Array<Category>}
     * @memberof PageCategory
     */
    content?: Array<Category>;
    /**
     * 
     * @type {boolean}
     * @memberof PageCategory
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCategory
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageCategory
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageCategory
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCategory
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageCategory
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageCategory
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageCategory
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageCategory
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageCategory
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageDeliveryNoteWithIsInvoicedFlag
 */
export interface PageDeliveryNoteWithIsInvoicedFlag {
    /**
     * 
     * @type {Array<DeliveryNoteWithIsInvoicedFlag>}
     * @memberof PageDeliveryNoteWithIsInvoicedFlag
     */
    content?: Array<DeliveryNoteWithIsInvoicedFlag>;
    /**
     * 
     * @type {boolean}
     * @memberof PageDeliveryNoteWithIsInvoicedFlag
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDeliveryNoteWithIsInvoicedFlag
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDeliveryNoteWithIsInvoicedFlag
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageDeliveryNoteWithIsInvoicedFlag
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDeliveryNoteWithIsInvoicedFlag
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageDeliveryNoteWithIsInvoicedFlag
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageDeliveryNoteWithIsInvoicedFlag
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageDeliveryNoteWithIsInvoicedFlag
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageDeliveryNoteWithIsInvoicedFlag
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDeliveryNoteWithIsInvoicedFlag
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageDiscrepancyReportRow
 */
export interface PageDiscrepancyReportRow {
    /**
     * 
     * @type {Array<DiscrepancyReportRow>}
     * @memberof PageDiscrepancyReportRow
     */
    content?: Array<DiscrepancyReportRow>;
    /**
     * 
     * @type {boolean}
     * @memberof PageDiscrepancyReportRow
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDiscrepancyReportRow
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDiscrepancyReportRow
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageDiscrepancyReportRow
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDiscrepancyReportRow
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageDiscrepancyReportRow
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageDiscrepancyReportRow
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageDiscrepancyReportRow
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageDiscrepancyReportRow
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDiscrepancyReportRow
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageDiscrepancyReportWithoutProductsDto
 */
export interface PageDiscrepancyReportWithoutProductsDto {
    /**
     * 
     * @type {Array<DiscrepancyReportWithoutProductsDto>}
     * @memberof PageDiscrepancyReportWithoutProductsDto
     */
    content?: Array<DiscrepancyReportWithoutProductsDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageDiscrepancyReportWithoutProductsDto
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDiscrepancyReportWithoutProductsDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDiscrepancyReportWithoutProductsDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageDiscrepancyReportWithoutProductsDto
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDiscrepancyReportWithoutProductsDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageDiscrepancyReportWithoutProductsDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageDiscrepancyReportWithoutProductsDto
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageDiscrepancyReportWithoutProductsDto
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageDiscrepancyReportWithoutProductsDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDiscrepancyReportWithoutProductsDto
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageDish
 */
export interface PageDish {
    /**
     * 
     * @type {Array<Dish>}
     * @memberof PageDish
     */
    content?: Array<Dish>;
    /**
     * 
     * @type {boolean}
     * @memberof PageDish
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDish
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageDish
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageDish
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDish
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageDish
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageDish
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageDish
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageDish
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageDish
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageEvent
 */
export interface PageEvent {
    /**
     * 
     * @type {Array<Event>}
     * @memberof PageEvent
     */
    content?: Array<Event>;
    /**
     * 
     * @type {boolean}
     * @memberof PageEvent
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEvent
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageEvent
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageEvent
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEvent
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageEvent
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageEvent
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageEvent
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageEvent
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageEvent
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageExtendedProduct
 */
export interface PageExtendedProduct {
    /**
     * 
     * @type {Array<ExtendedProduct>}
     * @memberof PageExtendedProduct
     */
    content?: Array<ExtendedProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof PageExtendedProduct
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageExtendedProduct
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageExtendedProduct
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageExtendedProduct
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageExtendedProduct
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageExtendedProduct
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageExtendedProduct
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageExtendedProduct
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageExtendedProduct
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageExtendedProduct
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageFeature
 */
export interface PageFeature {
    /**
     * 
     * @type {Array<Feature>}
     * @memberof PageFeature
     */
    content?: Array<Feature>;
    /**
     * 
     * @type {boolean}
     * @memberof PageFeature
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFeature
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFeature
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageFeature
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFeature
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageFeature
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageFeature
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageFeature
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageFeature
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFeature
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageFolder
 */
export interface PageFolder {
    /**
     * 
     * @type {Array<Folder>}
     * @memberof PageFolder
     */
    content?: Array<Folder>;
    /**
     * 
     * @type {boolean}
     * @memberof PageFolder
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFolder
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageFolder
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageFolder
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFolder
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageFolder
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageFolder
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageFolder
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageFolder
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageFolder
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageGPReportWithoutProductsDto
 */
export interface PageGPReportWithoutProductsDto {
    /**
     * 
     * @type {Array<GPReportWithoutProductsDto>}
     * @memberof PageGPReportWithoutProductsDto
     */
    content?: Array<GPReportWithoutProductsDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageGPReportWithoutProductsDto
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageGPReportWithoutProductsDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageGPReportWithoutProductsDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageGPReportWithoutProductsDto
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageGPReportWithoutProductsDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageGPReportWithoutProductsDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageGPReportWithoutProductsDto
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageGPReportWithoutProductsDto
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageGPReportWithoutProductsDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageGPReportWithoutProductsDto
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageGlobalProduct
 */
export interface PageGlobalProduct {
    /**
     * 
     * @type {Array<GlobalProduct>}
     * @memberof PageGlobalProduct
     */
    content?: Array<GlobalProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof PageGlobalProduct
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageGlobalProduct
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageGlobalProduct
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageGlobalProduct
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageGlobalProduct
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageGlobalProduct
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageGlobalProduct
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageGlobalProduct
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageGlobalProduct
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageGlobalProduct
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageInvoiceWithOrgsNames
 */
export interface PageInvoiceWithOrgsNames {
    /**
     * 
     * @type {Array<InvoiceWithOrgsNames>}
     * @memberof PageInvoiceWithOrgsNames
     */
    content?: Array<InvoiceWithOrgsNames>;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvoiceWithOrgsNames
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvoiceWithOrgsNames
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageInvoiceWithOrgsNames
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageInvoiceWithOrgsNames
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvoiceWithOrgsNames
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageInvoiceWithOrgsNames
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageInvoiceWithOrgsNames
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageInvoiceWithOrgsNames
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageInvoiceWithOrgsNames
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageInvoiceWithOrgsNames
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageNotificationWithOrgsNames
 */
export interface PageNotificationWithOrgsNames {
    /**
     * 
     * @type {Array<NotificationWithOrgsNames>}
     * @memberof PageNotificationWithOrgsNames
     */
    content?: Array<NotificationWithOrgsNames>;
    /**
     * 
     * @type {boolean}
     * @memberof PageNotificationWithOrgsNames
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageNotificationWithOrgsNames
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageNotificationWithOrgsNames
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageNotificationWithOrgsNames
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageNotificationWithOrgsNames
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageNotificationWithOrgsNames
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageNotificationWithOrgsNames
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageNotificationWithOrgsNames
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageNotificationWithOrgsNames
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageNotificationWithOrgsNames
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageOrdersResponseDto
 */
export interface PageOrdersResponseDto {
    /**
     * 
     * @type {Array<OrdersResponseDto>}
     * @memberof PageOrdersResponseDto
     */
    content?: Array<OrdersResponseDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrdersResponseDto
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrdersResponseDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrdersResponseDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageOrdersResponseDto
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOrdersResponseDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageOrdersResponseDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageOrdersResponseDto
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageOrdersResponseDto
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOrdersResponseDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOrdersResponseDto
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageOrganization
 */
export interface PageOrganization {
    /**
     * 
     * @type {Array<Organization>}
     * @memberof PageOrganization
     */
    content?: Array<Organization>;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrganization
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrganization
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrganization
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageOrganization
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOrganization
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageOrganization
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageOrganization
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageOrganization
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOrganization
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOrganization
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageOrganizationDto
 */
export interface PageOrganizationDto {
    /**
     * 
     * @type {Array<OrganizationDto>}
     * @memberof PageOrganizationDto
     */
    content?: Array<OrganizationDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrganizationDto
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrganizationDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageOrganizationDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageOrganizationDto
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOrganizationDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageOrganizationDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageOrganizationDto
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageOrganizationDto
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageOrganizationDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageOrganizationDto
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PagePaymentPlan
 */
export interface PagePaymentPlan {
    /**
     * 
     * @type {Array<PaymentPlan>}
     * @memberof PagePaymentPlan
     */
    content?: Array<PaymentPlan>;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentPlan
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentPlan
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PagePaymentPlan
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentPlan
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentPlan
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PagePaymentPlan
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentPlan
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PagePaymentPlan
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentPlan
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PagePaymentPlan
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageProduct
 */
export interface PageProduct {
    /**
     * 
     * @type {Array<Product>}
     * @memberof PageProduct
     */
    content?: Array<Product>;
    /**
     * 
     * @type {boolean}
     * @memberof PageProduct
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProduct
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProduct
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageProduct
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageProduct
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProduct
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageProductBelowMinQtyExtended
 */
export interface PageProductBelowMinQtyExtended {
    /**
     * 
     * @type {Array<ProductBelowMinQtyExtended>}
     * @memberof PageProductBelowMinQtyExtended
     */
    content?: Array<ProductBelowMinQtyExtended>;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductBelowMinQtyExtended
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductBelowMinQtyExtended
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductBelowMinQtyExtended
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageProductBelowMinQtyExtended
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProductBelowMinQtyExtended
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageProductBelowMinQtyExtended
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageProductBelowMinQtyExtended
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageProductBelowMinQtyExtended
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageProductBelowMinQtyExtended
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProductBelowMinQtyExtended
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageProductScannedDto
 */
export interface PageProductScannedDto {
    /**
     * 
     * @type {Array<ProductScannedDto>}
     * @memberof PageProductScannedDto
     */
    content?: Array<ProductScannedDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductScannedDto
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductScannedDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageProductScannedDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageProductScannedDto
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProductScannedDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageProductScannedDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageProductScannedDto
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageProductScannedDto
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageProductScannedDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageProductScannedDto
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageRecipe
 */
export interface PageRecipe {
    /**
     * 
     * @type {Array<Recipe>}
     * @memberof PageRecipe
     */
    content?: Array<Recipe>;
    /**
     * 
     * @type {boolean}
     * @memberof PageRecipe
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRecipe
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageRecipe
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageRecipe
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRecipe
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageRecipe
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageRecipe
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageRecipe
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageRecipe
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageRecipe
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageSales
 */
export interface PageSales {
    /**
     * 
     * @type {Array<Sales>}
     * @memberof PageSales
     */
    content?: Array<Sales>;
    /**
     * 
     * @type {boolean}
     * @memberof PageSales
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSales
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSales
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageSales
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSales
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageSales
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageSales
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageSales
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageSales
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSales
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageStockBelowMinQtyReportProductCountDto
 */
export interface PageStockBelowMinQtyReportProductCountDto {
    /**
     * 
     * @type {Array<StockBelowMinQtyReportProductCountDto>}
     * @memberof PageStockBelowMinQtyReportProductCountDto
     */
    content?: Array<StockBelowMinQtyReportProductCountDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockBelowMinQtyReportProductCountDto
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockBelowMinQtyReportProductCountDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockBelowMinQtyReportProductCountDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageStockBelowMinQtyReportProductCountDto
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStockBelowMinQtyReportProductCountDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageStockBelowMinQtyReportProductCountDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageStockBelowMinQtyReportProductCountDto
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageStockBelowMinQtyReportProductCountDto
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageStockBelowMinQtyReportProductCountDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStockBelowMinQtyReportProductCountDto
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageStockTakeListWrapper
 */
export interface PageStockTakeListWrapper {
    /**
     * 
     * @type {Array<StockTakeListWrapper>}
     * @memberof PageStockTakeListWrapper
     */
    content?: Array<StockTakeListWrapper>;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTakeListWrapper
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTakeListWrapper
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTakeListWrapper
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeListWrapper
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeListWrapper
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageStockTakeListWrapper
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeListWrapper
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageStockTakeListWrapper
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeListWrapper
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeListWrapper
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageStockTakeProduct
 */
export interface PageStockTakeProduct {
    /**
     * 
     * @type {Array<StockTakeProduct>}
     * @memberof PageStockTakeProduct
     */
    content?: Array<StockTakeProduct>;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTakeProduct
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTakeProduct
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTakeProduct
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeProduct
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeProduct
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageStockTakeProduct
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeProduct
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageStockTakeProduct
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeProduct
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeProduct
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageStockTakeRecipe
 */
export interface PageStockTakeRecipe {
    /**
     * 
     * @type {Array<StockTakeRecipe>}
     * @memberof PageStockTakeRecipe
     */
    content?: Array<StockTakeRecipe>;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTakeRecipe
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTakeRecipe
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTakeRecipe
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeRecipe
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeRecipe
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageStockTakeRecipe
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeRecipe
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageStockTakeRecipe
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeRecipe
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeRecipe
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageStockTakeTemplateProductsCountDto
 */
export interface PageStockTakeTemplateProductsCountDto {
    /**
     * 
     * @type {Array<StockTakeTemplateProductsCountDto>}
     * @memberof PageStockTakeTemplateProductsCountDto
     */
    content?: Array<StockTakeTemplateProductsCountDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTakeTemplateProductsCountDto
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTakeTemplateProductsCountDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTakeTemplateProductsCountDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeTemplateProductsCountDto
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeTemplateProductsCountDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageStockTakeTemplateProductsCountDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeTemplateProductsCountDto
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageStockTakeTemplateProductsCountDto
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeTemplateProductsCountDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStockTakeTemplateProductsCountDto
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageStockTransferConnectionDto
 */
export interface PageStockTransferConnectionDto {
    /**
     * 
     * @type {Array<StockTransferConnectionDto>}
     * @memberof PageStockTransferConnectionDto
     */
    content?: Array<StockTransferConnectionDto>;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTransferConnectionDto
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTransferConnectionDto
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTransferConnectionDto
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageStockTransferConnectionDto
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStockTransferConnectionDto
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageStockTransferConnectionDto
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageStockTransferConnectionDto
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageStockTransferConnectionDto
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageStockTransferConnectionDto
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStockTransferConnectionDto
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageStockTransferWithOrgDetails
 */
export interface PageStockTransferWithOrgDetails {
    /**
     * 
     * @type {Array<StockTransferWithOrgDetails>}
     * @memberof PageStockTransferWithOrgDetails
     */
    content?: Array<StockTransferWithOrgDetails>;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTransferWithOrgDetails
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTransferWithOrgDetails
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageStockTransferWithOrgDetails
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageStockTransferWithOrgDetails
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStockTransferWithOrgDetails
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageStockTransferWithOrgDetails
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageStockTransferWithOrgDetails
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageStockTransferWithOrgDetails
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageStockTransferWithOrgDetails
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageStockTransferWithOrgDetails
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageSupplier
 */
export interface PageSupplier {
    /**
     * 
     * @type {Array<Supplier>}
     * @memberof PageSupplier
     */
    content?: Array<Supplier>;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplier
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplier
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplier
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageSupplier
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSupplier
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageSupplier
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageSupplier
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageSupplier
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageSupplier
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSupplier
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageSupplierExtractionProfile
 */
export interface PageSupplierExtractionProfile {
    /**
     * 
     * @type {Array<SupplierExtractionProfile>}
     * @memberof PageSupplierExtractionProfile
     */
    content?: Array<SupplierExtractionProfile>;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplierExtractionProfile
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplierExtractionProfile
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageSupplierExtractionProfile
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierExtractionProfile
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierExtractionProfile
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageSupplierExtractionProfile
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierExtractionProfile
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageSupplierExtractionProfile
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierExtractionProfile
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageSupplierExtractionProfile
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageUserInvitation
 */
export interface PageUserInvitation {
    /**
     * 
     * @type {Array<UserInvitation>}
     * @memberof PageUserInvitation
     */
    content?: Array<UserInvitation>;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserInvitation
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserInvitation
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserInvitation
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageUserInvitation
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserInvitation
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageUserInvitation
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageUserInvitation
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageUserInvitation
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageUserInvitation
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserInvitation
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageUserResponseModel
 */
export interface PageUserResponseModel {
    /**
     * 
     * @type {Array<UserResponseModel>}
     * @memberof PageUserResponseModel
     */
    content?: Array<UserResponseModel>;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserResponseModel
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserResponseModel
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageUserResponseModel
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageUserResponseModel
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserResponseModel
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageUserResponseModel
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageUserResponseModel
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageUserResponseModel
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageUserResponseModel
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageUserResponseModel
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface PageWasteGroupWithOrgsNames
 */
export interface PageWasteGroupWithOrgsNames {
    /**
     * 
     * @type {Array<WasteGroupWithOrgsNames>}
     * @memberof PageWasteGroupWithOrgsNames
     */
    content?: Array<WasteGroupWithOrgsNames>;
    /**
     * 
     * @type {boolean}
     * @memberof PageWasteGroupWithOrgsNames
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageWasteGroupWithOrgsNames
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageWasteGroupWithOrgsNames
     */
    last?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PageWasteGroupWithOrgsNames
     */
    number?: number;
    /**
     * 
     * @type {number}
     * @memberof PageWasteGroupWithOrgsNames
     */
    numberOfElements?: number;
    /**
     * 
     * @type {Pageable}
     * @memberof PageWasteGroupWithOrgsNames
     */
    pageable?: Pageable;
    /**
     * 
     * @type {number}
     * @memberof PageWasteGroupWithOrgsNames
     */
    size?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageWasteGroupWithOrgsNames
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageWasteGroupWithOrgsNames
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageWasteGroupWithOrgsNames
     */
    totalPages?: number;
}

/**
 * 
 * @export
 * @interface Pageable
 */
export interface Pageable {
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof Pageable
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof Pageable
     */
    sort?: string;
}

/**
 * 
 * @export
 * @interface PasswordResetToken
 */
export interface PasswordResetToken {
    /**
     * 
     * @type {Date}
     * @memberof PasswordResetToken
     */
    expiryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetToken
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetToken
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof PasswordResetToken
     */
    username?: string;
}

/**
 * 
 * @export
 * @interface Payment
 */
export interface Payment {
    /**
     * 
     * @type {Date}
     * @memberof Payment
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    locationId?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof Payment
     */
    status?: Payment.StatusEnum;
}

/**
 * @export
 * @namespace Payment
 */
export namespace Payment {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        APPROVED = <any> 'APPROVED',
        CANCELED = <any> 'CANCELED',
        COMPLETED = <any> 'COMPLETED',
        FAILED = <any> 'FAILED',
        PENDING = <any> 'PENDING'
    }
}

/**
 * 
 * @export
 * @interface PaymentPlan
 */
export interface PaymentPlan {
    /**
     * 
     * @type {Date}
     * @memberof PaymentPlan
     */
    creationDate?: Date;
    /**
     * 
     * @type {Date}
     * @memberof PaymentPlan
     */
    expirationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    orgId?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    stripeCustomerId?: string;
    /**
     * 
     * @type {PaymentRecurring}
     * @memberof PaymentPlan
     */
    stripePaymentRecurring?: PaymentRecurring;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    stripeSubscriptionId?: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentPlan
     */
    type?: PaymentPlan.TypeEnum;
}

/**
 * @export
 * @namespace PaymentPlan
 */
export namespace PaymentPlan {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        DEFAULT = <any> 'DEFAULT',
        ENTERPRISE = <any> 'ENTERPRISE',
        ENTERPRISEPLUSSCANNING = <any> 'ENTERPRISE_PLUS_SCANNING',
        STARTER = <any> 'STARTER'
    }
}

/**
 * 
 * @export
 * @interface PaymentRecurring
 */
export interface PaymentRecurring {
    /**
     * 
     * @type {string}
     * @memberof PaymentRecurring
     */
    interval?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentRecurring
     */
    intervalCount?: number;
}

/**
 * 
 * @export
 * @interface PdfOperationResponse
 */
export interface PdfOperationResponse {
    /**
     * 
     * @type {string}
     * @memberof PdfOperationResponse
     */
    fileId?: string;
    /**
     * 
     * @type {string}
     * @memberof PdfOperationResponse
     */
    fileName?: string;
    /**
     * 
     * @type {string}
     * @memberof PdfOperationResponse
     */
    jobId?: string;
}

/**
 * 
 * @export
 * @interface Permissions
 */
export interface Permissions {
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    _delete?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    modify?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Permissions
     */
    read?: boolean;
}

/**
 * 
 * @export
 * @interface Portion
 */
export interface Portion {
    /**
     * 
     * @type {number}
     * @memberof Portion
     */
    cost?: number;
    /**
     * 
     * @type {string}
     * @memberof Portion
     */
    measure?: Portion.MeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof Portion
     */
    size?: number;
}

/**
 * @export
 * @namespace Portion
 */
export namespace Portion {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
}

/**
 * 
 * @export
 * @interface PortionDto
 */
export interface PortionDto {
    /**
     * 
     * @type {number}
     * @memberof PortionDto
     */
    cost?: number;
}

/**
 * 
 * @export
 * @interface PosIntegrationCreatePayload
 */
export interface PosIntegrationCreatePayload {
    /**
     * 
     * @type {string}
     * @memberof PosIntegrationCreatePayload
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof PosIntegrationCreatePayload
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PosIntegrationCreatePayload
     */
    organizations?: Array<string>;
}

/**
 * 
 * @export
 * @interface PosIntegrationUpdatePayload
 */
export interface PosIntegrationUpdatePayload {
    /**
     * 
     * @type {string}
     * @memberof PosIntegrationUpdatePayload
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface Prediction
 */
export interface Prediction {
    /**
     * 
     * @type {Annotations}
     * @memberof Prediction
     */
    annotations?: Annotations;
    /**
     * 
     * @type {Lines}
     * @memberof Prediction
     */
    lines?: Lines;
}

/**
 * 
 * @export
 * @interface Product
 */
export interface Product {
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    measure?: Product.MeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof Product
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    subCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof Product
     */
    unit?: Product.UnitEnum;
}

/**
 * @export
 * @namespace Product
 */
export namespace Product {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum UnitEnum {
        Bottle = <any> 'Bottle',
        Can = <any> 'Can',
        Keg = <any> 'Keg',
        Other = <any> 'Other'
    }
}

/**
 * 
 * @export
 * @interface ProductBarcodeListDto
 */
export interface ProductBarcodeListDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductBarcodeListDto
     */
    barcodes?: Array<string>;
}

/**
 * 
 * @export
 * @interface ProductBelowMinQtyExtended
 */
export interface ProductBelowMinQtyExtended {
    /**
     * 
     * @type {string}
     * @memberof ProductBelowMinQtyExtended
     */
    barcode?: string;
    /**
     * 
     * @type {number}
     * @memberof ProductBelowMinQtyExtended
     */
    currentQty?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductBelowMinQtyExtended
     */
    measure?: ProductBelowMinQtyExtended.MeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductBelowMinQtyExtended
     */
    minQtyInStock?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductBelowMinQtyExtended
     */
    name?: string;
    /**
     * 
     * @type {Array<ExtendedProduct>}
     * @memberof ProductBelowMinQtyExtended
     */
    productsForOrder?: Array<ExtendedProduct>;
    /**
     * 
     * @type {number}
     * @memberof ProductBelowMinQtyExtended
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductBelowMinQtyExtended
     */
    unit?: ProductBelowMinQtyExtended.UnitEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductBelowMinQtyExtended
     */
    varianceQty?: number;
}

/**
 * @export
 * @namespace ProductBelowMinQtyExtended
 */
export namespace ProductBelowMinQtyExtended {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum UnitEnum {
        Bottle = <any> 'Bottle',
        Can = <any> 'Can',
        Keg = <any> 'Keg',
        Other = <any> 'Other'
    }
}

/**
 * 
 * @export
 * @interface ProductDto
 */
export interface ProductDto {
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductDto
     */
    posId?: string;
}

/**
 * 
 * @export
 * @interface ProductIdListDto
 */
export interface ProductIdListDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductIdListDto
     */
    productsIds?: Array<string>;
}

/**
 * 
 * @export
 * @interface ProductPayload
 */
export interface ProductPayload {
    /**
     * 
     * @type {string}
     * @memberof ProductPayload
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductPayload
     */
    id?: string;
}

/**
 * 
 * @export
 * @interface ProductScannedDto
 */
export interface ProductScannedDto {
    /**
     * 
     * @type {string}
     * @memberof ProductScannedDto
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductScannedDto
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductScannedDto
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductScannedDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductScannedDto
     */
    measure?: ProductScannedDto.MeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof ProductScannedDto
     */
    minQtyInStock?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductScannedDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductScannedDto
     */
    posId?: string;
    /**
     * 
     * @type {ModelCase}
     * @memberof ProductScannedDto
     */
    productCase?: ModelCase;
    /**
     * 
     * @type {number}
     * @memberof ProductScannedDto
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof ProductScannedDto
     */
    subCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof ProductScannedDto
     */
    unit?: ProductScannedDto.UnitEnum;
}

/**
 * @export
 * @namespace ProductScannedDto
 */
export namespace ProductScannedDto {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum UnitEnum {
        Bottle = <any> 'Bottle',
        Can = <any> 'Can',
        Keg = <any> 'Keg',
        Other = <any> 'Other'
    }
}

/**
 * 
 * @export
 * @interface ProductUpdatePriceDto
 */
export interface ProductUpdatePriceDto {
    /**
     * 
     * @type {boolean}
     * @memberof ProductUpdatePriceDto
     */
    casePrice?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ProductUpdatePriceDto
     */
    price?: number;
}

/**
 * 
 * @export
 * @interface ProductsSyncDto
 */
export interface ProductsSyncDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProductsSyncDto
     */
    productsIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof ProductsSyncDto
     */
    syncCasePrice?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductsSyncDto
     */
    syncMeasureSizeUnit?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductsSyncDto
     */
    syncPosId?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ProductsSyncDto
     */
    syncPrice?: boolean;
}

/**
 * 
 * @export
 * @interface ReceivedProductUpdatePayload
 */
export interface ReceivedProductUpdatePayload {
    /**
     * 
     * @type {boolean}
     * @memberof ReceivedProductUpdatePayload
     */
    isConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReceivedProductUpdatePayload
     */
    receivedProductId?: string;
    /**
     * 
     * @type {number}
     * @memberof ReceivedProductUpdatePayload
     */
    receivedQty?: number;
    /**
     * 
     * @type {boolean}
     * @memberof ReceivedProductUpdatePayload
     */
    receivedQtyInCase?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ReceivedProductUpdatePayload
     */
    receiverComment?: string;
}

/**
 * 
 * @export
 * @interface Recipe
 */
export interface Recipe {
    /**
     * 
     * @type {Array<string>}
     * @memberof Recipe
     */
    allergens?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Recipe
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof Recipe
     */
    category?: string;
    /**
     * 
     * @type {Date}
     * @memberof Recipe
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof Recipe
     */
    description?: string;
    /**
     * 
     * @type {Array<RecipeDishDto>}
     * @memberof Recipe
     */
    dishes?: Array<RecipeDishDto>;
    /**
     * 
     * @type {GrowyzeFile}
     * @memberof Recipe
     */
    featuredFile?: GrowyzeFile;
    /**
     * 
     * @type {Array<GrowyzeFile>}
     * @memberof Recipe
     */
    files?: Array<GrowyzeFile>;
    /**
     * 
     * @type {FolderDto}
     * @memberof Recipe
     */
    folder?: FolderDto;
    /**
     * 
     * @type {string}
     * @memberof Recipe
     */
    id?: string;
    /**
     * 
     * @type {Array<Ingredient>}
     * @memberof Recipe
     */
    ingredients?: Array<Ingredient>;
    /**
     * 
     * @type {string}
     * @memberof Recipe
     */
    masterRecipeId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Recipe
     */
    mayContainAllergens?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Recipe
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Recipe
     */
    notes?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Recipe
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Recipe
     */
    otherIngredients?: string;
    /**
     * 
     * @type {number}
     * @memberof Recipe
     */
    otherIngredientsCost?: number;
    /**
     * 
     * @type {Portion}
     * @memberof Recipe
     */
    portion?: Portion;
    /**
     * 
     * @type {number}
     * @memberof Recipe
     */
    portionCount?: number;
    /**
     * 
     * @type {string}
     * @memberof Recipe
     */
    posId?: string;
    /**
     * 
     * @type {number}
     * @memberof Recipe
     */
    profit?: number;
    /**
     * 
     * @type {number}
     * @memberof Recipe
     */
    profitPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof Recipe
     */
    salePrice?: number;
    /**
     * 
     * @type {string}
     * @memberof Recipe
     */
    status?: Recipe.StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Recipe
     */
    suggestedSalePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof Recipe
     */
    targetMarginPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof Recipe
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof Recipe
     */
    totalCostPercent?: number;
    /**
     * 
     * @type {ModelYield}
     * @memberof Recipe
     */
    _yield?: ModelYield;
}

/**
 * @export
 * @namespace Recipe
 */
export namespace Recipe {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        DRAFT = <any> 'DRAFT',
        PUBLISHED = <any> 'PUBLISHED'
    }
}

/**
 * 
 * @export
 * @interface RecipeDishDto
 */
export interface RecipeDishDto {
    /**
     * 
     * @type {string}
     * @memberof RecipeDishDto
     */
    id?: string;
}

/**
 * 
 * @export
 * @interface RecipeIdListDto
 */
export interface RecipeIdListDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof RecipeIdListDto
     */
    recipesIds?: Array<string>;
}

/**
 * 
 * @export
 * @interface RecipeSectionElement
 */
export interface RecipeSectionElement {
    /**
     * 
     * @type {number}
     * @memberof RecipeSectionElement
     */
    cost?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RecipeSectionElement
     */
    inconsistentCost?: boolean;
    /**
     * 
     * @type {string}
     * @memberof RecipeSectionElement
     */
    measure?: RecipeSectionElement.MeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof RecipeSectionElement
     */
    pureMeasure?: RecipeSectionElement.PureMeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof RecipeSectionElement
     */
    pureQty?: number;
    /**
     * 
     * @type {DishRecipeDto}
     * @memberof RecipeSectionElement
     */
    recipe?: DishRecipeDto;
    /**
     * 
     * @type {number}
     * @memberof RecipeSectionElement
     */
    usedQty?: number;
    /**
     * 
     * @type {string}
     * @memberof RecipeSectionElement
     */
    wasteMeasure?: RecipeSectionElement.WasteMeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof RecipeSectionElement
     */
    wasteQty?: number;
}

/**
 * @export
 * @namespace RecipeSectionElement
 */
export namespace RecipeSectionElement {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        Full = <any> 'full',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        Percentage = <any> 'percentage',
        Portion = <any> 'portion',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum PureMeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        Full = <any> 'full',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        Percentage = <any> 'percentage',
        Portion = <any> 'portion',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum WasteMeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        Full = <any> 'full',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        Percentage = <any> 'percentage',
        Portion = <any> 'portion',
        PtUK = <any> 'pt_UK'
    }
}

/**
 * 
 * @export
 * @interface ReferenceIdListDto
 */
export interface ReferenceIdListDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof ReferenceIdListDto
     */
    referencesIds?: Array<string>;
}

/**
 * 
 * @export
 * @interface RepresentationModelobject
 */
export interface RepresentationModelobject {
    /**
     * 
     * @type {Links}
     * @memberof RepresentationModelobject
     */
    links?: Links;
}

/**
 * 
 * @export
 * @interface Row
 */
export interface Row {
    /**
     * 
     * @type {Array<string>}
     * @memberof Row
     */
    row?: Array<string>;
}

/**
 * 
 * @export
 * @interface SaleItem
 */
export interface SaleItem {
    /**
     * 
     * @type {boolean}
     * @memberof SaleItem
     */
    autoGeneratedPosId?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaleItem
     */
    name?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SaleItem
     */
    nonMatchingPosId?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SaleItem
     */
    posId?: string;
    /**
     * 
     * @type {number}
     * @memberof SaleItem
     */
    soldQty?: number;
    /**
     * 
     * @type {number}
     * @memberof SaleItem
     */
    totalValue?: number;
}

/**
 * 
 * @export
 * @interface SaleItemDto
 */
export interface SaleItemDto {
    /**
     * 
     * @type {string}
     * @memberof SaleItemDto
     */
    posId?: string;
    /**
     * 
     * @type {number}
     * @memberof SaleItemDto
     */
    salePrice?: number;
}

/**
 * 
 * @export
 * @interface Sales
 */
export interface Sales {
    /**
     * 
     * @type {string}
     * @memberof Sales
     */
    id?: string;
}

/**
 * 
 * @export
 * @interface SalesPayload
 */
export interface SalesPayload {
    /**
     * 
     * @type {Date}
     * @memberof SalesPayload
     */
    from?: Date;
    /**
     * 
     * @type {Array<SaleItem>}
     * @memberof SalesPayload
     */
    items?: Array<SaleItem>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SalesPayload
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof SalesPayload
     */
    salesOrigin?: SalesPayload.SalesOriginEnum;
    /**
     * 
     * @type {Date}
     * @memberof SalesPayload
     */
    to?: Date;
}

/**
 * @export
 * @namespace SalesPayload
 */
export namespace SalesPayload {
    /**
     * @export
     * @enum {string}
     */
    export enum SalesOriginEnum {
        CLOVER = <any> 'CLOVER',
        GOEPOS = <any> 'GOEPOS',
        IMPORTED = <any> 'IMPORTED',
        LIGHTSPEED = <any> 'LIGHTSPEED',
        NEWBRIDGE = <any> 'NEWBRIDGE',
        SQUARE = <any> 'SQUARE',
        WOOCOMMERCE = <any> 'WOOCOMMERCE'
    }
}

/**
 * 
 * @export
 * @interface Section
 */
export interface Section {
    /**
     * 
     * @type {Array<SectionElement>}
     * @memberof Section
     */
    elements?: Array<SectionElement>;
    /**
     * 
     * @type {string}
     * @memberof Section
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface SectionElement
 */
export interface SectionElement {
    /**
     * 
     * @type {Ingredient}
     * @memberof SectionElement
     */
    ingredient?: Ingredient;
    /**
     * 
     * @type {OtherIngredient}
     * @memberof SectionElement
     */
    otherIngredient?: OtherIngredient;
    /**
     * 
     * @type {RecipeSectionElement}
     * @memberof SectionElement
     */
    recipe?: RecipeSectionElement;
    /**
     * 
     * @type {string}
     * @memberof SectionElement
     */
    type?: SectionElement.TypeEnum;
}

/**
 * @export
 * @namespace SectionElement
 */
export namespace SectionElement {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        INGREDIENT = <any> 'INGREDIENT',
        OTHERINGREDIENT = <any> 'OTHER_INGREDIENT',
        RECIPE = <any> 'RECIPE'
    }
}

/**
 * 
 * @export
 * @interface SentProductUpdatePayload
 */
export interface SentProductUpdatePayload {
    /**
     * 
     * @type {string}
     * @memberof SentProductUpdatePayload
     */
    senderComment?: string;
    /**
     * 
     * @type {number}
     * @memberof SentProductUpdatePayload
     */
    sentQty?: number;
    /**
     * 
     * @type {boolean}
     * @memberof SentProductUpdatePayload
     */
    sentQtyInCase?: boolean;
}

/**
 * 
 * @export
 * @interface Site
 */
export interface Site {
    /**
     * 
     * @type {string}
     * @memberof Site
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Site
     */
    number?: number;
}

/**
 * 
 * @export
 * @interface SoldProductDto
 */
export interface SoldProductDto {
    /**
     * 
     * @type {string}
     * @memberof SoldProductDto
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof SoldProductDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof SoldProductDto
     */
    posId?: string;
    /**
     * 
     * @type {number}
     * @memberof SoldProductDto
     */
    qty?: number;
}

/**
 * 
 * @export
 * @interface Sort
 */
export interface Sort {
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    empty?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    sorted?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Sort
     */
    unsorted?: boolean;
}

/**
 * 
 * @export
 * @interface SquarePaymentEvent
 */
export interface SquarePaymentEvent {
    /**
     * 
     * @type {Data}
     * @memberof SquarePaymentEvent
     */
    data?: Data;
    /**
     * 
     * @type {string}
     * @memberof SquarePaymentEvent
     */
    merchantId?: string;
    /**
     * 
     * @type {string}
     * @memberof SquarePaymentEvent
     */
    type?: string;
}

/**
 * 
 * @export
 * @interface SquarePosIntegration
 */
export interface SquarePosIntegration {
    /**
     * 
     * @type {string}
     * @memberof SquarePosIntegration
     */
    id?: string;
    /**
     * 
     * @type {Location}
     * @memberof SquarePosIntegration
     */
    location?: Location;
    /**
     * 
     * @type {string}
     * @memberof SquarePosIntegration
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SquarePosIntegration
     */
    organizations?: Array<string>;
}

/**
 * 
 * @export
 * @interface StockBelowMinQtyReportProductCountDto
 */
export interface StockBelowMinQtyReportProductCountDto {
    /**
     * 
     * @type {Date}
     * @memberof StockBelowMinQtyReportProductCountDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StockBelowMinQtyReportProductCountDto
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockBelowMinQtyReportProductCountDto
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StockBelowMinQtyReportProductCountDto
     */
    productsCount?: number;
    /**
     * 
     * @type {StockTakeReport}
     * @memberof StockBelowMinQtyReportProductCountDto
     */
    stockTakeReport?: StockTakeReport;
    /**
     * 
     * @type {Date}
     * @memberof StockBelowMinQtyReportProductCountDto
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface StockTakeListWrapper
 */
export interface StockTakeListWrapper {
    /**
     * 
     * @type {string}
     * @memberof StockTakeListWrapper
     */
    discrepancyReportId?: string;
    /**
     * 
     * @type {Date}
     * @memberof StockTakeListWrapper
     */
    periodFrom?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StockTakeListWrapper
     */
    periodTo?: Date;
    /**
     * 
     * @type {StockTakeReportProductsCountDto}
     * @memberof StockTakeListWrapper
     */
    stockTakeReport?: StockTakeReportProductsCountDto;
    /**
     * 
     * @type {number}
     * @memberof StockTakeListWrapper
     */
    totalDiscrepancyAmount?: number;
}

/**
 * 
 * @export
 * @interface StockTakeProduct
 */
export interface StockTakeProduct {
    /**
     * 
     * @type {string}
     * @memberof StockTakeProduct
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeProduct
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeProduct
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StockTakeProduct
     */
    highestPrice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StockTakeProduct
     */
    measure?: StockTakeProduct.MeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof StockTakeProduct
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof StockTakeProduct
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof StockTakeProduct
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof StockTakeProduct
     */
    subCategory?: string;
    /**
     * 
     * @type {number}
     * @memberof StockTakeProduct
     */
    totalAmount?: number;
    /**
     * 
     * @type {string}
     * @memberof StockTakeProduct
     */
    unit?: StockTakeProduct.UnitEnum;
}

/**
 * @export
 * @namespace StockTakeProduct
 */
export namespace StockTakeProduct {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum UnitEnum {
        Bottle = <any> 'Bottle',
        Can = <any> 'Can',
        Keg = <any> 'Keg',
        Other = <any> 'Other'
    }
}

/**
 * 
 * @export
 * @interface StockTakeProductPayload
 */
export interface StockTakeProductPayload {
    /**
     * 
     * @type {string}
     * @memberof StockTakeProductPayload
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeProductPayload
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeProductPayload
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof StockTakeProductPayload
     */
    highestPrice?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StockTakeProductPayload
     */
    measure?: StockTakeProductPayload.MeasureEnum;
    /**
     * 
     * @type {number}
     * @memberof StockTakeProductPayload
     */
    price?: number;
    /**
     * 
     * @type {number}
     * @memberof StockTakeProductPayload
     */
    quantity?: number;
    /**
     * 
     * @type {number}
     * @memberof StockTakeProductPayload
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof StockTakeProductPayload
     */
    subCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeProductPayload
     */
    unit?: StockTakeProductPayload.UnitEnum;
}

/**
 * @export
 * @namespace StockTakeProductPayload
 */
export namespace StockTakeProductPayload {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum UnitEnum {
        Bottle = <any> 'Bottle',
        Can = <any> 'Can',
        Keg = <any> 'Keg',
        Other = <any> 'Other'
    }
}

/**
 * 
 * @export
 * @interface StockTakeRecipe
 */
export interface StockTakeRecipe {
    /**
     * 
     * @type {StockTakeRecipeDto}
     * @memberof StockTakeRecipe
     */
    recipe?: StockTakeRecipeDto;
    /**
     * 
     * @type {number}
     * @memberof StockTakeRecipe
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof StockTakeRecipe
     */
    totalQty?: number;
}

/**
 * 
 * @export
 * @interface StockTakeRecipeDto
 */
export interface StockTakeRecipeDto {
    /**
     * 
     * @type {string}
     * @memberof StockTakeRecipeDto
     */
    id?: string;
    /**
     * 
     * @type {Array<StockTakeRecipeDtoIngredient>}
     * @memberof StockTakeRecipeDto
     */
    ingredients?: Array<StockTakeRecipeDtoIngredient>;
    /**
     * 
     * @type {string}
     * @memberof StockTakeRecipeDto
     */
    name?: string;
    /**
     * 
     * @type {StockTakeRecipeDtoPortion}
     * @memberof StockTakeRecipeDto
     */
    portion?: StockTakeRecipeDtoPortion;
    /**
     * 
     * @type {number}
     * @memberof StockTakeRecipeDto
     */
    portionCount?: number;
    /**
     * 
     * @type {number}
     * @memberof StockTakeRecipeDto
     */
    totalCost?: number;
    /**
     * 
     * @type {ModelYield}
     * @memberof StockTakeRecipeDto
     */
    _yield?: ModelYield;
}

/**
 * 
 * @export
 * @interface StockTakeRecipeDtoIngredient
 */
export interface StockTakeRecipeDtoIngredient {
    /**
     * 
     * @type {string}
     * @memberof StockTakeRecipeDtoIngredient
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeRecipeDtoIngredient
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof StockTakeRecipeDtoIngredient
     */
    quantity?: number;
}

/**
 * 
 * @export
 * @interface StockTakeRecipeDtoPortion
 */
export interface StockTakeRecipeDtoPortion {
    /**
     * 
     * @type {number}
     * @memberof StockTakeRecipeDtoPortion
     */
    cost?: number;
}

/**
 * 
 * @export
 * @interface StockTakeRecipePayload
 */
export interface StockTakeRecipePayload {
    /**
     * 
     * @type {number}
     * @memberof StockTakeRecipePayload
     */
    qty?: number;
    /**
     * 
     * @type {string}
     * @memberof StockTakeRecipePayload
     */
    recipeId?: string;
}

/**
 * 
 * @export
 * @interface StockTakeReport
 */
export interface StockTakeReport {
    /**
     * 
     * @type {Date}
     * @memberof StockTakeReport
     */
    completedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StockTakeReport
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StockTakeReport
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeReport
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeReport
     */
    note?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockTakeReport
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof StockTakeReport
     */
    status?: StockTakeReport.StatusEnum;
    /**
     * 
     * @type {string}
     * @memberof StockTakeReport
     */
    stockTakeTemplateId?: string;
    /**
     * 
     * @type {Array<StockTakeReport>}
     * @memberof StockTakeReport
     */
    subStockTakeReports?: Array<StockTakeReport>;
    /**
     * 
     * @type {number}
     * @memberof StockTakeReport
     */
    totalAmount?: number;
    /**
     * 
     * @type {number}
     * @memberof StockTakeReport
     */
    totalAmountProducts?: number;
    /**
     * 
     * @type {number}
     * @memberof StockTakeReport
     */
    totalAmountRecipes?: number;
}

/**
 * @export
 * @namespace StockTakeReport
 */
export namespace StockTakeReport {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        COMPLETED = <any> 'COMPLETED',
        INPROGRESS = <any> 'IN_PROGRESS'
    }
}

/**
 * 
 * @export
 * @interface StockTakeReportPayload
 */
export interface StockTakeReportPayload {
    /**
     * 
     * @type {string}
     * @memberof StockTakeReportPayload
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeReportPayload
     */
    note?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockTakeReportPayload
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Array<StockTakeReportPayload>}
     * @memberof StockTakeReportPayload
     */
    subStockTakeReports?: Array<StockTakeReportPayload>;
}

/**
 * 
 * @export
 * @interface StockTakeReportProductsCountDto
 */
export interface StockTakeReportProductsCountDto {
    /**
     * 
     * @type {Date}
     * @memberof StockTakeReportProductsCountDto
     */
    completedAt?: Date;
    /**
     * 
     * @type {Date}
     * @memberof StockTakeReportProductsCountDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StockTakeReportProductsCountDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeReportProductsCountDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeReportProductsCountDto
     */
    note?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockTakeReportProductsCountDto
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StockTakeReportProductsCountDto
     */
    productsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof StockTakeReportProductsCountDto
     */
    status?: StockTakeReportProductsCountDto.StatusEnum;
    /**
     * 
     * @type {Array<StockTakeReportProductsCountDto>}
     * @memberof StockTakeReportProductsCountDto
     */
    subStockTakeReports?: Array<StockTakeReportProductsCountDto>;
    /**
     * 
     * @type {number}
     * @memberof StockTakeReportProductsCountDto
     */
    totalAmount?: number;
}

/**
 * @export
 * @namespace StockTakeReportProductsCountDto
 */
export namespace StockTakeReportProductsCountDto {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        COMPLETED = <any> 'COMPLETED',
        INPROGRESS = <any> 'IN_PROGRESS'
    }
}

/**
 * 
 * @export
 * @interface StockTakeReportUpdatePayload
 */
export interface StockTakeReportUpdatePayload {
    /**
     * 
     * @type {Date}
     * @memberof StockTakeReportUpdatePayload
     */
    completedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StockTakeReportUpdatePayload
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeReportUpdatePayload
     */
    note?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeReportUpdatePayload
     */
    stockTakeTemplateId?: string;
}

/**
 * 
 * @export
 * @interface StockTakeTemplate
 */
export interface StockTakeTemplate {
    /**
     * 
     * @type {Date}
     * @memberof StockTakeTemplate
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplate
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplate
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplate
     */
    note?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockTakeTemplate
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Array<StockTakeTemplateProduct>}
     * @memberof StockTakeTemplate
     */
    products?: Array<StockTakeTemplateProduct>;
    /**
     * 
     * @type {Array<StockTakeTemplateRecipe>}
     * @memberof StockTakeTemplate
     */
    recipes?: Array<StockTakeTemplateRecipe>;
    /**
     * 
     * @type {Date}
     * @memberof StockTakeTemplate
     */
    updatedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof StockTakeTemplate
     */
    version?: number;
}

/**
 * 
 * @export
 * @interface StockTakeTemplateProduct
 */
export interface StockTakeTemplateProduct {
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplateProduct
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplateProduct
     */
    category?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplateProduct
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplateProduct
     */
    measure?: StockTakeTemplateProduct.MeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplateProduct
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof StockTakeTemplateProduct
     */
    size?: number;
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplateProduct
     */
    subCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplateProduct
     */
    unit?: StockTakeTemplateProduct.UnitEnum;
}

/**
 * @export
 * @namespace StockTakeTemplateProduct
 */
export namespace StockTakeTemplateProduct {
    /**
     * @export
     * @enum {string}
     */
    export enum MeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum UnitEnum {
        Bottle = <any> 'Bottle',
        Can = <any> 'Can',
        Keg = <any> 'Keg',
        Other = <any> 'Other'
    }
}

/**
 * 
 * @export
 * @interface StockTakeTemplateProductsCountDto
 */
export interface StockTakeTemplateProductsCountDto {
    /**
     * 
     * @type {Date}
     * @memberof StockTakeTemplateProductsCountDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplateProductsCountDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplateProductsCountDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplateProductsCountDto
     */
    note?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockTakeTemplateProductsCountDto
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof StockTakeTemplateProductsCountDto
     */
    productsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof StockTakeTemplateProductsCountDto
     */
    recipesCount?: number;
    /**
     * 
     * @type {Date}
     * @memberof StockTakeTemplateProductsCountDto
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface StockTakeTemplateRecipe
 */
export interface StockTakeTemplateRecipe {
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplateRecipe
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTakeTemplateRecipe
     */
    recipeId?: string;
}

/**
 * 
 * @export
 * @interface StockTakeUpdateRecipePayload
 */
export interface StockTakeUpdateRecipePayload {
    /**
     * 
     * @type {number}
     * @memberof StockTakeUpdateRecipePayload
     */
    qty?: number;
}

/**
 * 
 * @export
 * @interface StockTransferConnectionCreatePayload
 */
export interface StockTransferConnectionCreatePayload {
    /**
     * 
     * @type {string}
     * @memberof StockTransferConnectionCreatePayload
     */
    activeOrgId?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTransferConnectionCreatePayload
     */
    connectToOrgId?: string;
}

/**
 * 
 * @export
 * @interface StockTransferConnectionDto
 */
export interface StockTransferConnectionDto {
    /**
     * 
     * @type {Organization}
     * @memberof StockTransferConnectionDto
     */
    connectedOrg?: Organization;
    /**
     * 
     * @type {Date}
     * @memberof StockTransferConnectionDto
     */
    createdAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StockTransferConnectionDto
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockTransferConnectionDto
     */
    organizations?: Array<string>;
}

/**
 * 
 * @export
 * @interface StockTransferCreatePayload
 */
export interface StockTransferCreatePayload {
    /**
     * 
     * @type {Array<TransferredProductReq>}
     * @memberof StockTransferCreatePayload
     */
    products?: Array<TransferredProductReq>;
    /**
     * 
     * @type {string}
     * @memberof StockTransferCreatePayload
     */
    receiverOrgId?: string;
    /**
     * 
     * @type {string}
     * @memberof StockTransferCreatePayload
     */
    senderOrgId?: string;
    /**
     * 
     * @type {Date}
     * @memberof StockTransferCreatePayload
     */
    sentAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StockTransferCreatePayload
     */
    status?: StockTransferCreatePayload.StatusEnum;
}

/**
 * @export
 * @namespace StockTransferCreatePayload
 */
export namespace StockTransferCreatePayload {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        CANCELLED = <any> 'CANCELLED',
        DRAFT = <any> 'DRAFT',
        RECEIVED = <any> 'RECEIVED',
        SENT = <any> 'SENT'
    }
}

/**
 * 
 * @export
 * @interface StockTransferWithOrgDetails
 */
export interface StockTransferWithOrgDetails {
    /**
     * 
     * @type {Date}
     * @memberof StockTransferWithOrgDetails
     */
    canceledAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof StockTransferWithOrgDetails
     */
    canceledByOrgId?: string;
    /**
     * 
     * @type {Date}
     * @memberof StockTransferWithOrgDetails
     */
    createdAt?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof StockTransferWithOrgDetails
     */
    hasProductsComments?: boolean;
    /**
     * 
     * @type {string}
     * @memberof StockTransferWithOrgDetails
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof StockTransferWithOrgDetails
     */
    nonMatchingReceivedProductsCount?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof StockTransferWithOrgDetails
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Array<TransferredProductRes>}
     * @memberof StockTransferWithOrgDetails
     */
    products?: Array<TransferredProductRes>;
    /**
     * 
     * @type {Date}
     * @memberof StockTransferWithOrgDetails
     */
    receivedAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof StockTransferWithOrgDetails
     */
    receivedTotalCost?: number;
    /**
     * 
     * @type {Organization}
     * @memberof StockTransferWithOrgDetails
     */
    receiverOrg?: Organization;
    /**
     * 
     * @type {string}
     * @memberof StockTransferWithOrgDetails
     */
    receiverOrgId?: string;
    /**
     * 
     * @type {Organization}
     * @memberof StockTransferWithOrgDetails
     */
    senderOrg?: Organization;
    /**
     * 
     * @type {string}
     * @memberof StockTransferWithOrgDetails
     */
    senderOrgId?: string;
    /**
     * 
     * @type {Date}
     * @memberof StockTransferWithOrgDetails
     */
    sentAt?: Date;
    /**
     * 
     * @type {number}
     * @memberof StockTransferWithOrgDetails
     */
    sentTotalCost?: number;
    /**
     * 
     * @type {string}
     * @memberof StockTransferWithOrgDetails
     */
    status?: StockTransferWithOrgDetails.StatusEnum;
    /**
     * 
     * @type {number}
     * @memberof StockTransferWithOrgDetails
     */
    totalCostVariance?: number;
}

/**
 * @export
 * @namespace StockTransferWithOrgDetails
 */
export namespace StockTransferWithOrgDetails {
    /**
     * @export
     * @enum {string}
     */
    export enum StatusEnum {
        CANCELLED = <any> 'CANCELLED',
        DRAFT = <any> 'DRAFT',
        RECEIVED = <any> 'RECEIVED',
        SENT = <any> 'SENT'
    }
}

/**
 * 
 * @export
 * @interface SubCategory
 */
export interface SubCategory {
    /**
     * 
     * @type {string}
     * @memberof SubCategory
     */
    desc?: string;
    /**
     * 
     * @type {string}
     * @memberof SubCategory
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface SubFolderPayload
 */
export interface SubFolderPayload {
    /**
     * 
     * @type {string}
     * @memberof SubFolderPayload
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface Supplier
 */
export interface Supplier {
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    accountNumber?: string;
    /**
     * 
     * @type {Address}
     * @memberof Supplier
     */
    address?: Address;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    contactName?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    currency?: string;
    /**
     * 
     * @type {ContactInfo}
     * @memberof Supplier
     */
    deliveriesContact?: ContactInfo;
    /**
     * 
     * @type {Weekdays}
     * @memberof Supplier
     */
    deliveryDays?: Weekdays;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    id?: string;
    /**
     * 
     * @type {ContactInfo}
     * @memberof Supplier
     */
    invoicesContact?: ContactInfo;
    /**
     * 
     * @type {MinOrderConfig}
     * @memberof Supplier
     */
    minOrderConfig?: MinOrderConfig;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    notes?: string;
    /**
     * 
     * @type {OrderApprovals}
     * @memberof Supplier
     */
    orderApprovals?: OrderApprovals;
    /**
     * 
     * @type {Array<OrderReminder>}
     * @memberof Supplier
     */
    orderReminders?: Array<OrderReminder>;
    /**
     * 
     * @type {ContactInfo}
     * @memberof Supplier
     */
    ordersContact?: ContactInfo;
    /**
     * 
     * @type {Array<string>}
     * @memberof Supplier
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    telephone?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    vatNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof Supplier
     */
    website?: string;
}

/**
 * 
 * @export
 * @interface SupplierExtractionProfile
 */
export interface SupplierExtractionProfile {
    /**
     * 
     * @type {string}
     * @memberof SupplierExtractionProfile
     */
    dnEngine?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierExtractionProfile
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierExtractionProfile
     */
    invoiceEngine?: string;
    /**
     * 
     * @type {string}
     * @memberof SupplierExtractionProfile
     */
    supplierName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierExtractionProfile
     */
    supplierNameVariations?: Array<string>;
}

/**
 * 
 * @export
 * @interface SupplierSyncDto
 */
export interface SupplierSyncDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof SupplierSyncDto
     */
    suppliersIds?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierSyncDto
     */
    syncDeliveriesContact?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierSyncDto
     */
    syncEmail?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierSyncDto
     */
    syncInvoicesContact?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierSyncDto
     */
    syncNotes?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierSyncDto
     */
    syncOrderReminders?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SupplierSyncDto
     */
    syncOrdersContact?: boolean;
}

/**
 * 
 * @export
 * @interface Table
 */
export interface Table {
    /**
     * 
     * @type {Array<Row>}
     * @memberof Table
     */
    rows?: Array<Row>;
}

/**
 * 
 * @export
 * @interface TemplateVariable
 */
export interface TemplateVariable {
    /**
     * 
     * @type {string}
     * @memberof TemplateVariable
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateVariable
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof TemplateVariable
     */
    type?: TemplateVariable.TypeEnum;
}

/**
 * @export
 * @namespace TemplateVariable
 */
export namespace TemplateVariable {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        COMPOSITEPARAM = <any> 'COMPOSITE_PARAM',
        FRAGMENT = <any> 'FRAGMENT',
        PATHVARIABLE = <any> 'PATH_VARIABLE',
        REQUESTPARAM = <any> 'REQUEST_PARAM',
        REQUESTPARAMCONTINUED = <any> 'REQUEST_PARAM_CONTINUED',
        SEGMENT = <any> 'SEGMENT'
    }
}

/**
 * 
 * @export
 * @interface TicketResponse
 */
export interface TicketResponse {
    /**
     * 
     * @type {number}
     * @memberof TicketResponse
     */
    ticketID?: number;
}

/**
 * 
 * @export
 * @interface TokenResponse
 */
export interface TokenResponse {
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenResponse
     */
    user?: string;
}

/**
 * 
 * @export
 * @interface TokenSigningKey
 */
export interface TokenSigningKey {
    /**
     * 
     * @type {string}
     * @memberof TokenSigningKey
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenSigningKey
     */
    keyBytes?: string;
    /**
     * 
     * @type {number}
     * @memberof TokenSigningKey
     */
    version?: number;
}

/**
 * 
 * @export
 * @interface Totals
 */
export interface Totals {
    /**
     * 
     * @type {boolean}
     * @memberof Totals
     */
    isManuallyEditedExpectedGP?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Totals
     */
    isManuallyEditedSoldValue?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    totalActualCOGS?: number;
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    totalActualGP?: number;
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    totalActualGPPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    totalExpectedCOGS?: number;
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    totalExpectedGP?: number;
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    totalExpectedGPPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    totalSoldValue?: number;
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    totalVarianceGP?: number;
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    totalVarianceGPPercent?: number;
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    totalWastePercent?: number;
    /**
     * 
     * @type {number}
     * @memberof Totals
     */
    totalWasteValue?: number;
}

/**
 * 
 * @export
 * @interface TransferredProductReq
 */
export interface TransferredProductReq {
    /**
     * 
     * @type {string}
     * @memberof TransferredProductReq
     */
    senderComment?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferredProductReq
     */
    sentProductId?: string;
    /**
     * 
     * @type {number}
     * @memberof TransferredProductReq
     */
    sentQty?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransferredProductReq
     */
    sentQtyInCase?: boolean;
}

/**
 * 
 * @export
 * @interface TransferredProductRes
 */
export interface TransferredProductRes {
    /**
     * 
     * @type {boolean}
     * @memberof TransferredProductRes
     */
    isConfirmed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TransferredProductRes
     */
    receivedBarcode?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferredProductRes
     */
    receivedMeasure?: TransferredProductRes.ReceivedMeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof TransferredProductRes
     */
    receivedName?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferredProductRes
     */
    receivedProductId?: string;
    /**
     * 
     * @type {number}
     * @memberof TransferredProductRes
     */
    receivedQty?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransferredProductRes
     */
    receivedQtyInCase?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransferredProductRes
     */
    receivedSize?: number;
    /**
     * 
     * @type {number}
     * @memberof TransferredProductRes
     */
    receivedTotalCost?: number;
    /**
     * 
     * @type {string}
     * @memberof TransferredProductRes
     */
    receiverComment?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferredProductRes
     */
    senderComment?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferredProductRes
     */
    sentBarcode?: string;
    /**
     * 
     * @type {number}
     * @memberof TransferredProductRes
     */
    sentCasePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof TransferredProductRes
     */
    sentCaseSize?: number;
    /**
     * 
     * @type {string}
     * @memberof TransferredProductRes
     */
    sentCategory?: string;
    /**
     * 
     * @type {string}
     * @memberof TransferredProductRes
     */
    sentMeasure?: TransferredProductRes.SentMeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof TransferredProductRes
     */
    sentName?: string;
    /**
     * 
     * @type {number}
     * @memberof TransferredProductRes
     */
    sentPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof TransferredProductRes
     */
    sentProductId?: string;
    /**
     * 
     * @type {number}
     * @memberof TransferredProductRes
     */
    sentQty?: number;
    /**
     * 
     * @type {boolean}
     * @memberof TransferredProductRes
     */
    sentQtyInCase?: boolean;
    /**
     * 
     * @type {number}
     * @memberof TransferredProductRes
     */
    sentSize?: number;
    /**
     * 
     * @type {string}
     * @memberof TransferredProductRes
     */
    sentSubCategory?: string;
    /**
     * 
     * @type {number}
     * @memberof TransferredProductRes
     */
    sentTotalCost?: number;
    /**
     * 
     * @type {string}
     * @memberof TransferredProductRes
     */
    sentUnit?: TransferredProductRes.SentUnitEnum;
}

/**
 * @export
 * @namespace TransferredProductRes
 */
export namespace TransferredProductRes {
    /**
     * @export
     * @enum {string}
     */
    export enum ReceivedMeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum SentMeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum SentUnitEnum {
        Bottle = <any> 'Bottle',
        Can = <any> 'Can',
        Keg = <any> 'Keg',
        Other = <any> 'Other'
    }
}

/**
 * 
 * @export
 * @interface UploadResponse
 */
export interface UploadResponse {
    /**
     * 
     * @type {string}
     * @memberof UploadResponse
     */
    fileId?: string;
    /**
     * 
     * @type {string}
     * @memberof UploadResponse
     */
    fileName?: string;
}

/**
 * 
 * @export
 * @interface UriTemplate
 */
export interface UriTemplate {
    /**
     * 
     * @type {Array<string>}
     * @memberof UriTemplate
     */
    variableNames?: Array<string>;
    /**
     * 
     * @type {Array<TemplateVariable>}
     * @memberof UriTemplate
     */
    variables?: Array<TemplateVariable>;
}

/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id?: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    isAdmin?: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Array<OrgAuth>}
     * @memberof User
     */
    orgsAuths?: Array<OrgAuth>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    roleOfUser?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof User
     */
    roles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username: string;
}

/**
 * 
 * @export
 * @interface UserAcceptInvitationRequestModel
 */
export interface UserAcceptInvitationRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UserAcceptInvitationRequestModel
     */
    confirmPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAcceptInvitationRequestModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserAcceptInvitationRequestModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserAcceptInvitationRequestModel
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserAcceptInvitationRequestModel
     */
    token: string;
}

/**
 * 
 * @export
 * @interface UserDataModel
 */
export interface UserDataModel {
    /**
     * 
     * @type {string}
     * @memberof UserDataModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataModel
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataModel
     */
    roleOfUser?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDataModel
     */
    username?: string;
}

/**
 * 
 * @export
 * @interface UserInvitation
 */
export interface UserInvitation {
    /**
     * 
     * @type {boolean}
     * @memberof UserInvitation
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserInvitation
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInvitation
     */
    id?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserInvitation
     */
    invitationDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserInvitation
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInvitation
     */
    organization?: string;
    /**
     * 
     * @type {Date}
     * @memberof UserInvitation
     */
    updatedAt?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserInvitation
     */
    username?: string;
}

/**
 * 
 * @export
 * @interface UserInviteRequestModel
 */
export interface UserInviteRequestModel {
    /**
     * 
     * @type {Array<FeatureRequestDto>}
     * @memberof UserInviteRequestModel
     */
    permissionsPerFeatures?: Array<FeatureRequestDto>;
    /**
     * 
     * @type {string}
     * @memberof UserInviteRequestModel
     */
    username: string;
}

/**
 * 
 * @export
 * @interface UserInviteToken
 */
export interface UserInviteToken {
    /**
     * 
     * @type {Date}
     * @memberof UserInviteToken
     */
    expiryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserInviteToken
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInviteToken
     */
    organization?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInviteToken
     */
    roleOfUser?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInviteToken
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof UserInviteToken
     */
    username?: string;
}

/**
 * 
 * @export
 * @interface UserLoginRequestModel
 */
export interface UserLoginRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequestModel
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginRequestModel
     */
    username: string;
}

/**
 * 
 * @export
 * @interface UserLoginSession
 */
export interface UserLoginSession {
    /**
     * 
     * @type {Date}
     * @memberof UserLoginSession
     */
    expiryDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof UserLoginSession
     */
    id?: string;
    /**
     * 
     * @type {User}
     * @memberof UserLoginSession
     */
    user?: User;
}

/**
 * 
 * @export
 * @interface UserLoginSessionRequestModel
 */
export interface UserLoginSessionRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UserLoginSessionRequestModel
     */
    activeOrgId: string;
    /**
     * 
     * @type {string}
     * @memberof UserLoginSessionRequestModel
     */
    sessionId: string;
}

/**
 * 
 * @export
 * @interface UserOrganizations
 */
export interface UserOrganizations {
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof UserOrganizations
     */
    orgIdToOrgName?: { [key: string]: string; };
    /**
     * 
     * @type {Array<OrganizationDto>}
     * @memberof UserOrganizations
     */
    organizations?: Array<OrganizationDto>;
}

/**
 * 
 * @export
 * @interface UserPasswordChangeRequestModel
 */
export interface UserPasswordChangeRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UserPasswordChangeRequestModel
     */
    confirmNewPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPasswordChangeRequestModel
     */
    newPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPasswordChangeRequestModel
     */
    oldPassword?: string;
}

/**
 * 
 * @export
 * @interface UserPasswordResetRequestModel
 */
export interface UserPasswordResetRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UserPasswordResetRequestModel
     */
    confirmPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPasswordResetRequestModel
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserPasswordResetRequestModel
     */
    token: string;
}

/**
 * 
 * @export
 * @interface UserRegisterRequestModel
 */
export interface UserRegisterRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequestModel
     */
    confirmPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequestModel
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequestModel
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequestModel
     */
    password?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRegisterRequestModel
     */
    username: string;
}

/**
 * 
 * @export
 * @interface UserRequestsCounter
 */
export interface UserRequestsCounter {
    /**
     * 
     * @type {number}
     * @memberof UserRequestsCounter
     */
    deliveryNotesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRequestsCounter
     */
    deliveryNotesScanningCount?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRequestsCounter
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof UserRequestsCounter
     */
    invoiceCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRequestsCounter
     */
    invoicesScanningCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRequestsCounter
     */
    ordersCount?: number;
    /**
     * 
     * @type {number}
     * @memberof UserRequestsCounter
     */
    supplierScanningCount?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRequestsCounter
     */
    userPlan?: UserRequestsCounter.UserPlanEnum;
    /**
     * 
     * @type {string}
     * @memberof UserRequestsCounter
     */
    username?: string;
    /**
     * 
     * @type {number}
     * @memberof UserRequestsCounter
     */
    version?: number;
}

/**
 * @export
 * @namespace UserRequestsCounter
 */
export namespace UserRequestsCounter {
    /**
     * @export
     * @enum {string}
     */
    export enum UserPlanEnum {
        LIMITED = <any> 'LIMITED',
        UNLIMITED = <any> 'UNLIMITED'
    }
}

/**
 * 
 * @export
 * @interface UserResponseModel
 */
export interface UserResponseModel {
    /**
     * 
     * @type {boolean}
     * @memberof UserResponseModel
     */
    active?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserResponseModel
     */
    creationDate?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseModel
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseModel
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserResponseModel
     */
    lastName?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponseModel
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Array<OrgAuth>}
     * @memberof UserResponseModel
     */
    orgsAuths?: Array<OrgAuth>;
    /**
     * 
     * @type {string}
     * @memberof UserResponseModel
     */
    roleOfUser?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserResponseModel
     */
    roles?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof UserResponseModel
     */
    username?: string;
}

/**
 * 
 * @export
 * @interface View
 */
export interface View {
    /**
     * 
     * @type {string}
     * @memberof View
     */
    contentType?: string;
}

/**
 * 
 * @export
 * @interface Waste
 */
export interface Waste {
    /**
     * 
     * @type {string}
     * @memberof Waste
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof Waste
     */
    dishName?: string;
    /**
     * 
     * @type {Array<GrowyzeFile>}
     * @memberof Waste
     */
    files?: Array<GrowyzeFile>;
    /**
     * 
     * @type {number}
     * @memberof Waste
     */
    fullQty?: number;
    /**
     * 
     * @type {string}
     * @memberof Waste
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof Waste
     */
    partialQty?: number;
    /**
     * 
     * @type {number}
     * @memberof Waste
     */
    partialQtyInProductMeasure?: number;
    /**
     * 
     * @type {string}
     * @memberof Waste
     */
    reason?: Waste.ReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof Waste
     */
    recipeName?: string;
    /**
     * 
     * @type {string}
     * @memberof Waste
     */
    reporter?: string;
    /**
     * 
     * @type {Date}
     * @memberof Waste
     */
    timeOfRecord?: Date;
    /**
     * 
     * @type {number}
     * @memberof Waste
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof Waste
     */
    totalQty?: number;
    /**
     * 
     * @type {string}
     * @memberof Waste
     */
    wasteDishRecordId?: string;
    /**
     * 
     * @type {string}
     * @memberof Waste
     */
    wasteMeasure?: Waste.WasteMeasureEnum;
    /**
     * 
     * @type {string}
     * @memberof Waste
     */
    wasteOrigin?: Waste.WasteOriginEnum;
    /**
     * 
     * @type {string}
     * @memberof Waste
     */
    wasteRecipeRecordId?: string;
}

/**
 * @export
 * @namespace Waste
 */
export namespace Waste {
    /**
     * @export
     * @enum {string}
     */
    export enum ReasonEnum {
        BREAKAGE = <any> 'BREAKAGE',
        OTHER = <any> 'OTHER',
        OUTOFDATE = <any> 'OUT_OF_DATE',
        SPILLAGE = <any> 'SPILLAGE',
        STAFFMEALS = <any> 'STAFF_MEALS'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum WasteMeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum WasteOriginEnum {
        MANUALLY = <any> 'MANUALLY',
        SQUARE = <any> 'SQUARE'
    }
}

/**
 * 
 * @export
 * @interface WasteDish
 */
export interface WasteDish {
    /**
     * 
     * @type {WasteDishDto}
     * @memberof WasteDish
     */
    dish?: WasteDishDto;
    /**
     * 
     * @type {number}
     * @memberof WasteDish
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof WasteDish
     */
    totalQty?: number;
    /**
     * 
     * @type {Array<WasteElementRecord>}
     * @memberof WasteDish
     */
    wastesPerDay?: Array<WasteElementRecord>;
}

/**
 * 
 * @export
 * @interface WasteDishDto
 */
export interface WasteDishDto {
    /**
     * 
     * @type {string}
     * @memberof WasteDishDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WasteDishDto
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface WasteDishPayload
 */
export interface WasteDishPayload {
    /**
     * 
     * @type {string}
     * @memberof WasteDishPayload
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof WasteDishPayload
     */
    date?: string;
    /**
     * 
     * @type {string}
     * @memberof WasteDishPayload
     */
    dishId?: string;
    /**
     * 
     * @type {Array<GrowyzeFile>}
     * @memberof WasteDishPayload
     */
    files?: Array<GrowyzeFile>;
    /**
     * 
     * @type {number}
     * @memberof WasteDishPayload
     */
    fullQty?: number;
    /**
     * 
     * @type {number}
     * @memberof WasteDishPayload
     */
    partialQty?: number;
    /**
     * 
     * @type {string}
     * @memberof WasteDishPayload
     */
    reason?: WasteDishPayload.ReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof WasteDishPayload
     */
    reporter?: string;
}

/**
 * @export
 * @namespace WasteDishPayload
 */
export namespace WasteDishPayload {
    /**
     * @export
     * @enum {string}
     */
    export enum ReasonEnum {
        BREAKAGE = <any> 'BREAKAGE',
        OTHER = <any> 'OTHER',
        OUTOFDATE = <any> 'OUT_OF_DATE',
        SPILLAGE = <any> 'SPILLAGE',
        STAFFMEALS = <any> 'STAFF_MEALS'
    }
}

/**
 * 
 * @export
 * @interface WasteElementPayloadUpdate
 */
export interface WasteElementPayloadUpdate {
    /**
     * 
     * @type {string}
     * @memberof WasteElementPayloadUpdate
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof WasteElementPayloadUpdate
     */
    fullQty?: number;
    /**
     * 
     * @type {number}
     * @memberof WasteElementPayloadUpdate
     */
    partialQty?: number;
    /**
     * 
     * @type {string}
     * @memberof WasteElementPayloadUpdate
     */
    reason?: WasteElementPayloadUpdate.ReasonEnum;
}

/**
 * @export
 * @namespace WasteElementPayloadUpdate
 */
export namespace WasteElementPayloadUpdate {
    /**
     * @export
     * @enum {string}
     */
    export enum ReasonEnum {
        BREAKAGE = <any> 'BREAKAGE',
        OTHER = <any> 'OTHER',
        OUTOFDATE = <any> 'OUT_OF_DATE',
        SPILLAGE = <any> 'SPILLAGE',
        STAFFMEALS = <any> 'STAFF_MEALS'
    }
}

/**
 * 
 * @export
 * @interface WasteElementRecord
 */
export interface WasteElementRecord {
    /**
     * 
     * @type {string}
     * @memberof WasteElementRecord
     */
    comment?: string;
    /**
     * 
     * @type {Array<GrowyzeFile>}
     * @memberof WasteElementRecord
     */
    files?: Array<GrowyzeFile>;
    /**
     * 
     * @type {number}
     * @memberof WasteElementRecord
     */
    fullQty?: number;
    /**
     * 
     * @type {string}
     * @memberof WasteElementRecord
     */
    id?: string;
    /**
     * 
     * @type {number}
     * @memberof WasteElementRecord
     */
    partialQty?: number;
    /**
     * 
     * @type {string}
     * @memberof WasteElementRecord
     */
    reason?: WasteElementRecord.ReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof WasteElementRecord
     */
    reporter?: string;
    /**
     * 
     * @type {Date}
     * @memberof WasteElementRecord
     */
    timeOfRecord?: Date;
    /**
     * 
     * @type {number}
     * @memberof WasteElementRecord
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof WasteElementRecord
     */
    totalQty?: number;
    /**
     * 
     * @type {string}
     * @memberof WasteElementRecord
     */
    wasteOrigin?: WasteElementRecord.WasteOriginEnum;
}

/**
 * @export
 * @namespace WasteElementRecord
 */
export namespace WasteElementRecord {
    /**
     * @export
     * @enum {string}
     */
    export enum ReasonEnum {
        BREAKAGE = <any> 'BREAKAGE',
        OTHER = <any> 'OTHER',
        OUTOFDATE = <any> 'OUT_OF_DATE',
        SPILLAGE = <any> 'SPILLAGE',
        STAFFMEALS = <any> 'STAFF_MEALS'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum WasteOriginEnum {
        MANUALLY = <any> 'MANUALLY',
        SQUARE = <any> 'SQUARE'
    }
}

/**
 * 
 * @export
 * @interface WasteFilesDto
 */
export interface WasteFilesDto {
    /**
     * 
     * @type {Array<GrowyzeFile>}
     * @memberof WasteFilesDto
     */
    files?: Array<GrowyzeFile>;
}

/**
 * 
 * @export
 * @interface WasteGroup
 */
export interface WasteGroup {
    /**
     * 
     * @type {string}
     * @memberof WasteGroup
     */
    date?: string;
    /**
     * 
     * @type {Array<WasteDish>}
     * @memberof WasteGroup
     */
    dishes?: Array<WasteDish>;
    /**
     * 
     * @type {string}
     * @memberof WasteGroup
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WasteGroup
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Array<WasteProduct>}
     * @memberof WasteGroup
     */
    products?: Array<WasteProduct>;
    /**
     * 
     * @type {Array<WasteRecipe>}
     * @memberof WasteGroup
     */
    recipes?: Array<WasteRecipe>;
    /**
     * 
     * @type {number}
     * @memberof WasteGroup
     */
    totalCost?: number;
    /**
     * 
     * @type {Date}
     * @memberof WasteGroup
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface WasteGroupWithOrgsNames
 */
export interface WasteGroupWithOrgsNames {
    /**
     * 
     * @type {string}
     * @memberof WasteGroupWithOrgsNames
     */
    date?: string;
    /**
     * 
     * @type {Array<WasteDish>}
     * @memberof WasteGroupWithOrgsNames
     */
    dishes?: Array<WasteDish>;
    /**
     * 
     * @type {string}
     * @memberof WasteGroupWithOrgsNames
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WasteGroupWithOrgsNames
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof WasteGroupWithOrgsNames
     */
    organizationsNames?: Array<string>;
    /**
     * 
     * @type {Array<WasteProduct>}
     * @memberof WasteGroupWithOrgsNames
     */
    products?: Array<WasteProduct>;
    /**
     * 
     * @type {Array<WasteRecipe>}
     * @memberof WasteGroupWithOrgsNames
     */
    recipes?: Array<WasteRecipe>;
    /**
     * 
     * @type {number}
     * @memberof WasteGroupWithOrgsNames
     */
    totalCost?: number;
    /**
     * 
     * @type {Date}
     * @memberof WasteGroupWithOrgsNames
     */
    updatedAt?: Date;
}

/**
 * 
 * @export
 * @interface WastePayload
 */
export interface WastePayload {
    /**
     * 
     * @type {string}
     * @memberof WastePayload
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof WastePayload
     */
    date?: string;
    /**
     * 
     * @type {Array<GrowyzeFile>}
     * @memberof WastePayload
     */
    files?: Array<GrowyzeFile>;
    /**
     * 
     * @type {number}
     * @memberof WastePayload
     */
    fullQty?: number;
    /**
     * 
     * @type {number}
     * @memberof WastePayload
     */
    partialQty?: number;
    /**
     * 
     * @type {ProductPayload}
     * @memberof WastePayload
     */
    product?: ProductPayload;
    /**
     * 
     * @type {string}
     * @memberof WastePayload
     */
    reason?: WastePayload.ReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof WastePayload
     */
    reporter?: string;
    /**
     * 
     * @type {string}
     * @memberof WastePayload
     */
    wasteMeasure?: WastePayload.WasteMeasureEnum;
}

/**
 * @export
 * @namespace WastePayload
 */
export namespace WastePayload {
    /**
     * @export
     * @enum {string}
     */
    export enum ReasonEnum {
        BREAKAGE = <any> 'BREAKAGE',
        OTHER = <any> 'OTHER',
        OUTOFDATE = <any> 'OUT_OF_DATE',
        SPILLAGE = <any> 'SPILLAGE',
        STAFFMEALS = <any> 'STAFF_MEALS'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum WasteMeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
}

/**
 * 
 * @export
 * @interface WastePayloadUpdate
 */
export interface WastePayloadUpdate {
    /**
     * 
     * @type {string}
     * @memberof WastePayloadUpdate
     */
    comment?: string;
    /**
     * 
     * @type {number}
     * @memberof WastePayloadUpdate
     */
    fullQty?: number;
    /**
     * 
     * @type {number}
     * @memberof WastePayloadUpdate
     */
    partialQty?: number;
    /**
     * 
     * @type {string}
     * @memberof WastePayloadUpdate
     */
    reason?: WastePayloadUpdate.ReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof WastePayloadUpdate
     */
    wasteMeasure?: WastePayloadUpdate.WasteMeasureEnum;
}

/**
 * @export
 * @namespace WastePayloadUpdate
 */
export namespace WastePayloadUpdate {
    /**
     * @export
     * @enum {string}
     */
    export enum ReasonEnum {
        BREAKAGE = <any> 'BREAKAGE',
        OTHER = <any> 'OTHER',
        OUTOFDATE = <any> 'OUT_OF_DATE',
        SPILLAGE = <any> 'SPILLAGE',
        STAFFMEALS = <any> 'STAFF_MEALS'
    }
    /**
     * @export
     * @enum {string}
     */
    export enum WasteMeasureEnum {
        L = <any> 'L',
        Cl = <any> 'cl',
        Each = <any> 'each',
        FlOzUK = <any> 'fl_oz_UK',
        G = <any> 'g',
        Gal = <any> 'gal',
        HfPtUK = <any> 'hf_pt_UK',
        Kg = <any> 'kg',
        Lbs = <any> 'lbs',
        Ml = <any> 'ml',
        Oz = <any> 'oz',
        PtUK = <any> 'pt_UK'
    }
}

/**
 * 
 * @export
 * @interface WastePerDay
 */
export interface WastePerDay {
    /**
     * 
     * @type {string}
     * @memberof WastePerDay
     */
    date?: string;
    /**
     * 
     * @type {number}
     * @memberof WastePerDay
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof WastePerDay
     */
    totalQty?: number;
    /**
     * 
     * @type {Array<Waste>}
     * @memberof WastePerDay
     */
    wastesPerDay?: Array<Waste>;
}

/**
 * 
 * @export
 * @interface WasteProduct
 */
export interface WasteProduct {
    /**
     * 
     * @type {Product}
     * @memberof WasteProduct
     */
    product?: Product;
    /**
     * 
     * @type {number}
     * @memberof WasteProduct
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof WasteProduct
     */
    totalQty?: number;
    /**
     * 
     * @type {Array<Waste>}
     * @memberof WasteProduct
     */
    wastesPerDay?: Array<Waste>;
}

/**
 * 
 * @export
 * @interface WasteProductReport
 */
export interface WasteProductReport {
    /**
     * 
     * @type {Product}
     * @memberof WasteProductReport
     */
    product?: Product;
    /**
     * 
     * @type {number}
     * @memberof WasteProductReport
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof WasteProductReport
     */
    totalQty?: number;
    /**
     * 
     * @type {Array<WastePerDay>}
     * @memberof WasteProductReport
     */
    wastesForPeriod?: Array<WastePerDay>;
}

/**
 * 
 * @export
 * @interface WasteRecipe
 */
export interface WasteRecipe {
    /**
     * 
     * @type {WasteRecipeDto}
     * @memberof WasteRecipe
     */
    recipe?: WasteRecipeDto;
    /**
     * 
     * @type {number}
     * @memberof WasteRecipe
     */
    totalCost?: number;
    /**
     * 
     * @type {number}
     * @memberof WasteRecipe
     */
    totalQty?: number;
    /**
     * 
     * @type {Array<WasteElementRecord>}
     * @memberof WasteRecipe
     */
    wastesPerDay?: Array<WasteElementRecord>;
}

/**
 * 
 * @export
 * @interface WasteRecipeDto
 */
export interface WasteRecipeDto {
    /**
     * 
     * @type {string}
     * @memberof WasteRecipeDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WasteRecipeDto
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface WasteRecipePayload
 */
export interface WasteRecipePayload {
    /**
     * 
     * @type {string}
     * @memberof WasteRecipePayload
     */
    comment?: string;
    /**
     * 
     * @type {string}
     * @memberof WasteRecipePayload
     */
    date?: string;
    /**
     * 
     * @type {Array<GrowyzeFile>}
     * @memberof WasteRecipePayload
     */
    files?: Array<GrowyzeFile>;
    /**
     * 
     * @type {number}
     * @memberof WasteRecipePayload
     */
    fullQty?: number;
    /**
     * 
     * @type {number}
     * @memberof WasteRecipePayload
     */
    partialQty?: number;
    /**
     * 
     * @type {string}
     * @memberof WasteRecipePayload
     */
    reason?: WasteRecipePayload.ReasonEnum;
    /**
     * 
     * @type {string}
     * @memberof WasteRecipePayload
     */
    recipeId?: string;
    /**
     * 
     * @type {string}
     * @memberof WasteRecipePayload
     */
    reporter?: string;
}

/**
 * @export
 * @namespace WasteRecipePayload
 */
export namespace WasteRecipePayload {
    /**
     * @export
     * @enum {string}
     */
    export enum ReasonEnum {
        BREAKAGE = <any> 'BREAKAGE',
        OTHER = <any> 'OTHER',
        OUTOFDATE = <any> 'OUT_OF_DATE',
        SPILLAGE = <any> 'SPILLAGE',
        STAFFMEALS = <any> 'STAFF_MEALS'
    }
}

/**
 * 
 * @export
 * @interface WasteReport
 */
export interface WasteReport {
    /**
     * 
     * @type {string}
     * @memberof WasteReport
     */
    fromDate?: string;
    /**
     * 
     * @type {Array<WasteProductReport>}
     * @memberof WasteReport
     */
    products?: Array<WasteProductReport>;
    /**
     * 
     * @type {string}
     * @memberof WasteReport
     */
    toDate?: string;
    /**
     * 
     * @type {number}
     * @memberof WasteReport
     */
    totalCost?: number;
}

/**
 * 
 * @export
 * @interface Weekdays
 */
export interface Weekdays {
    /**
     * 
     * @type {boolean}
     * @memberof Weekdays
     */
    friday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Weekdays
     */
    monday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Weekdays
     */
    saturday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Weekdays
     */
    sunday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Weekdays
     */
    thursday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Weekdays
     */
    tuesday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Weekdays
     */
    wednesday?: boolean;
}

/**
 * 
 * @export
 * @interface WooCommercePosIntegration
 */
export interface WooCommercePosIntegration {
    /**
     * 
     * @type {string}
     * @memberof WooCommercePosIntegration
     */
    consumerKey?: string;
    /**
     * 
     * @type {string}
     * @memberof WooCommercePosIntegration
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof WooCommercePosIntegration
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WooCommercePosIntegration
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WooCommercePosIntegration
     */
    storeUrl?: string;
}

/**
 * 
 * @export
 * @interface WooCommercePosIntegrationCreatePayload
 */
export interface WooCommercePosIntegrationCreatePayload {
    /**
     * 
     * @type {string}
     * @memberof WooCommercePosIntegrationCreatePayload
     */
    consumerKey?: string;
    /**
     * 
     * @type {string}
     * @memberof WooCommercePosIntegrationCreatePayload
     */
    consumerSecret?: string;
    /**
     * 
     * @type {string}
     * @memberof WooCommercePosIntegrationCreatePayload
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WooCommercePosIntegrationCreatePayload
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof WooCommercePosIntegrationCreatePayload
     */
    storeUrl?: string;
}

/**
 * 
 * @export
 * @interface WrapperProductPriceForSupplier
 */
export interface WrapperProductPriceForSupplier {
    /**
     * 
     * @type {string}
     * @memberof WrapperProductPriceForSupplier
     */
    barcode?: string;
    /**
     * 
     * @type {string}
     * @memberof WrapperProductPriceForSupplier
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof WrapperProductPriceForSupplier
     */
    price?: number;
    /**
     * 
     * @type {string}
     * @memberof WrapperProductPriceForSupplier
     */
    supplierName?: string;
}

/**
 * 
 * @export
 * @interface XeroAccount
 */
export interface XeroAccount {
    /**
     * 
     * @type {string}
     * @memberof XeroAccount
     */
    code?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroAccount
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroAccount
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroAccount
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroAccount
     */
    type?: XeroAccount.TypeEnum;
}

/**
 * @export
 * @namespace XeroAccount
 */
export namespace XeroAccount {
    /**
     * @export
     * @enum {string}
     */
    export enum TypeEnum {
        BANK = <any> 'BANK',
        CURRENT = <any> 'CURRENT',
        CURRLIAB = <any> 'CURRLIAB',
        DEPRECIATN = <any> 'DEPRECIATN',
        DIRECTCOSTS = <any> 'DIRECTCOSTS',
        EQUITY = <any> 'EQUITY',
        EXPENSE = <any> 'EXPENSE',
        FIXED = <any> 'FIXED',
        INVENTORY = <any> 'INVENTORY',
        LIABILITY = <any> 'LIABILITY',
        NONCURRENT = <any> 'NONCURRENT',
        OTHERINCOME = <any> 'OTHERINCOME',
        OVERHEADS = <any> 'OVERHEADS',
        PAYG = <any> 'PAYG',
        PAYGLIABILITY = <any> 'PAYGLIABILITY',
        PREPAYMENT = <any> 'PREPAYMENT',
        REVENUE = <any> 'REVENUE',
        SALES = <any> 'SALES',
        SUPERANNUATIONEXPENSE = <any> 'SUPERANNUATIONEXPENSE',
        SUPERANNUATIONLIABILITY = <any> 'SUPERANNUATIONLIABILITY',
        TERMLIAB = <any> 'TERMLIAB',
        WAGESEXPENSE = <any> 'WAGESEXPENSE'
    }
}

/**
 * 
 * @export
 * @interface XeroIntegration
 */
export interface XeroIntegration {
    /**
     * 
     * @type {Connection}
     * @memberof XeroIntegration
     */
    connection?: Connection;
    /**
     * 
     * @type {string}
     * @memberof XeroIntegration
     */
    id?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof XeroIntegration
     */
    organizations?: Array<string>;
}

/**
 * 
 * @export
 * @interface XeroIntegrationCreatePayload
 */
export interface XeroIntegrationCreatePayload {
    /**
     * 
     * @type {string}
     * @memberof XeroIntegrationCreatePayload
     */
    code?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof XeroIntegrationCreatePayload
     */
    organizations?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof XeroIntegrationCreatePayload
     */
    redirectUrl?: string;
}

/**
 * 
 * @export
 * @interface XeroInvoice
 */
export interface XeroInvoice {
    /**
     * 
     * @type {XeroAccount}
     * @memberof XeroInvoice
     */
    account?: XeroAccount;
    /**
     * 
     * @type {string}
     * @memberof XeroInvoice
     */
    description?: string;
    /**
     * 
     * @type {Date}
     * @memberof XeroInvoice
     */
    dueDate?: Date;
    /**
     * 
     * @type {number}
     * @memberof XeroInvoice
     */
    grossTotalCost?: number;
    /**
     * 
     * @type {Date}
     * @memberof XeroInvoice
     */
    invoiceDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof XeroInvoice
     */
    invoiceNumber?: string;
    /**
     * 
     * @type {Array<XeroLineItem>}
     * @memberof XeroInvoice
     */
    lineItems?: Array<XeroLineItem>;
    /**
     * 
     * @type {number}
     * @memberof XeroInvoice
     */
    netCost?: number;
    /**
     * 
     * @type {number}
     * @memberof XeroInvoice
     */
    totalVat?: number;
    /**
     * 
     * @type {XeroSupplier}
     * @memberof XeroInvoice
     */
    xeroSupplier?: XeroSupplier;
}

/**
 * 
 * @export
 * @interface XeroLineItem
 */
export interface XeroLineItem {
    /**
     * 
     * @type {XeroAccount}
     * @memberof XeroLineItem
     */
    account?: XeroAccount;
    /**
     * 
     * @type {string}
     * @memberof XeroLineItem
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof XeroLineItem
     */
    netCost?: number;
}

/**
 * 
 * @export
 * @interface XeroSupplier
 */
export interface XeroSupplier {
    /**
     * 
     * @type {string}
     * @memberof XeroSupplier
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroSupplier
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof XeroSupplier
     */
    name?: string;
}

/**
 * 
 * @export
 * @interface XeroTaxRate
 */
export interface XeroTaxRate {
    /**
     * 
     * @type {string}
     * @memberof XeroTaxRate
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof XeroTaxRate
     */
    taxRate?: number;
    /**
     * 
     * @type {string}
     * @memberof XeroTaxRate
     */
    taxType?: string;
}


/**
 * AccountControllerApi - fetch parameter creator
 * @export
 */
export const AccountControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createDemoCompanyAccountFromOrg
         * @param {string} fromOrgId fromOrgId
         * @param {string} newOrgName newOrgName
         * @param {string} toUsername toUsername
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoCompanyAccountFromOrgUsingPOST(fromOrgId: string, newOrgName: string, toUsername: string, options: any = {}): FetchArgs {
            // verify required parameter 'fromOrgId' is not null or undefined
            if (fromOrgId === null || fromOrgId === undefined) {
                throw new RequiredError('fromOrgId','Required parameter fromOrgId was null or undefined when calling createDemoCompanyAccountFromOrgUsingPOST.');
            }
            // verify required parameter 'newOrgName' is not null or undefined
            if (newOrgName === null || newOrgName === undefined) {
                throw new RequiredError('newOrgName','Required parameter newOrgName was null or undefined when calling createDemoCompanyAccountFromOrgUsingPOST.');
            }
            // verify required parameter 'toUsername' is not null or undefined
            if (toUsername === null || toUsername === undefined) {
                throw new RequiredError('toUsername','Required parameter toUsername was null or undefined when calling createDemoCompanyAccountFromOrgUsingPOST.');
            }
            const localVarPath = `/v1/accounts/copy`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromOrgId !== undefined) {
                localVarQueryParameter['fromOrgId'] = fromOrgId;
            }

            if (newOrgName !== undefined) {
                localVarQueryParameter['newOrgName'] = newOrgName;
            }

            if (toUsername !== undefined) {
                localVarQueryParameter['toUsername'] = toUsername;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createDemoCompanyAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoCompanyAccountUsingPOST(options: any = {}): FetchArgs {
            const localVarPath = `/v1/accounts/demo`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialCopyCompanyAccount
         * @param {string} fromOrgId fromOrgId
         * @param {string} newOrgName newOrgName
         * @param {string} toUsername toUsername
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialCopyCompanyAccountUsingPOST(fromOrgId: string, newOrgName: string, toUsername: string, options: any = {}): FetchArgs {
            // verify required parameter 'fromOrgId' is not null or undefined
            if (fromOrgId === null || fromOrgId === undefined) {
                throw new RequiredError('fromOrgId','Required parameter fromOrgId was null or undefined when calling partialCopyCompanyAccountUsingPOST.');
            }
            // verify required parameter 'newOrgName' is not null or undefined
            if (newOrgName === null || newOrgName === undefined) {
                throw new RequiredError('newOrgName','Required parameter newOrgName was null or undefined when calling partialCopyCompanyAccountUsingPOST.');
            }
            // verify required parameter 'toUsername' is not null or undefined
            if (toUsername === null || toUsername === undefined) {
                throw new RequiredError('toUsername','Required parameter toUsername was null or undefined when calling partialCopyCompanyAccountUsingPOST.');
            }
            const localVarPath = `/v1/accounts/partial-copy`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fromOrgId !== undefined) {
                localVarQueryParameter['fromOrgId'] = fromOrgId;
            }

            if (newOrgName !== undefined) {
                localVarQueryParameter['newOrgName'] = newOrgName;
            }

            if (toUsername !== undefined) {
                localVarQueryParameter['toUsername'] = toUsername;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountControllerApi - functional programming interface
 * @export
 */
export const AccountControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createDemoCompanyAccountFromOrg
         * @param {string} fromOrgId fromOrgId
         * @param {string} newOrgName newOrgName
         * @param {string} toUsername toUsername
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoCompanyAccountFromOrgUsingPOST(fromOrgId: string, newOrgName: string, toUsername: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).createDemoCompanyAccountFromOrgUsingPOST(fromOrgId, newOrgName, toUsername, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createDemoCompanyAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoCompanyAccountUsingPOST(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).createDemoCompanyAccountUsingPOST(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary partialCopyCompanyAccount
         * @param {string} fromOrgId fromOrgId
         * @param {string} newOrgName newOrgName
         * @param {string} toUsername toUsername
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialCopyCompanyAccountUsingPOST(fromOrgId: string, newOrgName: string, toUsername: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AccountControllerApiFetchParamCreator(configuration).partialCopyCompanyAccountUsingPOST(fromOrgId, newOrgName, toUsername, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountControllerApi - factory interface
 * @export
 */
export const AccountControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createDemoCompanyAccountFromOrg
         * @param {string} fromOrgId fromOrgId
         * @param {string} newOrgName newOrgName
         * @param {string} toUsername toUsername
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoCompanyAccountFromOrgUsingPOST(fromOrgId: string, newOrgName: string, toUsername: string, options?: any) {
            return AccountControllerApiFp(configuration).createDemoCompanyAccountFromOrgUsingPOST(fromOrgId, newOrgName, toUsername, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createDemoCompanyAccount
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDemoCompanyAccountUsingPOST(options?: any) {
            return AccountControllerApiFp(configuration).createDemoCompanyAccountUsingPOST(options)(fetch, basePath);
        },
        /**
         * 
         * @summary partialCopyCompanyAccount
         * @param {string} fromOrgId fromOrgId
         * @param {string} newOrgName newOrgName
         * @param {string} toUsername toUsername
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialCopyCompanyAccountUsingPOST(fromOrgId: string, newOrgName: string, toUsername: string, options?: any) {
            return AccountControllerApiFp(configuration).partialCopyCompanyAccountUsingPOST(fromOrgId, newOrgName, toUsername, options)(fetch, basePath);
        },
    };
};

/**
 * AccountControllerApi - object-oriented interface
 * @export
 * @class AccountControllerApi
 * @extends {BaseAPI}
 */
export class AccountControllerApi extends BaseAPI {
    /**
     * 
     * @summary createDemoCompanyAccountFromOrg
     * @param {string} fromOrgId fromOrgId
     * @param {string} newOrgName newOrgName
     * @param {string} toUsername toUsername
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public createDemoCompanyAccountFromOrgUsingPOST(fromOrgId: string, newOrgName: string, toUsername: string, options?: any) {
        return AccountControllerApiFp(this.configuration).createDemoCompanyAccountFromOrgUsingPOST(fromOrgId, newOrgName, toUsername, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createDemoCompanyAccount
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public createDemoCompanyAccountUsingPOST(options?: any) {
        return AccountControllerApiFp(this.configuration).createDemoCompanyAccountUsingPOST(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary partialCopyCompanyAccount
     * @param {string} fromOrgId fromOrgId
     * @param {string} newOrgName newOrgName
     * @param {string} toUsername toUsername
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountControllerApi
     */
    public partialCopyCompanyAccountUsingPOST(fromOrgId: string, newOrgName: string, toUsername: string, options?: any) {
        return AccountControllerApiFp(this.configuration).partialCopyCompanyAccountUsingPOST(fromOrgId, newOrgName, toUsername, options)(this.fetch, this.basePath);
    }

}

/**
 * AdminLoginSessionEntityApi - fetch parameter creator
 * @export
 */
export const AdminLoginSessionEntityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAdminLoginSession
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminLoginSessionUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAdminLoginSessionUsingDELETE.');
            }
            const localVarPath = `/adminLoginSessions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAllByExpiryDateIsLessThanEqualAdminLoginSession
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByExpiryDateIsLessThanEqualAdminLoginSessionUsingGET(now?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/adminLoginSessions/search/deleteAllByExpiryDateIsLessThanEqual`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (now !== undefined) {
                localVarQueryParameter['now'] = (now as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllAdminLoginSession
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminLoginSessionUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/adminLoginSessions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdAdminLoginSession
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdAdminLoginSessionUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdAdminLoginSessionUsingGET.');
            }
            const localVarPath = `/adminLoginSessions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdAndExpiryDateIsGreaterThanEqualAdminLoginSession
         * @param {string} [id] id
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdAndExpiryDateIsGreaterThanEqualAdminLoginSessionUsingGET(id?: string, now?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/adminLoginSessions/search/findByIdAndExpiryDateIsGreaterThanEqual`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (now !== undefined) {
                localVarQueryParameter['now'] = (now as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByUserIdAdminLoginSession
         * @param {string} [userId] userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUserIdAdminLoginSessionUsingGET(userId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/adminLoginSessions/search/findByUserId`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveAdminLoginSession
         * @param {AdminLoginSession} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAdminLoginSessionUsingPATCH(body: AdminLoginSession, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveAdminLoginSessionUsingPATCH.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveAdminLoginSessionUsingPATCH.');
            }
            const localVarPath = `/adminLoginSessions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdminLoginSession" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveAdminLoginSession
         * @param {AdminLoginSession} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAdminLoginSessionUsingPOST(body: AdminLoginSession, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveAdminLoginSessionUsingPOST.');
            }
            const localVarPath = `/adminLoginSessions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdminLoginSession" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveAdminLoginSession
         * @param {AdminLoginSession} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAdminLoginSessionUsingPUT(body: AdminLoginSession, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveAdminLoginSessionUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveAdminLoginSessionUsingPUT.');
            }
            const localVarPath = `/adminLoginSessions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AdminLoginSession" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AdminLoginSessionEntityApi - functional programming interface
 * @export
 */
export const AdminLoginSessionEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAdminLoginSession
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminLoginSessionUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminLoginSessionEntityApiFetchParamCreator(configuration).deleteAdminLoginSessionUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAllByExpiryDateIsLessThanEqualAdminLoginSession
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByExpiryDateIsLessThanEqualAdminLoginSessionUsingGET(now?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AdminLoginSessionEntityApiFetchParamCreator(configuration).deleteAllByExpiryDateIsLessThanEqualAdminLoginSessionUsingGET(now, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllAdminLoginSession
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminLoginSessionUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelAdminLoginSession> {
            const localVarFetchArgs = AdminLoginSessionEntityApiFetchParamCreator(configuration).findAllAdminLoginSessionUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByIdAdminLoginSession
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdAdminLoginSessionUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelAdminLoginSession> {
            const localVarFetchArgs = AdminLoginSessionEntityApiFetchParamCreator(configuration).findByIdAdminLoginSessionUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByIdAndExpiryDateIsGreaterThanEqualAdminLoginSession
         * @param {string} [id] id
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdAndExpiryDateIsGreaterThanEqualAdminLoginSessionUsingGET(id?: string, now?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelAdminLoginSession> {
            const localVarFetchArgs = AdminLoginSessionEntityApiFetchParamCreator(configuration).findByIdAndExpiryDateIsGreaterThanEqualAdminLoginSessionUsingGET(id, now, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByUserIdAdminLoginSession
         * @param {string} [userId] userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUserIdAdminLoginSessionUsingGET(userId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelAdminLoginSession> {
            const localVarFetchArgs = AdminLoginSessionEntityApiFetchParamCreator(configuration).findByUserIdAdminLoginSessionUsingGET(userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveAdminLoginSession
         * @param {AdminLoginSession} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAdminLoginSessionUsingPATCH(body: AdminLoginSession, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelAdminLoginSession> {
            const localVarFetchArgs = AdminLoginSessionEntityApiFetchParamCreator(configuration).saveAdminLoginSessionUsingPATCH(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveAdminLoginSession
         * @param {AdminLoginSession} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAdminLoginSessionUsingPOST(body: AdminLoginSession, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelAdminLoginSession> {
            const localVarFetchArgs = AdminLoginSessionEntityApiFetchParamCreator(configuration).saveAdminLoginSessionUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveAdminLoginSession
         * @param {AdminLoginSession} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAdminLoginSessionUsingPUT(body: AdminLoginSession, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelAdminLoginSession> {
            const localVarFetchArgs = AdminLoginSessionEntityApiFetchParamCreator(configuration).saveAdminLoginSessionUsingPUT(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AdminLoginSessionEntityApi - factory interface
 * @export
 */
export const AdminLoginSessionEntityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteAdminLoginSession
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAdminLoginSessionUsingDELETE(id: string, options?: any) {
            return AdminLoginSessionEntityApiFp(configuration).deleteAdminLoginSessionUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAllByExpiryDateIsLessThanEqualAdminLoginSession
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByExpiryDateIsLessThanEqualAdminLoginSessionUsingGET(now?: Date, options?: any) {
            return AdminLoginSessionEntityApiFp(configuration).deleteAllByExpiryDateIsLessThanEqualAdminLoginSessionUsingGET(now, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllAdminLoginSession
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAdminLoginSessionUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return AdminLoginSessionEntityApiFp(configuration).findAllAdminLoginSessionUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByIdAdminLoginSession
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdAdminLoginSessionUsingGET(id: string, options?: any) {
            return AdminLoginSessionEntityApiFp(configuration).findByIdAdminLoginSessionUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByIdAndExpiryDateIsGreaterThanEqualAdminLoginSession
         * @param {string} [id] id
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdAndExpiryDateIsGreaterThanEqualAdminLoginSessionUsingGET(id?: string, now?: Date, options?: any) {
            return AdminLoginSessionEntityApiFp(configuration).findByIdAndExpiryDateIsGreaterThanEqualAdminLoginSessionUsingGET(id, now, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByUserIdAdminLoginSession
         * @param {string} [userId] userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUserIdAdminLoginSessionUsingGET(userId?: string, options?: any) {
            return AdminLoginSessionEntityApiFp(configuration).findByUserIdAdminLoginSessionUsingGET(userId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveAdminLoginSession
         * @param {AdminLoginSession} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAdminLoginSessionUsingPATCH(body: AdminLoginSession, id: string, options?: any) {
            return AdminLoginSessionEntityApiFp(configuration).saveAdminLoginSessionUsingPATCH(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveAdminLoginSession
         * @param {AdminLoginSession} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAdminLoginSessionUsingPOST(body: AdminLoginSession, options?: any) {
            return AdminLoginSessionEntityApiFp(configuration).saveAdminLoginSessionUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveAdminLoginSession
         * @param {AdminLoginSession} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAdminLoginSessionUsingPUT(body: AdminLoginSession, id: string, options?: any) {
            return AdminLoginSessionEntityApiFp(configuration).saveAdminLoginSessionUsingPUT(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * AdminLoginSessionEntityApi - object-oriented interface
 * @export
 * @class AdminLoginSessionEntityApi
 * @extends {BaseAPI}
 */
export class AdminLoginSessionEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteAdminLoginSession
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLoginSessionEntityApi
     */
    public deleteAdminLoginSessionUsingDELETE(id: string, options?: any) {
        return AdminLoginSessionEntityApiFp(this.configuration).deleteAdminLoginSessionUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAllByExpiryDateIsLessThanEqualAdminLoginSession
     * @param {Date} [now] now
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLoginSessionEntityApi
     */
    public deleteAllByExpiryDateIsLessThanEqualAdminLoginSessionUsingGET(now?: Date, options?: any) {
        return AdminLoginSessionEntityApiFp(this.configuration).deleteAllByExpiryDateIsLessThanEqualAdminLoginSessionUsingGET(now, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllAdminLoginSession
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLoginSessionEntityApi
     */
    public findAllAdminLoginSessionUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return AdminLoginSessionEntityApiFp(this.configuration).findAllAdminLoginSessionUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByIdAdminLoginSession
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLoginSessionEntityApi
     */
    public findByIdAdminLoginSessionUsingGET(id: string, options?: any) {
        return AdminLoginSessionEntityApiFp(this.configuration).findByIdAdminLoginSessionUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByIdAndExpiryDateIsGreaterThanEqualAdminLoginSession
     * @param {string} [id] id
     * @param {Date} [now] now
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLoginSessionEntityApi
     */
    public findByIdAndExpiryDateIsGreaterThanEqualAdminLoginSessionUsingGET(id?: string, now?: Date, options?: any) {
        return AdminLoginSessionEntityApiFp(this.configuration).findByIdAndExpiryDateIsGreaterThanEqualAdminLoginSessionUsingGET(id, now, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByUserIdAdminLoginSession
     * @param {string} [userId] userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLoginSessionEntityApi
     */
    public findByUserIdAdminLoginSessionUsingGET(userId?: string, options?: any) {
        return AdminLoginSessionEntityApiFp(this.configuration).findByUserIdAdminLoginSessionUsingGET(userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveAdminLoginSession
     * @param {AdminLoginSession} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLoginSessionEntityApi
     */
    public saveAdminLoginSessionUsingPATCH(body: AdminLoginSession, id: string, options?: any) {
        return AdminLoginSessionEntityApiFp(this.configuration).saveAdminLoginSessionUsingPATCH(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveAdminLoginSession
     * @param {AdminLoginSession} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLoginSessionEntityApi
     */
    public saveAdminLoginSessionUsingPOST(body: AdminLoginSession, options?: any) {
        return AdminLoginSessionEntityApiFp(this.configuration).saveAdminLoginSessionUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveAdminLoginSession
     * @param {AdminLoginSession} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AdminLoginSessionEntityApi
     */
    public saveAdminLoginSessionUsingPUT(body: AdminLoginSession, id: string, options?: any) {
        return AdminLoginSessionEntityApiFp(this.configuration).saveAdminLoginSessionUsingPUT(body, id, options)(this.fetch, this.basePath);
    }

}

/**
 * AuthControllerApi - fetch parameter creator
 * @export
 */
export const AuthControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary authenticateAdmin
         * @param {UserLoginRequestModel} userLoginRequestModel userLoginRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateAdminUsingPOST(userLoginRequestModel: UserLoginRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'userLoginRequestModel' is not null or undefined
            if (userLoginRequestModel === null || userLoginRequestModel === undefined) {
                throw new RequiredError('userLoginRequestModel','Required parameter userLoginRequestModel was null or undefined when calling authenticateAdminUsingPOST.');
            }
            const localVarPath = `/v1/authenticate-admin`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserLoginRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(userLoginRequestModel || {}) : (userLoginRequestModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary authenticateUser
         * @param {UserLoginRequestModel} userLoginRequestModel userLoginRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserUsingPOST(userLoginRequestModel: UserLoginRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'userLoginRequestModel' is not null or undefined
            if (userLoginRequestModel === null || userLoginRequestModel === undefined) {
                throw new RequiredError('userLoginRequestModel','Required parameter userLoginRequestModel was null or undefined when calling authenticateUserUsingPOST.');
            }
            const localVarPath = `/v1/authenticate-user`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserLoginRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(userLoginRequestModel || {}) : (userLoginRequestModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary loginAdmin
         * @param {UserLoginSessionRequestModel} adminLoginSessionRequestModel adminLoginSessionRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAdminUsingPOST(adminLoginSessionRequestModel: UserLoginSessionRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'adminLoginSessionRequestModel' is not null or undefined
            if (adminLoginSessionRequestModel === null || adminLoginSessionRequestModel === undefined) {
                throw new RequiredError('adminLoginSessionRequestModel','Required parameter adminLoginSessionRequestModel was null or undefined when calling loginAdminUsingPOST.');
            }
            const localVarPath = `/v1/login/admin`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserLoginSessionRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(adminLoginSessionRequestModel || {}) : (adminLoginSessionRequestModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary loginRefresh
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginRefreshUsingPOST(orgId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/login/refresh`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary loginThroughSessionId
         * @param {UserLoginSessionRequestModel} userLoginSessionRequestModel userLoginSessionRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginThroughSessionIdUsingPOST(userLoginSessionRequestModel: UserLoginSessionRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'userLoginSessionRequestModel' is not null or undefined
            if (userLoginSessionRequestModel === null || userLoginSessionRequestModel === undefined) {
                throw new RequiredError('userLoginSessionRequestModel','Required parameter userLoginSessionRequestModel was null or undefined when calling loginThroughSessionIdUsingPOST.');
            }
            const localVarPath = `/v2/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserLoginSessionRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(userLoginSessionRequestModel || {}) : (userLoginSessionRequestModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary login
         * @param {UserLoginRequestModel} userLoginRequestModel userLoginRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUsingPOST(userLoginRequestModel: UserLoginRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'userLoginRequestModel' is not null or undefined
            if (userLoginRequestModel === null || userLoginRequestModel === undefined) {
                throw new RequiredError('userLoginRequestModel','Required parameter userLoginRequestModel was null or undefined when calling loginUsingPOST.');
            }
            const localVarPath = `/v1/login`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserLoginRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(userLoginRequestModel || {}) : (userLoginRequestModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary register
         * @param {UserRegisterRequestModel} userRegisterRequestModel UserRegisterRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUsingPOST(userRegisterRequestModel: UserRegisterRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'userRegisterRequestModel' is not null or undefined
            if (userRegisterRequestModel === null || userRegisterRequestModel === undefined) {
                throw new RequiredError('userRegisterRequestModel','Required parameter userRegisterRequestModel was null or undefined when calling registerUsingPOST.');
            }
            const localVarPath = `/v1/register`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserRegisterRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(userRegisterRequestModel || {}) : (userRegisterRequestModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthControllerApi - functional programming interface
 * @export
 */
export const AuthControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary authenticateAdmin
         * @param {UserLoginRequestModel} userLoginRequestModel userLoginRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateAdminUsingPOST(userLoginRequestModel: UserLoginRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthenticateAdminResponse> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).authenticateAdminUsingPOST(userLoginRequestModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary authenticateUser
         * @param {UserLoginRequestModel} userLoginRequestModel userLoginRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserUsingPOST(userLoginRequestModel: UserLoginRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthenticateUserResponse> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).authenticateUserUsingPOST(userLoginRequestModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary loginAdmin
         * @param {UserLoginSessionRequestModel} adminLoginSessionRequestModel adminLoginSessionRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAdminUsingPOST(adminLoginSessionRequestModel: UserLoginSessionRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenResponse> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).loginAdminUsingPOST(adminLoginSessionRequestModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary loginRefresh
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginRefreshUsingPOST(orgId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenResponse> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).loginRefreshUsingPOST(orgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary loginThroughSessionId
         * @param {UserLoginSessionRequestModel} userLoginSessionRequestModel userLoginSessionRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginThroughSessionIdUsingPOST(userLoginSessionRequestModel: UserLoginSessionRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenResponse> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).loginThroughSessionIdUsingPOST(userLoginSessionRequestModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary login
         * @param {UserLoginRequestModel} userLoginRequestModel userLoginRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUsingPOST(userLoginRequestModel: UserLoginRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenResponse> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).loginUsingPOST(userLoginRequestModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary register
         * @param {UserRegisterRequestModel} userRegisterRequestModel UserRegisterRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUsingPOST(userRegisterRequestModel: UserRegisterRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserResponseModel> {
            const localVarFetchArgs = AuthControllerApiFetchParamCreator(configuration).registerUsingPOST(userRegisterRequestModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthControllerApi - factory interface
 * @export
 */
export const AuthControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary authenticateAdmin
         * @param {UserLoginRequestModel} userLoginRequestModel userLoginRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateAdminUsingPOST(userLoginRequestModel: UserLoginRequestModel, options?: any) {
            return AuthControllerApiFp(configuration).authenticateAdminUsingPOST(userLoginRequestModel, options)(fetch, basePath);
        },
        /**
         * 
         * @summary authenticateUser
         * @param {UserLoginRequestModel} userLoginRequestModel userLoginRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authenticateUserUsingPOST(userLoginRequestModel: UserLoginRequestModel, options?: any) {
            return AuthControllerApiFp(configuration).authenticateUserUsingPOST(userLoginRequestModel, options)(fetch, basePath);
        },
        /**
         * 
         * @summary loginAdmin
         * @param {UserLoginSessionRequestModel} adminLoginSessionRequestModel adminLoginSessionRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginAdminUsingPOST(adminLoginSessionRequestModel: UserLoginSessionRequestModel, options?: any) {
            return AuthControllerApiFp(configuration).loginAdminUsingPOST(adminLoginSessionRequestModel, options)(fetch, basePath);
        },
        /**
         * 
         * @summary loginRefresh
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginRefreshUsingPOST(orgId?: string, options?: any) {
            return AuthControllerApiFp(configuration).loginRefreshUsingPOST(orgId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary loginThroughSessionId
         * @param {UserLoginSessionRequestModel} userLoginSessionRequestModel userLoginSessionRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginThroughSessionIdUsingPOST(userLoginSessionRequestModel: UserLoginSessionRequestModel, options?: any) {
            return AuthControllerApiFp(configuration).loginThroughSessionIdUsingPOST(userLoginSessionRequestModel, options)(fetch, basePath);
        },
        /**
         * 
         * @summary login
         * @param {UserLoginRequestModel} userLoginRequestModel userLoginRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginUsingPOST(userLoginRequestModel: UserLoginRequestModel, options?: any) {
            return AuthControllerApiFp(configuration).loginUsingPOST(userLoginRequestModel, options)(fetch, basePath);
        },
        /**
         * 
         * @summary register
         * @param {UserRegisterRequestModel} userRegisterRequestModel UserRegisterRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerUsingPOST(userRegisterRequestModel: UserRegisterRequestModel, options?: any) {
            return AuthControllerApiFp(configuration).registerUsingPOST(userRegisterRequestModel, options)(fetch, basePath);
        },
    };
};

/**
 * AuthControllerApi - object-oriented interface
 * @export
 * @class AuthControllerApi
 * @extends {BaseAPI}
 */
export class AuthControllerApi extends BaseAPI {
    /**
     * 
     * @summary authenticateAdmin
     * @param {UserLoginRequestModel} userLoginRequestModel userLoginRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authenticateAdminUsingPOST(userLoginRequestModel: UserLoginRequestModel, options?: any) {
        return AuthControllerApiFp(this.configuration).authenticateAdminUsingPOST(userLoginRequestModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary authenticateUser
     * @param {UserLoginRequestModel} userLoginRequestModel userLoginRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public authenticateUserUsingPOST(userLoginRequestModel: UserLoginRequestModel, options?: any) {
        return AuthControllerApiFp(this.configuration).authenticateUserUsingPOST(userLoginRequestModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary loginAdmin
     * @param {UserLoginSessionRequestModel} adminLoginSessionRequestModel adminLoginSessionRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public loginAdminUsingPOST(adminLoginSessionRequestModel: UserLoginSessionRequestModel, options?: any) {
        return AuthControllerApiFp(this.configuration).loginAdminUsingPOST(adminLoginSessionRequestModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary loginRefresh
     * @param {string} [orgId] orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public loginRefreshUsingPOST(orgId?: string, options?: any) {
        return AuthControllerApiFp(this.configuration).loginRefreshUsingPOST(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary loginThroughSessionId
     * @param {UserLoginSessionRequestModel} userLoginSessionRequestModel userLoginSessionRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public loginThroughSessionIdUsingPOST(userLoginSessionRequestModel: UserLoginSessionRequestModel, options?: any) {
        return AuthControllerApiFp(this.configuration).loginThroughSessionIdUsingPOST(userLoginSessionRequestModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary login
     * @param {UserLoginRequestModel} userLoginRequestModel userLoginRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public loginUsingPOST(userLoginRequestModel: UserLoginRequestModel, options?: any) {
        return AuthControllerApiFp(this.configuration).loginUsingPOST(userLoginRequestModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary register
     * @param {UserRegisterRequestModel} userRegisterRequestModel UserRegisterRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthControllerApi
     */
    public registerUsingPOST(userRegisterRequestModel: UserRegisterRequestModel, options?: any) {
        return AuthControllerApiFp(this.configuration).registerUsingPOST(userRegisterRequestModel, options)(this.fetch, this.basePath);
    }

}

/**
 * AuthEmailToOrgControllerApi - fetch parameter creator
 * @export
 */
export const AuthEmailToOrgControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createAuthEmailToOrg
         * @param {AuthEmailToOrg} authEmailToOrg authEmailToOrg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthEmailToOrgUsingPOST(authEmailToOrg: AuthEmailToOrg, options: any = {}): FetchArgs {
            // verify required parameter 'authEmailToOrg' is not null or undefined
            if (authEmailToOrg === null || authEmailToOrg === undefined) {
                throw new RequiredError('authEmailToOrg','Required parameter authEmailToOrg was null or undefined when calling createAuthEmailToOrgUsingPOST.');
            }
            const localVarPath = `/v1/auth-scanned-docs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuthEmailToOrg" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(authEmailToOrg || {}) : (authEmailToOrg || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteMyAuthEmailToOrg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyAuthEmailToOrgUsingDELETE(options: any = {}): FetchArgs {
            const localVarPath = `/v1/auth-scanned-docs/my`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getMyAuthEmailToOrg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyAuthEmailToOrgUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/auth-scanned-docs/my`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateMyAuthEmailToOrg
         * @param {AuthEmailToOrg} authEmailToOrg authEmailToOrg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyAuthEmailToOrgUsingPUT(authEmailToOrg: AuthEmailToOrg, options: any = {}): FetchArgs {
            // verify required parameter 'authEmailToOrg' is not null or undefined
            if (authEmailToOrg === null || authEmailToOrg === undefined) {
                throw new RequiredError('authEmailToOrg','Required parameter authEmailToOrg was null or undefined when calling updateMyAuthEmailToOrgUsingPUT.');
            }
            const localVarPath = `/v1/auth-scanned-docs/my`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuthEmailToOrg" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(authEmailToOrg || {}) : (authEmailToOrg || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthEmailToOrgControllerApi - functional programming interface
 * @export
 */
export const AuthEmailToOrgControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createAuthEmailToOrg
         * @param {AuthEmailToOrg} authEmailToOrg authEmailToOrg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthEmailToOrgUsingPOST(authEmailToOrg: AuthEmailToOrg, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthEmailToOrg> {
            const localVarFetchArgs = AuthEmailToOrgControllerApiFetchParamCreator(configuration).createAuthEmailToOrgUsingPOST(authEmailToOrg, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteMyAuthEmailToOrg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyAuthEmailToOrgUsingDELETE(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthEmailToOrgControllerApiFetchParamCreator(configuration).deleteMyAuthEmailToOrgUsingDELETE(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getMyAuthEmailToOrg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyAuthEmailToOrgUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthEmailToOrg> {
            const localVarFetchArgs = AuthEmailToOrgControllerApiFetchParamCreator(configuration).getMyAuthEmailToOrgUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateMyAuthEmailToOrg
         * @param {AuthEmailToOrg} authEmailToOrg authEmailToOrg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyAuthEmailToOrgUsingPUT(authEmailToOrg: AuthEmailToOrg, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AuthEmailToOrg> {
            const localVarFetchArgs = AuthEmailToOrgControllerApiFetchParamCreator(configuration).updateMyAuthEmailToOrgUsingPUT(authEmailToOrg, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthEmailToOrgControllerApi - factory interface
 * @export
 */
export const AuthEmailToOrgControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createAuthEmailToOrg
         * @param {AuthEmailToOrg} authEmailToOrg authEmailToOrg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAuthEmailToOrgUsingPOST(authEmailToOrg: AuthEmailToOrg, options?: any) {
            return AuthEmailToOrgControllerApiFp(configuration).createAuthEmailToOrgUsingPOST(authEmailToOrg, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteMyAuthEmailToOrg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyAuthEmailToOrgUsingDELETE(options?: any) {
            return AuthEmailToOrgControllerApiFp(configuration).deleteMyAuthEmailToOrgUsingDELETE(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getMyAuthEmailToOrg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyAuthEmailToOrgUsingGET(options?: any) {
            return AuthEmailToOrgControllerApiFp(configuration).getMyAuthEmailToOrgUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateMyAuthEmailToOrg
         * @param {AuthEmailToOrg} authEmailToOrg authEmailToOrg
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyAuthEmailToOrgUsingPUT(authEmailToOrg: AuthEmailToOrg, options?: any) {
            return AuthEmailToOrgControllerApiFp(configuration).updateMyAuthEmailToOrgUsingPUT(authEmailToOrg, options)(fetch, basePath);
        },
    };
};

/**
 * AuthEmailToOrgControllerApi - object-oriented interface
 * @export
 * @class AuthEmailToOrgControllerApi
 * @extends {BaseAPI}
 */
export class AuthEmailToOrgControllerApi extends BaseAPI {
    /**
     * 
     * @summary createAuthEmailToOrg
     * @param {AuthEmailToOrg} authEmailToOrg authEmailToOrg
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthEmailToOrgControllerApi
     */
    public createAuthEmailToOrgUsingPOST(authEmailToOrg: AuthEmailToOrg, options?: any) {
        return AuthEmailToOrgControllerApiFp(this.configuration).createAuthEmailToOrgUsingPOST(authEmailToOrg, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteMyAuthEmailToOrg
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthEmailToOrgControllerApi
     */
    public deleteMyAuthEmailToOrgUsingDELETE(options?: any) {
        return AuthEmailToOrgControllerApiFp(this.configuration).deleteMyAuthEmailToOrgUsingDELETE(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getMyAuthEmailToOrg
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthEmailToOrgControllerApi
     */
    public getMyAuthEmailToOrgUsingGET(options?: any) {
        return AuthEmailToOrgControllerApiFp(this.configuration).getMyAuthEmailToOrgUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateMyAuthEmailToOrg
     * @param {AuthEmailToOrg} authEmailToOrg authEmailToOrg
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthEmailToOrgControllerApi
     */
    public updateMyAuthEmailToOrgUsingPUT(authEmailToOrg: AuthEmailToOrg, options?: any) {
        return AuthEmailToOrgControllerApiFp(this.configuration).updateMyAuthEmailToOrgUsingPUT(authEmailToOrg, options)(this.fetch, this.basePath);
    }

}

/**
 * AuthEmailToOrgEntityApi - fetch parameter creator
 * @export
 */
export const AuthEmailToOrgEntityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAuthEmailToOrg
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthEmailToOrgUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteAuthEmailToOrgUsingDELETE.');
            }
            const localVarPath = `/authEmailToOrgs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteByOrgIdAuthEmailToOrg
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByOrgIdAuthEmailToOrgUsingGET(orgId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/authEmailToOrgs/search/deleteByOrgId`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllAuthEmailToOrg
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAuthEmailToOrgUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/authEmailToOrgs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByAccountNumberAuthEmailToOrg
         * @param {string} [accountNumber] accountNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByAccountNumberAuthEmailToOrgUsingGET(accountNumber?: string, options: any = {}): FetchArgs {
            const localVarPath = `/authEmailToOrgs/search/findByAccountNumber`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (accountNumber !== undefined) {
                localVarQueryParameter['accountNumber'] = accountNumber;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByEmailsInAuthEmailToOrg
         * @param {Array<string>} [emails] emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEmailsInAuthEmailToOrgUsingGET(emails?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/authEmailToOrgs/search/findByEmailsIn`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (emails) {
                localVarQueryParameter['emails'] = emails;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdAuthEmailToOrg
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdAuthEmailToOrgUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdAuthEmailToOrgUsingGET.');
            }
            const localVarPath = `/authEmailToOrgs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByOrgIdAuthEmailToOrg
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByOrgIdAuthEmailToOrgUsingGET(orgId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/authEmailToOrgs/search/findByOrgId`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveAuthEmailToOrg
         * @param {AuthEmailToOrg} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAuthEmailToOrgUsingPATCH(body: AuthEmailToOrg, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveAuthEmailToOrgUsingPATCH.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveAuthEmailToOrgUsingPATCH.');
            }
            const localVarPath = `/authEmailToOrgs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuthEmailToOrg" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveAuthEmailToOrg
         * @param {AuthEmailToOrg} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAuthEmailToOrgUsingPOST(body: AuthEmailToOrg, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveAuthEmailToOrgUsingPOST.');
            }
            const localVarPath = `/authEmailToOrgs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuthEmailToOrg" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveAuthEmailToOrg
         * @param {AuthEmailToOrg} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAuthEmailToOrgUsingPUT(body: AuthEmailToOrg, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveAuthEmailToOrgUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveAuthEmailToOrgUsingPUT.');
            }
            const localVarPath = `/authEmailToOrgs/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AuthEmailToOrg" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthEmailToOrgEntityApi - functional programming interface
 * @export
 */
export const AuthEmailToOrgEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAuthEmailToOrg
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthEmailToOrgUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthEmailToOrgEntityApiFetchParamCreator(configuration).deleteAuthEmailToOrgUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteByOrgIdAuthEmailToOrg
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByOrgIdAuthEmailToOrgUsingGET(orgId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthEmailToOrgEntityApiFetchParamCreator(configuration).deleteByOrgIdAuthEmailToOrgUsingGET(orgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllAuthEmailToOrg
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAuthEmailToOrgUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelAuthEmailToOrg> {
            const localVarFetchArgs = AuthEmailToOrgEntityApiFetchParamCreator(configuration).findAllAuthEmailToOrgUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByAccountNumberAuthEmailToOrg
         * @param {string} [accountNumber] accountNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByAccountNumberAuthEmailToOrgUsingGET(accountNumber?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelAuthEmailToOrg> {
            const localVarFetchArgs = AuthEmailToOrgEntityApiFetchParamCreator(configuration).findByAccountNumberAuthEmailToOrgUsingGET(accountNumber, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByEmailsInAuthEmailToOrg
         * @param {Array<string>} [emails] emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEmailsInAuthEmailToOrgUsingGET(emails?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelAuthEmailToOrg> {
            const localVarFetchArgs = AuthEmailToOrgEntityApiFetchParamCreator(configuration).findByEmailsInAuthEmailToOrgUsingGET(emails, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByIdAuthEmailToOrg
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdAuthEmailToOrgUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelAuthEmailToOrg> {
            const localVarFetchArgs = AuthEmailToOrgEntityApiFetchParamCreator(configuration).findByIdAuthEmailToOrgUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByOrgIdAuthEmailToOrg
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByOrgIdAuthEmailToOrgUsingGET(orgId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelAuthEmailToOrg> {
            const localVarFetchArgs = AuthEmailToOrgEntityApiFetchParamCreator(configuration).findByOrgIdAuthEmailToOrgUsingGET(orgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveAuthEmailToOrg
         * @param {AuthEmailToOrg} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAuthEmailToOrgUsingPATCH(body: AuthEmailToOrg, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelAuthEmailToOrg> {
            const localVarFetchArgs = AuthEmailToOrgEntityApiFetchParamCreator(configuration).saveAuthEmailToOrgUsingPATCH(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveAuthEmailToOrg
         * @param {AuthEmailToOrg} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAuthEmailToOrgUsingPOST(body: AuthEmailToOrg, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelAuthEmailToOrg> {
            const localVarFetchArgs = AuthEmailToOrgEntityApiFetchParamCreator(configuration).saveAuthEmailToOrgUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveAuthEmailToOrg
         * @param {AuthEmailToOrg} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAuthEmailToOrgUsingPUT(body: AuthEmailToOrg, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelAuthEmailToOrg> {
            const localVarFetchArgs = AuthEmailToOrgEntityApiFetchParamCreator(configuration).saveAuthEmailToOrgUsingPUT(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthEmailToOrgEntityApi - factory interface
 * @export
 */
export const AuthEmailToOrgEntityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteAuthEmailToOrg
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAuthEmailToOrgUsingDELETE(id: string, options?: any) {
            return AuthEmailToOrgEntityApiFp(configuration).deleteAuthEmailToOrgUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteByOrgIdAuthEmailToOrg
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByOrgIdAuthEmailToOrgUsingGET(orgId?: string, options?: any) {
            return AuthEmailToOrgEntityApiFp(configuration).deleteByOrgIdAuthEmailToOrgUsingGET(orgId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllAuthEmailToOrg
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllAuthEmailToOrgUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return AuthEmailToOrgEntityApiFp(configuration).findAllAuthEmailToOrgUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByAccountNumberAuthEmailToOrg
         * @param {string} [accountNumber] accountNumber
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByAccountNumberAuthEmailToOrgUsingGET(accountNumber?: string, options?: any) {
            return AuthEmailToOrgEntityApiFp(configuration).findByAccountNumberAuthEmailToOrgUsingGET(accountNumber, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByEmailsInAuthEmailToOrg
         * @param {Array<string>} [emails] emails
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByEmailsInAuthEmailToOrgUsingGET(emails?: Array<string>, options?: any) {
            return AuthEmailToOrgEntityApiFp(configuration).findByEmailsInAuthEmailToOrgUsingGET(emails, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByIdAuthEmailToOrg
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdAuthEmailToOrgUsingGET(id: string, options?: any) {
            return AuthEmailToOrgEntityApiFp(configuration).findByIdAuthEmailToOrgUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByOrgIdAuthEmailToOrg
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByOrgIdAuthEmailToOrgUsingGET(orgId?: string, options?: any) {
            return AuthEmailToOrgEntityApiFp(configuration).findByOrgIdAuthEmailToOrgUsingGET(orgId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveAuthEmailToOrg
         * @param {AuthEmailToOrg} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAuthEmailToOrgUsingPATCH(body: AuthEmailToOrg, id: string, options?: any) {
            return AuthEmailToOrgEntityApiFp(configuration).saveAuthEmailToOrgUsingPATCH(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveAuthEmailToOrg
         * @param {AuthEmailToOrg} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAuthEmailToOrgUsingPOST(body: AuthEmailToOrg, options?: any) {
            return AuthEmailToOrgEntityApiFp(configuration).saveAuthEmailToOrgUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveAuthEmailToOrg
         * @param {AuthEmailToOrg} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveAuthEmailToOrgUsingPUT(body: AuthEmailToOrg, id: string, options?: any) {
            return AuthEmailToOrgEntityApiFp(configuration).saveAuthEmailToOrgUsingPUT(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * AuthEmailToOrgEntityApi - object-oriented interface
 * @export
 * @class AuthEmailToOrgEntityApi
 * @extends {BaseAPI}
 */
export class AuthEmailToOrgEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteAuthEmailToOrg
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthEmailToOrgEntityApi
     */
    public deleteAuthEmailToOrgUsingDELETE(id: string, options?: any) {
        return AuthEmailToOrgEntityApiFp(this.configuration).deleteAuthEmailToOrgUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteByOrgIdAuthEmailToOrg
     * @param {string} [orgId] orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthEmailToOrgEntityApi
     */
    public deleteByOrgIdAuthEmailToOrgUsingGET(orgId?: string, options?: any) {
        return AuthEmailToOrgEntityApiFp(this.configuration).deleteByOrgIdAuthEmailToOrgUsingGET(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllAuthEmailToOrg
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthEmailToOrgEntityApi
     */
    public findAllAuthEmailToOrgUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return AuthEmailToOrgEntityApiFp(this.configuration).findAllAuthEmailToOrgUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByAccountNumberAuthEmailToOrg
     * @param {string} [accountNumber] accountNumber
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthEmailToOrgEntityApi
     */
    public findByAccountNumberAuthEmailToOrgUsingGET(accountNumber?: string, options?: any) {
        return AuthEmailToOrgEntityApiFp(this.configuration).findByAccountNumberAuthEmailToOrgUsingGET(accountNumber, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByEmailsInAuthEmailToOrg
     * @param {Array<string>} [emails] emails
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthEmailToOrgEntityApi
     */
    public findByEmailsInAuthEmailToOrgUsingGET(emails?: Array<string>, options?: any) {
        return AuthEmailToOrgEntityApiFp(this.configuration).findByEmailsInAuthEmailToOrgUsingGET(emails, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByIdAuthEmailToOrg
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthEmailToOrgEntityApi
     */
    public findByIdAuthEmailToOrgUsingGET(id: string, options?: any) {
        return AuthEmailToOrgEntityApiFp(this.configuration).findByIdAuthEmailToOrgUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByOrgIdAuthEmailToOrg
     * @param {string} [orgId] orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthEmailToOrgEntityApi
     */
    public findByOrgIdAuthEmailToOrgUsingGET(orgId?: string, options?: any) {
        return AuthEmailToOrgEntityApiFp(this.configuration).findByOrgIdAuthEmailToOrgUsingGET(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveAuthEmailToOrg
     * @param {AuthEmailToOrg} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthEmailToOrgEntityApi
     */
    public saveAuthEmailToOrgUsingPATCH(body: AuthEmailToOrg, id: string, options?: any) {
        return AuthEmailToOrgEntityApiFp(this.configuration).saveAuthEmailToOrgUsingPATCH(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveAuthEmailToOrg
     * @param {AuthEmailToOrg} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthEmailToOrgEntityApi
     */
    public saveAuthEmailToOrgUsingPOST(body: AuthEmailToOrg, options?: any) {
        return AuthEmailToOrgEntityApiFp(this.configuration).saveAuthEmailToOrgUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveAuthEmailToOrg
     * @param {AuthEmailToOrg} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthEmailToOrgEntityApi
     */
    public saveAuthEmailToOrgUsingPUT(body: AuthEmailToOrg, id: string, options?: any) {
        return AuthEmailToOrgEntityApiFp(this.configuration).saveAuthEmailToOrgUsingPUT(body, id, options)(this.fetch, this.basePath);
    }

}

/**
 * BasicErrorControllerApi - fetch parameter creator
 * @export
 */
export const BasicErrorControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingDELETE(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingHEAD(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'HEAD' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingOPTIONS(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'OPTIONS' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPATCH(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPOST(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPUT(options: any = {}): FetchArgs {
            const localVarPath = `/error`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BasicErrorControllerApi - functional programming interface
 * @export
 */
export const BasicErrorControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingDELETE(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingDELETE(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingHEAD(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingHEAD(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingOPTIONS(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingOPTIONS(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPATCH(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingPATCH(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPOST(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingPOST(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPUT(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelAndView> {
            const localVarFetchArgs = BasicErrorControllerApiFetchParamCreator(configuration).errorHtmlUsingPUT(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BasicErrorControllerApi - factory interface
 * @export
 */
export const BasicErrorControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingDELETE(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingDELETE(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingGET(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingHEAD(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingHEAD(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingOPTIONS(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingOPTIONS(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPATCH(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingPATCH(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPOST(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingPOST(options)(fetch, basePath);
        },
        /**
         * 
         * @summary errorHtml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        errorHtmlUsingPUT(options?: any) {
            return BasicErrorControllerApiFp(configuration).errorHtmlUsingPUT(options)(fetch, basePath);
        },
    };
};

/**
 * BasicErrorControllerApi - object-oriented interface
 * @export
 * @class BasicErrorControllerApi
 * @extends {BaseAPI}
 */
export class BasicErrorControllerApi extends BaseAPI {
    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingDELETE(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingDELETE(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingGET(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingHEAD(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingHEAD(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingOPTIONS(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingOPTIONS(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPATCH(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPATCH(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPOST(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPOST(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary errorHtml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BasicErrorControllerApi
     */
    public errorHtmlUsingPUT(options?: any) {
        return BasicErrorControllerApiFp(this.configuration).errorHtmlUsingPUT(options)(this.fetch, this.basePath);
    }

}

/**
 * FeatureControllerApi - fetch parameter creator
 * @export
 */
export const FeatureControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createFeature
         * @param {Feature} feature feature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureUsingPOST(feature: Feature, options: any = {}): FetchArgs {
            // verify required parameter 'feature' is not null or undefined
            if (feature === null || feature === undefined) {
                throw new RequiredError('feature','Required parameter feature was null or undefined when calling createFeatureUsingPOST.');
            }
            const localVarPath = `/v1/features`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Feature" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(feature || {}) : (feature || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteFeature
         * @param {string} featureId featureId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureUsingDELETE1(featureId: string, options: any = {}): FetchArgs {
            // verify required parameter 'featureId' is not null or undefined
            if (featureId === null || featureId === undefined) {
                throw new RequiredError('featureId','Required parameter featureId was null or undefined when calling deleteFeatureUsingDELETE1.');
            }
            const localVarPath = `/v1/features/{featureId}`
                .replace(`{${"featureId"}}`, encodeURIComponent(String(featureId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getFeaturesByCriteria
         * @param {number} [page] 
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesByCriteriaUsingGET(page?: number, partialName?: string, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/features`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialName !== undefined) {
                localVarQueryParameter['partialName'] = partialName;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateFeature
         * @param {Feature} feature feature
         * @param {string} featureId featureId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureUsingPUT(feature: Feature, featureId: string, options: any = {}): FetchArgs {
            // verify required parameter 'feature' is not null or undefined
            if (feature === null || feature === undefined) {
                throw new RequiredError('feature','Required parameter feature was null or undefined when calling updateFeatureUsingPUT.');
            }
            // verify required parameter 'featureId' is not null or undefined
            if (featureId === null || featureId === undefined) {
                throw new RequiredError('featureId','Required parameter featureId was null or undefined when calling updateFeatureUsingPUT.');
            }
            const localVarPath = `/v1/features/{featureId}`
                .replace(`{${"featureId"}}`, encodeURIComponent(String(featureId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Feature" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(feature || {}) : (feature || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureControllerApi - functional programming interface
 * @export
 */
export const FeatureControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createFeature
         * @param {Feature} feature feature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureUsingPOST(feature: Feature, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Feature> {
            const localVarFetchArgs = FeatureControllerApiFetchParamCreator(configuration).createFeatureUsingPOST(feature, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteFeature
         * @param {string} featureId featureId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureUsingDELETE1(featureId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = FeatureControllerApiFetchParamCreator(configuration).deleteFeatureUsingDELETE1(featureId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getFeaturesByCriteria
         * @param {number} [page] 
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesByCriteriaUsingGET(page?: number, partialName?: string, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageFeature> {
            const localVarFetchArgs = FeatureControllerApiFetchParamCreator(configuration).getFeaturesByCriteriaUsingGET(page, partialName, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateFeature
         * @param {Feature} feature feature
         * @param {string} featureId featureId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureUsingPUT(feature: Feature, featureId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Feature> {
            const localVarFetchArgs = FeatureControllerApiFetchParamCreator(configuration).updateFeatureUsingPUT(feature, featureId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FeatureControllerApi - factory interface
 * @export
 */
export const FeatureControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createFeature
         * @param {Feature} feature feature
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createFeatureUsingPOST(feature: Feature, options?: any) {
            return FeatureControllerApiFp(configuration).createFeatureUsingPOST(feature, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteFeature
         * @param {string} featureId featureId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureUsingDELETE1(featureId: string, options?: any) {
            return FeatureControllerApiFp(configuration).deleteFeatureUsingDELETE1(featureId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getFeaturesByCriteria
         * @param {number} [page] 
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturesByCriteriaUsingGET(page?: number, partialName?: string, size?: number, sort?: string, options?: any) {
            return FeatureControllerApiFp(configuration).getFeaturesByCriteriaUsingGET(page, partialName, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateFeature
         * @param {Feature} feature feature
         * @param {string} featureId featureId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFeatureUsingPUT(feature: Feature, featureId: string, options?: any) {
            return FeatureControllerApiFp(configuration).updateFeatureUsingPUT(feature, featureId, options)(fetch, basePath);
        },
    };
};

/**
 * FeatureControllerApi - object-oriented interface
 * @export
 * @class FeatureControllerApi
 * @extends {BaseAPI}
 */
export class FeatureControllerApi extends BaseAPI {
    /**
     * 
     * @summary createFeature
     * @param {Feature} feature feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureControllerApi
     */
    public createFeatureUsingPOST(feature: Feature, options?: any) {
        return FeatureControllerApiFp(this.configuration).createFeatureUsingPOST(feature, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteFeature
     * @param {string} featureId featureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureControllerApi
     */
    public deleteFeatureUsingDELETE1(featureId: string, options?: any) {
        return FeatureControllerApiFp(this.configuration).deleteFeatureUsingDELETE1(featureId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getFeaturesByCriteria
     * @param {number} [page] 
     * @param {string} [partialName] partialName
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureControllerApi
     */
    public getFeaturesByCriteriaUsingGET(page?: number, partialName?: string, size?: number, sort?: string, options?: any) {
        return FeatureControllerApiFp(this.configuration).getFeaturesByCriteriaUsingGET(page, partialName, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateFeature
     * @param {Feature} feature feature
     * @param {string} featureId featureId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureControllerApi
     */
    public updateFeatureUsingPUT(feature: Feature, featureId: string, options?: any) {
        return FeatureControllerApiFp(this.configuration).updateFeatureUsingPUT(feature, featureId, options)(this.fetch, this.basePath);
    }

}

/**
 * FeatureEntityApi - fetch parameter creator
 * @export
 */
export const FeatureEntityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteFeature
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteFeatureUsingDELETE.');
            }
            const localVarPath = `/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllFeature
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllFeatureUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/features`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdFeature
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdFeatureUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdFeatureUsingGET.');
            }
            const localVarPath = `/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByNameIgnoreCaseFeature
         * @param {string} [name] name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByNameIgnoreCaseFeatureUsingGET(name?: string, options: any = {}): FetchArgs {
            const localVarPath = `/features/search/findByNameIgnoreCase`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveFeature
         * @param {Feature} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFeatureUsingPATCH(body: Feature, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveFeatureUsingPATCH.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveFeatureUsingPATCH.');
            }
            const localVarPath = `/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Feature" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveFeature
         * @param {Feature} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFeatureUsingPOST(body: Feature, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveFeatureUsingPOST.');
            }
            const localVarPath = `/features`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Feature" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveFeature
         * @param {Feature} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFeatureUsingPUT(body: Feature, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveFeatureUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveFeatureUsingPUT.');
            }
            const localVarPath = `/features/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Feature" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeatureEntityApi - functional programming interface
 * @export
 */
export const FeatureEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteFeature
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = FeatureEntityApiFetchParamCreator(configuration).deleteFeatureUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllFeature
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllFeatureUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelFeature> {
            const localVarFetchArgs = FeatureEntityApiFetchParamCreator(configuration).findAllFeatureUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByIdFeature
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdFeatureUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelFeature> {
            const localVarFetchArgs = FeatureEntityApiFetchParamCreator(configuration).findByIdFeatureUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByNameIgnoreCaseFeature
         * @param {string} [name] name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByNameIgnoreCaseFeatureUsingGET(name?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelFeature> {
            const localVarFetchArgs = FeatureEntityApiFetchParamCreator(configuration).findByNameIgnoreCaseFeatureUsingGET(name, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveFeature
         * @param {Feature} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFeatureUsingPATCH(body: Feature, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelFeature> {
            const localVarFetchArgs = FeatureEntityApiFetchParamCreator(configuration).saveFeatureUsingPATCH(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveFeature
         * @param {Feature} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFeatureUsingPOST(body: Feature, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelFeature> {
            const localVarFetchArgs = FeatureEntityApiFetchParamCreator(configuration).saveFeatureUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveFeature
         * @param {Feature} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFeatureUsingPUT(body: Feature, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelFeature> {
            const localVarFetchArgs = FeatureEntityApiFetchParamCreator(configuration).saveFeatureUsingPUT(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FeatureEntityApi - factory interface
 * @export
 */
export const FeatureEntityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteFeature
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFeatureUsingDELETE(id: string, options?: any) {
            return FeatureEntityApiFp(configuration).deleteFeatureUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllFeature
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllFeatureUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return FeatureEntityApiFp(configuration).findAllFeatureUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByIdFeature
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdFeatureUsingGET(id: string, options?: any) {
            return FeatureEntityApiFp(configuration).findByIdFeatureUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByNameIgnoreCaseFeature
         * @param {string} [name] name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByNameIgnoreCaseFeatureUsingGET(name?: string, options?: any) {
            return FeatureEntityApiFp(configuration).findByNameIgnoreCaseFeatureUsingGET(name, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveFeature
         * @param {Feature} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFeatureUsingPATCH(body: Feature, id: string, options?: any) {
            return FeatureEntityApiFp(configuration).saveFeatureUsingPATCH(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveFeature
         * @param {Feature} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFeatureUsingPOST(body: Feature, options?: any) {
            return FeatureEntityApiFp(configuration).saveFeatureUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveFeature
         * @param {Feature} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFeatureUsingPUT(body: Feature, id: string, options?: any) {
            return FeatureEntityApiFp(configuration).saveFeatureUsingPUT(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * FeatureEntityApi - object-oriented interface
 * @export
 * @class FeatureEntityApi
 * @extends {BaseAPI}
 */
export class FeatureEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteFeature
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureEntityApi
     */
    public deleteFeatureUsingDELETE(id: string, options?: any) {
        return FeatureEntityApiFp(this.configuration).deleteFeatureUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllFeature
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureEntityApi
     */
    public findAllFeatureUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return FeatureEntityApiFp(this.configuration).findAllFeatureUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByIdFeature
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureEntityApi
     */
    public findByIdFeatureUsingGET(id: string, options?: any) {
        return FeatureEntityApiFp(this.configuration).findByIdFeatureUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByNameIgnoreCaseFeature
     * @param {string} [name] name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureEntityApi
     */
    public findByNameIgnoreCaseFeatureUsingGET(name?: string, options?: any) {
        return FeatureEntityApiFp(this.configuration).findByNameIgnoreCaseFeatureUsingGET(name, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveFeature
     * @param {Feature} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureEntityApi
     */
    public saveFeatureUsingPATCH(body: Feature, id: string, options?: any) {
        return FeatureEntityApiFp(this.configuration).saveFeatureUsingPATCH(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveFeature
     * @param {Feature} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureEntityApi
     */
    public saveFeatureUsingPOST(body: Feature, options?: any) {
        return FeatureEntityApiFp(this.configuration).saveFeatureUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveFeature
     * @param {Feature} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeatureEntityApi
     */
    public saveFeatureUsingPUT(body: Feature, id: string, options?: any) {
        return FeatureEntityApiFp(this.configuration).saveFeatureUsingPUT(body, id, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayCategoryControllerApi - fetch parameter creator
 * @export
 */
export const GatewayCategoryControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createCategory
         * @param {Category} category category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategoryUsingPOST(category: Category, options: any = {}): FetchArgs {
            // verify required parameter 'category' is not null or undefined
            if (category === null || category === undefined) {
                throw new RequiredError('category','Required parameter category was null or undefined when calling createCategoryUsingPOST.');
            }
            const localVarPath = `/v1/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Category" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(category || {}) : (category || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteCategory
         * @param {string} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategoryUsingDELETE(categoryId: string, options: any = {}): FetchArgs {
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling deleteCategoryUsingDELETE.');
            }
            const localVarPath = `/v1/categories/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCategoriesByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesByCriteriaUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/categories`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCategoryById
         * @param {string} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryByIdUsingGET(categoryId: string, options: any = {}): FetchArgs {
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling getCategoryByIdUsingGET.');
            }
            const localVarPath = `/v1/categories/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateCategory
         * @param {Category} category category
         * @param {string} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryUsingPUT(category: Category, categoryId: string, options: any = {}): FetchArgs {
            // verify required parameter 'category' is not null or undefined
            if (category === null || category === undefined) {
                throw new RequiredError('category','Required parameter category was null or undefined when calling updateCategoryUsingPUT.');
            }
            // verify required parameter 'categoryId' is not null or undefined
            if (categoryId === null || categoryId === undefined) {
                throw new RequiredError('categoryId','Required parameter categoryId was null or undefined when calling updateCategoryUsingPUT.');
            }
            const localVarPath = `/v1/categories/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Category" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(category || {}) : (category || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayCategoryControllerApi - functional programming interface
 * @export
 */
export const GatewayCategoryControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createCategory
         * @param {Category} category category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategoryUsingPOST(category: Category, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Category> {
            const localVarFetchArgs = GatewayCategoryControllerApiFetchParamCreator(configuration).createCategoryUsingPOST(category, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteCategory
         * @param {string} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategoryUsingDELETE(categoryId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayCategoryControllerApiFetchParamCreator(configuration).deleteCategoryUsingDELETE(categoryId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCategoriesByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageCategory> {
            const localVarFetchArgs = GatewayCategoryControllerApiFetchParamCreator(configuration).getCategoriesByCriteriaUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCategoryById
         * @param {string} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryByIdUsingGET(categoryId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Category> {
            const localVarFetchArgs = GatewayCategoryControllerApiFetchParamCreator(configuration).getCategoryByIdUsingGET(categoryId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateCategory
         * @param {Category} category category
         * @param {string} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryUsingPUT(category: Category, categoryId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Category> {
            const localVarFetchArgs = GatewayCategoryControllerApiFetchParamCreator(configuration).updateCategoryUsingPUT(category, categoryId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayCategoryControllerApi - factory interface
 * @export
 */
export const GatewayCategoryControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createCategory
         * @param {Category} category category
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCategoryUsingPOST(category: Category, options?: any) {
            return GatewayCategoryControllerApiFp(configuration).createCategoryUsingPOST(category, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteCategory
         * @param {string} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCategoryUsingDELETE(categoryId: string, options?: any) {
            return GatewayCategoryControllerApiFp(configuration).deleteCategoryUsingDELETE(categoryId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCategoriesByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoriesByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return GatewayCategoryControllerApiFp(configuration).getCategoriesByCriteriaUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCategoryById
         * @param {string} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategoryByIdUsingGET(categoryId: string, options?: any) {
            return GatewayCategoryControllerApiFp(configuration).getCategoryByIdUsingGET(categoryId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateCategory
         * @param {Category} category category
         * @param {string} categoryId categoryId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryUsingPUT(category: Category, categoryId: string, options?: any) {
            return GatewayCategoryControllerApiFp(configuration).updateCategoryUsingPUT(category, categoryId, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayCategoryControllerApi - object-oriented interface
 * @export
 * @class GatewayCategoryControllerApi
 * @extends {BaseAPI}
 */
export class GatewayCategoryControllerApi extends BaseAPI {
    /**
     * 
     * @summary createCategory
     * @param {Category} category category
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayCategoryControllerApi
     */
    public createCategoryUsingPOST(category: Category, options?: any) {
        return GatewayCategoryControllerApiFp(this.configuration).createCategoryUsingPOST(category, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteCategory
     * @param {string} categoryId categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayCategoryControllerApi
     */
    public deleteCategoryUsingDELETE(categoryId: string, options?: any) {
        return GatewayCategoryControllerApiFp(this.configuration).deleteCategoryUsingDELETE(categoryId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCategoriesByCriteria
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayCategoryControllerApi
     */
    public getCategoriesByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return GatewayCategoryControllerApiFp(this.configuration).getCategoriesByCriteriaUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCategoryById
     * @param {string} categoryId categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayCategoryControllerApi
     */
    public getCategoryByIdUsingGET(categoryId: string, options?: any) {
        return GatewayCategoryControllerApiFp(this.configuration).getCategoryByIdUsingGET(categoryId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateCategory
     * @param {Category} category category
     * @param {string} categoryId categoryId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayCategoryControllerApi
     */
    public updateCategoryUsingPUT(category: Category, categoryId: string, options?: any) {
        return GatewayCategoryControllerApiFp(this.configuration).updateCategoryUsingPUT(category, categoryId, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayCloverPosIntegrationControllerApi - fetch parameter creator
 * @export
 */
export const GatewayCloverPosIntegrationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createCloverPosIntegration
         * @param {CloverPosIntegrationCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCloverPosIntegrationUsingPOST(payload: CloverPosIntegrationCreatePayload, options: any = {}): FetchArgs {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createCloverPosIntegrationUsingPOST.');
            }
            const localVarPath = `/v1/clover-pos-integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CloverPosIntegrationCreatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(payload || {}) : (payload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteCloverPosIntegrationById
         * @param {string} cloverPosIntegrationId cloverPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCloverPosIntegrationByIdUsingDELETE(cloverPosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'cloverPosIntegrationId' is not null or undefined
            if (cloverPosIntegrationId === null || cloverPosIntegrationId === undefined) {
                throw new RequiredError('cloverPosIntegrationId','Required parameter cloverPosIntegrationId was null or undefined when calling deleteCloverPosIntegrationByIdUsingDELETE.');
            }
            const localVarPath = `/v1/clover-pos-integrations/{cloverPosIntegrationId}`
                .replace(`{${"cloverPosIntegrationId"}}`, encodeURIComponent(String(cloverPosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCloverPosIntegrationById
         * @param {string} cloverPosIntegrationId cloverPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCloverPosIntegrationByIdUsingGET(cloverPosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'cloverPosIntegrationId' is not null or undefined
            if (cloverPosIntegrationId === null || cloverPosIntegrationId === undefined) {
                throw new RequiredError('cloverPosIntegrationId','Required parameter cloverPosIntegrationId was null or undefined when calling getCloverPosIntegrationByIdUsingGET.');
            }
            const localVarPath = `/v1/clover-pos-integrations/{cloverPosIntegrationId}`
                .replace(`{${"cloverPosIntegrationId"}}`, encodeURIComponent(String(cloverPosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCloverPosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCloverPosIntegrationsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/clover-pos-integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOauthSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOauthSettingsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/clover-pos-integrations/oauth-settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary redirectURL
         * @param {string} clientId client_id
         * @param {string} code code
         * @param {string} employeeId employee_id
         * @param {string} merchantId merchant_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectURLUsingGET(clientId: string, code: string, employeeId: string, merchantId: string, options: any = {}): FetchArgs {
            // verify required parameter 'clientId' is not null or undefined
            if (clientId === null || clientId === undefined) {
                throw new RequiredError('clientId','Required parameter clientId was null or undefined when calling redirectURLUsingGET.');
            }
            // verify required parameter 'code' is not null or undefined
            if (code === null || code === undefined) {
                throw new RequiredError('code','Required parameter code was null or undefined when calling redirectURLUsingGET.');
            }
            // verify required parameter 'employeeId' is not null or undefined
            if (employeeId === null || employeeId === undefined) {
                throw new RequiredError('employeeId','Required parameter employeeId was null or undefined when calling redirectURLUsingGET.');
            }
            // verify required parameter 'merchantId' is not null or undefined
            if (merchantId === null || merchantId === undefined) {
                throw new RequiredError('merchantId','Required parameter merchantId was null or undefined when calling redirectURLUsingGET.');
            }
            const localVarPath = `/v1/clover-pos-integrations/redirect`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (clientId !== undefined) {
                localVarQueryParameter['client_id'] = clientId;
            }

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }

            if (employeeId !== undefined) {
                localVarQueryParameter['employee_id'] = employeeId;
            }

            if (merchantId !== undefined) {
                localVarQueryParameter['merchant_id'] = merchantId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary refreshAccessToken
         * @param {string} cloverPosIntegrationId cloverPosIntegrationId
         * @param {CloverRefreshTokenPayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAccessTokenUsingPATCH(cloverPosIntegrationId: string, payload: CloverRefreshTokenPayload, options: any = {}): FetchArgs {
            // verify required parameter 'cloverPosIntegrationId' is not null or undefined
            if (cloverPosIntegrationId === null || cloverPosIntegrationId === undefined) {
                throw new RequiredError('cloverPosIntegrationId','Required parameter cloverPosIntegrationId was null or undefined when calling refreshAccessTokenUsingPATCH.');
            }
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling refreshAccessTokenUsingPATCH.');
            }
            const localVarPath = `/v1/clover-pos-integrations/{cloverPosIntegrationId}/refresh-token`
                .replace(`{${"cloverPosIntegrationId"}}`, encodeURIComponent(String(cloverPosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CloverRefreshTokenPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(payload || {}) : (payload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary testWebhook
         * @param {any} [payload] payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testWebhookUsingPOST(payload?: any, options: any = {}): FetchArgs {
            const localVarPath = `/v1/clover-pos-integrations/webhook`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"any" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(payload || {}) : (payload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayCloverPosIntegrationControllerApi - functional programming interface
 * @export
 */
export const GatewayCloverPosIntegrationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createCloverPosIntegration
         * @param {CloverPosIntegrationCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCloverPosIntegrationUsingPOST(payload: CloverPosIntegrationCreatePayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CloverPosIntegration> {
            const localVarFetchArgs = GatewayCloverPosIntegrationControllerApiFetchParamCreator(configuration).createCloverPosIntegrationUsingPOST(payload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteCloverPosIntegrationById
         * @param {string} cloverPosIntegrationId cloverPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCloverPosIntegrationByIdUsingDELETE(cloverPosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayCloverPosIntegrationControllerApiFetchParamCreator(configuration).deleteCloverPosIntegrationByIdUsingDELETE(cloverPosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCloverPosIntegrationById
         * @param {string} cloverPosIntegrationId cloverPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCloverPosIntegrationByIdUsingGET(cloverPosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CloverPosIntegration> {
            const localVarFetchArgs = GatewayCloverPosIntegrationControllerApiFetchParamCreator(configuration).getCloverPosIntegrationByIdUsingGET(cloverPosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCloverPosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCloverPosIntegrationsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CloverPosIntegration>> {
            const localVarFetchArgs = GatewayCloverPosIntegrationControllerApiFetchParamCreator(configuration).getCloverPosIntegrationsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOauthSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOauthSettingsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiProps> {
            const localVarFetchArgs = GatewayCloverPosIntegrationControllerApiFetchParamCreator(configuration).getOauthSettingsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary redirectURL
         * @param {string} clientId client_id
         * @param {string} code code
         * @param {string} employeeId employee_id
         * @param {string} merchantId merchant_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectURLUsingGET(clientId: string, code: string, employeeId: string, merchantId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayCloverPosIntegrationControllerApiFetchParamCreator(configuration).redirectURLUsingGET(clientId, code, employeeId, merchantId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary refreshAccessToken
         * @param {string} cloverPosIntegrationId cloverPosIntegrationId
         * @param {CloverRefreshTokenPayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAccessTokenUsingPATCH(cloverPosIntegrationId: string, payload: CloverRefreshTokenPayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CloverPosIntegration> {
            const localVarFetchArgs = GatewayCloverPosIntegrationControllerApiFetchParamCreator(configuration).refreshAccessTokenUsingPATCH(cloverPosIntegrationId, payload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary testWebhook
         * @param {any} [payload] payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testWebhookUsingPOST(payload?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayCloverPosIntegrationControllerApiFetchParamCreator(configuration).testWebhookUsingPOST(payload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayCloverPosIntegrationControllerApi - factory interface
 * @export
 */
export const GatewayCloverPosIntegrationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createCloverPosIntegration
         * @param {CloverPosIntegrationCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCloverPosIntegrationUsingPOST(payload: CloverPosIntegrationCreatePayload, options?: any) {
            return GatewayCloverPosIntegrationControllerApiFp(configuration).createCloverPosIntegrationUsingPOST(payload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteCloverPosIntegrationById
         * @param {string} cloverPosIntegrationId cloverPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCloverPosIntegrationByIdUsingDELETE(cloverPosIntegrationId: string, options?: any) {
            return GatewayCloverPosIntegrationControllerApiFp(configuration).deleteCloverPosIntegrationByIdUsingDELETE(cloverPosIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCloverPosIntegrationById
         * @param {string} cloverPosIntegrationId cloverPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCloverPosIntegrationByIdUsingGET(cloverPosIntegrationId: string, options?: any) {
            return GatewayCloverPosIntegrationControllerApiFp(configuration).getCloverPosIntegrationByIdUsingGET(cloverPosIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCloverPosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCloverPosIntegrationsUsingGET(options?: any) {
            return GatewayCloverPosIntegrationControllerApiFp(configuration).getCloverPosIntegrationsUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOauthSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOauthSettingsUsingGET(options?: any) {
            return GatewayCloverPosIntegrationControllerApiFp(configuration).getOauthSettingsUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary redirectURL
         * @param {string} clientId client_id
         * @param {string} code code
         * @param {string} employeeId employee_id
         * @param {string} merchantId merchant_id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        redirectURLUsingGET(clientId: string, code: string, employeeId: string, merchantId: string, options?: any) {
            return GatewayCloverPosIntegrationControllerApiFp(configuration).redirectURLUsingGET(clientId, code, employeeId, merchantId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary refreshAccessToken
         * @param {string} cloverPosIntegrationId cloverPosIntegrationId
         * @param {CloverRefreshTokenPayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshAccessTokenUsingPATCH(cloverPosIntegrationId: string, payload: CloverRefreshTokenPayload, options?: any) {
            return GatewayCloverPosIntegrationControllerApiFp(configuration).refreshAccessTokenUsingPATCH(cloverPosIntegrationId, payload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary testWebhook
         * @param {any} [payload] payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        testWebhookUsingPOST(payload?: any, options?: any) {
            return GatewayCloverPosIntegrationControllerApiFp(configuration).testWebhookUsingPOST(payload, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayCloverPosIntegrationControllerApi - object-oriented interface
 * @export
 * @class GatewayCloverPosIntegrationControllerApi
 * @extends {BaseAPI}
 */
export class GatewayCloverPosIntegrationControllerApi extends BaseAPI {
    /**
     * 
     * @summary createCloverPosIntegration
     * @param {CloverPosIntegrationCreatePayload} payload payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayCloverPosIntegrationControllerApi
     */
    public createCloverPosIntegrationUsingPOST(payload: CloverPosIntegrationCreatePayload, options?: any) {
        return GatewayCloverPosIntegrationControllerApiFp(this.configuration).createCloverPosIntegrationUsingPOST(payload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteCloverPosIntegrationById
     * @param {string} cloverPosIntegrationId cloverPosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayCloverPosIntegrationControllerApi
     */
    public deleteCloverPosIntegrationByIdUsingDELETE(cloverPosIntegrationId: string, options?: any) {
        return GatewayCloverPosIntegrationControllerApiFp(this.configuration).deleteCloverPosIntegrationByIdUsingDELETE(cloverPosIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCloverPosIntegrationById
     * @param {string} cloverPosIntegrationId cloverPosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayCloverPosIntegrationControllerApi
     */
    public getCloverPosIntegrationByIdUsingGET(cloverPosIntegrationId: string, options?: any) {
        return GatewayCloverPosIntegrationControllerApiFp(this.configuration).getCloverPosIntegrationByIdUsingGET(cloverPosIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCloverPosIntegrations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayCloverPosIntegrationControllerApi
     */
    public getCloverPosIntegrationsUsingGET(options?: any) {
        return GatewayCloverPosIntegrationControllerApiFp(this.configuration).getCloverPosIntegrationsUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOauthSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayCloverPosIntegrationControllerApi
     */
    public getOauthSettingsUsingGET(options?: any) {
        return GatewayCloverPosIntegrationControllerApiFp(this.configuration).getOauthSettingsUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary redirectURL
     * @param {string} clientId client_id
     * @param {string} code code
     * @param {string} employeeId employee_id
     * @param {string} merchantId merchant_id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayCloverPosIntegrationControllerApi
     */
    public redirectURLUsingGET(clientId: string, code: string, employeeId: string, merchantId: string, options?: any) {
        return GatewayCloverPosIntegrationControllerApiFp(this.configuration).redirectURLUsingGET(clientId, code, employeeId, merchantId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary refreshAccessToken
     * @param {string} cloverPosIntegrationId cloverPosIntegrationId
     * @param {CloverRefreshTokenPayload} payload payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayCloverPosIntegrationControllerApi
     */
    public refreshAccessTokenUsingPATCH(cloverPosIntegrationId: string, payload: CloverRefreshTokenPayload, options?: any) {
        return GatewayCloverPosIntegrationControllerApiFp(this.configuration).refreshAccessTokenUsingPATCH(cloverPosIntegrationId, payload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary testWebhook
     * @param {any} [payload] payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayCloverPosIntegrationControllerApi
     */
    public testWebhookUsingPOST(payload?: any, options?: any) {
        return GatewayCloverPosIntegrationControllerApiFp(this.configuration).testWebhookUsingPOST(payload, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayDashboardControllerApi - fetch parameter creator
 * @export
 */
export const GatewayDashboardControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary exportActivitiesToXlsx
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportActivitiesToXlsxUsingGET(from?: Date, to?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/v1/dashboard/activities/export-as-xlsx`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportOrganizationsActivitiesToXlsx
         * @param {Array<string>} organizations organizations
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOrganizationsActivitiesToXlsxUsingPOST(organizations: Array<string>, from?: Date, to?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'organizations' is not null or undefined
            if (organizations === null || organizations === undefined) {
                throw new RequiredError('organizations','Required parameter organizations was null or undefined when calling exportOrganizationsActivitiesToXlsxUsingPOST.');
            }
            const localVarPath = `/v1/dashboard/activities/export-as-xlsx`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(organizations || {}) : (organizations || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getActivitiesByCriteria
         * @param {Date} [from] from
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesByCriteriaUsingGET(from?: Date, organizations?: Array<string>, page?: number, size?: number, sort?: string, to?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/v1/dashboard/activities`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (organizations) {
                localVarQueryParameter['organizations'] = organizations;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getActivityById
         * @param {string} activityId activityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityByIdUsingGET(activityId: string, options: any = {}): FetchArgs {
            // verify required parameter 'activityId' is not null or undefined
            if (activityId === null || activityId === undefined) {
                throw new RequiredError('activityId','Required parameter activityId was null or undefined when calling getActivityByIdUsingGET.');
            }
            const localVarPath = `/v1/dashboard/activities/{activityId}`
                .replace(`{${"activityId"}}`, encodeURIComponent(String(activityId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getNotificationById
         * @param {string} notificationId notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationByIdUsingGET(notificationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'notificationId' is not null or undefined
            if (notificationId === null || notificationId === undefined) {
                throw new RequiredError('notificationId','Required parameter notificationId was null or undefined when calling getNotificationByIdUsingGET.');
            }
            const localVarPath = `/v1/dashboard/notifications/{notificationId}`
                .replace(`{${"notificationId"}}`, encodeURIComponent(String(notificationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getNotificationsByCriteria
         * @param {Date} [from] from
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByCriteriaUsingGET(from?: Date, organizations?: Array<string>, page?: number, size?: number, sort?: string, to?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/v1/dashboard/notifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (organizations) {
                localVarQueryParameter['organizations'] = organizations;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayDashboardControllerApi - functional programming interface
 * @export
 */
export const GatewayDashboardControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary exportActivitiesToXlsx
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportActivitiesToXlsxUsingGET(from?: Date, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayDashboardControllerApiFetchParamCreator(configuration).exportActivitiesToXlsxUsingGET(from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportOrganizationsActivitiesToXlsx
         * @param {Array<string>} organizations organizations
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOrganizationsActivitiesToXlsxUsingPOST(organizations: Array<string>, from?: Date, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayDashboardControllerApiFetchParamCreator(configuration).exportOrganizationsActivitiesToXlsxUsingPOST(organizations, from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getActivitiesByCriteria
         * @param {Date} [from] from
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesByCriteriaUsingGET(from?: Date, organizations?: Array<string>, page?: number, size?: number, sort?: string, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageActivityWithOrgsNames> {
            const localVarFetchArgs = GatewayDashboardControllerApiFetchParamCreator(configuration).getActivitiesByCriteriaUsingGET(from, organizations, page, size, sort, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getActivityById
         * @param {string} activityId activityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityByIdUsingGET(activityId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Activity> {
            const localVarFetchArgs = GatewayDashboardControllerApiFetchParamCreator(configuration).getActivityByIdUsingGET(activityId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getNotificationById
         * @param {string} notificationId notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationByIdUsingGET(notificationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Notification> {
            const localVarFetchArgs = GatewayDashboardControllerApiFetchParamCreator(configuration).getNotificationByIdUsingGET(notificationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getNotificationsByCriteria
         * @param {Date} [from] from
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByCriteriaUsingGET(from?: Date, organizations?: Array<string>, page?: number, size?: number, sort?: string, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageNotificationWithOrgsNames> {
            const localVarFetchArgs = GatewayDashboardControllerApiFetchParamCreator(configuration).getNotificationsByCriteriaUsingGET(from, organizations, page, size, sort, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayDashboardControllerApi - factory interface
 * @export
 */
export const GatewayDashboardControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary exportActivitiesToXlsx
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportActivitiesToXlsxUsingGET(from?: Date, to?: Date, options?: any) {
            return GatewayDashboardControllerApiFp(configuration).exportActivitiesToXlsxUsingGET(from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportOrganizationsActivitiesToXlsx
         * @param {Array<string>} organizations organizations
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportOrganizationsActivitiesToXlsxUsingPOST(organizations: Array<string>, from?: Date, to?: Date, options?: any) {
            return GatewayDashboardControllerApiFp(configuration).exportOrganizationsActivitiesToXlsxUsingPOST(organizations, from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getActivitiesByCriteria
         * @param {Date} [from] from
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivitiesByCriteriaUsingGET(from?: Date, organizations?: Array<string>, page?: number, size?: number, sort?: string, to?: Date, options?: any) {
            return GatewayDashboardControllerApiFp(configuration).getActivitiesByCriteriaUsingGET(from, organizations, page, size, sort, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getActivityById
         * @param {string} activityId activityId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getActivityByIdUsingGET(activityId: string, options?: any) {
            return GatewayDashboardControllerApiFp(configuration).getActivityByIdUsingGET(activityId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getNotificationById
         * @param {string} notificationId notificationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationByIdUsingGET(notificationId: string, options?: any) {
            return GatewayDashboardControllerApiFp(configuration).getNotificationByIdUsingGET(notificationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getNotificationsByCriteria
         * @param {Date} [from] from
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotificationsByCriteriaUsingGET(from?: Date, organizations?: Array<string>, page?: number, size?: number, sort?: string, to?: Date, options?: any) {
            return GatewayDashboardControllerApiFp(configuration).getNotificationsByCriteriaUsingGET(from, organizations, page, size, sort, to, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayDashboardControllerApi - object-oriented interface
 * @export
 * @class GatewayDashboardControllerApi
 * @extends {BaseAPI}
 */
export class GatewayDashboardControllerApi extends BaseAPI {
    /**
     * 
     * @summary exportActivitiesToXlsx
     * @param {Date} [from] from
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDashboardControllerApi
     */
    public exportActivitiesToXlsxUsingGET(from?: Date, to?: Date, options?: any) {
        return GatewayDashboardControllerApiFp(this.configuration).exportActivitiesToXlsxUsingGET(from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportOrganizationsActivitiesToXlsx
     * @param {Array<string>} organizations organizations
     * @param {Date} [from] from
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDashboardControllerApi
     */
    public exportOrganizationsActivitiesToXlsxUsingPOST(organizations: Array<string>, from?: Date, to?: Date, options?: any) {
        return GatewayDashboardControllerApiFp(this.configuration).exportOrganizationsActivitiesToXlsxUsingPOST(organizations, from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getActivitiesByCriteria
     * @param {Date} [from] from
     * @param {Array<string>} [organizations] organizations
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDashboardControllerApi
     */
    public getActivitiesByCriteriaUsingGET(from?: Date, organizations?: Array<string>, page?: number, size?: number, sort?: string, to?: Date, options?: any) {
        return GatewayDashboardControllerApiFp(this.configuration).getActivitiesByCriteriaUsingGET(from, organizations, page, size, sort, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getActivityById
     * @param {string} activityId activityId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDashboardControllerApi
     */
    public getActivityByIdUsingGET(activityId: string, options?: any) {
        return GatewayDashboardControllerApiFp(this.configuration).getActivityByIdUsingGET(activityId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getNotificationById
     * @param {string} notificationId notificationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDashboardControllerApi
     */
    public getNotificationByIdUsingGET(notificationId: string, options?: any) {
        return GatewayDashboardControllerApiFp(this.configuration).getNotificationByIdUsingGET(notificationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getNotificationsByCriteria
     * @param {Date} [from] from
     * @param {Array<string>} [organizations] organizations
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDashboardControllerApi
     */
    public getNotificationsByCriteriaUsingGET(from?: Date, organizations?: Array<string>, page?: number, size?: number, sort?: string, to?: Date, options?: any) {
        return GatewayDashboardControllerApiFp(this.configuration).getNotificationsByCriteriaUsingGET(from, organizations, page, size, sort, to, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayDeliveryNoteControllerApi - fetch parameter creator
 * @export
 */
export const GatewayDeliveryNoteControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary approveDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveDeliveryNoteUsingPOST(deliveryNoteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryNoteId' is not null or undefined
            if (deliveryNoteId === null || deliveryNoteId === undefined) {
                throw new RequiredError('deliveryNoteId','Required parameter deliveryNoteId was null or undefined when calling approveDeliveryNoteUsingPOST.');
            }
            const localVarPath = `/v1/delivery-notes/{deliveryNoteId}/approve-dn`
                .replace(`{${"deliveryNoteId"}}`, encodeURIComponent(String(deliveryNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createDeliveryNoteFromInvoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryNoteFromInvoiceUsingPOST(invoiceId: string, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling createDeliveryNoteFromInvoiceUsingPOST.');
            }
            const localVarPath = `/v1/delivery-notes/from-invoice`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (invoiceId !== undefined) {
                localVarQueryParameter['invoiceId'] = invoiceId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createDeliveryNote
         * @param {DeliveryNoteCreateDto} deliveryNoteCreateDto deliveryNoteCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryNoteUsingPOST(deliveryNoteCreateDto: DeliveryNoteCreateDto, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryNoteCreateDto' is not null or undefined
            if (deliveryNoteCreateDto === null || deliveryNoteCreateDto === undefined) {
                throw new RequiredError('deliveryNoteCreateDto','Required parameter deliveryNoteCreateDto was null or undefined when calling createDeliveryNoteUsingPOST.');
            }
            const localVarPath = `/v1/delivery-notes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeliveryNoteCreateDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(deliveryNoteCreateDto || {}) : (deliveryNoteCreateDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryNoteUsingDELETE(deliveryNoteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryNoteId' is not null or undefined
            if (deliveryNoteId === null || deliveryNoteId === undefined) {
                throw new RequiredError('deliveryNoteId','Required parameter deliveryNoteId was null or undefined when calling deleteDeliveryNoteUsingDELETE.');
            }
            const localVarPath = `/v1/delivery-notes/{deliveryNoteId}`
                .replace(`{${"deliveryNoteId"}}`, encodeURIComponent(String(deliveryNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportDeliveryNotesAsCSV
         * @param {Date} [from] from
         * @param {'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {Array<string>} [suppliersIds] suppliersIds
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeliveryNotesAsCSVUsingGET(from?: Date, status?: 'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/v1/delivery-notes/export-as-csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            if (suppliersIds) {
                localVarQueryParameter['suppliersIds'] = suppliersIds;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportDeliveryNotesAsXlsx
         * @param {Date} [from] from
         * @param {'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {Array<string>} [suppliersIds] suppliersIds
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeliveryNotesAsXlsxUsingGET(from?: Date, status?: 'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/v1/delivery-notes/export-as-xlsx`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            if (suppliersIds) {
                localVarQueryParameter['suppliersIds'] = suppliersIds;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllDeliveredProductsInTimeIntervalByBarcode
         * @param {Array<string>} barcodes barcodes
         * @param {Date} from from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeliveredProductsInTimeIntervalByBarcodeUsingPOST(barcodes: Array<string>, from: Date, to?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'barcodes' is not null or undefined
            if (barcodes === null || barcodes === undefined) {
                throw new RequiredError('barcodes','Required parameter barcodes was null or undefined when calling getAllDeliveredProductsInTimeIntervalByBarcodeUsingPOST.');
            }
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling getAllDeliveredProductsInTimeIntervalByBarcodeUsingPOST.');
            }
            const localVarPath = `/v1/delivery-notes/products-qty-by-barcodes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(barcodes || {}) : (barcodes || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllDeliveredProductsInTimeInterval
         * @param {Date} from from
         * @param {boolean} [includeCategory] includeCategory
         * @param {boolean} [sortByCategory] sortByCategory
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeliveredProductsInTimeIntervalUsingGET(from: Date, includeCategory?: boolean, sortByCategory?: boolean, to?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling getAllDeliveredProductsInTimeIntervalUsingGET.');
            }
            const localVarPath = `/v1/delivery-notes/products-qty`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (includeCategory !== undefined) {
                localVarQueryParameter['includeCategory'] = includeCategory;
            }

            if (sortByCategory !== undefined) {
                localVarQueryParameter['sortByCategory'] = sortByCategory;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDeliveryNoteByDNNumber
         * @param {string} deliveryNoteNumber deliveryNoteNumber
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryNoteByDNNumberUsingGET(deliveryNoteNumber: string, supplierId: string, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryNoteNumber' is not null or undefined
            if (deliveryNoteNumber === null || deliveryNoteNumber === undefined) {
                throw new RequiredError('deliveryNoteNumber','Required parameter deliveryNoteNumber was null or undefined when calling getDeliveryNoteByDNNumberUsingGET.');
            }
            // verify required parameter 'supplierId' is not null or undefined
            if (supplierId === null || supplierId === undefined) {
                throw new RequiredError('supplierId','Required parameter supplierId was null or undefined when calling getDeliveryNoteByDNNumberUsingGET.');
            }
            const localVarPath = `/v1/delivery-notes/dn-number/{deliveryNoteNumber}`
                .replace(`{${"deliveryNoteNumber"}}`, encodeURIComponent(String(deliveryNoteNumber)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDeliveryNoteById
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryNoteByIdUsingGET(deliveryNoteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryNoteId' is not null or undefined
            if (deliveryNoteId === null || deliveryNoteId === undefined) {
                throw new RequiredError('deliveryNoteId','Required parameter deliveryNoteId was null or undefined when calling getDeliveryNoteByIdUsingGET.');
            }
            const localVarPath = `/v1/delivery-notes/{deliveryNoteId}`
                .replace(`{${"deliveryNoteId"}}`, encodeURIComponent(String(deliveryNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDeliveryNoteByPONumber
         * @param {string} po po
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryNoteByPONumberUsingGET(po: string, supplierId: string, options: any = {}): FetchArgs {
            // verify required parameter 'po' is not null or undefined
            if (po === null || po === undefined) {
                throw new RequiredError('po','Required parameter po was null or undefined when calling getDeliveryNoteByPONumberUsingGET.');
            }
            // verify required parameter 'supplierId' is not null or undefined
            if (supplierId === null || supplierId === undefined) {
                throw new RequiredError('supplierId','Required parameter supplierId was null or undefined when calling getDeliveryNoteByPONumberUsingGET.');
            }
            const localVarPath = `/v1/delivery-notes/po-number/{po}`
                .replace(`{${"po"}}`, encodeURIComponent(String(po)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDeliveryNotesByCriteria
         * @param {string} [deliveryNoteNumber] deliveryNoteNumber
         * @param {boolean} [includeIsInvoiced] includeIsInvoiced
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {string} [partialBarcode] partialBarcode
         * @param {string} [partialProductCode] partialProductCode
         * @param {string} [partialProductName] partialProductName
         * @param {string} [po] po
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryNotesByCriteriaUsingGET(deliveryNoteNumber?: string, includeIsInvoiced?: boolean, organizations?: Array<string>, page?: number, partialBarcode?: string, partialProductCode?: string, partialProductName?: string, po?: string, size?: number, sort?: string, status?: 'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED', supplierId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/delivery-notes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deliveryNoteNumber !== undefined) {
                localVarQueryParameter['deliveryNoteNumber'] = deliveryNoteNumber;
            }

            if (includeIsInvoiced !== undefined) {
                localVarQueryParameter['includeIsInvoiced'] = includeIsInvoiced;
            }

            if (organizations) {
                localVarQueryParameter['organizations'] = organizations;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialBarcode !== undefined) {
                localVarQueryParameter['partialBarcode'] = partialBarcode;
            }

            if (partialProductCode !== undefined) {
                localVarQueryParameter['partialProductCode'] = partialProductCode;
            }

            if (partialProductName !== undefined) {
                localVarQueryParameter['partialProductName'] = partialProductName;
            }

            if (po !== undefined) {
                localVarQueryParameter['po'] = po;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary querySupplierForDiscrepancies
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {Array<any>} [files] files
         * @param {string} [message] message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        querySupplierForDiscrepanciesUsingPOST(deliveryNoteId: string, files?: Array<any>, message?: string, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryNoteId' is not null or undefined
            if (deliveryNoteId === null || deliveryNoteId === undefined) {
                throw new RequiredError('deliveryNoteId','Required parameter deliveryNoteId was null or undefined when calling querySupplierForDiscrepanciesUsingPOST.');
            }
            const localVarPath = `/v1/delivery-notes/{deliveryNoteId}/query-supplier`
                .replace(`{${"deliveryNoteId"}}`, encodeURIComponent(String(deliveryNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;any&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(files || {}) : (files || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary rejectDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {boolean} [completeOrder] completeOrder
         * @param {Array<any>} [files] files
         * @param {string} [message] message
         * @param {boolean} [notifySupplier] notifySupplier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectDeliveryNoteUsingPOST(deliveryNoteId: string, completeOrder?: boolean, files?: Array<any>, message?: string, notifySupplier?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryNoteId' is not null or undefined
            if (deliveryNoteId === null || deliveryNoteId === undefined) {
                throw new RequiredError('deliveryNoteId','Required parameter deliveryNoteId was null or undefined when calling rejectDeliveryNoteUsingPOST.');
            }
            const localVarPath = `/v1/delivery-notes/{deliveryNoteId}/reject-dn`
                .replace(`{${"deliveryNoteId"}}`, encodeURIComponent(String(deliveryNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (completeOrder !== undefined) {
                localVarQueryParameter['completeOrder'] = completeOrder;
            }

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }

            if (notifySupplier !== undefined) {
                localVarQueryParameter['notifySupplier'] = notifySupplier;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;any&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(files || {}) : (files || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDeliveryNoteByDNNumber
         * @param {string} deliveryNoteNumber deliveryNoteNumber
         * @param {DeliveryNotePatchDto} deliveryNotePatchDto deliveryNotePatchDto
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteByDNNumberUsingPATCH(deliveryNoteNumber: string, deliveryNotePatchDto: DeliveryNotePatchDto, supplierId: string, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryNoteNumber' is not null or undefined
            if (deliveryNoteNumber === null || deliveryNoteNumber === undefined) {
                throw new RequiredError('deliveryNoteNumber','Required parameter deliveryNoteNumber was null or undefined when calling updateDeliveryNoteByDNNumberUsingPATCH.');
            }
            // verify required parameter 'deliveryNotePatchDto' is not null or undefined
            if (deliveryNotePatchDto === null || deliveryNotePatchDto === undefined) {
                throw new RequiredError('deliveryNotePatchDto','Required parameter deliveryNotePatchDto was null or undefined when calling updateDeliveryNoteByDNNumberUsingPATCH.');
            }
            // verify required parameter 'supplierId' is not null or undefined
            if (supplierId === null || supplierId === undefined) {
                throw new RequiredError('supplierId','Required parameter supplierId was null or undefined when calling updateDeliveryNoteByDNNumberUsingPATCH.');
            }
            const localVarPath = `/v1/delivery-notes/dn-number/{deliveryNoteNumber}`
                .replace(`{${"deliveryNoteNumber"}}`, encodeURIComponent(String(deliveryNoteNumber)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeliveryNotePatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(deliveryNotePatchDto || {}) : (deliveryNotePatchDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDeliveryNoteById
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {DeliveryNotePatchDto} deliveryNotePatchDto deliveryNotePatchDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteByIdUsingPATCH(deliveryNoteId: string, deliveryNotePatchDto: DeliveryNotePatchDto, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryNoteId' is not null or undefined
            if (deliveryNoteId === null || deliveryNoteId === undefined) {
                throw new RequiredError('deliveryNoteId','Required parameter deliveryNoteId was null or undefined when calling updateDeliveryNoteByIdUsingPATCH.');
            }
            // verify required parameter 'deliveryNotePatchDto' is not null or undefined
            if (deliveryNotePatchDto === null || deliveryNotePatchDto === undefined) {
                throw new RequiredError('deliveryNotePatchDto','Required parameter deliveryNotePatchDto was null or undefined when calling updateDeliveryNoteByIdUsingPATCH.');
            }
            const localVarPath = `/v1/delivery-notes/{deliveryNoteId}`
                .replace(`{${"deliveryNoteId"}}`, encodeURIComponent(String(deliveryNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeliveryNotePatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(deliveryNotePatchDto || {}) : (deliveryNotePatchDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDeliveryNoteByPONumber
         * @param {DeliveryNotePatchDto} deliveryNotePatchDto deliveryNotePatchDto
         * @param {string} po po
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteByPONumberUsingPATCH(deliveryNotePatchDto: DeliveryNotePatchDto, po: string, supplierId: string, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryNotePatchDto' is not null or undefined
            if (deliveryNotePatchDto === null || deliveryNotePatchDto === undefined) {
                throw new RequiredError('deliveryNotePatchDto','Required parameter deliveryNotePatchDto was null or undefined when calling updateDeliveryNoteByPONumberUsingPATCH.');
            }
            // verify required parameter 'po' is not null or undefined
            if (po === null || po === undefined) {
                throw new RequiredError('po','Required parameter po was null or undefined when calling updateDeliveryNoteByPONumberUsingPATCH.');
            }
            // verify required parameter 'supplierId' is not null or undefined
            if (supplierId === null || supplierId === undefined) {
                throw new RequiredError('supplierId','Required parameter supplierId was null or undefined when calling updateDeliveryNoteByPONumberUsingPATCH.');
            }
            const localVarPath = `/v1/delivery-notes/po-number/{po}`
                .replace(`{${"po"}}`, encodeURIComponent(String(po)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeliveryNotePatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(deliveryNotePatchDto || {}) : (deliveryNotePatchDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDeliveryNoteFiles
         * @param {DeliveryNoteFilesDto} deliveryNoteFilesDto deliveryNoteFilesDto
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteFilesUsingPOST(deliveryNoteFilesDto: DeliveryNoteFilesDto, deliveryNoteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryNoteFilesDto' is not null or undefined
            if (deliveryNoteFilesDto === null || deliveryNoteFilesDto === undefined) {
                throw new RequiredError('deliveryNoteFilesDto','Required parameter deliveryNoteFilesDto was null or undefined when calling updateDeliveryNoteFilesUsingPOST.');
            }
            // verify required parameter 'deliveryNoteId' is not null or undefined
            if (deliveryNoteId === null || deliveryNoteId === undefined) {
                throw new RequiredError('deliveryNoteId','Required parameter deliveryNoteId was null or undefined when calling updateDeliveryNoteFilesUsingPOST.');
            }
            const localVarPath = `/v1/delivery-notes/{deliveryNoteId}/files`
                .replace(`{${"deliveryNoteId"}}`, encodeURIComponent(String(deliveryNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeliveryNoteFilesDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(deliveryNoteFilesDto || {}) : (deliveryNoteFilesDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {DeliveryNoteUpdateDto} deliveryNoteUpdateDto deliveryNoteUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteUsingPUT(deliveryNoteId: string, deliveryNoteUpdateDto: DeliveryNoteUpdateDto, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryNoteId' is not null or undefined
            if (deliveryNoteId === null || deliveryNoteId === undefined) {
                throw new RequiredError('deliveryNoteId','Required parameter deliveryNoteId was null or undefined when calling updateDeliveryNoteUsingPUT.');
            }
            // verify required parameter 'deliveryNoteUpdateDto' is not null or undefined
            if (deliveryNoteUpdateDto === null || deliveryNoteUpdateDto === undefined) {
                throw new RequiredError('deliveryNoteUpdateDto','Required parameter deliveryNoteUpdateDto was null or undefined when calling updateDeliveryNoteUsingPUT.');
            }
            const localVarPath = `/v1/delivery-notes/{deliveryNoteId}`
                .replace(`{${"deliveryNoteId"}}`, encodeURIComponent(String(deliveryNoteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DeliveryNoteUpdateDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(deliveryNoteUpdateDto || {}) : (deliveryNoteUpdateDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayDeliveryNoteControllerApi - functional programming interface
 * @export
 */
export const GatewayDeliveryNoteControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary approveDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveDeliveryNoteUsingPOST(deliveryNoteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).approveDeliveryNoteUsingPOST(deliveryNoteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createDeliveryNoteFromInvoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryNoteFromInvoiceUsingPOST(invoiceId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeliveryNote> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).createDeliveryNoteFromInvoiceUsingPOST(invoiceId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createDeliveryNote
         * @param {DeliveryNoteCreateDto} deliveryNoteCreateDto deliveryNoteCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryNoteUsingPOST(deliveryNoteCreateDto: DeliveryNoteCreateDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeliveryNote> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).createDeliveryNoteUsingPOST(deliveryNoteCreateDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryNoteUsingDELETE(deliveryNoteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).deleteDeliveryNoteUsingDELETE(deliveryNoteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportDeliveryNotesAsCSV
         * @param {Date} [from] from
         * @param {'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {Array<string>} [suppliersIds] suppliersIds
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeliveryNotesAsCSVUsingGET(from?: Date, status?: 'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).exportDeliveryNotesAsCSVUsingGET(from, status, supplierId, suppliersIds, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportDeliveryNotesAsXlsx
         * @param {Date} [from] from
         * @param {'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {Array<string>} [suppliersIds] suppliersIds
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeliveryNotesAsXlsxUsingGET(from?: Date, status?: 'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).exportDeliveryNotesAsXlsxUsingGET(from, status, supplierId, suppliersIds, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllDeliveredProductsInTimeIntervalByBarcode
         * @param {Array<string>} barcodes barcodes
         * @param {Date} from from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeliveredProductsInTimeIntervalByBarcodeUsingPOST(barcodes: Array<string>, from: Date, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DeliveredProductDto>> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).getAllDeliveredProductsInTimeIntervalByBarcodeUsingPOST(barcodes, from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllDeliveredProductsInTimeInterval
         * @param {Date} from from
         * @param {boolean} [includeCategory] includeCategory
         * @param {boolean} [sortByCategory] sortByCategory
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeliveredProductsInTimeIntervalUsingGET(from: Date, includeCategory?: boolean, sortByCategory?: boolean, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<DeliveredProductSortedDto>> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).getAllDeliveredProductsInTimeIntervalUsingGET(from, includeCategory, sortByCategory, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDeliveryNoteByDNNumber
         * @param {string} deliveryNoteNumber deliveryNoteNumber
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryNoteByDNNumberUsingGET(deliveryNoteNumber: string, supplierId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeliveryNote> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).getDeliveryNoteByDNNumberUsingGET(deliveryNoteNumber, supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDeliveryNoteById
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryNoteByIdUsingGET(deliveryNoteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeliveryNote> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).getDeliveryNoteByIdUsingGET(deliveryNoteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDeliveryNoteByPONumber
         * @param {string} po po
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryNoteByPONumberUsingGET(po: string, supplierId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeliveryNote> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).getDeliveryNoteByPONumberUsingGET(po, supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDeliveryNotesByCriteria
         * @param {string} [deliveryNoteNumber] deliveryNoteNumber
         * @param {boolean} [includeIsInvoiced] includeIsInvoiced
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {string} [partialBarcode] partialBarcode
         * @param {string} [partialProductCode] partialProductCode
         * @param {string} [partialProductName] partialProductName
         * @param {string} [po] po
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryNotesByCriteriaUsingGET(deliveryNoteNumber?: string, includeIsInvoiced?: boolean, organizations?: Array<string>, page?: number, partialBarcode?: string, partialProductCode?: string, partialProductName?: string, po?: string, size?: number, sort?: string, status?: 'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED', supplierId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageDeliveryNoteWithIsInvoicedFlag> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).getDeliveryNotesByCriteriaUsingGET(deliveryNoteNumber, includeIsInvoiced, organizations, page, partialBarcode, partialProductCode, partialProductName, po, size, sort, status, supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary querySupplierForDiscrepancies
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {Array<any>} [files] files
         * @param {string} [message] message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        querySupplierForDiscrepanciesUsingPOST(deliveryNoteId: string, files?: Array<any>, message?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).querySupplierForDiscrepanciesUsingPOST(deliveryNoteId, files, message, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary rejectDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {boolean} [completeOrder] completeOrder
         * @param {Array<any>} [files] files
         * @param {string} [message] message
         * @param {boolean} [notifySupplier] notifySupplier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectDeliveryNoteUsingPOST(deliveryNoteId: string, completeOrder?: boolean, files?: Array<any>, message?: string, notifySupplier?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).rejectDeliveryNoteUsingPOST(deliveryNoteId, completeOrder, files, message, notifySupplier, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateDeliveryNoteByDNNumber
         * @param {string} deliveryNoteNumber deliveryNoteNumber
         * @param {DeliveryNotePatchDto} deliveryNotePatchDto deliveryNotePatchDto
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteByDNNumberUsingPATCH(deliveryNoteNumber: string, deliveryNotePatchDto: DeliveryNotePatchDto, supplierId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeliveryNote> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).updateDeliveryNoteByDNNumberUsingPATCH(deliveryNoteNumber, deliveryNotePatchDto, supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateDeliveryNoteById
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {DeliveryNotePatchDto} deliveryNotePatchDto deliveryNotePatchDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteByIdUsingPATCH(deliveryNoteId: string, deliveryNotePatchDto: DeliveryNotePatchDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeliveryNote> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).updateDeliveryNoteByIdUsingPATCH(deliveryNoteId, deliveryNotePatchDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateDeliveryNoteByPONumber
         * @param {DeliveryNotePatchDto} deliveryNotePatchDto deliveryNotePatchDto
         * @param {string} po po
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteByPONumberUsingPATCH(deliveryNotePatchDto: DeliveryNotePatchDto, po: string, supplierId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeliveryNote> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).updateDeliveryNoteByPONumberUsingPATCH(deliveryNotePatchDto, po, supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateDeliveryNoteFiles
         * @param {DeliveryNoteFilesDto} deliveryNoteFilesDto deliveryNoteFilesDto
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteFilesUsingPOST(deliveryNoteFilesDto: DeliveryNoteFilesDto, deliveryNoteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeliveryNote> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).updateDeliveryNoteFilesUsingPOST(deliveryNoteFilesDto, deliveryNoteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {DeliveryNoteUpdateDto} deliveryNoteUpdateDto deliveryNoteUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteUsingPUT(deliveryNoteId: string, deliveryNoteUpdateDto: DeliveryNoteUpdateDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DeliveryNote> {
            const localVarFetchArgs = GatewayDeliveryNoteControllerApiFetchParamCreator(configuration).updateDeliveryNoteUsingPUT(deliveryNoteId, deliveryNoteUpdateDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayDeliveryNoteControllerApi - factory interface
 * @export
 */
export const GatewayDeliveryNoteControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary approveDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveDeliveryNoteUsingPOST(deliveryNoteId: string, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).approveDeliveryNoteUsingPOST(deliveryNoteId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createDeliveryNoteFromInvoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryNoteFromInvoiceUsingPOST(invoiceId: string, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).createDeliveryNoteFromInvoiceUsingPOST(invoiceId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createDeliveryNote
         * @param {DeliveryNoteCreateDto} deliveryNoteCreateDto deliveryNoteCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDeliveryNoteUsingPOST(deliveryNoteCreateDto: DeliveryNoteCreateDto, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).createDeliveryNoteUsingPOST(deliveryNoteCreateDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDeliveryNoteUsingDELETE(deliveryNoteId: string, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).deleteDeliveryNoteUsingDELETE(deliveryNoteId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportDeliveryNotesAsCSV
         * @param {Date} [from] from
         * @param {'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {Array<string>} [suppliersIds] suppliersIds
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeliveryNotesAsCSVUsingGET(from?: Date, status?: 'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).exportDeliveryNotesAsCSVUsingGET(from, status, supplierId, suppliersIds, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportDeliveryNotesAsXlsx
         * @param {Date} [from] from
         * @param {'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {Array<string>} [suppliersIds] suppliersIds
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDeliveryNotesAsXlsxUsingGET(from?: Date, status?: 'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).exportDeliveryNotesAsXlsxUsingGET(from, status, supplierId, suppliersIds, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllDeliveredProductsInTimeIntervalByBarcode
         * @param {Array<string>} barcodes barcodes
         * @param {Date} from from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeliveredProductsInTimeIntervalByBarcodeUsingPOST(barcodes: Array<string>, from: Date, to?: Date, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).getAllDeliveredProductsInTimeIntervalByBarcodeUsingPOST(barcodes, from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllDeliveredProductsInTimeInterval
         * @param {Date} from from
         * @param {boolean} [includeCategory] includeCategory
         * @param {boolean} [sortByCategory] sortByCategory
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeliveredProductsInTimeIntervalUsingGET(from: Date, includeCategory?: boolean, sortByCategory?: boolean, to?: Date, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).getAllDeliveredProductsInTimeIntervalUsingGET(from, includeCategory, sortByCategory, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getDeliveryNoteByDNNumber
         * @param {string} deliveryNoteNumber deliveryNoteNumber
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryNoteByDNNumberUsingGET(deliveryNoteNumber: string, supplierId: string, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).getDeliveryNoteByDNNumberUsingGET(deliveryNoteNumber, supplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getDeliveryNoteById
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryNoteByIdUsingGET(deliveryNoteId: string, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).getDeliveryNoteByIdUsingGET(deliveryNoteId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getDeliveryNoteByPONumber
         * @param {string} po po
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryNoteByPONumberUsingGET(po: string, supplierId: string, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).getDeliveryNoteByPONumberUsingGET(po, supplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getDeliveryNotesByCriteria
         * @param {string} [deliveryNoteNumber] deliveryNoteNumber
         * @param {boolean} [includeIsInvoiced] includeIsInvoiced
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {string} [partialBarcode] partialBarcode
         * @param {string} [partialProductCode] partialProductCode
         * @param {string} [partialProductName] partialProductName
         * @param {string} [po] po
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeliveryNotesByCriteriaUsingGET(deliveryNoteNumber?: string, includeIsInvoiced?: boolean, organizations?: Array<string>, page?: number, partialBarcode?: string, partialProductCode?: string, partialProductName?: string, po?: string, size?: number, sort?: string, status?: 'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED', supplierId?: string, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).getDeliveryNotesByCriteriaUsingGET(deliveryNoteNumber, includeIsInvoiced, organizations, page, partialBarcode, partialProductCode, partialProductName, po, size, sort, status, supplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary querySupplierForDiscrepancies
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {Array<any>} [files] files
         * @param {string} [message] message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        querySupplierForDiscrepanciesUsingPOST(deliveryNoteId: string, files?: Array<any>, message?: string, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).querySupplierForDiscrepanciesUsingPOST(deliveryNoteId, files, message, options)(fetch, basePath);
        },
        /**
         * 
         * @summary rejectDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {boolean} [completeOrder] completeOrder
         * @param {Array<any>} [files] files
         * @param {string} [message] message
         * @param {boolean} [notifySupplier] notifySupplier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectDeliveryNoteUsingPOST(deliveryNoteId: string, completeOrder?: boolean, files?: Array<any>, message?: string, notifySupplier?: boolean, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).rejectDeliveryNoteUsingPOST(deliveryNoteId, completeOrder, files, message, notifySupplier, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateDeliveryNoteByDNNumber
         * @param {string} deliveryNoteNumber deliveryNoteNumber
         * @param {DeliveryNotePatchDto} deliveryNotePatchDto deliveryNotePatchDto
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteByDNNumberUsingPATCH(deliveryNoteNumber: string, deliveryNotePatchDto: DeliveryNotePatchDto, supplierId: string, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).updateDeliveryNoteByDNNumberUsingPATCH(deliveryNoteNumber, deliveryNotePatchDto, supplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateDeliveryNoteById
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {DeliveryNotePatchDto} deliveryNotePatchDto deliveryNotePatchDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteByIdUsingPATCH(deliveryNoteId: string, deliveryNotePatchDto: DeliveryNotePatchDto, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).updateDeliveryNoteByIdUsingPATCH(deliveryNoteId, deliveryNotePatchDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateDeliveryNoteByPONumber
         * @param {DeliveryNotePatchDto} deliveryNotePatchDto deliveryNotePatchDto
         * @param {string} po po
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteByPONumberUsingPATCH(deliveryNotePatchDto: DeliveryNotePatchDto, po: string, supplierId: string, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).updateDeliveryNoteByPONumberUsingPATCH(deliveryNotePatchDto, po, supplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateDeliveryNoteFiles
         * @param {DeliveryNoteFilesDto} deliveryNoteFilesDto deliveryNoteFilesDto
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteFilesUsingPOST(deliveryNoteFilesDto: DeliveryNoteFilesDto, deliveryNoteId: string, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).updateDeliveryNoteFilesUsingPOST(deliveryNoteFilesDto, deliveryNoteId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {DeliveryNoteUpdateDto} deliveryNoteUpdateDto deliveryNoteUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDeliveryNoteUsingPUT(deliveryNoteId: string, deliveryNoteUpdateDto: DeliveryNoteUpdateDto, options?: any) {
            return GatewayDeliveryNoteControllerApiFp(configuration).updateDeliveryNoteUsingPUT(deliveryNoteId, deliveryNoteUpdateDto, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayDeliveryNoteControllerApi - object-oriented interface
 * @export
 * @class GatewayDeliveryNoteControllerApi
 * @extends {BaseAPI}
 */
export class GatewayDeliveryNoteControllerApi extends BaseAPI {
    /**
     * 
     * @summary approveDeliveryNote
     * @param {string} deliveryNoteId deliveryNoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public approveDeliveryNoteUsingPOST(deliveryNoteId: string, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).approveDeliveryNoteUsingPOST(deliveryNoteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createDeliveryNoteFromInvoice
     * @param {string} invoiceId invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public createDeliveryNoteFromInvoiceUsingPOST(invoiceId: string, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).createDeliveryNoteFromInvoiceUsingPOST(invoiceId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createDeliveryNote
     * @param {DeliveryNoteCreateDto} deliveryNoteCreateDto deliveryNoteCreateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public createDeliveryNoteUsingPOST(deliveryNoteCreateDto: DeliveryNoteCreateDto, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).createDeliveryNoteUsingPOST(deliveryNoteCreateDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteDeliveryNote
     * @param {string} deliveryNoteId deliveryNoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public deleteDeliveryNoteUsingDELETE(deliveryNoteId: string, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).deleteDeliveryNoteUsingDELETE(deliveryNoteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportDeliveryNotesAsCSV
     * @param {Date} [from] from
     * @param {'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED'} [status] status
     * @param {string} [supplierId] supplierId
     * @param {Array<string>} [suppliersIds] suppliersIds
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public exportDeliveryNotesAsCSVUsingGET(from?: Date, status?: 'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).exportDeliveryNotesAsCSVUsingGET(from, status, supplierId, suppliersIds, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportDeliveryNotesAsXlsx
     * @param {Date} [from] from
     * @param {'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED'} [status] status
     * @param {string} [supplierId] supplierId
     * @param {Array<string>} [suppliersIds] suppliersIds
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public exportDeliveryNotesAsXlsxUsingGET(from?: Date, status?: 'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).exportDeliveryNotesAsXlsxUsingGET(from, status, supplierId, suppliersIds, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllDeliveredProductsInTimeIntervalByBarcode
     * @param {Array<string>} barcodes barcodes
     * @param {Date} from from
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public getAllDeliveredProductsInTimeIntervalByBarcodeUsingPOST(barcodes: Array<string>, from: Date, to?: Date, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).getAllDeliveredProductsInTimeIntervalByBarcodeUsingPOST(barcodes, from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllDeliveredProductsInTimeInterval
     * @param {Date} from from
     * @param {boolean} [includeCategory] includeCategory
     * @param {boolean} [sortByCategory] sortByCategory
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public getAllDeliveredProductsInTimeIntervalUsingGET(from: Date, includeCategory?: boolean, sortByCategory?: boolean, to?: Date, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).getAllDeliveredProductsInTimeIntervalUsingGET(from, includeCategory, sortByCategory, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getDeliveryNoteByDNNumber
     * @param {string} deliveryNoteNumber deliveryNoteNumber
     * @param {string} supplierId supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public getDeliveryNoteByDNNumberUsingGET(deliveryNoteNumber: string, supplierId: string, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).getDeliveryNoteByDNNumberUsingGET(deliveryNoteNumber, supplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getDeliveryNoteById
     * @param {string} deliveryNoteId deliveryNoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public getDeliveryNoteByIdUsingGET(deliveryNoteId: string, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).getDeliveryNoteByIdUsingGET(deliveryNoteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getDeliveryNoteByPONumber
     * @param {string} po po
     * @param {string} supplierId supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public getDeliveryNoteByPONumberUsingGET(po: string, supplierId: string, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).getDeliveryNoteByPONumberUsingGET(po, supplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getDeliveryNotesByCriteria
     * @param {string} [deliveryNoteNumber] deliveryNoteNumber
     * @param {boolean} [includeIsInvoiced] includeIsInvoiced
     * @param {Array<string>} [organizations] organizations
     * @param {number} [page] 
     * @param {string} [partialBarcode] partialBarcode
     * @param {string} [partialProductCode] partialProductCode
     * @param {string} [partialProductName] partialProductName
     * @param {string} [po] po
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED'} [status] status
     * @param {string} [supplierId] supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public getDeliveryNotesByCriteriaUsingGET(deliveryNoteNumber?: string, includeIsInvoiced?: boolean, organizations?: Array<string>, page?: number, partialBarcode?: string, partialProductCode?: string, partialProductName?: string, po?: string, size?: number, sort?: string, status?: 'APPROVED' | 'COMPLETED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED', supplierId?: string, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).getDeliveryNotesByCriteriaUsingGET(deliveryNoteNumber, includeIsInvoiced, organizations, page, partialBarcode, partialProductCode, partialProductName, po, size, sort, status, supplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary querySupplierForDiscrepancies
     * @param {string} deliveryNoteId deliveryNoteId
     * @param {Array<any>} [files] files
     * @param {string} [message] message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public querySupplierForDiscrepanciesUsingPOST(deliveryNoteId: string, files?: Array<any>, message?: string, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).querySupplierForDiscrepanciesUsingPOST(deliveryNoteId, files, message, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary rejectDeliveryNote
     * @param {string} deliveryNoteId deliveryNoteId
     * @param {boolean} [completeOrder] completeOrder
     * @param {Array<any>} [files] files
     * @param {string} [message] message
     * @param {boolean} [notifySupplier] notifySupplier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public rejectDeliveryNoteUsingPOST(deliveryNoteId: string, completeOrder?: boolean, files?: Array<any>, message?: string, notifySupplier?: boolean, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).rejectDeliveryNoteUsingPOST(deliveryNoteId, completeOrder, files, message, notifySupplier, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateDeliveryNoteByDNNumber
     * @param {string} deliveryNoteNumber deliveryNoteNumber
     * @param {DeliveryNotePatchDto} deliveryNotePatchDto deliveryNotePatchDto
     * @param {string} supplierId supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public updateDeliveryNoteByDNNumberUsingPATCH(deliveryNoteNumber: string, deliveryNotePatchDto: DeliveryNotePatchDto, supplierId: string, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).updateDeliveryNoteByDNNumberUsingPATCH(deliveryNoteNumber, deliveryNotePatchDto, supplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateDeliveryNoteById
     * @param {string} deliveryNoteId deliveryNoteId
     * @param {DeliveryNotePatchDto} deliveryNotePatchDto deliveryNotePatchDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public updateDeliveryNoteByIdUsingPATCH(deliveryNoteId: string, deliveryNotePatchDto: DeliveryNotePatchDto, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).updateDeliveryNoteByIdUsingPATCH(deliveryNoteId, deliveryNotePatchDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateDeliveryNoteByPONumber
     * @param {DeliveryNotePatchDto} deliveryNotePatchDto deliveryNotePatchDto
     * @param {string} po po
     * @param {string} supplierId supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public updateDeliveryNoteByPONumberUsingPATCH(deliveryNotePatchDto: DeliveryNotePatchDto, po: string, supplierId: string, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).updateDeliveryNoteByPONumberUsingPATCH(deliveryNotePatchDto, po, supplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateDeliveryNoteFiles
     * @param {DeliveryNoteFilesDto} deliveryNoteFilesDto deliveryNoteFilesDto
     * @param {string} deliveryNoteId deliveryNoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public updateDeliveryNoteFilesUsingPOST(deliveryNoteFilesDto: DeliveryNoteFilesDto, deliveryNoteId: string, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).updateDeliveryNoteFilesUsingPOST(deliveryNoteFilesDto, deliveryNoteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateDeliveryNote
     * @param {string} deliveryNoteId deliveryNoteId
     * @param {DeliveryNoteUpdateDto} deliveryNoteUpdateDto deliveryNoteUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDeliveryNoteControllerApi
     */
    public updateDeliveryNoteUsingPUT(deliveryNoteId: string, deliveryNoteUpdateDto: DeliveryNoteUpdateDto, options?: any) {
        return GatewayDeliveryNoteControllerApiFp(this.configuration).updateDeliveryNoteUsingPUT(deliveryNoteId, deliveryNoteUpdateDto, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayDishControllerApi - fetch parameter creator
 * @export
 */
export const GatewayDishControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createDish
         * @param {Dish} dish dish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDishUsingPOST(dish: Dish, options: any = {}): FetchArgs {
            // verify required parameter 'dish' is not null or undefined
            if (dish === null || dish === undefined) {
                throw new RequiredError('dish','Required parameter dish was null or undefined when calling createDishUsingPOST.');
            }
            const localVarPath = `/v1/dishes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Dish" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dish || {}) : (dish || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteDish
         * @param {string} dishId dishId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDishUsingDELETE(dishId: string, options: any = {}): FetchArgs {
            // verify required parameter 'dishId' is not null or undefined
            if (dishId === null || dishId === undefined) {
                throw new RequiredError('dishId','Required parameter dishId was null or undefined when calling deleteDishUsingDELETE.');
            }
            const localVarPath = `/v1/dishes/{dishId}`
                .replace(`{${"dishId"}}`, encodeURIComponent(String(dishId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportDishesAsXlsx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDishesAsXlsxUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/dishes/export-as-xlsx`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportDishesWithDeletedItemsAsXlsx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDishesWithDeletedItemsAsXlsxUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/dishes/deleted-ingredients/export-as-xlsx`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDishById
         * @param {string} dishId dishId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDishByIdUsingGET(dishId: string, options: any = {}): FetchArgs {
            // verify required parameter 'dishId' is not null or undefined
            if (dishId === null || dishId === undefined) {
                throw new RequiredError('dishId','Required parameter dishId was null or undefined when calling getDishByIdUsingGET.');
            }
            const localVarPath = `/v1/dishes/{dishId}`
                .replace(`{${"dishId"}}`, encodeURIComponent(String(dishId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDishesByCriteria
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialDishName] partialDishName
         * @param {string} [partialIngredientName] partialIngredientName
         * @param {string} [partialPosId] partialPosId
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'DRAFT' | 'PUBLISHED'} [status] status
         * @param {boolean} [withoutFolder] withoutFolder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDishesByCriteriaUsingGET(category?: string, page?: number, partialDishName?: string, partialIngredientName?: string, partialPosId?: string, size?: number, sort?: string, status?: 'DRAFT' | 'PUBLISHED', withoutFolder?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/v1/dishes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialDishName !== undefined) {
                localVarQueryParameter['partialDishName'] = partialDishName;
            }

            if (partialIngredientName !== undefined) {
                localVarQueryParameter['partialIngredientName'] = partialIngredientName;
            }

            if (partialPosId !== undefined) {
                localVarQueryParameter['partialPosId'] = partialPosId;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (withoutFolder !== undefined) {
                localVarQueryParameter['withoutFolder'] = withoutFolder;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDishesByIds
         * @param {DishIdListDto} dishes dishes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDishesByIdsUsingPOST(dishes: DishIdListDto, options: any = {}): FetchArgs {
            // verify required parameter 'dishes' is not null or undefined
            if (dishes === null || dishes === undefined) {
                throw new RequiredError('dishes','Required parameter dishes was null or undefined when calling getDishesByIdsUsingPOST.');
            }
            const localVarPath = `/v1/dishes/get-by-ids`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"DishIdListDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dishes || {}) : (dishes || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary importDishesFromExcel
         * @param {string} dishList dish-list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importDishesFromExcelUsingPOST(dishList: string, options: any = {}): FetchArgs {
            // verify required parameter 'dishList' is not null or undefined
            if (dishList === null || dishList === undefined) {
                throw new RequiredError('dishList','Required parameter dishList was null or undefined when calling importDishesFromExcelUsingPOST.');
            }
            const localVarPath = `/v1/dishes/import`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dishList || {}) : (dishList || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDishFeaturedFile
         * @param {string} dishId dishId
         * @param {GrowyzeFile} [featuredFile] featuredFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDishFeaturedFileUsingPOST(dishId: string, featuredFile?: GrowyzeFile, options: any = {}): FetchArgs {
            // verify required parameter 'dishId' is not null or undefined
            if (dishId === null || dishId === undefined) {
                throw new RequiredError('dishId','Required parameter dishId was null or undefined when calling updateDishFeaturedFileUsingPOST.');
            }
            const localVarPath = `/v1/dishes/{dishId}/featured-file`
                .replace(`{${"dishId"}}`, encodeURIComponent(String(dishId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GrowyzeFile" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(featuredFile || {}) : (featuredFile || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDishFiles
         * @param {string} dishId dishId
         * @param {FilesDto} filesDto filesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDishFilesUsingPOST(dishId: string, filesDto: FilesDto, options: any = {}): FetchArgs {
            // verify required parameter 'dishId' is not null or undefined
            if (dishId === null || dishId === undefined) {
                throw new RequiredError('dishId','Required parameter dishId was null or undefined when calling updateDishFilesUsingPOST.');
            }
            // verify required parameter 'filesDto' is not null or undefined
            if (filesDto === null || filesDto === undefined) {
                throw new RequiredError('filesDto','Required parameter filesDto was null or undefined when calling updateDishFilesUsingPOST.');
            }
            const localVarPath = `/v1/dishes/{dishId}/files`
                .replace(`{${"dishId"}}`, encodeURIComponent(String(dishId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FilesDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filesDto || {}) : (filesDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDish
         * @param {Dish} dish dish
         * @param {string} dishId dishId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDishUsingPUT(dish: Dish, dishId: string, options: any = {}): FetchArgs {
            // verify required parameter 'dish' is not null or undefined
            if (dish === null || dish === undefined) {
                throw new RequiredError('dish','Required parameter dish was null or undefined when calling updateDishUsingPUT.');
            }
            // verify required parameter 'dishId' is not null or undefined
            if (dishId === null || dishId === undefined) {
                throw new RequiredError('dishId','Required parameter dishId was null or undefined when calling updateDishUsingPUT.');
            }
            const localVarPath = `/v1/dishes/{dishId}`
                .replace(`{${"dishId"}}`, encodeURIComponent(String(dishId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Dish" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dish || {}) : (dish || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayDishControllerApi - functional programming interface
 * @export
 */
export const GatewayDishControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createDish
         * @param {Dish} dish dish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDishUsingPOST(dish: Dish, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Dish> {
            const localVarFetchArgs = GatewayDishControllerApiFetchParamCreator(configuration).createDishUsingPOST(dish, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteDish
         * @param {string} dishId dishId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDishUsingDELETE(dishId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayDishControllerApiFetchParamCreator(configuration).deleteDishUsingDELETE(dishId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportDishesAsXlsx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDishesAsXlsxUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayDishControllerApiFetchParamCreator(configuration).exportDishesAsXlsxUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportDishesWithDeletedItemsAsXlsx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDishesWithDeletedItemsAsXlsxUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayDishControllerApiFetchParamCreator(configuration).exportDishesWithDeletedItemsAsXlsxUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDishById
         * @param {string} dishId dishId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDishByIdUsingGET(dishId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Dish> {
            const localVarFetchArgs = GatewayDishControllerApiFetchParamCreator(configuration).getDishByIdUsingGET(dishId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDishesByCriteria
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialDishName] partialDishName
         * @param {string} [partialIngredientName] partialIngredientName
         * @param {string} [partialPosId] partialPosId
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'DRAFT' | 'PUBLISHED'} [status] status
         * @param {boolean} [withoutFolder] withoutFolder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDishesByCriteriaUsingGET(category?: string, page?: number, partialDishName?: string, partialIngredientName?: string, partialPosId?: string, size?: number, sort?: string, status?: 'DRAFT' | 'PUBLISHED', withoutFolder?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageDish> {
            const localVarFetchArgs = GatewayDishControllerApiFetchParamCreator(configuration).getDishesByCriteriaUsingGET(category, page, partialDishName, partialIngredientName, partialPosId, size, sort, status, withoutFolder, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDishesByIds
         * @param {DishIdListDto} dishes dishes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDishesByIdsUsingPOST(dishes: DishIdListDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Dish>> {
            const localVarFetchArgs = GatewayDishControllerApiFetchParamCreator(configuration).getDishesByIdsUsingPOST(dishes, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary importDishesFromExcel
         * @param {string} dishList dish-list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importDishesFromExcelUsingPOST(dishList: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayDishControllerApiFetchParamCreator(configuration).importDishesFromExcelUsingPOST(dishList, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateDishFeaturedFile
         * @param {string} dishId dishId
         * @param {GrowyzeFile} [featuredFile] featuredFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDishFeaturedFileUsingPOST(dishId: string, featuredFile?: GrowyzeFile, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Dish> {
            const localVarFetchArgs = GatewayDishControllerApiFetchParamCreator(configuration).updateDishFeaturedFileUsingPOST(dishId, featuredFile, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateDishFiles
         * @param {string} dishId dishId
         * @param {FilesDto} filesDto filesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDishFilesUsingPOST(dishId: string, filesDto: FilesDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Dish> {
            const localVarFetchArgs = GatewayDishControllerApiFetchParamCreator(configuration).updateDishFilesUsingPOST(dishId, filesDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateDish
         * @param {Dish} dish dish
         * @param {string} dishId dishId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDishUsingPUT(dish: Dish, dishId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Dish> {
            const localVarFetchArgs = GatewayDishControllerApiFetchParamCreator(configuration).updateDishUsingPUT(dish, dishId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayDishControllerApi - factory interface
 * @export
 */
export const GatewayDishControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createDish
         * @param {Dish} dish dish
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDishUsingPOST(dish: Dish, options?: any) {
            return GatewayDishControllerApiFp(configuration).createDishUsingPOST(dish, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteDish
         * @param {string} dishId dishId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDishUsingDELETE(dishId: string, options?: any) {
            return GatewayDishControllerApiFp(configuration).deleteDishUsingDELETE(dishId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportDishesAsXlsx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDishesAsXlsxUsingGET(options?: any) {
            return GatewayDishControllerApiFp(configuration).exportDishesAsXlsxUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportDishesWithDeletedItemsAsXlsx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDishesWithDeletedItemsAsXlsxUsingGET(options?: any) {
            return GatewayDishControllerApiFp(configuration).exportDishesWithDeletedItemsAsXlsxUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getDishById
         * @param {string} dishId dishId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDishByIdUsingGET(dishId: string, options?: any) {
            return GatewayDishControllerApiFp(configuration).getDishByIdUsingGET(dishId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getDishesByCriteria
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialDishName] partialDishName
         * @param {string} [partialIngredientName] partialIngredientName
         * @param {string} [partialPosId] partialPosId
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'DRAFT' | 'PUBLISHED'} [status] status
         * @param {boolean} [withoutFolder] withoutFolder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDishesByCriteriaUsingGET(category?: string, page?: number, partialDishName?: string, partialIngredientName?: string, partialPosId?: string, size?: number, sort?: string, status?: 'DRAFT' | 'PUBLISHED', withoutFolder?: boolean, options?: any) {
            return GatewayDishControllerApiFp(configuration).getDishesByCriteriaUsingGET(category, page, partialDishName, partialIngredientName, partialPosId, size, sort, status, withoutFolder, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getDishesByIds
         * @param {DishIdListDto} dishes dishes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDishesByIdsUsingPOST(dishes: DishIdListDto, options?: any) {
            return GatewayDishControllerApiFp(configuration).getDishesByIdsUsingPOST(dishes, options)(fetch, basePath);
        },
        /**
         * 
         * @summary importDishesFromExcel
         * @param {string} dishList dish-list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importDishesFromExcelUsingPOST(dishList: string, options?: any) {
            return GatewayDishControllerApiFp(configuration).importDishesFromExcelUsingPOST(dishList, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateDishFeaturedFile
         * @param {string} dishId dishId
         * @param {GrowyzeFile} [featuredFile] featuredFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDishFeaturedFileUsingPOST(dishId: string, featuredFile?: GrowyzeFile, options?: any) {
            return GatewayDishControllerApiFp(configuration).updateDishFeaturedFileUsingPOST(dishId, featuredFile, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateDishFiles
         * @param {string} dishId dishId
         * @param {FilesDto} filesDto filesDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDishFilesUsingPOST(dishId: string, filesDto: FilesDto, options?: any) {
            return GatewayDishControllerApiFp(configuration).updateDishFilesUsingPOST(dishId, filesDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateDish
         * @param {Dish} dish dish
         * @param {string} dishId dishId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDishUsingPUT(dish: Dish, dishId: string, options?: any) {
            return GatewayDishControllerApiFp(configuration).updateDishUsingPUT(dish, dishId, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayDishControllerApi - object-oriented interface
 * @export
 * @class GatewayDishControllerApi
 * @extends {BaseAPI}
 */
export class GatewayDishControllerApi extends BaseAPI {
    /**
     * 
     * @summary createDish
     * @param {Dish} dish dish
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDishControllerApi
     */
    public createDishUsingPOST(dish: Dish, options?: any) {
        return GatewayDishControllerApiFp(this.configuration).createDishUsingPOST(dish, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteDish
     * @param {string} dishId dishId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDishControllerApi
     */
    public deleteDishUsingDELETE(dishId: string, options?: any) {
        return GatewayDishControllerApiFp(this.configuration).deleteDishUsingDELETE(dishId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportDishesAsXlsx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDishControllerApi
     */
    public exportDishesAsXlsxUsingGET(options?: any) {
        return GatewayDishControllerApiFp(this.configuration).exportDishesAsXlsxUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportDishesWithDeletedItemsAsXlsx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDishControllerApi
     */
    public exportDishesWithDeletedItemsAsXlsxUsingGET(options?: any) {
        return GatewayDishControllerApiFp(this.configuration).exportDishesWithDeletedItemsAsXlsxUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getDishById
     * @param {string} dishId dishId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDishControllerApi
     */
    public getDishByIdUsingGET(dishId: string, options?: any) {
        return GatewayDishControllerApiFp(this.configuration).getDishByIdUsingGET(dishId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getDishesByCriteria
     * @param {string} [category] category
     * @param {number} [page] 
     * @param {string} [partialDishName] partialDishName
     * @param {string} [partialIngredientName] partialIngredientName
     * @param {string} [partialPosId] partialPosId
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {'DRAFT' | 'PUBLISHED'} [status] status
     * @param {boolean} [withoutFolder] withoutFolder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDishControllerApi
     */
    public getDishesByCriteriaUsingGET(category?: string, page?: number, partialDishName?: string, partialIngredientName?: string, partialPosId?: string, size?: number, sort?: string, status?: 'DRAFT' | 'PUBLISHED', withoutFolder?: boolean, options?: any) {
        return GatewayDishControllerApiFp(this.configuration).getDishesByCriteriaUsingGET(category, page, partialDishName, partialIngredientName, partialPosId, size, sort, status, withoutFolder, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getDishesByIds
     * @param {DishIdListDto} dishes dishes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDishControllerApi
     */
    public getDishesByIdsUsingPOST(dishes: DishIdListDto, options?: any) {
        return GatewayDishControllerApiFp(this.configuration).getDishesByIdsUsingPOST(dishes, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary importDishesFromExcel
     * @param {string} dishList dish-list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDishControllerApi
     */
    public importDishesFromExcelUsingPOST(dishList: string, options?: any) {
        return GatewayDishControllerApiFp(this.configuration).importDishesFromExcelUsingPOST(dishList, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateDishFeaturedFile
     * @param {string} dishId dishId
     * @param {GrowyzeFile} [featuredFile] featuredFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDishControllerApi
     */
    public updateDishFeaturedFileUsingPOST(dishId: string, featuredFile?: GrowyzeFile, options?: any) {
        return GatewayDishControllerApiFp(this.configuration).updateDishFeaturedFileUsingPOST(dishId, featuredFile, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateDishFiles
     * @param {string} dishId dishId
     * @param {FilesDto} filesDto filesDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDishControllerApi
     */
    public updateDishFilesUsingPOST(dishId: string, filesDto: FilesDto, options?: any) {
        return GatewayDishControllerApiFp(this.configuration).updateDishFilesUsingPOST(dishId, filesDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateDish
     * @param {Dish} dish dish
     * @param {string} dishId dishId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayDishControllerApi
     */
    public updateDishUsingPUT(dish: Dish, dishId: string, options?: any) {
        return GatewayDishControllerApiFp(this.configuration).updateDishUsingPUT(dish, dishId, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayEventControllerApi - fetch parameter creator
 * @export
 */
export const GatewayEventControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteEventById
         * @param {string} eventId eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventByIdUsingDELETE(eventId: string, options: any = {}): FetchArgs {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling deleteEventByIdUsingDELETE.');
            }
            const localVarPath = `/v1/events/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteEvents
         * @param {EventIdListDto} eventsIds eventsIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventsUsingDELETE(eventsIds: EventIdListDto, options: any = {}): FetchArgs {
            // verify required parameter 'eventsIds' is not null or undefined
            if (eventsIds === null || eventsIds === undefined) {
                throw new RequiredError('eventsIds','Required parameter eventsIds was null or undefined when calling deleteEventsUsingDELETE.');
            }
            const localVarPath = `/v1/events/multiple`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EventIdListDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(eventsIds || {}) : (eventsIds || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getEventById
         * @param {string} eventId eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventByIdUsingGET(eventId: string, options: any = {}): FetchArgs {
            // verify required parameter 'eventId' is not null or undefined
            if (eventId === null || eventId === undefined) {
                throw new RequiredError('eventId','Required parameter eventId was null or undefined when calling getEventByIdUsingGET.');
            }
            const localVarPath = `/v1/events/{eventId}`
                .replace(`{${"eventId"}}`, encodeURIComponent(String(eventId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getEventsByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'PRODUCT' | 'RECIPE' | 'SUPPLIER'} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsByCriteriaUsingGET(page?: number, size?: number, sort?: string, type?: 'PRODUCT' | 'RECIPE' | 'SUPPLIER', options: any = {}): FetchArgs {
            const localVarPath = `/v1/events`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getEventsByReferencesIds
         * @param {ReferenceIdListDto} references references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsByReferencesIdsUsingPOST(references: ReferenceIdListDto, options: any = {}): FetchArgs {
            // verify required parameter 'references' is not null or undefined
            if (references === null || references === undefined) {
                throw new RequiredError('references','Required parameter references was null or undefined when calling getEventsByReferencesIdsUsingPOST.');
            }
            const localVarPath = `/v1/events/get-multiple`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReferenceIdListDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(references || {}) : (references || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateEvents
         * @param {EventListDto} eventListDto eventListDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventsUsingPUT(eventListDto: EventListDto, options: any = {}): FetchArgs {
            // verify required parameter 'eventListDto' is not null or undefined
            if (eventListDto === null || eventListDto === undefined) {
                throw new RequiredError('eventListDto','Required parameter eventListDto was null or undefined when calling updateEventsUsingPUT.');
            }
            const localVarPath = `/v1/events/multiple`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"EventListDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(eventListDto || {}) : (eventListDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayEventControllerApi - functional programming interface
 * @export
 */
export const GatewayEventControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteEventById
         * @param {string} eventId eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventByIdUsingDELETE(eventId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayEventControllerApiFetchParamCreator(configuration).deleteEventByIdUsingDELETE(eventId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteEvents
         * @param {EventIdListDto} eventsIds eventsIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventsUsingDELETE(eventsIds: EventIdListDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayEventControllerApiFetchParamCreator(configuration).deleteEventsUsingDELETE(eventsIds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getEventById
         * @param {string} eventId eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventByIdUsingGET(eventId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Event> {
            const localVarFetchArgs = GatewayEventControllerApiFetchParamCreator(configuration).getEventByIdUsingGET(eventId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getEventsByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'PRODUCT' | 'RECIPE' | 'SUPPLIER'} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsByCriteriaUsingGET(page?: number, size?: number, sort?: string, type?: 'PRODUCT' | 'RECIPE' | 'SUPPLIER', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageEvent> {
            const localVarFetchArgs = GatewayEventControllerApiFetchParamCreator(configuration).getEventsByCriteriaUsingGET(page, size, sort, type, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getEventsByReferencesIds
         * @param {ReferenceIdListDto} references references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsByReferencesIdsUsingPOST(references: ReferenceIdListDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Event>> {
            const localVarFetchArgs = GatewayEventControllerApiFetchParamCreator(configuration).getEventsByReferencesIdsUsingPOST(references, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateEvents
         * @param {EventListDto} eventListDto eventListDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventsUsingPUT(eventListDto: EventListDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayEventControllerApiFetchParamCreator(configuration).updateEventsUsingPUT(eventListDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayEventControllerApi - factory interface
 * @export
 */
export const GatewayEventControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteEventById
         * @param {string} eventId eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventByIdUsingDELETE(eventId: string, options?: any) {
            return GatewayEventControllerApiFp(configuration).deleteEventByIdUsingDELETE(eventId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteEvents
         * @param {EventIdListDto} eventsIds eventsIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEventsUsingDELETE(eventsIds: EventIdListDto, options?: any) {
            return GatewayEventControllerApiFp(configuration).deleteEventsUsingDELETE(eventsIds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getEventById
         * @param {string} eventId eventId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventByIdUsingGET(eventId: string, options?: any) {
            return GatewayEventControllerApiFp(configuration).getEventByIdUsingGET(eventId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getEventsByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'PRODUCT' | 'RECIPE' | 'SUPPLIER'} [type] type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsByCriteriaUsingGET(page?: number, size?: number, sort?: string, type?: 'PRODUCT' | 'RECIPE' | 'SUPPLIER', options?: any) {
            return GatewayEventControllerApiFp(configuration).getEventsByCriteriaUsingGET(page, size, sort, type, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getEventsByReferencesIds
         * @param {ReferenceIdListDto} references references
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEventsByReferencesIdsUsingPOST(references: ReferenceIdListDto, options?: any) {
            return GatewayEventControllerApiFp(configuration).getEventsByReferencesIdsUsingPOST(references, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateEvents
         * @param {EventListDto} eventListDto eventListDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEventsUsingPUT(eventListDto: EventListDto, options?: any) {
            return GatewayEventControllerApiFp(configuration).updateEventsUsingPUT(eventListDto, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayEventControllerApi - object-oriented interface
 * @export
 * @class GatewayEventControllerApi
 * @extends {BaseAPI}
 */
export class GatewayEventControllerApi extends BaseAPI {
    /**
     * 
     * @summary deleteEventById
     * @param {string} eventId eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayEventControllerApi
     */
    public deleteEventByIdUsingDELETE(eventId: string, options?: any) {
        return GatewayEventControllerApiFp(this.configuration).deleteEventByIdUsingDELETE(eventId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteEvents
     * @param {EventIdListDto} eventsIds eventsIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayEventControllerApi
     */
    public deleteEventsUsingDELETE(eventsIds: EventIdListDto, options?: any) {
        return GatewayEventControllerApiFp(this.configuration).deleteEventsUsingDELETE(eventsIds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getEventById
     * @param {string} eventId eventId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayEventControllerApi
     */
    public getEventByIdUsingGET(eventId: string, options?: any) {
        return GatewayEventControllerApiFp(this.configuration).getEventByIdUsingGET(eventId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getEventsByCriteria
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {'PRODUCT' | 'RECIPE' | 'SUPPLIER'} [type] type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayEventControllerApi
     */
    public getEventsByCriteriaUsingGET(page?: number, size?: number, sort?: string, type?: 'PRODUCT' | 'RECIPE' | 'SUPPLIER', options?: any) {
        return GatewayEventControllerApiFp(this.configuration).getEventsByCriteriaUsingGET(page, size, sort, type, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getEventsByReferencesIds
     * @param {ReferenceIdListDto} references references
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayEventControllerApi
     */
    public getEventsByReferencesIdsUsingPOST(references: ReferenceIdListDto, options?: any) {
        return GatewayEventControllerApiFp(this.configuration).getEventsByReferencesIdsUsingPOST(references, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateEvents
     * @param {EventListDto} eventListDto eventListDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayEventControllerApi
     */
    public updateEventsUsingPUT(eventListDto: EventListDto, options?: any) {
        return GatewayEventControllerApiFp(this.configuration).updateEventsUsingPUT(eventListDto, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayFolderControllerApi - fetch parameter creator
 * @export
 */
export const GatewayFolderControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createRootFolder
         * @param {FolderPayload} folderPayload folderPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRootFolderUsingPOST(folderPayload: FolderPayload, options: any = {}): FetchArgs {
            // verify required parameter 'folderPayload' is not null or undefined
            if (folderPayload === null || folderPayload === undefined) {
                throw new RequiredError('folderPayload','Required parameter folderPayload was null or undefined when calling createRootFolderUsingPOST.');
            }
            const localVarPath = `/v1/folders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FolderPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(folderPayload || {}) : (folderPayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createSubFolder
         * @param {string} folderId folderId
         * @param {SubFolderPayload} folderPayload folderPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubFolderUsingPOST(folderId: string, folderPayload: SubFolderPayload, options: any = {}): FetchArgs {
            // verify required parameter 'folderId' is not null or undefined
            if (folderId === null || folderId === undefined) {
                throw new RequiredError('folderId','Required parameter folderId was null or undefined when calling createSubFolderUsingPOST.');
            }
            // verify required parameter 'folderPayload' is not null or undefined
            if (folderPayload === null || folderPayload === undefined) {
                throw new RequiredError('folderPayload','Required parameter folderPayload was null or undefined when calling createSubFolderUsingPOST.');
            }
            const localVarPath = `/v1/folders/{folderId}/sub-folder`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SubFolderPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(folderPayload || {}) : (folderPayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteFolderById
         * @param {string} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolderByIdUsingDELETE(folderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'folderId' is not null or undefined
            if (folderId === null || folderId === undefined) {
                throw new RequiredError('folderId','Required parameter folderId was null or undefined when calling deleteFolderByIdUsingDELETE.');
            }
            const localVarPath = `/v1/folders/{folderId}`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getFolderById
         * @param {string} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderByIdUsingGET(folderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'folderId' is not null or undefined
            if (folderId === null || folderId === undefined) {
                throw new RequiredError('folderId','Required parameter folderId was null or undefined when calling getFolderByIdUsingGET.');
            }
            const localVarPath = `/v1/folders/{folderId}`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRootFoldersByCriteria
         * @param {'DISH' | 'MENU' | 'RECIPE'} contentType contentType
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootFoldersByCriteriaUsingGET(contentType: 'DISH' | 'MENU' | 'RECIPE', page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            // verify required parameter 'contentType' is not null or undefined
            if (contentType === null || contentType === undefined) {
                throw new RequiredError('contentType','Required parameter contentType was null or undefined when calling getRootFoldersByCriteriaUsingGET.');
            }
            const localVarPath = `/v1/folders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (contentType !== undefined) {
                localVarQueryParameter['contentType'] = contentType;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateFolder
         * @param {string} folderId folderId
         * @param {SubFolderPayload} folderPayload folderPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolderUsingPUT(folderId: string, folderPayload: SubFolderPayload, options: any = {}): FetchArgs {
            // verify required parameter 'folderId' is not null or undefined
            if (folderId === null || folderId === undefined) {
                throw new RequiredError('folderId','Required parameter folderId was null or undefined when calling updateFolderUsingPUT.');
            }
            // verify required parameter 'folderPayload' is not null or undefined
            if (folderPayload === null || folderPayload === undefined) {
                throw new RequiredError('folderPayload','Required parameter folderPayload was null or undefined when calling updateFolderUsingPUT.');
            }
            const localVarPath = `/v1/folders/{folderId}`
                .replace(`{${"folderId"}}`, encodeURIComponent(String(folderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SubFolderPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(folderPayload || {}) : (folderPayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayFolderControllerApi - functional programming interface
 * @export
 */
export const GatewayFolderControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createRootFolder
         * @param {FolderPayload} folderPayload folderPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRootFolderUsingPOST(folderPayload: FolderPayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Folder> {
            const localVarFetchArgs = GatewayFolderControllerApiFetchParamCreator(configuration).createRootFolderUsingPOST(folderPayload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createSubFolder
         * @param {string} folderId folderId
         * @param {SubFolderPayload} folderPayload folderPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubFolderUsingPOST(folderId: string, folderPayload: SubFolderPayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Folder> {
            const localVarFetchArgs = GatewayFolderControllerApiFetchParamCreator(configuration).createSubFolderUsingPOST(folderId, folderPayload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteFolderById
         * @param {string} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolderByIdUsingDELETE(folderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayFolderControllerApiFetchParamCreator(configuration).deleteFolderByIdUsingDELETE(folderId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getFolderById
         * @param {string} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderByIdUsingGET(folderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Folder> {
            const localVarFetchArgs = GatewayFolderControllerApiFetchParamCreator(configuration).getFolderByIdUsingGET(folderId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getRootFoldersByCriteria
         * @param {'DISH' | 'MENU' | 'RECIPE'} contentType contentType
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootFoldersByCriteriaUsingGET(contentType: 'DISH' | 'MENU' | 'RECIPE', page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageFolder> {
            const localVarFetchArgs = GatewayFolderControllerApiFetchParamCreator(configuration).getRootFoldersByCriteriaUsingGET(contentType, page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateFolder
         * @param {string} folderId folderId
         * @param {SubFolderPayload} folderPayload folderPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolderUsingPUT(folderId: string, folderPayload: SubFolderPayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Folder> {
            const localVarFetchArgs = GatewayFolderControllerApiFetchParamCreator(configuration).updateFolderUsingPUT(folderId, folderPayload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayFolderControllerApi - factory interface
 * @export
 */
export const GatewayFolderControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createRootFolder
         * @param {FolderPayload} folderPayload folderPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRootFolderUsingPOST(folderPayload: FolderPayload, options?: any) {
            return GatewayFolderControllerApiFp(configuration).createRootFolderUsingPOST(folderPayload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createSubFolder
         * @param {string} folderId folderId
         * @param {SubFolderPayload} folderPayload folderPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubFolderUsingPOST(folderId: string, folderPayload: SubFolderPayload, options?: any) {
            return GatewayFolderControllerApiFp(configuration).createSubFolderUsingPOST(folderId, folderPayload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteFolderById
         * @param {string} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolderByIdUsingDELETE(folderId: string, options?: any) {
            return GatewayFolderControllerApiFp(configuration).deleteFolderByIdUsingDELETE(folderId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getFolderById
         * @param {string} folderId folderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFolderByIdUsingGET(folderId: string, options?: any) {
            return GatewayFolderControllerApiFp(configuration).getFolderByIdUsingGET(folderId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getRootFoldersByCriteria
         * @param {'DISH' | 'MENU' | 'RECIPE'} contentType contentType
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRootFoldersByCriteriaUsingGET(contentType: 'DISH' | 'MENU' | 'RECIPE', page?: number, size?: number, sort?: string, options?: any) {
            return GatewayFolderControllerApiFp(configuration).getRootFoldersByCriteriaUsingGET(contentType, page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateFolder
         * @param {string} folderId folderId
         * @param {SubFolderPayload} folderPayload folderPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateFolderUsingPUT(folderId: string, folderPayload: SubFolderPayload, options?: any) {
            return GatewayFolderControllerApiFp(configuration).updateFolderUsingPUT(folderId, folderPayload, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayFolderControllerApi - object-oriented interface
 * @export
 * @class GatewayFolderControllerApi
 * @extends {BaseAPI}
 */
export class GatewayFolderControllerApi extends BaseAPI {
    /**
     * 
     * @summary createRootFolder
     * @param {FolderPayload} folderPayload folderPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayFolderControllerApi
     */
    public createRootFolderUsingPOST(folderPayload: FolderPayload, options?: any) {
        return GatewayFolderControllerApiFp(this.configuration).createRootFolderUsingPOST(folderPayload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createSubFolder
     * @param {string} folderId folderId
     * @param {SubFolderPayload} folderPayload folderPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayFolderControllerApi
     */
    public createSubFolderUsingPOST(folderId: string, folderPayload: SubFolderPayload, options?: any) {
        return GatewayFolderControllerApiFp(this.configuration).createSubFolderUsingPOST(folderId, folderPayload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteFolderById
     * @param {string} folderId folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayFolderControllerApi
     */
    public deleteFolderByIdUsingDELETE(folderId: string, options?: any) {
        return GatewayFolderControllerApiFp(this.configuration).deleteFolderByIdUsingDELETE(folderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getFolderById
     * @param {string} folderId folderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayFolderControllerApi
     */
    public getFolderByIdUsingGET(folderId: string, options?: any) {
        return GatewayFolderControllerApiFp(this.configuration).getFolderByIdUsingGET(folderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getRootFoldersByCriteria
     * @param {'DISH' | 'MENU' | 'RECIPE'} contentType contentType
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayFolderControllerApi
     */
    public getRootFoldersByCriteriaUsingGET(contentType: 'DISH' | 'MENU' | 'RECIPE', page?: number, size?: number, sort?: string, options?: any) {
        return GatewayFolderControllerApiFp(this.configuration).getRootFoldersByCriteriaUsingGET(contentType, page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateFolder
     * @param {string} folderId folderId
     * @param {SubFolderPayload} folderPayload folderPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayFolderControllerApi
     */
    public updateFolderUsingPUT(folderId: string, folderPayload: SubFolderPayload, options?: any) {
        return GatewayFolderControllerApiFp(this.configuration).updateFolderUsingPUT(folderId, folderPayload, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayGlobalProductControllerApi - fetch parameter creator
 * @export
 */
export const GatewayGlobalProductControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary exportGlobalProductsToCSV
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportGlobalProductsToCSVUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/global-products/export-as-csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getGlobalProductByBarcode
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalProductByBarcodeUsingGET(barcode: string, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling getGlobalProductByBarcodeUsingGET.');
            }
            const localVarPath = `/v1/global-products/{barcode}`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getGlobalProductsByBarcodes
         * @param {ProductBarcodeListDto} barcodes barcodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalProductsByBarcodesUsingPOST(barcodes: ProductBarcodeListDto, options: any = {}): FetchArgs {
            // verify required parameter 'barcodes' is not null or undefined
            if (barcodes === null || barcodes === undefined) {
                throw new RequiredError('barcodes','Required parameter barcodes was null or undefined when calling getGlobalProductsByBarcodesUsingPOST.');
            }
            const localVarPath = `/v1/global-products`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductBarcodeListDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(barcodes || {}) : (barcodes || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getGlobalProductsByName
         * @param {string} name name
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalProductsByNameUsingGET(name: string, page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            // verify required parameter 'name' is not null or undefined
            if (name === null || name === undefined) {
                throw new RequiredError('name','Required parameter name was null or undefined when calling getGlobalProductsByNameUsingGET.');
            }
            const localVarPath = `/v1/global-products`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary importGlobalProductsFromCSV
         * @param {string} productList product-list
         * @param {boolean} [override] override
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importGlobalProductsFromCSVUsingPOST(productList: string, override?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'productList' is not null or undefined
            if (productList === null || productList === undefined) {
                throw new RequiredError('productList','Required parameter productList was null or undefined when calling importGlobalProductsFromCSVUsingPOST.');
            }
            const localVarPath = `/v1/global-products/import`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (override !== undefined) {
                localVarQueryParameter['override'] = override;
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(productList || {}) : (productList || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayGlobalProductControllerApi - functional programming interface
 * @export
 */
export const GatewayGlobalProductControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary exportGlobalProductsToCSV
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportGlobalProductsToCSVUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayGlobalProductControllerApiFetchParamCreator(configuration).exportGlobalProductsToCSVUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getGlobalProductByBarcode
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalProductByBarcodeUsingGET(barcode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GlobalProduct> {
            const localVarFetchArgs = GatewayGlobalProductControllerApiFetchParamCreator(configuration).getGlobalProductByBarcodeUsingGET(barcode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getGlobalProductsByBarcodes
         * @param {ProductBarcodeListDto} barcodes barcodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalProductsByBarcodesUsingPOST(barcodes: ProductBarcodeListDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GlobalProduct>> {
            const localVarFetchArgs = GatewayGlobalProductControllerApiFetchParamCreator(configuration).getGlobalProductsByBarcodesUsingPOST(barcodes, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getGlobalProductsByName
         * @param {string} name name
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalProductsByNameUsingGET(name: string, page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageGlobalProduct> {
            const localVarFetchArgs = GatewayGlobalProductControllerApiFetchParamCreator(configuration).getGlobalProductsByNameUsingGET(name, page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary importGlobalProductsFromCSV
         * @param {string} productList product-list
         * @param {boolean} [override] override
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importGlobalProductsFromCSVUsingPOST(productList: string, override?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayGlobalProductControllerApiFetchParamCreator(configuration).importGlobalProductsFromCSVUsingPOST(productList, override, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayGlobalProductControllerApi - factory interface
 * @export
 */
export const GatewayGlobalProductControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary exportGlobalProductsToCSV
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportGlobalProductsToCSVUsingGET(options?: any) {
            return GatewayGlobalProductControllerApiFp(configuration).exportGlobalProductsToCSVUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getGlobalProductByBarcode
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalProductByBarcodeUsingGET(barcode: string, options?: any) {
            return GatewayGlobalProductControllerApiFp(configuration).getGlobalProductByBarcodeUsingGET(barcode, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getGlobalProductsByBarcodes
         * @param {ProductBarcodeListDto} barcodes barcodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalProductsByBarcodesUsingPOST(barcodes: ProductBarcodeListDto, options?: any) {
            return GatewayGlobalProductControllerApiFp(configuration).getGlobalProductsByBarcodesUsingPOST(barcodes, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getGlobalProductsByName
         * @param {string} name name
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGlobalProductsByNameUsingGET(name: string, page?: number, size?: number, sort?: string, options?: any) {
            return GatewayGlobalProductControllerApiFp(configuration).getGlobalProductsByNameUsingGET(name, page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary importGlobalProductsFromCSV
         * @param {string} productList product-list
         * @param {boolean} [override] override
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importGlobalProductsFromCSVUsingPOST(productList: string, override?: boolean, options?: any) {
            return GatewayGlobalProductControllerApiFp(configuration).importGlobalProductsFromCSVUsingPOST(productList, override, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayGlobalProductControllerApi - object-oriented interface
 * @export
 * @class GatewayGlobalProductControllerApi
 * @extends {BaseAPI}
 */
export class GatewayGlobalProductControllerApi extends BaseAPI {
    /**
     * 
     * @summary exportGlobalProductsToCSV
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGlobalProductControllerApi
     */
    public exportGlobalProductsToCSVUsingGET(options?: any) {
        return GatewayGlobalProductControllerApiFp(this.configuration).exportGlobalProductsToCSVUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getGlobalProductByBarcode
     * @param {string} barcode barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGlobalProductControllerApi
     */
    public getGlobalProductByBarcodeUsingGET(barcode: string, options?: any) {
        return GatewayGlobalProductControllerApiFp(this.configuration).getGlobalProductByBarcodeUsingGET(barcode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getGlobalProductsByBarcodes
     * @param {ProductBarcodeListDto} barcodes barcodes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGlobalProductControllerApi
     */
    public getGlobalProductsByBarcodesUsingPOST(barcodes: ProductBarcodeListDto, options?: any) {
        return GatewayGlobalProductControllerApiFp(this.configuration).getGlobalProductsByBarcodesUsingPOST(barcodes, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getGlobalProductsByName
     * @param {string} name name
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGlobalProductControllerApi
     */
    public getGlobalProductsByNameUsingGET(name: string, page?: number, size?: number, sort?: string, options?: any) {
        return GatewayGlobalProductControllerApiFp(this.configuration).getGlobalProductsByNameUsingGET(name, page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary importGlobalProductsFromCSV
     * @param {string} productList product-list
     * @param {boolean} [override] override
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGlobalProductControllerApi
     */
    public importGlobalProductsFromCSVUsingPOST(productList: string, override?: boolean, options?: any) {
        return GatewayGlobalProductControllerApiFp(this.configuration).importGlobalProductsFromCSVUsingPOST(productList, override, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayGoEposPosIntegrationControllerApi - fetch parameter creator
 * @export
 */
export const GatewayGoEposPosIntegrationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createGoEposPosIntegration
         * @param {GoEposIntegrationCreatePayload} goEposIntegrationCreatePayload goEposIntegrationCreatePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoEposPosIntegrationUsingPOST(goEposIntegrationCreatePayload: GoEposIntegrationCreatePayload, options: any = {}): FetchArgs {
            // verify required parameter 'goEposIntegrationCreatePayload' is not null or undefined
            if (goEposIntegrationCreatePayload === null || goEposIntegrationCreatePayload === undefined) {
                throw new RequiredError('goEposIntegrationCreatePayload','Required parameter goEposIntegrationCreatePayload was null or undefined when calling createGoEposPosIntegrationUsingPOST.');
            }
            const localVarPath = `/v1/goepos-pos-integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GoEposIntegrationCreatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(goEposIntegrationCreatePayload || {}) : (goEposIntegrationCreatePayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteGoEposPosIntegrationById
         * @param {string} goEposPosIntegrationId goEposPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGoEposPosIntegrationByIdUsingDELETE(goEposPosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'goEposPosIntegrationId' is not null or undefined
            if (goEposPosIntegrationId === null || goEposPosIntegrationId === undefined) {
                throw new RequiredError('goEposPosIntegrationId','Required parameter goEposPosIntegrationId was null or undefined when calling deleteGoEposPosIntegrationByIdUsingDELETE.');
            }
            const localVarPath = `/v1/goepos-pos-integrations/{goEposPosIntegrationId}`
                .replace(`{${"goEposPosIntegrationId"}}`, encodeURIComponent(String(goEposPosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getGoEposPosIntegrationById
         * @param {string} goEposPosIntegrationId goEposPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoEposPosIntegrationByIdUsingGET(goEposPosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'goEposPosIntegrationId' is not null or undefined
            if (goEposPosIntegrationId === null || goEposPosIntegrationId === undefined) {
                throw new RequiredError('goEposPosIntegrationId','Required parameter goEposPosIntegrationId was null or undefined when calling getGoEposPosIntegrationByIdUsingGET.');
            }
            const localVarPath = `/v1/goepos-pos-integrations/{goEposPosIntegrationId}`
                .replace(`{${"goEposPosIntegrationId"}}`, encodeURIComponent(String(goEposPosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getGoEposPosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoEposPosIntegrationsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/goepos-pos-integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateGoEposPosIntegrationById
         * @param {string} goEposPosIntegrationId goEposPosIntegrationId
         * @param {PosIntegrationUpdatePayload} posIntegrationUpdatePayload posIntegrationUpdatePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGoEposPosIntegrationByIdUsingPATCH(goEposPosIntegrationId: string, posIntegrationUpdatePayload: PosIntegrationUpdatePayload, options: any = {}): FetchArgs {
            // verify required parameter 'goEposPosIntegrationId' is not null or undefined
            if (goEposPosIntegrationId === null || goEposPosIntegrationId === undefined) {
                throw new RequiredError('goEposPosIntegrationId','Required parameter goEposPosIntegrationId was null or undefined when calling updateGoEposPosIntegrationByIdUsingPATCH.');
            }
            // verify required parameter 'posIntegrationUpdatePayload' is not null or undefined
            if (posIntegrationUpdatePayload === null || posIntegrationUpdatePayload === undefined) {
                throw new RequiredError('posIntegrationUpdatePayload','Required parameter posIntegrationUpdatePayload was null or undefined when calling updateGoEposPosIntegrationByIdUsingPATCH.');
            }
            const localVarPath = `/v1/goepos-pos-integrations/{goEposPosIntegrationId}`
                .replace(`{${"goEposPosIntegrationId"}}`, encodeURIComponent(String(goEposPosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PosIntegrationUpdatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(posIntegrationUpdatePayload || {}) : (posIntegrationUpdatePayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayGoEposPosIntegrationControllerApi - functional programming interface
 * @export
 */
export const GatewayGoEposPosIntegrationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createGoEposPosIntegration
         * @param {GoEposIntegrationCreatePayload} goEposIntegrationCreatePayload goEposIntegrationCreatePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoEposPosIntegrationUsingPOST(goEposIntegrationCreatePayload: GoEposIntegrationCreatePayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoEposPosIntegration> {
            const localVarFetchArgs = GatewayGoEposPosIntegrationControllerApiFetchParamCreator(configuration).createGoEposPosIntegrationUsingPOST(goEposIntegrationCreatePayload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteGoEposPosIntegrationById
         * @param {string} goEposPosIntegrationId goEposPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGoEposPosIntegrationByIdUsingDELETE(goEposPosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayGoEposPosIntegrationControllerApiFetchParamCreator(configuration).deleteGoEposPosIntegrationByIdUsingDELETE(goEposPosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getGoEposPosIntegrationById
         * @param {string} goEposPosIntegrationId goEposPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoEposPosIntegrationByIdUsingGET(goEposPosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoEposPosIntegration> {
            const localVarFetchArgs = GatewayGoEposPosIntegrationControllerApiFetchParamCreator(configuration).getGoEposPosIntegrationByIdUsingGET(goEposPosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getGoEposPosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoEposPosIntegrationsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<GoEposPosIntegration>> {
            const localVarFetchArgs = GatewayGoEposPosIntegrationControllerApiFetchParamCreator(configuration).getGoEposPosIntegrationsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateGoEposPosIntegrationById
         * @param {string} goEposPosIntegrationId goEposPosIntegrationId
         * @param {PosIntegrationUpdatePayload} posIntegrationUpdatePayload posIntegrationUpdatePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGoEposPosIntegrationByIdUsingPATCH(goEposPosIntegrationId: string, posIntegrationUpdatePayload: PosIntegrationUpdatePayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoEposPosIntegration> {
            const localVarFetchArgs = GatewayGoEposPosIntegrationControllerApiFetchParamCreator(configuration).updateGoEposPosIntegrationByIdUsingPATCH(goEposPosIntegrationId, posIntegrationUpdatePayload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayGoEposPosIntegrationControllerApi - factory interface
 * @export
 */
export const GatewayGoEposPosIntegrationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createGoEposPosIntegration
         * @param {GoEposIntegrationCreatePayload} goEposIntegrationCreatePayload goEposIntegrationCreatePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGoEposPosIntegrationUsingPOST(goEposIntegrationCreatePayload: GoEposIntegrationCreatePayload, options?: any) {
            return GatewayGoEposPosIntegrationControllerApiFp(configuration).createGoEposPosIntegrationUsingPOST(goEposIntegrationCreatePayload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteGoEposPosIntegrationById
         * @param {string} goEposPosIntegrationId goEposPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGoEposPosIntegrationByIdUsingDELETE(goEposPosIntegrationId: string, options?: any) {
            return GatewayGoEposPosIntegrationControllerApiFp(configuration).deleteGoEposPosIntegrationByIdUsingDELETE(goEposPosIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getGoEposPosIntegrationById
         * @param {string} goEposPosIntegrationId goEposPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoEposPosIntegrationByIdUsingGET(goEposPosIntegrationId: string, options?: any) {
            return GatewayGoEposPosIntegrationControllerApiFp(configuration).getGoEposPosIntegrationByIdUsingGET(goEposPosIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getGoEposPosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGoEposPosIntegrationsUsingGET(options?: any) {
            return GatewayGoEposPosIntegrationControllerApiFp(configuration).getGoEposPosIntegrationsUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateGoEposPosIntegrationById
         * @param {string} goEposPosIntegrationId goEposPosIntegrationId
         * @param {PosIntegrationUpdatePayload} posIntegrationUpdatePayload posIntegrationUpdatePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGoEposPosIntegrationByIdUsingPATCH(goEposPosIntegrationId: string, posIntegrationUpdatePayload: PosIntegrationUpdatePayload, options?: any) {
            return GatewayGoEposPosIntegrationControllerApiFp(configuration).updateGoEposPosIntegrationByIdUsingPATCH(goEposPosIntegrationId, posIntegrationUpdatePayload, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayGoEposPosIntegrationControllerApi - object-oriented interface
 * @export
 * @class GatewayGoEposPosIntegrationControllerApi
 * @extends {BaseAPI}
 */
export class GatewayGoEposPosIntegrationControllerApi extends BaseAPI {
    /**
     * 
     * @summary createGoEposPosIntegration
     * @param {GoEposIntegrationCreatePayload} goEposIntegrationCreatePayload goEposIntegrationCreatePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGoEposPosIntegrationControllerApi
     */
    public createGoEposPosIntegrationUsingPOST(goEposIntegrationCreatePayload: GoEposIntegrationCreatePayload, options?: any) {
        return GatewayGoEposPosIntegrationControllerApiFp(this.configuration).createGoEposPosIntegrationUsingPOST(goEposIntegrationCreatePayload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteGoEposPosIntegrationById
     * @param {string} goEposPosIntegrationId goEposPosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGoEposPosIntegrationControllerApi
     */
    public deleteGoEposPosIntegrationByIdUsingDELETE(goEposPosIntegrationId: string, options?: any) {
        return GatewayGoEposPosIntegrationControllerApiFp(this.configuration).deleteGoEposPosIntegrationByIdUsingDELETE(goEposPosIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getGoEposPosIntegrationById
     * @param {string} goEposPosIntegrationId goEposPosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGoEposPosIntegrationControllerApi
     */
    public getGoEposPosIntegrationByIdUsingGET(goEposPosIntegrationId: string, options?: any) {
        return GatewayGoEposPosIntegrationControllerApiFp(this.configuration).getGoEposPosIntegrationByIdUsingGET(goEposPosIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getGoEposPosIntegrations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGoEposPosIntegrationControllerApi
     */
    public getGoEposPosIntegrationsUsingGET(options?: any) {
        return GatewayGoEposPosIntegrationControllerApiFp(this.configuration).getGoEposPosIntegrationsUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateGoEposPosIntegrationById
     * @param {string} goEposPosIntegrationId goEposPosIntegrationId
     * @param {PosIntegrationUpdatePayload} posIntegrationUpdatePayload posIntegrationUpdatePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGoEposPosIntegrationControllerApi
     */
    public updateGoEposPosIntegrationByIdUsingPATCH(goEposPosIntegrationId: string, posIntegrationUpdatePayload: PosIntegrationUpdatePayload, options?: any) {
        return GatewayGoEposPosIntegrationControllerApiFp(this.configuration).updateGoEposPosIntegrationByIdUsingPATCH(goEposPosIntegrationId, posIntegrationUpdatePayload, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayGpReportControllerApi - fetch parameter creator
 * @export
 */
export const GatewayGpReportControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createGPReport
         * @param {string} closingStockTakeReportId closingStockTakeReportId
         * @param {string} openingStockTakeReportId openingStockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGPReportUsingPOST(closingStockTakeReportId: string, openingStockTakeReportId: string, filterCategories?: FilterCategories, salesId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'closingStockTakeReportId' is not null or undefined
            if (closingStockTakeReportId === null || closingStockTakeReportId === undefined) {
                throw new RequiredError('closingStockTakeReportId','Required parameter closingStockTakeReportId was null or undefined when calling createGPReportUsingPOST.');
            }
            // verify required parameter 'openingStockTakeReportId' is not null or undefined
            if (openingStockTakeReportId === null || openingStockTakeReportId === undefined) {
                throw new RequiredError('openingStockTakeReportId','Required parameter openingStockTakeReportId was null or undefined when calling createGPReportUsingPOST.');
            }
            const localVarPath = `/v1/gp-reports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (closingStockTakeReportId !== undefined) {
                localVarQueryParameter['closingStockTakeReportId'] = closingStockTakeReportId;
            }

            if (openingStockTakeReportId !== undefined) {
                localVarQueryParameter['openingStockTakeReportId'] = openingStockTakeReportId;
            }

            if (salesId !== undefined) {
                localVarQueryParameter['salesId'] = salesId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FilterCategories" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filterCategories || {}) : (filterCategories || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteGPReport
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGPReportUsingDELETE(gpReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'gpReportId' is not null or undefined
            if (gpReportId === null || gpReportId === undefined) {
                throw new RequiredError('gpReportId','Required parameter gpReportId was null or undefined when calling deleteGPReportUsingDELETE.');
            }
            const localVarPath = `/v1/gp-reports/{gpReportId}`
                .replace(`{${"gpReportId"}}`, encodeURIComponent(String(gpReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportItemisedGPReportAsCsv
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportItemisedGPReportAsCsvUsingGET(fileName: string, gpReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling exportItemisedGPReportAsCsvUsingGET.');
            }
            // verify required parameter 'gpReportId' is not null or undefined
            if (gpReportId === null || gpReportId === undefined) {
                throw new RequiredError('gpReportId','Required parameter gpReportId was null or undefined when calling exportItemisedGPReportAsCsvUsingGET.');
            }
            const localVarPath = `/v1/gp-reports/{gpReportId}/itemised/export-as-csv`
                .replace(`{${"gpReportId"}}`, encodeURIComponent(String(gpReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportItemisedGPReportAsXlsx
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportItemisedGPReportAsXlsxUsingGET(fileName: string, gpReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling exportItemisedGPReportAsXlsxUsingGET.');
            }
            // verify required parameter 'gpReportId' is not null or undefined
            if (gpReportId === null || gpReportId === undefined) {
                throw new RequiredError('gpReportId','Required parameter gpReportId was null or undefined when calling exportItemisedGPReportAsXlsxUsingGET.');
            }
            const localVarPath = `/v1/gp-reports/{gpReportId}/itemised/export-as-xlsx`
                .replace(`{${"gpReportId"}}`, encodeURIComponent(String(gpReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportSummarisedGPReportAsCsv
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSummarisedGPReportAsCsvUsingGET(fileName: string, gpReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling exportSummarisedGPReportAsCsvUsingGET.');
            }
            // verify required parameter 'gpReportId' is not null or undefined
            if (gpReportId === null || gpReportId === undefined) {
                throw new RequiredError('gpReportId','Required parameter gpReportId was null or undefined when calling exportSummarisedGPReportAsCsvUsingGET.');
            }
            const localVarPath = `/v1/gp-reports/{gpReportId}/summarised/export-as-csv`
                .replace(`{${"gpReportId"}}`, encodeURIComponent(String(gpReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportSummarisedGPReportAsPdf
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSummarisedGPReportAsPdfUsingGET(fileName: string, gpReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling exportSummarisedGPReportAsPdfUsingGET.');
            }
            // verify required parameter 'gpReportId' is not null or undefined
            if (gpReportId === null || gpReportId === undefined) {
                throw new RequiredError('gpReportId','Required parameter gpReportId was null or undefined when calling exportSummarisedGPReportAsPdfUsingGET.');
            }
            const localVarPath = `/v1/gp-reports/{gpReportId}/summarised/export-as-pdf`
                .replace(`{${"gpReportId"}}`, encodeURIComponent(String(gpReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportSummarisedGPReportAsXlsx
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSummarisedGPReportAsXlsxUsingGET(fileName: string, gpReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling exportSummarisedGPReportAsXlsxUsingGET.');
            }
            // verify required parameter 'gpReportId' is not null or undefined
            if (gpReportId === null || gpReportId === undefined) {
                throw new RequiredError('gpReportId','Required parameter gpReportId was null or undefined when calling exportSummarisedGPReportAsXlsxUsingGET.');
            }
            const localVarPath = `/v1/gp-reports/{gpReportId}/summarised/export-as-xlsx`
                .replace(`{${"gpReportId"}}`, encodeURIComponent(String(gpReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getGPReportById
         * @param {string} gpReportId gpReportId
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGPReportByIdUsingGET(gpReportId: string, partialProductName?: string, options: any = {}): FetchArgs {
            // verify required parameter 'gpReportId' is not null or undefined
            if (gpReportId === null || gpReportId === undefined) {
                throw new RequiredError('gpReportId','Required parameter gpReportId was null or undefined when calling getGPReportByIdUsingGET.');
            }
            const localVarPath = `/v1/gp-reports/{gpReportId}`
                .replace(`{${"gpReportId"}}`, encodeURIComponent(String(gpReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partialProductName !== undefined) {
                localVarQueryParameter['partialProductName'] = partialProductName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getGPReports
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGPReportsUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/gp-reports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateCategoryTotalsOfGPReport
         * @param {string} category category
         * @param {GPCategoryUpdatePayload} categoryPayload categoryPayload
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryTotalsOfGPReportUsingPATCH(category: string, categoryPayload: GPCategoryUpdatePayload, gpReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'category' is not null or undefined
            if (category === null || category === undefined) {
                throw new RequiredError('category','Required parameter category was null or undefined when calling updateCategoryTotalsOfGPReportUsingPATCH.');
            }
            // verify required parameter 'categoryPayload' is not null or undefined
            if (categoryPayload === null || categoryPayload === undefined) {
                throw new RequiredError('categoryPayload','Required parameter categoryPayload was null or undefined when calling updateCategoryTotalsOfGPReportUsingPATCH.');
            }
            // verify required parameter 'gpReportId' is not null or undefined
            if (gpReportId === null || gpReportId === undefined) {
                throw new RequiredError('gpReportId','Required parameter gpReportId was null or undefined when calling updateCategoryTotalsOfGPReportUsingPATCH.');
            }
            const localVarPath = `/v1/gp-reports/{gpReportId}/category-totals`
                .replace(`{${"gpReportId"}}`, encodeURIComponent(String(gpReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GPCategoryUpdatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(categoryPayload || {}) : (categoryPayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateGPReport
         * @param {string} gpReportId gpReportId
         * @param {string} [closingStockTakeReportId] closingStockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [openingStockTakeReportId] openingStockTakeReportId
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGPReportUsingPUT(gpReportId: string, closingStockTakeReportId?: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'gpReportId' is not null or undefined
            if (gpReportId === null || gpReportId === undefined) {
                throw new RequiredError('gpReportId','Required parameter gpReportId was null or undefined when calling updateGPReportUsingPUT.');
            }
            const localVarPath = `/v1/gp-reports/{gpReportId}`
                .replace(`{${"gpReportId"}}`, encodeURIComponent(String(gpReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (closingStockTakeReportId !== undefined) {
                localVarQueryParameter['closingStockTakeReportId'] = closingStockTakeReportId;
            }

            if (openingStockTakeReportId !== undefined) {
                localVarQueryParameter['openingStockTakeReportId'] = openingStockTakeReportId;
            }

            if (salesId !== undefined) {
                localVarQueryParameter['salesId'] = salesId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FilterCategories" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filterCategories || {}) : (filterCategories || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateProductOfGPReport
         * @param {string} barcode barcode
         * @param {string} gpReportId gpReportId
         * @param {GPProductUpdatePayload} productPayload productPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductOfGPReportUsingPATCH(barcode: string, gpReportId: string, productPayload: GPProductUpdatePayload, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling updateProductOfGPReportUsingPATCH.');
            }
            // verify required parameter 'gpReportId' is not null or undefined
            if (gpReportId === null || gpReportId === undefined) {
                throw new RequiredError('gpReportId','Required parameter gpReportId was null or undefined when calling updateProductOfGPReportUsingPATCH.');
            }
            // verify required parameter 'productPayload' is not null or undefined
            if (productPayload === null || productPayload === undefined) {
                throw new RequiredError('productPayload','Required parameter productPayload was null or undefined when calling updateProductOfGPReportUsingPATCH.');
            }
            const localVarPath = `/v1/gp-reports/{gpReportId}/product`
                .replace(`{${"gpReportId"}}`, encodeURIComponent(String(gpReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GPProductUpdatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(productPayload || {}) : (productPayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateSubCategoryTotalsOfGPReport
         * @param {string} category category
         * @param {string} gpReportId gpReportId
         * @param {string} subCategory subCategory
         * @param {GPCategoryUpdatePayload} subCategoryPayload subCategoryPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubCategoryTotalsOfGPReportUsingPATCH(category: string, gpReportId: string, subCategory: string, subCategoryPayload: GPCategoryUpdatePayload, options: any = {}): FetchArgs {
            // verify required parameter 'category' is not null or undefined
            if (category === null || category === undefined) {
                throw new RequiredError('category','Required parameter category was null or undefined when calling updateSubCategoryTotalsOfGPReportUsingPATCH.');
            }
            // verify required parameter 'gpReportId' is not null or undefined
            if (gpReportId === null || gpReportId === undefined) {
                throw new RequiredError('gpReportId','Required parameter gpReportId was null or undefined when calling updateSubCategoryTotalsOfGPReportUsingPATCH.');
            }
            // verify required parameter 'subCategory' is not null or undefined
            if (subCategory === null || subCategory === undefined) {
                throw new RequiredError('subCategory','Required parameter subCategory was null or undefined when calling updateSubCategoryTotalsOfGPReportUsingPATCH.');
            }
            // verify required parameter 'subCategoryPayload' is not null or undefined
            if (subCategoryPayload === null || subCategoryPayload === undefined) {
                throw new RequiredError('subCategoryPayload','Required parameter subCategoryPayload was null or undefined when calling updateSubCategoryTotalsOfGPReportUsingPATCH.');
            }
            const localVarPath = `/v1/gp-reports/{gpReportId}/subCategory-totals`
                .replace(`{${"gpReportId"}}`, encodeURIComponent(String(gpReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (subCategory !== undefined) {
                localVarQueryParameter['subCategory'] = subCategory;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GPCategoryUpdatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(subCategoryPayload || {}) : (subCategoryPayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayGpReportControllerApi - functional programming interface
 * @export
 */
export const GatewayGpReportControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createGPReport
         * @param {string} closingStockTakeReportId closingStockTakeReportId
         * @param {string} openingStockTakeReportId openingStockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGPReportUsingPOST(closingStockTakeReportId: string, openingStockTakeReportId: string, filterCategories?: FilterCategories, salesId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GPReport> {
            const localVarFetchArgs = GatewayGpReportControllerApiFetchParamCreator(configuration).createGPReportUsingPOST(closingStockTakeReportId, openingStockTakeReportId, filterCategories, salesId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteGPReport
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGPReportUsingDELETE(gpReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayGpReportControllerApiFetchParamCreator(configuration).deleteGPReportUsingDELETE(gpReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportItemisedGPReportAsCsv
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportItemisedGPReportAsCsvUsingGET(fileName: string, gpReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayGpReportControllerApiFetchParamCreator(configuration).exportItemisedGPReportAsCsvUsingGET(fileName, gpReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportItemisedGPReportAsXlsx
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportItemisedGPReportAsXlsxUsingGET(fileName: string, gpReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayGpReportControllerApiFetchParamCreator(configuration).exportItemisedGPReportAsXlsxUsingGET(fileName, gpReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportSummarisedGPReportAsCsv
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSummarisedGPReportAsCsvUsingGET(fileName: string, gpReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayGpReportControllerApiFetchParamCreator(configuration).exportSummarisedGPReportAsCsvUsingGET(fileName, gpReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportSummarisedGPReportAsPdf
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSummarisedGPReportAsPdfUsingGET(fileName: string, gpReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayGpReportControllerApiFetchParamCreator(configuration).exportSummarisedGPReportAsPdfUsingGET(fileName, gpReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportSummarisedGPReportAsXlsx
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSummarisedGPReportAsXlsxUsingGET(fileName: string, gpReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayGpReportControllerApiFetchParamCreator(configuration).exportSummarisedGPReportAsXlsxUsingGET(fileName, gpReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getGPReportById
         * @param {string} gpReportId gpReportId
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGPReportByIdUsingGET(gpReportId: string, partialProductName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GPReport> {
            const localVarFetchArgs = GatewayGpReportControllerApiFetchParamCreator(configuration).getGPReportByIdUsingGET(gpReportId, partialProductName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getGPReports
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGPReportsUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageGPReportWithoutProductsDto> {
            const localVarFetchArgs = GatewayGpReportControllerApiFetchParamCreator(configuration).getGPReportsUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateCategoryTotalsOfGPReport
         * @param {string} category category
         * @param {GPCategoryUpdatePayload} categoryPayload categoryPayload
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryTotalsOfGPReportUsingPATCH(category: string, categoryPayload: GPCategoryUpdatePayload, gpReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GPReport> {
            const localVarFetchArgs = GatewayGpReportControllerApiFetchParamCreator(configuration).updateCategoryTotalsOfGPReportUsingPATCH(category, categoryPayload, gpReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateGPReport
         * @param {string} gpReportId gpReportId
         * @param {string} [closingStockTakeReportId] closingStockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [openingStockTakeReportId] openingStockTakeReportId
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGPReportUsingPUT(gpReportId: string, closingStockTakeReportId?: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GPReport> {
            const localVarFetchArgs = GatewayGpReportControllerApiFetchParamCreator(configuration).updateGPReportUsingPUT(gpReportId, closingStockTakeReportId, filterCategories, openingStockTakeReportId, salesId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateProductOfGPReport
         * @param {string} barcode barcode
         * @param {string} gpReportId gpReportId
         * @param {GPProductUpdatePayload} productPayload productPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductOfGPReportUsingPATCH(barcode: string, gpReportId: string, productPayload: GPProductUpdatePayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GPReport> {
            const localVarFetchArgs = GatewayGpReportControllerApiFetchParamCreator(configuration).updateProductOfGPReportUsingPATCH(barcode, gpReportId, productPayload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateSubCategoryTotalsOfGPReport
         * @param {string} category category
         * @param {string} gpReportId gpReportId
         * @param {string} subCategory subCategory
         * @param {GPCategoryUpdatePayload} subCategoryPayload subCategoryPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubCategoryTotalsOfGPReportUsingPATCH(category: string, gpReportId: string, subCategory: string, subCategoryPayload: GPCategoryUpdatePayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GPReport> {
            const localVarFetchArgs = GatewayGpReportControllerApiFetchParamCreator(configuration).updateSubCategoryTotalsOfGPReportUsingPATCH(category, gpReportId, subCategory, subCategoryPayload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayGpReportControllerApi - factory interface
 * @export
 */
export const GatewayGpReportControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createGPReport
         * @param {string} closingStockTakeReportId closingStockTakeReportId
         * @param {string} openingStockTakeReportId openingStockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createGPReportUsingPOST(closingStockTakeReportId: string, openingStockTakeReportId: string, filterCategories?: FilterCategories, salesId?: string, options?: any) {
            return GatewayGpReportControllerApiFp(configuration).createGPReportUsingPOST(closingStockTakeReportId, openingStockTakeReportId, filterCategories, salesId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteGPReport
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteGPReportUsingDELETE(gpReportId: string, options?: any) {
            return GatewayGpReportControllerApiFp(configuration).deleteGPReportUsingDELETE(gpReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportItemisedGPReportAsCsv
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportItemisedGPReportAsCsvUsingGET(fileName: string, gpReportId: string, options?: any) {
            return GatewayGpReportControllerApiFp(configuration).exportItemisedGPReportAsCsvUsingGET(fileName, gpReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportItemisedGPReportAsXlsx
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportItemisedGPReportAsXlsxUsingGET(fileName: string, gpReportId: string, options?: any) {
            return GatewayGpReportControllerApiFp(configuration).exportItemisedGPReportAsXlsxUsingGET(fileName, gpReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportSummarisedGPReportAsCsv
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSummarisedGPReportAsCsvUsingGET(fileName: string, gpReportId: string, options?: any) {
            return GatewayGpReportControllerApiFp(configuration).exportSummarisedGPReportAsCsvUsingGET(fileName, gpReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportSummarisedGPReportAsPdf
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSummarisedGPReportAsPdfUsingGET(fileName: string, gpReportId: string, options?: any) {
            return GatewayGpReportControllerApiFp(configuration).exportSummarisedGPReportAsPdfUsingGET(fileName, gpReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportSummarisedGPReportAsXlsx
         * @param {string} fileName fileName
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSummarisedGPReportAsXlsxUsingGET(fileName: string, gpReportId: string, options?: any) {
            return GatewayGpReportControllerApiFp(configuration).exportSummarisedGPReportAsXlsxUsingGET(fileName, gpReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getGPReportById
         * @param {string} gpReportId gpReportId
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGPReportByIdUsingGET(gpReportId: string, partialProductName?: string, options?: any) {
            return GatewayGpReportControllerApiFp(configuration).getGPReportByIdUsingGET(gpReportId, partialProductName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getGPReports
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGPReportsUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return GatewayGpReportControllerApiFp(configuration).getGPReportsUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateCategoryTotalsOfGPReport
         * @param {string} category category
         * @param {GPCategoryUpdatePayload} categoryPayload categoryPayload
         * @param {string} gpReportId gpReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCategoryTotalsOfGPReportUsingPATCH(category: string, categoryPayload: GPCategoryUpdatePayload, gpReportId: string, options?: any) {
            return GatewayGpReportControllerApiFp(configuration).updateCategoryTotalsOfGPReportUsingPATCH(category, categoryPayload, gpReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateGPReport
         * @param {string} gpReportId gpReportId
         * @param {string} [closingStockTakeReportId] closingStockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [openingStockTakeReportId] openingStockTakeReportId
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGPReportUsingPUT(gpReportId: string, closingStockTakeReportId?: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options?: any) {
            return GatewayGpReportControllerApiFp(configuration).updateGPReportUsingPUT(gpReportId, closingStockTakeReportId, filterCategories, openingStockTakeReportId, salesId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateProductOfGPReport
         * @param {string} barcode barcode
         * @param {string} gpReportId gpReportId
         * @param {GPProductUpdatePayload} productPayload productPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductOfGPReportUsingPATCH(barcode: string, gpReportId: string, productPayload: GPProductUpdatePayload, options?: any) {
            return GatewayGpReportControllerApiFp(configuration).updateProductOfGPReportUsingPATCH(barcode, gpReportId, productPayload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateSubCategoryTotalsOfGPReport
         * @param {string} category category
         * @param {string} gpReportId gpReportId
         * @param {string} subCategory subCategory
         * @param {GPCategoryUpdatePayload} subCategoryPayload subCategoryPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubCategoryTotalsOfGPReportUsingPATCH(category: string, gpReportId: string, subCategory: string, subCategoryPayload: GPCategoryUpdatePayload, options?: any) {
            return GatewayGpReportControllerApiFp(configuration).updateSubCategoryTotalsOfGPReportUsingPATCH(category, gpReportId, subCategory, subCategoryPayload, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayGpReportControllerApi - object-oriented interface
 * @export
 * @class GatewayGpReportControllerApi
 * @extends {BaseAPI}
 */
export class GatewayGpReportControllerApi extends BaseAPI {
    /**
     * 
     * @summary createGPReport
     * @param {string} closingStockTakeReportId closingStockTakeReportId
     * @param {string} openingStockTakeReportId openingStockTakeReportId
     * @param {FilterCategories} [filterCategories] filterCategories
     * @param {string} [salesId] salesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGpReportControllerApi
     */
    public createGPReportUsingPOST(closingStockTakeReportId: string, openingStockTakeReportId: string, filterCategories?: FilterCategories, salesId?: string, options?: any) {
        return GatewayGpReportControllerApiFp(this.configuration).createGPReportUsingPOST(closingStockTakeReportId, openingStockTakeReportId, filterCategories, salesId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteGPReport
     * @param {string} gpReportId gpReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGpReportControllerApi
     */
    public deleteGPReportUsingDELETE(gpReportId: string, options?: any) {
        return GatewayGpReportControllerApiFp(this.configuration).deleteGPReportUsingDELETE(gpReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportItemisedGPReportAsCsv
     * @param {string} fileName fileName
     * @param {string} gpReportId gpReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGpReportControllerApi
     */
    public exportItemisedGPReportAsCsvUsingGET(fileName: string, gpReportId: string, options?: any) {
        return GatewayGpReportControllerApiFp(this.configuration).exportItemisedGPReportAsCsvUsingGET(fileName, gpReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportItemisedGPReportAsXlsx
     * @param {string} fileName fileName
     * @param {string} gpReportId gpReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGpReportControllerApi
     */
    public exportItemisedGPReportAsXlsxUsingGET(fileName: string, gpReportId: string, options?: any) {
        return GatewayGpReportControllerApiFp(this.configuration).exportItemisedGPReportAsXlsxUsingGET(fileName, gpReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportSummarisedGPReportAsCsv
     * @param {string} fileName fileName
     * @param {string} gpReportId gpReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGpReportControllerApi
     */
    public exportSummarisedGPReportAsCsvUsingGET(fileName: string, gpReportId: string, options?: any) {
        return GatewayGpReportControllerApiFp(this.configuration).exportSummarisedGPReportAsCsvUsingGET(fileName, gpReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportSummarisedGPReportAsPdf
     * @param {string} fileName fileName
     * @param {string} gpReportId gpReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGpReportControllerApi
     */
    public exportSummarisedGPReportAsPdfUsingGET(fileName: string, gpReportId: string, options?: any) {
        return GatewayGpReportControllerApiFp(this.configuration).exportSummarisedGPReportAsPdfUsingGET(fileName, gpReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportSummarisedGPReportAsXlsx
     * @param {string} fileName fileName
     * @param {string} gpReportId gpReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGpReportControllerApi
     */
    public exportSummarisedGPReportAsXlsxUsingGET(fileName: string, gpReportId: string, options?: any) {
        return GatewayGpReportControllerApiFp(this.configuration).exportSummarisedGPReportAsXlsxUsingGET(fileName, gpReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getGPReportById
     * @param {string} gpReportId gpReportId
     * @param {string} [partialProductName] partialProductName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGpReportControllerApi
     */
    public getGPReportByIdUsingGET(gpReportId: string, partialProductName?: string, options?: any) {
        return GatewayGpReportControllerApiFp(this.configuration).getGPReportByIdUsingGET(gpReportId, partialProductName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getGPReports
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGpReportControllerApi
     */
    public getGPReportsUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return GatewayGpReportControllerApiFp(this.configuration).getGPReportsUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateCategoryTotalsOfGPReport
     * @param {string} category category
     * @param {GPCategoryUpdatePayload} categoryPayload categoryPayload
     * @param {string} gpReportId gpReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGpReportControllerApi
     */
    public updateCategoryTotalsOfGPReportUsingPATCH(category: string, categoryPayload: GPCategoryUpdatePayload, gpReportId: string, options?: any) {
        return GatewayGpReportControllerApiFp(this.configuration).updateCategoryTotalsOfGPReportUsingPATCH(category, categoryPayload, gpReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateGPReport
     * @param {string} gpReportId gpReportId
     * @param {string} [closingStockTakeReportId] closingStockTakeReportId
     * @param {FilterCategories} [filterCategories] filterCategories
     * @param {string} [openingStockTakeReportId] openingStockTakeReportId
     * @param {string} [salesId] salesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGpReportControllerApi
     */
    public updateGPReportUsingPUT(gpReportId: string, closingStockTakeReportId?: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options?: any) {
        return GatewayGpReportControllerApiFp(this.configuration).updateGPReportUsingPUT(gpReportId, closingStockTakeReportId, filterCategories, openingStockTakeReportId, salesId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateProductOfGPReport
     * @param {string} barcode barcode
     * @param {string} gpReportId gpReportId
     * @param {GPProductUpdatePayload} productPayload productPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGpReportControllerApi
     */
    public updateProductOfGPReportUsingPATCH(barcode: string, gpReportId: string, productPayload: GPProductUpdatePayload, options?: any) {
        return GatewayGpReportControllerApiFp(this.configuration).updateProductOfGPReportUsingPATCH(barcode, gpReportId, productPayload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateSubCategoryTotalsOfGPReport
     * @param {string} category category
     * @param {string} gpReportId gpReportId
     * @param {string} subCategory subCategory
     * @param {GPCategoryUpdatePayload} subCategoryPayload subCategoryPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGpReportControllerApi
     */
    public updateSubCategoryTotalsOfGPReportUsingPATCH(category: string, gpReportId: string, subCategory: string, subCategoryPayload: GPCategoryUpdatePayload, options?: any) {
        return GatewayGpReportControllerApiFp(this.configuration).updateSubCategoryTotalsOfGPReportUsingPATCH(category, gpReportId, subCategory, subCategoryPayload, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayGrowyzeStorageControllerApi - fetch parameter creator
 * @export
 */
export const GatewayGrowyzeStorageControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getFileById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileByIdUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getFileByIdUsingGET.');
            }
            const localVarPath = `/v1/growyze-storage/files/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayGrowyzeStorageControllerApi - functional programming interface
 * @export
 */
export const GatewayGrowyzeStorageControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getFileById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileByIdUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayGrowyzeStorageControllerApiFetchParamCreator(configuration).getFileByIdUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayGrowyzeStorageControllerApi - factory interface
 * @export
 */
export const GatewayGrowyzeStorageControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getFileById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileByIdUsingGET(id: string, options?: any) {
            return GatewayGrowyzeStorageControllerApiFp(configuration).getFileByIdUsingGET(id, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayGrowyzeStorageControllerApi - object-oriented interface
 * @export
 * @class GatewayGrowyzeStorageControllerApi
 * @extends {BaseAPI}
 */
export class GatewayGrowyzeStorageControllerApi extends BaseAPI {
    /**
     * 
     * @summary getFileById
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayGrowyzeStorageControllerApi
     */
    public getFileByIdUsingGET(id: string, options?: any) {
        return GatewayGrowyzeStorageControllerApiFp(this.configuration).getFileByIdUsingGET(id, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayImageProcessingControllerApi - fetch parameter creator
 * @export
 */
export const GatewayImageProcessingControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary contactSupport
         * @param {ImageSupportRequestModel} imageSupportRequestModel imageSupportRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSupportUsingPOST(imageSupportRequestModel: ImageSupportRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'imageSupportRequestModel' is not null or undefined
            if (imageSupportRequestModel === null || imageSupportRequestModel === undefined) {
                throw new RequiredError('imageSupportRequestModel','Required parameter imageSupportRequestModel was null or undefined when calling contactSupportUsingPOST.');
            }
            const localVarPath = `/v1/file-processing/support`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ImageSupportRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(imageSupportRequestModel || {}) : (imageSupportRequestModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createPdf
         * @param {string} client client
         * @param {string} file file
         * @param {boolean} [completed] completed
         * @param {string} [fileId] fileId
         * @param {boolean} [skipTextRecognition] skipTextRecognition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPdfUsingPOST(client: string, file: string, completed?: boolean, fileId?: string, skipTextRecognition?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'client' is not null or undefined
            if (client === null || client === undefined) {
                throw new RequiredError('client','Required parameter client was null or undefined when calling createPdfUsingPOST.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling createPdfUsingPOST.');
            }
            const localVarPath = `/v1/file-processing/pdf/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (client !== undefined) {
                localVarQueryParameter['client'] = client;
            }

            if (completed !== undefined) {
                localVarQueryParameter['completed'] = completed;
            }

            if (fileId !== undefined) {
                localVarQueryParameter['fileId'] = fileId;
            }

            if (skipTextRecognition !== undefined) {
                localVarQueryParameter['skipTextRecognition'] = skipTextRecognition;
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(file || {}) : (file || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary operatePdf
         * @param {string} client client
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatePdfUsingPOST(client: string, file: string, options: any = {}): FetchArgs {
            // verify required parameter 'client' is not null or undefined
            if (client === null || client === undefined) {
                throw new RequiredError('client','Required parameter client was null or undefined when calling operatePdfUsingPOST.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling operatePdfUsingPOST.');
            }
            const localVarPath = `/v1/file-processing/pdf/operation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (client !== undefined) {
                localVarQueryParameter['client'] = client;
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(file || {}) : (file || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary processDNImg
         * @param {string} file file
         * @param {string} [supplierName] supplierName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processDNImgUsingPOST(file: string, supplierName?: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling processDNImgUsingPOST.');
            }
            const localVarPath = `/v1/file-processing/img/extract-dn-data`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (supplierName !== undefined) {
                localVarQueryParameter['supplierName'] = supplierName;
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(file || {}) : (file || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary processInvoiceImg
         * @param {string} file file
         * @param {string} [supplierName] supplierName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processInvoiceImgUsingPOST(file: string, supplierName?: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling processInvoiceImgUsingPOST.');
            }
            const localVarPath = `/v1/file-processing/img/extract-invoice-data`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (supplierName !== undefined) {
                localVarQueryParameter['supplierName'] = supplierName;
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(file || {}) : (file || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary processPdfDeliveryNote
         * @param {string} jobId jobId
         * @param {string} [supplierName] supplierName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPdfDeliveryNoteUsingPOST(jobId: string, supplierName?: string, options: any = {}): FetchArgs {
            // verify required parameter 'jobId' is not null or undefined
            if (jobId === null || jobId === undefined) {
                throw new RequiredError('jobId','Required parameter jobId was null or undefined when calling processPdfDeliveryNoteUsingPOST.');
            }
            const localVarPath = `/v1/file-processing/pdf/extract-dn-data`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            if (supplierName !== undefined) {
                localVarQueryParameter['supplierName'] = supplierName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary processPdfInvoice
         * @param {string} jobId jobId
         * @param {string} [supplierName] supplierName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPdfInvoiceUsingPOST(jobId: string, supplierName?: string, options: any = {}): FetchArgs {
            // verify required parameter 'jobId' is not null or undefined
            if (jobId === null || jobId === undefined) {
                throw new RequiredError('jobId','Required parameter jobId was null or undefined when calling processPdfInvoiceUsingPOST.');
            }
            const localVarPath = `/v1/file-processing/pdf/extract-invoice-data`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (jobId !== undefined) {
                localVarQueryParameter['jobId'] = jobId;
            }

            if (supplierName !== undefined) {
                localVarQueryParameter['supplierName'] = supplierName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary upload
         * @param {string} client client
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST(client: string, file: string, options: any = {}): FetchArgs {
            // verify required parameter 'client' is not null or undefined
            if (client === null || client === undefined) {
                throw new RequiredError('client','Required parameter client was null or undefined when calling uploadUsingPOST.');
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling uploadUsingPOST.');
            }
            const localVarPath = `/v1/file-processing/upload`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (client !== undefined) {
                localVarQueryParameter['client'] = client;
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(file || {}) : (file || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayImageProcessingControllerApi - functional programming interface
 * @export
 */
export const GatewayImageProcessingControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary contactSupport
         * @param {ImageSupportRequestModel} imageSupportRequestModel imageSupportRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSupportUsingPOST(imageSupportRequestModel: ImageSupportRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayImageProcessingControllerApiFetchParamCreator(configuration).contactSupportUsingPOST(imageSupportRequestModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createPdf
         * @param {string} client client
         * @param {string} file file
         * @param {boolean} [completed] completed
         * @param {string} [fileId] fileId
         * @param {boolean} [skipTextRecognition] skipTextRecognition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPdfUsingPOST(client: string, file: string, completed?: boolean, fileId?: string, skipTextRecognition?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PdfOperationResponse> {
            const localVarFetchArgs = GatewayImageProcessingControllerApiFetchParamCreator(configuration).createPdfUsingPOST(client, file, completed, fileId, skipTextRecognition, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary operatePdf
         * @param {string} client client
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatePdfUsingPOST(client: string, file: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PdfOperationResponse> {
            const localVarFetchArgs = GatewayImageProcessingControllerApiFetchParamCreator(configuration).operatePdfUsingPOST(client, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary processDNImg
         * @param {string} file file
         * @param {string} [supplierName] supplierName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processDNImgUsingPOST(file: string, supplierName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DNProcessingResultWithFile> {
            const localVarFetchArgs = GatewayImageProcessingControllerApiFetchParamCreator(configuration).processDNImgUsingPOST(file, supplierName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary processInvoiceImg
         * @param {string} file file
         * @param {string} [supplierName] supplierName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processInvoiceImgUsingPOST(file: string, supplierName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InvoiceProcessingResultWithFile> {
            const localVarFetchArgs = GatewayImageProcessingControllerApiFetchParamCreator(configuration).processInvoiceImgUsingPOST(file, supplierName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary processPdfDeliveryNote
         * @param {string} jobId jobId
         * @param {string} [supplierName] supplierName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPdfDeliveryNoteUsingPOST(jobId: string, supplierName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DNProcessingResult> {
            const localVarFetchArgs = GatewayImageProcessingControllerApiFetchParamCreator(configuration).processPdfDeliveryNoteUsingPOST(jobId, supplierName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary processPdfInvoice
         * @param {string} jobId jobId
         * @param {string} [supplierName] supplierName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPdfInvoiceUsingPOST(jobId: string, supplierName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InvoiceProcessingResult> {
            const localVarFetchArgs = GatewayImageProcessingControllerApiFetchParamCreator(configuration).processPdfInvoiceUsingPOST(jobId, supplierName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary upload
         * @param {string} client client
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST(client: string, file: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UploadResponse> {
            const localVarFetchArgs = GatewayImageProcessingControllerApiFetchParamCreator(configuration).uploadUsingPOST(client, file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayImageProcessingControllerApi - factory interface
 * @export
 */
export const GatewayImageProcessingControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary contactSupport
         * @param {ImageSupportRequestModel} imageSupportRequestModel imageSupportRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSupportUsingPOST(imageSupportRequestModel: ImageSupportRequestModel, options?: any) {
            return GatewayImageProcessingControllerApiFp(configuration).contactSupportUsingPOST(imageSupportRequestModel, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createPdf
         * @param {string} client client
         * @param {string} file file
         * @param {boolean} [completed] completed
         * @param {string} [fileId] fileId
         * @param {boolean} [skipTextRecognition] skipTextRecognition
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPdfUsingPOST(client: string, file: string, completed?: boolean, fileId?: string, skipTextRecognition?: boolean, options?: any) {
            return GatewayImageProcessingControllerApiFp(configuration).createPdfUsingPOST(client, file, completed, fileId, skipTextRecognition, options)(fetch, basePath);
        },
        /**
         * 
         * @summary operatePdf
         * @param {string} client client
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operatePdfUsingPOST(client: string, file: string, options?: any) {
            return GatewayImageProcessingControllerApiFp(configuration).operatePdfUsingPOST(client, file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary processDNImg
         * @param {string} file file
         * @param {string} [supplierName] supplierName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processDNImgUsingPOST(file: string, supplierName?: string, options?: any) {
            return GatewayImageProcessingControllerApiFp(configuration).processDNImgUsingPOST(file, supplierName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary processInvoiceImg
         * @param {string} file file
         * @param {string} [supplierName] supplierName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processInvoiceImgUsingPOST(file: string, supplierName?: string, options?: any) {
            return GatewayImageProcessingControllerApiFp(configuration).processInvoiceImgUsingPOST(file, supplierName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary processPdfDeliveryNote
         * @param {string} jobId jobId
         * @param {string} [supplierName] supplierName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPdfDeliveryNoteUsingPOST(jobId: string, supplierName?: string, options?: any) {
            return GatewayImageProcessingControllerApiFp(configuration).processPdfDeliveryNoteUsingPOST(jobId, supplierName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary processPdfInvoice
         * @param {string} jobId jobId
         * @param {string} [supplierName] supplierName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        processPdfInvoiceUsingPOST(jobId: string, supplierName?: string, options?: any) {
            return GatewayImageProcessingControllerApiFp(configuration).processPdfInvoiceUsingPOST(jobId, supplierName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary upload
         * @param {string} client client
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadUsingPOST(client: string, file: string, options?: any) {
            return GatewayImageProcessingControllerApiFp(configuration).uploadUsingPOST(client, file, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayImageProcessingControllerApi - object-oriented interface
 * @export
 * @class GatewayImageProcessingControllerApi
 * @extends {BaseAPI}
 */
export class GatewayImageProcessingControllerApi extends BaseAPI {
    /**
     * 
     * @summary contactSupport
     * @param {ImageSupportRequestModel} imageSupportRequestModel imageSupportRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayImageProcessingControllerApi
     */
    public contactSupportUsingPOST(imageSupportRequestModel: ImageSupportRequestModel, options?: any) {
        return GatewayImageProcessingControllerApiFp(this.configuration).contactSupportUsingPOST(imageSupportRequestModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createPdf
     * @param {string} client client
     * @param {string} file file
     * @param {boolean} [completed] completed
     * @param {string} [fileId] fileId
     * @param {boolean} [skipTextRecognition] skipTextRecognition
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayImageProcessingControllerApi
     */
    public createPdfUsingPOST(client: string, file: string, completed?: boolean, fileId?: string, skipTextRecognition?: boolean, options?: any) {
        return GatewayImageProcessingControllerApiFp(this.configuration).createPdfUsingPOST(client, file, completed, fileId, skipTextRecognition, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary operatePdf
     * @param {string} client client
     * @param {string} file file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayImageProcessingControllerApi
     */
    public operatePdfUsingPOST(client: string, file: string, options?: any) {
        return GatewayImageProcessingControllerApiFp(this.configuration).operatePdfUsingPOST(client, file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary processDNImg
     * @param {string} file file
     * @param {string} [supplierName] supplierName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayImageProcessingControllerApi
     */
    public processDNImgUsingPOST(file: string, supplierName?: string, options?: any) {
        return GatewayImageProcessingControllerApiFp(this.configuration).processDNImgUsingPOST(file, supplierName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary processInvoiceImg
     * @param {string} file file
     * @param {string} [supplierName] supplierName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayImageProcessingControllerApi
     */
    public processInvoiceImgUsingPOST(file: string, supplierName?: string, options?: any) {
        return GatewayImageProcessingControllerApiFp(this.configuration).processInvoiceImgUsingPOST(file, supplierName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary processPdfDeliveryNote
     * @param {string} jobId jobId
     * @param {string} [supplierName] supplierName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayImageProcessingControllerApi
     */
    public processPdfDeliveryNoteUsingPOST(jobId: string, supplierName?: string, options?: any) {
        return GatewayImageProcessingControllerApiFp(this.configuration).processPdfDeliveryNoteUsingPOST(jobId, supplierName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary processPdfInvoice
     * @param {string} jobId jobId
     * @param {string} [supplierName] supplierName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayImageProcessingControllerApi
     */
    public processPdfInvoiceUsingPOST(jobId: string, supplierName?: string, options?: any) {
        return GatewayImageProcessingControllerApiFp(this.configuration).processPdfInvoiceUsingPOST(jobId, supplierName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary upload
     * @param {string} client client
     * @param {string} file file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayImageProcessingControllerApi
     */
    public uploadUsingPOST(client: string, file: string, options?: any) {
        return GatewayImageProcessingControllerApiFp(this.configuration).uploadUsingPOST(client, file, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayIntegrationsControllerApi - fetch parameter creator
 * @export
 */
export const GatewayIntegrationsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayIntegrationsControllerApi - functional programming interface
 * @export
 */
export const GatewayIntegrationsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Integrations> {
            const localVarFetchArgs = GatewayIntegrationsControllerApiFetchParamCreator(configuration).getIntegrationsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayIntegrationsControllerApi - factory interface
 * @export
 */
export const GatewayIntegrationsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getIntegrationsUsingGET(options?: any) {
            return GatewayIntegrationsControllerApiFp(configuration).getIntegrationsUsingGET(options)(fetch, basePath);
        },
    };
};

/**
 * GatewayIntegrationsControllerApi - object-oriented interface
 * @export
 * @class GatewayIntegrationsControllerApi
 * @extends {BaseAPI}
 */
export class GatewayIntegrationsControllerApi extends BaseAPI {
    /**
     * 
     * @summary getIntegrations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayIntegrationsControllerApi
     */
    public getIntegrationsUsingGET(options?: any) {
        return GatewayIntegrationsControllerApiFp(this.configuration).getIntegrationsUsingGET(options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayInvoiceControllerApi - fetch parameter creator
 * @export
 */
export const GatewayInvoiceControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary approveInvoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveInvoiceUsingPOST(invoiceId: string, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling approveInvoiceUsingPOST.');
            }
            const localVarPath = `/v1/invoices/{invoiceId}/approve-invoice`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createInvoiceFromDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceFromDeliveryNoteUsingPOST(deliveryNoteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'deliveryNoteId' is not null or undefined
            if (deliveryNoteId === null || deliveryNoteId === undefined) {
                throw new RequiredError('deliveryNoteId','Required parameter deliveryNoteId was null or undefined when calling createInvoiceFromDeliveryNoteUsingPOST.');
            }
            const localVarPath = `/v1/invoices/create-from-dn`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deliveryNoteId !== undefined) {
                localVarQueryParameter['deliveryNoteId'] = deliveryNoteId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createInvoiceFromEmail
         * @param {InvoiceContractFitDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceFromEmailUsingPOST(body: InvoiceContractFitDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createInvoiceFromEmailUsingPOST.');
            }
            const localVarPath = `/v1/invoices/from-email`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvoiceContractFitDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createInvoice
         * @param {InvoiceCreateDto} invoiceCreateDto invoiceCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceUsingPOST(invoiceCreateDto: InvoiceCreateDto, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceCreateDto' is not null or undefined
            if (invoiceCreateDto === null || invoiceCreateDto === undefined) {
                throw new RequiredError('invoiceCreateDto','Required parameter invoiceCreateDto was null or undefined when calling createInvoiceUsingPOST.');
            }
            const localVarPath = `/v1/invoices`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvoiceCreateDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(invoiceCreateDto || {}) : (invoiceCreateDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteInvoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoiceUsingDELETE(invoiceId: string, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling deleteInvoiceUsingDELETE.');
            }
            const localVarPath = `/v1/invoices/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportInvoicesAsCSV
         * @param {Date} [from] from
         * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {Array<string>} [suppliersIds] suppliersIds
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportInvoicesAsCSVUsingGET(from?: Date, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/v1/invoices/export-as-csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            if (suppliersIds) {
                localVarQueryParameter['suppliersIds'] = suppliersIds;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportInvoicesAsXlsx
         * @param {Date} [from] from
         * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {Array<string>} [suppliersIds] suppliersIds
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportInvoicesAsXlsxUsingGET(from?: Date, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/v1/invoices/export-as-xlsx`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            if (suppliersIds) {
                localVarQueryParameter['suppliersIds'] = suppliersIds;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportProductsOfInvoicesAsXlsx
         * @param {ExportInvoiceProductsPayload} payload payload
         * @param {Date} [from] from
         * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductsOfInvoicesAsXlsxUsingPOST(payload: ExportInvoiceProductsPayload, from?: Date, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', to?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling exportProductsOfInvoicesAsXlsxUsingPOST.');
            }
            const localVarPath = `/v1/invoices/products/export-as-xlsx`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ExportInvoiceProductsPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(payload || {}) : (payload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportScannedInvoicesAsXlsx
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportScannedInvoicesAsXlsxUsingGET(from: Date, to: Date, options: any = {}): FetchArgs {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling exportScannedInvoicesAsXlsxUsingGET.');
            }
            // verify required parameter 'to' is not null or undefined
            if (to === null || to === undefined) {
                throw new RequiredError('to','Required parameter to was null or undefined when calling exportScannedInvoicesAsXlsxUsingGET.');
            }
            const localVarPath = `/v1/invoices/scanned/export-as-xlsx`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getInvoiceById
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceByIdUsingGET(invoiceId: string, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling getInvoiceByIdUsingGET.');
            }
            const localVarPath = `/v1/invoices/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getInvoiceDiscrepanciesByInvoiceId
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceDiscrepanciesByInvoiceIdUsingGET(invoiceId: string, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling getInvoiceDiscrepanciesByInvoiceIdUsingGET.');
            }
            const localVarPath = `/v1/invoices/{invoiceId}/discrepancies`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getInvoicesByCriteria
         * @param {string} [deliveryNoteNumber] deliveryNoteNumber
         * @param {string} [invoiceNumber] invoiceNumber
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {string} [partialProductCode] partialProductCode
         * @param {string} [partialProductName] partialProductName
         * @param {string} [po] po
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesByCriteriaUsingGET(deliveryNoteNumber?: string, invoiceNumber?: string, organizations?: Array<string>, page?: number, partialProductCode?: string, partialProductName?: string, po?: string, size?: number, sort?: string, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', supplierId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/invoices`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deliveryNoteNumber !== undefined) {
                localVarQueryParameter['deliveryNoteNumber'] = deliveryNoteNumber;
            }

            if (invoiceNumber !== undefined) {
                localVarQueryParameter['invoiceNumber'] = invoiceNumber;
            }

            if (organizations) {
                localVarQueryParameter['organizations'] = organizations;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialProductCode !== undefined) {
                localVarQueryParameter['partialProductCode'] = partialProductCode;
            }

            if (partialProductName !== undefined) {
                localVarQueryParameter['partialProductName'] = partialProductName;
            }

            if (po !== undefined) {
                localVarQueryParameter['po'] = po;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getLastProductPrice
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastProductPriceUsingGET(barcode: string, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling getLastProductPriceUsingGET.');
            }
            const localVarPath = `/v1/invoices/last-product-price/{barcode}`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProductsLastPricesByBarcodes
         * @param {Array<string>} barcodes barcodes
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsLastPricesByBarcodesUsingPOST(barcodes: Array<string>, from?: Date, to?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'barcodes' is not null or undefined
            if (barcodes === null || barcodes === undefined) {
                throw new RequiredError('barcodes','Required parameter barcodes was null or undefined when calling getProductsLastPricesByBarcodesUsingPOST.');
            }
            const localVarPath = `/v1/invoices/products-last-prices-by-barcodes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(barcodes || {}) : (barcodes || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProductsLastPrices
         * @param {Date} from from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsLastPricesUsingGET(from: Date, to?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling getProductsLastPricesUsingGET.');
            }
            const localVarPath = `/v1/invoices/products-last-prices`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary publishXeroInvoice
         * @param {string} invoiceId invoiceId
         * @param {XeroInvoice} xeroInvoice xeroInvoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishXeroInvoiceUsingPOST(invoiceId: string, xeroInvoice: XeroInvoice, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling publishXeroInvoiceUsingPOST.');
            }
            // verify required parameter 'xeroInvoice' is not null or undefined
            if (xeroInvoice === null || xeroInvoice === undefined) {
                throw new RequiredError('xeroInvoice','Required parameter xeroInvoice was null or undefined when calling publishXeroInvoiceUsingPOST.');
            }
            const localVarPath = `/v1/invoices/{invoiceId}/xero`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"XeroInvoice" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(xeroInvoice || {}) : (xeroInvoice || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary querySupplierForDiscrepancies
         * @param {string} invoiceId invoiceId
         * @param {Array<any>} [files] files
         * @param {string} [message] message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        querySupplierForDiscrepanciesUsingPOST1(invoiceId: string, files?: Array<any>, message?: string, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling querySupplierForDiscrepanciesUsingPOST1.');
            }
            const localVarPath = `/v1/invoices/{invoiceId}/query-supplier`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;any&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(files || {}) : (files || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary rejectInvoice
         * @param {string} invoiceId invoiceId
         * @param {boolean} [completeDN] completeDN
         * @param {Array<any>} [files] files
         * @param {string} [message] message
         * @param {boolean} [notifySupplier] notifySupplier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectInvoiceUsingPOST(invoiceId: string, completeDN?: boolean, files?: Array<any>, message?: string, notifySupplier?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling rejectInvoiceUsingPOST.');
            }
            const localVarPath = `/v1/invoices/{invoiceId}/reject-invoice`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (completeDN !== undefined) {
                localVarQueryParameter['completeDN'] = completeDN;
            }

            if (message !== undefined) {
                localVarQueryParameter['message'] = message;
            }

            if (notifySupplier !== undefined) {
                localVarQueryParameter['notifySupplier'] = notifySupplier;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;any&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(files || {}) : (files || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateInvoiceDiscrepancy
         * @param {string} discrepancyId discrepancyId
         * @param {string} invoiceId invoiceId
         * @param {InvoiceDiscrepancyPatchDto} patch patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceDiscrepancyUsingPATCH(discrepancyId: string, invoiceId: string, patch: InvoiceDiscrepancyPatchDto, options: any = {}): FetchArgs {
            // verify required parameter 'discrepancyId' is not null or undefined
            if (discrepancyId === null || discrepancyId === undefined) {
                throw new RequiredError('discrepancyId','Required parameter discrepancyId was null or undefined when calling updateInvoiceDiscrepancyUsingPATCH.');
            }
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling updateInvoiceDiscrepancyUsingPATCH.');
            }
            // verify required parameter 'patch' is not null or undefined
            if (patch === null || patch === undefined) {
                throw new RequiredError('patch','Required parameter patch was null or undefined when calling updateInvoiceDiscrepancyUsingPATCH.');
            }
            const localVarPath = `/v1/invoices/{invoiceId}/discrepancies/{discrepancyId}`
                .replace(`{${"discrepancyId"}}`, encodeURIComponent(String(discrepancyId)))
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvoiceDiscrepancyPatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(patch || {}) : (patch || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateInvoiceFiles
         * @param {InvoiceFilesDto} invoiceFilesDto invoiceFilesDto
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceFilesUsingPOST(invoiceFilesDto: InvoiceFilesDto, invoiceId: string, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceFilesDto' is not null or undefined
            if (invoiceFilesDto === null || invoiceFilesDto === undefined) {
                throw new RequiredError('invoiceFilesDto','Required parameter invoiceFilesDto was null or undefined when calling updateInvoiceFilesUsingPOST.');
            }
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling updateInvoiceFilesUsingPOST.');
            }
            const localVarPath = `/v1/invoices/{invoiceId}/files`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvoiceFilesDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(invoiceFilesDto || {}) : (invoiceFilesDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateInvoice
         * @param {string} invoiceId invoiceId
         * @param {InvoiceUpdateDto} invoiceUpdateDto invoiceUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceUsingPUT(invoiceId: string, invoiceUpdateDto: InvoiceUpdateDto, options: any = {}): FetchArgs {
            // verify required parameter 'invoiceId' is not null or undefined
            if (invoiceId === null || invoiceId === undefined) {
                throw new RequiredError('invoiceId','Required parameter invoiceId was null or undefined when calling updateInvoiceUsingPUT.');
            }
            // verify required parameter 'invoiceUpdateDto' is not null or undefined
            if (invoiceUpdateDto === null || invoiceUpdateDto === undefined) {
                throw new RequiredError('invoiceUpdateDto','Required parameter invoiceUpdateDto was null or undefined when calling updateInvoiceUsingPUT.');
            }
            const localVarPath = `/v1/invoices/{invoiceId}`
                .replace(`{${"invoiceId"}}`, encodeURIComponent(String(invoiceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"InvoiceUpdateDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(invoiceUpdateDto || {}) : (invoiceUpdateDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayInvoiceControllerApi - functional programming interface
 * @export
 */
export const GatewayInvoiceControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary approveInvoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveInvoiceUsingPOST(invoiceId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).approveInvoiceUsingPOST(invoiceId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createInvoiceFromDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceFromDeliveryNoteUsingPOST(deliveryNoteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Invoice> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).createInvoiceFromDeliveryNoteUsingPOST(deliveryNoteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createInvoiceFromEmail
         * @param {InvoiceContractFitDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceFromEmailUsingPOST(body: InvoiceContractFitDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).createInvoiceFromEmailUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createInvoice
         * @param {InvoiceCreateDto} invoiceCreateDto invoiceCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceUsingPOST(invoiceCreateDto: InvoiceCreateDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Invoice> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).createInvoiceUsingPOST(invoiceCreateDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteInvoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoiceUsingDELETE(invoiceId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).deleteInvoiceUsingDELETE(invoiceId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportInvoicesAsCSV
         * @param {Date} [from] from
         * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {Array<string>} [suppliersIds] suppliersIds
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportInvoicesAsCSVUsingGET(from?: Date, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).exportInvoicesAsCSVUsingGET(from, status, supplierId, suppliersIds, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportInvoicesAsXlsx
         * @param {Date} [from] from
         * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {Array<string>} [suppliersIds] suppliersIds
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportInvoicesAsXlsxUsingGET(from?: Date, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).exportInvoicesAsXlsxUsingGET(from, status, supplierId, suppliersIds, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportProductsOfInvoicesAsXlsx
         * @param {ExportInvoiceProductsPayload} payload payload
         * @param {Date} [from] from
         * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductsOfInvoicesAsXlsxUsingPOST(payload: ExportInvoiceProductsPayload, from?: Date, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).exportProductsOfInvoicesAsXlsxUsingPOST(payload, from, status, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportScannedInvoicesAsXlsx
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportScannedInvoicesAsXlsxUsingGET(from: Date, to: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).exportScannedInvoicesAsXlsxUsingGET(from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getInvoiceById
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceByIdUsingGET(invoiceId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Invoice> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).getInvoiceByIdUsingGET(invoiceId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getInvoiceDiscrepanciesByInvoiceId
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceDiscrepanciesByInvoiceIdUsingGET(invoiceId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<InvoiceDiscrepanciesResponseDto> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).getInvoiceDiscrepanciesByInvoiceIdUsingGET(invoiceId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getInvoicesByCriteria
         * @param {string} [deliveryNoteNumber] deliveryNoteNumber
         * @param {string} [invoiceNumber] invoiceNumber
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {string} [partialProductCode] partialProductCode
         * @param {string} [partialProductName] partialProductName
         * @param {string} [po] po
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesByCriteriaUsingGET(deliveryNoteNumber?: string, invoiceNumber?: string, organizations?: Array<string>, page?: number, partialProductCode?: string, partialProductName?: string, po?: string, size?: number, sort?: string, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', supplierId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageInvoiceWithOrgsNames> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).getInvoicesByCriteriaUsingGET(deliveryNoteNumber, invoiceNumber, organizations, page, partialProductCode, partialProductName, po, size, sort, status, supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getLastProductPrice
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastProductPriceUsingGET(barcode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).getLastProductPriceUsingGET(barcode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getProductsLastPricesByBarcodes
         * @param {Array<string>} barcodes barcodes
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsLastPricesByBarcodesUsingPOST(barcodes: Array<string>, from?: Date, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<InvoicedProductDto>> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).getProductsLastPricesByBarcodesUsingPOST(barcodes, from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getProductsLastPrices
         * @param {Date} from from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsLastPricesUsingGET(from: Date, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<InvoicedProductSortedDto>> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).getProductsLastPricesUsingGET(from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary publishXeroInvoice
         * @param {string} invoiceId invoiceId
         * @param {XeroInvoice} xeroInvoice xeroInvoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishXeroInvoiceUsingPOST(invoiceId: string, xeroInvoice: XeroInvoice, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).publishXeroInvoiceUsingPOST(invoiceId, xeroInvoice, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary querySupplierForDiscrepancies
         * @param {string} invoiceId invoiceId
         * @param {Array<any>} [files] files
         * @param {string} [message] message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        querySupplierForDiscrepanciesUsingPOST1(invoiceId: string, files?: Array<any>, message?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).querySupplierForDiscrepanciesUsingPOST1(invoiceId, files, message, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary rejectInvoice
         * @param {string} invoiceId invoiceId
         * @param {boolean} [completeDN] completeDN
         * @param {Array<any>} [files] files
         * @param {string} [message] message
         * @param {boolean} [notifySupplier] notifySupplier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectInvoiceUsingPOST(invoiceId: string, completeDN?: boolean, files?: Array<any>, message?: string, notifySupplier?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).rejectInvoiceUsingPOST(invoiceId, completeDN, files, message, notifySupplier, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateInvoiceDiscrepancy
         * @param {string} discrepancyId discrepancyId
         * @param {string} invoiceId invoiceId
         * @param {InvoiceDiscrepancyPatchDto} patch patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceDiscrepancyUsingPATCH(discrepancyId: string, invoiceId: string, patch: InvoiceDiscrepancyPatchDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).updateInvoiceDiscrepancyUsingPATCH(discrepancyId, invoiceId, patch, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateInvoiceFiles
         * @param {InvoiceFilesDto} invoiceFilesDto invoiceFilesDto
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceFilesUsingPOST(invoiceFilesDto: InvoiceFilesDto, invoiceId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Invoice> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).updateInvoiceFilesUsingPOST(invoiceFilesDto, invoiceId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateInvoice
         * @param {string} invoiceId invoiceId
         * @param {InvoiceUpdateDto} invoiceUpdateDto invoiceUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceUsingPUT(invoiceId: string, invoiceUpdateDto: InvoiceUpdateDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Invoice> {
            const localVarFetchArgs = GatewayInvoiceControllerApiFetchParamCreator(configuration).updateInvoiceUsingPUT(invoiceId, invoiceUpdateDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayInvoiceControllerApi - factory interface
 * @export
 */
export const GatewayInvoiceControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary approveInvoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveInvoiceUsingPOST(invoiceId: string, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).approveInvoiceUsingPOST(invoiceId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createInvoiceFromDeliveryNote
         * @param {string} deliveryNoteId deliveryNoteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceFromDeliveryNoteUsingPOST(deliveryNoteId: string, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).createInvoiceFromDeliveryNoteUsingPOST(deliveryNoteId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createInvoiceFromEmail
         * @param {InvoiceContractFitDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceFromEmailUsingPOST(body: InvoiceContractFitDTO, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).createInvoiceFromEmailUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createInvoice
         * @param {InvoiceCreateDto} invoiceCreateDto invoiceCreateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createInvoiceUsingPOST(invoiceCreateDto: InvoiceCreateDto, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).createInvoiceUsingPOST(invoiceCreateDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteInvoice
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvoiceUsingDELETE(invoiceId: string, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).deleteInvoiceUsingDELETE(invoiceId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportInvoicesAsCSV
         * @param {Date} [from] from
         * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {Array<string>} [suppliersIds] suppliersIds
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportInvoicesAsCSVUsingGET(from?: Date, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).exportInvoicesAsCSVUsingGET(from, status, supplierId, suppliersIds, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportInvoicesAsXlsx
         * @param {Date} [from] from
         * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {Array<string>} [suppliersIds] suppliersIds
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportInvoicesAsXlsxUsingGET(from?: Date, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).exportInvoicesAsXlsxUsingGET(from, status, supplierId, suppliersIds, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportProductsOfInvoicesAsXlsx
         * @param {ExportInvoiceProductsPayload} payload payload
         * @param {Date} [from] from
         * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductsOfInvoicesAsXlsxUsingPOST(payload: ExportInvoiceProductsPayload, from?: Date, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', to?: Date, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).exportProductsOfInvoicesAsXlsxUsingPOST(payload, from, status, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportScannedInvoicesAsXlsx
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportScannedInvoicesAsXlsxUsingGET(from: Date, to: Date, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).exportScannedInvoicesAsXlsxUsingGET(from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getInvoiceById
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceByIdUsingGET(invoiceId: string, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).getInvoiceByIdUsingGET(invoiceId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getInvoiceDiscrepanciesByInvoiceId
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoiceDiscrepanciesByInvoiceIdUsingGET(invoiceId: string, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).getInvoiceDiscrepanciesByInvoiceIdUsingGET(invoiceId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getInvoicesByCriteria
         * @param {string} [deliveryNoteNumber] deliveryNoteNumber
         * @param {string} [invoiceNumber] invoiceNumber
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {string} [partialProductCode] partialProductCode
         * @param {string} [partialProductName] partialProductName
         * @param {string} [po] po
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvoicesByCriteriaUsingGET(deliveryNoteNumber?: string, invoiceNumber?: string, organizations?: Array<string>, page?: number, partialProductCode?: string, partialProductName?: string, po?: string, size?: number, sort?: string, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', supplierId?: string, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).getInvoicesByCriteriaUsingGET(deliveryNoteNumber, invoiceNumber, organizations, page, partialProductCode, partialProductName, po, size, sort, status, supplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getLastProductPrice
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastProductPriceUsingGET(barcode: string, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).getLastProductPriceUsingGET(barcode, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getProductsLastPricesByBarcodes
         * @param {Array<string>} barcodes barcodes
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsLastPricesByBarcodesUsingPOST(barcodes: Array<string>, from?: Date, to?: Date, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).getProductsLastPricesByBarcodesUsingPOST(barcodes, from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getProductsLastPrices
         * @param {Date} from from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsLastPricesUsingGET(from: Date, to?: Date, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).getProductsLastPricesUsingGET(from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary publishXeroInvoice
         * @param {string} invoiceId invoiceId
         * @param {XeroInvoice} xeroInvoice xeroInvoice
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        publishXeroInvoiceUsingPOST(invoiceId: string, xeroInvoice: XeroInvoice, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).publishXeroInvoiceUsingPOST(invoiceId, xeroInvoice, options)(fetch, basePath);
        },
        /**
         * 
         * @summary querySupplierForDiscrepancies
         * @param {string} invoiceId invoiceId
         * @param {Array<any>} [files] files
         * @param {string} [message] message
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        querySupplierForDiscrepanciesUsingPOST1(invoiceId: string, files?: Array<any>, message?: string, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).querySupplierForDiscrepanciesUsingPOST1(invoiceId, files, message, options)(fetch, basePath);
        },
        /**
         * 
         * @summary rejectInvoice
         * @param {string} invoiceId invoiceId
         * @param {boolean} [completeDN] completeDN
         * @param {Array<any>} [files] files
         * @param {string} [message] message
         * @param {boolean} [notifySupplier] notifySupplier
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        rejectInvoiceUsingPOST(invoiceId: string, completeDN?: boolean, files?: Array<any>, message?: string, notifySupplier?: boolean, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).rejectInvoiceUsingPOST(invoiceId, completeDN, files, message, notifySupplier, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateInvoiceDiscrepancy
         * @param {string} discrepancyId discrepancyId
         * @param {string} invoiceId invoiceId
         * @param {InvoiceDiscrepancyPatchDto} patch patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceDiscrepancyUsingPATCH(discrepancyId: string, invoiceId: string, patch: InvoiceDiscrepancyPatchDto, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).updateInvoiceDiscrepancyUsingPATCH(discrepancyId, invoiceId, patch, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateInvoiceFiles
         * @param {InvoiceFilesDto} invoiceFilesDto invoiceFilesDto
         * @param {string} invoiceId invoiceId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceFilesUsingPOST(invoiceFilesDto: InvoiceFilesDto, invoiceId: string, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).updateInvoiceFilesUsingPOST(invoiceFilesDto, invoiceId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateInvoice
         * @param {string} invoiceId invoiceId
         * @param {InvoiceUpdateDto} invoiceUpdateDto invoiceUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateInvoiceUsingPUT(invoiceId: string, invoiceUpdateDto: InvoiceUpdateDto, options?: any) {
            return GatewayInvoiceControllerApiFp(configuration).updateInvoiceUsingPUT(invoiceId, invoiceUpdateDto, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayInvoiceControllerApi - object-oriented interface
 * @export
 * @class GatewayInvoiceControllerApi
 * @extends {BaseAPI}
 */
export class GatewayInvoiceControllerApi extends BaseAPI {
    /**
     * 
     * @summary approveInvoice
     * @param {string} invoiceId invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public approveInvoiceUsingPOST(invoiceId: string, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).approveInvoiceUsingPOST(invoiceId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createInvoiceFromDeliveryNote
     * @param {string} deliveryNoteId deliveryNoteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public createInvoiceFromDeliveryNoteUsingPOST(deliveryNoteId: string, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).createInvoiceFromDeliveryNoteUsingPOST(deliveryNoteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createInvoiceFromEmail
     * @param {InvoiceContractFitDTO} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public createInvoiceFromEmailUsingPOST(body: InvoiceContractFitDTO, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).createInvoiceFromEmailUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createInvoice
     * @param {InvoiceCreateDto} invoiceCreateDto invoiceCreateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public createInvoiceUsingPOST(invoiceCreateDto: InvoiceCreateDto, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).createInvoiceUsingPOST(invoiceCreateDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteInvoice
     * @param {string} invoiceId invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public deleteInvoiceUsingDELETE(invoiceId: string, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).deleteInvoiceUsingDELETE(invoiceId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportInvoicesAsCSV
     * @param {Date} [from] from
     * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
     * @param {string} [supplierId] supplierId
     * @param {Array<string>} [suppliersIds] suppliersIds
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public exportInvoicesAsCSVUsingGET(from?: Date, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).exportInvoicesAsCSVUsingGET(from, status, supplierId, suppliersIds, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportInvoicesAsXlsx
     * @param {Date} [from] from
     * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
     * @param {string} [supplierId] supplierId
     * @param {Array<string>} [suppliersIds] suppliersIds
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public exportInvoicesAsXlsxUsingGET(from?: Date, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', supplierId?: string, suppliersIds?: Array<string>, to?: Date, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).exportInvoicesAsXlsxUsingGET(from, status, supplierId, suppliersIds, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportProductsOfInvoicesAsXlsx
     * @param {ExportInvoiceProductsPayload} payload payload
     * @param {Date} [from] from
     * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public exportProductsOfInvoicesAsXlsxUsingPOST(payload: ExportInvoiceProductsPayload, from?: Date, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', to?: Date, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).exportProductsOfInvoicesAsXlsxUsingPOST(payload, from, status, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportScannedInvoicesAsXlsx
     * @param {Date} from from
     * @param {Date} to to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public exportScannedInvoicesAsXlsxUsingGET(from: Date, to: Date, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).exportScannedInvoicesAsXlsxUsingGET(from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getInvoiceById
     * @param {string} invoiceId invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public getInvoiceByIdUsingGET(invoiceId: string, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).getInvoiceByIdUsingGET(invoiceId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getInvoiceDiscrepanciesByInvoiceId
     * @param {string} invoiceId invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public getInvoiceDiscrepanciesByInvoiceIdUsingGET(invoiceId: string, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).getInvoiceDiscrepanciesByInvoiceIdUsingGET(invoiceId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getInvoicesByCriteria
     * @param {string} [deliveryNoteNumber] deliveryNoteNumber
     * @param {string} [invoiceNumber] invoiceNumber
     * @param {Array<string>} [organizations] organizations
     * @param {number} [page] 
     * @param {string} [partialProductCode] partialProductCode
     * @param {string} [partialProductName] partialProductName
     * @param {string} [po] po
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW'} [status] status
     * @param {string} [supplierId] supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public getInvoicesByCriteriaUsingGET(deliveryNoteNumber?: string, invoiceNumber?: string, organizations?: Array<string>, page?: number, partialProductCode?: string, partialProductName?: string, po?: string, size?: number, sort?: string, status?: 'APPROVED' | 'DRAFT' | 'IN_QUERY' | 'REJECTED' | 'REVIEW', supplierId?: string, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).getInvoicesByCriteriaUsingGET(deliveryNoteNumber, invoiceNumber, organizations, page, partialProductCode, partialProductName, po, size, sort, status, supplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getLastProductPrice
     * @param {string} barcode barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public getLastProductPriceUsingGET(barcode: string, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).getLastProductPriceUsingGET(barcode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getProductsLastPricesByBarcodes
     * @param {Array<string>} barcodes barcodes
     * @param {Date} [from] from
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public getProductsLastPricesByBarcodesUsingPOST(barcodes: Array<string>, from?: Date, to?: Date, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).getProductsLastPricesByBarcodesUsingPOST(barcodes, from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getProductsLastPrices
     * @param {Date} from from
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public getProductsLastPricesUsingGET(from: Date, to?: Date, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).getProductsLastPricesUsingGET(from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary publishXeroInvoice
     * @param {string} invoiceId invoiceId
     * @param {XeroInvoice} xeroInvoice xeroInvoice
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public publishXeroInvoiceUsingPOST(invoiceId: string, xeroInvoice: XeroInvoice, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).publishXeroInvoiceUsingPOST(invoiceId, xeroInvoice, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary querySupplierForDiscrepancies
     * @param {string} invoiceId invoiceId
     * @param {Array<any>} [files] files
     * @param {string} [message] message
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public querySupplierForDiscrepanciesUsingPOST1(invoiceId: string, files?: Array<any>, message?: string, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).querySupplierForDiscrepanciesUsingPOST1(invoiceId, files, message, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary rejectInvoice
     * @param {string} invoiceId invoiceId
     * @param {boolean} [completeDN] completeDN
     * @param {Array<any>} [files] files
     * @param {string} [message] message
     * @param {boolean} [notifySupplier] notifySupplier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public rejectInvoiceUsingPOST(invoiceId: string, completeDN?: boolean, files?: Array<any>, message?: string, notifySupplier?: boolean, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).rejectInvoiceUsingPOST(invoiceId, completeDN, files, message, notifySupplier, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateInvoiceDiscrepancy
     * @param {string} discrepancyId discrepancyId
     * @param {string} invoiceId invoiceId
     * @param {InvoiceDiscrepancyPatchDto} patch patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public updateInvoiceDiscrepancyUsingPATCH(discrepancyId: string, invoiceId: string, patch: InvoiceDiscrepancyPatchDto, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).updateInvoiceDiscrepancyUsingPATCH(discrepancyId, invoiceId, patch, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateInvoiceFiles
     * @param {InvoiceFilesDto} invoiceFilesDto invoiceFilesDto
     * @param {string} invoiceId invoiceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public updateInvoiceFilesUsingPOST(invoiceFilesDto: InvoiceFilesDto, invoiceId: string, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).updateInvoiceFilesUsingPOST(invoiceFilesDto, invoiceId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateInvoice
     * @param {string} invoiceId invoiceId
     * @param {InvoiceUpdateDto} invoiceUpdateDto invoiceUpdateDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayInvoiceControllerApi
     */
    public updateInvoiceUsingPUT(invoiceId: string, invoiceUpdateDto: InvoiceUpdateDto, options?: any) {
        return GatewayInvoiceControllerApiFp(this.configuration).updateInvoiceUsingPUT(invoiceId, invoiceUpdateDto, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayLightspeedPosIntegrationControllerApi - fetch parameter creator
 * @export
 */
export const GatewayLightspeedPosIntegrationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createLightspeedPosIntegration
         * @param {LightspeedPosIntegrationCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLightspeedPosIntegrationUsingPOST(payload: LightspeedPosIntegrationCreatePayload, options: any = {}): FetchArgs {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createLightspeedPosIntegrationUsingPOST.');
            }
            const localVarPath = `/v1/lightspeed-pos-integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"LightspeedPosIntegrationCreatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(payload || {}) : (payload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteLightspeedPosIntegrationById
         * @param {string} lightspeedPosIntegrationId lightspeedPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLightspeedPosIntegrationByIdUsingDELETE(lightspeedPosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'lightspeedPosIntegrationId' is not null or undefined
            if (lightspeedPosIntegrationId === null || lightspeedPosIntegrationId === undefined) {
                throw new RequiredError('lightspeedPosIntegrationId','Required parameter lightspeedPosIntegrationId was null or undefined when calling deleteLightspeedPosIntegrationByIdUsingDELETE.');
            }
            const localVarPath = `/v1/lightspeed-pos-integrations/{lightspeedPosIntegrationId}`
                .replace(`{${"lightspeedPosIntegrationId"}}`, encodeURIComponent(String(lightspeedPosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getBusinesses
         * @param {string} lightspeedPosIntegrationId lightspeedPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessesUsingGET(lightspeedPosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'lightspeedPosIntegrationId' is not null or undefined
            if (lightspeedPosIntegrationId === null || lightspeedPosIntegrationId === undefined) {
                throw new RequiredError('lightspeedPosIntegrationId','Required parameter lightspeedPosIntegrationId was null or undefined when calling getBusinessesUsingGET.');
            }
            const localVarPath = `/v1/lightspeed-pos-integrations/{lightspeedPosIntegrationId}/businesses`
                .replace(`{${"lightspeedPosIntegrationId"}}`, encodeURIComponent(String(lightspeedPosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getLightspeedPosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLightspeedPosIntegrationsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/lightspeed-pos-integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOAuthSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuthSettingsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/lightspeed-pos-integrations/oauth-settings`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayLightspeedPosIntegrationControllerApi - functional programming interface
 * @export
 */
export const GatewayLightspeedPosIntegrationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createLightspeedPosIntegration
         * @param {LightspeedPosIntegrationCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLightspeedPosIntegrationUsingPOST(payload: LightspeedPosIntegrationCreatePayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LightspeedPosIntegration> {
            const localVarFetchArgs = GatewayLightspeedPosIntegrationControllerApiFetchParamCreator(configuration).createLightspeedPosIntegrationUsingPOST(payload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteLightspeedPosIntegrationById
         * @param {string} lightspeedPosIntegrationId lightspeedPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLightspeedPosIntegrationByIdUsingDELETE(lightspeedPosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayLightspeedPosIntegrationControllerApiFetchParamCreator(configuration).deleteLightspeedPosIntegrationByIdUsingDELETE(lightspeedPosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getBusinesses
         * @param {string} lightspeedPosIntegrationId lightspeedPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessesUsingGET(lightspeedPosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LightspeedGetBusinessesResponse> {
            const localVarFetchArgs = GatewayLightspeedPosIntegrationControllerApiFetchParamCreator(configuration).getBusinessesUsingGET(lightspeedPosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getLightspeedPosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLightspeedPosIntegrationsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LightspeedPosIntegration>> {
            const localVarFetchArgs = GatewayLightspeedPosIntegrationControllerApiFetchParamCreator(configuration).getLightspeedPosIntegrationsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOAuthSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuthSettingsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiProps> {
            const localVarFetchArgs = GatewayLightspeedPosIntegrationControllerApiFetchParamCreator(configuration).getOAuthSettingsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayLightspeedPosIntegrationControllerApi - factory interface
 * @export
 */
export const GatewayLightspeedPosIntegrationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createLightspeedPosIntegration
         * @param {LightspeedPosIntegrationCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createLightspeedPosIntegrationUsingPOST(payload: LightspeedPosIntegrationCreatePayload, options?: any) {
            return GatewayLightspeedPosIntegrationControllerApiFp(configuration).createLightspeedPosIntegrationUsingPOST(payload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteLightspeedPosIntegrationById
         * @param {string} lightspeedPosIntegrationId lightspeedPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLightspeedPosIntegrationByIdUsingDELETE(lightspeedPosIntegrationId: string, options?: any) {
            return GatewayLightspeedPosIntegrationControllerApiFp(configuration).deleteLightspeedPosIntegrationByIdUsingDELETE(lightspeedPosIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getBusinesses
         * @param {string} lightspeedPosIntegrationId lightspeedPosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBusinessesUsingGET(lightspeedPosIntegrationId: string, options?: any) {
            return GatewayLightspeedPosIntegrationControllerApiFp(configuration).getBusinessesUsingGET(lightspeedPosIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getLightspeedPosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLightspeedPosIntegrationsUsingGET(options?: any) {
            return GatewayLightspeedPosIntegrationControllerApiFp(configuration).getLightspeedPosIntegrationsUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOAuthSettings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOAuthSettingsUsingGET(options?: any) {
            return GatewayLightspeedPosIntegrationControllerApiFp(configuration).getOAuthSettingsUsingGET(options)(fetch, basePath);
        },
    };
};

/**
 * GatewayLightspeedPosIntegrationControllerApi - object-oriented interface
 * @export
 * @class GatewayLightspeedPosIntegrationControllerApi
 * @extends {BaseAPI}
 */
export class GatewayLightspeedPosIntegrationControllerApi extends BaseAPI {
    /**
     * 
     * @summary createLightspeedPosIntegration
     * @param {LightspeedPosIntegrationCreatePayload} payload payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayLightspeedPosIntegrationControllerApi
     */
    public createLightspeedPosIntegrationUsingPOST(payload: LightspeedPosIntegrationCreatePayload, options?: any) {
        return GatewayLightspeedPosIntegrationControllerApiFp(this.configuration).createLightspeedPosIntegrationUsingPOST(payload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteLightspeedPosIntegrationById
     * @param {string} lightspeedPosIntegrationId lightspeedPosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayLightspeedPosIntegrationControllerApi
     */
    public deleteLightspeedPosIntegrationByIdUsingDELETE(lightspeedPosIntegrationId: string, options?: any) {
        return GatewayLightspeedPosIntegrationControllerApiFp(this.configuration).deleteLightspeedPosIntegrationByIdUsingDELETE(lightspeedPosIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getBusinesses
     * @param {string} lightspeedPosIntegrationId lightspeedPosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayLightspeedPosIntegrationControllerApi
     */
    public getBusinessesUsingGET(lightspeedPosIntegrationId: string, options?: any) {
        return GatewayLightspeedPosIntegrationControllerApiFp(this.configuration).getBusinessesUsingGET(lightspeedPosIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getLightspeedPosIntegrations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayLightspeedPosIntegrationControllerApi
     */
    public getLightspeedPosIntegrationsUsingGET(options?: any) {
        return GatewayLightspeedPosIntegrationControllerApiFp(this.configuration).getLightspeedPosIntegrationsUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOAuthSettings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayLightspeedPosIntegrationControllerApi
     */
    public getOAuthSettingsUsingGET(options?: any) {
        return GatewayLightspeedPosIntegrationControllerApiFp(this.configuration).getOAuthSettingsUsingGET(options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayNewbridgePosIntegrationControllerApi - fetch parameter creator
 * @export
 */
export const GatewayNewbridgePosIntegrationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createNewbridgePosIntegration
         * @param {NewbridgePosIntegrationCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewbridgePosIntegrationUsingPOST(payload: NewbridgePosIntegrationCreatePayload, options: any = {}): FetchArgs {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createNewbridgePosIntegrationUsingPOST.');
            }
            const localVarPath = `/v1/newbridge-pos-integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"NewbridgePosIntegrationCreatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(payload || {}) : (payload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteNewbridgePosIntegrationById
         * @param {string} newbridgePosIntegrationId newbridgePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNewbridgePosIntegrationByIdUsingDELETE(newbridgePosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'newbridgePosIntegrationId' is not null or undefined
            if (newbridgePosIntegrationId === null || newbridgePosIntegrationId === undefined) {
                throw new RequiredError('newbridgePosIntegrationId','Required parameter newbridgePosIntegrationId was null or undefined when calling deleteNewbridgePosIntegrationByIdUsingDELETE.');
            }
            const localVarPath = `/v1/newbridge-pos-integrations/{newbridgePosIntegrationId}`
                .replace(`{${"newbridgePosIntegrationId"}}`, encodeURIComponent(String(newbridgePosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getNewbridgePosIntegrationById
         * @param {string} newbridgePosIntegrationId newbridgePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewbridgePosIntegrationByIdUsingGET(newbridgePosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'newbridgePosIntegrationId' is not null or undefined
            if (newbridgePosIntegrationId === null || newbridgePosIntegrationId === undefined) {
                throw new RequiredError('newbridgePosIntegrationId','Required parameter newbridgePosIntegrationId was null or undefined when calling getNewbridgePosIntegrationByIdUsingGET.');
            }
            const localVarPath = `/v1/newbridge-pos-integrations/{newbridgePosIntegrationId}`
                .replace(`{${"newbridgePosIntegrationId"}}`, encodeURIComponent(String(newbridgePosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getNewbridgePosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewbridgePosIntegrationsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/newbridge-pos-integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayNewbridgePosIntegrationControllerApi - functional programming interface
 * @export
 */
export const GatewayNewbridgePosIntegrationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createNewbridgePosIntegration
         * @param {NewbridgePosIntegrationCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewbridgePosIntegrationUsingPOST(payload: NewbridgePosIntegrationCreatePayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NewbridgePosIntegration> {
            const localVarFetchArgs = GatewayNewbridgePosIntegrationControllerApiFetchParamCreator(configuration).createNewbridgePosIntegrationUsingPOST(payload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteNewbridgePosIntegrationById
         * @param {string} newbridgePosIntegrationId newbridgePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNewbridgePosIntegrationByIdUsingDELETE(newbridgePosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayNewbridgePosIntegrationControllerApiFetchParamCreator(configuration).deleteNewbridgePosIntegrationByIdUsingDELETE(newbridgePosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getNewbridgePosIntegrationById
         * @param {string} newbridgePosIntegrationId newbridgePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewbridgePosIntegrationByIdUsingGET(newbridgePosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<NewbridgePosIntegration> {
            const localVarFetchArgs = GatewayNewbridgePosIntegrationControllerApiFetchParamCreator(configuration).getNewbridgePosIntegrationByIdUsingGET(newbridgePosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getNewbridgePosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewbridgePosIntegrationsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<NewbridgePosIntegration>> {
            const localVarFetchArgs = GatewayNewbridgePosIntegrationControllerApiFetchParamCreator(configuration).getNewbridgePosIntegrationsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayNewbridgePosIntegrationControllerApi - factory interface
 * @export
 */
export const GatewayNewbridgePosIntegrationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createNewbridgePosIntegration
         * @param {NewbridgePosIntegrationCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNewbridgePosIntegrationUsingPOST(payload: NewbridgePosIntegrationCreatePayload, options?: any) {
            return GatewayNewbridgePosIntegrationControllerApiFp(configuration).createNewbridgePosIntegrationUsingPOST(payload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteNewbridgePosIntegrationById
         * @param {string} newbridgePosIntegrationId newbridgePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteNewbridgePosIntegrationByIdUsingDELETE(newbridgePosIntegrationId: string, options?: any) {
            return GatewayNewbridgePosIntegrationControllerApiFp(configuration).deleteNewbridgePosIntegrationByIdUsingDELETE(newbridgePosIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getNewbridgePosIntegrationById
         * @param {string} newbridgePosIntegrationId newbridgePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewbridgePosIntegrationByIdUsingGET(newbridgePosIntegrationId: string, options?: any) {
            return GatewayNewbridgePosIntegrationControllerApiFp(configuration).getNewbridgePosIntegrationByIdUsingGET(newbridgePosIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getNewbridgePosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewbridgePosIntegrationsUsingGET(options?: any) {
            return GatewayNewbridgePosIntegrationControllerApiFp(configuration).getNewbridgePosIntegrationsUsingGET(options)(fetch, basePath);
        },
    };
};

/**
 * GatewayNewbridgePosIntegrationControllerApi - object-oriented interface
 * @export
 * @class GatewayNewbridgePosIntegrationControllerApi
 * @extends {BaseAPI}
 */
export class GatewayNewbridgePosIntegrationControllerApi extends BaseAPI {
    /**
     * 
     * @summary createNewbridgePosIntegration
     * @param {NewbridgePosIntegrationCreatePayload} payload payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayNewbridgePosIntegrationControllerApi
     */
    public createNewbridgePosIntegrationUsingPOST(payload: NewbridgePosIntegrationCreatePayload, options?: any) {
        return GatewayNewbridgePosIntegrationControllerApiFp(this.configuration).createNewbridgePosIntegrationUsingPOST(payload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteNewbridgePosIntegrationById
     * @param {string} newbridgePosIntegrationId newbridgePosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayNewbridgePosIntegrationControllerApi
     */
    public deleteNewbridgePosIntegrationByIdUsingDELETE(newbridgePosIntegrationId: string, options?: any) {
        return GatewayNewbridgePosIntegrationControllerApiFp(this.configuration).deleteNewbridgePosIntegrationByIdUsingDELETE(newbridgePosIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getNewbridgePosIntegrationById
     * @param {string} newbridgePosIntegrationId newbridgePosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayNewbridgePosIntegrationControllerApi
     */
    public getNewbridgePosIntegrationByIdUsingGET(newbridgePosIntegrationId: string, options?: any) {
        return GatewayNewbridgePosIntegrationControllerApiFp(this.configuration).getNewbridgePosIntegrationByIdUsingGET(newbridgePosIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getNewbridgePosIntegrations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayNewbridgePosIntegrationControllerApi
     */
    public getNewbridgePosIntegrationsUsingGET(options?: any) {
        return GatewayNewbridgePosIntegrationControllerApiFp(this.configuration).getNewbridgePosIntegrationsUsingGET(options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayNotificationControllerApi - fetch parameter creator
 * @export
 */
export const GatewayNotificationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createTicketForSupport
         * @param {any} [file] 
         * @param {string} [subject] 
         * @param {string} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketForSupportUsingPOST(file?: any, subject?: string, message?: string, options: any = {}): FetchArgs {
            
            const localVarPath = `/v1/notifications/ticket-support`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (file !== undefined) {
                localVarFormParams.set('file', file as any);
            }

            if (subject !== undefined) {
                localVarFormParams.set('subject', subject as any);
            }

            if (message !== undefined) {
                localVarFormParams.set('message', message as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayNotificationControllerApi - functional programming interface
 * @export
 */
export const GatewayNotificationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createTicketForSupport
         * @param {any} [file] 
         * @param {string} [subject] 
         * @param {string} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketForSupportUsingPOST(file?: any, subject?: string, message?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TicketResponse> {
            const localVarFetchArgs = GatewayNotificationControllerApiFetchParamCreator(configuration).createTicketForSupportUsingPOST(file, subject, message, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayNotificationControllerApi - factory interface
 * @export
 */
export const GatewayNotificationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createTicketForSupport
         * @param {any} [file] 
         * @param {string} [subject] 
         * @param {string} [message] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createTicketForSupportUsingPOST(file?: any, subject?: string, message?: string, options?: any) {
            return GatewayNotificationControllerApiFp(configuration).createTicketForSupportUsingPOST(file, subject, message, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayNotificationControllerApi - object-oriented interface
 * @export
 * @class GatewayNotificationControllerApi
 * @extends {BaseAPI}
 */
export class GatewayNotificationControllerApi extends BaseAPI {
    /**
     * 
     * @summary createTicketForSupport
     * @param {any} [file] 
     * @param {string} [subject] 
     * @param {string} [message] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayNotificationControllerApi
     */
    public createTicketForSupportUsingPOST(file?: any, subject?: string, message?: string, options?: any) {
        return GatewayNotificationControllerApiFp(this.configuration).createTicketForSupportUsingPOST(file, subject, message, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayOrderControllerApi - fetch parameter creator
 * @export
 */
export const GatewayOrderControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary approveOrder
         * @param {ApproveOrderDto} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveOrderUsingPOST(body: ApproveOrderDto, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling approveOrderUsingPOST.');
            }
            const localVarPath = `/v1/orders/approve`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ApproveOrderDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary cancelOrder
         * @param {string} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrderUsingPOST(orderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling cancelOrderUsingPOST.');
            }
            const localVarPath = `/v1/orders/{orderId}/cancel`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createOrderFromEmail
         * @param {OrderContractFitDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderFromEmailUsingPOST(body: OrderContractFitDTO, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling createOrderFromEmailUsingPOST.');
            }
            const localVarPath = `/v1/orders/from-email`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderContractFitDTO" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createOrder
         * @param {Order} order order
         * @param {boolean} [skipSendingEmail] skipSendingEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderUsingPOST(order: Order, skipSendingEmail?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'order' is not null or undefined
            if (order === null || order === undefined) {
                throw new RequiredError('order','Required parameter order was null or undefined when calling createOrderUsingPOST.');
            }
            const localVarPath = `/v1/orders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skipSendingEmail !== undefined) {
                localVarQueryParameter['skipSendingEmail'] = skipSendingEmail;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Order" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(order || {}) : (order || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteOrder
         * @param {string} orderId orderId
         * @param {boolean} [hardDelete] hardDelete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderUsingDELETE(orderId: string, hardDelete?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling deleteOrderUsingDELETE.');
            }
            const localVarPath = `/v1/orders/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (hardDelete !== undefined) {
                localVarQueryParameter['hardDelete'] = hardDelete;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOrderByPO
         * @param {string} po po
         * @param {string} supplierId supplierId
         * @param {boolean} [includeApprovers] includeApprovers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderByPOUsingGET(po: string, supplierId: string, includeApprovers?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'po' is not null or undefined
            if (po === null || po === undefined) {
                throw new RequiredError('po','Required parameter po was null or undefined when calling getOrderByPOUsingGET.');
            }
            // verify required parameter 'supplierId' is not null or undefined
            if (supplierId === null || supplierId === undefined) {
                throw new RequiredError('supplierId','Required parameter supplierId was null or undefined when calling getOrderByPOUsingGET.');
            }
            const localVarPath = `/v1/orders/po-number/{po}`
                .replace(`{${"po"}}`, encodeURIComponent(String(po)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeApprovers !== undefined) {
                localVarQueryParameter['includeApprovers'] = includeApprovers;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOrderDiscrepancies
         * @param {string} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderDiscrepanciesUsingGET(orderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling getOrderDiscrepanciesUsingGET.');
            }
            const localVarPath = `/v1/orders/{orderId}/discrepancies`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOrder
         * @param {string} orderId orderId
         * @param {boolean} [includeApprovers] includeApprovers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderUsingGET(orderId: string, includeApprovers?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling getOrderUsingGET.');
            }
            const localVarPath = `/v1/orders/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeApprovers !== undefined) {
                localVarQueryParameter['includeApprovers'] = includeApprovers;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOrdersByCriteria
         * @param {boolean} [includeApprovers] includeApprovers
         * @param {boolean} [includeIsDelivered] includeIsDelivered
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {string} [partialPONumber] partialPONumber
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'CANCELED' | 'COMPLETED' | 'DRAFT' | 'PENDING_APPROVAL' | 'PLACED' | 'REVIEW'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersByCriteriaUsingGET(includeApprovers?: boolean, includeIsDelivered?: boolean, organizations?: Array<string>, page?: number, partialPONumber?: string, size?: number, sort?: string, status?: 'CANCELED' | 'COMPLETED' | 'DRAFT' | 'PENDING_APPROVAL' | 'PLACED' | 'REVIEW', supplierId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/orders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (includeApprovers !== undefined) {
                localVarQueryParameter['includeApprovers'] = includeApprovers;
            }

            if (includeIsDelivered !== undefined) {
                localVarQueryParameter['includeIsDelivered'] = includeIsDelivered;
            }

            if (organizations) {
                localVarQueryParameter['organizations'] = organizations;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialPONumber !== undefined) {
                localVarQueryParameter['partialPONumber'] = partialPONumber;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateOrderByPO
         * @param {OrderPatchDto} orderPatchDto orderPatchDto
         * @param {string} po po
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateOrderByPOUsingPATCH(orderPatchDto: OrderPatchDto, po: string, supplierId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderPatchDto' is not null or undefined
            if (orderPatchDto === null || orderPatchDto === undefined) {
                throw new RequiredError('orderPatchDto','Required parameter orderPatchDto was null or undefined when calling partialUpdateOrderByPOUsingPATCH.');
            }
            // verify required parameter 'po' is not null or undefined
            if (po === null || po === undefined) {
                throw new RequiredError('po','Required parameter po was null or undefined when calling partialUpdateOrderByPOUsingPATCH.');
            }
            // verify required parameter 'supplierId' is not null or undefined
            if (supplierId === null || supplierId === undefined) {
                throw new RequiredError('supplierId','Required parameter supplierId was null or undefined when calling partialUpdateOrderByPOUsingPATCH.');
            }
            const localVarPath = `/v1/orders/po-number/{po}`
                .replace(`{${"po"}}`, encodeURIComponent(String(po)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderPatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(orderPatchDto || {}) : (orderPatchDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resendOrder
         * @param {string} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendOrderUsingPOST(orderId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling resendOrderUsingPOST.');
            }
            const localVarPath = `/v1/orders/{orderId}/resend`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateOrderDiscrepancy
         * @param {string} discrepancyId discrepancyId
         * @param {string} orderId orderId
         * @param {OrderDiscrepancyPatchDto} patch patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderDiscrepancyUsingPATCH(discrepancyId: string, orderId: string, patch: OrderDiscrepancyPatchDto, options: any = {}): FetchArgs {
            // verify required parameter 'discrepancyId' is not null or undefined
            if (discrepancyId === null || discrepancyId === undefined) {
                throw new RequiredError('discrepancyId','Required parameter discrepancyId was null or undefined when calling updateOrderDiscrepancyUsingPATCH.');
            }
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling updateOrderDiscrepancyUsingPATCH.');
            }
            // verify required parameter 'patch' is not null or undefined
            if (patch === null || patch === undefined) {
                throw new RequiredError('patch','Required parameter patch was null or undefined when calling updateOrderDiscrepancyUsingPATCH.');
            }
            const localVarPath = `/v1/orders/{orderId}/discrepancies/{discrepancyId}`
                .replace(`{${"discrepancyId"}}`, encodeURIComponent(String(discrepancyId)))
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrderDiscrepancyPatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(patch || {}) : (patch || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateOrder
         * @param {Order} order order
         * @param {string} orderId orderId
         * @param {boolean} [skipSendingEmail] skipSendingEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderUsingPUT(order: Order, orderId: string, skipSendingEmail?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'order' is not null or undefined
            if (order === null || order === undefined) {
                throw new RequiredError('order','Required parameter order was null or undefined when calling updateOrderUsingPUT.');
            }
            // verify required parameter 'orderId' is not null or undefined
            if (orderId === null || orderId === undefined) {
                throw new RequiredError('orderId','Required parameter orderId was null or undefined when calling updateOrderUsingPUT.');
            }
            const localVarPath = `/v1/orders/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (skipSendingEmail !== undefined) {
                localVarQueryParameter['skipSendingEmail'] = skipSendingEmail;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Order" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(order || {}) : (order || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayOrderControllerApi - functional programming interface
 * @export
 */
export const GatewayOrderControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary approveOrder
         * @param {ApproveOrderDto} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveOrderUsingPOST(body: ApproveOrderDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderForApproval> {
            const localVarFetchArgs = GatewayOrderControllerApiFetchParamCreator(configuration).approveOrderUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary cancelOrder
         * @param {string} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrderUsingPOST(orderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayOrderControllerApiFetchParamCreator(configuration).cancelOrderUsingPOST(orderId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createOrderFromEmail
         * @param {OrderContractFitDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderFromEmailUsingPOST(body: OrderContractFitDTO, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayOrderControllerApiFetchParamCreator(configuration).createOrderFromEmailUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createOrder
         * @param {Order} order order
         * @param {boolean} [skipSendingEmail] skipSendingEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderUsingPOST(order: Order, skipSendingEmail?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Order> {
            const localVarFetchArgs = GatewayOrderControllerApiFetchParamCreator(configuration).createOrderUsingPOST(order, skipSendingEmail, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteOrder
         * @param {string} orderId orderId
         * @param {boolean} [hardDelete] hardDelete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderUsingDELETE(orderId: string, hardDelete?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayOrderControllerApiFetchParamCreator(configuration).deleteOrderUsingDELETE(orderId, hardDelete, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOrderByPO
         * @param {string} po po
         * @param {string} supplierId supplierId
         * @param {boolean} [includeApprovers] includeApprovers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderByPOUsingGET(po: string, supplierId: string, includeApprovers?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponseDto> {
            const localVarFetchArgs = GatewayOrderControllerApiFetchParamCreator(configuration).getOrderByPOUsingGET(po, supplierId, includeApprovers, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOrderDiscrepancies
         * @param {string} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderDiscrepanciesUsingGET(orderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderDiscrepanciesResponseDto> {
            const localVarFetchArgs = GatewayOrderControllerApiFetchParamCreator(configuration).getOrderDiscrepanciesUsingGET(orderId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOrder
         * @param {string} orderId orderId
         * @param {boolean} [includeApprovers] includeApprovers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderUsingGET(orderId: string, includeApprovers?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OrderResponseDto> {
            const localVarFetchArgs = GatewayOrderControllerApiFetchParamCreator(configuration).getOrderUsingGET(orderId, includeApprovers, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOrdersByCriteria
         * @param {boolean} [includeApprovers] includeApprovers
         * @param {boolean} [includeIsDelivered] includeIsDelivered
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {string} [partialPONumber] partialPONumber
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'CANCELED' | 'COMPLETED' | 'DRAFT' | 'PENDING_APPROVAL' | 'PLACED' | 'REVIEW'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersByCriteriaUsingGET(includeApprovers?: boolean, includeIsDelivered?: boolean, organizations?: Array<string>, page?: number, partialPONumber?: string, size?: number, sort?: string, status?: 'CANCELED' | 'COMPLETED' | 'DRAFT' | 'PENDING_APPROVAL' | 'PLACED' | 'REVIEW', supplierId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageOrdersResponseDto> {
            const localVarFetchArgs = GatewayOrderControllerApiFetchParamCreator(configuration).getOrdersByCriteriaUsingGET(includeApprovers, includeIsDelivered, organizations, page, partialPONumber, size, sort, status, supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary partialUpdateOrderByPO
         * @param {OrderPatchDto} orderPatchDto orderPatchDto
         * @param {string} po po
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateOrderByPOUsingPATCH(orderPatchDto: OrderPatchDto, po: string, supplierId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Order> {
            const localVarFetchArgs = GatewayOrderControllerApiFetchParamCreator(configuration).partialUpdateOrderByPOUsingPATCH(orderPatchDto, po, supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary resendOrder
         * @param {string} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendOrderUsingPOST(orderId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Order> {
            const localVarFetchArgs = GatewayOrderControllerApiFetchParamCreator(configuration).resendOrderUsingPOST(orderId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateOrderDiscrepancy
         * @param {string} discrepancyId discrepancyId
         * @param {string} orderId orderId
         * @param {OrderDiscrepancyPatchDto} patch patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderDiscrepancyUsingPATCH(discrepancyId: string, orderId: string, patch: OrderDiscrepancyPatchDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayOrderControllerApiFetchParamCreator(configuration).updateOrderDiscrepancyUsingPATCH(discrepancyId, orderId, patch, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateOrder
         * @param {Order} order order
         * @param {string} orderId orderId
         * @param {boolean} [skipSendingEmail] skipSendingEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderUsingPUT(order: Order, orderId: string, skipSendingEmail?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Order> {
            const localVarFetchArgs = GatewayOrderControllerApiFetchParamCreator(configuration).updateOrderUsingPUT(order, orderId, skipSendingEmail, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayOrderControllerApi - factory interface
 * @export
 */
export const GatewayOrderControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary approveOrder
         * @param {ApproveOrderDto} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        approveOrderUsingPOST(body: ApproveOrderDto, options?: any) {
            return GatewayOrderControllerApiFp(configuration).approveOrderUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary cancelOrder
         * @param {string} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrderUsingPOST(orderId: string, options?: any) {
            return GatewayOrderControllerApiFp(configuration).cancelOrderUsingPOST(orderId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createOrderFromEmail
         * @param {OrderContractFitDTO} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderFromEmailUsingPOST(body: OrderContractFitDTO, options?: any) {
            return GatewayOrderControllerApiFp(configuration).createOrderFromEmailUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createOrder
         * @param {Order} order order
         * @param {boolean} [skipSendingEmail] skipSendingEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrderUsingPOST(order: Order, skipSendingEmail?: boolean, options?: any) {
            return GatewayOrderControllerApiFp(configuration).createOrderUsingPOST(order, skipSendingEmail, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteOrder
         * @param {string} orderId orderId
         * @param {boolean} [hardDelete] hardDelete
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrderUsingDELETE(orderId: string, hardDelete?: boolean, options?: any) {
            return GatewayOrderControllerApiFp(configuration).deleteOrderUsingDELETE(orderId, hardDelete, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOrderByPO
         * @param {string} po po
         * @param {string} supplierId supplierId
         * @param {boolean} [includeApprovers] includeApprovers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderByPOUsingGET(po: string, supplierId: string, includeApprovers?: boolean, options?: any) {
            return GatewayOrderControllerApiFp(configuration).getOrderByPOUsingGET(po, supplierId, includeApprovers, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOrderDiscrepancies
         * @param {string} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderDiscrepanciesUsingGET(orderId: string, options?: any) {
            return GatewayOrderControllerApiFp(configuration).getOrderDiscrepanciesUsingGET(orderId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOrder
         * @param {string} orderId orderId
         * @param {boolean} [includeApprovers] includeApprovers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrderUsingGET(orderId: string, includeApprovers?: boolean, options?: any) {
            return GatewayOrderControllerApiFp(configuration).getOrderUsingGET(orderId, includeApprovers, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOrdersByCriteria
         * @param {boolean} [includeApprovers] includeApprovers
         * @param {boolean} [includeIsDelivered] includeIsDelivered
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {string} [partialPONumber] partialPONumber
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'CANCELED' | 'COMPLETED' | 'DRAFT' | 'PENDING_APPROVAL' | 'PLACED' | 'REVIEW'} [status] status
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrdersByCriteriaUsingGET(includeApprovers?: boolean, includeIsDelivered?: boolean, organizations?: Array<string>, page?: number, partialPONumber?: string, size?: number, sort?: string, status?: 'CANCELED' | 'COMPLETED' | 'DRAFT' | 'PENDING_APPROVAL' | 'PLACED' | 'REVIEW', supplierId?: string, options?: any) {
            return GatewayOrderControllerApiFp(configuration).getOrdersByCriteriaUsingGET(includeApprovers, includeIsDelivered, organizations, page, partialPONumber, size, sort, status, supplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary partialUpdateOrderByPO
         * @param {OrderPatchDto} orderPatchDto orderPatchDto
         * @param {string} po po
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateOrderByPOUsingPATCH(orderPatchDto: OrderPatchDto, po: string, supplierId: string, options?: any) {
            return GatewayOrderControllerApiFp(configuration).partialUpdateOrderByPOUsingPATCH(orderPatchDto, po, supplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary resendOrder
         * @param {string} orderId orderId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendOrderUsingPOST(orderId: string, options?: any) {
            return GatewayOrderControllerApiFp(configuration).resendOrderUsingPOST(orderId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateOrderDiscrepancy
         * @param {string} discrepancyId discrepancyId
         * @param {string} orderId orderId
         * @param {OrderDiscrepancyPatchDto} patch patch
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderDiscrepancyUsingPATCH(discrepancyId: string, orderId: string, patch: OrderDiscrepancyPatchDto, options?: any) {
            return GatewayOrderControllerApiFp(configuration).updateOrderDiscrepancyUsingPATCH(discrepancyId, orderId, patch, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateOrder
         * @param {Order} order order
         * @param {string} orderId orderId
         * @param {boolean} [skipSendingEmail] skipSendingEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrderUsingPUT(order: Order, orderId: string, skipSendingEmail?: boolean, options?: any) {
            return GatewayOrderControllerApiFp(configuration).updateOrderUsingPUT(order, orderId, skipSendingEmail, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayOrderControllerApi - object-oriented interface
 * @export
 * @class GatewayOrderControllerApi
 * @extends {BaseAPI}
 */
export class GatewayOrderControllerApi extends BaseAPI {
    /**
     * 
     * @summary approveOrder
     * @param {ApproveOrderDto} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrderControllerApi
     */
    public approveOrderUsingPOST(body: ApproveOrderDto, options?: any) {
        return GatewayOrderControllerApiFp(this.configuration).approveOrderUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary cancelOrder
     * @param {string} orderId orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrderControllerApi
     */
    public cancelOrderUsingPOST(orderId: string, options?: any) {
        return GatewayOrderControllerApiFp(this.configuration).cancelOrderUsingPOST(orderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createOrderFromEmail
     * @param {OrderContractFitDTO} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrderControllerApi
     */
    public createOrderFromEmailUsingPOST(body: OrderContractFitDTO, options?: any) {
        return GatewayOrderControllerApiFp(this.configuration).createOrderFromEmailUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createOrder
     * @param {Order} order order
     * @param {boolean} [skipSendingEmail] skipSendingEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrderControllerApi
     */
    public createOrderUsingPOST(order: Order, skipSendingEmail?: boolean, options?: any) {
        return GatewayOrderControllerApiFp(this.configuration).createOrderUsingPOST(order, skipSendingEmail, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteOrder
     * @param {string} orderId orderId
     * @param {boolean} [hardDelete] hardDelete
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrderControllerApi
     */
    public deleteOrderUsingDELETE(orderId: string, hardDelete?: boolean, options?: any) {
        return GatewayOrderControllerApiFp(this.configuration).deleteOrderUsingDELETE(orderId, hardDelete, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOrderByPO
     * @param {string} po po
     * @param {string} supplierId supplierId
     * @param {boolean} [includeApprovers] includeApprovers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrderControllerApi
     */
    public getOrderByPOUsingGET(po: string, supplierId: string, includeApprovers?: boolean, options?: any) {
        return GatewayOrderControllerApiFp(this.configuration).getOrderByPOUsingGET(po, supplierId, includeApprovers, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOrderDiscrepancies
     * @param {string} orderId orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrderControllerApi
     */
    public getOrderDiscrepanciesUsingGET(orderId: string, options?: any) {
        return GatewayOrderControllerApiFp(this.configuration).getOrderDiscrepanciesUsingGET(orderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOrder
     * @param {string} orderId orderId
     * @param {boolean} [includeApprovers] includeApprovers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrderControllerApi
     */
    public getOrderUsingGET(orderId: string, includeApprovers?: boolean, options?: any) {
        return GatewayOrderControllerApiFp(this.configuration).getOrderUsingGET(orderId, includeApprovers, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOrdersByCriteria
     * @param {boolean} [includeApprovers] includeApprovers
     * @param {boolean} [includeIsDelivered] includeIsDelivered
     * @param {Array<string>} [organizations] organizations
     * @param {number} [page] 
     * @param {string} [partialPONumber] partialPONumber
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {'CANCELED' | 'COMPLETED' | 'DRAFT' | 'PENDING_APPROVAL' | 'PLACED' | 'REVIEW'} [status] status
     * @param {string} [supplierId] supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrderControllerApi
     */
    public getOrdersByCriteriaUsingGET(includeApprovers?: boolean, includeIsDelivered?: boolean, organizations?: Array<string>, page?: number, partialPONumber?: string, size?: number, sort?: string, status?: 'CANCELED' | 'COMPLETED' | 'DRAFT' | 'PENDING_APPROVAL' | 'PLACED' | 'REVIEW', supplierId?: string, options?: any) {
        return GatewayOrderControllerApiFp(this.configuration).getOrdersByCriteriaUsingGET(includeApprovers, includeIsDelivered, organizations, page, partialPONumber, size, sort, status, supplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary partialUpdateOrderByPO
     * @param {OrderPatchDto} orderPatchDto orderPatchDto
     * @param {string} po po
     * @param {string} supplierId supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrderControllerApi
     */
    public partialUpdateOrderByPOUsingPATCH(orderPatchDto: OrderPatchDto, po: string, supplierId: string, options?: any) {
        return GatewayOrderControllerApiFp(this.configuration).partialUpdateOrderByPOUsingPATCH(orderPatchDto, po, supplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary resendOrder
     * @param {string} orderId orderId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrderControllerApi
     */
    public resendOrderUsingPOST(orderId: string, options?: any) {
        return GatewayOrderControllerApiFp(this.configuration).resendOrderUsingPOST(orderId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateOrderDiscrepancy
     * @param {string} discrepancyId discrepancyId
     * @param {string} orderId orderId
     * @param {OrderDiscrepancyPatchDto} patch patch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrderControllerApi
     */
    public updateOrderDiscrepancyUsingPATCH(discrepancyId: string, orderId: string, patch: OrderDiscrepancyPatchDto, options?: any) {
        return GatewayOrderControllerApiFp(this.configuration).updateOrderDiscrepancyUsingPATCH(discrepancyId, orderId, patch, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateOrder
     * @param {Order} order order
     * @param {string} orderId orderId
     * @param {boolean} [skipSendingEmail] skipSendingEmail
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrderControllerApi
     */
    public updateOrderUsingPUT(order: Order, orderId: string, skipSendingEmail?: boolean, options?: any) {
        return GatewayOrderControllerApiFp(this.configuration).updateOrderUsingPUT(order, orderId, skipSendingEmail, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayOrganizationControllerApi - fetch parameter creator
 * @export
 */
export const GatewayOrganizationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createOrganization
         * @param {Organization} organization organization
         * @param {string} roleOfUser roleOfUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationUsingPOST(organization: Organization, roleOfUser: string, options: any = {}): FetchArgs {
            // verify required parameter 'organization' is not null or undefined
            if (organization === null || organization === undefined) {
                throw new RequiredError('organization','Required parameter organization was null or undefined when calling createOrganizationUsingPOST.');
            }
            // verify required parameter 'roleOfUser' is not null or undefined
            if (roleOfUser === null || roleOfUser === undefined) {
                throw new RequiredError('roleOfUser','Required parameter roleOfUser was null or undefined when calling createOrganizationUsingPOST.');
            }
            const localVarPath = `/v1/organizations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (roleOfUser !== undefined) {
                localVarQueryParameter['roleOfUser'] = roleOfUser;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Organization" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(organization || {}) : (organization || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createSubOrganization
         * @param {Organization} organization organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubOrganizationUsingPOST(organization: Organization, options: any = {}): FetchArgs {
            // verify required parameter 'organization' is not null or undefined
            if (organization === null || organization === undefined) {
                throw new RequiredError('organization','Required parameter organization was null or undefined when calling createSubOrganizationUsingPOST.');
            }
            const localVarPath = `/v1/organizations/sub`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Organization" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(organization || {}) : (organization || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteMyOrganization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyOrganizationUsingDELETE(options: any = {}): FetchArgs {
            const localVarPath = `/v1/organizations/my`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteOrganizationById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationByIdUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOrganizationByIdUsingDELETE.');
            }
            const localVarPath = `/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportUsersOfOrganizationsToXlsx
         * @param {string} [partialOrgName] partialOrgName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUsersOfOrganizationsToXlsxUsingGET(partialOrgName?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/organizations/users/export-as-xlsx`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partialOrgName !== undefined) {
                localVarQueryParameter['partialOrgName'] = partialOrgName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllOrganizations
         * @param {number} [page] 
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganizationsUsingGET(page?: number, partialName?: string, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/organizations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialName !== undefined) {
                localVarQueryParameter['partialName'] = partialName;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getMyOrganizationConnections
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOrganizationConnectionsUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/organizations/my/connections`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getMyOrganization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOrganizationUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/organizations/my`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOrganizationById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationByIdUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOrganizationByIdUsingGET.');
            }
            const localVarPath = `/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateMyOrganization
         * @param {OrganizationPatchDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateMyOrganizationUsingPATCH(dto: OrganizationPatchDto, options: any = {}): FetchArgs {
            // verify required parameter 'dto' is not null or undefined
            if (dto === null || dto === undefined) {
                throw new RequiredError('dto','Required parameter dto was null or undefined when calling partialUpdateMyOrganizationUsingPATCH.');
            }
            const localVarPath = `/v1/organizations/my`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrganizationPatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dto || {}) : (dto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary partialUpdateOrganization
         * @param {OrganizationPatchDto} dto dto
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateOrganizationUsingPATCH(dto: OrganizationPatchDto, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'dto' is not null or undefined
            if (dto === null || dto === undefined) {
                throw new RequiredError('dto','Required parameter dto was null or undefined when calling partialUpdateOrganizationUsingPATCH.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling partialUpdateOrganizationUsingPATCH.');
            }
            const localVarPath = `/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OrganizationPatchDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(dto || {}) : (dto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateMyOrganization
         * @param {Organization} organization organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyOrganizationUsingPUT(organization: Organization, options: any = {}): FetchArgs {
            // verify required parameter 'organization' is not null or undefined
            if (organization === null || organization === undefined) {
                throw new RequiredError('organization','Required parameter organization was null or undefined when calling updateMyOrganizationUsingPUT.');
            }
            const localVarPath = `/v1/organizations/my`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Organization" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(organization || {}) : (organization || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateOrganization
         * @param {string} id id
         * @param {Organization} organization organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationUsingPUT(id: string, organization: Organization, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOrganizationUsingPUT.');
            }
            // verify required parameter 'organization' is not null or undefined
            if (organization === null || organization === undefined) {
                throw new RequiredError('organization','Required parameter organization was null or undefined when calling updateOrganizationUsingPUT.');
            }
            const localVarPath = `/v1/organizations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Organization" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(organization || {}) : (organization || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayOrganizationControllerApi - functional programming interface
 * @export
 */
export const GatewayOrganizationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createOrganization
         * @param {Organization} organization organization
         * @param {string} roleOfUser roleOfUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationUsingPOST(organization: Organization, roleOfUser: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = GatewayOrganizationControllerApiFetchParamCreator(configuration).createOrganizationUsingPOST(organization, roleOfUser, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createSubOrganization
         * @param {Organization} organization organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubOrganizationUsingPOST(organization: Organization, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = GatewayOrganizationControllerApiFetchParamCreator(configuration).createSubOrganizationUsingPOST(organization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteMyOrganization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyOrganizationUsingDELETE(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayOrganizationControllerApiFetchParamCreator(configuration).deleteMyOrganizationUsingDELETE(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteOrganizationById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationByIdUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayOrganizationControllerApiFetchParamCreator(configuration).deleteOrganizationByIdUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportUsersOfOrganizationsToXlsx
         * @param {string} [partialOrgName] partialOrgName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUsersOfOrganizationsToXlsxUsingGET(partialOrgName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayOrganizationControllerApiFetchParamCreator(configuration).exportUsersOfOrganizationsToXlsxUsingGET(partialOrgName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllOrganizations
         * @param {number} [page] 
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganizationsUsingGET(page?: number, partialName?: string, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageOrganization> {
            const localVarFetchArgs = GatewayOrganizationControllerApiFetchParamCreator(configuration).getAllOrganizationsUsingGET(page, partialName, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getMyOrganizationConnections
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOrganizationConnectionsUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageOrganizationDto> {
            const localVarFetchArgs = GatewayOrganizationControllerApiFetchParamCreator(configuration).getMyOrganizationConnectionsUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getMyOrganization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOrganizationUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = GatewayOrganizationControllerApiFetchParamCreator(configuration).getMyOrganizationUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOrganizationById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationByIdUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = GatewayOrganizationControllerApiFetchParamCreator(configuration).getOrganizationByIdUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary partialUpdateMyOrganization
         * @param {OrganizationPatchDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateMyOrganizationUsingPATCH(dto: OrganizationPatchDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = GatewayOrganizationControllerApiFetchParamCreator(configuration).partialUpdateMyOrganizationUsingPATCH(dto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary partialUpdateOrganization
         * @param {OrganizationPatchDto} dto dto
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateOrganizationUsingPATCH(dto: OrganizationPatchDto, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = GatewayOrganizationControllerApiFetchParamCreator(configuration).partialUpdateOrganizationUsingPATCH(dto, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateMyOrganization
         * @param {Organization} organization organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyOrganizationUsingPUT(organization: Organization, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = GatewayOrganizationControllerApiFetchParamCreator(configuration).updateMyOrganizationUsingPUT(organization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateOrganization
         * @param {string} id id
         * @param {Organization} organization organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationUsingPUT(id: string, organization: Organization, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Organization> {
            const localVarFetchArgs = GatewayOrganizationControllerApiFetchParamCreator(configuration).updateOrganizationUsingPUT(id, organization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayOrganizationControllerApi - factory interface
 * @export
 */
export const GatewayOrganizationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createOrganization
         * @param {Organization} organization organization
         * @param {string} roleOfUser roleOfUser
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrganizationUsingPOST(organization: Organization, roleOfUser: string, options?: any) {
            return GatewayOrganizationControllerApiFp(configuration).createOrganizationUsingPOST(organization, roleOfUser, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createSubOrganization
         * @param {Organization} organization organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubOrganizationUsingPOST(organization: Organization, options?: any) {
            return GatewayOrganizationControllerApiFp(configuration).createSubOrganizationUsingPOST(organization, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteMyOrganization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyOrganizationUsingDELETE(options?: any) {
            return GatewayOrganizationControllerApiFp(configuration).deleteMyOrganizationUsingDELETE(options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteOrganizationById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrganizationByIdUsingDELETE(id: string, options?: any) {
            return GatewayOrganizationControllerApiFp(configuration).deleteOrganizationByIdUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportUsersOfOrganizationsToXlsx
         * @param {string} [partialOrgName] partialOrgName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportUsersOfOrganizationsToXlsxUsingGET(partialOrgName?: string, options?: any) {
            return GatewayOrganizationControllerApiFp(configuration).exportUsersOfOrganizationsToXlsxUsingGET(partialOrgName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllOrganizations
         * @param {number} [page] 
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllOrganizationsUsingGET(page?: number, partialName?: string, size?: number, sort?: string, options?: any) {
            return GatewayOrganizationControllerApiFp(configuration).getAllOrganizationsUsingGET(page, partialName, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getMyOrganizationConnections
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOrganizationConnectionsUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return GatewayOrganizationControllerApiFp(configuration).getMyOrganizationConnectionsUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getMyOrganization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOrganizationUsingGET(options?: any) {
            return GatewayOrganizationControllerApiFp(configuration).getMyOrganizationUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOrganizationById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrganizationByIdUsingGET(id: string, options?: any) {
            return GatewayOrganizationControllerApiFp(configuration).getOrganizationByIdUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary partialUpdateMyOrganization
         * @param {OrganizationPatchDto} dto dto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateMyOrganizationUsingPATCH(dto: OrganizationPatchDto, options?: any) {
            return GatewayOrganizationControllerApiFp(configuration).partialUpdateMyOrganizationUsingPATCH(dto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary partialUpdateOrganization
         * @param {OrganizationPatchDto} dto dto
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        partialUpdateOrganizationUsingPATCH(dto: OrganizationPatchDto, id: string, options?: any) {
            return GatewayOrganizationControllerApiFp(configuration).partialUpdateOrganizationUsingPATCH(dto, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateMyOrganization
         * @param {Organization} organization organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyOrganizationUsingPUT(organization: Organization, options?: any) {
            return GatewayOrganizationControllerApiFp(configuration).updateMyOrganizationUsingPUT(organization, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateOrganization
         * @param {string} id id
         * @param {Organization} organization organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrganizationUsingPUT(id: string, organization: Organization, options?: any) {
            return GatewayOrganizationControllerApiFp(configuration).updateOrganizationUsingPUT(id, organization, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayOrganizationControllerApi - object-oriented interface
 * @export
 * @class GatewayOrganizationControllerApi
 * @extends {BaseAPI}
 */
export class GatewayOrganizationControllerApi extends BaseAPI {
    /**
     * 
     * @summary createOrganization
     * @param {Organization} organization organization
     * @param {string} roleOfUser roleOfUser
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrganizationControllerApi
     */
    public createOrganizationUsingPOST(organization: Organization, roleOfUser: string, options?: any) {
        return GatewayOrganizationControllerApiFp(this.configuration).createOrganizationUsingPOST(organization, roleOfUser, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createSubOrganization
     * @param {Organization} organization organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrganizationControllerApi
     */
    public createSubOrganizationUsingPOST(organization: Organization, options?: any) {
        return GatewayOrganizationControllerApiFp(this.configuration).createSubOrganizationUsingPOST(organization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteMyOrganization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrganizationControllerApi
     */
    public deleteMyOrganizationUsingDELETE(options?: any) {
        return GatewayOrganizationControllerApiFp(this.configuration).deleteMyOrganizationUsingDELETE(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteOrganizationById
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrganizationControllerApi
     */
    public deleteOrganizationByIdUsingDELETE(id: string, options?: any) {
        return GatewayOrganizationControllerApiFp(this.configuration).deleteOrganizationByIdUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportUsersOfOrganizationsToXlsx
     * @param {string} [partialOrgName] partialOrgName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrganizationControllerApi
     */
    public exportUsersOfOrganizationsToXlsxUsingGET(partialOrgName?: string, options?: any) {
        return GatewayOrganizationControllerApiFp(this.configuration).exportUsersOfOrganizationsToXlsxUsingGET(partialOrgName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllOrganizations
     * @param {number} [page] 
     * @param {string} [partialName] partialName
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrganizationControllerApi
     */
    public getAllOrganizationsUsingGET(page?: number, partialName?: string, size?: number, sort?: string, options?: any) {
        return GatewayOrganizationControllerApiFp(this.configuration).getAllOrganizationsUsingGET(page, partialName, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getMyOrganizationConnections
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrganizationControllerApi
     */
    public getMyOrganizationConnectionsUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return GatewayOrganizationControllerApiFp(this.configuration).getMyOrganizationConnectionsUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getMyOrganization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrganizationControllerApi
     */
    public getMyOrganizationUsingGET(options?: any) {
        return GatewayOrganizationControllerApiFp(this.configuration).getMyOrganizationUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOrganizationById
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrganizationControllerApi
     */
    public getOrganizationByIdUsingGET(id: string, options?: any) {
        return GatewayOrganizationControllerApiFp(this.configuration).getOrganizationByIdUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary partialUpdateMyOrganization
     * @param {OrganizationPatchDto} dto dto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrganizationControllerApi
     */
    public partialUpdateMyOrganizationUsingPATCH(dto: OrganizationPatchDto, options?: any) {
        return GatewayOrganizationControllerApiFp(this.configuration).partialUpdateMyOrganizationUsingPATCH(dto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary partialUpdateOrganization
     * @param {OrganizationPatchDto} dto dto
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrganizationControllerApi
     */
    public partialUpdateOrganizationUsingPATCH(dto: OrganizationPatchDto, id: string, options?: any) {
        return GatewayOrganizationControllerApiFp(this.configuration).partialUpdateOrganizationUsingPATCH(dto, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateMyOrganization
     * @param {Organization} organization organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrganizationControllerApi
     */
    public updateMyOrganizationUsingPUT(organization: Organization, options?: any) {
        return GatewayOrganizationControllerApiFp(this.configuration).updateMyOrganizationUsingPUT(organization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateOrganization
     * @param {string} id id
     * @param {Organization} organization organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayOrganizationControllerApi
     */
    public updateOrganizationUsingPUT(id: string, organization: Organization, options?: any) {
        return GatewayOrganizationControllerApiFp(this.configuration).updateOrganizationUsingPUT(id, organization, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayPriceReportControllerApi - fetch parameter creator
 * @export
 */
export const GatewayPriceReportControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary exportPriceReportInPeriodOfTimeAsXlsx
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPriceReportInPeriodOfTimeAsXlsxUsingGET(from: Date, to: Date, options: any = {}): FetchArgs {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling exportPriceReportInPeriodOfTimeAsXlsxUsingGET.');
            }
            // verify required parameter 'to' is not null or undefined
            if (to === null || to === undefined) {
                throw new RequiredError('to','Required parameter to was null or undefined when calling exportPriceReportInPeriodOfTimeAsXlsxUsingGET.');
            }
            const localVarPath = `/v1/price-reports/export-as-xlsx`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayPriceReportControllerApi - functional programming interface
 * @export
 */
export const GatewayPriceReportControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary exportPriceReportInPeriodOfTimeAsXlsx
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPriceReportInPeriodOfTimeAsXlsxUsingGET(from: Date, to: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayPriceReportControllerApiFetchParamCreator(configuration).exportPriceReportInPeriodOfTimeAsXlsxUsingGET(from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayPriceReportControllerApi - factory interface
 * @export
 */
export const GatewayPriceReportControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary exportPriceReportInPeriodOfTimeAsXlsx
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportPriceReportInPeriodOfTimeAsXlsxUsingGET(from: Date, to: Date, options?: any) {
            return GatewayPriceReportControllerApiFp(configuration).exportPriceReportInPeriodOfTimeAsXlsxUsingGET(from, to, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayPriceReportControllerApi - object-oriented interface
 * @export
 * @class GatewayPriceReportControllerApi
 * @extends {BaseAPI}
 */
export class GatewayPriceReportControllerApi extends BaseAPI {
    /**
     * 
     * @summary exportPriceReportInPeriodOfTimeAsXlsx
     * @param {Date} from from
     * @param {Date} to to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayPriceReportControllerApi
     */
    public exportPriceReportInPeriodOfTimeAsXlsxUsingGET(from: Date, to: Date, options?: any) {
        return GatewayPriceReportControllerApiFp(this.configuration).exportPriceReportInPeriodOfTimeAsXlsxUsingGET(from, to, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayProductControllerApi - fetch parameter creator
 * @export
 */
export const GatewayProductControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary assignProductGroupsToOrgs
         * @param {AssignGroupsPayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignProductGroupsToOrgsUsingPOST(payload: AssignGroupsPayload, options: any = {}): FetchArgs {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling assignProductGroupsToOrgsUsingPOST.');
            }
            const localVarPath = `/v1/products/assign`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AssignGroupsPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(payload || {}) : (payload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary changeGeneratedUUIDBarcodesToShorterBarcodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeGeneratedUUIDBarcodesToShorterBarcodesUsingPOST(options: any = {}): FetchArgs {
            const localVarPath = `/v1/products/generated-uuid-barcode/change-to-shorter`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary convertFile
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertFileUsingPOST(file: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling convertFileUsingPOST.');
            }
            const localVarPath = `/v1/products/convert`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(file || {}) : (file || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteMultipleProducts
         * @param {ProductIdListDto} products products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMultipleProductsUsingDELETE(products: ProductIdListDto, options: any = {}): FetchArgs {
            // verify required parameter 'products' is not null or undefined
            if (products === null || products === undefined) {
                throw new RequiredError('products','Required parameter products was null or undefined when calling deleteMultipleProductsUsingDELETE.');
            }
            const localVarPath = `/v1/products/multiple`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductIdListDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(products || {}) : (products || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteProduct
         * @param {string} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductUsingDELETE(productId: string, options: any = {}): FetchArgs {
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling deleteProductUsingDELETE.');
            }
            const localVarPath = `/v1/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportProductsAsXlsx
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductsAsXlsxUsingGET(supplierId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/products/export-as-xlsx`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportProductsToCSV
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductsToCSVUsingGET(supplierId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/products/export-as-csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllByIds
         * @param {ProductIdListDto} products products
         * @param {boolean} [extended] extended
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByIdsUsingPOST(products: ProductIdListDto, extended?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'products' is not null or undefined
            if (products === null || products === undefined) {
                throw new RequiredError('products','Required parameter products was null or undefined when calling getAllByIdsUsingPOST.');
            }
            const localVarPath = `/v1/products`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (extended !== undefined) {
                localVarQueryParameter['extended'] = extended;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductIdListDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(products || {}) : (products || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllProductScannedDtosByCriteria
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialBarcode] partialBarcode
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [subCategory] subCategory
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProductScannedDtosByCriteriaUsingGET(category?: string, page?: number, partialBarcode?: string, partialName?: string, size?: number, sort?: string, subCategory?: string, supplierId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/products/search-by-barcode-and-name`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialBarcode !== undefined) {
                localVarQueryParameter['partialBarcode'] = partialBarcode;
            }

            if (partialName !== undefined) {
                localVarQueryParameter['partialName'] = partialName;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (subCategory !== undefined) {
                localVarQueryParameter['subCategory'] = subCategory;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProductScannedDtoByBarcode
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductScannedDtoByBarcodeUsingGET(barcode: string, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling getProductScannedDtoByBarcodeUsingGET.');
            }
            const localVarPath = `/v1/products/barcode/{barcode}`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProduct
         * @param {string} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductUsingGET(productId: string, options: any = {}): FetchArgs {
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling getProductUsingGET.');
            }
            const localVarPath = `/v1/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProductsByCriteria
         * @param {Array<string>} [categories] categories
         * @param {string} [category] category
         * @param {boolean} [extended] extended
         * @param {boolean} [hasCase] hasCase
         * @param {number} [page] 
         * @param {string} [partialBarcode] partialBarcode
         * @param {string} [partialCode] partialCode
         * @param {string} [partialPosId] partialPosId
         * @param {string} [partialProductName] partialProductName
         * @param {boolean} [preferred] preferred
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {boolean} [stockOnHand] stockOnHand
         * @param {Array<string>} [subCategories] subCategories
         * @param {string} [subCategory] subCategory
         * @param {string} [supplierId] supplierId
         * @param {'Bottle' | 'Can' | 'Keg' | 'Other'} [unit] unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsByCriteriaUsingGET(categories?: Array<string>, category?: string, extended?: boolean, hasCase?: boolean, page?: number, partialBarcode?: string, partialCode?: string, partialPosId?: string, partialProductName?: string, preferred?: boolean, size?: number, sort?: string, stockOnHand?: boolean, subCategories?: Array<string>, subCategory?: string, supplierId?: string, unit?: 'Bottle' | 'Can' | 'Keg' | 'Other', options: any = {}): FetchArgs {
            const localVarPath = `/v1/products`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categories) {
                localVarQueryParameter['categories'] = categories;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (extended !== undefined) {
                localVarQueryParameter['extended'] = extended;
            }

            if (hasCase !== undefined) {
                localVarQueryParameter['hasCase'] = hasCase;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialBarcode !== undefined) {
                localVarQueryParameter['partialBarcode'] = partialBarcode;
            }

            if (partialCode !== undefined) {
                localVarQueryParameter['partialCode'] = partialCode;
            }

            if (partialPosId !== undefined) {
                localVarQueryParameter['partialPosId'] = partialPosId;
            }

            if (partialProductName !== undefined) {
                localVarQueryParameter['partialProductName'] = partialProductName;
            }

            if (preferred !== undefined) {
                localVarQueryParameter['preferred'] = preferred;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (stockOnHand !== undefined) {
                localVarQueryParameter['stockOnHand'] = stockOnHand;
            }

            if (subCategories) {
                localVarQueryParameter['subCategories'] = subCategories;
            }

            if (subCategory !== undefined) {
                localVarQueryParameter['subCategory'] = subCategory;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            if (unit !== undefined) {
                localVarQueryParameter['unit'] = unit;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProductsByGroupId
         * @param {string} groupId groupId
         * @param {boolean} [stockOnHand] stockOnHand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsByGroupIdUsingGET(groupId: string, stockOnHand?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'groupId' is not null or undefined
            if (groupId === null || groupId === undefined) {
                throw new RequiredError('groupId','Required parameter groupId was null or undefined when calling getProductsByGroupIdUsingGET.');
            }
            const localVarPath = `/v1/products/group/{groupId}`
                .replace(`{${"groupId"}}`, encodeURIComponent(String(groupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (stockOnHand !== undefined) {
                localVarQueryParameter['stockOnHand'] = stockOnHand;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProductsContainPosIdByBarcodes
         * @param {Array<string>} barcodes barcodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsContainPosIdByBarcodesUsingPOST(barcodes: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'barcodes' is not null or undefined
            if (barcodes === null || barcodes === undefined) {
                throw new RequiredError('barcodes','Required parameter barcodes was null or undefined when calling getProductsContainPosIdByBarcodesUsingPOST.');
            }
            const localVarPath = `/v1/products/get-posIds-by-barcodes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(barcodes || {}) : (barcodes || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getWrapperProductPriceForSupplierByBarcode
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWrapperProductPriceForSupplierByBarcodeUsingGET(barcode: string, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling getWrapperProductPriceForSupplierByBarcodeUsingGET.');
            }
            const localVarPath = `/v1/products/get-all-prices/{barcode}`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mergeGroups
         * @param {MergeGroupsPayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeGroupsUsingPOST(payload: MergeGroupsPayload, options: any = {}): FetchArgs {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling mergeGroupsUsingPOST.');
            }
            const localVarPath = `/v1/products/group`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"MergeGroupsPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(payload || {}) : (payload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary removeProductFromGroup
         * @param {string} productId productId
         * @param {boolean} [removeOnlyThis] removeOnlyThis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProductFromGroupUsingDELETE(productId: string, removeOnlyThis?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling removeProductFromGroupUsingDELETE.');
            }
            const localVarPath = `/v1/products/{productId}/group`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (removeOnlyThis !== undefined) {
                localVarQueryParameter['removeOnlyThis'] = removeOnlyThis;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setGroupIdToAllProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGroupIdToAllProductsUsingPOST(options: any = {}): FetchArgs {
            const localVarPath = `/v1/products/group-id`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setInitialPreferredProductsForAllOrgs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInitialPreferredProductsForAllOrgsUsingPOST(options: any = {}): FetchArgs {
            const localVarPath = `/v1/products/preferred`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary syncProductsWithMaster
         * @param {ProductsSyncDto} productsSyncDto productsSyncDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProductsWithMasterUsingPATCH(productsSyncDto: ProductsSyncDto, options: any = {}): FetchArgs {
            // verify required parameter 'productsSyncDto' is not null or undefined
            if (productsSyncDto === null || productsSyncDto === undefined) {
                throw new RequiredError('productsSyncDto','Required parameter productsSyncDto was null or undefined when calling syncProductsWithMasterUsingPATCH.');
            }
            const localVarPath = `/v1/products/sync`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductsSyncDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(productsSyncDto || {}) : (productsSyncDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary unassignProductGroupsFromOrgs
         * @param {AssignGroupsPayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignProductGroupsFromOrgsUsingPOST(payload: AssignGroupsPayload, options: any = {}): FetchArgs {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling unassignProductGroupsFromOrgsUsingPOST.');
            }
            const localVarPath = `/v1/products/unassign`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AssignGroupsPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(payload || {}) : (payload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateBarcodeToAllServices
         * @param {string} newBarcode newBarcode
         * @param {string} oldBarcode oldBarcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBarcodeToAllServicesUsingPOST(newBarcode: string, oldBarcode: string, options: any = {}): FetchArgs {
            // verify required parameter 'newBarcode' is not null or undefined
            if (newBarcode === null || newBarcode === undefined) {
                throw new RequiredError('newBarcode','Required parameter newBarcode was null or undefined when calling updateBarcodeToAllServicesUsingPOST.');
            }
            // verify required parameter 'oldBarcode' is not null or undefined
            if (oldBarcode === null || oldBarcode === undefined) {
                throw new RequiredError('oldBarcode','Required parameter oldBarcode was null or undefined when calling updateBarcodeToAllServicesUsingPOST.');
            }
            const localVarPath = `/v1/products/update-barcode`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (newBarcode !== undefined) {
                localVarQueryParameter['newBarcode'] = newBarcode;
            }

            if (oldBarcode !== undefined) {
                localVarQueryParameter['oldBarcode'] = oldBarcode;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateProductPrices
         * @param {string} barcode barcode
         * @param {ProductUpdatePriceDto} product product
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductPricesUsingPATCH(barcode: string, product: ProductUpdatePriceDto, supplierId: string, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling updateProductPricesUsingPATCH.');
            }
            // verify required parameter 'product' is not null or undefined
            if (product === null || product === undefined) {
                throw new RequiredError('product','Required parameter product was null or undefined when calling updateProductPricesUsingPATCH.');
            }
            // verify required parameter 'supplierId' is not null or undefined
            if (supplierId === null || supplierId === undefined) {
                throw new RequiredError('supplierId','Required parameter supplierId was null or undefined when calling updateProductPricesUsingPATCH.');
            }
            const localVarPath = `/v1/products/prices`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }

            if (supplierId !== undefined) {
                localVarQueryParameter['supplierId'] = supplierId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductUpdatePriceDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(product || {}) : (product || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateProduct
         * @param {Product} product product
         * @param {string} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductUsingPUT(product: Product, productId: string, options: any = {}): FetchArgs {
            // verify required parameter 'product' is not null or undefined
            if (product === null || product === undefined) {
                throw new RequiredError('product','Required parameter product was null or undefined when calling updateProductUsingPUT.');
            }
            // verify required parameter 'productId' is not null or undefined
            if (productId === null || productId === undefined) {
                throw new RequiredError('productId','Required parameter productId was null or undefined when calling updateProductUsingPUT.');
            }
            const localVarPath = `/v1/products/{productId}`
                .replace(`{${"productId"}}`, encodeURIComponent(String(productId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Product" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(product || {}) : (product || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateProductsPosId
         * @param {string} barcode barcode
         * @param {string} posId posId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductsPosIdUsingPOST(barcode: string, posId: string, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling updateProductsPosIdUsingPOST.');
            }
            // verify required parameter 'posId' is not null or undefined
            if (posId === null || posId === undefined) {
                throw new RequiredError('posId','Required parameter posId was null or undefined when calling updateProductsPosIdUsingPOST.');
            }
            const localVarPath = `/v1/products/change-posId`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }

            if (posId !== undefined) {
                localVarQueryParameter['posId'] = posId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayProductControllerApi - functional programming interface
 * @export
 */
export const GatewayProductControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary assignProductGroupsToOrgs
         * @param {AssignGroupsPayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignProductGroupsToOrgsUsingPOST(payload: AssignGroupsPayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).assignProductGroupsToOrgsUsingPOST(payload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary changeGeneratedUUIDBarcodesToShorterBarcodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeGeneratedUUIDBarcodesToShorterBarcodesUsingPOST(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).changeGeneratedUUIDBarcodesToShorterBarcodesUsingPOST(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary convertFile
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertFileUsingPOST(file: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Table> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).convertFileUsingPOST(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteMultipleProducts
         * @param {ProductIdListDto} products products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMultipleProductsUsingDELETE(products: ProductIdListDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).deleteMultipleProductsUsingDELETE(products, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteProduct
         * @param {string} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductUsingDELETE(productId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).deleteProductUsingDELETE(productId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportProductsAsXlsx
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductsAsXlsxUsingGET(supplierId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).exportProductsAsXlsxUsingGET(supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportProductsToCSV
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductsToCSVUsingGET(supplierId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).exportProductsToCSVUsingGET(supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllByIds
         * @param {ProductIdListDto} products products
         * @param {boolean} [extended] extended
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByIdsUsingPOST(products: ProductIdListDto, extended?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ExtendedProduct>> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).getAllByIdsUsingPOST(products, extended, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllProductScannedDtosByCriteria
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialBarcode] partialBarcode
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [subCategory] subCategory
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProductScannedDtosByCriteriaUsingGET(category?: string, page?: number, partialBarcode?: string, partialName?: string, size?: number, sort?: string, subCategory?: string, supplierId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageProductScannedDto> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).getAllProductScannedDtosByCriteriaUsingGET(category, page, partialBarcode, partialName, size, sort, subCategory, supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getProductScannedDtoByBarcode
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductScannedDtoByBarcodeUsingGET(barcode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductScannedDto> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).getProductScannedDtoByBarcodeUsingGET(barcode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getProduct
         * @param {string} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductUsingGET(productId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Product> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).getProductUsingGET(productId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getProductsByCriteria
         * @param {Array<string>} [categories] categories
         * @param {string} [category] category
         * @param {boolean} [extended] extended
         * @param {boolean} [hasCase] hasCase
         * @param {number} [page] 
         * @param {string} [partialBarcode] partialBarcode
         * @param {string} [partialCode] partialCode
         * @param {string} [partialPosId] partialPosId
         * @param {string} [partialProductName] partialProductName
         * @param {boolean} [preferred] preferred
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {boolean} [stockOnHand] stockOnHand
         * @param {Array<string>} [subCategories] subCategories
         * @param {string} [subCategory] subCategory
         * @param {string} [supplierId] supplierId
         * @param {'Bottle' | 'Can' | 'Keg' | 'Other'} [unit] unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsByCriteriaUsingGET(categories?: Array<string>, category?: string, extended?: boolean, hasCase?: boolean, page?: number, partialBarcode?: string, partialCode?: string, partialPosId?: string, partialProductName?: string, preferred?: boolean, size?: number, sort?: string, stockOnHand?: boolean, subCategories?: Array<string>, subCategory?: string, supplierId?: string, unit?: 'Bottle' | 'Can' | 'Keg' | 'Other', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageExtendedProduct> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).getProductsByCriteriaUsingGET(categories, category, extended, hasCase, page, partialBarcode, partialCode, partialPosId, partialProductName, preferred, size, sort, stockOnHand, subCategories, subCategory, supplierId, unit, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getProductsByGroupId
         * @param {string} groupId groupId
         * @param {boolean} [stockOnHand] stockOnHand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsByGroupIdUsingGET(groupId: string, stockOnHand?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ExtendedProduct>> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).getProductsByGroupIdUsingGET(groupId, stockOnHand, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getProductsContainPosIdByBarcodes
         * @param {Array<string>} barcodes barcodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsContainPosIdByBarcodesUsingPOST(barcodes: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ProductDto>> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).getProductsContainPosIdByBarcodesUsingPOST(barcodes, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getWrapperProductPriceForSupplierByBarcode
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWrapperProductPriceForSupplierByBarcodeUsingGET(barcode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WrapperProductPriceForSupplier>> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).getWrapperProductPriceForSupplierByBarcodeUsingGET(barcode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary mergeGroups
         * @param {MergeGroupsPayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeGroupsUsingPOST(payload: MergeGroupsPayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).mergeGroupsUsingPOST(payload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary removeProductFromGroup
         * @param {string} productId productId
         * @param {boolean} [removeOnlyThis] removeOnlyThis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProductFromGroupUsingDELETE(productId: string, removeOnlyThis?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ExtendedProduct> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).removeProductFromGroupUsingDELETE(productId, removeOnlyThis, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setGroupIdToAllProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGroupIdToAllProductsUsingPOST(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).setGroupIdToAllProductsUsingPOST(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setInitialPreferredProductsForAllOrgs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInitialPreferredProductsForAllOrgsUsingPOST(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).setInitialPreferredProductsForAllOrgsUsingPOST(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary syncProductsWithMaster
         * @param {ProductsSyncDto} productsSyncDto productsSyncDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProductsWithMasterUsingPATCH(productsSyncDto: ProductsSyncDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).syncProductsWithMasterUsingPATCH(productsSyncDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary unassignProductGroupsFromOrgs
         * @param {AssignGroupsPayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignProductGroupsFromOrgsUsingPOST(payload: AssignGroupsPayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).unassignProductGroupsFromOrgsUsingPOST(payload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateBarcodeToAllServices
         * @param {string} newBarcode newBarcode
         * @param {string} oldBarcode oldBarcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBarcodeToAllServicesUsingPOST(newBarcode: string, oldBarcode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).updateBarcodeToAllServicesUsingPOST(newBarcode, oldBarcode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateProductPrices
         * @param {string} barcode barcode
         * @param {ProductUpdatePriceDto} product product
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductPricesUsingPATCH(barcode: string, product: ProductUpdatePriceDto, supplierId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Product> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).updateProductPricesUsingPATCH(barcode, product, supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateProduct
         * @param {Product} product product
         * @param {string} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductUsingPUT(product: Product, productId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Product> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).updateProductUsingPUT(product, productId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateProductsPosId
         * @param {string} barcode barcode
         * @param {string} posId posId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductsPosIdUsingPOST(barcode: string, posId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayProductControllerApiFetchParamCreator(configuration).updateProductsPosIdUsingPOST(barcode, posId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayProductControllerApi - factory interface
 * @export
 */
export const GatewayProductControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary assignProductGroupsToOrgs
         * @param {AssignGroupsPayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        assignProductGroupsToOrgsUsingPOST(payload: AssignGroupsPayload, options?: any) {
            return GatewayProductControllerApiFp(configuration).assignProductGroupsToOrgsUsingPOST(payload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary changeGeneratedUUIDBarcodesToShorterBarcodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changeGeneratedUUIDBarcodesToShorterBarcodesUsingPOST(options?: any) {
            return GatewayProductControllerApiFp(configuration).changeGeneratedUUIDBarcodesToShorterBarcodesUsingPOST(options)(fetch, basePath);
        },
        /**
         * 
         * @summary convertFile
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertFileUsingPOST(file: string, options?: any) {
            return GatewayProductControllerApiFp(configuration).convertFileUsingPOST(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteMultipleProducts
         * @param {ProductIdListDto} products products
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMultipleProductsUsingDELETE(products: ProductIdListDto, options?: any) {
            return GatewayProductControllerApiFp(configuration).deleteMultipleProductsUsingDELETE(products, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteProduct
         * @param {string} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductUsingDELETE(productId: string, options?: any) {
            return GatewayProductControllerApiFp(configuration).deleteProductUsingDELETE(productId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportProductsAsXlsx
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductsAsXlsxUsingGET(supplierId?: string, options?: any) {
            return GatewayProductControllerApiFp(configuration).exportProductsAsXlsxUsingGET(supplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportProductsToCSV
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportProductsToCSVUsingGET(supplierId?: string, options?: any) {
            return GatewayProductControllerApiFp(configuration).exportProductsToCSVUsingGET(supplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllByIds
         * @param {ProductIdListDto} products products
         * @param {boolean} [extended] extended
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllByIdsUsingPOST(products: ProductIdListDto, extended?: boolean, options?: any) {
            return GatewayProductControllerApiFp(configuration).getAllByIdsUsingPOST(products, extended, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllProductScannedDtosByCriteria
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialBarcode] partialBarcode
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [subCategory] subCategory
         * @param {string} [supplierId] supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllProductScannedDtosByCriteriaUsingGET(category?: string, page?: number, partialBarcode?: string, partialName?: string, size?: number, sort?: string, subCategory?: string, supplierId?: string, options?: any) {
            return GatewayProductControllerApiFp(configuration).getAllProductScannedDtosByCriteriaUsingGET(category, page, partialBarcode, partialName, size, sort, subCategory, supplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getProductScannedDtoByBarcode
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductScannedDtoByBarcodeUsingGET(barcode: string, options?: any) {
            return GatewayProductControllerApiFp(configuration).getProductScannedDtoByBarcodeUsingGET(barcode, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getProduct
         * @param {string} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductUsingGET(productId: string, options?: any) {
            return GatewayProductControllerApiFp(configuration).getProductUsingGET(productId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getProductsByCriteria
         * @param {Array<string>} [categories] categories
         * @param {string} [category] category
         * @param {boolean} [extended] extended
         * @param {boolean} [hasCase] hasCase
         * @param {number} [page] 
         * @param {string} [partialBarcode] partialBarcode
         * @param {string} [partialCode] partialCode
         * @param {string} [partialPosId] partialPosId
         * @param {string} [partialProductName] partialProductName
         * @param {boolean} [preferred] preferred
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {boolean} [stockOnHand] stockOnHand
         * @param {Array<string>} [subCategories] subCategories
         * @param {string} [subCategory] subCategory
         * @param {string} [supplierId] supplierId
         * @param {'Bottle' | 'Can' | 'Keg' | 'Other'} [unit] unit
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsByCriteriaUsingGET(categories?: Array<string>, category?: string, extended?: boolean, hasCase?: boolean, page?: number, partialBarcode?: string, partialCode?: string, partialPosId?: string, partialProductName?: string, preferred?: boolean, size?: number, sort?: string, stockOnHand?: boolean, subCategories?: Array<string>, subCategory?: string, supplierId?: string, unit?: 'Bottle' | 'Can' | 'Keg' | 'Other', options?: any) {
            return GatewayProductControllerApiFp(configuration).getProductsByCriteriaUsingGET(categories, category, extended, hasCase, page, partialBarcode, partialCode, partialPosId, partialProductName, preferred, size, sort, stockOnHand, subCategories, subCategory, supplierId, unit, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getProductsByGroupId
         * @param {string} groupId groupId
         * @param {boolean} [stockOnHand] stockOnHand
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsByGroupIdUsingGET(groupId: string, stockOnHand?: boolean, options?: any) {
            return GatewayProductControllerApiFp(configuration).getProductsByGroupIdUsingGET(groupId, stockOnHand, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getProductsContainPosIdByBarcodes
         * @param {Array<string>} barcodes barcodes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsContainPosIdByBarcodesUsingPOST(barcodes: Array<string>, options?: any) {
            return GatewayProductControllerApiFp(configuration).getProductsContainPosIdByBarcodesUsingPOST(barcodes, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getWrapperProductPriceForSupplierByBarcode
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWrapperProductPriceForSupplierByBarcodeUsingGET(barcode: string, options?: any) {
            return GatewayProductControllerApiFp(configuration).getWrapperProductPriceForSupplierByBarcodeUsingGET(barcode, options)(fetch, basePath);
        },
        /**
         * 
         * @summary mergeGroups
         * @param {MergeGroupsPayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeGroupsUsingPOST(payload: MergeGroupsPayload, options?: any) {
            return GatewayProductControllerApiFp(configuration).mergeGroupsUsingPOST(payload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary removeProductFromGroup
         * @param {string} productId productId
         * @param {boolean} [removeOnlyThis] removeOnlyThis
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeProductFromGroupUsingDELETE(productId: string, removeOnlyThis?: boolean, options?: any) {
            return GatewayProductControllerApiFp(configuration).removeProductFromGroupUsingDELETE(productId, removeOnlyThis, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setGroupIdToAllProducts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setGroupIdToAllProductsUsingPOST(options?: any) {
            return GatewayProductControllerApiFp(configuration).setGroupIdToAllProductsUsingPOST(options)(fetch, basePath);
        },
        /**
         * 
         * @summary setInitialPreferredProductsForAllOrgs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setInitialPreferredProductsForAllOrgsUsingPOST(options?: any) {
            return GatewayProductControllerApiFp(configuration).setInitialPreferredProductsForAllOrgsUsingPOST(options)(fetch, basePath);
        },
        /**
         * 
         * @summary syncProductsWithMaster
         * @param {ProductsSyncDto} productsSyncDto productsSyncDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncProductsWithMasterUsingPATCH(productsSyncDto: ProductsSyncDto, options?: any) {
            return GatewayProductControllerApiFp(configuration).syncProductsWithMasterUsingPATCH(productsSyncDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary unassignProductGroupsFromOrgs
         * @param {AssignGroupsPayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unassignProductGroupsFromOrgsUsingPOST(payload: AssignGroupsPayload, options?: any) {
            return GatewayProductControllerApiFp(configuration).unassignProductGroupsFromOrgsUsingPOST(payload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateBarcodeToAllServices
         * @param {string} newBarcode newBarcode
         * @param {string} oldBarcode oldBarcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateBarcodeToAllServicesUsingPOST(newBarcode: string, oldBarcode: string, options?: any) {
            return GatewayProductControllerApiFp(configuration).updateBarcodeToAllServicesUsingPOST(newBarcode, oldBarcode, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateProductPrices
         * @param {string} barcode barcode
         * @param {ProductUpdatePriceDto} product product
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductPricesUsingPATCH(barcode: string, product: ProductUpdatePriceDto, supplierId: string, options?: any) {
            return GatewayProductControllerApiFp(configuration).updateProductPricesUsingPATCH(barcode, product, supplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateProduct
         * @param {Product} product product
         * @param {string} productId productId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductUsingPUT(product: Product, productId: string, options?: any) {
            return GatewayProductControllerApiFp(configuration).updateProductUsingPUT(product, productId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateProductsPosId
         * @param {string} barcode barcode
         * @param {string} posId posId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductsPosIdUsingPOST(barcode: string, posId: string, options?: any) {
            return GatewayProductControllerApiFp(configuration).updateProductsPosIdUsingPOST(barcode, posId, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayProductControllerApi - object-oriented interface
 * @export
 * @class GatewayProductControllerApi
 * @extends {BaseAPI}
 */
export class GatewayProductControllerApi extends BaseAPI {
    /**
     * 
     * @summary assignProductGroupsToOrgs
     * @param {AssignGroupsPayload} payload payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public assignProductGroupsToOrgsUsingPOST(payload: AssignGroupsPayload, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).assignProductGroupsToOrgsUsingPOST(payload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary changeGeneratedUUIDBarcodesToShorterBarcodes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public changeGeneratedUUIDBarcodesToShorterBarcodesUsingPOST(options?: any) {
        return GatewayProductControllerApiFp(this.configuration).changeGeneratedUUIDBarcodesToShorterBarcodesUsingPOST(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary convertFile
     * @param {string} file file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public convertFileUsingPOST(file: string, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).convertFileUsingPOST(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteMultipleProducts
     * @param {ProductIdListDto} products products
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public deleteMultipleProductsUsingDELETE(products: ProductIdListDto, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).deleteMultipleProductsUsingDELETE(products, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteProduct
     * @param {string} productId productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public deleteProductUsingDELETE(productId: string, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).deleteProductUsingDELETE(productId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportProductsAsXlsx
     * @param {string} [supplierId] supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public exportProductsAsXlsxUsingGET(supplierId?: string, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).exportProductsAsXlsxUsingGET(supplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportProductsToCSV
     * @param {string} [supplierId] supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public exportProductsToCSVUsingGET(supplierId?: string, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).exportProductsToCSVUsingGET(supplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllByIds
     * @param {ProductIdListDto} products products
     * @param {boolean} [extended] extended
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public getAllByIdsUsingPOST(products: ProductIdListDto, extended?: boolean, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).getAllByIdsUsingPOST(products, extended, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllProductScannedDtosByCriteria
     * @param {string} [category] category
     * @param {number} [page] 
     * @param {string} [partialBarcode] partialBarcode
     * @param {string} [partialName] partialName
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [subCategory] subCategory
     * @param {string} [supplierId] supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public getAllProductScannedDtosByCriteriaUsingGET(category?: string, page?: number, partialBarcode?: string, partialName?: string, size?: number, sort?: string, subCategory?: string, supplierId?: string, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).getAllProductScannedDtosByCriteriaUsingGET(category, page, partialBarcode, partialName, size, sort, subCategory, supplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getProductScannedDtoByBarcode
     * @param {string} barcode barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public getProductScannedDtoByBarcodeUsingGET(barcode: string, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).getProductScannedDtoByBarcodeUsingGET(barcode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getProduct
     * @param {string} productId productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public getProductUsingGET(productId: string, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).getProductUsingGET(productId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getProductsByCriteria
     * @param {Array<string>} [categories] categories
     * @param {string} [category] category
     * @param {boolean} [extended] extended
     * @param {boolean} [hasCase] hasCase
     * @param {number} [page] 
     * @param {string} [partialBarcode] partialBarcode
     * @param {string} [partialCode] partialCode
     * @param {string} [partialPosId] partialPosId
     * @param {string} [partialProductName] partialProductName
     * @param {boolean} [preferred] preferred
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {boolean} [stockOnHand] stockOnHand
     * @param {Array<string>} [subCategories] subCategories
     * @param {string} [subCategory] subCategory
     * @param {string} [supplierId] supplierId
     * @param {'Bottle' | 'Can' | 'Keg' | 'Other'} [unit] unit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public getProductsByCriteriaUsingGET(categories?: Array<string>, category?: string, extended?: boolean, hasCase?: boolean, page?: number, partialBarcode?: string, partialCode?: string, partialPosId?: string, partialProductName?: string, preferred?: boolean, size?: number, sort?: string, stockOnHand?: boolean, subCategories?: Array<string>, subCategory?: string, supplierId?: string, unit?: 'Bottle' | 'Can' | 'Keg' | 'Other', options?: any) {
        return GatewayProductControllerApiFp(this.configuration).getProductsByCriteriaUsingGET(categories, category, extended, hasCase, page, partialBarcode, partialCode, partialPosId, partialProductName, preferred, size, sort, stockOnHand, subCategories, subCategory, supplierId, unit, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getProductsByGroupId
     * @param {string} groupId groupId
     * @param {boolean} [stockOnHand] stockOnHand
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public getProductsByGroupIdUsingGET(groupId: string, stockOnHand?: boolean, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).getProductsByGroupIdUsingGET(groupId, stockOnHand, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getProductsContainPosIdByBarcodes
     * @param {Array<string>} barcodes barcodes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public getProductsContainPosIdByBarcodesUsingPOST(barcodes: Array<string>, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).getProductsContainPosIdByBarcodesUsingPOST(barcodes, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getWrapperProductPriceForSupplierByBarcode
     * @param {string} barcode barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public getWrapperProductPriceForSupplierByBarcodeUsingGET(barcode: string, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).getWrapperProductPriceForSupplierByBarcodeUsingGET(barcode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary mergeGroups
     * @param {MergeGroupsPayload} payload payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public mergeGroupsUsingPOST(payload: MergeGroupsPayload, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).mergeGroupsUsingPOST(payload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary removeProductFromGroup
     * @param {string} productId productId
     * @param {boolean} [removeOnlyThis] removeOnlyThis
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public removeProductFromGroupUsingDELETE(productId: string, removeOnlyThis?: boolean, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).removeProductFromGroupUsingDELETE(productId, removeOnlyThis, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setGroupIdToAllProducts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public setGroupIdToAllProductsUsingPOST(options?: any) {
        return GatewayProductControllerApiFp(this.configuration).setGroupIdToAllProductsUsingPOST(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setInitialPreferredProductsForAllOrgs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public setInitialPreferredProductsForAllOrgsUsingPOST(options?: any) {
        return GatewayProductControllerApiFp(this.configuration).setInitialPreferredProductsForAllOrgsUsingPOST(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary syncProductsWithMaster
     * @param {ProductsSyncDto} productsSyncDto productsSyncDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public syncProductsWithMasterUsingPATCH(productsSyncDto: ProductsSyncDto, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).syncProductsWithMasterUsingPATCH(productsSyncDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary unassignProductGroupsFromOrgs
     * @param {AssignGroupsPayload} payload payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public unassignProductGroupsFromOrgsUsingPOST(payload: AssignGroupsPayload, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).unassignProductGroupsFromOrgsUsingPOST(payload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateBarcodeToAllServices
     * @param {string} newBarcode newBarcode
     * @param {string} oldBarcode oldBarcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public updateBarcodeToAllServicesUsingPOST(newBarcode: string, oldBarcode: string, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).updateBarcodeToAllServicesUsingPOST(newBarcode, oldBarcode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateProductPrices
     * @param {string} barcode barcode
     * @param {ProductUpdatePriceDto} product product
     * @param {string} supplierId supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public updateProductPricesUsingPATCH(barcode: string, product: ProductUpdatePriceDto, supplierId: string, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).updateProductPricesUsingPATCH(barcode, product, supplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateProduct
     * @param {Product} product product
     * @param {string} productId productId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public updateProductUsingPUT(product: Product, productId: string, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).updateProductUsingPUT(product, productId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateProductsPosId
     * @param {string} barcode barcode
     * @param {string} posId posId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayProductControllerApi
     */
    public updateProductsPosIdUsingPOST(barcode: string, posId: string, options?: any) {
        return GatewayProductControllerApiFp(this.configuration).updateProductsPosIdUsingPOST(barcode, posId, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayRecipeControllerApi - fetch parameter creator
 * @export
 */
export const GatewayRecipeControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary copyRecipesFromMaster
         * @param {RecipeIdListDto} masterRecipes masterRecipes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyRecipesFromMasterUsingPOST(masterRecipes: RecipeIdListDto, options: any = {}): FetchArgs {
            // verify required parameter 'masterRecipes' is not null or undefined
            if (masterRecipes === null || masterRecipes === undefined) {
                throw new RequiredError('masterRecipes','Required parameter masterRecipes was null or undefined when calling copyRecipesFromMasterUsingPOST.');
            }
            const localVarPath = `/v1/recipes/copy-from-master`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RecipeIdListDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(masterRecipes || {}) : (masterRecipes || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createRecipe
         * @param {Recipe} recipe recipe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecipeUsingPOST(recipe: Recipe, options: any = {}): FetchArgs {
            // verify required parameter 'recipe' is not null or undefined
            if (recipe === null || recipe === undefined) {
                throw new RequiredError('recipe','Required parameter recipe was null or undefined when calling createRecipeUsingPOST.');
            }
            const localVarPath = `/v1/recipes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Recipe" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(recipe || {}) : (recipe || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteRecipe
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecipeUsingDELETE(recipeId: string, options: any = {}): FetchArgs {
            // verify required parameter 'recipeId' is not null or undefined
            if (recipeId === null || recipeId === undefined) {
                throw new RequiredError('recipeId','Required parameter recipeId was null or undefined when calling deleteRecipeUsingDELETE.');
            }
            const localVarPath = `/v1/recipes/{recipeId}`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportRecipesAsCSV
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportRecipesAsCSVUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/recipes/export-as-csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportRecipesWithDeletedItemsAsXlsx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportRecipesWithDeletedItemsAsXlsxUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/recipes/deleted-ingredients/export-as-xlsx`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRecipeByBarcode
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeByBarcodeUsingGET(barcode: string, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling getRecipeByBarcodeUsingGET.');
            }
            const localVarPath = `/v1/recipes/barcode/{barcode}`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRecipeById
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeByIdUsingGET(recipeId: string, options: any = {}): FetchArgs {
            // verify required parameter 'recipeId' is not null or undefined
            if (recipeId === null || recipeId === undefined) {
                throw new RequiredError('recipeId','Required parameter recipeId was null or undefined when calling getRecipeByIdUsingGET.');
            }
            const localVarPath = `/v1/recipes/{recipeId}`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRecipesByCriteria
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialIngredientName] partialIngredientName
         * @param {string} [partialPosId] partialPosId
         * @param {string} [partialRecipeName] partialRecipeName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'DRAFT' | 'PUBLISHED'} [status] status
         * @param {boolean} [withoutFolder] withoutFolder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipesByCriteriaUsingGET(category?: string, page?: number, partialIngredientName?: string, partialPosId?: string, partialRecipeName?: string, size?: number, sort?: string, status?: 'DRAFT' | 'PUBLISHED', withoutFolder?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/v1/recipes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialIngredientName !== undefined) {
                localVarQueryParameter['partialIngredientName'] = partialIngredientName;
            }

            if (partialPosId !== undefined) {
                localVarQueryParameter['partialPosId'] = partialPosId;
            }

            if (partialRecipeName !== undefined) {
                localVarQueryParameter['partialRecipeName'] = partialRecipeName;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (withoutFolder !== undefined) {
                localVarQueryParameter['withoutFolder'] = withoutFolder;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRecipesByIds
         * @param {RecipeIdListDto} recipes recipes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipesByIdsUsingPOST(recipes: RecipeIdListDto, options: any = {}): FetchArgs {
            // verify required parameter 'recipes' is not null or undefined
            if (recipes === null || recipes === undefined) {
                throw new RequiredError('recipes','Required parameter recipes was null or undefined when calling getRecipesByIdsUsingPOST.');
            }
            const localVarPath = `/v1/recipes/get-by-ids`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RecipeIdListDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(recipes || {}) : (recipes || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary recalculateAllRecipesWithLatestPrices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateAllRecipesWithLatestPricesUsingPOST(options: any = {}): FetchArgs {
            const localVarPath = `/v1/recipes/update-latest-prices`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setPublishedStatusToAllWithoutStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPublishedStatusToAllWithoutStatusUsingPATCH(options: any = {}): FetchArgs {
            const localVarPath = `/v1/recipes/status`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateRecipeFeaturedFile
         * @param {string} recipeId recipeId
         * @param {GrowyzeFile} [featuredFile] featuredFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeFeaturedFileUsingPOST(recipeId: string, featuredFile?: GrowyzeFile, options: any = {}): FetchArgs {
            // verify required parameter 'recipeId' is not null or undefined
            if (recipeId === null || recipeId === undefined) {
                throw new RequiredError('recipeId','Required parameter recipeId was null or undefined when calling updateRecipeFeaturedFileUsingPOST.');
            }
            const localVarPath = `/v1/recipes/{recipeId}/featured-file`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GrowyzeFile" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(featuredFile || {}) : (featuredFile || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateRecipeFiles
         * @param {FilesDto} filesDto filesDto
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeFilesUsingPOST(filesDto: FilesDto, recipeId: string, options: any = {}): FetchArgs {
            // verify required parameter 'filesDto' is not null or undefined
            if (filesDto === null || filesDto === undefined) {
                throw new RequiredError('filesDto','Required parameter filesDto was null or undefined when calling updateRecipeFilesUsingPOST.');
            }
            // verify required parameter 'recipeId' is not null or undefined
            if (recipeId === null || recipeId === undefined) {
                throw new RequiredError('recipeId','Required parameter recipeId was null or undefined when calling updateRecipeFilesUsingPOST.');
            }
            const localVarPath = `/v1/recipes/{recipeId}/files`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FilesDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filesDto || {}) : (filesDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateRecipe
         * @param {Recipe} recipe recipe
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeUsingPUT(recipe: Recipe, recipeId: string, options: any = {}): FetchArgs {
            // verify required parameter 'recipe' is not null or undefined
            if (recipe === null || recipe === undefined) {
                throw new RequiredError('recipe','Required parameter recipe was null or undefined when calling updateRecipeUsingPUT.');
            }
            // verify required parameter 'recipeId' is not null or undefined
            if (recipeId === null || recipeId === undefined) {
                throw new RequiredError('recipeId','Required parameter recipeId was null or undefined when calling updateRecipeUsingPUT.');
            }
            const localVarPath = `/v1/recipes/{recipeId}`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Recipe" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(recipe || {}) : (recipe || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateRecipesPosIds
         * @param {string} filePosIds file-pos-ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipesPosIdsUsingPATCH(filePosIds: string, options: any = {}): FetchArgs {
            // verify required parameter 'filePosIds' is not null or undefined
            if (filePosIds === null || filePosIds === undefined) {
                throw new RequiredError('filePosIds','Required parameter filePosIds was null or undefined when calling updateRecipesPosIdsUsingPATCH.');
            }
            const localVarPath = `/v1/recipes/pos-ids`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filePosIds || {}) : (filePosIds || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayRecipeControllerApi - functional programming interface
 * @export
 */
export const GatewayRecipeControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary copyRecipesFromMaster
         * @param {RecipeIdListDto} masterRecipes masterRecipes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyRecipesFromMasterUsingPOST(masterRecipes: RecipeIdListDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).copyRecipesFromMasterUsingPOST(masterRecipes, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createRecipe
         * @param {Recipe} recipe recipe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecipeUsingPOST(recipe: Recipe, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Recipe> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).createRecipeUsingPOST(recipe, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteRecipe
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecipeUsingDELETE(recipeId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).deleteRecipeUsingDELETE(recipeId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportRecipesAsCSV
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportRecipesAsCSVUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).exportRecipesAsCSVUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportRecipesWithDeletedItemsAsXlsx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportRecipesWithDeletedItemsAsXlsxUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).exportRecipesWithDeletedItemsAsXlsxUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getRecipeByBarcode
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeByBarcodeUsingGET(barcode: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Recipe> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).getRecipeByBarcodeUsingGET(barcode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getRecipeById
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeByIdUsingGET(recipeId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Recipe> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).getRecipeByIdUsingGET(recipeId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getRecipesByCriteria
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialIngredientName] partialIngredientName
         * @param {string} [partialPosId] partialPosId
         * @param {string} [partialRecipeName] partialRecipeName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'DRAFT' | 'PUBLISHED'} [status] status
         * @param {boolean} [withoutFolder] withoutFolder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipesByCriteriaUsingGET(category?: string, page?: number, partialIngredientName?: string, partialPosId?: string, partialRecipeName?: string, size?: number, sort?: string, status?: 'DRAFT' | 'PUBLISHED', withoutFolder?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageRecipe> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).getRecipesByCriteriaUsingGET(category, page, partialIngredientName, partialPosId, partialRecipeName, size, sort, status, withoutFolder, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getRecipesByIds
         * @param {RecipeIdListDto} recipes recipes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipesByIdsUsingPOST(recipes: RecipeIdListDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Recipe>> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).getRecipesByIdsUsingPOST(recipes, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary recalculateAllRecipesWithLatestPrices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateAllRecipesWithLatestPricesUsingPOST(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).recalculateAllRecipesWithLatestPricesUsingPOST(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setPublishedStatusToAllWithoutStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPublishedStatusToAllWithoutStatusUsingPATCH(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).setPublishedStatusToAllWithoutStatusUsingPATCH(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateRecipeFeaturedFile
         * @param {string} recipeId recipeId
         * @param {GrowyzeFile} [featuredFile] featuredFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeFeaturedFileUsingPOST(recipeId: string, featuredFile?: GrowyzeFile, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Recipe> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).updateRecipeFeaturedFileUsingPOST(recipeId, featuredFile, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateRecipeFiles
         * @param {FilesDto} filesDto filesDto
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeFilesUsingPOST(filesDto: FilesDto, recipeId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Recipe> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).updateRecipeFilesUsingPOST(filesDto, recipeId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateRecipe
         * @param {Recipe} recipe recipe
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeUsingPUT(recipe: Recipe, recipeId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Recipe> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).updateRecipeUsingPUT(recipe, recipeId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateRecipesPosIds
         * @param {string} filePosIds file-pos-ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipesPosIdsUsingPATCH(filePosIds: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayRecipeControllerApiFetchParamCreator(configuration).updateRecipesPosIdsUsingPATCH(filePosIds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayRecipeControllerApi - factory interface
 * @export
 */
export const GatewayRecipeControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary copyRecipesFromMaster
         * @param {RecipeIdListDto} masterRecipes masterRecipes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyRecipesFromMasterUsingPOST(masterRecipes: RecipeIdListDto, options?: any) {
            return GatewayRecipeControllerApiFp(configuration).copyRecipesFromMasterUsingPOST(masterRecipes, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createRecipe
         * @param {Recipe} recipe recipe
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRecipeUsingPOST(recipe: Recipe, options?: any) {
            return GatewayRecipeControllerApiFp(configuration).createRecipeUsingPOST(recipe, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteRecipe
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecipeUsingDELETE(recipeId: string, options?: any) {
            return GatewayRecipeControllerApiFp(configuration).deleteRecipeUsingDELETE(recipeId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportRecipesAsCSV
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportRecipesAsCSVUsingGET(options?: any) {
            return GatewayRecipeControllerApiFp(configuration).exportRecipesAsCSVUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportRecipesWithDeletedItemsAsXlsx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportRecipesWithDeletedItemsAsXlsxUsingGET(options?: any) {
            return GatewayRecipeControllerApiFp(configuration).exportRecipesWithDeletedItemsAsXlsxUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getRecipeByBarcode
         * @param {string} barcode barcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeByBarcodeUsingGET(barcode: string, options?: any) {
            return GatewayRecipeControllerApiFp(configuration).getRecipeByBarcodeUsingGET(barcode, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getRecipeById
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipeByIdUsingGET(recipeId: string, options?: any) {
            return GatewayRecipeControllerApiFp(configuration).getRecipeByIdUsingGET(recipeId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getRecipesByCriteria
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialIngredientName] partialIngredientName
         * @param {string} [partialPosId] partialPosId
         * @param {string} [partialRecipeName] partialRecipeName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'DRAFT' | 'PUBLISHED'} [status] status
         * @param {boolean} [withoutFolder] withoutFolder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipesByCriteriaUsingGET(category?: string, page?: number, partialIngredientName?: string, partialPosId?: string, partialRecipeName?: string, size?: number, sort?: string, status?: 'DRAFT' | 'PUBLISHED', withoutFolder?: boolean, options?: any) {
            return GatewayRecipeControllerApiFp(configuration).getRecipesByCriteriaUsingGET(category, page, partialIngredientName, partialPosId, partialRecipeName, size, sort, status, withoutFolder, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getRecipesByIds
         * @param {RecipeIdListDto} recipes recipes
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipesByIdsUsingPOST(recipes: RecipeIdListDto, options?: any) {
            return GatewayRecipeControllerApiFp(configuration).getRecipesByIdsUsingPOST(recipes, options)(fetch, basePath);
        },
        /**
         * 
         * @summary recalculateAllRecipesWithLatestPrices
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        recalculateAllRecipesWithLatestPricesUsingPOST(options?: any) {
            return GatewayRecipeControllerApiFp(configuration).recalculateAllRecipesWithLatestPricesUsingPOST(options)(fetch, basePath);
        },
        /**
         * 
         * @summary setPublishedStatusToAllWithoutStatus
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPublishedStatusToAllWithoutStatusUsingPATCH(options?: any) {
            return GatewayRecipeControllerApiFp(configuration).setPublishedStatusToAllWithoutStatusUsingPATCH(options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateRecipeFeaturedFile
         * @param {string} recipeId recipeId
         * @param {GrowyzeFile} [featuredFile] featuredFile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeFeaturedFileUsingPOST(recipeId: string, featuredFile?: GrowyzeFile, options?: any) {
            return GatewayRecipeControllerApiFp(configuration).updateRecipeFeaturedFileUsingPOST(recipeId, featuredFile, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateRecipeFiles
         * @param {FilesDto} filesDto filesDto
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeFilesUsingPOST(filesDto: FilesDto, recipeId: string, options?: any) {
            return GatewayRecipeControllerApiFp(configuration).updateRecipeFilesUsingPOST(filesDto, recipeId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateRecipe
         * @param {Recipe} recipe recipe
         * @param {string} recipeId recipeId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeUsingPUT(recipe: Recipe, recipeId: string, options?: any) {
            return GatewayRecipeControllerApiFp(configuration).updateRecipeUsingPUT(recipe, recipeId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateRecipesPosIds
         * @param {string} filePosIds file-pos-ids
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipesPosIdsUsingPATCH(filePosIds: string, options?: any) {
            return GatewayRecipeControllerApiFp(configuration).updateRecipesPosIdsUsingPATCH(filePosIds, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayRecipeControllerApi - object-oriented interface
 * @export
 * @class GatewayRecipeControllerApi
 * @extends {BaseAPI}
 */
export class GatewayRecipeControllerApi extends BaseAPI {
    /**
     * 
     * @summary copyRecipesFromMaster
     * @param {RecipeIdListDto} masterRecipes masterRecipes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public copyRecipesFromMasterUsingPOST(masterRecipes: RecipeIdListDto, options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).copyRecipesFromMasterUsingPOST(masterRecipes, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createRecipe
     * @param {Recipe} recipe recipe
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public createRecipeUsingPOST(recipe: Recipe, options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).createRecipeUsingPOST(recipe, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteRecipe
     * @param {string} recipeId recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public deleteRecipeUsingDELETE(recipeId: string, options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).deleteRecipeUsingDELETE(recipeId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportRecipesAsCSV
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public exportRecipesAsCSVUsingGET(options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).exportRecipesAsCSVUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportRecipesWithDeletedItemsAsXlsx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public exportRecipesWithDeletedItemsAsXlsxUsingGET(options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).exportRecipesWithDeletedItemsAsXlsxUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getRecipeByBarcode
     * @param {string} barcode barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public getRecipeByBarcodeUsingGET(barcode: string, options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).getRecipeByBarcodeUsingGET(barcode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getRecipeById
     * @param {string} recipeId recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public getRecipeByIdUsingGET(recipeId: string, options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).getRecipeByIdUsingGET(recipeId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getRecipesByCriteria
     * @param {string} [category] category
     * @param {number} [page] 
     * @param {string} [partialIngredientName] partialIngredientName
     * @param {string} [partialPosId] partialPosId
     * @param {string} [partialRecipeName] partialRecipeName
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {'DRAFT' | 'PUBLISHED'} [status] status
     * @param {boolean} [withoutFolder] withoutFolder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public getRecipesByCriteriaUsingGET(category?: string, page?: number, partialIngredientName?: string, partialPosId?: string, partialRecipeName?: string, size?: number, sort?: string, status?: 'DRAFT' | 'PUBLISHED', withoutFolder?: boolean, options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).getRecipesByCriteriaUsingGET(category, page, partialIngredientName, partialPosId, partialRecipeName, size, sort, status, withoutFolder, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getRecipesByIds
     * @param {RecipeIdListDto} recipes recipes
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public getRecipesByIdsUsingPOST(recipes: RecipeIdListDto, options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).getRecipesByIdsUsingPOST(recipes, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary recalculateAllRecipesWithLatestPrices
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public recalculateAllRecipesWithLatestPricesUsingPOST(options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).recalculateAllRecipesWithLatestPricesUsingPOST(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setPublishedStatusToAllWithoutStatus
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public setPublishedStatusToAllWithoutStatusUsingPATCH(options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).setPublishedStatusToAllWithoutStatusUsingPATCH(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateRecipeFeaturedFile
     * @param {string} recipeId recipeId
     * @param {GrowyzeFile} [featuredFile] featuredFile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public updateRecipeFeaturedFileUsingPOST(recipeId: string, featuredFile?: GrowyzeFile, options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).updateRecipeFeaturedFileUsingPOST(recipeId, featuredFile, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateRecipeFiles
     * @param {FilesDto} filesDto filesDto
     * @param {string} recipeId recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public updateRecipeFilesUsingPOST(filesDto: FilesDto, recipeId: string, options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).updateRecipeFilesUsingPOST(filesDto, recipeId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateRecipe
     * @param {Recipe} recipe recipe
     * @param {string} recipeId recipeId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public updateRecipeUsingPUT(recipe: Recipe, recipeId: string, options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).updateRecipeUsingPUT(recipe, recipeId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateRecipesPosIds
     * @param {string} filePosIds file-pos-ids
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayRecipeControllerApi
     */
    public updateRecipesPosIdsUsingPATCH(filePosIds: string, options?: any) {
        return GatewayRecipeControllerApiFp(this.configuration).updateRecipesPosIdsUsingPATCH(filePosIds, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewaySalesControllerApi - fetch parameter creator
 * @export
 */
export const GatewaySalesControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary convertFile
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertFileUsingPOST1(file: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling convertFileUsingPOST1.');
            }
            const localVarPath = `/v1/sales/convert`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(file || {}) : (file || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createSalesFromClover
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromCloverUsingPOST(from: Date, to: Date, options: any = {}): FetchArgs {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling createSalesFromCloverUsingPOST.');
            }
            // verify required parameter 'to' is not null or undefined
            if (to === null || to === undefined) {
                throw new RequiredError('to','Required parameter to was null or undefined when calling createSalesFromCloverUsingPOST.');
            }
            const localVarPath = `/v1/sales/extract-from-clover`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createSalesFromGoEpos
         * @param {GoEposSalesPayload} salesPayload salesPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromGoEposUsingPOST(salesPayload: GoEposSalesPayload, options: any = {}): FetchArgs {
            // verify required parameter 'salesPayload' is not null or undefined
            if (salesPayload === null || salesPayload === undefined) {
                throw new RequiredError('salesPayload','Required parameter salesPayload was null or undefined when calling createSalesFromGoEposUsingPOST.');
            }
            const localVarPath = `/v1/sales/goepos`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"GoEposSalesPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(salesPayload || {}) : (salesPayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createSalesFromLightspeed
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromLightspeedUsingPOST(from: Date, to: Date, options: any = {}): FetchArgs {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling createSalesFromLightspeedUsingPOST.');
            }
            // verify required parameter 'to' is not null or undefined
            if (to === null || to === undefined) {
                throw new RequiredError('to','Required parameter to was null or undefined when calling createSalesFromLightspeedUsingPOST.');
            }
            const localVarPath = `/v1/sales/extract-from-lightspeed`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createSalesFromNewbridge
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromNewbridgeUsingPOST(from: Date, to: Date, options: any = {}): FetchArgs {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling createSalesFromNewbridgeUsingPOST.');
            }
            // verify required parameter 'to' is not null or undefined
            if (to === null || to === undefined) {
                throw new RequiredError('to','Required parameter to was null or undefined when calling createSalesFromNewbridgeUsingPOST.');
            }
            const localVarPath = `/v1/sales/extract-from-newbridge`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createSalesFromSquare
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromSquareUsingPOST(from: Date, to: Date, options: any = {}): FetchArgs {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling createSalesFromSquareUsingPOST.');
            }
            // verify required parameter 'to' is not null or undefined
            if (to === null || to === undefined) {
                throw new RequiredError('to','Required parameter to was null or undefined when calling createSalesFromSquareUsingPOST.');
            }
            const localVarPath = `/v1/sales/extract-from-square`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createSalesFromSquareWebhook
         * @param {SquarePaymentEvent} paymentEvent paymentEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromSquareWebhookUsingPOST(paymentEvent: SquarePaymentEvent, options: any = {}): FetchArgs {
            // verify required parameter 'paymentEvent' is not null or undefined
            if (paymentEvent === null || paymentEvent === undefined) {
                throw new RequiredError('paymentEvent','Required parameter paymentEvent was null or undefined when calling createSalesFromSquareWebhookUsingPOST.');
            }
            const localVarPath = `/v1/sales/square/webhook`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SquarePaymentEvent" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(paymentEvent || {}) : (paymentEvent || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createSalesFromWooCommerce
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromWooCommerceUsingPOST(from: Date, to: Date, options: any = {}): FetchArgs {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling createSalesFromWooCommerceUsingPOST.');
            }
            // verify required parameter 'to' is not null or undefined
            if (to === null || to === undefined) {
                throw new RequiredError('to','Required parameter to was null or undefined when calling createSalesFromWooCommerceUsingPOST.');
            }
            const localVarPath = `/v1/sales/extract-from-woocommerce`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createSales
         * @param {SalesPayload} salesPayload salesPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesUsingPOST(salesPayload: SalesPayload, options: any = {}): FetchArgs {
            // verify required parameter 'salesPayload' is not null or undefined
            if (salesPayload === null || salesPayload === undefined) {
                throw new RequiredError('salesPayload','Required parameter salesPayload was null or undefined when calling createSalesUsingPOST.');
            }
            const localVarPath = `/v1/sales`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SalesPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(salesPayload || {}) : (salesPayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteLiveSalesBeforeThreeMonths
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLiveSalesBeforeThreeMonthsUsingDELETE(options: any = {}): FetchArgs {
            const localVarPath = `/v1/sales/live`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteSales
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSalesUsingDELETE(salesId: string, options: any = {}): FetchArgs {
            // verify required parameter 'salesId' is not null or undefined
            if (salesId === null || salesId === undefined) {
                throw new RequiredError('salesId','Required parameter salesId was null or undefined when calling deleteSalesUsingDELETE.');
            }
            const localVarPath = `/v1/sales/{salesId}`
                .replace(`{${"salesId"}}`, encodeURIComponent(String(salesId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportSalesAsCsv
         * @param {string} fileName fileName
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSalesAsCsvUsingGET(fileName: string, salesId: string, options: any = {}): FetchArgs {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling exportSalesAsCsvUsingGET.');
            }
            // verify required parameter 'salesId' is not null or undefined
            if (salesId === null || salesId === undefined) {
                throw new RequiredError('salesId','Required parameter salesId was null or undefined when calling exportSalesAsCsvUsingGET.');
            }
            const localVarPath = `/v1/sales/{salesId}/export-as-csv`
                .replace(`{${"salesId"}}`, encodeURIComponent(String(salesId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportSalesAsXlsx
         * @param {string} fileName fileName
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSalesAsXlsxUsingGET(fileName: string, salesId: string, options: any = {}): FetchArgs {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling exportSalesAsXlsxUsingGET.');
            }
            // verify required parameter 'salesId' is not null or undefined
            if (salesId === null || salesId === undefined) {
                throw new RequiredError('salesId','Required parameter salesId was null or undefined when calling exportSalesAsXlsxUsingGET.');
            }
            const localVarPath = `/v1/sales/{salesId}/export-as-xlsx`
                .replace(`{${"salesId"}}`, encodeURIComponent(String(salesId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllSalesWithoutItems
         * @param {Date} [from] from
         * @param {boolean} [live] live
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSalesWithoutItemsUsingGET(from?: Date, live?: boolean, page?: number, size?: number, sort?: string, to?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/v1/sales`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (live !== undefined) {
                localVarQueryParameter['live'] = live;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSaleItemWithLastPrice
         * @param {string} posId posId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleItemWithLastPriceUsingGET(posId: string, options: any = {}): FetchArgs {
            // verify required parameter 'posId' is not null or undefined
            if (posId === null || posId === undefined) {
                throw new RequiredError('posId','Required parameter posId was null or undefined when calling getSaleItemWithLastPriceUsingGET.');
            }
            const localVarPath = `/v1/sales/products/last-sale-price/{posId}`
                .replace(`{${"posId"}}`, encodeURIComponent(String(posId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSalesById
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesByIdUsingGET(salesId: string, options: any = {}): FetchArgs {
            // verify required parameter 'salesId' is not null or undefined
            if (salesId === null || salesId === undefined) {
                throw new RequiredError('salesId','Required parameter salesId was null or undefined when calling getSalesByIdUsingGET.');
            }
            const localVarPath = `/v1/sales/{salesId}`
                .replace(`{${"salesId"}}`, encodeURIComponent(String(salesId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSalesProductsByCriteria
         * @param {string} salesId salesId
         * @param {string} [partialPosId] partialPosId
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesProductsByCriteriaUsingGET(salesId: string, partialPosId?: string, partialProductName?: string, options: any = {}): FetchArgs {
            // verify required parameter 'salesId' is not null or undefined
            if (salesId === null || salesId === undefined) {
                throw new RequiredError('salesId','Required parameter salesId was null or undefined when calling getSalesProductsByCriteriaUsingGET.');
            }
            const localVarPath = `/v1/sales/{salesId}/products`
                .replace(`{${"salesId"}}`, encodeURIComponent(String(salesId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partialPosId !== undefined) {
                localVarQueryParameter['partialPosId'] = partialPosId;
            }

            if (partialProductName !== undefined) {
                localVarQueryParameter['partialProductName'] = partialProductName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSoldProductsByBarcodesInTimeInterval
         * @param {Array<string>} barcodes barcodes
         * @param {Date} from from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoldProductsByBarcodesInTimeIntervalUsingPOST(barcodes: Array<string>, from: Date, to?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'barcodes' is not null or undefined
            if (barcodes === null || barcodes === undefined) {
                throw new RequiredError('barcodes','Required parameter barcodes was null or undefined when calling getSoldProductsByBarcodesInTimeIntervalUsingPOST.');
            }
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling getSoldProductsByBarcodesInTimeIntervalUsingPOST.');
            }
            const localVarPath = `/v1/sales/products`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;string&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(barcodes || {}) : (barcodes || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setSalesOriginToAllWithoutIt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSalesOriginToAllWithoutItUsingPATCH(options: any = {}): FetchArgs {
            const localVarPath = `/v1/sales/sales-origin`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateNonMatchingPosIds
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNonMatchingPosIdsUsingPUT(salesId: string, options: any = {}): FetchArgs {
            // verify required parameter 'salesId' is not null or undefined
            if (salesId === null || salesId === undefined) {
                throw new RequiredError('salesId','Required parameter salesId was null or undefined when calling updateNonMatchingPosIdsUsingPUT.');
            }
            const localVarPath = `/v1/sales/{salesId}/non-matching-posIds`
                .replace(`{${"salesId"}}`, encodeURIComponent(String(salesId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewaySalesControllerApi - functional programming interface
 * @export
 */
export const GatewaySalesControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary convertFile
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertFileUsingPOST1(file: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Table> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).convertFileUsingPOST1(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createSalesFromClover
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromCloverUsingPOST(from: Date, to: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Sales> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).createSalesFromCloverUsingPOST(from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createSalesFromGoEpos
         * @param {GoEposSalesPayload} salesPayload salesPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromGoEposUsingPOST(salesPayload: GoEposSalesPayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<GoEposSalesResponse> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).createSalesFromGoEposUsingPOST(salesPayload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createSalesFromLightspeed
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromLightspeedUsingPOST(from: Date, to: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Sales> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).createSalesFromLightspeedUsingPOST(from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createSalesFromNewbridge
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromNewbridgeUsingPOST(from: Date, to: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Sales> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).createSalesFromNewbridgeUsingPOST(from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createSalesFromSquare
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromSquareUsingPOST(from: Date, to: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Sales> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).createSalesFromSquareUsingPOST(from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createSalesFromSquareWebhook
         * @param {SquarePaymentEvent} paymentEvent paymentEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromSquareWebhookUsingPOST(paymentEvent: SquarePaymentEvent, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).createSalesFromSquareWebhookUsingPOST(paymentEvent, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createSalesFromWooCommerce
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromWooCommerceUsingPOST(from: Date, to: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Sales> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).createSalesFromWooCommerceUsingPOST(from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createSales
         * @param {SalesPayload} salesPayload salesPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesUsingPOST(salesPayload: SalesPayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Sales> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).createSalesUsingPOST(salesPayload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteLiveSalesBeforeThreeMonths
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLiveSalesBeforeThreeMonthsUsingDELETE(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).deleteLiveSalesBeforeThreeMonthsUsingDELETE(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteSales
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSalesUsingDELETE(salesId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).deleteSalesUsingDELETE(salesId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportSalesAsCsv
         * @param {string} fileName fileName
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSalesAsCsvUsingGET(fileName: string, salesId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).exportSalesAsCsvUsingGET(fileName, salesId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportSalesAsXlsx
         * @param {string} fileName fileName
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSalesAsXlsxUsingGET(fileName: string, salesId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).exportSalesAsXlsxUsingGET(fileName, salesId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllSalesWithoutItems
         * @param {Date} [from] from
         * @param {boolean} [live] live
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSalesWithoutItemsUsingGET(from?: Date, live?: boolean, page?: number, size?: number, sort?: string, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageSales> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).getAllSalesWithoutItemsUsingGET(from, live, page, size, sort, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSaleItemWithLastPrice
         * @param {string} posId posId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleItemWithLastPriceUsingGET(posId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SaleItemDto> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).getSaleItemWithLastPriceUsingGET(posId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSalesById
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesByIdUsingGET(salesId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Sales> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).getSalesByIdUsingGET(salesId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSalesProductsByCriteria
         * @param {string} salesId salesId
         * @param {string} [partialPosId] partialPosId
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesProductsByCriteriaUsingGET(salesId: string, partialPosId?: string, partialProductName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SaleItem>> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).getSalesProductsByCriteriaUsingGET(salesId, partialPosId, partialProductName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSoldProductsByBarcodesInTimeInterval
         * @param {Array<string>} barcodes barcodes
         * @param {Date} from from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoldProductsByBarcodesInTimeIntervalUsingPOST(barcodes: Array<string>, from: Date, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SoldProductDto>> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).getSoldProductsByBarcodesInTimeIntervalUsingPOST(barcodes, from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setSalesOriginToAllWithoutIt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSalesOriginToAllWithoutItUsingPATCH(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).setSalesOriginToAllWithoutItUsingPATCH(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateNonMatchingPosIds
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNonMatchingPosIdsUsingPUT(salesId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Sales> {
            const localVarFetchArgs = GatewaySalesControllerApiFetchParamCreator(configuration).updateNonMatchingPosIdsUsingPUT(salesId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewaySalesControllerApi - factory interface
 * @export
 */
export const GatewaySalesControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary convertFile
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertFileUsingPOST1(file: string, options?: any) {
            return GatewaySalesControllerApiFp(configuration).convertFileUsingPOST1(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createSalesFromClover
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromCloverUsingPOST(from: Date, to: Date, options?: any) {
            return GatewaySalesControllerApiFp(configuration).createSalesFromCloverUsingPOST(from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createSalesFromGoEpos
         * @param {GoEposSalesPayload} salesPayload salesPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromGoEposUsingPOST(salesPayload: GoEposSalesPayload, options?: any) {
            return GatewaySalesControllerApiFp(configuration).createSalesFromGoEposUsingPOST(salesPayload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createSalesFromLightspeed
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromLightspeedUsingPOST(from: Date, to: Date, options?: any) {
            return GatewaySalesControllerApiFp(configuration).createSalesFromLightspeedUsingPOST(from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createSalesFromNewbridge
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromNewbridgeUsingPOST(from: Date, to: Date, options?: any) {
            return GatewaySalesControllerApiFp(configuration).createSalesFromNewbridgeUsingPOST(from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createSalesFromSquare
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromSquareUsingPOST(from: Date, to: Date, options?: any) {
            return GatewaySalesControllerApiFp(configuration).createSalesFromSquareUsingPOST(from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createSalesFromSquareWebhook
         * @param {SquarePaymentEvent} paymentEvent paymentEvent
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromSquareWebhookUsingPOST(paymentEvent: SquarePaymentEvent, options?: any) {
            return GatewaySalesControllerApiFp(configuration).createSalesFromSquareWebhookUsingPOST(paymentEvent, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createSalesFromWooCommerce
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesFromWooCommerceUsingPOST(from: Date, to: Date, options?: any) {
            return GatewaySalesControllerApiFp(configuration).createSalesFromWooCommerceUsingPOST(from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createSales
         * @param {SalesPayload} salesPayload salesPayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSalesUsingPOST(salesPayload: SalesPayload, options?: any) {
            return GatewaySalesControllerApiFp(configuration).createSalesUsingPOST(salesPayload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteLiveSalesBeforeThreeMonths
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteLiveSalesBeforeThreeMonthsUsingDELETE(options?: any) {
            return GatewaySalesControllerApiFp(configuration).deleteLiveSalesBeforeThreeMonthsUsingDELETE(options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteSales
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSalesUsingDELETE(salesId: string, options?: any) {
            return GatewaySalesControllerApiFp(configuration).deleteSalesUsingDELETE(salesId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportSalesAsCsv
         * @param {string} fileName fileName
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSalesAsCsvUsingGET(fileName: string, salesId: string, options?: any) {
            return GatewaySalesControllerApiFp(configuration).exportSalesAsCsvUsingGET(fileName, salesId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportSalesAsXlsx
         * @param {string} fileName fileName
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportSalesAsXlsxUsingGET(fileName: string, salesId: string, options?: any) {
            return GatewaySalesControllerApiFp(configuration).exportSalesAsXlsxUsingGET(fileName, salesId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllSalesWithoutItems
         * @param {Date} [from] from
         * @param {boolean} [live] live
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllSalesWithoutItemsUsingGET(from?: Date, live?: boolean, page?: number, size?: number, sort?: string, to?: Date, options?: any) {
            return GatewaySalesControllerApiFp(configuration).getAllSalesWithoutItemsUsingGET(from, live, page, size, sort, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSaleItemWithLastPrice
         * @param {string} posId posId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSaleItemWithLastPriceUsingGET(posId: string, options?: any) {
            return GatewaySalesControllerApiFp(configuration).getSaleItemWithLastPriceUsingGET(posId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSalesById
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesByIdUsingGET(salesId: string, options?: any) {
            return GatewaySalesControllerApiFp(configuration).getSalesByIdUsingGET(salesId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSalesProductsByCriteria
         * @param {string} salesId salesId
         * @param {string} [partialPosId] partialPosId
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSalesProductsByCriteriaUsingGET(salesId: string, partialPosId?: string, partialProductName?: string, options?: any) {
            return GatewaySalesControllerApiFp(configuration).getSalesProductsByCriteriaUsingGET(salesId, partialPosId, partialProductName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSoldProductsByBarcodesInTimeInterval
         * @param {Array<string>} barcodes barcodes
         * @param {Date} from from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSoldProductsByBarcodesInTimeIntervalUsingPOST(barcodes: Array<string>, from: Date, to?: Date, options?: any) {
            return GatewaySalesControllerApiFp(configuration).getSoldProductsByBarcodesInTimeIntervalUsingPOST(barcodes, from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setSalesOriginToAllWithoutIt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setSalesOriginToAllWithoutItUsingPATCH(options?: any) {
            return GatewaySalesControllerApiFp(configuration).setSalesOriginToAllWithoutItUsingPATCH(options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateNonMatchingPosIds
         * @param {string} salesId salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNonMatchingPosIdsUsingPUT(salesId: string, options?: any) {
            return GatewaySalesControllerApiFp(configuration).updateNonMatchingPosIdsUsingPUT(salesId, options)(fetch, basePath);
        },
    };
};

/**
 * GatewaySalesControllerApi - object-oriented interface
 * @export
 * @class GatewaySalesControllerApi
 * @extends {BaseAPI}
 */
export class GatewaySalesControllerApi extends BaseAPI {
    /**
     * 
     * @summary convertFile
     * @param {string} file file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public convertFileUsingPOST1(file: string, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).convertFileUsingPOST1(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createSalesFromClover
     * @param {Date} from from
     * @param {Date} to to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public createSalesFromCloverUsingPOST(from: Date, to: Date, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).createSalesFromCloverUsingPOST(from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createSalesFromGoEpos
     * @param {GoEposSalesPayload} salesPayload salesPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public createSalesFromGoEposUsingPOST(salesPayload: GoEposSalesPayload, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).createSalesFromGoEposUsingPOST(salesPayload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createSalesFromLightspeed
     * @param {Date} from from
     * @param {Date} to to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public createSalesFromLightspeedUsingPOST(from: Date, to: Date, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).createSalesFromLightspeedUsingPOST(from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createSalesFromNewbridge
     * @param {Date} from from
     * @param {Date} to to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public createSalesFromNewbridgeUsingPOST(from: Date, to: Date, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).createSalesFromNewbridgeUsingPOST(from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createSalesFromSquare
     * @param {Date} from from
     * @param {Date} to to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public createSalesFromSquareUsingPOST(from: Date, to: Date, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).createSalesFromSquareUsingPOST(from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createSalesFromSquareWebhook
     * @param {SquarePaymentEvent} paymentEvent paymentEvent
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public createSalesFromSquareWebhookUsingPOST(paymentEvent: SquarePaymentEvent, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).createSalesFromSquareWebhookUsingPOST(paymentEvent, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createSalesFromWooCommerce
     * @param {Date} from from
     * @param {Date} to to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public createSalesFromWooCommerceUsingPOST(from: Date, to: Date, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).createSalesFromWooCommerceUsingPOST(from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createSales
     * @param {SalesPayload} salesPayload salesPayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public createSalesUsingPOST(salesPayload: SalesPayload, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).createSalesUsingPOST(salesPayload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteLiveSalesBeforeThreeMonths
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public deleteLiveSalesBeforeThreeMonthsUsingDELETE(options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).deleteLiveSalesBeforeThreeMonthsUsingDELETE(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteSales
     * @param {string} salesId salesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public deleteSalesUsingDELETE(salesId: string, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).deleteSalesUsingDELETE(salesId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportSalesAsCsv
     * @param {string} fileName fileName
     * @param {string} salesId salesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public exportSalesAsCsvUsingGET(fileName: string, salesId: string, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).exportSalesAsCsvUsingGET(fileName, salesId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportSalesAsXlsx
     * @param {string} fileName fileName
     * @param {string} salesId salesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public exportSalesAsXlsxUsingGET(fileName: string, salesId: string, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).exportSalesAsXlsxUsingGET(fileName, salesId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllSalesWithoutItems
     * @param {Date} [from] from
     * @param {boolean} [live] live
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public getAllSalesWithoutItemsUsingGET(from?: Date, live?: boolean, page?: number, size?: number, sort?: string, to?: Date, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).getAllSalesWithoutItemsUsingGET(from, live, page, size, sort, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSaleItemWithLastPrice
     * @param {string} posId posId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public getSaleItemWithLastPriceUsingGET(posId: string, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).getSaleItemWithLastPriceUsingGET(posId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSalesById
     * @param {string} salesId salesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public getSalesByIdUsingGET(salesId: string, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).getSalesByIdUsingGET(salesId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSalesProductsByCriteria
     * @param {string} salesId salesId
     * @param {string} [partialPosId] partialPosId
     * @param {string} [partialProductName] partialProductName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public getSalesProductsByCriteriaUsingGET(salesId: string, partialPosId?: string, partialProductName?: string, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).getSalesProductsByCriteriaUsingGET(salesId, partialPosId, partialProductName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSoldProductsByBarcodesInTimeInterval
     * @param {Array<string>} barcodes barcodes
     * @param {Date} from from
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public getSoldProductsByBarcodesInTimeIntervalUsingPOST(barcodes: Array<string>, from: Date, to?: Date, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).getSoldProductsByBarcodesInTimeIntervalUsingPOST(barcodes, from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setSalesOriginToAllWithoutIt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public setSalesOriginToAllWithoutItUsingPATCH(options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).setSalesOriginToAllWithoutItUsingPATCH(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateNonMatchingPosIds
     * @param {string} salesId salesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySalesControllerApi
     */
    public updateNonMatchingPosIdsUsingPUT(salesId: string, options?: any) {
        return GatewaySalesControllerApiFp(this.configuration).updateNonMatchingPosIdsUsingPUT(salesId, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewaySquarePosIntegrationControllerApi - fetch parameter creator
 * @export
 */
export const GatewaySquarePosIntegrationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createSquarePosIntegration
         * @param {PosIntegrationCreatePayload} posIntegrationCreatePayload posIntegrationCreatePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSquarePosIntegrationUsingPOST(posIntegrationCreatePayload: PosIntegrationCreatePayload, options: any = {}): FetchArgs {
            // verify required parameter 'posIntegrationCreatePayload' is not null or undefined
            if (posIntegrationCreatePayload === null || posIntegrationCreatePayload === undefined) {
                throw new RequiredError('posIntegrationCreatePayload','Required parameter posIntegrationCreatePayload was null or undefined when calling createSquarePosIntegrationUsingPOST.');
            }
            const localVarPath = `/v1/square-pos-integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PosIntegrationCreatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(posIntegrationCreatePayload || {}) : (posIntegrationCreatePayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteSquarePosIntegrationById
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSquarePosIntegrationByIdUsingDELETE(squarePosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'squarePosIntegrationId' is not null or undefined
            if (squarePosIntegrationId === null || squarePosIntegrationId === undefined) {
                throw new RequiredError('squarePosIntegrationId','Required parameter squarePosIntegrationId was null or undefined when calling deleteSquarePosIntegrationByIdUsingDELETE.');
            }
            const localVarPath = `/v1/square-pos-integrations/{squarePosIntegrationId}`
                .replace(`{${"squarePosIntegrationId"}}`, encodeURIComponent(String(squarePosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getClientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientIdUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/square-pos-integrations/client-id`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getLocationsFromSquare
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsFromSquareUsingGET(squarePosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'squarePosIntegrationId' is not null or undefined
            if (squarePosIntegrationId === null || squarePosIntegrationId === undefined) {
                throw new RequiredError('squarePosIntegrationId','Required parameter squarePosIntegrationId was null or undefined when calling getLocationsFromSquareUsingGET.');
            }
            const localVarPath = `/v1/square-pos-integrations/locations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (squarePosIntegrationId !== undefined) {
                localVarQueryParameter['squarePosIntegrationId'] = squarePosIntegrationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosIntegrationsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/square-pos-integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSquarePosIntegrationById
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSquarePosIntegrationByIdUsingGET(squarePosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'squarePosIntegrationId' is not null or undefined
            if (squarePosIntegrationId === null || squarePosIntegrationId === undefined) {
                throw new RequiredError('squarePosIntegrationId','Required parameter squarePosIntegrationId was null or undefined when calling getSquarePosIntegrationByIdUsingGET.');
            }
            const localVarPath = `/v1/square-pos-integrations/{squarePosIntegrationId}`
                .replace(`{${"squarePosIntegrationId"}}`, encodeURIComponent(String(squarePosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateSquarePosIntegrationById
         * @param {PosIntegrationUpdatePayload} posIntegrationUpdatePayload posIntegrationUpdatePayload
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSquarePosIntegrationByIdUsingPATCH(posIntegrationUpdatePayload: PosIntegrationUpdatePayload, squarePosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'posIntegrationUpdatePayload' is not null or undefined
            if (posIntegrationUpdatePayload === null || posIntegrationUpdatePayload === undefined) {
                throw new RequiredError('posIntegrationUpdatePayload','Required parameter posIntegrationUpdatePayload was null or undefined when calling updateSquarePosIntegrationByIdUsingPATCH.');
            }
            // verify required parameter 'squarePosIntegrationId' is not null or undefined
            if (squarePosIntegrationId === null || squarePosIntegrationId === undefined) {
                throw new RequiredError('squarePosIntegrationId','Required parameter squarePosIntegrationId was null or undefined when calling updateSquarePosIntegrationByIdUsingPATCH.');
            }
            const localVarPath = `/v1/square-pos-integrations/{squarePosIntegrationId}`
                .replace(`{${"squarePosIntegrationId"}}`, encodeURIComponent(String(squarePosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PosIntegrationUpdatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(posIntegrationUpdatePayload || {}) : (posIntegrationUpdatePayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateSquarePosIntegrationLocation
         * @param {string} locationId locationId
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSquarePosIntegrationLocationUsingPATCH(locationId: string, squarePosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'locationId' is not null or undefined
            if (locationId === null || locationId === undefined) {
                throw new RequiredError('locationId','Required parameter locationId was null or undefined when calling updateSquarePosIntegrationLocationUsingPATCH.');
            }
            // verify required parameter 'squarePosIntegrationId' is not null or undefined
            if (squarePosIntegrationId === null || squarePosIntegrationId === undefined) {
                throw new RequiredError('squarePosIntegrationId','Required parameter squarePosIntegrationId was null or undefined when calling updateSquarePosIntegrationLocationUsingPATCH.');
            }
            const localVarPath = `/v1/square-pos-integrations/{squarePosIntegrationId}/location/{locationId}`
                .replace(`{${"locationId"}}`, encodeURIComponent(String(locationId)))
                .replace(`{${"squarePosIntegrationId"}}`, encodeURIComponent(String(squarePosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewaySquarePosIntegrationControllerApi - functional programming interface
 * @export
 */
export const GatewaySquarePosIntegrationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createSquarePosIntegration
         * @param {PosIntegrationCreatePayload} posIntegrationCreatePayload posIntegrationCreatePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSquarePosIntegrationUsingPOST(posIntegrationCreatePayload: PosIntegrationCreatePayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SquarePosIntegration> {
            const localVarFetchArgs = GatewaySquarePosIntegrationControllerApiFetchParamCreator(configuration).createSquarePosIntegrationUsingPOST(posIntegrationCreatePayload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteSquarePosIntegrationById
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSquarePosIntegrationByIdUsingDELETE(squarePosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySquarePosIntegrationControllerApiFetchParamCreator(configuration).deleteSquarePosIntegrationByIdUsingDELETE(squarePosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getClientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientIdUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewaySquarePosIntegrationControllerApiFetchParamCreator(configuration).getClientIdUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getLocationsFromSquare
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsFromSquareUsingGET(squarePosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<LocationsDto> {
            const localVarFetchArgs = GatewaySquarePosIntegrationControllerApiFetchParamCreator(configuration).getLocationsFromSquareUsingGET(squarePosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getPosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosIntegrationsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SquarePosIntegration>> {
            const localVarFetchArgs = GatewaySquarePosIntegrationControllerApiFetchParamCreator(configuration).getPosIntegrationsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSquarePosIntegrationById
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSquarePosIntegrationByIdUsingGET(squarePosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SquarePosIntegration> {
            const localVarFetchArgs = GatewaySquarePosIntegrationControllerApiFetchParamCreator(configuration).getSquarePosIntegrationByIdUsingGET(squarePosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateSquarePosIntegrationById
         * @param {PosIntegrationUpdatePayload} posIntegrationUpdatePayload posIntegrationUpdatePayload
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSquarePosIntegrationByIdUsingPATCH(posIntegrationUpdatePayload: PosIntegrationUpdatePayload, squarePosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SquarePosIntegration> {
            const localVarFetchArgs = GatewaySquarePosIntegrationControllerApiFetchParamCreator(configuration).updateSquarePosIntegrationByIdUsingPATCH(posIntegrationUpdatePayload, squarePosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateSquarePosIntegrationLocation
         * @param {string} locationId locationId
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSquarePosIntegrationLocationUsingPATCH(locationId: string, squarePosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SquarePosIntegration> {
            const localVarFetchArgs = GatewaySquarePosIntegrationControllerApiFetchParamCreator(configuration).updateSquarePosIntegrationLocationUsingPATCH(locationId, squarePosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewaySquarePosIntegrationControllerApi - factory interface
 * @export
 */
export const GatewaySquarePosIntegrationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createSquarePosIntegration
         * @param {PosIntegrationCreatePayload} posIntegrationCreatePayload posIntegrationCreatePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSquarePosIntegrationUsingPOST(posIntegrationCreatePayload: PosIntegrationCreatePayload, options?: any) {
            return GatewaySquarePosIntegrationControllerApiFp(configuration).createSquarePosIntegrationUsingPOST(posIntegrationCreatePayload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteSquarePosIntegrationById
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSquarePosIntegrationByIdUsingDELETE(squarePosIntegrationId: string, options?: any) {
            return GatewaySquarePosIntegrationControllerApiFp(configuration).deleteSquarePosIntegrationByIdUsingDELETE(squarePosIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getClientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientIdUsingGET(options?: any) {
            return GatewaySquarePosIntegrationControllerApiFp(configuration).getClientIdUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getLocationsFromSquare
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocationsFromSquareUsingGET(squarePosIntegrationId: string, options?: any) {
            return GatewaySquarePosIntegrationControllerApiFp(configuration).getLocationsFromSquareUsingGET(squarePosIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getPosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPosIntegrationsUsingGET(options?: any) {
            return GatewaySquarePosIntegrationControllerApiFp(configuration).getPosIntegrationsUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSquarePosIntegrationById
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSquarePosIntegrationByIdUsingGET(squarePosIntegrationId: string, options?: any) {
            return GatewaySquarePosIntegrationControllerApiFp(configuration).getSquarePosIntegrationByIdUsingGET(squarePosIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateSquarePosIntegrationById
         * @param {PosIntegrationUpdatePayload} posIntegrationUpdatePayload posIntegrationUpdatePayload
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSquarePosIntegrationByIdUsingPATCH(posIntegrationUpdatePayload: PosIntegrationUpdatePayload, squarePosIntegrationId: string, options?: any) {
            return GatewaySquarePosIntegrationControllerApiFp(configuration).updateSquarePosIntegrationByIdUsingPATCH(posIntegrationUpdatePayload, squarePosIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateSquarePosIntegrationLocation
         * @param {string} locationId locationId
         * @param {string} squarePosIntegrationId squarePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSquarePosIntegrationLocationUsingPATCH(locationId: string, squarePosIntegrationId: string, options?: any) {
            return GatewaySquarePosIntegrationControllerApiFp(configuration).updateSquarePosIntegrationLocationUsingPATCH(locationId, squarePosIntegrationId, options)(fetch, basePath);
        },
    };
};

/**
 * GatewaySquarePosIntegrationControllerApi - object-oriented interface
 * @export
 * @class GatewaySquarePosIntegrationControllerApi
 * @extends {BaseAPI}
 */
export class GatewaySquarePosIntegrationControllerApi extends BaseAPI {
    /**
     * 
     * @summary createSquarePosIntegration
     * @param {PosIntegrationCreatePayload} posIntegrationCreatePayload posIntegrationCreatePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySquarePosIntegrationControllerApi
     */
    public createSquarePosIntegrationUsingPOST(posIntegrationCreatePayload: PosIntegrationCreatePayload, options?: any) {
        return GatewaySquarePosIntegrationControllerApiFp(this.configuration).createSquarePosIntegrationUsingPOST(posIntegrationCreatePayload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteSquarePosIntegrationById
     * @param {string} squarePosIntegrationId squarePosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySquarePosIntegrationControllerApi
     */
    public deleteSquarePosIntegrationByIdUsingDELETE(squarePosIntegrationId: string, options?: any) {
        return GatewaySquarePosIntegrationControllerApiFp(this.configuration).deleteSquarePosIntegrationByIdUsingDELETE(squarePosIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getClientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySquarePosIntegrationControllerApi
     */
    public getClientIdUsingGET(options?: any) {
        return GatewaySquarePosIntegrationControllerApiFp(this.configuration).getClientIdUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getLocationsFromSquare
     * @param {string} squarePosIntegrationId squarePosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySquarePosIntegrationControllerApi
     */
    public getLocationsFromSquareUsingGET(squarePosIntegrationId: string, options?: any) {
        return GatewaySquarePosIntegrationControllerApiFp(this.configuration).getLocationsFromSquareUsingGET(squarePosIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getPosIntegrations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySquarePosIntegrationControllerApi
     */
    public getPosIntegrationsUsingGET(options?: any) {
        return GatewaySquarePosIntegrationControllerApiFp(this.configuration).getPosIntegrationsUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSquarePosIntegrationById
     * @param {string} squarePosIntegrationId squarePosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySquarePosIntegrationControllerApi
     */
    public getSquarePosIntegrationByIdUsingGET(squarePosIntegrationId: string, options?: any) {
        return GatewaySquarePosIntegrationControllerApiFp(this.configuration).getSquarePosIntegrationByIdUsingGET(squarePosIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateSquarePosIntegrationById
     * @param {PosIntegrationUpdatePayload} posIntegrationUpdatePayload posIntegrationUpdatePayload
     * @param {string} squarePosIntegrationId squarePosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySquarePosIntegrationControllerApi
     */
    public updateSquarePosIntegrationByIdUsingPATCH(posIntegrationUpdatePayload: PosIntegrationUpdatePayload, squarePosIntegrationId: string, options?: any) {
        return GatewaySquarePosIntegrationControllerApiFp(this.configuration).updateSquarePosIntegrationByIdUsingPATCH(posIntegrationUpdatePayload, squarePosIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateSquarePosIntegrationLocation
     * @param {string} locationId locationId
     * @param {string} squarePosIntegrationId squarePosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySquarePosIntegrationControllerApi
     */
    public updateSquarePosIntegrationLocationUsingPATCH(locationId: string, squarePosIntegrationId: string, options?: any) {
        return GatewaySquarePosIntegrationControllerApiFp(this.configuration).updateSquarePosIntegrationLocationUsingPATCH(locationId, squarePosIntegrationId, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayStockBelowMinQtyReportControllerApi - fetch parameter creator
 * @export
 */
export const GatewayStockBelowMinQtyReportControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createStockBelowMinQtyReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockBelowMinQtyReportUsingPOST(options: any = {}): FetchArgs {
            const localVarPath = `/v1/stock-below-min-qty-reports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllStockBelowMinQtyReports
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStockBelowMinQtyReportsUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/stock-below-min-qty-reports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getStockBelowMinQtyReportProducts
         * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
         * @param {boolean} [addProductsForOrder] addProductsForOrder
         * @param {number} [page] 
         * @param {string} [partialProductName] partialProductName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockBelowMinQtyReportProductsUsingGET(stockBelowMinQtyReportId: string, addProductsForOrder?: boolean, page?: number, partialProductName?: string, size?: number, sort?: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockBelowMinQtyReportId' is not null or undefined
            if (stockBelowMinQtyReportId === null || stockBelowMinQtyReportId === undefined) {
                throw new RequiredError('stockBelowMinQtyReportId','Required parameter stockBelowMinQtyReportId was null or undefined when calling getStockBelowMinQtyReportProductsUsingGET.');
            }
            const localVarPath = `/v1/stock-below-min-qty-reports/{stockBelowMinQtyReportId}/products`
                .replace(`{${"stockBelowMinQtyReportId"}}`, encodeURIComponent(String(stockBelowMinQtyReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (addProductsForOrder !== undefined) {
                localVarQueryParameter['addProductsForOrder'] = addProductsForOrder;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialProductName !== undefined) {
                localVarQueryParameter['partialProductName'] = partialProductName;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getStockBelowMinQtyReport
         * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockBelowMinQtyReportUsingGET(stockBelowMinQtyReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockBelowMinQtyReportId' is not null or undefined
            if (stockBelowMinQtyReportId === null || stockBelowMinQtyReportId === undefined) {
                throw new RequiredError('stockBelowMinQtyReportId','Required parameter stockBelowMinQtyReportId was null or undefined when calling getStockBelowMinQtyReportUsingGET.');
            }
            const localVarPath = `/v1/stock-below-min-qty-reports/{stockBelowMinQtyReportId}`
                .replace(`{${"stockBelowMinQtyReportId"}}`, encodeURIComponent(String(stockBelowMinQtyReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary sendEmailForStockBelowMinQty
         * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailForStockBelowMinQtyUsingPOST(stockBelowMinQtyReportId: string, username: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockBelowMinQtyReportId' is not null or undefined
            if (stockBelowMinQtyReportId === null || stockBelowMinQtyReportId === undefined) {
                throw new RequiredError('stockBelowMinQtyReportId','Required parameter stockBelowMinQtyReportId was null or undefined when calling sendEmailForStockBelowMinQtyUsingPOST.');
            }
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling sendEmailForStockBelowMinQtyUsingPOST.');
            }
            const localVarPath = `/v1/stock-below-min-qty-reports/{stockBelowMinQtyReportId}/email-me`
                .replace(`{${"stockBelowMinQtyReportId"}}`, encodeURIComponent(String(stockBelowMinQtyReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateStockBelowMinQtyReport
         * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockBelowMinQtyReportUsingPUT(stockBelowMinQtyReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockBelowMinQtyReportId' is not null or undefined
            if (stockBelowMinQtyReportId === null || stockBelowMinQtyReportId === undefined) {
                throw new RequiredError('stockBelowMinQtyReportId','Required parameter stockBelowMinQtyReportId was null or undefined when calling updateStockBelowMinQtyReportUsingPUT.');
            }
            const localVarPath = `/v1/stock-below-min-qty-reports/{stockBelowMinQtyReportId}`
                .replace(`{${"stockBelowMinQtyReportId"}}`, encodeURIComponent(String(stockBelowMinQtyReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayStockBelowMinQtyReportControllerApi - functional programming interface
 * @export
 */
export const GatewayStockBelowMinQtyReportControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createStockBelowMinQtyReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockBelowMinQtyReportUsingPOST(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockBelowMinQtyReportProductCountDto> {
            const localVarFetchArgs = GatewayStockBelowMinQtyReportControllerApiFetchParamCreator(configuration).createStockBelowMinQtyReportUsingPOST(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllStockBelowMinQtyReports
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStockBelowMinQtyReportsUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageStockBelowMinQtyReportProductCountDto> {
            const localVarFetchArgs = GatewayStockBelowMinQtyReportControllerApiFetchParamCreator(configuration).getAllStockBelowMinQtyReportsUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getStockBelowMinQtyReportProducts
         * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
         * @param {boolean} [addProductsForOrder] addProductsForOrder
         * @param {number} [page] 
         * @param {string} [partialProductName] partialProductName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockBelowMinQtyReportProductsUsingGET(stockBelowMinQtyReportId: string, addProductsForOrder?: boolean, page?: number, partialProductName?: string, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageProductBelowMinQtyExtended> {
            const localVarFetchArgs = GatewayStockBelowMinQtyReportControllerApiFetchParamCreator(configuration).getStockBelowMinQtyReportProductsUsingGET(stockBelowMinQtyReportId, addProductsForOrder, page, partialProductName, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getStockBelowMinQtyReport
         * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockBelowMinQtyReportUsingGET(stockBelowMinQtyReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockBelowMinQtyReportProductCountDto> {
            const localVarFetchArgs = GatewayStockBelowMinQtyReportControllerApiFetchParamCreator(configuration).getStockBelowMinQtyReportUsingGET(stockBelowMinQtyReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary sendEmailForStockBelowMinQty
         * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailForStockBelowMinQtyUsingPOST(stockBelowMinQtyReportId: string, username: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockBelowMinQtyReportControllerApiFetchParamCreator(configuration).sendEmailForStockBelowMinQtyUsingPOST(stockBelowMinQtyReportId, username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateStockBelowMinQtyReport
         * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockBelowMinQtyReportUsingPUT(stockBelowMinQtyReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockBelowMinQtyReportProductCountDto> {
            const localVarFetchArgs = GatewayStockBelowMinQtyReportControllerApiFetchParamCreator(configuration).updateStockBelowMinQtyReportUsingPUT(stockBelowMinQtyReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayStockBelowMinQtyReportControllerApi - factory interface
 * @export
 */
export const GatewayStockBelowMinQtyReportControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createStockBelowMinQtyReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockBelowMinQtyReportUsingPOST(options?: any) {
            return GatewayStockBelowMinQtyReportControllerApiFp(configuration).createStockBelowMinQtyReportUsingPOST(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllStockBelowMinQtyReports
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStockBelowMinQtyReportsUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return GatewayStockBelowMinQtyReportControllerApiFp(configuration).getAllStockBelowMinQtyReportsUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getStockBelowMinQtyReportProducts
         * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
         * @param {boolean} [addProductsForOrder] addProductsForOrder
         * @param {number} [page] 
         * @param {string} [partialProductName] partialProductName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockBelowMinQtyReportProductsUsingGET(stockBelowMinQtyReportId: string, addProductsForOrder?: boolean, page?: number, partialProductName?: string, size?: number, sort?: string, options?: any) {
            return GatewayStockBelowMinQtyReportControllerApiFp(configuration).getStockBelowMinQtyReportProductsUsingGET(stockBelowMinQtyReportId, addProductsForOrder, page, partialProductName, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getStockBelowMinQtyReport
         * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockBelowMinQtyReportUsingGET(stockBelowMinQtyReportId: string, options?: any) {
            return GatewayStockBelowMinQtyReportControllerApiFp(configuration).getStockBelowMinQtyReportUsingGET(stockBelowMinQtyReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary sendEmailForStockBelowMinQty
         * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        sendEmailForStockBelowMinQtyUsingPOST(stockBelowMinQtyReportId: string, username: string, options?: any) {
            return GatewayStockBelowMinQtyReportControllerApiFp(configuration).sendEmailForStockBelowMinQtyUsingPOST(stockBelowMinQtyReportId, username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateStockBelowMinQtyReport
         * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockBelowMinQtyReportUsingPUT(stockBelowMinQtyReportId: string, options?: any) {
            return GatewayStockBelowMinQtyReportControllerApiFp(configuration).updateStockBelowMinQtyReportUsingPUT(stockBelowMinQtyReportId, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayStockBelowMinQtyReportControllerApi - object-oriented interface
 * @export
 * @class GatewayStockBelowMinQtyReportControllerApi
 * @extends {BaseAPI}
 */
export class GatewayStockBelowMinQtyReportControllerApi extends BaseAPI {
    /**
     * 
     * @summary createStockBelowMinQtyReport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockBelowMinQtyReportControllerApi
     */
    public createStockBelowMinQtyReportUsingPOST(options?: any) {
        return GatewayStockBelowMinQtyReportControllerApiFp(this.configuration).createStockBelowMinQtyReportUsingPOST(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllStockBelowMinQtyReports
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockBelowMinQtyReportControllerApi
     */
    public getAllStockBelowMinQtyReportsUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return GatewayStockBelowMinQtyReportControllerApiFp(this.configuration).getAllStockBelowMinQtyReportsUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getStockBelowMinQtyReportProducts
     * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
     * @param {boolean} [addProductsForOrder] addProductsForOrder
     * @param {number} [page] 
     * @param {string} [partialProductName] partialProductName
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockBelowMinQtyReportControllerApi
     */
    public getStockBelowMinQtyReportProductsUsingGET(stockBelowMinQtyReportId: string, addProductsForOrder?: boolean, page?: number, partialProductName?: string, size?: number, sort?: string, options?: any) {
        return GatewayStockBelowMinQtyReportControllerApiFp(this.configuration).getStockBelowMinQtyReportProductsUsingGET(stockBelowMinQtyReportId, addProductsForOrder, page, partialProductName, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getStockBelowMinQtyReport
     * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockBelowMinQtyReportControllerApi
     */
    public getStockBelowMinQtyReportUsingGET(stockBelowMinQtyReportId: string, options?: any) {
        return GatewayStockBelowMinQtyReportControllerApiFp(this.configuration).getStockBelowMinQtyReportUsingGET(stockBelowMinQtyReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary sendEmailForStockBelowMinQty
     * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
     * @param {string} username username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockBelowMinQtyReportControllerApi
     */
    public sendEmailForStockBelowMinQtyUsingPOST(stockBelowMinQtyReportId: string, username: string, options?: any) {
        return GatewayStockBelowMinQtyReportControllerApiFp(this.configuration).sendEmailForStockBelowMinQtyUsingPOST(stockBelowMinQtyReportId, username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateStockBelowMinQtyReport
     * @param {string} stockBelowMinQtyReportId stockBelowMinQtyReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockBelowMinQtyReportControllerApi
     */
    public updateStockBelowMinQtyReportUsingPUT(stockBelowMinQtyReportId: string, options?: any) {
        return GatewayStockBelowMinQtyReportControllerApiFp(this.configuration).updateStockBelowMinQtyReportUsingPUT(stockBelowMinQtyReportId, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayStockTakeControllerApi - fetch parameter creator
 * @export
 */
export const GatewayStockTakeControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addProductsToSubStockTakeReport
         * @param {Array<StockTakeProductPayload>} products products
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductsToSubStockTakeReportUsingPOST(products: Array<StockTakeProductPayload>, stockTakeReportId: string, subReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'products' is not null or undefined
            if (products === null || products === undefined) {
                throw new RequiredError('products','Required parameter products was null or undefined when calling addProductsToSubStockTakeReportUsingPOST.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling addProductsToSubStockTakeReportUsingPOST.');
            }
            // verify required parameter 'subReportId' is not null or undefined
            if (subReportId === null || subReportId === undefined) {
                throw new RequiredError('subReportId','Required parameter subReportId was null or undefined when calling addProductsToSubStockTakeReportUsingPOST.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/sub-report/{subReportId}/products`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)))
                .replace(`{${"subReportId"}}`, encodeURIComponent(String(subReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;StockTakeProductPayload&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(products || {}) : (products || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addProducts
         * @param {Array<StockTakeProductPayload>} products products
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductsUsingPOST(products: Array<StockTakeProductPayload>, stockTakeReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'products' is not null or undefined
            if (products === null || products === undefined) {
                throw new RequiredError('products','Required parameter products was null or undefined when calling addProductsUsingPOST.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling addProductsUsingPOST.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/products`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;StockTakeProductPayload&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(products || {}) : (products || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addRecipesToSubStockTakeReport
         * @param {Array<StockTakeRecipePayload>} recipes recipes
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecipesToSubStockTakeReportUsingPOST(recipes: Array<StockTakeRecipePayload>, stockTakeReportId: string, subReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'recipes' is not null or undefined
            if (recipes === null || recipes === undefined) {
                throw new RequiredError('recipes','Required parameter recipes was null or undefined when calling addRecipesToSubStockTakeReportUsingPOST.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling addRecipesToSubStockTakeReportUsingPOST.');
            }
            // verify required parameter 'subReportId' is not null or undefined
            if (subReportId === null || subReportId === undefined) {
                throw new RequiredError('subReportId','Required parameter subReportId was null or undefined when calling addRecipesToSubStockTakeReportUsingPOST.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/sub-report/{subReportId}/recipes`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)))
                .replace(`{${"subReportId"}}`, encodeURIComponent(String(subReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;StockTakeRecipePayload&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(recipes || {}) : (recipes || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary addRecipes
         * @param {Array<StockTakeRecipePayload>} recipes recipes
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecipesUsingPOST(recipes: Array<StockTakeRecipePayload>, stockTakeReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'recipes' is not null or undefined
            if (recipes === null || recipes === undefined) {
                throw new RequiredError('recipes','Required parameter recipes was null or undefined when calling addRecipesUsingPOST.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling addRecipesUsingPOST.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/recipes`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;StockTakeRecipePayload&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(recipes || {}) : (recipes || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary completeSubStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeSubStockTakeReportUsingPOST(stockTakeReportId: string, subReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling completeSubStockTakeReportUsingPOST.');
            }
            // verify required parameter 'subReportId' is not null or undefined
            if (subReportId === null || subReportId === undefined) {
                throw new RequiredError('subReportId','Required parameter subReportId was null or undefined when calling completeSubStockTakeReportUsingPOST.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/sub-report/{subReportId}/complete`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)))
                .replace(`{${"subReportId"}}`, encodeURIComponent(String(subReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createCOGSReport
         * @param {string} closingStockTakeReportId closingStockTakeReportId
         * @param {string} openingStockTakeReportId openingStockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCOGSReportUsingPOST(closingStockTakeReportId: string, openingStockTakeReportId: string, filterCategories?: FilterCategories, salesId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'closingStockTakeReportId' is not null or undefined
            if (closingStockTakeReportId === null || closingStockTakeReportId === undefined) {
                throw new RequiredError('closingStockTakeReportId','Required parameter closingStockTakeReportId was null or undefined when calling createCOGSReportUsingPOST.');
            }
            // verify required parameter 'openingStockTakeReportId' is not null or undefined
            if (openingStockTakeReportId === null || openingStockTakeReportId === undefined) {
                throw new RequiredError('openingStockTakeReportId','Required parameter openingStockTakeReportId was null or undefined when calling createCOGSReportUsingPOST.');
            }
            const localVarPath = `/v1/stock-take/cogs-reports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (closingStockTakeReportId !== undefined) {
                localVarQueryParameter['closingStockTakeReportId'] = closingStockTakeReportId;
            }

            if (openingStockTakeReportId !== undefined) {
                localVarQueryParameter['openingStockTakeReportId'] = openingStockTakeReportId;
            }

            if (salesId !== undefined) {
                localVarQueryParameter['salesId'] = salesId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FilterCategories" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filterCategories || {}) : (filterCategories || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createDiscrepancyStockReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [openingStockTakeReportId] openingStockTakeReportId
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDiscrepancyStockReportUsingPOST(stockTakeReportId: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling createDiscrepancyStockReportUsingPOST.');
            }
            const localVarPath = `/v1/stock-take/discrepancy-report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (openingStockTakeReportId !== undefined) {
                localVarQueryParameter['openingStockTakeReportId'] = openingStockTakeReportId;
            }

            if (salesId !== undefined) {
                localVarQueryParameter['salesId'] = salesId;
            }

            if (stockTakeReportId !== undefined) {
                localVarQueryParameter['stockTakeReportId'] = stockTakeReportId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FilterCategories" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filterCategories || {}) : (filterCategories || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createStockTakeReport
         * @param {StockTakeReportPayload} stockTakeReport stockTakeReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockTakeReportUsingPOST(stockTakeReport: StockTakeReportPayload, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeReport' is not null or undefined
            if (stockTakeReport === null || stockTakeReport === undefined) {
                throw new RequiredError('stockTakeReport','Required parameter stockTakeReport was null or undefined when calling createStockTakeReportUsingPOST.');
            }
            const localVarPath = `/v1/stock-take/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StockTakeReportPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(stockTakeReport || {}) : (stockTakeReport || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createStockTakeTemplate
         * @param {StockTakeTemplate} stockTakeTemplate stockTakeTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockTakeTemplateUsingPOST(stockTakeTemplate: StockTakeTemplate, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeTemplate' is not null or undefined
            if (stockTakeTemplate === null || stockTakeTemplate === undefined) {
                throw new RequiredError('stockTakeTemplate','Required parameter stockTakeTemplate was null or undefined when calling createStockTakeTemplateUsingPOST.');
            }
            const localVarPath = `/v1/stock-take/report-templates`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StockTakeTemplate" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(stockTakeTemplate || {}) : (stockTakeTemplate || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createSubStockTakeReport
         * @param {StockTakeReportPayload} stockTakeReport stockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubStockTakeReportUsingPOST(stockTakeReport: StockTakeReportPayload, stockTakeReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeReport' is not null or undefined
            if (stockTakeReport === null || stockTakeReport === undefined) {
                throw new RequiredError('stockTakeReport','Required parameter stockTakeReport was null or undefined when calling createSubStockTakeReportUsingPOST.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling createSubStockTakeReportUsingPOST.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/sub-report`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StockTakeReportPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(stockTakeReport || {}) : (stockTakeReport || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteCOGSReport
         * @param {string} cogsReportId cogsReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCOGSReportUsingDELETE(cogsReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'cogsReportId' is not null or undefined
            if (cogsReportId === null || cogsReportId === undefined) {
                throw new RequiredError('cogsReportId','Required parameter cogsReportId was null or undefined when calling deleteCOGSReportUsingDELETE.');
            }
            const localVarPath = `/v1/stock-take/cogs-reports/{cogsReportId}`
                .replace(`{${"cogsReportId"}}`, encodeURIComponent(String(cogsReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteProductFromSubStockTakeReport
         * @param {string} barcode barcode
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductFromSubStockTakeReportUsingDELETE(barcode: string, stockTakeReportId: string, subReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling deleteProductFromSubStockTakeReportUsingDELETE.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling deleteProductFromSubStockTakeReportUsingDELETE.');
            }
            // verify required parameter 'subReportId' is not null or undefined
            if (subReportId === null || subReportId === undefined) {
                throw new RequiredError('subReportId','Required parameter subReportId was null or undefined when calling deleteProductFromSubStockTakeReportUsingDELETE.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/sub-report/{subReportId}/products/{barcode}`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)))
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)))
                .replace(`{${"subReportId"}}`, encodeURIComponent(String(subReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteProduct
         * @param {string} barcode barcode
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductUsingDELETE1(barcode: string, stockTakeReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling deleteProductUsingDELETE1.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling deleteProductUsingDELETE1.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/products/{barcode}`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)))
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteRecipeFromMainReport
         * @param {string} recipeId recipeId
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecipeFromMainReportUsingDELETE(recipeId: string, stockTakeReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'recipeId' is not null or undefined
            if (recipeId === null || recipeId === undefined) {
                throw new RequiredError('recipeId','Required parameter recipeId was null or undefined when calling deleteRecipeFromMainReportUsingDELETE.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling deleteRecipeFromMainReportUsingDELETE.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/recipes/{recipeId}`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)))
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteRecipeFromSubStockTakeReport
         * @param {string} recipeId recipeId
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecipeFromSubStockTakeReportUsingDELETE(recipeId: string, stockTakeReportId: string, subReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'recipeId' is not null or undefined
            if (recipeId === null || recipeId === undefined) {
                throw new RequiredError('recipeId','Required parameter recipeId was null or undefined when calling deleteRecipeFromSubStockTakeReportUsingDELETE.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling deleteRecipeFromSubStockTakeReportUsingDELETE.');
            }
            // verify required parameter 'subReportId' is not null or undefined
            if (subReportId === null || subReportId === undefined) {
                throw new RequiredError('subReportId','Required parameter subReportId was null or undefined when calling deleteRecipeFromSubStockTakeReportUsingDELETE.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/sub-report/{subReportId}/recipes/{recipeId}`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)))
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)))
                .replace(`{${"subReportId"}}`, encodeURIComponent(String(subReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStockTakeReportUsingDELETE(stockTakeReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling deleteStockTakeReportUsingDELETE.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteStockTakeTemplate
         * @param {string} stockTakeTemplateId stockTakeTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStockTakeTemplateUsingDELETE(stockTakeTemplateId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeTemplateId' is not null or undefined
            if (stockTakeTemplateId === null || stockTakeTemplateId === undefined) {
                throw new RequiredError('stockTakeTemplateId','Required parameter stockTakeTemplateId was null or undefined when calling deleteStockTakeTemplateUsingDELETE.');
            }
            const localVarPath = `/v1/stock-take/report-templates/{stockTakeTemplateId}`
                .replace(`{${"stockTakeTemplateId"}}`, encodeURIComponent(String(stockTakeTemplateId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteSubStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubStockTakeReportUsingDELETE(stockTakeReportId: string, subReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling deleteSubStockTakeReportUsingDELETE.');
            }
            // verify required parameter 'subReportId' is not null or undefined
            if (subReportId === null || subReportId === undefined) {
                throw new RequiredError('subReportId','Required parameter subReportId was null or undefined when calling deleteSubStockTakeReportUsingDELETE.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/sub-report/{subReportId}`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)))
                .replace(`{${"subReportId"}}`, encodeURIComponent(String(subReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportCOGSReportAsCsv
         * @param {string} cogsReportId cogsReportId
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCOGSReportAsCsvUsingGET(cogsReportId: string, fileName: string, options: any = {}): FetchArgs {
            // verify required parameter 'cogsReportId' is not null or undefined
            if (cogsReportId === null || cogsReportId === undefined) {
                throw new RequiredError('cogsReportId','Required parameter cogsReportId was null or undefined when calling exportCOGSReportAsCsvUsingGET.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling exportCOGSReportAsCsvUsingGET.');
            }
            const localVarPath = `/v1/stock-take/cogs-reports/{cogsReportId}/export-as-csv`
                .replace(`{${"cogsReportId"}}`, encodeURIComponent(String(cogsReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportDiscrepancyReportAsCsv
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDiscrepancyReportAsCsvUsingGET(discrepancyReportId: string, fileName: string, options: any = {}): FetchArgs {
            // verify required parameter 'discrepancyReportId' is not null or undefined
            if (discrepancyReportId === null || discrepancyReportId === undefined) {
                throw new RequiredError('discrepancyReportId','Required parameter discrepancyReportId was null or undefined when calling exportDiscrepancyReportAsCsvUsingGET.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling exportDiscrepancyReportAsCsvUsingGET.');
            }
            const localVarPath = `/v1/stock-take/discrepancy-report/{discrepancyReportId}/export-as-csv`
                .replace(`{${"discrepancyReportId"}}`, encodeURIComponent(String(discrepancyReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportDiscrepancyReportAsPDF
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDiscrepancyReportAsPDFUsingGET(discrepancyReportId: string, fileName: string, options: any = {}): FetchArgs {
            // verify required parameter 'discrepancyReportId' is not null or undefined
            if (discrepancyReportId === null || discrepancyReportId === undefined) {
                throw new RequiredError('discrepancyReportId','Required parameter discrepancyReportId was null or undefined when calling exportDiscrepancyReportAsPDFUsingGET.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling exportDiscrepancyReportAsPDFUsingGET.');
            }
            const localVarPath = `/v1/stock-take/discrepancy-report/{discrepancyReportId}/export-as-pdf`
                .replace(`{${"discrepancyReportId"}}`, encodeURIComponent(String(discrepancyReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportDiscrepancyReportAsXlsx
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDiscrepancyReportAsXlsxUsingGET(discrepancyReportId: string, fileName: string, options: any = {}): FetchArgs {
            // verify required parameter 'discrepancyReportId' is not null or undefined
            if (discrepancyReportId === null || discrepancyReportId === undefined) {
                throw new RequiredError('discrepancyReportId','Required parameter discrepancyReportId was null or undefined when calling exportDiscrepancyReportAsXlsxUsingGET.');
            }
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling exportDiscrepancyReportAsXlsxUsingGET.');
            }
            const localVarPath = `/v1/stock-take/discrepancy-report/{discrepancyReportId}/export-as-xlsx`
                .replace(`{${"discrepancyReportId"}}`, encodeURIComponent(String(discrepancyReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportStockTakeReportAsPDF
         * @param {string} fileName fileName
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportStockTakeReportAsPDFUsingGET(fileName: string, stockTakeReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling exportStockTakeReportAsPDFUsingGET.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling exportStockTakeReportAsPDFUsingGET.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/export-as-pdf`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportStockTakeReportAsXlsx
         * @param {string} fileName fileName
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportStockTakeReportAsXlsxUsingGET(fileName: string, stockTakeReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling exportStockTakeReportAsXlsxUsingGET.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling exportStockTakeReportAsXlsxUsingGET.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/export-as-xlsx`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportStockTakeReportToCSV
         * @param {string} fileName fileName
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportStockTakeReportToCSVUsingGET(fileName: string, stockTakeReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'fileName' is not null or undefined
            if (fileName === null || fileName === undefined) {
                throw new RequiredError('fileName','Required parameter fileName was null or undefined when calling exportStockTakeReportToCSVUsingGET.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling exportStockTakeReportToCSVUsingGET.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/export-as-csv`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (fileName !== undefined) {
                localVarQueryParameter['fileName'] = fileName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllStockTakeReports
         * @param {Date} [from] from
         * @param {number} [page] 
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'COMPLETED' | 'IN_PROGRESS'} [status] status
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStockTakeReportsUsingGET(from?: Date, page?: number, partialName?: string, size?: number, sort?: string, status?: 'COMPLETED' | 'IN_PROGRESS', to?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/v1/stock-take/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialName !== undefined) {
                localVarQueryParameter['partialName'] = partialName;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCOGSReportById
         * @param {string} cogsReportId cogsReportId
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCOGSReportByIdUsingGET(cogsReportId: string, partialProductName?: string, options: any = {}): FetchArgs {
            // verify required parameter 'cogsReportId' is not null or undefined
            if (cogsReportId === null || cogsReportId === undefined) {
                throw new RequiredError('cogsReportId','Required parameter cogsReportId was null or undefined when calling getCOGSReportByIdUsingGET.');
            }
            const localVarPath = `/v1/stock-take/cogs-reports/{cogsReportId}`
                .replace(`{${"cogsReportId"}}`, encodeURIComponent(String(cogsReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partialProductName !== undefined) {
                localVarQueryParameter['partialProductName'] = partialProductName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCOGSReportProducts
         * @param {string} cogsReportId cogsReportId
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCOGSReportProductsUsingGET(cogsReportId: string, page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            // verify required parameter 'cogsReportId' is not null or undefined
            if (cogsReportId === null || cogsReportId === undefined) {
                throw new RequiredError('cogsReportId','Required parameter cogsReportId was null or undefined when calling getCOGSReportProductsUsingGET.');
            }
            const localVarPath = `/v1/stock-take/cogs-reports/{cogsReportId}/products`
                .replace(`{${"cogsReportId"}}`, encodeURIComponent(String(cogsReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getCOGSReportsByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCOGSReportsByCriteriaUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/stock-take/cogs-reports`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDiscrepancyData
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {number} [page] 
         * @param {string} [partialProductName] partialProductName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscrepancyDataUsingGET(discrepancyReportId: string, page?: number, partialProductName?: string, size?: number, sort?: string, options: any = {}): FetchArgs {
            // verify required parameter 'discrepancyReportId' is not null or undefined
            if (discrepancyReportId === null || discrepancyReportId === undefined) {
                throw new RequiredError('discrepancyReportId','Required parameter discrepancyReportId was null or undefined when calling getDiscrepancyDataUsingGET.');
            }
            const localVarPath = `/v1/stock-take/discrepancy-report/{discrepancyReportId}/discrepancy-data`
                .replace(`{${"discrepancyReportId"}}`, encodeURIComponent(String(discrepancyReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialProductName !== undefined) {
                localVarQueryParameter['partialProductName'] = partialProductName;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDiscrepancyReport
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscrepancyReportUsingGET(discrepancyReportId: string, partialProductName?: string, options: any = {}): FetchArgs {
            // verify required parameter 'discrepancyReportId' is not null or undefined
            if (discrepancyReportId === null || discrepancyReportId === undefined) {
                throw new RequiredError('discrepancyReportId','Required parameter discrepancyReportId was null or undefined when calling getDiscrepancyReportUsingGET.');
            }
            const localVarPath = `/v1/stock-take/discrepancy-report/{discrepancyReportId}`
                .replace(`{${"discrepancyReportId"}}`, encodeURIComponent(String(discrepancyReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (partialProductName !== undefined) {
                localVarQueryParameter['partialProductName'] = partialProductName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getDiscrepancyReportsByCriteria
         * @param {Date} [from] from
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscrepancyReportsByCriteriaUsingGET(from?: Date, page?: number, size?: number, sort?: string, to?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/v1/stock-take/discrepancy-report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProducts
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialProductName] partialProductName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [subCategory] subCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsUsingGET(stockTakeReportId: string, subReportId: string, category?: string, page?: number, partialProductName?: string, size?: number, sort?: string, subCategory?: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling getProductsUsingGET.');
            }
            // verify required parameter 'subReportId' is not null or undefined
            if (subReportId === null || subReportId === undefined) {
                throw new RequiredError('subReportId','Required parameter subReportId was null or undefined when calling getProductsUsingGET.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/sub-report/{subReportId}/products`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)))
                .replace(`{${"subReportId"}}`, encodeURIComponent(String(subReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialProductName !== undefined) {
                localVarQueryParameter['partialProductName'] = partialProductName;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (subCategory !== undefined) {
                localVarQueryParameter['subCategory'] = subCategory;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getProducts
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialProductName] partialProductName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [subCategory] subCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsUsingGET1(stockTakeReportId: string, category?: string, page?: number, partialProductName?: string, size?: number, sort?: string, subCategory?: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling getProductsUsingGET1.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/products`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialProductName !== undefined) {
                localVarQueryParameter['partialProductName'] = partialProductName;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (subCategory !== undefined) {
                localVarQueryParameter['subCategory'] = subCategory;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRecipesFromMainReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {number} [page] 
         * @param {string} [partialRecipeName] partialRecipeName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipesFromMainReportUsingGET(stockTakeReportId: string, page?: number, partialRecipeName?: string, size?: number, sort?: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling getRecipesFromMainReportUsingGET.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/recipes`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialRecipeName !== undefined) {
                localVarQueryParameter['partialRecipeName'] = partialRecipeName;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getRecipesFromSubStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {number} [page] 
         * @param {string} [partialRecipeName] partialRecipeName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipesFromSubStockTakeReportUsingGET(stockTakeReportId: string, subReportId: string, page?: number, partialRecipeName?: string, size?: number, sort?: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling getRecipesFromSubStockTakeReportUsingGET.');
            }
            // verify required parameter 'subReportId' is not null or undefined
            if (subReportId === null || subReportId === undefined) {
                throw new RequiredError('subReportId','Required parameter subReportId was null or undefined when calling getRecipesFromSubStockTakeReportUsingGET.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/sub-report/{subReportId}/recipes`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)))
                .replace(`{${"subReportId"}}`, encodeURIComponent(String(subReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialRecipeName !== undefined) {
                localVarQueryParameter['partialRecipeName'] = partialRecipeName;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSTTemplatesByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSTTemplatesByCriteriaUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/stock-take/report-templates`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getStockTakeReportById
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTakeReportByIdUsingGET(stockTakeReportId: string, subReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling getStockTakeReportByIdUsingGET.');
            }
            // verify required parameter 'subReportId' is not null or undefined
            if (subReportId === null || subReportId === undefined) {
                throw new RequiredError('subReportId','Required parameter subReportId was null or undefined when calling getStockTakeReportByIdUsingGET.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/sub-report/{subReportId}`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)))
                .replace(`{${"subReportId"}}`, encodeURIComponent(String(subReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getStockTakeReportById
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTakeReportByIdUsingGET1(stockTakeReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling getStockTakeReportByIdUsingGET1.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getStockTakeTemplate
         * @param {string} stockTakeTemplateId stockTakeTemplateId
         * @param {string} [barcode] barcode
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTakeTemplateUsingGET(stockTakeTemplateId: string, barcode?: string, partialProductName?: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeTemplateId' is not null or undefined
            if (stockTakeTemplateId === null || stockTakeTemplateId === undefined) {
                throw new RequiredError('stockTakeTemplateId','Required parameter stockTakeTemplateId was null or undefined when calling getStockTakeTemplateUsingGET.');
            }
            const localVarPath = `/v1/stock-take/report-templates/{stockTakeTemplateId}`
                .replace(`{${"stockTakeTemplateId"}}`, encodeURIComponent(String(stockTakeTemplateId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }

            if (partialProductName !== undefined) {
                localVarQueryParameter['partialProductName'] = partialProductName;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary mergeStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {Date} [completedAt] completedAt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeStockTakeReportUsingGET(stockTakeReportId: string, completedAt?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling mergeStockTakeReportUsingGET.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/merge`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (completedAt !== undefined) {
                localVarQueryParameter['completedAt'] = (completedAt as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setTotalAmountOfProductsAndRecipesWhereMissingInST
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTotalAmountOfProductsAndRecipesWhereMissingInSTUsingPATCH(options: any = {}): FetchArgs {
            const localVarPath = `/v1/stock-take/report/totals`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateCOGSReport
         * @param {string} cogsReportId cogsReportId
         * @param {string} [closingStockTakeReportId] closingStockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [openingStockTakeReportId] openingStockTakeReportId
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCOGSReportUsingPUT(cogsReportId: string, closingStockTakeReportId?: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'cogsReportId' is not null or undefined
            if (cogsReportId === null || cogsReportId === undefined) {
                throw new RequiredError('cogsReportId','Required parameter cogsReportId was null or undefined when calling updateCOGSReportUsingPUT.');
            }
            const localVarPath = `/v1/stock-take/cogs-reports/{cogsReportId}`
                .replace(`{${"cogsReportId"}}`, encodeURIComponent(String(cogsReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (closingStockTakeReportId !== undefined) {
                localVarQueryParameter['closingStockTakeReportId'] = closingStockTakeReportId;
            }

            if (openingStockTakeReportId !== undefined) {
                localVarQueryParameter['openingStockTakeReportId'] = openingStockTakeReportId;
            }

            if (salesId !== undefined) {
                localVarQueryParameter['salesId'] = salesId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FilterCategories" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filterCategories || {}) : (filterCategories || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateDiscrepancyReportById
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [openingStockTakeReportId] openingStockTakeReportId
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDiscrepancyReportByIdUsingPUT(discrepancyReportId: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'discrepancyReportId' is not null or undefined
            if (discrepancyReportId === null || discrepancyReportId === undefined) {
                throw new RequiredError('discrepancyReportId','Required parameter discrepancyReportId was null or undefined when calling updateDiscrepancyReportByIdUsingPUT.');
            }
            const localVarPath = `/v1/stock-take/discrepancy-report/{discrepancyReportId}`
                .replace(`{${"discrepancyReportId"}}`, encodeURIComponent(String(discrepancyReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (openingStockTakeReportId !== undefined) {
                localVarQueryParameter['openingStockTakeReportId'] = openingStockTakeReportId;
            }

            if (salesId !== undefined) {
                localVarQueryParameter['salesId'] = salesId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FilterCategories" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(filterCategories || {}) : (filterCategories || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateProductInSubStockTakeReport
         * @param {string} barcode barcode
         * @param {StockTakeProductPayload} product product
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductInSubStockTakeReportUsingPUT(barcode: string, product: StockTakeProductPayload, stockTakeReportId: string, subReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling updateProductInSubStockTakeReportUsingPUT.');
            }
            // verify required parameter 'product' is not null or undefined
            if (product === null || product === undefined) {
                throw new RequiredError('product','Required parameter product was null or undefined when calling updateProductInSubStockTakeReportUsingPUT.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling updateProductInSubStockTakeReportUsingPUT.');
            }
            // verify required parameter 'subReportId' is not null or undefined
            if (subReportId === null || subReportId === undefined) {
                throw new RequiredError('subReportId','Required parameter subReportId was null or undefined when calling updateProductInSubStockTakeReportUsingPUT.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/sub-report/{subReportId}/products/{barcode}`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)))
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)))
                .replace(`{${"subReportId"}}`, encodeURIComponent(String(subReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StockTakeProductPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(product || {}) : (product || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateProduct
         * @param {string} barcode barcode
         * @param {StockTakeProductPayload} product product
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductUsingPUT1(barcode: string, product: StockTakeProductPayload, stockTakeReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling updateProductUsingPUT1.');
            }
            // verify required parameter 'product' is not null or undefined
            if (product === null || product === undefined) {
                throw new RequiredError('product','Required parameter product was null or undefined when calling updateProductUsingPUT1.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling updateProductUsingPUT1.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/products/{barcode}`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)))
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StockTakeProductPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(product || {}) : (product || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateRecipeInMainReport
         * @param {StockTakeUpdateRecipePayload} recipe recipe
         * @param {string} recipeId recipeId
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeInMainReportUsingPUT(recipe: StockTakeUpdateRecipePayload, recipeId: string, stockTakeReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'recipe' is not null or undefined
            if (recipe === null || recipe === undefined) {
                throw new RequiredError('recipe','Required parameter recipe was null or undefined when calling updateRecipeInMainReportUsingPUT.');
            }
            // verify required parameter 'recipeId' is not null or undefined
            if (recipeId === null || recipeId === undefined) {
                throw new RequiredError('recipeId','Required parameter recipeId was null or undefined when calling updateRecipeInMainReportUsingPUT.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling updateRecipeInMainReportUsingPUT.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/recipes/{recipeId}`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)))
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StockTakeUpdateRecipePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(recipe || {}) : (recipe || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateRecipeInSubStockTakeReport
         * @param {StockTakeUpdateRecipePayload} recipe recipe
         * @param {string} recipeId recipeId
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeInSubStockTakeReportUsingPUT(recipe: StockTakeUpdateRecipePayload, recipeId: string, stockTakeReportId: string, subReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'recipe' is not null or undefined
            if (recipe === null || recipe === undefined) {
                throw new RequiredError('recipe','Required parameter recipe was null or undefined when calling updateRecipeInSubStockTakeReportUsingPUT.');
            }
            // verify required parameter 'recipeId' is not null or undefined
            if (recipeId === null || recipeId === undefined) {
                throw new RequiredError('recipeId','Required parameter recipeId was null or undefined when calling updateRecipeInSubStockTakeReportUsingPUT.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling updateRecipeInSubStockTakeReportUsingPUT.');
            }
            // verify required parameter 'subReportId' is not null or undefined
            if (subReportId === null || subReportId === undefined) {
                throw new RequiredError('subReportId','Required parameter subReportId was null or undefined when calling updateRecipeInSubStockTakeReportUsingPUT.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/sub-report/{subReportId}/recipes/{recipeId}`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)))
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)))
                .replace(`{${"subReportId"}}`, encodeURIComponent(String(subReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StockTakeUpdateRecipePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(recipe || {}) : (recipe || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateStockTakeReport
         * @param {StockTakeReportUpdatePayload} payload payload
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTakeReportUsingPUT(payload: StockTakeReportUpdatePayload, stockTakeReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling updateStockTakeReportUsingPUT.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling updateStockTakeReportUsingPUT.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StockTakeReportUpdatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(payload || {}) : (payload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateStockTakeTemplate
         * @param {StockTakeTemplate} stockTakeTemplate stockTakeTemplate
         * @param {string} stockTakeTemplateId stockTakeTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTakeTemplateUsingPUT(stockTakeTemplate: StockTakeTemplate, stockTakeTemplateId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeTemplate' is not null or undefined
            if (stockTakeTemplate === null || stockTakeTemplate === undefined) {
                throw new RequiredError('stockTakeTemplate','Required parameter stockTakeTemplate was null or undefined when calling updateStockTakeTemplateUsingPUT.');
            }
            // verify required parameter 'stockTakeTemplateId' is not null or undefined
            if (stockTakeTemplateId === null || stockTakeTemplateId === undefined) {
                throw new RequiredError('stockTakeTemplateId','Required parameter stockTakeTemplateId was null or undefined when calling updateStockTakeTemplateUsingPUT.');
            }
            const localVarPath = `/v1/stock-take/report-templates/{stockTakeTemplateId}`
                .replace(`{${"stockTakeTemplateId"}}`, encodeURIComponent(String(stockTakeTemplateId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StockTakeTemplate" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(stockTakeTemplate || {}) : (stockTakeTemplate || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateSubStockTakeReport
         * @param {StockTakeReportUpdatePayload} stockTakeReport stockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubStockTakeReportUsingPUT(stockTakeReport: StockTakeReportUpdatePayload, stockTakeReportId: string, subReportId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTakeReport' is not null or undefined
            if (stockTakeReport === null || stockTakeReport === undefined) {
                throw new RequiredError('stockTakeReport','Required parameter stockTakeReport was null or undefined when calling updateSubStockTakeReportUsingPUT.');
            }
            // verify required parameter 'stockTakeReportId' is not null or undefined
            if (stockTakeReportId === null || stockTakeReportId === undefined) {
                throw new RequiredError('stockTakeReportId','Required parameter stockTakeReportId was null or undefined when calling updateSubStockTakeReportUsingPUT.');
            }
            // verify required parameter 'subReportId' is not null or undefined
            if (subReportId === null || subReportId === undefined) {
                throw new RequiredError('subReportId','Required parameter subReportId was null or undefined when calling updateSubStockTakeReportUsingPUT.');
            }
            const localVarPath = `/v1/stock-take/report/{stockTakeReportId}/sub-report/{subReportId}`
                .replace(`{${"stockTakeReportId"}}`, encodeURIComponent(String(stockTakeReportId)))
                .replace(`{${"subReportId"}}`, encodeURIComponent(String(subReportId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StockTakeReportUpdatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(stockTakeReport || {}) : (stockTakeReport || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayStockTakeControllerApi - functional programming interface
 * @export
 */
export const GatewayStockTakeControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary addProductsToSubStockTakeReport
         * @param {Array<StockTakeProductPayload>} products products
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductsToSubStockTakeReportUsingPOST(products: Array<StockTakeProductPayload>, stockTakeReportId: string, subReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).addProductsToSubStockTakeReportUsingPOST(products, stockTakeReportId, subReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary addProducts
         * @param {Array<StockTakeProductPayload>} products products
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductsUsingPOST(products: Array<StockTakeProductPayload>, stockTakeReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).addProductsUsingPOST(products, stockTakeReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary addRecipesToSubStockTakeReport
         * @param {Array<StockTakeRecipePayload>} recipes recipes
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecipesToSubStockTakeReportUsingPOST(recipes: Array<StockTakeRecipePayload>, stockTakeReportId: string, subReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).addRecipesToSubStockTakeReportUsingPOST(recipes, stockTakeReportId, subReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary addRecipes
         * @param {Array<StockTakeRecipePayload>} recipes recipes
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecipesUsingPOST(recipes: Array<StockTakeRecipePayload>, stockTakeReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).addRecipesUsingPOST(recipes, stockTakeReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary completeSubStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeSubStockTakeReportUsingPOST(stockTakeReportId: string, subReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeReport> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).completeSubStockTakeReportUsingPOST(stockTakeReportId, subReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createCOGSReport
         * @param {string} closingStockTakeReportId closingStockTakeReportId
         * @param {string} openingStockTakeReportId openingStockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCOGSReportUsingPOST(closingStockTakeReportId: string, openingStockTakeReportId: string, filterCategories?: FilterCategories, salesId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<COGSReport> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).createCOGSReportUsingPOST(closingStockTakeReportId, openingStockTakeReportId, filterCategories, salesId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createDiscrepancyStockReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [openingStockTakeReportId] openingStockTakeReportId
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDiscrepancyStockReportUsingPOST(stockTakeReportId: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DiscrepancyReport> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).createDiscrepancyStockReportUsingPOST(stockTakeReportId, filterCategories, openingStockTakeReportId, salesId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createStockTakeReport
         * @param {StockTakeReportPayload} stockTakeReport stockTakeReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockTakeReportUsingPOST(stockTakeReport: StockTakeReportPayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeReport> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).createStockTakeReportUsingPOST(stockTakeReport, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createStockTakeTemplate
         * @param {StockTakeTemplate} stockTakeTemplate stockTakeTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockTakeTemplateUsingPOST(stockTakeTemplate: StockTakeTemplate, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeTemplate> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).createStockTakeTemplateUsingPOST(stockTakeTemplate, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createSubStockTakeReport
         * @param {StockTakeReportPayload} stockTakeReport stockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubStockTakeReportUsingPOST(stockTakeReport: StockTakeReportPayload, stockTakeReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeReport> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).createSubStockTakeReportUsingPOST(stockTakeReport, stockTakeReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteCOGSReport
         * @param {string} cogsReportId cogsReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCOGSReportUsingDELETE(cogsReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).deleteCOGSReportUsingDELETE(cogsReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteProductFromSubStockTakeReport
         * @param {string} barcode barcode
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductFromSubStockTakeReportUsingDELETE(barcode: string, stockTakeReportId: string, subReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).deleteProductFromSubStockTakeReportUsingDELETE(barcode, stockTakeReportId, subReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteProduct
         * @param {string} barcode barcode
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductUsingDELETE1(barcode: string, stockTakeReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).deleteProductUsingDELETE1(barcode, stockTakeReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteRecipeFromMainReport
         * @param {string} recipeId recipeId
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecipeFromMainReportUsingDELETE(recipeId: string, stockTakeReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).deleteRecipeFromMainReportUsingDELETE(recipeId, stockTakeReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteRecipeFromSubStockTakeReport
         * @param {string} recipeId recipeId
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecipeFromSubStockTakeReportUsingDELETE(recipeId: string, stockTakeReportId: string, subReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).deleteRecipeFromSubStockTakeReportUsingDELETE(recipeId, stockTakeReportId, subReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStockTakeReportUsingDELETE(stockTakeReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).deleteStockTakeReportUsingDELETE(stockTakeReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteStockTakeTemplate
         * @param {string} stockTakeTemplateId stockTakeTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStockTakeTemplateUsingDELETE(stockTakeTemplateId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).deleteStockTakeTemplateUsingDELETE(stockTakeTemplateId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteSubStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubStockTakeReportUsingDELETE(stockTakeReportId: string, subReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).deleteSubStockTakeReportUsingDELETE(stockTakeReportId, subReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportCOGSReportAsCsv
         * @param {string} cogsReportId cogsReportId
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCOGSReportAsCsvUsingGET(cogsReportId: string, fileName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).exportCOGSReportAsCsvUsingGET(cogsReportId, fileName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportDiscrepancyReportAsCsv
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDiscrepancyReportAsCsvUsingGET(discrepancyReportId: string, fileName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).exportDiscrepancyReportAsCsvUsingGET(discrepancyReportId, fileName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportDiscrepancyReportAsPDF
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDiscrepancyReportAsPDFUsingGET(discrepancyReportId: string, fileName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).exportDiscrepancyReportAsPDFUsingGET(discrepancyReportId, fileName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportDiscrepancyReportAsXlsx
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDiscrepancyReportAsXlsxUsingGET(discrepancyReportId: string, fileName: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).exportDiscrepancyReportAsXlsxUsingGET(discrepancyReportId, fileName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportStockTakeReportAsPDF
         * @param {string} fileName fileName
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportStockTakeReportAsPDFUsingGET(fileName: string, stockTakeReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).exportStockTakeReportAsPDFUsingGET(fileName, stockTakeReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportStockTakeReportAsXlsx
         * @param {string} fileName fileName
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportStockTakeReportAsXlsxUsingGET(fileName: string, stockTakeReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).exportStockTakeReportAsXlsxUsingGET(fileName, stockTakeReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportStockTakeReportToCSV
         * @param {string} fileName fileName
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportStockTakeReportToCSVUsingGET(fileName: string, stockTakeReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).exportStockTakeReportToCSVUsingGET(fileName, stockTakeReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllStockTakeReports
         * @param {Date} [from] from
         * @param {number} [page] 
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'COMPLETED' | 'IN_PROGRESS'} [status] status
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStockTakeReportsUsingGET(from?: Date, page?: number, partialName?: string, size?: number, sort?: string, status?: 'COMPLETED' | 'IN_PROGRESS', to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageStockTakeListWrapper> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getAllStockTakeReportsUsingGET(from, page, partialName, size, sort, status, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCOGSReportById
         * @param {string} cogsReportId cogsReportId
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCOGSReportByIdUsingGET(cogsReportId: string, partialProductName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<COGSReport> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getCOGSReportByIdUsingGET(cogsReportId, partialProductName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCOGSReportProducts
         * @param {string} cogsReportId cogsReportId
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCOGSReportProductsUsingGET(cogsReportId: string, page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageProduct> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getCOGSReportProductsUsingGET(cogsReportId, page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getCOGSReportsByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCOGSReportsByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageCOGSReportWithoutProductsDto> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getCOGSReportsByCriteriaUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDiscrepancyData
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {number} [page] 
         * @param {string} [partialProductName] partialProductName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscrepancyDataUsingGET(discrepancyReportId: string, page?: number, partialProductName?: string, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageDiscrepancyReportRow> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getDiscrepancyDataUsingGET(discrepancyReportId, page, partialProductName, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDiscrepancyReport
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscrepancyReportUsingGET(discrepancyReportId: string, partialProductName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DiscrepancyReport> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getDiscrepancyReportUsingGET(discrepancyReportId, partialProductName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getDiscrepancyReportsByCriteria
         * @param {Date} [from] from
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscrepancyReportsByCriteriaUsingGET(from?: Date, page?: number, size?: number, sort?: string, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageDiscrepancyReportWithoutProductsDto> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getDiscrepancyReportsByCriteriaUsingGET(from, page, size, sort, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getProducts
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialProductName] partialProductName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [subCategory] subCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsUsingGET(stockTakeReportId: string, subReportId: string, category?: string, page?: number, partialProductName?: string, size?: number, sort?: string, subCategory?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageStockTakeProduct> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getProductsUsingGET(stockTakeReportId, subReportId, category, page, partialProductName, size, sort, subCategory, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getProducts
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialProductName] partialProductName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [subCategory] subCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsUsingGET1(stockTakeReportId: string, category?: string, page?: number, partialProductName?: string, size?: number, sort?: string, subCategory?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageStockTakeProduct> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getProductsUsingGET1(stockTakeReportId, category, page, partialProductName, size, sort, subCategory, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getRecipesFromMainReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {number} [page] 
         * @param {string} [partialRecipeName] partialRecipeName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipesFromMainReportUsingGET(stockTakeReportId: string, page?: number, partialRecipeName?: string, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageStockTakeRecipe> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getRecipesFromMainReportUsingGET(stockTakeReportId, page, partialRecipeName, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getRecipesFromSubStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {number} [page] 
         * @param {string} [partialRecipeName] partialRecipeName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipesFromSubStockTakeReportUsingGET(stockTakeReportId: string, subReportId: string, page?: number, partialRecipeName?: string, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageStockTakeRecipe> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getRecipesFromSubStockTakeReportUsingGET(stockTakeReportId, subReportId, page, partialRecipeName, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSTTemplatesByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSTTemplatesByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageStockTakeTemplateProductsCountDto> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getSTTemplatesByCriteriaUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getStockTakeReportById
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTakeReportByIdUsingGET(stockTakeReportId: string, subReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeReport> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getStockTakeReportByIdUsingGET(stockTakeReportId, subReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getStockTakeReportById
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTakeReportByIdUsingGET1(stockTakeReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeReport> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getStockTakeReportByIdUsingGET1(stockTakeReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getStockTakeTemplate
         * @param {string} stockTakeTemplateId stockTakeTemplateId
         * @param {string} [barcode] barcode
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTakeTemplateUsingGET(stockTakeTemplateId: string, barcode?: string, partialProductName?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeTemplate> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).getStockTakeTemplateUsingGET(stockTakeTemplateId, barcode, partialProductName, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary mergeStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {Date} [completedAt] completedAt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeStockTakeReportUsingGET(stockTakeReportId: string, completedAt?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeReport> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).mergeStockTakeReportUsingGET(stockTakeReportId, completedAt, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setTotalAmountOfProductsAndRecipesWhereMissingInST
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTotalAmountOfProductsAndRecipesWhereMissingInSTUsingPATCH(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).setTotalAmountOfProductsAndRecipesWhereMissingInSTUsingPATCH(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateCOGSReport
         * @param {string} cogsReportId cogsReportId
         * @param {string} [closingStockTakeReportId] closingStockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [openingStockTakeReportId] openingStockTakeReportId
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCOGSReportUsingPUT(cogsReportId: string, closingStockTakeReportId?: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<COGSReport> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).updateCOGSReportUsingPUT(cogsReportId, closingStockTakeReportId, filterCategories, openingStockTakeReportId, salesId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateDiscrepancyReportById
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [openingStockTakeReportId] openingStockTakeReportId
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDiscrepancyReportByIdUsingPUT(discrepancyReportId: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<DiscrepancyReport> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).updateDiscrepancyReportByIdUsingPUT(discrepancyReportId, filterCategories, openingStockTakeReportId, salesId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateProductInSubStockTakeReport
         * @param {string} barcode barcode
         * @param {StockTakeProductPayload} product product
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductInSubStockTakeReportUsingPUT(barcode: string, product: StockTakeProductPayload, stockTakeReportId: string, subReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeProduct> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).updateProductInSubStockTakeReportUsingPUT(barcode, product, stockTakeReportId, subReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateProduct
         * @param {string} barcode barcode
         * @param {StockTakeProductPayload} product product
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductUsingPUT1(barcode: string, product: StockTakeProductPayload, stockTakeReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeProduct> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).updateProductUsingPUT1(barcode, product, stockTakeReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateRecipeInMainReport
         * @param {StockTakeUpdateRecipePayload} recipe recipe
         * @param {string} recipeId recipeId
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeInMainReportUsingPUT(recipe: StockTakeUpdateRecipePayload, recipeId: string, stockTakeReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeRecipe> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).updateRecipeInMainReportUsingPUT(recipe, recipeId, stockTakeReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateRecipeInSubStockTakeReport
         * @param {StockTakeUpdateRecipePayload} recipe recipe
         * @param {string} recipeId recipeId
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeInSubStockTakeReportUsingPUT(recipe: StockTakeUpdateRecipePayload, recipeId: string, stockTakeReportId: string, subReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeRecipe> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).updateRecipeInSubStockTakeReportUsingPUT(recipe, recipeId, stockTakeReportId, subReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateStockTakeReport
         * @param {StockTakeReportUpdatePayload} payload payload
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTakeReportUsingPUT(payload: StockTakeReportUpdatePayload, stockTakeReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeReport> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).updateStockTakeReportUsingPUT(payload, stockTakeReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateStockTakeTemplate
         * @param {StockTakeTemplate} stockTakeTemplate stockTakeTemplate
         * @param {string} stockTakeTemplateId stockTakeTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTakeTemplateUsingPUT(stockTakeTemplate: StockTakeTemplate, stockTakeTemplateId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeTemplate> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).updateStockTakeTemplateUsingPUT(stockTakeTemplate, stockTakeTemplateId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateSubStockTakeReport
         * @param {StockTakeReportUpdatePayload} stockTakeReport stockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubStockTakeReportUsingPUT(stockTakeReport: StockTakeReportUpdatePayload, stockTakeReportId: string, subReportId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTakeReport> {
            const localVarFetchArgs = GatewayStockTakeControllerApiFetchParamCreator(configuration).updateSubStockTakeReportUsingPUT(stockTakeReport, stockTakeReportId, subReportId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayStockTakeControllerApi - factory interface
 * @export
 */
export const GatewayStockTakeControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary addProductsToSubStockTakeReport
         * @param {Array<StockTakeProductPayload>} products products
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductsToSubStockTakeReportUsingPOST(products: Array<StockTakeProductPayload>, stockTakeReportId: string, subReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).addProductsToSubStockTakeReportUsingPOST(products, stockTakeReportId, subReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary addProducts
         * @param {Array<StockTakeProductPayload>} products products
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addProductsUsingPOST(products: Array<StockTakeProductPayload>, stockTakeReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).addProductsUsingPOST(products, stockTakeReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary addRecipesToSubStockTakeReport
         * @param {Array<StockTakeRecipePayload>} recipes recipes
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecipesToSubStockTakeReportUsingPOST(recipes: Array<StockTakeRecipePayload>, stockTakeReportId: string, subReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).addRecipesToSubStockTakeReportUsingPOST(recipes, stockTakeReportId, subReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary addRecipes
         * @param {Array<StockTakeRecipePayload>} recipes recipes
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addRecipesUsingPOST(recipes: Array<StockTakeRecipePayload>, stockTakeReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).addRecipesUsingPOST(recipes, stockTakeReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary completeSubStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeSubStockTakeReportUsingPOST(stockTakeReportId: string, subReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).completeSubStockTakeReportUsingPOST(stockTakeReportId, subReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createCOGSReport
         * @param {string} closingStockTakeReportId closingStockTakeReportId
         * @param {string} openingStockTakeReportId openingStockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCOGSReportUsingPOST(closingStockTakeReportId: string, openingStockTakeReportId: string, filterCategories?: FilterCategories, salesId?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).createCOGSReportUsingPOST(closingStockTakeReportId, openingStockTakeReportId, filterCategories, salesId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createDiscrepancyStockReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [openingStockTakeReportId] openingStockTakeReportId
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDiscrepancyStockReportUsingPOST(stockTakeReportId: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).createDiscrepancyStockReportUsingPOST(stockTakeReportId, filterCategories, openingStockTakeReportId, salesId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createStockTakeReport
         * @param {StockTakeReportPayload} stockTakeReport stockTakeReport
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockTakeReportUsingPOST(stockTakeReport: StockTakeReportPayload, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).createStockTakeReportUsingPOST(stockTakeReport, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createStockTakeTemplate
         * @param {StockTakeTemplate} stockTakeTemplate stockTakeTemplate
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockTakeTemplateUsingPOST(stockTakeTemplate: StockTakeTemplate, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).createStockTakeTemplateUsingPOST(stockTakeTemplate, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createSubStockTakeReport
         * @param {StockTakeReportPayload} stockTakeReport stockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSubStockTakeReportUsingPOST(stockTakeReport: StockTakeReportPayload, stockTakeReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).createSubStockTakeReportUsingPOST(stockTakeReport, stockTakeReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteCOGSReport
         * @param {string} cogsReportId cogsReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCOGSReportUsingDELETE(cogsReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).deleteCOGSReportUsingDELETE(cogsReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteProductFromSubStockTakeReport
         * @param {string} barcode barcode
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductFromSubStockTakeReportUsingDELETE(barcode: string, stockTakeReportId: string, subReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).deleteProductFromSubStockTakeReportUsingDELETE(barcode, stockTakeReportId, subReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteProduct
         * @param {string} barcode barcode
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProductUsingDELETE1(barcode: string, stockTakeReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).deleteProductUsingDELETE1(barcode, stockTakeReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteRecipeFromMainReport
         * @param {string} recipeId recipeId
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecipeFromMainReportUsingDELETE(recipeId: string, stockTakeReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).deleteRecipeFromMainReportUsingDELETE(recipeId, stockTakeReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteRecipeFromSubStockTakeReport
         * @param {string} recipeId recipeId
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteRecipeFromSubStockTakeReportUsingDELETE(recipeId: string, stockTakeReportId: string, subReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).deleteRecipeFromSubStockTakeReportUsingDELETE(recipeId, stockTakeReportId, subReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStockTakeReportUsingDELETE(stockTakeReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).deleteStockTakeReportUsingDELETE(stockTakeReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteStockTakeTemplate
         * @param {string} stockTakeTemplateId stockTakeTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStockTakeTemplateUsingDELETE(stockTakeTemplateId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).deleteStockTakeTemplateUsingDELETE(stockTakeTemplateId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteSubStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSubStockTakeReportUsingDELETE(stockTakeReportId: string, subReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).deleteSubStockTakeReportUsingDELETE(stockTakeReportId, subReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportCOGSReportAsCsv
         * @param {string} cogsReportId cogsReportId
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportCOGSReportAsCsvUsingGET(cogsReportId: string, fileName: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).exportCOGSReportAsCsvUsingGET(cogsReportId, fileName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportDiscrepancyReportAsCsv
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDiscrepancyReportAsCsvUsingGET(discrepancyReportId: string, fileName: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).exportDiscrepancyReportAsCsvUsingGET(discrepancyReportId, fileName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportDiscrepancyReportAsPDF
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDiscrepancyReportAsPDFUsingGET(discrepancyReportId: string, fileName: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).exportDiscrepancyReportAsPDFUsingGET(discrepancyReportId, fileName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportDiscrepancyReportAsXlsx
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {string} fileName fileName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportDiscrepancyReportAsXlsxUsingGET(discrepancyReportId: string, fileName: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).exportDiscrepancyReportAsXlsxUsingGET(discrepancyReportId, fileName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportStockTakeReportAsPDF
         * @param {string} fileName fileName
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportStockTakeReportAsPDFUsingGET(fileName: string, stockTakeReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).exportStockTakeReportAsPDFUsingGET(fileName, stockTakeReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportStockTakeReportAsXlsx
         * @param {string} fileName fileName
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportStockTakeReportAsXlsxUsingGET(fileName: string, stockTakeReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).exportStockTakeReportAsXlsxUsingGET(fileName, stockTakeReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportStockTakeReportToCSV
         * @param {string} fileName fileName
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportStockTakeReportToCSVUsingGET(fileName: string, stockTakeReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).exportStockTakeReportToCSVUsingGET(fileName, stockTakeReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllStockTakeReports
         * @param {Date} [from] from
         * @param {number} [page] 
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'COMPLETED' | 'IN_PROGRESS'} [status] status
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllStockTakeReportsUsingGET(from?: Date, page?: number, partialName?: string, size?: number, sort?: string, status?: 'COMPLETED' | 'IN_PROGRESS', to?: Date, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getAllStockTakeReportsUsingGET(from, page, partialName, size, sort, status, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCOGSReportById
         * @param {string} cogsReportId cogsReportId
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCOGSReportByIdUsingGET(cogsReportId: string, partialProductName?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getCOGSReportByIdUsingGET(cogsReportId, partialProductName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCOGSReportProducts
         * @param {string} cogsReportId cogsReportId
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCOGSReportProductsUsingGET(cogsReportId: string, page?: number, size?: number, sort?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getCOGSReportProductsUsingGET(cogsReportId, page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getCOGSReportsByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCOGSReportsByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getCOGSReportsByCriteriaUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getDiscrepancyData
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {number} [page] 
         * @param {string} [partialProductName] partialProductName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscrepancyDataUsingGET(discrepancyReportId: string, page?: number, partialProductName?: string, size?: number, sort?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getDiscrepancyDataUsingGET(discrepancyReportId, page, partialProductName, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getDiscrepancyReport
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscrepancyReportUsingGET(discrepancyReportId: string, partialProductName?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getDiscrepancyReportUsingGET(discrepancyReportId, partialProductName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getDiscrepancyReportsByCriteria
         * @param {Date} [from] from
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDiscrepancyReportsByCriteriaUsingGET(from?: Date, page?: number, size?: number, sort?: string, to?: Date, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getDiscrepancyReportsByCriteriaUsingGET(from, page, size, sort, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getProducts
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialProductName] partialProductName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [subCategory] subCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsUsingGET(stockTakeReportId: string, subReportId: string, category?: string, page?: number, partialProductName?: string, size?: number, sort?: string, subCategory?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getProductsUsingGET(stockTakeReportId, subReportId, category, page, partialProductName, size, sort, subCategory, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getProducts
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} [category] category
         * @param {number} [page] 
         * @param {string} [partialProductName] partialProductName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [subCategory] subCategory
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProductsUsingGET1(stockTakeReportId: string, category?: string, page?: number, partialProductName?: string, size?: number, sort?: string, subCategory?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getProductsUsingGET1(stockTakeReportId, category, page, partialProductName, size, sort, subCategory, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getRecipesFromMainReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {number} [page] 
         * @param {string} [partialRecipeName] partialRecipeName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipesFromMainReportUsingGET(stockTakeReportId: string, page?: number, partialRecipeName?: string, size?: number, sort?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getRecipesFromMainReportUsingGET(stockTakeReportId, page, partialRecipeName, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getRecipesFromSubStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {number} [page] 
         * @param {string} [partialRecipeName] partialRecipeName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecipesFromSubStockTakeReportUsingGET(stockTakeReportId: string, subReportId: string, page?: number, partialRecipeName?: string, size?: number, sort?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getRecipesFromSubStockTakeReportUsingGET(stockTakeReportId, subReportId, page, partialRecipeName, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSTTemplatesByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSTTemplatesByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getSTTemplatesByCriteriaUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getStockTakeReportById
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTakeReportByIdUsingGET(stockTakeReportId: string, subReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getStockTakeReportByIdUsingGET(stockTakeReportId, subReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getStockTakeReportById
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTakeReportByIdUsingGET1(stockTakeReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getStockTakeReportByIdUsingGET1(stockTakeReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getStockTakeTemplate
         * @param {string} stockTakeTemplateId stockTakeTemplateId
         * @param {string} [barcode] barcode
         * @param {string} [partialProductName] partialProductName
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTakeTemplateUsingGET(stockTakeTemplateId: string, barcode?: string, partialProductName?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).getStockTakeTemplateUsingGET(stockTakeTemplateId, barcode, partialProductName, options)(fetch, basePath);
        },
        /**
         * 
         * @summary mergeStockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {Date} [completedAt] completedAt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        mergeStockTakeReportUsingGET(stockTakeReportId: string, completedAt?: Date, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).mergeStockTakeReportUsingGET(stockTakeReportId, completedAt, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setTotalAmountOfProductsAndRecipesWhereMissingInST
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setTotalAmountOfProductsAndRecipesWhereMissingInSTUsingPATCH(options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).setTotalAmountOfProductsAndRecipesWhereMissingInSTUsingPATCH(options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateCOGSReport
         * @param {string} cogsReportId cogsReportId
         * @param {string} [closingStockTakeReportId] closingStockTakeReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [openingStockTakeReportId] openingStockTakeReportId
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCOGSReportUsingPUT(cogsReportId: string, closingStockTakeReportId?: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).updateCOGSReportUsingPUT(cogsReportId, closingStockTakeReportId, filterCategories, openingStockTakeReportId, salesId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateDiscrepancyReportById
         * @param {string} discrepancyReportId discrepancyReportId
         * @param {FilterCategories} [filterCategories] filterCategories
         * @param {string} [openingStockTakeReportId] openingStockTakeReportId
         * @param {string} [salesId] salesId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDiscrepancyReportByIdUsingPUT(discrepancyReportId: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).updateDiscrepancyReportByIdUsingPUT(discrepancyReportId, filterCategories, openingStockTakeReportId, salesId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateProductInSubStockTakeReport
         * @param {string} barcode barcode
         * @param {StockTakeProductPayload} product product
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductInSubStockTakeReportUsingPUT(barcode: string, product: StockTakeProductPayload, stockTakeReportId: string, subReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).updateProductInSubStockTakeReportUsingPUT(barcode, product, stockTakeReportId, subReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateProduct
         * @param {string} barcode barcode
         * @param {StockTakeProductPayload} product product
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProductUsingPUT1(barcode: string, product: StockTakeProductPayload, stockTakeReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).updateProductUsingPUT1(barcode, product, stockTakeReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateRecipeInMainReport
         * @param {StockTakeUpdateRecipePayload} recipe recipe
         * @param {string} recipeId recipeId
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeInMainReportUsingPUT(recipe: StockTakeUpdateRecipePayload, recipeId: string, stockTakeReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).updateRecipeInMainReportUsingPUT(recipe, recipeId, stockTakeReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateRecipeInSubStockTakeReport
         * @param {StockTakeUpdateRecipePayload} recipe recipe
         * @param {string} recipeId recipeId
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateRecipeInSubStockTakeReportUsingPUT(recipe: StockTakeUpdateRecipePayload, recipeId: string, stockTakeReportId: string, subReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).updateRecipeInSubStockTakeReportUsingPUT(recipe, recipeId, stockTakeReportId, subReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateStockTakeReport
         * @param {StockTakeReportUpdatePayload} payload payload
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTakeReportUsingPUT(payload: StockTakeReportUpdatePayload, stockTakeReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).updateStockTakeReportUsingPUT(payload, stockTakeReportId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateStockTakeTemplate
         * @param {StockTakeTemplate} stockTakeTemplate stockTakeTemplate
         * @param {string} stockTakeTemplateId stockTakeTemplateId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTakeTemplateUsingPUT(stockTakeTemplate: StockTakeTemplate, stockTakeTemplateId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).updateStockTakeTemplateUsingPUT(stockTakeTemplate, stockTakeTemplateId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateSubStockTakeReport
         * @param {StockTakeReportUpdatePayload} stockTakeReport stockTakeReport
         * @param {string} stockTakeReportId stockTakeReportId
         * @param {string} subReportId subReportId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSubStockTakeReportUsingPUT(stockTakeReport: StockTakeReportUpdatePayload, stockTakeReportId: string, subReportId: string, options?: any) {
            return GatewayStockTakeControllerApiFp(configuration).updateSubStockTakeReportUsingPUT(stockTakeReport, stockTakeReportId, subReportId, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayStockTakeControllerApi - object-oriented interface
 * @export
 * @class GatewayStockTakeControllerApi
 * @extends {BaseAPI}
 */
export class GatewayStockTakeControllerApi extends BaseAPI {
    /**
     * 
     * @summary addProductsToSubStockTakeReport
     * @param {Array<StockTakeProductPayload>} products products
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {string} subReportId subReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public addProductsToSubStockTakeReportUsingPOST(products: Array<StockTakeProductPayload>, stockTakeReportId: string, subReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).addProductsToSubStockTakeReportUsingPOST(products, stockTakeReportId, subReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary addProducts
     * @param {Array<StockTakeProductPayload>} products products
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public addProductsUsingPOST(products: Array<StockTakeProductPayload>, stockTakeReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).addProductsUsingPOST(products, stockTakeReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary addRecipesToSubStockTakeReport
     * @param {Array<StockTakeRecipePayload>} recipes recipes
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {string} subReportId subReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public addRecipesToSubStockTakeReportUsingPOST(recipes: Array<StockTakeRecipePayload>, stockTakeReportId: string, subReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).addRecipesToSubStockTakeReportUsingPOST(recipes, stockTakeReportId, subReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary addRecipes
     * @param {Array<StockTakeRecipePayload>} recipes recipes
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public addRecipesUsingPOST(recipes: Array<StockTakeRecipePayload>, stockTakeReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).addRecipesUsingPOST(recipes, stockTakeReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary completeSubStockTakeReport
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {string} subReportId subReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public completeSubStockTakeReportUsingPOST(stockTakeReportId: string, subReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).completeSubStockTakeReportUsingPOST(stockTakeReportId, subReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createCOGSReport
     * @param {string} closingStockTakeReportId closingStockTakeReportId
     * @param {string} openingStockTakeReportId openingStockTakeReportId
     * @param {FilterCategories} [filterCategories] filterCategories
     * @param {string} [salesId] salesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public createCOGSReportUsingPOST(closingStockTakeReportId: string, openingStockTakeReportId: string, filterCategories?: FilterCategories, salesId?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).createCOGSReportUsingPOST(closingStockTakeReportId, openingStockTakeReportId, filterCategories, salesId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createDiscrepancyStockReport
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {FilterCategories} [filterCategories] filterCategories
     * @param {string} [openingStockTakeReportId] openingStockTakeReportId
     * @param {string} [salesId] salesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public createDiscrepancyStockReportUsingPOST(stockTakeReportId: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).createDiscrepancyStockReportUsingPOST(stockTakeReportId, filterCategories, openingStockTakeReportId, salesId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createStockTakeReport
     * @param {StockTakeReportPayload} stockTakeReport stockTakeReport
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public createStockTakeReportUsingPOST(stockTakeReport: StockTakeReportPayload, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).createStockTakeReportUsingPOST(stockTakeReport, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createStockTakeTemplate
     * @param {StockTakeTemplate} stockTakeTemplate stockTakeTemplate
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public createStockTakeTemplateUsingPOST(stockTakeTemplate: StockTakeTemplate, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).createStockTakeTemplateUsingPOST(stockTakeTemplate, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createSubStockTakeReport
     * @param {StockTakeReportPayload} stockTakeReport stockTakeReport
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public createSubStockTakeReportUsingPOST(stockTakeReport: StockTakeReportPayload, stockTakeReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).createSubStockTakeReportUsingPOST(stockTakeReport, stockTakeReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteCOGSReport
     * @param {string} cogsReportId cogsReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public deleteCOGSReportUsingDELETE(cogsReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).deleteCOGSReportUsingDELETE(cogsReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteProductFromSubStockTakeReport
     * @param {string} barcode barcode
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {string} subReportId subReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public deleteProductFromSubStockTakeReportUsingDELETE(barcode: string, stockTakeReportId: string, subReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).deleteProductFromSubStockTakeReportUsingDELETE(barcode, stockTakeReportId, subReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteProduct
     * @param {string} barcode barcode
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public deleteProductUsingDELETE1(barcode: string, stockTakeReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).deleteProductUsingDELETE1(barcode, stockTakeReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteRecipeFromMainReport
     * @param {string} recipeId recipeId
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public deleteRecipeFromMainReportUsingDELETE(recipeId: string, stockTakeReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).deleteRecipeFromMainReportUsingDELETE(recipeId, stockTakeReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteRecipeFromSubStockTakeReport
     * @param {string} recipeId recipeId
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {string} subReportId subReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public deleteRecipeFromSubStockTakeReportUsingDELETE(recipeId: string, stockTakeReportId: string, subReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).deleteRecipeFromSubStockTakeReportUsingDELETE(recipeId, stockTakeReportId, subReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteStockTakeReport
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public deleteStockTakeReportUsingDELETE(stockTakeReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).deleteStockTakeReportUsingDELETE(stockTakeReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteStockTakeTemplate
     * @param {string} stockTakeTemplateId stockTakeTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public deleteStockTakeTemplateUsingDELETE(stockTakeTemplateId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).deleteStockTakeTemplateUsingDELETE(stockTakeTemplateId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteSubStockTakeReport
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {string} subReportId subReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public deleteSubStockTakeReportUsingDELETE(stockTakeReportId: string, subReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).deleteSubStockTakeReportUsingDELETE(stockTakeReportId, subReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportCOGSReportAsCsv
     * @param {string} cogsReportId cogsReportId
     * @param {string} fileName fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public exportCOGSReportAsCsvUsingGET(cogsReportId: string, fileName: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).exportCOGSReportAsCsvUsingGET(cogsReportId, fileName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportDiscrepancyReportAsCsv
     * @param {string} discrepancyReportId discrepancyReportId
     * @param {string} fileName fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public exportDiscrepancyReportAsCsvUsingGET(discrepancyReportId: string, fileName: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).exportDiscrepancyReportAsCsvUsingGET(discrepancyReportId, fileName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportDiscrepancyReportAsPDF
     * @param {string} discrepancyReportId discrepancyReportId
     * @param {string} fileName fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public exportDiscrepancyReportAsPDFUsingGET(discrepancyReportId: string, fileName: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).exportDiscrepancyReportAsPDFUsingGET(discrepancyReportId, fileName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportDiscrepancyReportAsXlsx
     * @param {string} discrepancyReportId discrepancyReportId
     * @param {string} fileName fileName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public exportDiscrepancyReportAsXlsxUsingGET(discrepancyReportId: string, fileName: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).exportDiscrepancyReportAsXlsxUsingGET(discrepancyReportId, fileName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportStockTakeReportAsPDF
     * @param {string} fileName fileName
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public exportStockTakeReportAsPDFUsingGET(fileName: string, stockTakeReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).exportStockTakeReportAsPDFUsingGET(fileName, stockTakeReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportStockTakeReportAsXlsx
     * @param {string} fileName fileName
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public exportStockTakeReportAsXlsxUsingGET(fileName: string, stockTakeReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).exportStockTakeReportAsXlsxUsingGET(fileName, stockTakeReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportStockTakeReportToCSV
     * @param {string} fileName fileName
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public exportStockTakeReportToCSVUsingGET(fileName: string, stockTakeReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).exportStockTakeReportToCSVUsingGET(fileName, stockTakeReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllStockTakeReports
     * @param {Date} [from] from
     * @param {number} [page] 
     * @param {string} [partialName] partialName
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {'COMPLETED' | 'IN_PROGRESS'} [status] status
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getAllStockTakeReportsUsingGET(from?: Date, page?: number, partialName?: string, size?: number, sort?: string, status?: 'COMPLETED' | 'IN_PROGRESS', to?: Date, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getAllStockTakeReportsUsingGET(from, page, partialName, size, sort, status, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCOGSReportById
     * @param {string} cogsReportId cogsReportId
     * @param {string} [partialProductName] partialProductName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getCOGSReportByIdUsingGET(cogsReportId: string, partialProductName?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getCOGSReportByIdUsingGET(cogsReportId, partialProductName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCOGSReportProducts
     * @param {string} cogsReportId cogsReportId
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getCOGSReportProductsUsingGET(cogsReportId: string, page?: number, size?: number, sort?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getCOGSReportProductsUsingGET(cogsReportId, page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getCOGSReportsByCriteria
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getCOGSReportsByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getCOGSReportsByCriteriaUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getDiscrepancyData
     * @param {string} discrepancyReportId discrepancyReportId
     * @param {number} [page] 
     * @param {string} [partialProductName] partialProductName
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getDiscrepancyDataUsingGET(discrepancyReportId: string, page?: number, partialProductName?: string, size?: number, sort?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getDiscrepancyDataUsingGET(discrepancyReportId, page, partialProductName, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getDiscrepancyReport
     * @param {string} discrepancyReportId discrepancyReportId
     * @param {string} [partialProductName] partialProductName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getDiscrepancyReportUsingGET(discrepancyReportId: string, partialProductName?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getDiscrepancyReportUsingGET(discrepancyReportId, partialProductName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getDiscrepancyReportsByCriteria
     * @param {Date} [from] from
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getDiscrepancyReportsByCriteriaUsingGET(from?: Date, page?: number, size?: number, sort?: string, to?: Date, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getDiscrepancyReportsByCriteriaUsingGET(from, page, size, sort, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getProducts
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {string} subReportId subReportId
     * @param {string} [category] category
     * @param {number} [page] 
     * @param {string} [partialProductName] partialProductName
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [subCategory] subCategory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getProductsUsingGET(stockTakeReportId: string, subReportId: string, category?: string, page?: number, partialProductName?: string, size?: number, sort?: string, subCategory?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getProductsUsingGET(stockTakeReportId, subReportId, category, page, partialProductName, size, sort, subCategory, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getProducts
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {string} [category] category
     * @param {number} [page] 
     * @param {string} [partialProductName] partialProductName
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [subCategory] subCategory
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getProductsUsingGET1(stockTakeReportId: string, category?: string, page?: number, partialProductName?: string, size?: number, sort?: string, subCategory?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getProductsUsingGET1(stockTakeReportId, category, page, partialProductName, size, sort, subCategory, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getRecipesFromMainReport
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {number} [page] 
     * @param {string} [partialRecipeName] partialRecipeName
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getRecipesFromMainReportUsingGET(stockTakeReportId: string, page?: number, partialRecipeName?: string, size?: number, sort?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getRecipesFromMainReportUsingGET(stockTakeReportId, page, partialRecipeName, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getRecipesFromSubStockTakeReport
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {string} subReportId subReportId
     * @param {number} [page] 
     * @param {string} [partialRecipeName] partialRecipeName
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getRecipesFromSubStockTakeReportUsingGET(stockTakeReportId: string, subReportId: string, page?: number, partialRecipeName?: string, size?: number, sort?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getRecipesFromSubStockTakeReportUsingGET(stockTakeReportId, subReportId, page, partialRecipeName, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSTTemplatesByCriteria
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getSTTemplatesByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getSTTemplatesByCriteriaUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getStockTakeReportById
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {string} subReportId subReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getStockTakeReportByIdUsingGET(stockTakeReportId: string, subReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getStockTakeReportByIdUsingGET(stockTakeReportId, subReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getStockTakeReportById
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getStockTakeReportByIdUsingGET1(stockTakeReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getStockTakeReportByIdUsingGET1(stockTakeReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getStockTakeTemplate
     * @param {string} stockTakeTemplateId stockTakeTemplateId
     * @param {string} [barcode] barcode
     * @param {string} [partialProductName] partialProductName
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public getStockTakeTemplateUsingGET(stockTakeTemplateId: string, barcode?: string, partialProductName?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).getStockTakeTemplateUsingGET(stockTakeTemplateId, barcode, partialProductName, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary mergeStockTakeReport
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {Date} [completedAt] completedAt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public mergeStockTakeReportUsingGET(stockTakeReportId: string, completedAt?: Date, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).mergeStockTakeReportUsingGET(stockTakeReportId, completedAt, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setTotalAmountOfProductsAndRecipesWhereMissingInST
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public setTotalAmountOfProductsAndRecipesWhereMissingInSTUsingPATCH(options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).setTotalAmountOfProductsAndRecipesWhereMissingInSTUsingPATCH(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateCOGSReport
     * @param {string} cogsReportId cogsReportId
     * @param {string} [closingStockTakeReportId] closingStockTakeReportId
     * @param {FilterCategories} [filterCategories] filterCategories
     * @param {string} [openingStockTakeReportId] openingStockTakeReportId
     * @param {string} [salesId] salesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public updateCOGSReportUsingPUT(cogsReportId: string, closingStockTakeReportId?: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).updateCOGSReportUsingPUT(cogsReportId, closingStockTakeReportId, filterCategories, openingStockTakeReportId, salesId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateDiscrepancyReportById
     * @param {string} discrepancyReportId discrepancyReportId
     * @param {FilterCategories} [filterCategories] filterCategories
     * @param {string} [openingStockTakeReportId] openingStockTakeReportId
     * @param {string} [salesId] salesId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public updateDiscrepancyReportByIdUsingPUT(discrepancyReportId: string, filterCategories?: FilterCategories, openingStockTakeReportId?: string, salesId?: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).updateDiscrepancyReportByIdUsingPUT(discrepancyReportId, filterCategories, openingStockTakeReportId, salesId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateProductInSubStockTakeReport
     * @param {string} barcode barcode
     * @param {StockTakeProductPayload} product product
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {string} subReportId subReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public updateProductInSubStockTakeReportUsingPUT(barcode: string, product: StockTakeProductPayload, stockTakeReportId: string, subReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).updateProductInSubStockTakeReportUsingPUT(barcode, product, stockTakeReportId, subReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateProduct
     * @param {string} barcode barcode
     * @param {StockTakeProductPayload} product product
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public updateProductUsingPUT1(barcode: string, product: StockTakeProductPayload, stockTakeReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).updateProductUsingPUT1(barcode, product, stockTakeReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateRecipeInMainReport
     * @param {StockTakeUpdateRecipePayload} recipe recipe
     * @param {string} recipeId recipeId
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public updateRecipeInMainReportUsingPUT(recipe: StockTakeUpdateRecipePayload, recipeId: string, stockTakeReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).updateRecipeInMainReportUsingPUT(recipe, recipeId, stockTakeReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateRecipeInSubStockTakeReport
     * @param {StockTakeUpdateRecipePayload} recipe recipe
     * @param {string} recipeId recipeId
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {string} subReportId subReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public updateRecipeInSubStockTakeReportUsingPUT(recipe: StockTakeUpdateRecipePayload, recipeId: string, stockTakeReportId: string, subReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).updateRecipeInSubStockTakeReportUsingPUT(recipe, recipeId, stockTakeReportId, subReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateStockTakeReport
     * @param {StockTakeReportUpdatePayload} payload payload
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public updateStockTakeReportUsingPUT(payload: StockTakeReportUpdatePayload, stockTakeReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).updateStockTakeReportUsingPUT(payload, stockTakeReportId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateStockTakeTemplate
     * @param {StockTakeTemplate} stockTakeTemplate stockTakeTemplate
     * @param {string} stockTakeTemplateId stockTakeTemplateId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public updateStockTakeTemplateUsingPUT(stockTakeTemplate: StockTakeTemplate, stockTakeTemplateId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).updateStockTakeTemplateUsingPUT(stockTakeTemplate, stockTakeTemplateId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateSubStockTakeReport
     * @param {StockTakeReportUpdatePayload} stockTakeReport stockTakeReport
     * @param {string} stockTakeReportId stockTakeReportId
     * @param {string} subReportId subReportId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTakeControllerApi
     */
    public updateSubStockTakeReportUsingPUT(stockTakeReport: StockTakeReportUpdatePayload, stockTakeReportId: string, subReportId: string, options?: any) {
        return GatewayStockTakeControllerApiFp(this.configuration).updateSubStockTakeReportUsingPUT(stockTakeReport, stockTakeReportId, subReportId, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayStockTransferConnectionControllerApi - fetch parameter creator
 * @export
 */
export const GatewayStockTransferConnectionControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createStockTransferConnection
         * @param {StockTransferConnectionCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockTransferConnectionUsingPOST(payload: StockTransferConnectionCreatePayload, options: any = {}): FetchArgs {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createStockTransferConnectionUsingPOST.');
            }
            const localVarPath = `/v1/stock-transfer-connections`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StockTransferConnectionCreatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(payload || {}) : (payload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteStockTransferConnectionById
         * @param {string} stockTransferConnectionId stockTransferConnectionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStockTransferConnectionByIdUsingDELETE(stockTransferConnectionId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTransferConnectionId' is not null or undefined
            if (stockTransferConnectionId === null || stockTransferConnectionId === undefined) {
                throw new RequiredError('stockTransferConnectionId','Required parameter stockTransferConnectionId was null or undefined when calling deleteStockTransferConnectionByIdUsingDELETE.');
            }
            const localVarPath = `/v1/stock-transfer-connections/{stockTransferConnectionId}`
                .replace(`{${"stockTransferConnectionId"}}`, encodeURIComponent(String(stockTransferConnectionId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getStockTransferConnectionByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTransferConnectionByCriteriaUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/stock-transfer-connections`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayStockTransferConnectionControllerApi - functional programming interface
 * @export
 */
export const GatewayStockTransferConnectionControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createStockTransferConnection
         * @param {StockTransferConnectionCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockTransferConnectionUsingPOST(payload: StockTransferConnectionCreatePayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTransferConnectionControllerApiFetchParamCreator(configuration).createStockTransferConnectionUsingPOST(payload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteStockTransferConnectionById
         * @param {string} stockTransferConnectionId stockTransferConnectionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStockTransferConnectionByIdUsingDELETE(stockTransferConnectionId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTransferConnectionControllerApiFetchParamCreator(configuration).deleteStockTransferConnectionByIdUsingDELETE(stockTransferConnectionId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getStockTransferConnectionByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTransferConnectionByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageStockTransferConnectionDto> {
            const localVarFetchArgs = GatewayStockTransferConnectionControllerApiFetchParamCreator(configuration).getStockTransferConnectionByCriteriaUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayStockTransferConnectionControllerApi - factory interface
 * @export
 */
export const GatewayStockTransferConnectionControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createStockTransferConnection
         * @param {StockTransferConnectionCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockTransferConnectionUsingPOST(payload: StockTransferConnectionCreatePayload, options?: any) {
            return GatewayStockTransferConnectionControllerApiFp(configuration).createStockTransferConnectionUsingPOST(payload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteStockTransferConnectionById
         * @param {string} stockTransferConnectionId stockTransferConnectionId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStockTransferConnectionByIdUsingDELETE(stockTransferConnectionId: string, options?: any) {
            return GatewayStockTransferConnectionControllerApiFp(configuration).deleteStockTransferConnectionByIdUsingDELETE(stockTransferConnectionId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getStockTransferConnectionByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTransferConnectionByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return GatewayStockTransferConnectionControllerApiFp(configuration).getStockTransferConnectionByCriteriaUsingGET(page, size, sort, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayStockTransferConnectionControllerApi - object-oriented interface
 * @export
 * @class GatewayStockTransferConnectionControllerApi
 * @extends {BaseAPI}
 */
export class GatewayStockTransferConnectionControllerApi extends BaseAPI {
    /**
     * 
     * @summary createStockTransferConnection
     * @param {StockTransferConnectionCreatePayload} payload payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTransferConnectionControllerApi
     */
    public createStockTransferConnectionUsingPOST(payload: StockTransferConnectionCreatePayload, options?: any) {
        return GatewayStockTransferConnectionControllerApiFp(this.configuration).createStockTransferConnectionUsingPOST(payload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteStockTransferConnectionById
     * @param {string} stockTransferConnectionId stockTransferConnectionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTransferConnectionControllerApi
     */
    public deleteStockTransferConnectionByIdUsingDELETE(stockTransferConnectionId: string, options?: any) {
        return GatewayStockTransferConnectionControllerApiFp(this.configuration).deleteStockTransferConnectionByIdUsingDELETE(stockTransferConnectionId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getStockTransferConnectionByCriteria
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTransferConnectionControllerApi
     */
    public getStockTransferConnectionByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return GatewayStockTransferConnectionControllerApiFp(this.configuration).getStockTransferConnectionByCriteriaUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayStockTransferControllerApi - fetch parameter creator
 * @export
 */
export const GatewayStockTransferControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary cancelStockTransfer
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelStockTransferUsingPOST(stockTransferId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTransferId' is not null or undefined
            if (stockTransferId === null || stockTransferId === undefined) {
                throw new RequiredError('stockTransferId','Required parameter stockTransferId was null or undefined when calling cancelStockTransferUsingPOST.');
            }
            const localVarPath = `/v1/stock-transfers/{stockTransferId}/cancel`
                .replace(`{${"stockTransferId"}}`, encodeURIComponent(String(stockTransferId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createStockTransfer
         * @param {StockTransferCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockTransferUsingPOST(payload: StockTransferCreatePayload, options: any = {}): FetchArgs {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createStockTransferUsingPOST.');
            }
            const localVarPath = `/v1/stock-transfers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StockTransferCreatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(payload || {}) : (payload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteStockTransferById
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStockTransferByIdUsingDELETE(stockTransferId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTransferId' is not null or undefined
            if (stockTransferId === null || stockTransferId === undefined) {
                throw new RequiredError('stockTransferId','Required parameter stockTransferId was null or undefined when calling deleteStockTransferByIdUsingDELETE.');
            }
            const localVarPath = `/v1/stock-transfers/{stockTransferId}`
                .replace(`{${"stockTransferId"}}`, encodeURIComponent(String(stockTransferId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getReceivedStockTransfersByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT'} [status] status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivedStockTransfersByCriteriaUsingGET(page?: number, size?: number, sort?: string, status?: 'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT', options: any = {}): FetchArgs {
            const localVarPath = `/v1/stock-transfers/received`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSentStockTransfersByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT'} [status] status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSentStockTransfersByCriteriaUsingGET(page?: number, size?: number, sort?: string, status?: 'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT', options: any = {}): FetchArgs {
            const localVarPath = `/v1/stock-transfers/sent`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getStockTransferById
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTransferByIdUsingGET(stockTransferId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTransferId' is not null or undefined
            if (stockTransferId === null || stockTransferId === undefined) {
                throw new RequiredError('stockTransferId','Required parameter stockTransferId was null or undefined when calling getStockTransferByIdUsingGET.');
            }
            const localVarPath = `/v1/stock-transfers/{stockTransferId}`
                .replace(`{${"stockTransferId"}}`, encodeURIComponent(String(stockTransferId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary receiveStockTransfer
         * @param {string} stockTransferId stockTransferId
         * @param {Date} [receivedAt] receivedAt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveStockTransferUsingPOST(stockTransferId: string, receivedAt?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'stockTransferId' is not null or undefined
            if (stockTransferId === null || stockTransferId === undefined) {
                throw new RequiredError('stockTransferId','Required parameter stockTransferId was null or undefined when calling receiveStockTransferUsingPOST.');
            }
            const localVarPath = `/v1/stock-transfers/{stockTransferId}/receive`
                .replace(`{${"stockTransferId"}}`, encodeURIComponent(String(stockTransferId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (receivedAt !== undefined) {
                localVarQueryParameter['receivedAt'] = (receivedAt as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary revertStockTransferToSent
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertStockTransferToSentUsingPATCH(stockTransferId: string, options: any = {}): FetchArgs {
            // verify required parameter 'stockTransferId' is not null or undefined
            if (stockTransferId === null || stockTransferId === undefined) {
                throw new RequiredError('stockTransferId','Required parameter stockTransferId was null or undefined when calling revertStockTransferToSentUsingPATCH.');
            }
            const localVarPath = `/v1/stock-transfers/{stockTransferId}/revert-to-sent`
                .replace(`{${"stockTransferId"}}`, encodeURIComponent(String(stockTransferId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateStockTransferReceivedProduct
         * @param {ReceivedProductUpdatePayload} productPayload productPayload
         * @param {string} sentProductId sentProductId
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTransferReceivedProductUsingPUT(productPayload: ReceivedProductUpdatePayload, sentProductId: string, stockTransferId: string, options: any = {}): FetchArgs {
            // verify required parameter 'productPayload' is not null or undefined
            if (productPayload === null || productPayload === undefined) {
                throw new RequiredError('productPayload','Required parameter productPayload was null or undefined when calling updateStockTransferReceivedProductUsingPUT.');
            }
            // verify required parameter 'sentProductId' is not null or undefined
            if (sentProductId === null || sentProductId === undefined) {
                throw new RequiredError('sentProductId','Required parameter sentProductId was null or undefined when calling updateStockTransferReceivedProductUsingPUT.');
            }
            // verify required parameter 'stockTransferId' is not null or undefined
            if (stockTransferId === null || stockTransferId === undefined) {
                throw new RequiredError('stockTransferId','Required parameter stockTransferId was null or undefined when calling updateStockTransferReceivedProductUsingPUT.');
            }
            const localVarPath = `/v1/stock-transfers/{stockTransferId}/received-product/{sentProductId}`
                .replace(`{${"sentProductId"}}`, encodeURIComponent(String(sentProductId)))
                .replace(`{${"stockTransferId"}}`, encodeURIComponent(String(stockTransferId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ReceivedProductUpdatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(productPayload || {}) : (productPayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateStockTransferSentProduct
         * @param {SentProductUpdatePayload} productPayload productPayload
         * @param {string} sentProductId sentProductId
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTransferSentProductUsingPUT(productPayload: SentProductUpdatePayload, sentProductId: string, stockTransferId: string, options: any = {}): FetchArgs {
            // verify required parameter 'productPayload' is not null or undefined
            if (productPayload === null || productPayload === undefined) {
                throw new RequiredError('productPayload','Required parameter productPayload was null or undefined when calling updateStockTransferSentProductUsingPUT.');
            }
            // verify required parameter 'sentProductId' is not null or undefined
            if (sentProductId === null || sentProductId === undefined) {
                throw new RequiredError('sentProductId','Required parameter sentProductId was null or undefined when calling updateStockTransferSentProductUsingPUT.');
            }
            // verify required parameter 'stockTransferId' is not null or undefined
            if (stockTransferId === null || stockTransferId === undefined) {
                throw new RequiredError('stockTransferId','Required parameter stockTransferId was null or undefined when calling updateStockTransferSentProductUsingPUT.');
            }
            const localVarPath = `/v1/stock-transfers/{stockTransferId}/sent-product/{sentProductId}`
                .replace(`{${"sentProductId"}}`, encodeURIComponent(String(sentProductId)))
                .replace(`{${"stockTransferId"}}`, encodeURIComponent(String(stockTransferId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SentProductUpdatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(productPayload || {}) : (productPayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateStockTransfer
         * @param {StockTransferCreatePayload} payload payload
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTransferUsingPUT(payload: StockTransferCreatePayload, stockTransferId: string, options: any = {}): FetchArgs {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling updateStockTransferUsingPUT.');
            }
            // verify required parameter 'stockTransferId' is not null or undefined
            if (stockTransferId === null || stockTransferId === undefined) {
                throw new RequiredError('stockTransferId','Required parameter stockTransferId was null or undefined when calling updateStockTransferUsingPUT.');
            }
            const localVarPath = `/v1/stock-transfers/{stockTransferId}`
                .replace(`{${"stockTransferId"}}`, encodeURIComponent(String(stockTransferId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"StockTransferCreatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(payload || {}) : (payload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayStockTransferControllerApi - functional programming interface
 * @export
 */
export const GatewayStockTransferControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary cancelStockTransfer
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelStockTransferUsingPOST(stockTransferId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTransferControllerApiFetchParamCreator(configuration).cancelStockTransferUsingPOST(stockTransferId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createStockTransfer
         * @param {StockTransferCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockTransferUsingPOST(payload: StockTransferCreatePayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTransferWithOrgDetails> {
            const localVarFetchArgs = GatewayStockTransferControllerApiFetchParamCreator(configuration).createStockTransferUsingPOST(payload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteStockTransferById
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStockTransferByIdUsingDELETE(stockTransferId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTransferControllerApiFetchParamCreator(configuration).deleteStockTransferByIdUsingDELETE(stockTransferId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getReceivedStockTransfersByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT'} [status] status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivedStockTransfersByCriteriaUsingGET(page?: number, size?: number, sort?: string, status?: 'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageStockTransferWithOrgDetails> {
            const localVarFetchArgs = GatewayStockTransferControllerApiFetchParamCreator(configuration).getReceivedStockTransfersByCriteriaUsingGET(page, size, sort, status, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSentStockTransfersByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT'} [status] status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSentStockTransfersByCriteriaUsingGET(page?: number, size?: number, sort?: string, status?: 'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT', options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageStockTransferWithOrgDetails> {
            const localVarFetchArgs = GatewayStockTransferControllerApiFetchParamCreator(configuration).getSentStockTransfersByCriteriaUsingGET(page, size, sort, status, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getStockTransferById
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTransferByIdUsingGET(stockTransferId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTransferWithOrgDetails> {
            const localVarFetchArgs = GatewayStockTransferControllerApiFetchParamCreator(configuration).getStockTransferByIdUsingGET(stockTransferId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary receiveStockTransfer
         * @param {string} stockTransferId stockTransferId
         * @param {Date} [receivedAt] receivedAt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveStockTransferUsingPOST(stockTransferId: string, receivedAt?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTransferControllerApiFetchParamCreator(configuration).receiveStockTransferUsingPOST(stockTransferId, receivedAt, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary revertStockTransferToSent
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertStockTransferToSentUsingPATCH(stockTransferId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayStockTransferControllerApiFetchParamCreator(configuration).revertStockTransferToSentUsingPATCH(stockTransferId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateStockTransferReceivedProduct
         * @param {ReceivedProductUpdatePayload} productPayload productPayload
         * @param {string} sentProductId sentProductId
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTransferReceivedProductUsingPUT(productPayload: ReceivedProductUpdatePayload, sentProductId: string, stockTransferId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTransferWithOrgDetails> {
            const localVarFetchArgs = GatewayStockTransferControllerApiFetchParamCreator(configuration).updateStockTransferReceivedProductUsingPUT(productPayload, sentProductId, stockTransferId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateStockTransferSentProduct
         * @param {SentProductUpdatePayload} productPayload productPayload
         * @param {string} sentProductId sentProductId
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTransferSentProductUsingPUT(productPayload: SentProductUpdatePayload, sentProductId: string, stockTransferId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTransferWithOrgDetails> {
            const localVarFetchArgs = GatewayStockTransferControllerApiFetchParamCreator(configuration).updateStockTransferSentProductUsingPUT(productPayload, sentProductId, stockTransferId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateStockTransfer
         * @param {StockTransferCreatePayload} payload payload
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTransferUsingPUT(payload: StockTransferCreatePayload, stockTransferId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<StockTransferWithOrgDetails> {
            const localVarFetchArgs = GatewayStockTransferControllerApiFetchParamCreator(configuration).updateStockTransferUsingPUT(payload, stockTransferId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayStockTransferControllerApi - factory interface
 * @export
 */
export const GatewayStockTransferControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary cancelStockTransfer
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelStockTransferUsingPOST(stockTransferId: string, options?: any) {
            return GatewayStockTransferControllerApiFp(configuration).cancelStockTransferUsingPOST(stockTransferId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createStockTransfer
         * @param {StockTransferCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createStockTransferUsingPOST(payload: StockTransferCreatePayload, options?: any) {
            return GatewayStockTransferControllerApiFp(configuration).createStockTransferUsingPOST(payload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteStockTransferById
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStockTransferByIdUsingDELETE(stockTransferId: string, options?: any) {
            return GatewayStockTransferControllerApiFp(configuration).deleteStockTransferByIdUsingDELETE(stockTransferId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getReceivedStockTransfersByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT'} [status] status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getReceivedStockTransfersByCriteriaUsingGET(page?: number, size?: number, sort?: string, status?: 'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT', options?: any) {
            return GatewayStockTransferControllerApiFp(configuration).getReceivedStockTransfersByCriteriaUsingGET(page, size, sort, status, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSentStockTransfersByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT'} [status] status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSentStockTransfersByCriteriaUsingGET(page?: number, size?: number, sort?: string, status?: 'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT', options?: any) {
            return GatewayStockTransferControllerApiFp(configuration).getSentStockTransfersByCriteriaUsingGET(page, size, sort, status, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getStockTransferById
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStockTransferByIdUsingGET(stockTransferId: string, options?: any) {
            return GatewayStockTransferControllerApiFp(configuration).getStockTransferByIdUsingGET(stockTransferId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary receiveStockTransfer
         * @param {string} stockTransferId stockTransferId
         * @param {Date} [receivedAt] receivedAt
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        receiveStockTransferUsingPOST(stockTransferId: string, receivedAt?: Date, options?: any) {
            return GatewayStockTransferControllerApiFp(configuration).receiveStockTransferUsingPOST(stockTransferId, receivedAt, options)(fetch, basePath);
        },
        /**
         * 
         * @summary revertStockTransferToSent
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        revertStockTransferToSentUsingPATCH(stockTransferId: string, options?: any) {
            return GatewayStockTransferControllerApiFp(configuration).revertStockTransferToSentUsingPATCH(stockTransferId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateStockTransferReceivedProduct
         * @param {ReceivedProductUpdatePayload} productPayload productPayload
         * @param {string} sentProductId sentProductId
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTransferReceivedProductUsingPUT(productPayload: ReceivedProductUpdatePayload, sentProductId: string, stockTransferId: string, options?: any) {
            return GatewayStockTransferControllerApiFp(configuration).updateStockTransferReceivedProductUsingPUT(productPayload, sentProductId, stockTransferId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateStockTransferSentProduct
         * @param {SentProductUpdatePayload} productPayload productPayload
         * @param {string} sentProductId sentProductId
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTransferSentProductUsingPUT(productPayload: SentProductUpdatePayload, sentProductId: string, stockTransferId: string, options?: any) {
            return GatewayStockTransferControllerApiFp(configuration).updateStockTransferSentProductUsingPUT(productPayload, sentProductId, stockTransferId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateStockTransfer
         * @param {StockTransferCreatePayload} payload payload
         * @param {string} stockTransferId stockTransferId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateStockTransferUsingPUT(payload: StockTransferCreatePayload, stockTransferId: string, options?: any) {
            return GatewayStockTransferControllerApiFp(configuration).updateStockTransferUsingPUT(payload, stockTransferId, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayStockTransferControllerApi - object-oriented interface
 * @export
 * @class GatewayStockTransferControllerApi
 * @extends {BaseAPI}
 */
export class GatewayStockTransferControllerApi extends BaseAPI {
    /**
     * 
     * @summary cancelStockTransfer
     * @param {string} stockTransferId stockTransferId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTransferControllerApi
     */
    public cancelStockTransferUsingPOST(stockTransferId: string, options?: any) {
        return GatewayStockTransferControllerApiFp(this.configuration).cancelStockTransferUsingPOST(stockTransferId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createStockTransfer
     * @param {StockTransferCreatePayload} payload payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTransferControllerApi
     */
    public createStockTransferUsingPOST(payload: StockTransferCreatePayload, options?: any) {
        return GatewayStockTransferControllerApiFp(this.configuration).createStockTransferUsingPOST(payload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteStockTransferById
     * @param {string} stockTransferId stockTransferId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTransferControllerApi
     */
    public deleteStockTransferByIdUsingDELETE(stockTransferId: string, options?: any) {
        return GatewayStockTransferControllerApiFp(this.configuration).deleteStockTransferByIdUsingDELETE(stockTransferId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getReceivedStockTransfersByCriteria
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT'} [status] status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTransferControllerApi
     */
    public getReceivedStockTransfersByCriteriaUsingGET(page?: number, size?: number, sort?: string, status?: 'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT', options?: any) {
        return GatewayStockTransferControllerApiFp(this.configuration).getReceivedStockTransfersByCriteriaUsingGET(page, size, sort, status, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSentStockTransfersByCriteria
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT'} [status] status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTransferControllerApi
     */
    public getSentStockTransfersByCriteriaUsingGET(page?: number, size?: number, sort?: string, status?: 'CANCELLED' | 'DRAFT' | 'RECEIVED' | 'SENT', options?: any) {
        return GatewayStockTransferControllerApiFp(this.configuration).getSentStockTransfersByCriteriaUsingGET(page, size, sort, status, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getStockTransferById
     * @param {string} stockTransferId stockTransferId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTransferControllerApi
     */
    public getStockTransferByIdUsingGET(stockTransferId: string, options?: any) {
        return GatewayStockTransferControllerApiFp(this.configuration).getStockTransferByIdUsingGET(stockTransferId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary receiveStockTransfer
     * @param {string} stockTransferId stockTransferId
     * @param {Date} [receivedAt] receivedAt
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTransferControllerApi
     */
    public receiveStockTransferUsingPOST(stockTransferId: string, receivedAt?: Date, options?: any) {
        return GatewayStockTransferControllerApiFp(this.configuration).receiveStockTransferUsingPOST(stockTransferId, receivedAt, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary revertStockTransferToSent
     * @param {string} stockTransferId stockTransferId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTransferControllerApi
     */
    public revertStockTransferToSentUsingPATCH(stockTransferId: string, options?: any) {
        return GatewayStockTransferControllerApiFp(this.configuration).revertStockTransferToSentUsingPATCH(stockTransferId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateStockTransferReceivedProduct
     * @param {ReceivedProductUpdatePayload} productPayload productPayload
     * @param {string} sentProductId sentProductId
     * @param {string} stockTransferId stockTransferId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTransferControllerApi
     */
    public updateStockTransferReceivedProductUsingPUT(productPayload: ReceivedProductUpdatePayload, sentProductId: string, stockTransferId: string, options?: any) {
        return GatewayStockTransferControllerApiFp(this.configuration).updateStockTransferReceivedProductUsingPUT(productPayload, sentProductId, stockTransferId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateStockTransferSentProduct
     * @param {SentProductUpdatePayload} productPayload productPayload
     * @param {string} sentProductId sentProductId
     * @param {string} stockTransferId stockTransferId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTransferControllerApi
     */
    public updateStockTransferSentProductUsingPUT(productPayload: SentProductUpdatePayload, sentProductId: string, stockTransferId: string, options?: any) {
        return GatewayStockTransferControllerApiFp(this.configuration).updateStockTransferSentProductUsingPUT(productPayload, sentProductId, stockTransferId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateStockTransfer
     * @param {StockTransferCreatePayload} payload payload
     * @param {string} stockTransferId stockTransferId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayStockTransferControllerApi
     */
    public updateStockTransferUsingPUT(payload: StockTransferCreatePayload, stockTransferId: string, options?: any) {
        return GatewayStockTransferControllerApiFp(this.configuration).updateStockTransferUsingPUT(payload, stockTransferId, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewaySupplierControllerApi - fetch parameter creator
 * @export
 */
export const GatewaySupplierControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary convertFile
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertFileUsingPOST2(file: string, options: any = {}): FetchArgs {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError('file','Required parameter file was null or undefined when calling convertFileUsingPOST2.');
            }
            const localVarPath = `/v1/suppliers/convert`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(file || {}) : (file || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary copyProductsFromMaster
         * @param {ProductIdListDto} masterProducts masterProducts
         * @param {string} subSupplierId subSupplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProductsFromMasterUsingPOST(masterProducts: ProductIdListDto, subSupplierId: string, options: any = {}): FetchArgs {
            // verify required parameter 'masterProducts' is not null or undefined
            if (masterProducts === null || masterProducts === undefined) {
                throw new RequiredError('masterProducts','Required parameter masterProducts was null or undefined when calling copyProductsFromMasterUsingPOST.');
            }
            // verify required parameter 'subSupplierId' is not null or undefined
            if (subSupplierId === null || subSupplierId === undefined) {
                throw new RequiredError('subSupplierId','Required parameter subSupplierId was null or undefined when calling copyProductsFromMasterUsingPOST.');
            }
            const localVarPath = `/v1/suppliers/{subSupplierId}/products/copy-from-master`
                .replace(`{${"subSupplierId"}}`, encodeURIComponent(String(subSupplierId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductIdListDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(masterProducts || {}) : (masterProducts || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary copySupplierWithProductsFromMaster
         * @param {ProductIdListDto} masterProducts masterProducts
         * @param {string} masterSupplierId masterSupplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copySupplierWithProductsFromMasterUsingPOST(masterProducts: ProductIdListDto, masterSupplierId: string, options: any = {}): FetchArgs {
            // verify required parameter 'masterProducts' is not null or undefined
            if (masterProducts === null || masterProducts === undefined) {
                throw new RequiredError('masterProducts','Required parameter masterProducts was null or undefined when calling copySupplierWithProductsFromMasterUsingPOST.');
            }
            // verify required parameter 'masterSupplierId' is not null or undefined
            if (masterSupplierId === null || masterSupplierId === undefined) {
                throw new RequiredError('masterSupplierId','Required parameter masterSupplierId was null or undefined when calling copySupplierWithProductsFromMasterUsingPOST.');
            }
            const localVarPath = `/v1/suppliers/copy-supplier-and-products`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (masterSupplierId !== undefined) {
                localVarQueryParameter['masterSupplierId'] = masterSupplierId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ProductIdListDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(masterProducts || {}) : (masterProducts || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary copySuppliersWithTheirProductsFromMaster
         * @param {Array<string>} masterSuppliersIds masterSuppliersIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copySuppliersWithTheirProductsFromMasterUsingPOST(masterSuppliersIds: Array<string>, options: any = {}): FetchArgs {
            // verify required parameter 'masterSuppliersIds' is not null or undefined
            if (masterSuppliersIds === null || masterSuppliersIds === undefined) {
                throw new RequiredError('masterSuppliersIds','Required parameter masterSuppliersIds was null or undefined when calling copySuppliersWithTheirProductsFromMasterUsingPOST.');
            }
            const localVarPath = `/v1/suppliers/copy-suppliers-and-products`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (masterSuppliersIds) {
                localVarQueryParameter['masterSuppliersIds'] = masterSuppliersIds;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createProduct
         * @param {Product} product product
         * @param {string} supplierId supplierId
         * @param {boolean} [generateBarcode] generateBarcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductUsingPOST(product: Product, supplierId: string, generateBarcode?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'product' is not null or undefined
            if (product === null || product === undefined) {
                throw new RequiredError('product','Required parameter product was null or undefined when calling createProductUsingPOST.');
            }
            // verify required parameter 'supplierId' is not null or undefined
            if (supplierId === null || supplierId === undefined) {
                throw new RequiredError('supplierId','Required parameter supplierId was null or undefined when calling createProductUsingPOST.');
            }
            const localVarPath = `/v1/suppliers/{supplierId}/product`
                .replace(`{${"supplierId"}}`, encodeURIComponent(String(supplierId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (generateBarcode !== undefined) {
                localVarQueryParameter['generateBarcode'] = generateBarcode;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Product" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(product || {}) : (product || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createProductsFromJson
         * @param {Array<Product>} products products
         * @param {string} supplierId supplierId
         * @param {boolean} [generateBarcode] generateBarcode
         * @param {boolean} [override] override
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductsFromJsonUsingPOST(products: Array<Product>, supplierId: string, generateBarcode?: boolean, override?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'products' is not null or undefined
            if (products === null || products === undefined) {
                throw new RequiredError('products','Required parameter products was null or undefined when calling createProductsFromJsonUsingPOST.');
            }
            // verify required parameter 'supplierId' is not null or undefined
            if (supplierId === null || supplierId === undefined) {
                throw new RequiredError('supplierId','Required parameter supplierId was null or undefined when calling createProductsFromJsonUsingPOST.');
            }
            const localVarPath = `/v1/suppliers/{supplierId}/products`
                .replace(`{${"supplierId"}}`, encodeURIComponent(String(supplierId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (generateBarcode !== undefined) {
                localVarQueryParameter['generateBarcode'] = generateBarcode;
            }

            if (override !== undefined) {
                localVarQueryParameter['override'] = override;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;Product&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(products || {}) : (products || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createSupplier
         * @param {Supplier} supplier supplier
         * @param {boolean} [authenticated] 
         * @param {string} [authorities0Authority] 
         * @param {{ [key: string]: string; }} [credentials] 
         * @param {{ [key: string]: string; }} [details] 
         * @param {{ [key: string]: string; }} [principal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierUsingPOST(supplier: Supplier, authenticated?: boolean, authorities0Authority?: string, credentials?: { [key: string]: string; }, details?: { [key: string]: string; }, principal?: { [key: string]: string; }, options: any = {}): FetchArgs {
            // verify required parameter 'supplier' is not null or undefined
            if (supplier === null || supplier === undefined) {
                throw new RequiredError('supplier','Required parameter supplier was null or undefined when calling createSupplierUsingPOST.');
            }
            const localVarPath = `/v1/suppliers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (authenticated !== undefined) {
                localVarQueryParameter['authenticated'] = authenticated;
            }

            if (authorities0Authority !== undefined) {
                localVarQueryParameter['authorities[0].authority'] = authorities0Authority;
            }

            if (credentials !== undefined) {
                localVarQueryParameter['credentials'] = credentials;
            }

            if (details !== undefined) {
                localVarQueryParameter['details'] = details;
            }

            if (principal !== undefined) {
                localVarQueryParameter['principal'] = principal;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Supplier" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(supplier || {}) : (supplier || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createSuppliers
         * @param {Array<Supplier>} suppliers suppliers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSuppliersUsingPOST(suppliers: Array<Supplier>, options: any = {}): FetchArgs {
            // verify required parameter 'suppliers' is not null or undefined
            if (suppliers === null || suppliers === undefined) {
                throw new RequiredError('suppliers','Required parameter suppliers was null or undefined when calling createSuppliersUsingPOST.');
            }
            const localVarPath = `/v1/suppliers/import-multiple`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Array&lt;Supplier&gt;" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(suppliers || {}) : (suppliers || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteSupplier
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupplierUsingDELETE(supplierId: string, options: any = {}): FetchArgs {
            // verify required parameter 'supplierId' is not null or undefined
            if (supplierId === null || supplierId === undefined) {
                throw new RequiredError('supplierId','Required parameter supplierId was null or undefined when calling deleteSupplierUsingDELETE.');
            }
            const localVarPath = `/v1/suppliers/{supplierId}`
                .replace(`{${"supplierId"}}`, encodeURIComponent(String(supplierId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSupplierById
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplierByIdUsingGET(supplierId: string, options: any = {}): FetchArgs {
            // verify required parameter 'supplierId' is not null or undefined
            if (supplierId === null || supplierId === undefined) {
                throw new RequiredError('supplierId','Required parameter supplierId was null or undefined when calling getSupplierByIdUsingGET.');
            }
            const localVarPath = `/v1/suppliers/{supplierId}`
                .replace(`{${"supplierId"}}`, encodeURIComponent(String(supplierId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSuppliersByPartialName
         * @param {number} [page] 
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuppliersByPartialNameUsingGET(page?: number, partialName?: string, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/suppliers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialName !== undefined) {
                localVarQueryParameter['partialName'] = partialName;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary importProductsFromCsv
         * @param {string} productList product-list
         * @param {string} supplierId supplierId
         * @param {boolean} [generateBarcode] generateBarcode
         * @param {boolean} [override] override
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProductsFromCsvUsingPOST(productList: string, supplierId: string, generateBarcode?: boolean, override?: boolean, options: any = {}): FetchArgs {
            // verify required parameter 'productList' is not null or undefined
            if (productList === null || productList === undefined) {
                throw new RequiredError('productList','Required parameter productList was null or undefined when calling importProductsFromCsvUsingPOST.');
            }
            // verify required parameter 'supplierId' is not null or undefined
            if (supplierId === null || supplierId === undefined) {
                throw new RequiredError('supplierId','Required parameter supplierId was null or undefined when calling importProductsFromCsvUsingPOST.');
            }
            const localVarPath = `/v1/suppliers/{supplierId}/products/import`
                .replace(`{${"supplierId"}}`, encodeURIComponent(String(supplierId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (generateBarcode !== undefined) {
                localVarQueryParameter['generateBarcode'] = generateBarcode;
            }

            if (override !== undefined) {
                localVarQueryParameter['override'] = override;
            }

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(productList || {}) : (productList || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary importSuppliersFromCsv
         * @param {string} supplierList supplier-list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importSuppliersFromCsvUsingPOST(supplierList: string, options: any = {}): FetchArgs {
            // verify required parameter 'supplierList' is not null or undefined
            if (supplierList === null || supplierList === undefined) {
                throw new RequiredError('supplierList','Required parameter supplierList was null or undefined when calling importSuppliersFromCsvUsingPOST.');
            }
            const localVarPath = `/v1/suppliers/import`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(supplierList || {}) : (supplierList || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary syncSuppliersWithMaster
         * @param {SupplierSyncDto} supplierSyncDto supplierSyncDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSuppliersWithMasterUsingPATCH(supplierSyncDto: SupplierSyncDto, options: any = {}): FetchArgs {
            // verify required parameter 'supplierSyncDto' is not null or undefined
            if (supplierSyncDto === null || supplierSyncDto === undefined) {
                throw new RequiredError('supplierSyncDto','Required parameter supplierSyncDto was null or undefined when calling syncSuppliersWithMasterUsingPATCH.');
            }
            const localVarPath = `/v1/suppliers/sync`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SupplierSyncDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(supplierSyncDto || {}) : (supplierSyncDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateSupplier
         * @param {Supplier} supplier supplier
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierUsingPUT(supplier: Supplier, supplierId: string, options: any = {}): FetchArgs {
            // verify required parameter 'supplier' is not null or undefined
            if (supplier === null || supplier === undefined) {
                throw new RequiredError('supplier','Required parameter supplier was null or undefined when calling updateSupplierUsingPUT.');
            }
            // verify required parameter 'supplierId' is not null or undefined
            if (supplierId === null || supplierId === undefined) {
                throw new RequiredError('supplierId','Required parameter supplierId was null or undefined when calling updateSupplierUsingPUT.');
            }
            const localVarPath = `/v1/suppliers/{supplierId}`
                .replace(`{${"supplierId"}}`, encodeURIComponent(String(supplierId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"Supplier" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(supplier || {}) : (supplier || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewaySupplierControllerApi - functional programming interface
 * @export
 */
export const GatewaySupplierControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary convertFile
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertFileUsingPOST2(file: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Table> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).convertFileUsingPOST2(file, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary copyProductsFromMaster
         * @param {ProductIdListDto} masterProducts masterProducts
         * @param {string} subSupplierId subSupplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProductsFromMasterUsingPOST(masterProducts: ProductIdListDto, subSupplierId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).copyProductsFromMasterUsingPOST(masterProducts, subSupplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary copySupplierWithProductsFromMaster
         * @param {ProductIdListDto} masterProducts masterProducts
         * @param {string} masterSupplierId masterSupplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copySupplierWithProductsFromMasterUsingPOST(masterProducts: ProductIdListDto, masterSupplierId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).copySupplierWithProductsFromMasterUsingPOST(masterProducts, masterSupplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary copySuppliersWithTheirProductsFromMaster
         * @param {Array<string>} masterSuppliersIds masterSuppliersIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copySuppliersWithTheirProductsFromMasterUsingPOST(masterSuppliersIds: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).copySuppliersWithTheirProductsFromMasterUsingPOST(masterSuppliersIds, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createProduct
         * @param {Product} product product
         * @param {string} supplierId supplierId
         * @param {boolean} [generateBarcode] generateBarcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductUsingPOST(product: Product, supplierId: string, generateBarcode?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Product> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).createProductUsingPOST(product, supplierId, generateBarcode, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createProductsFromJson
         * @param {Array<Product>} products products
         * @param {string} supplierId supplierId
         * @param {boolean} [generateBarcode] generateBarcode
         * @param {boolean} [override] override
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductsFromJsonUsingPOST(products: Array<Product>, supplierId: string, generateBarcode?: boolean, override?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).createProductsFromJsonUsingPOST(products, supplierId, generateBarcode, override, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createSupplier
         * @param {Supplier} supplier supplier
         * @param {boolean} [authenticated] 
         * @param {string} [authorities0Authority] 
         * @param {{ [key: string]: string; }} [credentials] 
         * @param {{ [key: string]: string; }} [details] 
         * @param {{ [key: string]: string; }} [principal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierUsingPOST(supplier: Supplier, authenticated?: boolean, authorities0Authority?: string, credentials?: { [key: string]: string; }, details?: { [key: string]: string; }, principal?: { [key: string]: string; }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Supplier> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).createSupplierUsingPOST(supplier, authenticated, authorities0Authority, credentials, details, principal, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createSuppliers
         * @param {Array<Supplier>} suppliers suppliers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSuppliersUsingPOST(suppliers: Array<Supplier>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).createSuppliersUsingPOST(suppliers, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteSupplier
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupplierUsingDELETE(supplierId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).deleteSupplierUsingDELETE(supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSupplierById
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplierByIdUsingGET(supplierId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Supplier> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).getSupplierByIdUsingGET(supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSuppliersByPartialName
         * @param {number} [page] 
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuppliersByPartialNameUsingGET(page?: number, partialName?: string, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageSupplier> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).getSuppliersByPartialNameUsingGET(page, partialName, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary importProductsFromCsv
         * @param {string} productList product-list
         * @param {string} supplierId supplierId
         * @param {boolean} [generateBarcode] generateBarcode
         * @param {boolean} [override] override
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProductsFromCsvUsingPOST(productList: string, supplierId: string, generateBarcode?: boolean, override?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).importProductsFromCsvUsingPOST(productList, supplierId, generateBarcode, override, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary importSuppliersFromCsv
         * @param {string} supplierList supplier-list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importSuppliersFromCsvUsingPOST(supplierList: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).importSuppliersFromCsvUsingPOST(supplierList, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary syncSuppliersWithMaster
         * @param {SupplierSyncDto} supplierSyncDto supplierSyncDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSuppliersWithMasterUsingPATCH(supplierSyncDto: SupplierSyncDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).syncSuppliersWithMasterUsingPATCH(supplierSyncDto, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateSupplier
         * @param {Supplier} supplier supplier
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierUsingPUT(supplier: Supplier, supplierId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Supplier> {
            const localVarFetchArgs = GatewaySupplierControllerApiFetchParamCreator(configuration).updateSupplierUsingPUT(supplier, supplierId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewaySupplierControllerApi - factory interface
 * @export
 */
export const GatewaySupplierControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary convertFile
         * @param {string} file file
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        convertFileUsingPOST2(file: string, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).convertFileUsingPOST2(file, options)(fetch, basePath);
        },
        /**
         * 
         * @summary copyProductsFromMaster
         * @param {ProductIdListDto} masterProducts masterProducts
         * @param {string} subSupplierId subSupplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyProductsFromMasterUsingPOST(masterProducts: ProductIdListDto, subSupplierId: string, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).copyProductsFromMasterUsingPOST(masterProducts, subSupplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary copySupplierWithProductsFromMaster
         * @param {ProductIdListDto} masterProducts masterProducts
         * @param {string} masterSupplierId masterSupplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copySupplierWithProductsFromMasterUsingPOST(masterProducts: ProductIdListDto, masterSupplierId: string, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).copySupplierWithProductsFromMasterUsingPOST(masterProducts, masterSupplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary copySuppliersWithTheirProductsFromMaster
         * @param {Array<string>} masterSuppliersIds masterSuppliersIds
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copySuppliersWithTheirProductsFromMasterUsingPOST(masterSuppliersIds: Array<string>, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).copySuppliersWithTheirProductsFromMasterUsingPOST(masterSuppliersIds, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createProduct
         * @param {Product} product product
         * @param {string} supplierId supplierId
         * @param {boolean} [generateBarcode] generateBarcode
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductUsingPOST(product: Product, supplierId: string, generateBarcode?: boolean, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).createProductUsingPOST(product, supplierId, generateBarcode, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createProductsFromJson
         * @param {Array<Product>} products products
         * @param {string} supplierId supplierId
         * @param {boolean} [generateBarcode] generateBarcode
         * @param {boolean} [override] override
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProductsFromJsonUsingPOST(products: Array<Product>, supplierId: string, generateBarcode?: boolean, override?: boolean, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).createProductsFromJsonUsingPOST(products, supplierId, generateBarcode, override, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createSupplier
         * @param {Supplier} supplier supplier
         * @param {boolean} [authenticated] 
         * @param {string} [authorities0Authority] 
         * @param {{ [key: string]: string; }} [credentials] 
         * @param {{ [key: string]: string; }} [details] 
         * @param {{ [key: string]: string; }} [principal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierUsingPOST(supplier: Supplier, authenticated?: boolean, authorities0Authority?: string, credentials?: { [key: string]: string; }, details?: { [key: string]: string; }, principal?: { [key: string]: string; }, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).createSupplierUsingPOST(supplier, authenticated, authorities0Authority, credentials, details, principal, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createSuppliers
         * @param {Array<Supplier>} suppliers suppliers
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSuppliersUsingPOST(suppliers: Array<Supplier>, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).createSuppliersUsingPOST(suppliers, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteSupplier
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupplierUsingDELETE(supplierId: string, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).deleteSupplierUsingDELETE(supplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSupplierById
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplierByIdUsingGET(supplierId: string, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).getSupplierByIdUsingGET(supplierId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSuppliersByPartialName
         * @param {number} [page] 
         * @param {string} [partialName] partialName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuppliersByPartialNameUsingGET(page?: number, partialName?: string, size?: number, sort?: string, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).getSuppliersByPartialNameUsingGET(page, partialName, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary importProductsFromCsv
         * @param {string} productList product-list
         * @param {string} supplierId supplierId
         * @param {boolean} [generateBarcode] generateBarcode
         * @param {boolean} [override] override
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importProductsFromCsvUsingPOST(productList: string, supplierId: string, generateBarcode?: boolean, override?: boolean, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).importProductsFromCsvUsingPOST(productList, supplierId, generateBarcode, override, options)(fetch, basePath);
        },
        /**
         * 
         * @summary importSuppliersFromCsv
         * @param {string} supplierList supplier-list
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importSuppliersFromCsvUsingPOST(supplierList: string, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).importSuppliersFromCsvUsingPOST(supplierList, options)(fetch, basePath);
        },
        /**
         * 
         * @summary syncSuppliersWithMaster
         * @param {SupplierSyncDto} supplierSyncDto supplierSyncDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncSuppliersWithMasterUsingPATCH(supplierSyncDto: SupplierSyncDto, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).syncSuppliersWithMasterUsingPATCH(supplierSyncDto, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateSupplier
         * @param {Supplier} supplier supplier
         * @param {string} supplierId supplierId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierUsingPUT(supplier: Supplier, supplierId: string, options?: any) {
            return GatewaySupplierControllerApiFp(configuration).updateSupplierUsingPUT(supplier, supplierId, options)(fetch, basePath);
        },
    };
};

/**
 * GatewaySupplierControllerApi - object-oriented interface
 * @export
 * @class GatewaySupplierControllerApi
 * @extends {BaseAPI}
 */
export class GatewaySupplierControllerApi extends BaseAPI {
    /**
     * 
     * @summary convertFile
     * @param {string} file file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public convertFileUsingPOST2(file: string, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).convertFileUsingPOST2(file, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary copyProductsFromMaster
     * @param {ProductIdListDto} masterProducts masterProducts
     * @param {string} subSupplierId subSupplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public copyProductsFromMasterUsingPOST(masterProducts: ProductIdListDto, subSupplierId: string, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).copyProductsFromMasterUsingPOST(masterProducts, subSupplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary copySupplierWithProductsFromMaster
     * @param {ProductIdListDto} masterProducts masterProducts
     * @param {string} masterSupplierId masterSupplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public copySupplierWithProductsFromMasterUsingPOST(masterProducts: ProductIdListDto, masterSupplierId: string, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).copySupplierWithProductsFromMasterUsingPOST(masterProducts, masterSupplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary copySuppliersWithTheirProductsFromMaster
     * @param {Array<string>} masterSuppliersIds masterSuppliersIds
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public copySuppliersWithTheirProductsFromMasterUsingPOST(masterSuppliersIds: Array<string>, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).copySuppliersWithTheirProductsFromMasterUsingPOST(masterSuppliersIds, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createProduct
     * @param {Product} product product
     * @param {string} supplierId supplierId
     * @param {boolean} [generateBarcode] generateBarcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public createProductUsingPOST(product: Product, supplierId: string, generateBarcode?: boolean, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).createProductUsingPOST(product, supplierId, generateBarcode, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createProductsFromJson
     * @param {Array<Product>} products products
     * @param {string} supplierId supplierId
     * @param {boolean} [generateBarcode] generateBarcode
     * @param {boolean} [override] override
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public createProductsFromJsonUsingPOST(products: Array<Product>, supplierId: string, generateBarcode?: boolean, override?: boolean, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).createProductsFromJsonUsingPOST(products, supplierId, generateBarcode, override, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createSupplier
     * @param {Supplier} supplier supplier
     * @param {boolean} [authenticated] 
     * @param {string} [authorities0Authority] 
     * @param {{ [key: string]: string; }} [credentials] 
     * @param {{ [key: string]: string; }} [details] 
     * @param {{ [key: string]: string; }} [principal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public createSupplierUsingPOST(supplier: Supplier, authenticated?: boolean, authorities0Authority?: string, credentials?: { [key: string]: string; }, details?: { [key: string]: string; }, principal?: { [key: string]: string; }, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).createSupplierUsingPOST(supplier, authenticated, authorities0Authority, credentials, details, principal, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createSuppliers
     * @param {Array<Supplier>} suppliers suppliers
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public createSuppliersUsingPOST(suppliers: Array<Supplier>, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).createSuppliersUsingPOST(suppliers, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteSupplier
     * @param {string} supplierId supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public deleteSupplierUsingDELETE(supplierId: string, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).deleteSupplierUsingDELETE(supplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSupplierById
     * @param {string} supplierId supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public getSupplierByIdUsingGET(supplierId: string, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).getSupplierByIdUsingGET(supplierId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSuppliersByPartialName
     * @param {number} [page] 
     * @param {string} [partialName] partialName
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public getSuppliersByPartialNameUsingGET(page?: number, partialName?: string, size?: number, sort?: string, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).getSuppliersByPartialNameUsingGET(page, partialName, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary importProductsFromCsv
     * @param {string} productList product-list
     * @param {string} supplierId supplierId
     * @param {boolean} [generateBarcode] generateBarcode
     * @param {boolean} [override] override
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public importProductsFromCsvUsingPOST(productList: string, supplierId: string, generateBarcode?: boolean, override?: boolean, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).importProductsFromCsvUsingPOST(productList, supplierId, generateBarcode, override, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary importSuppliersFromCsv
     * @param {string} supplierList supplier-list
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public importSuppliersFromCsvUsingPOST(supplierList: string, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).importSuppliersFromCsvUsingPOST(supplierList, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary syncSuppliersWithMaster
     * @param {SupplierSyncDto} supplierSyncDto supplierSyncDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public syncSuppliersWithMasterUsingPATCH(supplierSyncDto: SupplierSyncDto, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).syncSuppliersWithMasterUsingPATCH(supplierSyncDto, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateSupplier
     * @param {Supplier} supplier supplier
     * @param {string} supplierId supplierId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierControllerApi
     */
    public updateSupplierUsingPUT(supplier: Supplier, supplierId: string, options?: any) {
        return GatewaySupplierControllerApiFp(this.configuration).updateSupplierUsingPUT(supplier, supplierId, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewaySupplierExtractionProfileControllerApi - fetch parameter creator
 * @export
 */
export const GatewaySupplierExtractionProfileControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createSupplierExtractionProfile
         * @param {SupplierExtractionProfile} profile profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierExtractionProfileUsingPOST(profile: SupplierExtractionProfile, options: any = {}): FetchArgs {
            // verify required parameter 'profile' is not null or undefined
            if (profile === null || profile === undefined) {
                throw new RequiredError('profile','Required parameter profile was null or undefined when calling createSupplierExtractionProfileUsingPOST.');
            }
            const localVarPath = `/v1/supplier-extraction-profiles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SupplierExtractionProfile" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(profile || {}) : (profile || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteSupplierExtractionProfile
         * @param {string} profileId profileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupplierExtractionProfileUsingDELETE(profileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'profileId' is not null or undefined
            if (profileId === null || profileId === undefined) {
                throw new RequiredError('profileId','Required parameter profileId was null or undefined when calling deleteSupplierExtractionProfileUsingDELETE.');
            }
            const localVarPath = `/v1/supplier-extraction-profiles/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSupplierExtractionProfileById
         * @param {string} profileId profileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplierExtractionProfileByIdUsingGET(profileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'profileId' is not null or undefined
            if (profileId === null || profileId === undefined) {
                throw new RequiredError('profileId','Required parameter profileId was null or undefined when calling getSupplierExtractionProfileByIdUsingGET.');
            }
            const localVarPath = `/v1/supplier-extraction-profiles/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSuppliersExtractionProfilesByPartialName
         * @param {number} [page] 
         * @param {string} [partialSupplierName] partialSupplierName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuppliersExtractionProfilesByPartialNameUsingGET(page?: number, partialSupplierName?: string, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/supplier-extraction-profiles`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (partialSupplierName !== undefined) {
                localVarQueryParameter['partialSupplierName'] = partialSupplierName;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateSupplierExtractionProfile
         * @param {SupplierExtractionProfile} profile profile
         * @param {string} profileId profileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierExtractionProfileUsingPUT(profile: SupplierExtractionProfile, profileId: string, options: any = {}): FetchArgs {
            // verify required parameter 'profile' is not null or undefined
            if (profile === null || profile === undefined) {
                throw new RequiredError('profile','Required parameter profile was null or undefined when calling updateSupplierExtractionProfileUsingPUT.');
            }
            // verify required parameter 'profileId' is not null or undefined
            if (profileId === null || profileId === undefined) {
                throw new RequiredError('profileId','Required parameter profileId was null or undefined when calling updateSupplierExtractionProfileUsingPUT.');
            }
            const localVarPath = `/v1/supplier-extraction-profiles/{profileId}`
                .replace(`{${"profileId"}}`, encodeURIComponent(String(profileId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"SupplierExtractionProfile" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(profile || {}) : (profile || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewaySupplierExtractionProfileControllerApi - functional programming interface
 * @export
 */
export const GatewaySupplierExtractionProfileControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createSupplierExtractionProfile
         * @param {SupplierExtractionProfile} profile profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierExtractionProfileUsingPOST(profile: SupplierExtractionProfile, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupplierExtractionProfile> {
            const localVarFetchArgs = GatewaySupplierExtractionProfileControllerApiFetchParamCreator(configuration).createSupplierExtractionProfileUsingPOST(profile, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteSupplierExtractionProfile
         * @param {string} profileId profileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupplierExtractionProfileUsingDELETE(profileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewaySupplierExtractionProfileControllerApiFetchParamCreator(configuration).deleteSupplierExtractionProfileUsingDELETE(profileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSupplierExtractionProfileById
         * @param {string} profileId profileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplierExtractionProfileByIdUsingGET(profileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupplierExtractionProfile> {
            const localVarFetchArgs = GatewaySupplierExtractionProfileControllerApiFetchParamCreator(configuration).getSupplierExtractionProfileByIdUsingGET(profileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSuppliersExtractionProfilesByPartialName
         * @param {number} [page] 
         * @param {string} [partialSupplierName] partialSupplierName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuppliersExtractionProfilesByPartialNameUsingGET(page?: number, partialSupplierName?: string, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageSupplierExtractionProfile> {
            const localVarFetchArgs = GatewaySupplierExtractionProfileControllerApiFetchParamCreator(configuration).getSuppliersExtractionProfilesByPartialNameUsingGET(page, partialSupplierName, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateSupplierExtractionProfile
         * @param {SupplierExtractionProfile} profile profile
         * @param {string} profileId profileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierExtractionProfileUsingPUT(profile: SupplierExtractionProfile, profileId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupplierExtractionProfile> {
            const localVarFetchArgs = GatewaySupplierExtractionProfileControllerApiFetchParamCreator(configuration).updateSupplierExtractionProfileUsingPUT(profile, profileId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewaySupplierExtractionProfileControllerApi - factory interface
 * @export
 */
export const GatewaySupplierExtractionProfileControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createSupplierExtractionProfile
         * @param {SupplierExtractionProfile} profile profile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createSupplierExtractionProfileUsingPOST(profile: SupplierExtractionProfile, options?: any) {
            return GatewaySupplierExtractionProfileControllerApiFp(configuration).createSupplierExtractionProfileUsingPOST(profile, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteSupplierExtractionProfile
         * @param {string} profileId profileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteSupplierExtractionProfileUsingDELETE(profileId: string, options?: any) {
            return GatewaySupplierExtractionProfileControllerApiFp(configuration).deleteSupplierExtractionProfileUsingDELETE(profileId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSupplierExtractionProfileById
         * @param {string} profileId profileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupplierExtractionProfileByIdUsingGET(profileId: string, options?: any) {
            return GatewaySupplierExtractionProfileControllerApiFp(configuration).getSupplierExtractionProfileByIdUsingGET(profileId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSuppliersExtractionProfilesByPartialName
         * @param {number} [page] 
         * @param {string} [partialSupplierName] partialSupplierName
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuppliersExtractionProfilesByPartialNameUsingGET(page?: number, partialSupplierName?: string, size?: number, sort?: string, options?: any) {
            return GatewaySupplierExtractionProfileControllerApiFp(configuration).getSuppliersExtractionProfilesByPartialNameUsingGET(page, partialSupplierName, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateSupplierExtractionProfile
         * @param {SupplierExtractionProfile} profile profile
         * @param {string} profileId profileId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateSupplierExtractionProfileUsingPUT(profile: SupplierExtractionProfile, profileId: string, options?: any) {
            return GatewaySupplierExtractionProfileControllerApiFp(configuration).updateSupplierExtractionProfileUsingPUT(profile, profileId, options)(fetch, basePath);
        },
    };
};

/**
 * GatewaySupplierExtractionProfileControllerApi - object-oriented interface
 * @export
 * @class GatewaySupplierExtractionProfileControllerApi
 * @extends {BaseAPI}
 */
export class GatewaySupplierExtractionProfileControllerApi extends BaseAPI {
    /**
     * 
     * @summary createSupplierExtractionProfile
     * @param {SupplierExtractionProfile} profile profile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierExtractionProfileControllerApi
     */
    public createSupplierExtractionProfileUsingPOST(profile: SupplierExtractionProfile, options?: any) {
        return GatewaySupplierExtractionProfileControllerApiFp(this.configuration).createSupplierExtractionProfileUsingPOST(profile, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteSupplierExtractionProfile
     * @param {string} profileId profileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierExtractionProfileControllerApi
     */
    public deleteSupplierExtractionProfileUsingDELETE(profileId: string, options?: any) {
        return GatewaySupplierExtractionProfileControllerApiFp(this.configuration).deleteSupplierExtractionProfileUsingDELETE(profileId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSupplierExtractionProfileById
     * @param {string} profileId profileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierExtractionProfileControllerApi
     */
    public getSupplierExtractionProfileByIdUsingGET(profileId: string, options?: any) {
        return GatewaySupplierExtractionProfileControllerApiFp(this.configuration).getSupplierExtractionProfileByIdUsingGET(profileId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSuppliersExtractionProfilesByPartialName
     * @param {number} [page] 
     * @param {string} [partialSupplierName] partialSupplierName
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierExtractionProfileControllerApi
     */
    public getSuppliersExtractionProfilesByPartialNameUsingGET(page?: number, partialSupplierName?: string, size?: number, sort?: string, options?: any) {
        return GatewaySupplierExtractionProfileControllerApiFp(this.configuration).getSuppliersExtractionProfilesByPartialNameUsingGET(page, partialSupplierName, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateSupplierExtractionProfile
     * @param {SupplierExtractionProfile} profile profile
     * @param {string} profileId profileId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewaySupplierExtractionProfileControllerApi
     */
    public updateSupplierExtractionProfileUsingPUT(profile: SupplierExtractionProfile, profileId: string, options?: any) {
        return GatewaySupplierExtractionProfileControllerApiFp(this.configuration).updateSupplierExtractionProfileUsingPUT(profile, profileId, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayWasteControllerApi - fetch parameter creator
 * @export
 */
export const GatewayWasteControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary constructWasteReport
         * @param {Date} from from
         * @param {string} [barcode] barcode
         * @param {Array<string>} [organizations] organizations
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        constructWasteReportUsingGET(from: Date, barcode?: string, organizations?: Array<string>, to?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling constructWasteReportUsingGET.');
            }
            const localVarPath = `/v1/wastes/report`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (organizations) {
                localVarQueryParameter['organizations'] = organizations;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createWasteDish
         * @param {WasteDishPayload} wasteDishPayload wasteDishPayload
         * @param {string} [wasteGroupId] wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWasteDishUsingPOST(wasteDishPayload: WasteDishPayload, wasteGroupId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'wasteDishPayload' is not null or undefined
            if (wasteDishPayload === null || wasteDishPayload === undefined) {
                throw new RequiredError('wasteDishPayload','Required parameter wasteDishPayload was null or undefined when calling createWasteDishUsingPOST.');
            }
            const localVarPath = `/v1/wastes/dish`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wasteGroupId !== undefined) {
                localVarQueryParameter['wasteGroupId'] = wasteGroupId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WasteDishPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(wasteDishPayload || {}) : (wasteDishPayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createWasteFromSquare
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWasteFromSquareUsingPOST(from: Date, to: Date, options: any = {}): FetchArgs {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling createWasteFromSquareUsingPOST.');
            }
            // verify required parameter 'to' is not null or undefined
            if (to === null || to === undefined) {
                throw new RequiredError('to','Required parameter to was null or undefined when calling createWasteFromSquareUsingPOST.');
            }
            const localVarPath = `/v1/wastes/extract-from-square`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createWasteRecipe
         * @param {WasteRecipePayload} wasteRecipePayload wasteRecipePayload
         * @param {string} [wasteGroupId] wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWasteRecipeUsingPOST(wasteRecipePayload: WasteRecipePayload, wasteGroupId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'wasteRecipePayload' is not null or undefined
            if (wasteRecipePayload === null || wasteRecipePayload === undefined) {
                throw new RequiredError('wasteRecipePayload','Required parameter wasteRecipePayload was null or undefined when calling createWasteRecipeUsingPOST.');
            }
            const localVarPath = `/v1/wastes/recipe`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wasteGroupId !== undefined) {
                localVarQueryParameter['wasteGroupId'] = wasteGroupId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WasteRecipePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(wasteRecipePayload || {}) : (wasteRecipePayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createWaste
         * @param {WastePayload} wastePayload wastePayload
         * @param {string} [wasteGroupId] wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWasteUsingPOST(wastePayload: WastePayload, wasteGroupId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'wastePayload' is not null or undefined
            if (wastePayload === null || wastePayload === undefined) {
                throw new RequiredError('wastePayload','Required parameter wastePayload was null or undefined when calling createWasteUsingPOST.');
            }
            const localVarPath = `/v1/wastes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (wasteGroupId !== undefined) {
                localVarQueryParameter['wasteGroupId'] = wasteGroupId;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WastePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(wastePayload || {}) : (wastePayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteWasteDishRecord
         * @param {string} dishId dishId
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteDishRecordUsingDELETE(dishId: string, wasteGroupId: string, wasteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'dishId' is not null or undefined
            if (dishId === null || dishId === undefined) {
                throw new RequiredError('dishId','Required parameter dishId was null or undefined when calling deleteWasteDishRecordUsingDELETE.');
            }
            // verify required parameter 'wasteGroupId' is not null or undefined
            if (wasteGroupId === null || wasteGroupId === undefined) {
                throw new RequiredError('wasteGroupId','Required parameter wasteGroupId was null or undefined when calling deleteWasteDishRecordUsingDELETE.');
            }
            // verify required parameter 'wasteId' is not null or undefined
            if (wasteId === null || wasteId === undefined) {
                throw new RequiredError('wasteId','Required parameter wasteId was null or undefined when calling deleteWasteDishRecordUsingDELETE.');
            }
            const localVarPath = `/v1/wastes/{wasteGroupId}/dish/{dishId}/waste/{wasteId}`
                .replace(`{${"dishId"}}`, encodeURIComponent(String(dishId)))
                .replace(`{${"wasteGroupId"}}`, encodeURIComponent(String(wasteGroupId)))
                .replace(`{${"wasteId"}}`, encodeURIComponent(String(wasteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteWasteDish
         * @param {string} dishId dishId
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteDishUsingDELETE(dishId: string, wasteGroupId: string, options: any = {}): FetchArgs {
            // verify required parameter 'dishId' is not null or undefined
            if (dishId === null || dishId === undefined) {
                throw new RequiredError('dishId','Required parameter dishId was null or undefined when calling deleteWasteDishUsingDELETE.');
            }
            // verify required parameter 'wasteGroupId' is not null or undefined
            if (wasteGroupId === null || wasteGroupId === undefined) {
                throw new RequiredError('wasteGroupId','Required parameter wasteGroupId was null or undefined when calling deleteWasteDishUsingDELETE.');
            }
            const localVarPath = `/v1/wastes/{wasteGroupId}/dish/{dishId}`
                .replace(`{${"dishId"}}`, encodeURIComponent(String(dishId)))
                .replace(`{${"wasteGroupId"}}`, encodeURIComponent(String(wasteGroupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteWasteGroupById
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteGroupByIdUsingDELETE(wasteGroupId: string, options: any = {}): FetchArgs {
            // verify required parameter 'wasteGroupId' is not null or undefined
            if (wasteGroupId === null || wasteGroupId === undefined) {
                throw new RequiredError('wasteGroupId','Required parameter wasteGroupId was null or undefined when calling deleteWasteGroupByIdUsingDELETE.');
            }
            const localVarPath = `/v1/wastes/{wasteGroupId}`
                .replace(`{${"wasteGroupId"}}`, encodeURIComponent(String(wasteGroupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteWasteProduct
         * @param {string} barcode barcode
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteProductUsingDELETE(barcode: string, wasteGroupId: string, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling deleteWasteProductUsingDELETE.');
            }
            // verify required parameter 'wasteGroupId' is not null or undefined
            if (wasteGroupId === null || wasteGroupId === undefined) {
                throw new RequiredError('wasteGroupId','Required parameter wasteGroupId was null or undefined when calling deleteWasteProductUsingDELETE.');
            }
            const localVarPath = `/v1/wastes/{wasteGroupId}/products/{barcode}`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)))
                .replace(`{${"wasteGroupId"}}`, encodeURIComponent(String(wasteGroupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteWasteRecipeRecord
         * @param {string} recipeId recipeId
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteRecipeRecordUsingDELETE(recipeId: string, wasteGroupId: string, wasteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'recipeId' is not null or undefined
            if (recipeId === null || recipeId === undefined) {
                throw new RequiredError('recipeId','Required parameter recipeId was null or undefined when calling deleteWasteRecipeRecordUsingDELETE.');
            }
            // verify required parameter 'wasteGroupId' is not null or undefined
            if (wasteGroupId === null || wasteGroupId === undefined) {
                throw new RequiredError('wasteGroupId','Required parameter wasteGroupId was null or undefined when calling deleteWasteRecipeRecordUsingDELETE.');
            }
            // verify required parameter 'wasteId' is not null or undefined
            if (wasteId === null || wasteId === undefined) {
                throw new RequiredError('wasteId','Required parameter wasteId was null or undefined when calling deleteWasteRecipeRecordUsingDELETE.');
            }
            const localVarPath = `/v1/wastes/{wasteGroupId}/recipe/{recipeId}/waste/{wasteId}`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)))
                .replace(`{${"wasteGroupId"}}`, encodeURIComponent(String(wasteGroupId)))
                .replace(`{${"wasteId"}}`, encodeURIComponent(String(wasteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteWasteRecipe
         * @param {string} recipeId recipeId
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteRecipeUsingDELETE(recipeId: string, wasteGroupId: string, options: any = {}): FetchArgs {
            // verify required parameter 'recipeId' is not null or undefined
            if (recipeId === null || recipeId === undefined) {
                throw new RequiredError('recipeId','Required parameter recipeId was null or undefined when calling deleteWasteRecipeUsingDELETE.');
            }
            // verify required parameter 'wasteGroupId' is not null or undefined
            if (wasteGroupId === null || wasteGroupId === undefined) {
                throw new RequiredError('wasteGroupId','Required parameter wasteGroupId was null or undefined when calling deleteWasteRecipeUsingDELETE.');
            }
            const localVarPath = `/v1/wastes/{wasteGroupId}/recipe/{recipeId}`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)))
                .replace(`{${"wasteGroupId"}}`, encodeURIComponent(String(wasteGroupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteWaste
         * @param {string} barcode barcode
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteUsingDELETE(barcode: string, wasteGroupId: string, wasteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling deleteWasteUsingDELETE.');
            }
            // verify required parameter 'wasteGroupId' is not null or undefined
            if (wasteGroupId === null || wasteGroupId === undefined) {
                throw new RequiredError('wasteGroupId','Required parameter wasteGroupId was null or undefined when calling deleteWasteUsingDELETE.');
            }
            // verify required parameter 'wasteId' is not null or undefined
            if (wasteId === null || wasteId === undefined) {
                throw new RequiredError('wasteId','Required parameter wasteId was null or undefined when calling deleteWasteUsingDELETE.');
            }
            const localVarPath = `/v1/wastes/{wasteGroupId}/products/{barcode}/waste/{wasteId}`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)))
                .replace(`{${"wasteGroupId"}}`, encodeURIComponent(String(wasteGroupId)))
                .replace(`{${"wasteId"}}`, encodeURIComponent(String(wasteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary exportWasteReportAsCSV
         * @param {Date} from from
         * @param {string} [barcode] barcode
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportWasteReportAsCSVUsingGET(from: Date, barcode?: string, to?: Date, options: any = {}): FetchArgs {
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError('from','Required parameter from was null or undefined when calling exportWasteReportAsCSVUsingGET.');
            }
            const localVarPath = `/v1/wastes/report/export-as-csv`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getWasteGroupById
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWasteGroupByIdUsingGET(wasteGroupId: string, options: any = {}): FetchArgs {
            // verify required parameter 'wasteGroupId' is not null or undefined
            if (wasteGroupId === null || wasteGroupId === undefined) {
                throw new RequiredError('wasteGroupId','Required parameter wasteGroupId was null or undefined when calling getWasteGroupByIdUsingGET.');
            }
            const localVarPath = `/v1/wastes/{wasteGroupId}`
                .replace(`{${"wasteGroupId"}}`, encodeURIComponent(String(wasteGroupId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getWasteGroupForCurrentDay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWasteGroupForCurrentDayUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/wastes/current-day`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getWasteGroupsByCriteria
         * @param {string} [barcode] barcode
         * @param {string} [category] category
         * @param {Date} [from] from
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [subCategory] subCategory
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWasteGroupsByCriteriaUsingGET(barcode?: string, category?: string, from?: Date, organizations?: Array<string>, page?: number, size?: number, sort?: string, subCategory?: string, to?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/v1/wastes`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (barcode !== undefined) {
                localVarQueryParameter['barcode'] = barcode;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (organizations) {
                localVarQueryParameter['organizations'] = organizations;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (subCategory !== undefined) {
                localVarQueryParameter['subCategory'] = subCategory;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateWasteDishRecordFiles
         * @param {string} dishId dishId
         * @param {WasteFilesDto} wasteFilesDto wasteFilesDto
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteDishRecordFilesUsingPOST(dishId: string, wasteFilesDto: WasteFilesDto, wasteGroupId: string, wasteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'dishId' is not null or undefined
            if (dishId === null || dishId === undefined) {
                throw new RequiredError('dishId','Required parameter dishId was null or undefined when calling updateWasteDishRecordFilesUsingPOST.');
            }
            // verify required parameter 'wasteFilesDto' is not null or undefined
            if (wasteFilesDto === null || wasteFilesDto === undefined) {
                throw new RequiredError('wasteFilesDto','Required parameter wasteFilesDto was null or undefined when calling updateWasteDishRecordFilesUsingPOST.');
            }
            // verify required parameter 'wasteGroupId' is not null or undefined
            if (wasteGroupId === null || wasteGroupId === undefined) {
                throw new RequiredError('wasteGroupId','Required parameter wasteGroupId was null or undefined when calling updateWasteDishRecordFilesUsingPOST.');
            }
            // verify required parameter 'wasteId' is not null or undefined
            if (wasteId === null || wasteId === undefined) {
                throw new RequiredError('wasteId','Required parameter wasteId was null or undefined when calling updateWasteDishRecordFilesUsingPOST.');
            }
            const localVarPath = `/v1/wastes/{wasteGroupId}/dish/{dishId}/waste/{wasteId}/files`
                .replace(`{${"dishId"}}`, encodeURIComponent(String(dishId)))
                .replace(`{${"wasteGroupId"}}`, encodeURIComponent(String(wasteGroupId)))
                .replace(`{${"wasteId"}}`, encodeURIComponent(String(wasteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WasteFilesDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(wasteFilesDto || {}) : (wasteFilesDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateWasteDishRecord
         * @param {string} dishId dishId
         * @param {WasteElementPayloadUpdate} wasteDishPayload wasteDishPayload
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteDishRecordUsingPUT(dishId: string, wasteDishPayload: WasteElementPayloadUpdate, wasteGroupId: string, wasteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'dishId' is not null or undefined
            if (dishId === null || dishId === undefined) {
                throw new RequiredError('dishId','Required parameter dishId was null or undefined when calling updateWasteDishRecordUsingPUT.');
            }
            // verify required parameter 'wasteDishPayload' is not null or undefined
            if (wasteDishPayload === null || wasteDishPayload === undefined) {
                throw new RequiredError('wasteDishPayload','Required parameter wasteDishPayload was null or undefined when calling updateWasteDishRecordUsingPUT.');
            }
            // verify required parameter 'wasteGroupId' is not null or undefined
            if (wasteGroupId === null || wasteGroupId === undefined) {
                throw new RequiredError('wasteGroupId','Required parameter wasteGroupId was null or undefined when calling updateWasteDishRecordUsingPUT.');
            }
            // verify required parameter 'wasteId' is not null or undefined
            if (wasteId === null || wasteId === undefined) {
                throw new RequiredError('wasteId','Required parameter wasteId was null or undefined when calling updateWasteDishRecordUsingPUT.');
            }
            const localVarPath = `/v1/wastes/{wasteGroupId}/dish/{dishId}/waste/{wasteId}`
                .replace(`{${"dishId"}}`, encodeURIComponent(String(dishId)))
                .replace(`{${"wasteGroupId"}}`, encodeURIComponent(String(wasteGroupId)))
                .replace(`{${"wasteId"}}`, encodeURIComponent(String(wasteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WasteElementPayloadUpdate" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(wasteDishPayload || {}) : (wasteDishPayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateWasteFiles
         * @param {string} barcode barcode
         * @param {WasteFilesDto} wasteFilesDto wasteFilesDto
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteFilesUsingPOST(barcode: string, wasteFilesDto: WasteFilesDto, wasteGroupId: string, wasteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling updateWasteFilesUsingPOST.');
            }
            // verify required parameter 'wasteFilesDto' is not null or undefined
            if (wasteFilesDto === null || wasteFilesDto === undefined) {
                throw new RequiredError('wasteFilesDto','Required parameter wasteFilesDto was null or undefined when calling updateWasteFilesUsingPOST.');
            }
            // verify required parameter 'wasteGroupId' is not null or undefined
            if (wasteGroupId === null || wasteGroupId === undefined) {
                throw new RequiredError('wasteGroupId','Required parameter wasteGroupId was null or undefined when calling updateWasteFilesUsingPOST.');
            }
            // verify required parameter 'wasteId' is not null or undefined
            if (wasteId === null || wasteId === undefined) {
                throw new RequiredError('wasteId','Required parameter wasteId was null or undefined when calling updateWasteFilesUsingPOST.');
            }
            const localVarPath = `/v1/wastes/{wasteGroupId}/products/{barcode}/waste/{wasteId}/files`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)))
                .replace(`{${"wasteGroupId"}}`, encodeURIComponent(String(wasteGroupId)))
                .replace(`{${"wasteId"}}`, encodeURIComponent(String(wasteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WasteFilesDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(wasteFilesDto || {}) : (wasteFilesDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateWasteRecipeRecordFiles
         * @param {string} recipeId recipeId
         * @param {WasteFilesDto} wasteFilesDto wasteFilesDto
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteRecipeRecordFilesUsingPOST(recipeId: string, wasteFilesDto: WasteFilesDto, wasteGroupId: string, wasteId: string, options: any = {}): FetchArgs {
            // verify required parameter 'recipeId' is not null or undefined
            if (recipeId === null || recipeId === undefined) {
                throw new RequiredError('recipeId','Required parameter recipeId was null or undefined when calling updateWasteRecipeRecordFilesUsingPOST.');
            }
            // verify required parameter 'wasteFilesDto' is not null or undefined
            if (wasteFilesDto === null || wasteFilesDto === undefined) {
                throw new RequiredError('wasteFilesDto','Required parameter wasteFilesDto was null or undefined when calling updateWasteRecipeRecordFilesUsingPOST.');
            }
            // verify required parameter 'wasteGroupId' is not null or undefined
            if (wasteGroupId === null || wasteGroupId === undefined) {
                throw new RequiredError('wasteGroupId','Required parameter wasteGroupId was null or undefined when calling updateWasteRecipeRecordFilesUsingPOST.');
            }
            // verify required parameter 'wasteId' is not null or undefined
            if (wasteId === null || wasteId === undefined) {
                throw new RequiredError('wasteId','Required parameter wasteId was null or undefined when calling updateWasteRecipeRecordFilesUsingPOST.');
            }
            const localVarPath = `/v1/wastes/{wasteGroupId}/recipe/{recipeId}/waste/{wasteId}/files`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)))
                .replace(`{${"wasteGroupId"}}`, encodeURIComponent(String(wasteGroupId)))
                .replace(`{${"wasteId"}}`, encodeURIComponent(String(wasteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WasteFilesDto" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(wasteFilesDto || {}) : (wasteFilesDto || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateWasteRecipeRecord
         * @param {string} recipeId recipeId
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {WasteElementPayloadUpdate} wasteRecipePayload wasteRecipePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteRecipeRecordUsingPUT(recipeId: string, wasteGroupId: string, wasteId: string, wasteRecipePayload: WasteElementPayloadUpdate, options: any = {}): FetchArgs {
            // verify required parameter 'recipeId' is not null or undefined
            if (recipeId === null || recipeId === undefined) {
                throw new RequiredError('recipeId','Required parameter recipeId was null or undefined when calling updateWasteRecipeRecordUsingPUT.');
            }
            // verify required parameter 'wasteGroupId' is not null or undefined
            if (wasteGroupId === null || wasteGroupId === undefined) {
                throw new RequiredError('wasteGroupId','Required parameter wasteGroupId was null or undefined when calling updateWasteRecipeRecordUsingPUT.');
            }
            // verify required parameter 'wasteId' is not null or undefined
            if (wasteId === null || wasteId === undefined) {
                throw new RequiredError('wasteId','Required parameter wasteId was null or undefined when calling updateWasteRecipeRecordUsingPUT.');
            }
            // verify required parameter 'wasteRecipePayload' is not null or undefined
            if (wasteRecipePayload === null || wasteRecipePayload === undefined) {
                throw new RequiredError('wasteRecipePayload','Required parameter wasteRecipePayload was null or undefined when calling updateWasteRecipeRecordUsingPUT.');
            }
            const localVarPath = `/v1/wastes/{wasteGroupId}/recipe/{recipeId}/waste/{wasteId}`
                .replace(`{${"recipeId"}}`, encodeURIComponent(String(recipeId)))
                .replace(`{${"wasteGroupId"}}`, encodeURIComponent(String(wasteGroupId)))
                .replace(`{${"wasteId"}}`, encodeURIComponent(String(wasteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WasteElementPayloadUpdate" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(wasteRecipePayload || {}) : (wasteRecipePayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateWaste
         * @param {string} barcode barcode
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {WastePayloadUpdate} wastePayload wastePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteUsingPUT(barcode: string, wasteGroupId: string, wasteId: string, wastePayload: WastePayloadUpdate, options: any = {}): FetchArgs {
            // verify required parameter 'barcode' is not null or undefined
            if (barcode === null || barcode === undefined) {
                throw new RequiredError('barcode','Required parameter barcode was null or undefined when calling updateWasteUsingPUT.');
            }
            // verify required parameter 'wasteGroupId' is not null or undefined
            if (wasteGroupId === null || wasteGroupId === undefined) {
                throw new RequiredError('wasteGroupId','Required parameter wasteGroupId was null or undefined when calling updateWasteUsingPUT.');
            }
            // verify required parameter 'wasteId' is not null or undefined
            if (wasteId === null || wasteId === undefined) {
                throw new RequiredError('wasteId','Required parameter wasteId was null or undefined when calling updateWasteUsingPUT.');
            }
            // verify required parameter 'wastePayload' is not null or undefined
            if (wastePayload === null || wastePayload === undefined) {
                throw new RequiredError('wastePayload','Required parameter wastePayload was null or undefined when calling updateWasteUsingPUT.');
            }
            const localVarPath = `/v1/wastes/{wasteGroupId}/products/{barcode}/waste/{wasteId}`
                .replace(`{${"barcode"}}`, encodeURIComponent(String(barcode)))
                .replace(`{${"wasteGroupId"}}`, encodeURIComponent(String(wasteGroupId)))
                .replace(`{${"wasteId"}}`, encodeURIComponent(String(wasteId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WastePayloadUpdate" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(wastePayload || {}) : (wastePayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayWasteControllerApi - functional programming interface
 * @export
 */
export const GatewayWasteControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary constructWasteReport
         * @param {Date} from from
         * @param {string} [barcode] barcode
         * @param {Array<string>} [organizations] organizations
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        constructWasteReportUsingGET(from: Date, barcode?: string, organizations?: Array<string>, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WasteReport> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).constructWasteReportUsingGET(from, barcode, organizations, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createWasteDish
         * @param {WasteDishPayload} wasteDishPayload wasteDishPayload
         * @param {string} [wasteGroupId] wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWasteDishUsingPOST(wasteDishPayload: WasteDishPayload, wasteGroupId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WasteGroup> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).createWasteDishUsingPOST(wasteDishPayload, wasteGroupId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createWasteFromSquare
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWasteFromSquareUsingPOST(from: Date, to: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).createWasteFromSquareUsingPOST(from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createWasteRecipe
         * @param {WasteRecipePayload} wasteRecipePayload wasteRecipePayload
         * @param {string} [wasteGroupId] wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWasteRecipeUsingPOST(wasteRecipePayload: WasteRecipePayload, wasteGroupId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WasteGroup> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).createWasteRecipeUsingPOST(wasteRecipePayload, wasteGroupId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createWaste
         * @param {WastePayload} wastePayload wastePayload
         * @param {string} [wasteGroupId] wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWasteUsingPOST(wastePayload: WastePayload, wasteGroupId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WasteGroup> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).createWasteUsingPOST(wastePayload, wasteGroupId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteWasteDishRecord
         * @param {string} dishId dishId
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteDishRecordUsingDELETE(dishId: string, wasteGroupId: string, wasteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).deleteWasteDishRecordUsingDELETE(dishId, wasteGroupId, wasteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteWasteDish
         * @param {string} dishId dishId
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteDishUsingDELETE(dishId: string, wasteGroupId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).deleteWasteDishUsingDELETE(dishId, wasteGroupId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteWasteGroupById
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteGroupByIdUsingDELETE(wasteGroupId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).deleteWasteGroupByIdUsingDELETE(wasteGroupId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteWasteProduct
         * @param {string} barcode barcode
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteProductUsingDELETE(barcode: string, wasteGroupId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).deleteWasteProductUsingDELETE(barcode, wasteGroupId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteWasteRecipeRecord
         * @param {string} recipeId recipeId
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteRecipeRecordUsingDELETE(recipeId: string, wasteGroupId: string, wasteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).deleteWasteRecipeRecordUsingDELETE(recipeId, wasteGroupId, wasteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteWasteRecipe
         * @param {string} recipeId recipeId
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteRecipeUsingDELETE(recipeId: string, wasteGroupId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).deleteWasteRecipeUsingDELETE(recipeId, wasteGroupId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteWaste
         * @param {string} barcode barcode
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteUsingDELETE(barcode: string, wasteGroupId: string, wasteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).deleteWasteUsingDELETE(barcode, wasteGroupId, wasteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary exportWasteReportAsCSV
         * @param {Date} from from
         * @param {string} [barcode] barcode
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportWasteReportAsCSVUsingGET(from: Date, barcode?: string, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).exportWasteReportAsCSVUsingGET(from, barcode, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getWasteGroupById
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWasteGroupByIdUsingGET(wasteGroupId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WasteGroup> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).getWasteGroupByIdUsingGET(wasteGroupId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getWasteGroupForCurrentDay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWasteGroupForCurrentDayUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WasteGroup> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).getWasteGroupForCurrentDayUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getWasteGroupsByCriteria
         * @param {string} [barcode] barcode
         * @param {string} [category] category
         * @param {Date} [from] from
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [subCategory] subCategory
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWasteGroupsByCriteriaUsingGET(barcode?: string, category?: string, from?: Date, organizations?: Array<string>, page?: number, size?: number, sort?: string, subCategory?: string, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageWasteGroupWithOrgsNames> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).getWasteGroupsByCriteriaUsingGET(barcode, category, from, organizations, page, size, sort, subCategory, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateWasteDishRecordFiles
         * @param {string} dishId dishId
         * @param {WasteFilesDto} wasteFilesDto wasteFilesDto
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteDishRecordFilesUsingPOST(dishId: string, wasteFilesDto: WasteFilesDto, wasteGroupId: string, wasteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WasteGroup> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).updateWasteDishRecordFilesUsingPOST(dishId, wasteFilesDto, wasteGroupId, wasteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateWasteDishRecord
         * @param {string} dishId dishId
         * @param {WasteElementPayloadUpdate} wasteDishPayload wasteDishPayload
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteDishRecordUsingPUT(dishId: string, wasteDishPayload: WasteElementPayloadUpdate, wasteGroupId: string, wasteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WasteGroup> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).updateWasteDishRecordUsingPUT(dishId, wasteDishPayload, wasteGroupId, wasteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateWasteFiles
         * @param {string} barcode barcode
         * @param {WasteFilesDto} wasteFilesDto wasteFilesDto
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteFilesUsingPOST(barcode: string, wasteFilesDto: WasteFilesDto, wasteGroupId: string, wasteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WasteGroup> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).updateWasteFilesUsingPOST(barcode, wasteFilesDto, wasteGroupId, wasteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateWasteRecipeRecordFiles
         * @param {string} recipeId recipeId
         * @param {WasteFilesDto} wasteFilesDto wasteFilesDto
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteRecipeRecordFilesUsingPOST(recipeId: string, wasteFilesDto: WasteFilesDto, wasteGroupId: string, wasteId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WasteGroup> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).updateWasteRecipeRecordFilesUsingPOST(recipeId, wasteFilesDto, wasteGroupId, wasteId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateWasteRecipeRecord
         * @param {string} recipeId recipeId
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {WasteElementPayloadUpdate} wasteRecipePayload wasteRecipePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteRecipeRecordUsingPUT(recipeId: string, wasteGroupId: string, wasteId: string, wasteRecipePayload: WasteElementPayloadUpdate, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WasteGroup> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).updateWasteRecipeRecordUsingPUT(recipeId, wasteGroupId, wasteId, wasteRecipePayload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateWaste
         * @param {string} barcode barcode
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {WastePayloadUpdate} wastePayload wastePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteUsingPUT(barcode: string, wasteGroupId: string, wasteId: string, wastePayload: WastePayloadUpdate, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WasteGroup> {
            const localVarFetchArgs = GatewayWasteControllerApiFetchParamCreator(configuration).updateWasteUsingPUT(barcode, wasteGroupId, wasteId, wastePayload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayWasteControllerApi - factory interface
 * @export
 */
export const GatewayWasteControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary constructWasteReport
         * @param {Date} from from
         * @param {string} [barcode] barcode
         * @param {Array<string>} [organizations] organizations
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        constructWasteReportUsingGET(from: Date, barcode?: string, organizations?: Array<string>, to?: Date, options?: any) {
            return GatewayWasteControllerApiFp(configuration).constructWasteReportUsingGET(from, barcode, organizations, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createWasteDish
         * @param {WasteDishPayload} wasteDishPayload wasteDishPayload
         * @param {string} [wasteGroupId] wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWasteDishUsingPOST(wasteDishPayload: WasteDishPayload, wasteGroupId?: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).createWasteDishUsingPOST(wasteDishPayload, wasteGroupId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createWasteFromSquare
         * @param {Date} from from
         * @param {Date} to to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWasteFromSquareUsingPOST(from: Date, to: Date, options?: any) {
            return GatewayWasteControllerApiFp(configuration).createWasteFromSquareUsingPOST(from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createWasteRecipe
         * @param {WasteRecipePayload} wasteRecipePayload wasteRecipePayload
         * @param {string} [wasteGroupId] wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWasteRecipeUsingPOST(wasteRecipePayload: WasteRecipePayload, wasteGroupId?: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).createWasteRecipeUsingPOST(wasteRecipePayload, wasteGroupId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary createWaste
         * @param {WastePayload} wastePayload wastePayload
         * @param {string} [wasteGroupId] wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWasteUsingPOST(wastePayload: WastePayload, wasteGroupId?: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).createWasteUsingPOST(wastePayload, wasteGroupId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteWasteDishRecord
         * @param {string} dishId dishId
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteDishRecordUsingDELETE(dishId: string, wasteGroupId: string, wasteId: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).deleteWasteDishRecordUsingDELETE(dishId, wasteGroupId, wasteId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteWasteDish
         * @param {string} dishId dishId
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteDishUsingDELETE(dishId: string, wasteGroupId: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).deleteWasteDishUsingDELETE(dishId, wasteGroupId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteWasteGroupById
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteGroupByIdUsingDELETE(wasteGroupId: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).deleteWasteGroupByIdUsingDELETE(wasteGroupId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteWasteProduct
         * @param {string} barcode barcode
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteProductUsingDELETE(barcode: string, wasteGroupId: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).deleteWasteProductUsingDELETE(barcode, wasteGroupId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteWasteRecipeRecord
         * @param {string} recipeId recipeId
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteRecipeRecordUsingDELETE(recipeId: string, wasteGroupId: string, wasteId: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).deleteWasteRecipeRecordUsingDELETE(recipeId, wasteGroupId, wasteId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteWasteRecipe
         * @param {string} recipeId recipeId
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteRecipeUsingDELETE(recipeId: string, wasteGroupId: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).deleteWasteRecipeUsingDELETE(recipeId, wasteGroupId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteWaste
         * @param {string} barcode barcode
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWasteUsingDELETE(barcode: string, wasteGroupId: string, wasteId: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).deleteWasteUsingDELETE(barcode, wasteGroupId, wasteId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary exportWasteReportAsCSV
         * @param {Date} from from
         * @param {string} [barcode] barcode
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportWasteReportAsCSVUsingGET(from: Date, barcode?: string, to?: Date, options?: any) {
            return GatewayWasteControllerApiFp(configuration).exportWasteReportAsCSVUsingGET(from, barcode, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getWasteGroupById
         * @param {string} wasteGroupId wasteGroupId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWasteGroupByIdUsingGET(wasteGroupId: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).getWasteGroupByIdUsingGET(wasteGroupId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getWasteGroupForCurrentDay
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWasteGroupForCurrentDayUsingGET(options?: any) {
            return GatewayWasteControllerApiFp(configuration).getWasteGroupForCurrentDayUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getWasteGroupsByCriteria
         * @param {string} [barcode] barcode
         * @param {string} [category] category
         * @param {Date} [from] from
         * @param {Array<string>} [organizations] organizations
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {string} [subCategory] subCategory
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWasteGroupsByCriteriaUsingGET(barcode?: string, category?: string, from?: Date, organizations?: Array<string>, page?: number, size?: number, sort?: string, subCategory?: string, to?: Date, options?: any) {
            return GatewayWasteControllerApiFp(configuration).getWasteGroupsByCriteriaUsingGET(barcode, category, from, organizations, page, size, sort, subCategory, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateWasteDishRecordFiles
         * @param {string} dishId dishId
         * @param {WasteFilesDto} wasteFilesDto wasteFilesDto
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteDishRecordFilesUsingPOST(dishId: string, wasteFilesDto: WasteFilesDto, wasteGroupId: string, wasteId: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).updateWasteDishRecordFilesUsingPOST(dishId, wasteFilesDto, wasteGroupId, wasteId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateWasteDishRecord
         * @param {string} dishId dishId
         * @param {WasteElementPayloadUpdate} wasteDishPayload wasteDishPayload
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteDishRecordUsingPUT(dishId: string, wasteDishPayload: WasteElementPayloadUpdate, wasteGroupId: string, wasteId: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).updateWasteDishRecordUsingPUT(dishId, wasteDishPayload, wasteGroupId, wasteId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateWasteFiles
         * @param {string} barcode barcode
         * @param {WasteFilesDto} wasteFilesDto wasteFilesDto
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteFilesUsingPOST(barcode: string, wasteFilesDto: WasteFilesDto, wasteGroupId: string, wasteId: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).updateWasteFilesUsingPOST(barcode, wasteFilesDto, wasteGroupId, wasteId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateWasteRecipeRecordFiles
         * @param {string} recipeId recipeId
         * @param {WasteFilesDto} wasteFilesDto wasteFilesDto
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteRecipeRecordFilesUsingPOST(recipeId: string, wasteFilesDto: WasteFilesDto, wasteGroupId: string, wasteId: string, options?: any) {
            return GatewayWasteControllerApiFp(configuration).updateWasteRecipeRecordFilesUsingPOST(recipeId, wasteFilesDto, wasteGroupId, wasteId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateWasteRecipeRecord
         * @param {string} recipeId recipeId
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {WasteElementPayloadUpdate} wasteRecipePayload wasteRecipePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteRecipeRecordUsingPUT(recipeId: string, wasteGroupId: string, wasteId: string, wasteRecipePayload: WasteElementPayloadUpdate, options?: any) {
            return GatewayWasteControllerApiFp(configuration).updateWasteRecipeRecordUsingPUT(recipeId, wasteGroupId, wasteId, wasteRecipePayload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateWaste
         * @param {string} barcode barcode
         * @param {string} wasteGroupId wasteGroupId
         * @param {string} wasteId wasteId
         * @param {WastePayloadUpdate} wastePayload wastePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateWasteUsingPUT(barcode: string, wasteGroupId: string, wasteId: string, wastePayload: WastePayloadUpdate, options?: any) {
            return GatewayWasteControllerApiFp(configuration).updateWasteUsingPUT(barcode, wasteGroupId, wasteId, wastePayload, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayWasteControllerApi - object-oriented interface
 * @export
 * @class GatewayWasteControllerApi
 * @extends {BaseAPI}
 */
export class GatewayWasteControllerApi extends BaseAPI {
    /**
     * 
     * @summary constructWasteReport
     * @param {Date} from from
     * @param {string} [barcode] barcode
     * @param {Array<string>} [organizations] organizations
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public constructWasteReportUsingGET(from: Date, barcode?: string, organizations?: Array<string>, to?: Date, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).constructWasteReportUsingGET(from, barcode, organizations, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createWasteDish
     * @param {WasteDishPayload} wasteDishPayload wasteDishPayload
     * @param {string} [wasteGroupId] wasteGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public createWasteDishUsingPOST(wasteDishPayload: WasteDishPayload, wasteGroupId?: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).createWasteDishUsingPOST(wasteDishPayload, wasteGroupId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createWasteFromSquare
     * @param {Date} from from
     * @param {Date} to to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public createWasteFromSquareUsingPOST(from: Date, to: Date, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).createWasteFromSquareUsingPOST(from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createWasteRecipe
     * @param {WasteRecipePayload} wasteRecipePayload wasteRecipePayload
     * @param {string} [wasteGroupId] wasteGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public createWasteRecipeUsingPOST(wasteRecipePayload: WasteRecipePayload, wasteGroupId?: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).createWasteRecipeUsingPOST(wasteRecipePayload, wasteGroupId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createWaste
     * @param {WastePayload} wastePayload wastePayload
     * @param {string} [wasteGroupId] wasteGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public createWasteUsingPOST(wastePayload: WastePayload, wasteGroupId?: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).createWasteUsingPOST(wastePayload, wasteGroupId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteWasteDishRecord
     * @param {string} dishId dishId
     * @param {string} wasteGroupId wasteGroupId
     * @param {string} wasteId wasteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public deleteWasteDishRecordUsingDELETE(dishId: string, wasteGroupId: string, wasteId: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).deleteWasteDishRecordUsingDELETE(dishId, wasteGroupId, wasteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteWasteDish
     * @param {string} dishId dishId
     * @param {string} wasteGroupId wasteGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public deleteWasteDishUsingDELETE(dishId: string, wasteGroupId: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).deleteWasteDishUsingDELETE(dishId, wasteGroupId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteWasteGroupById
     * @param {string} wasteGroupId wasteGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public deleteWasteGroupByIdUsingDELETE(wasteGroupId: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).deleteWasteGroupByIdUsingDELETE(wasteGroupId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteWasteProduct
     * @param {string} barcode barcode
     * @param {string} wasteGroupId wasteGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public deleteWasteProductUsingDELETE(barcode: string, wasteGroupId: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).deleteWasteProductUsingDELETE(barcode, wasteGroupId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteWasteRecipeRecord
     * @param {string} recipeId recipeId
     * @param {string} wasteGroupId wasteGroupId
     * @param {string} wasteId wasteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public deleteWasteRecipeRecordUsingDELETE(recipeId: string, wasteGroupId: string, wasteId: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).deleteWasteRecipeRecordUsingDELETE(recipeId, wasteGroupId, wasteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteWasteRecipe
     * @param {string} recipeId recipeId
     * @param {string} wasteGroupId wasteGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public deleteWasteRecipeUsingDELETE(recipeId: string, wasteGroupId: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).deleteWasteRecipeUsingDELETE(recipeId, wasteGroupId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteWaste
     * @param {string} barcode barcode
     * @param {string} wasteGroupId wasteGroupId
     * @param {string} wasteId wasteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public deleteWasteUsingDELETE(barcode: string, wasteGroupId: string, wasteId: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).deleteWasteUsingDELETE(barcode, wasteGroupId, wasteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary exportWasteReportAsCSV
     * @param {Date} from from
     * @param {string} [barcode] barcode
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public exportWasteReportAsCSVUsingGET(from: Date, barcode?: string, to?: Date, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).exportWasteReportAsCSVUsingGET(from, barcode, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getWasteGroupById
     * @param {string} wasteGroupId wasteGroupId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public getWasteGroupByIdUsingGET(wasteGroupId: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).getWasteGroupByIdUsingGET(wasteGroupId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getWasteGroupForCurrentDay
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public getWasteGroupForCurrentDayUsingGET(options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).getWasteGroupForCurrentDayUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getWasteGroupsByCriteria
     * @param {string} [barcode] barcode
     * @param {string} [category] category
     * @param {Date} [from] from
     * @param {Array<string>} [organizations] organizations
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {string} [subCategory] subCategory
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public getWasteGroupsByCriteriaUsingGET(barcode?: string, category?: string, from?: Date, organizations?: Array<string>, page?: number, size?: number, sort?: string, subCategory?: string, to?: Date, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).getWasteGroupsByCriteriaUsingGET(barcode, category, from, organizations, page, size, sort, subCategory, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateWasteDishRecordFiles
     * @param {string} dishId dishId
     * @param {WasteFilesDto} wasteFilesDto wasteFilesDto
     * @param {string} wasteGroupId wasteGroupId
     * @param {string} wasteId wasteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public updateWasteDishRecordFilesUsingPOST(dishId: string, wasteFilesDto: WasteFilesDto, wasteGroupId: string, wasteId: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).updateWasteDishRecordFilesUsingPOST(dishId, wasteFilesDto, wasteGroupId, wasteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateWasteDishRecord
     * @param {string} dishId dishId
     * @param {WasteElementPayloadUpdate} wasteDishPayload wasteDishPayload
     * @param {string} wasteGroupId wasteGroupId
     * @param {string} wasteId wasteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public updateWasteDishRecordUsingPUT(dishId: string, wasteDishPayload: WasteElementPayloadUpdate, wasteGroupId: string, wasteId: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).updateWasteDishRecordUsingPUT(dishId, wasteDishPayload, wasteGroupId, wasteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateWasteFiles
     * @param {string} barcode barcode
     * @param {WasteFilesDto} wasteFilesDto wasteFilesDto
     * @param {string} wasteGroupId wasteGroupId
     * @param {string} wasteId wasteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public updateWasteFilesUsingPOST(barcode: string, wasteFilesDto: WasteFilesDto, wasteGroupId: string, wasteId: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).updateWasteFilesUsingPOST(barcode, wasteFilesDto, wasteGroupId, wasteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateWasteRecipeRecordFiles
     * @param {string} recipeId recipeId
     * @param {WasteFilesDto} wasteFilesDto wasteFilesDto
     * @param {string} wasteGroupId wasteGroupId
     * @param {string} wasteId wasteId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public updateWasteRecipeRecordFilesUsingPOST(recipeId: string, wasteFilesDto: WasteFilesDto, wasteGroupId: string, wasteId: string, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).updateWasteRecipeRecordFilesUsingPOST(recipeId, wasteFilesDto, wasteGroupId, wasteId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateWasteRecipeRecord
     * @param {string} recipeId recipeId
     * @param {string} wasteGroupId wasteGroupId
     * @param {string} wasteId wasteId
     * @param {WasteElementPayloadUpdate} wasteRecipePayload wasteRecipePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public updateWasteRecipeRecordUsingPUT(recipeId: string, wasteGroupId: string, wasteId: string, wasteRecipePayload: WasteElementPayloadUpdate, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).updateWasteRecipeRecordUsingPUT(recipeId, wasteGroupId, wasteId, wasteRecipePayload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateWaste
     * @param {string} barcode barcode
     * @param {string} wasteGroupId wasteGroupId
     * @param {string} wasteId wasteId
     * @param {WastePayloadUpdate} wastePayload wastePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWasteControllerApi
     */
    public updateWasteUsingPUT(barcode: string, wasteGroupId: string, wasteId: string, wastePayload: WastePayloadUpdate, options?: any) {
        return GatewayWasteControllerApiFp(this.configuration).updateWasteUsingPUT(barcode, wasteGroupId, wasteId, wastePayload, options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayWooCommercePosIntegrationControllerApi - fetch parameter creator
 * @export
 */
export const GatewayWooCommercePosIntegrationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createWooCommercePosIntegration
         * @param {WooCommercePosIntegrationCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWooCommercePosIntegrationUsingPOST(payload: WooCommercePosIntegrationCreatePayload, options: any = {}): FetchArgs {
            // verify required parameter 'payload' is not null or undefined
            if (payload === null || payload === undefined) {
                throw new RequiredError('payload','Required parameter payload was null or undefined when calling createWooCommercePosIntegrationUsingPOST.');
            }
            const localVarPath = `/v1/woocommerce-pos-integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"WooCommercePosIntegrationCreatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(payload || {}) : (payload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteWooCommercePosIntegrationById
         * @param {string} wooCommercePosIntegrationId wooCommercePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWooCommercePosIntegrationByIdUsingDELETE(wooCommercePosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'wooCommercePosIntegrationId' is not null or undefined
            if (wooCommercePosIntegrationId === null || wooCommercePosIntegrationId === undefined) {
                throw new RequiredError('wooCommercePosIntegrationId','Required parameter wooCommercePosIntegrationId was null or undefined when calling deleteWooCommercePosIntegrationByIdUsingDELETE.');
            }
            const localVarPath = `/v1/woocommerce-pos-integrations/{wooCommercePosIntegrationId}`
                .replace(`{${"wooCommercePosIntegrationId"}}`, encodeURIComponent(String(wooCommercePosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getWooCommercePosIntegrationById
         * @param {string} wooCommercePosIntegrationId wooCommercePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWooCommercePosIntegrationByIdUsingGET(wooCommercePosIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'wooCommercePosIntegrationId' is not null or undefined
            if (wooCommercePosIntegrationId === null || wooCommercePosIntegrationId === undefined) {
                throw new RequiredError('wooCommercePosIntegrationId','Required parameter wooCommercePosIntegrationId was null or undefined when calling getWooCommercePosIntegrationByIdUsingGET.');
            }
            const localVarPath = `/v1/woocommerce-pos-integrations/{wooCommercePosIntegrationId}`
                .replace(`{${"wooCommercePosIntegrationId"}}`, encodeURIComponent(String(wooCommercePosIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getWooCommercePosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWooCommercePosIntegrationsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/woocommerce-pos-integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayWooCommercePosIntegrationControllerApi - functional programming interface
 * @export
 */
export const GatewayWooCommercePosIntegrationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createWooCommercePosIntegration
         * @param {WooCommercePosIntegrationCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWooCommercePosIntegrationUsingPOST(payload: WooCommercePosIntegrationCreatePayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WooCommercePosIntegration> {
            const localVarFetchArgs = GatewayWooCommercePosIntegrationControllerApiFetchParamCreator(configuration).createWooCommercePosIntegrationUsingPOST(payload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteWooCommercePosIntegrationById
         * @param {string} wooCommercePosIntegrationId wooCommercePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWooCommercePosIntegrationByIdUsingDELETE(wooCommercePosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayWooCommercePosIntegrationControllerApiFetchParamCreator(configuration).deleteWooCommercePosIntegrationByIdUsingDELETE(wooCommercePosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getWooCommercePosIntegrationById
         * @param {string} wooCommercePosIntegrationId wooCommercePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWooCommercePosIntegrationByIdUsingGET(wooCommercePosIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<WooCommercePosIntegration> {
            const localVarFetchArgs = GatewayWooCommercePosIntegrationControllerApiFetchParamCreator(configuration).getWooCommercePosIntegrationByIdUsingGET(wooCommercePosIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getWooCommercePosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWooCommercePosIntegrationsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<WooCommercePosIntegration>> {
            const localVarFetchArgs = GatewayWooCommercePosIntegrationControllerApiFetchParamCreator(configuration).getWooCommercePosIntegrationsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayWooCommercePosIntegrationControllerApi - factory interface
 * @export
 */
export const GatewayWooCommercePosIntegrationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createWooCommercePosIntegration
         * @param {WooCommercePosIntegrationCreatePayload} payload payload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createWooCommercePosIntegrationUsingPOST(payload: WooCommercePosIntegrationCreatePayload, options?: any) {
            return GatewayWooCommercePosIntegrationControllerApiFp(configuration).createWooCommercePosIntegrationUsingPOST(payload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteWooCommercePosIntegrationById
         * @param {string} wooCommercePosIntegrationId wooCommercePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteWooCommercePosIntegrationByIdUsingDELETE(wooCommercePosIntegrationId: string, options?: any) {
            return GatewayWooCommercePosIntegrationControllerApiFp(configuration).deleteWooCommercePosIntegrationByIdUsingDELETE(wooCommercePosIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getWooCommercePosIntegrationById
         * @param {string} wooCommercePosIntegrationId wooCommercePosIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWooCommercePosIntegrationByIdUsingGET(wooCommercePosIntegrationId: string, options?: any) {
            return GatewayWooCommercePosIntegrationControllerApiFp(configuration).getWooCommercePosIntegrationByIdUsingGET(wooCommercePosIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getWooCommercePosIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getWooCommercePosIntegrationsUsingGET(options?: any) {
            return GatewayWooCommercePosIntegrationControllerApiFp(configuration).getWooCommercePosIntegrationsUsingGET(options)(fetch, basePath);
        },
    };
};

/**
 * GatewayWooCommercePosIntegrationControllerApi - object-oriented interface
 * @export
 * @class GatewayWooCommercePosIntegrationControllerApi
 * @extends {BaseAPI}
 */
export class GatewayWooCommercePosIntegrationControllerApi extends BaseAPI {
    /**
     * 
     * @summary createWooCommercePosIntegration
     * @param {WooCommercePosIntegrationCreatePayload} payload payload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWooCommercePosIntegrationControllerApi
     */
    public createWooCommercePosIntegrationUsingPOST(payload: WooCommercePosIntegrationCreatePayload, options?: any) {
        return GatewayWooCommercePosIntegrationControllerApiFp(this.configuration).createWooCommercePosIntegrationUsingPOST(payload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteWooCommercePosIntegrationById
     * @param {string} wooCommercePosIntegrationId wooCommercePosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWooCommercePosIntegrationControllerApi
     */
    public deleteWooCommercePosIntegrationByIdUsingDELETE(wooCommercePosIntegrationId: string, options?: any) {
        return GatewayWooCommercePosIntegrationControllerApiFp(this.configuration).deleteWooCommercePosIntegrationByIdUsingDELETE(wooCommercePosIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getWooCommercePosIntegrationById
     * @param {string} wooCommercePosIntegrationId wooCommercePosIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWooCommercePosIntegrationControllerApi
     */
    public getWooCommercePosIntegrationByIdUsingGET(wooCommercePosIntegrationId: string, options?: any) {
        return GatewayWooCommercePosIntegrationControllerApiFp(this.configuration).getWooCommercePosIntegrationByIdUsingGET(wooCommercePosIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getWooCommercePosIntegrations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayWooCommercePosIntegrationControllerApi
     */
    public getWooCommercePosIntegrationsUsingGET(options?: any) {
        return GatewayWooCommercePosIntegrationControllerApiFp(this.configuration).getWooCommercePosIntegrationsUsingGET(options)(this.fetch, this.basePath);
    }

}

/**
 * GatewayXeroIntegrationControllerApi - fetch parameter creator
 * @export
 */
export const GatewayXeroIntegrationControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createXeroIntegration
         * @param {XeroIntegrationCreatePayload} xeroIntegrationCreatePayload xeroIntegrationCreatePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createXeroIntegrationUsingPOST(xeroIntegrationCreatePayload: XeroIntegrationCreatePayload, options: any = {}): FetchArgs {
            // verify required parameter 'xeroIntegrationCreatePayload' is not null or undefined
            if (xeroIntegrationCreatePayload === null || xeroIntegrationCreatePayload === undefined) {
                throw new RequiredError('xeroIntegrationCreatePayload','Required parameter xeroIntegrationCreatePayload was null or undefined when calling createXeroIntegrationUsingPOST.');
            }
            const localVarPath = `/v1/xero-integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"XeroIntegrationCreatePayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(xeroIntegrationCreatePayload || {}) : (xeroIntegrationCreatePayload || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteXeroIntegrationById
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteXeroIntegrationByIdUsingDELETE(xeroIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'xeroIntegrationId' is not null or undefined
            if (xeroIntegrationId === null || xeroIntegrationId === undefined) {
                throw new RequiredError('xeroIntegrationId','Required parameter xeroIntegrationId was null or undefined when calling deleteXeroIntegrationByIdUsingDELETE.');
            }
            const localVarPath = `/v1/xero-integrations/{xeroIntegrationId}`
                .replace(`{${"xeroIntegrationId"}}`, encodeURIComponent(String(xeroIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAccountsFromXero
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsFromXeroUsingGET(xeroIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'xeroIntegrationId' is not null or undefined
            if (xeroIntegrationId === null || xeroIntegrationId === undefined) {
                throw new RequiredError('xeroIntegrationId','Required parameter xeroIntegrationId was null or undefined when calling getAccountsFromXeroUsingGET.');
            }
            const localVarPath = `/v1/xero-integrations/accounts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xeroIntegrationId !== undefined) {
                localVarQueryParameter['xeroIntegrationId'] = xeroIntegrationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getClientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientIdUsingGET1(options: any = {}): FetchArgs {
            const localVarPath = `/v1/xero-integrations/client-id`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getConnectionsFromXero
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionsFromXeroUsingGET(xeroIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'xeroIntegrationId' is not null or undefined
            if (xeroIntegrationId === null || xeroIntegrationId === undefined) {
                throw new RequiredError('xeroIntegrationId','Required parameter xeroIntegrationId was null or undefined when calling getConnectionsFromXeroUsingGET.');
            }
            const localVarPath = `/v1/xero-integrations/connections`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xeroIntegrationId !== undefined) {
                localVarQueryParameter['xeroIntegrationId'] = xeroIntegrationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getSuppliersFromXero
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuppliersFromXeroUsingGET(xeroIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'xeroIntegrationId' is not null or undefined
            if (xeroIntegrationId === null || xeroIntegrationId === undefined) {
                throw new RequiredError('xeroIntegrationId','Required parameter xeroIntegrationId was null or undefined when calling getSuppliersFromXeroUsingGET.');
            }
            const localVarPath = `/v1/xero-integrations/suppliers`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xeroIntegrationId !== undefined) {
                localVarQueryParameter['xeroIntegrationId'] = xeroIntegrationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getTaxRatesFromXero
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxRatesFromXeroUsingGET(xeroIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'xeroIntegrationId' is not null or undefined
            if (xeroIntegrationId === null || xeroIntegrationId === undefined) {
                throw new RequiredError('xeroIntegrationId','Required parameter xeroIntegrationId was null or undefined when calling getTaxRatesFromXeroUsingGET.');
            }
            const localVarPath = `/v1/xero-integrations/tax-rates`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xeroIntegrationId !== undefined) {
                localVarQueryParameter['xeroIntegrationId'] = xeroIntegrationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getXeroIntegrationById
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroIntegrationByIdUsingGET(xeroIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'xeroIntegrationId' is not null or undefined
            if (xeroIntegrationId === null || xeroIntegrationId === undefined) {
                throw new RequiredError('xeroIntegrationId','Required parameter xeroIntegrationId was null or undefined when calling getXeroIntegrationByIdUsingGET.');
            }
            const localVarPath = `/v1/xero-integrations/{xeroIntegrationId}`
                .replace(`{${"xeroIntegrationId"}}`, encodeURIComponent(String(xeroIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getXeroIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroIntegrationsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/xero-integrations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateXeroIntegrationConnection
         * @param {string} connectionId connectionId
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateXeroIntegrationConnectionUsingPATCH(connectionId: string, xeroIntegrationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'connectionId' is not null or undefined
            if (connectionId === null || connectionId === undefined) {
                throw new RequiredError('connectionId','Required parameter connectionId was null or undefined when calling updateXeroIntegrationConnectionUsingPATCH.');
            }
            // verify required parameter 'xeroIntegrationId' is not null or undefined
            if (xeroIntegrationId === null || xeroIntegrationId === undefined) {
                throw new RequiredError('xeroIntegrationId','Required parameter xeroIntegrationId was null or undefined when calling updateXeroIntegrationConnectionUsingPATCH.');
            }
            const localVarPath = `/v1/xero-integrations/{xeroIntegrationId}/connection/{connectionId}`
                .replace(`{${"connectionId"}}`, encodeURIComponent(String(connectionId)))
                .replace(`{${"xeroIntegrationId"}}`, encodeURIComponent(String(xeroIntegrationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GatewayXeroIntegrationControllerApi - functional programming interface
 * @export
 */
export const GatewayXeroIntegrationControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary createXeroIntegration
         * @param {XeroIntegrationCreatePayload} xeroIntegrationCreatePayload xeroIntegrationCreatePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createXeroIntegrationUsingPOST(xeroIntegrationCreatePayload: XeroIntegrationCreatePayload, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<XeroIntegration> {
            const localVarFetchArgs = GatewayXeroIntegrationControllerApiFetchParamCreator(configuration).createXeroIntegrationUsingPOST(xeroIntegrationCreatePayload, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteXeroIntegrationById
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteXeroIntegrationByIdUsingDELETE(xeroIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = GatewayXeroIntegrationControllerApiFetchParamCreator(configuration).deleteXeroIntegrationByIdUsingDELETE(xeroIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAccountsFromXero
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsFromXeroUsingGET(xeroIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<XeroAccount>> {
            const localVarFetchArgs = GatewayXeroIntegrationControllerApiFetchParamCreator(configuration).getAccountsFromXeroUsingGET(xeroIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getClientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientIdUsingGET1(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = GatewayXeroIntegrationControllerApiFetchParamCreator(configuration).getClientIdUsingGET1(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getConnectionsFromXero
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionsFromXeroUsingGET(xeroIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ConnectionsDto> {
            const localVarFetchArgs = GatewayXeroIntegrationControllerApiFetchParamCreator(configuration).getConnectionsFromXeroUsingGET(xeroIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getSuppliersFromXero
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuppliersFromXeroUsingGET(xeroIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<XeroSupplier>> {
            const localVarFetchArgs = GatewayXeroIntegrationControllerApiFetchParamCreator(configuration).getSuppliersFromXeroUsingGET(xeroIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getTaxRatesFromXero
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxRatesFromXeroUsingGET(xeroIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<XeroTaxRate>> {
            const localVarFetchArgs = GatewayXeroIntegrationControllerApiFetchParamCreator(configuration).getTaxRatesFromXeroUsingGET(xeroIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getXeroIntegrationById
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroIntegrationByIdUsingGET(xeroIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<XeroIntegration> {
            const localVarFetchArgs = GatewayXeroIntegrationControllerApiFetchParamCreator(configuration).getXeroIntegrationByIdUsingGET(xeroIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getXeroIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroIntegrationsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<XeroIntegration>> {
            const localVarFetchArgs = GatewayXeroIntegrationControllerApiFetchParamCreator(configuration).getXeroIntegrationsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateXeroIntegrationConnection
         * @param {string} connectionId connectionId
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateXeroIntegrationConnectionUsingPATCH(connectionId: string, xeroIntegrationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<XeroIntegration> {
            const localVarFetchArgs = GatewayXeroIntegrationControllerApiFetchParamCreator(configuration).updateXeroIntegrationConnectionUsingPATCH(connectionId, xeroIntegrationId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * GatewayXeroIntegrationControllerApi - factory interface
 * @export
 */
export const GatewayXeroIntegrationControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary createXeroIntegration
         * @param {XeroIntegrationCreatePayload} xeroIntegrationCreatePayload xeroIntegrationCreatePayload
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createXeroIntegrationUsingPOST(xeroIntegrationCreatePayload: XeroIntegrationCreatePayload, options?: any) {
            return GatewayXeroIntegrationControllerApiFp(configuration).createXeroIntegrationUsingPOST(xeroIntegrationCreatePayload, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteXeroIntegrationById
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteXeroIntegrationByIdUsingDELETE(xeroIntegrationId: string, options?: any) {
            return GatewayXeroIntegrationControllerApiFp(configuration).deleteXeroIntegrationByIdUsingDELETE(xeroIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAccountsFromXero
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccountsFromXeroUsingGET(xeroIntegrationId: string, options?: any) {
            return GatewayXeroIntegrationControllerApiFp(configuration).getAccountsFromXeroUsingGET(xeroIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getClientId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClientIdUsingGET1(options?: any) {
            return GatewayXeroIntegrationControllerApiFp(configuration).getClientIdUsingGET1(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getConnectionsFromXero
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionsFromXeroUsingGET(xeroIntegrationId: string, options?: any) {
            return GatewayXeroIntegrationControllerApiFp(configuration).getConnectionsFromXeroUsingGET(xeroIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getSuppliersFromXero
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSuppliersFromXeroUsingGET(xeroIntegrationId: string, options?: any) {
            return GatewayXeroIntegrationControllerApiFp(configuration).getSuppliersFromXeroUsingGET(xeroIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getTaxRatesFromXero
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaxRatesFromXeroUsingGET(xeroIntegrationId: string, options?: any) {
            return GatewayXeroIntegrationControllerApiFp(configuration).getTaxRatesFromXeroUsingGET(xeroIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getXeroIntegrationById
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroIntegrationByIdUsingGET(xeroIntegrationId: string, options?: any) {
            return GatewayXeroIntegrationControllerApiFp(configuration).getXeroIntegrationByIdUsingGET(xeroIntegrationId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getXeroIntegrations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getXeroIntegrationsUsingGET(options?: any) {
            return GatewayXeroIntegrationControllerApiFp(configuration).getXeroIntegrationsUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateXeroIntegrationConnection
         * @param {string} connectionId connectionId
         * @param {string} xeroIntegrationId xeroIntegrationId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateXeroIntegrationConnectionUsingPATCH(connectionId: string, xeroIntegrationId: string, options?: any) {
            return GatewayXeroIntegrationControllerApiFp(configuration).updateXeroIntegrationConnectionUsingPATCH(connectionId, xeroIntegrationId, options)(fetch, basePath);
        },
    };
};

/**
 * GatewayXeroIntegrationControllerApi - object-oriented interface
 * @export
 * @class GatewayXeroIntegrationControllerApi
 * @extends {BaseAPI}
 */
export class GatewayXeroIntegrationControllerApi extends BaseAPI {
    /**
     * 
     * @summary createXeroIntegration
     * @param {XeroIntegrationCreatePayload} xeroIntegrationCreatePayload xeroIntegrationCreatePayload
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayXeroIntegrationControllerApi
     */
    public createXeroIntegrationUsingPOST(xeroIntegrationCreatePayload: XeroIntegrationCreatePayload, options?: any) {
        return GatewayXeroIntegrationControllerApiFp(this.configuration).createXeroIntegrationUsingPOST(xeroIntegrationCreatePayload, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteXeroIntegrationById
     * @param {string} xeroIntegrationId xeroIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayXeroIntegrationControllerApi
     */
    public deleteXeroIntegrationByIdUsingDELETE(xeroIntegrationId: string, options?: any) {
        return GatewayXeroIntegrationControllerApiFp(this.configuration).deleteXeroIntegrationByIdUsingDELETE(xeroIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAccountsFromXero
     * @param {string} xeroIntegrationId xeroIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayXeroIntegrationControllerApi
     */
    public getAccountsFromXeroUsingGET(xeroIntegrationId: string, options?: any) {
        return GatewayXeroIntegrationControllerApiFp(this.configuration).getAccountsFromXeroUsingGET(xeroIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getClientId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayXeroIntegrationControllerApi
     */
    public getClientIdUsingGET1(options?: any) {
        return GatewayXeroIntegrationControllerApiFp(this.configuration).getClientIdUsingGET1(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getConnectionsFromXero
     * @param {string} xeroIntegrationId xeroIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayXeroIntegrationControllerApi
     */
    public getConnectionsFromXeroUsingGET(xeroIntegrationId: string, options?: any) {
        return GatewayXeroIntegrationControllerApiFp(this.configuration).getConnectionsFromXeroUsingGET(xeroIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getSuppliersFromXero
     * @param {string} xeroIntegrationId xeroIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayXeroIntegrationControllerApi
     */
    public getSuppliersFromXeroUsingGET(xeroIntegrationId: string, options?: any) {
        return GatewayXeroIntegrationControllerApiFp(this.configuration).getSuppliersFromXeroUsingGET(xeroIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getTaxRatesFromXero
     * @param {string} xeroIntegrationId xeroIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayXeroIntegrationControllerApi
     */
    public getTaxRatesFromXeroUsingGET(xeroIntegrationId: string, options?: any) {
        return GatewayXeroIntegrationControllerApiFp(this.configuration).getTaxRatesFromXeroUsingGET(xeroIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getXeroIntegrationById
     * @param {string} xeroIntegrationId xeroIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayXeroIntegrationControllerApi
     */
    public getXeroIntegrationByIdUsingGET(xeroIntegrationId: string, options?: any) {
        return GatewayXeroIntegrationControllerApiFp(this.configuration).getXeroIntegrationByIdUsingGET(xeroIntegrationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getXeroIntegrations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayXeroIntegrationControllerApi
     */
    public getXeroIntegrationsUsingGET(options?: any) {
        return GatewayXeroIntegrationControllerApiFp(this.configuration).getXeroIntegrationsUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateXeroIntegrationConnection
     * @param {string} connectionId connectionId
     * @param {string} xeroIntegrationId xeroIntegrationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GatewayXeroIntegrationControllerApi
     */
    public updateXeroIntegrationConnectionUsingPATCH(connectionId: string, xeroIntegrationId: string, options?: any) {
        return GatewayXeroIntegrationControllerApiFp(this.configuration).updateXeroIntegrationConnectionUsingPATCH(connectionId, xeroIntegrationId, options)(this.fetch, this.basePath);
    }

}

/**
 * OnboardingOrgControllerApi - fetch parameter creator
 * @export
 */
export const OnboardingOrgControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getMyOnboardingOrgSteps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOnboardingOrgStepsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/onboarding-org/my`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getOnboardingOrgStepsById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnboardingOrgStepsByIdUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getOnboardingOrgStepsByIdUsingGET.');
            }
            const localVarPath = `/v1/onboarding-org/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateMyOnboardingOrgSteps
         * @param {OnboardingOrgSteps} onboardingOrgSteps onboardingOrgSteps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyOnboardingOrgStepsUsingPUT(onboardingOrgSteps: OnboardingOrgSteps, options: any = {}): FetchArgs {
            // verify required parameter 'onboardingOrgSteps' is not null or undefined
            if (onboardingOrgSteps === null || onboardingOrgSteps === undefined) {
                throw new RequiredError('onboardingOrgSteps','Required parameter onboardingOrgSteps was null or undefined when calling updateMyOnboardingOrgStepsUsingPUT.');
            }
            const localVarPath = `/v1/onboarding-org/my`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OnboardingOrgSteps" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(onboardingOrgSteps || {}) : (onboardingOrgSteps || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateOnboardingOrgSteps
         * @param {string} id id
         * @param {OnboardingOrgSteps} onboardingOrgSteps onboardingOrgSteps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOnboardingOrgStepsUsingPUT(id: string, onboardingOrgSteps: OnboardingOrgSteps, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling updateOnboardingOrgStepsUsingPUT.');
            }
            // verify required parameter 'onboardingOrgSteps' is not null or undefined
            if (onboardingOrgSteps === null || onboardingOrgSteps === undefined) {
                throw new RequiredError('onboardingOrgSteps','Required parameter onboardingOrgSteps was null or undefined when calling updateOnboardingOrgStepsUsingPUT.');
            }
            const localVarPath = `/v1/onboarding-org/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OnboardingOrgSteps" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(onboardingOrgSteps || {}) : (onboardingOrgSteps || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnboardingOrgControllerApi - functional programming interface
 * @export
 */
export const OnboardingOrgControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getMyOnboardingOrgSteps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOnboardingOrgStepsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OnboardingOrgSteps> {
            const localVarFetchArgs = OnboardingOrgControllerApiFetchParamCreator(configuration).getMyOnboardingOrgStepsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getOnboardingOrgStepsById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnboardingOrgStepsByIdUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OnboardingOrgSteps> {
            const localVarFetchArgs = OnboardingOrgControllerApiFetchParamCreator(configuration).getOnboardingOrgStepsByIdUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateMyOnboardingOrgSteps
         * @param {OnboardingOrgSteps} onboardingOrgSteps onboardingOrgSteps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyOnboardingOrgStepsUsingPUT(onboardingOrgSteps: OnboardingOrgSteps, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OnboardingOrgSteps> {
            const localVarFetchArgs = OnboardingOrgControllerApiFetchParamCreator(configuration).updateMyOnboardingOrgStepsUsingPUT(onboardingOrgSteps, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateOnboardingOrgSteps
         * @param {string} id id
         * @param {OnboardingOrgSteps} onboardingOrgSteps onboardingOrgSteps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOnboardingOrgStepsUsingPUT(id: string, onboardingOrgSteps: OnboardingOrgSteps, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<OnboardingOrgSteps> {
            const localVarFetchArgs = OnboardingOrgControllerApiFetchParamCreator(configuration).updateOnboardingOrgStepsUsingPUT(id, onboardingOrgSteps, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OnboardingOrgControllerApi - factory interface
 * @export
 */
export const OnboardingOrgControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getMyOnboardingOrgSteps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOnboardingOrgStepsUsingGET(options?: any) {
            return OnboardingOrgControllerApiFp(configuration).getMyOnboardingOrgStepsUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getOnboardingOrgStepsById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnboardingOrgStepsByIdUsingGET(id: string, options?: any) {
            return OnboardingOrgControllerApiFp(configuration).getOnboardingOrgStepsByIdUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateMyOnboardingOrgSteps
         * @param {OnboardingOrgSteps} onboardingOrgSteps onboardingOrgSteps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyOnboardingOrgStepsUsingPUT(onboardingOrgSteps: OnboardingOrgSteps, options?: any) {
            return OnboardingOrgControllerApiFp(configuration).updateMyOnboardingOrgStepsUsingPUT(onboardingOrgSteps, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateOnboardingOrgSteps
         * @param {string} id id
         * @param {OnboardingOrgSteps} onboardingOrgSteps onboardingOrgSteps
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOnboardingOrgStepsUsingPUT(id: string, onboardingOrgSteps: OnboardingOrgSteps, options?: any) {
            return OnboardingOrgControllerApiFp(configuration).updateOnboardingOrgStepsUsingPUT(id, onboardingOrgSteps, options)(fetch, basePath);
        },
    };
};

/**
 * OnboardingOrgControllerApi - object-oriented interface
 * @export
 * @class OnboardingOrgControllerApi
 * @extends {BaseAPI}
 */
export class OnboardingOrgControllerApi extends BaseAPI {
    /**
     * 
     * @summary getMyOnboardingOrgSteps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingOrgControllerApi
     */
    public getMyOnboardingOrgStepsUsingGET(options?: any) {
        return OnboardingOrgControllerApiFp(this.configuration).getMyOnboardingOrgStepsUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getOnboardingOrgStepsById
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingOrgControllerApi
     */
    public getOnboardingOrgStepsByIdUsingGET(id: string, options?: any) {
        return OnboardingOrgControllerApiFp(this.configuration).getOnboardingOrgStepsByIdUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateMyOnboardingOrgSteps
     * @param {OnboardingOrgSteps} onboardingOrgSteps onboardingOrgSteps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingOrgControllerApi
     */
    public updateMyOnboardingOrgStepsUsingPUT(onboardingOrgSteps: OnboardingOrgSteps, options?: any) {
        return OnboardingOrgControllerApiFp(this.configuration).updateMyOnboardingOrgStepsUsingPUT(onboardingOrgSteps, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateOnboardingOrgSteps
     * @param {string} id id
     * @param {OnboardingOrgSteps} onboardingOrgSteps onboardingOrgSteps
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingOrgControllerApi
     */
    public updateOnboardingOrgStepsUsingPUT(id: string, onboardingOrgSteps: OnboardingOrgSteps, options?: any) {
        return OnboardingOrgControllerApiFp(this.configuration).updateOnboardingOrgStepsUsingPUT(id, onboardingOrgSteps, options)(this.fetch, this.basePath);
    }

}

/**
 * OnboardingOrgStepsEntityApi - fetch parameter creator
 * @export
 */
export const OnboardingOrgStepsEntityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteByOrgIdOnboardingOrgSteps
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByOrgIdOnboardingOrgStepsUsingGET(orgId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/onboardingOrgStepses/search/deleteByOrgId`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteOnboardingOrgSteps
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOnboardingOrgStepsUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteOnboardingOrgStepsUsingDELETE.');
            }
            const localVarPath = `/onboardingOrgStepses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllByOrgIdInOnboardingOrgSteps
         * @param {Array<string>} [organizations] organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrgIdInOnboardingOrgStepsUsingGET(organizations?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/onboardingOrgStepses/search/findAllByOrgIdIn`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (organizations) {
                localVarQueryParameter['organizations'] = organizations;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllOnboardingOrgSteps
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllOnboardingOrgStepsUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/onboardingOrgStepses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdOnboardingOrgSteps
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdOnboardingOrgStepsUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdOnboardingOrgStepsUsingGET.');
            }
            const localVarPath = `/onboardingOrgStepses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByOrgIdOnboardingOrgSteps
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByOrgIdOnboardingOrgStepsUsingGET(orgId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/onboardingOrgStepses/search/findByOrgId`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveOnboardingOrgSteps
         * @param {OnboardingOrgSteps} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOnboardingOrgStepsUsingPATCH(body: OnboardingOrgSteps, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveOnboardingOrgStepsUsingPATCH.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveOnboardingOrgStepsUsingPATCH.');
            }
            const localVarPath = `/onboardingOrgStepses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OnboardingOrgSteps" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveOnboardingOrgSteps
         * @param {OnboardingOrgSteps} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOnboardingOrgStepsUsingPOST(body: OnboardingOrgSteps, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveOnboardingOrgStepsUsingPOST.');
            }
            const localVarPath = `/onboardingOrgStepses`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OnboardingOrgSteps" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveOnboardingOrgSteps
         * @param {OnboardingOrgSteps} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOnboardingOrgStepsUsingPUT(body: OnboardingOrgSteps, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveOnboardingOrgStepsUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveOnboardingOrgStepsUsingPUT.');
            }
            const localVarPath = `/onboardingOrgStepses/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"OnboardingOrgSteps" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OnboardingOrgStepsEntityApi - functional programming interface
 * @export
 */
export const OnboardingOrgStepsEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteByOrgIdOnboardingOrgSteps
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByOrgIdOnboardingOrgStepsUsingGET(orgId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = OnboardingOrgStepsEntityApiFetchParamCreator(configuration).deleteByOrgIdOnboardingOrgStepsUsingGET(orgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteOnboardingOrgSteps
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOnboardingOrgStepsUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = OnboardingOrgStepsEntityApiFetchParamCreator(configuration).deleteOnboardingOrgStepsUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllByOrgIdInOnboardingOrgSteps
         * @param {Array<string>} [organizations] organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrgIdInOnboardingOrgStepsUsingGET(organizations?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelOnboardingOrgSteps> {
            const localVarFetchArgs = OnboardingOrgStepsEntityApiFetchParamCreator(configuration).findAllByOrgIdInOnboardingOrgStepsUsingGET(organizations, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllOnboardingOrgSteps
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllOnboardingOrgStepsUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelOnboardingOrgSteps> {
            const localVarFetchArgs = OnboardingOrgStepsEntityApiFetchParamCreator(configuration).findAllOnboardingOrgStepsUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByIdOnboardingOrgSteps
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdOnboardingOrgStepsUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelOnboardingOrgSteps> {
            const localVarFetchArgs = OnboardingOrgStepsEntityApiFetchParamCreator(configuration).findByIdOnboardingOrgStepsUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByOrgIdOnboardingOrgSteps
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByOrgIdOnboardingOrgStepsUsingGET(orgId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelOnboardingOrgSteps> {
            const localVarFetchArgs = OnboardingOrgStepsEntityApiFetchParamCreator(configuration).findByOrgIdOnboardingOrgStepsUsingGET(orgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveOnboardingOrgSteps
         * @param {OnboardingOrgSteps} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOnboardingOrgStepsUsingPATCH(body: OnboardingOrgSteps, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelOnboardingOrgSteps> {
            const localVarFetchArgs = OnboardingOrgStepsEntityApiFetchParamCreator(configuration).saveOnboardingOrgStepsUsingPATCH(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveOnboardingOrgSteps
         * @param {OnboardingOrgSteps} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOnboardingOrgStepsUsingPOST(body: OnboardingOrgSteps, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelOnboardingOrgSteps> {
            const localVarFetchArgs = OnboardingOrgStepsEntityApiFetchParamCreator(configuration).saveOnboardingOrgStepsUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveOnboardingOrgSteps
         * @param {OnboardingOrgSteps} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOnboardingOrgStepsUsingPUT(body: OnboardingOrgSteps, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelOnboardingOrgSteps> {
            const localVarFetchArgs = OnboardingOrgStepsEntityApiFetchParamCreator(configuration).saveOnboardingOrgStepsUsingPUT(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OnboardingOrgStepsEntityApi - factory interface
 * @export
 */
export const OnboardingOrgStepsEntityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteByOrgIdOnboardingOrgSteps
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByOrgIdOnboardingOrgStepsUsingGET(orgId?: string, options?: any) {
            return OnboardingOrgStepsEntityApiFp(configuration).deleteByOrgIdOnboardingOrgStepsUsingGET(orgId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteOnboardingOrgSteps
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOnboardingOrgStepsUsingDELETE(id: string, options?: any) {
            return OnboardingOrgStepsEntityApiFp(configuration).deleteOnboardingOrgStepsUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllByOrgIdInOnboardingOrgSteps
         * @param {Array<string>} [organizations] organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrgIdInOnboardingOrgStepsUsingGET(organizations?: Array<string>, options?: any) {
            return OnboardingOrgStepsEntityApiFp(configuration).findAllByOrgIdInOnboardingOrgStepsUsingGET(organizations, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllOnboardingOrgSteps
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllOnboardingOrgStepsUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return OnboardingOrgStepsEntityApiFp(configuration).findAllOnboardingOrgStepsUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByIdOnboardingOrgSteps
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdOnboardingOrgStepsUsingGET(id: string, options?: any) {
            return OnboardingOrgStepsEntityApiFp(configuration).findByIdOnboardingOrgStepsUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByOrgIdOnboardingOrgSteps
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByOrgIdOnboardingOrgStepsUsingGET(orgId?: string, options?: any) {
            return OnboardingOrgStepsEntityApiFp(configuration).findByOrgIdOnboardingOrgStepsUsingGET(orgId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveOnboardingOrgSteps
         * @param {OnboardingOrgSteps} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOnboardingOrgStepsUsingPATCH(body: OnboardingOrgSteps, id: string, options?: any) {
            return OnboardingOrgStepsEntityApiFp(configuration).saveOnboardingOrgStepsUsingPATCH(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveOnboardingOrgSteps
         * @param {OnboardingOrgSteps} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOnboardingOrgStepsUsingPOST(body: OnboardingOrgSteps, options?: any) {
            return OnboardingOrgStepsEntityApiFp(configuration).saveOnboardingOrgStepsUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveOnboardingOrgSteps
         * @param {OnboardingOrgSteps} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOnboardingOrgStepsUsingPUT(body: OnboardingOrgSteps, id: string, options?: any) {
            return OnboardingOrgStepsEntityApiFp(configuration).saveOnboardingOrgStepsUsingPUT(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * OnboardingOrgStepsEntityApi - object-oriented interface
 * @export
 * @class OnboardingOrgStepsEntityApi
 * @extends {BaseAPI}
 */
export class OnboardingOrgStepsEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteByOrgIdOnboardingOrgSteps
     * @param {string} [orgId] orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingOrgStepsEntityApi
     */
    public deleteByOrgIdOnboardingOrgStepsUsingGET(orgId?: string, options?: any) {
        return OnboardingOrgStepsEntityApiFp(this.configuration).deleteByOrgIdOnboardingOrgStepsUsingGET(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteOnboardingOrgSteps
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingOrgStepsEntityApi
     */
    public deleteOnboardingOrgStepsUsingDELETE(id: string, options?: any) {
        return OnboardingOrgStepsEntityApiFp(this.configuration).deleteOnboardingOrgStepsUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllByOrgIdInOnboardingOrgSteps
     * @param {Array<string>} [organizations] organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingOrgStepsEntityApi
     */
    public findAllByOrgIdInOnboardingOrgStepsUsingGET(organizations?: Array<string>, options?: any) {
        return OnboardingOrgStepsEntityApiFp(this.configuration).findAllByOrgIdInOnboardingOrgStepsUsingGET(organizations, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllOnboardingOrgSteps
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingOrgStepsEntityApi
     */
    public findAllOnboardingOrgStepsUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return OnboardingOrgStepsEntityApiFp(this.configuration).findAllOnboardingOrgStepsUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByIdOnboardingOrgSteps
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingOrgStepsEntityApi
     */
    public findByIdOnboardingOrgStepsUsingGET(id: string, options?: any) {
        return OnboardingOrgStepsEntityApiFp(this.configuration).findByIdOnboardingOrgStepsUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByOrgIdOnboardingOrgSteps
     * @param {string} [orgId] orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingOrgStepsEntityApi
     */
    public findByOrgIdOnboardingOrgStepsUsingGET(orgId?: string, options?: any) {
        return OnboardingOrgStepsEntityApiFp(this.configuration).findByOrgIdOnboardingOrgStepsUsingGET(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveOnboardingOrgSteps
     * @param {OnboardingOrgSteps} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingOrgStepsEntityApi
     */
    public saveOnboardingOrgStepsUsingPATCH(body: OnboardingOrgSteps, id: string, options?: any) {
        return OnboardingOrgStepsEntityApiFp(this.configuration).saveOnboardingOrgStepsUsingPATCH(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveOnboardingOrgSteps
     * @param {OnboardingOrgSteps} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingOrgStepsEntityApi
     */
    public saveOnboardingOrgStepsUsingPOST(body: OnboardingOrgSteps, options?: any) {
        return OnboardingOrgStepsEntityApiFp(this.configuration).saveOnboardingOrgStepsUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveOnboardingOrgSteps
     * @param {OnboardingOrgSteps} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OnboardingOrgStepsEntityApi
     */
    public saveOnboardingOrgStepsUsingPUT(body: OnboardingOrgSteps, id: string, options?: any) {
        return OnboardingOrgStepsEntityApiFp(this.configuration).saveOnboardingOrgStepsUsingPUT(body, id, options)(this.fetch, this.basePath);
    }

}

/**
 * OperationHandlerApi - fetch parameter creator
 * @export
 */
export const OperationHandlerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary handle
         * @param {any} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET(body?: any, options: any = {}): FetchArgs {
            const localVarPath = `/actuator/health`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"any" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary handle
         * @param {any} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET1(body?: any, options: any = {}): FetchArgs {
            const localVarPath = `/actuator/health/**`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"any" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary handle
         * @param {any} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET2(body?: any, options: any = {}): FetchArgs {
            const localVarPath = `/actuator/info`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"any" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OperationHandlerApi - functional programming interface
 * @export
 */
export const OperationHandlerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary handle
         * @param {any} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET(body?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = OperationHandlerApiFetchParamCreator(configuration).handleUsingGET(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary handle
         * @param {any} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET1(body?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = OperationHandlerApiFetchParamCreator(configuration).handleUsingGET1(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary handle
         * @param {any} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET2(body?: any, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = OperationHandlerApiFetchParamCreator(configuration).handleUsingGET2(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OperationHandlerApi - factory interface
 * @export
 */
export const OperationHandlerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary handle
         * @param {any} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET(body?: any, options?: any) {
            return OperationHandlerApiFp(configuration).handleUsingGET(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary handle
         * @param {any} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET1(body?: any, options?: any) {
            return OperationHandlerApiFp(configuration).handleUsingGET1(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary handle
         * @param {any} [body] body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        handleUsingGET2(body?: any, options?: any) {
            return OperationHandlerApiFp(configuration).handleUsingGET2(body, options)(fetch, basePath);
        },
    };
};

/**
 * OperationHandlerApi - object-oriented interface
 * @export
 * @class OperationHandlerApi
 * @extends {BaseAPI}
 */
export class OperationHandlerApi extends BaseAPI {
    /**
     * 
     * @summary handle
     * @param {any} [body] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationHandlerApi
     */
    public handleUsingGET(body?: any, options?: any) {
        return OperationHandlerApiFp(this.configuration).handleUsingGET(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary handle
     * @param {any} [body] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationHandlerApi
     */
    public handleUsingGET1(body?: any, options?: any) {
        return OperationHandlerApiFp(this.configuration).handleUsingGET1(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary handle
     * @param {any} [body] body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OperationHandlerApi
     */
    public handleUsingGET2(body?: any, options?: any) {
        return OperationHandlerApiFp(this.configuration).handleUsingGET2(body, options)(this.fetch, this.basePath);
    }

}

/**
 * PasswordResetTokenEntityApi - fetch parameter creator
 * @export
 */
export const PasswordResetTokenEntityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAllByExpiryDateIsLessThanEqualPasswordResetToken
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByExpiryDateIsLessThanEqualPasswordResetTokenUsingGET(now?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/passwordResetTokens/search/deleteAllByExpiryDateIsLessThanEqual`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (now !== undefined) {
                localVarQueryParameter['now'] = (now as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAllByUsernameIgnoreCasePasswordResetToken
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUsernameIgnoreCasePasswordResetTokenUsingGET(username?: string, options: any = {}): FetchArgs {
            const localVarPath = `/passwordResetTokens/search/deleteAllByUsernameIgnoreCase`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteByTokenPasswordResetToken
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByTokenPasswordResetTokenUsingGET(token?: string, options: any = {}): FetchArgs {
            const localVarPath = `/passwordResetTokens/search/deleteByToken`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletePasswordResetToken
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePasswordResetTokenUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePasswordResetTokenUsingDELETE.');
            }
            const localVarPath = `/passwordResetTokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllPasswordResetToken
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPasswordResetTokenUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/passwordResetTokens`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdPasswordResetToken
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdPasswordResetTokenUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdPasswordResetTokenUsingGET.');
            }
            const localVarPath = `/passwordResetTokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByTokenAndExpiryDateIsGreaterThanEqualPasswordResetToken
         * @param {Date} [now] now
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTokenAndExpiryDateIsGreaterThanEqualPasswordResetTokenUsingGET(now?: Date, token?: string, options: any = {}): FetchArgs {
            const localVarPath = `/passwordResetTokens/search/findByTokenAndExpiryDateIsGreaterThanEqual`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (now !== undefined) {
                localVarQueryParameter['now'] = (now as any).toISOString();
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByTokenPasswordResetToken
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTokenPasswordResetTokenUsingGET(token?: string, options: any = {}): FetchArgs {
            const localVarPath = `/passwordResetTokens/search/findByToken`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByUsernameIgnoreCasePasswordResetToken
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUsernameIgnoreCasePasswordResetTokenUsingGET(username?: string, options: any = {}): FetchArgs {
            const localVarPath = `/passwordResetTokens/search/findByUsernameIgnoreCase`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePasswordResetToken
         * @param {PasswordResetToken} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePasswordResetTokenUsingPATCH(body: PasswordResetToken, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePasswordResetTokenUsingPATCH.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling savePasswordResetTokenUsingPATCH.');
            }
            const localVarPath = `/passwordResetTokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PasswordResetToken" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePasswordResetToken
         * @param {PasswordResetToken} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePasswordResetTokenUsingPOST(body: PasswordResetToken, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePasswordResetTokenUsingPOST.');
            }
            const localVarPath = `/passwordResetTokens`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PasswordResetToken" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePasswordResetToken
         * @param {PasswordResetToken} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePasswordResetTokenUsingPUT(body: PasswordResetToken, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePasswordResetTokenUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling savePasswordResetTokenUsingPUT.');
            }
            const localVarPath = `/passwordResetTokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PasswordResetToken" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordResetTokenEntityApi - functional programming interface
 * @export
 */
export const PasswordResetTokenEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAllByExpiryDateIsLessThanEqualPasswordResetToken
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByExpiryDateIsLessThanEqualPasswordResetTokenUsingGET(now?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PasswordResetTokenEntityApiFetchParamCreator(configuration).deleteAllByExpiryDateIsLessThanEqualPasswordResetTokenUsingGET(now, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAllByUsernameIgnoreCasePasswordResetToken
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUsernameIgnoreCasePasswordResetTokenUsingGET(username?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PasswordResetTokenEntityApiFetchParamCreator(configuration).deleteAllByUsernameIgnoreCasePasswordResetTokenUsingGET(username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteByTokenPasswordResetToken
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByTokenPasswordResetTokenUsingGET(token?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PasswordResetTokenEntityApiFetchParamCreator(configuration).deleteByTokenPasswordResetTokenUsingGET(token, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deletePasswordResetToken
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePasswordResetTokenUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PasswordResetTokenEntityApiFetchParamCreator(configuration).deletePasswordResetTokenUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllPasswordResetToken
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPasswordResetTokenUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelPasswordResetToken> {
            const localVarFetchArgs = PasswordResetTokenEntityApiFetchParamCreator(configuration).findAllPasswordResetTokenUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByIdPasswordResetToken
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdPasswordResetTokenUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelPasswordResetToken> {
            const localVarFetchArgs = PasswordResetTokenEntityApiFetchParamCreator(configuration).findByIdPasswordResetTokenUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByTokenAndExpiryDateIsGreaterThanEqualPasswordResetToken
         * @param {Date} [now] now
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTokenAndExpiryDateIsGreaterThanEqualPasswordResetTokenUsingGET(now?: Date, token?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelPasswordResetToken> {
            const localVarFetchArgs = PasswordResetTokenEntityApiFetchParamCreator(configuration).findByTokenAndExpiryDateIsGreaterThanEqualPasswordResetTokenUsingGET(now, token, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByTokenPasswordResetToken
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTokenPasswordResetTokenUsingGET(token?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelPasswordResetToken> {
            const localVarFetchArgs = PasswordResetTokenEntityApiFetchParamCreator(configuration).findByTokenPasswordResetTokenUsingGET(token, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByUsernameIgnoreCasePasswordResetToken
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUsernameIgnoreCasePasswordResetTokenUsingGET(username?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelPasswordResetToken> {
            const localVarFetchArgs = PasswordResetTokenEntityApiFetchParamCreator(configuration).findByUsernameIgnoreCasePasswordResetTokenUsingGET(username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary savePasswordResetToken
         * @param {PasswordResetToken} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePasswordResetTokenUsingPATCH(body: PasswordResetToken, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelPasswordResetToken> {
            const localVarFetchArgs = PasswordResetTokenEntityApiFetchParamCreator(configuration).savePasswordResetTokenUsingPATCH(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary savePasswordResetToken
         * @param {PasswordResetToken} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePasswordResetTokenUsingPOST(body: PasswordResetToken, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelPasswordResetToken> {
            const localVarFetchArgs = PasswordResetTokenEntityApiFetchParamCreator(configuration).savePasswordResetTokenUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary savePasswordResetToken
         * @param {PasswordResetToken} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePasswordResetTokenUsingPUT(body: PasswordResetToken, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelPasswordResetToken> {
            const localVarFetchArgs = PasswordResetTokenEntityApiFetchParamCreator(configuration).savePasswordResetTokenUsingPUT(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PasswordResetTokenEntityApi - factory interface
 * @export
 */
export const PasswordResetTokenEntityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteAllByExpiryDateIsLessThanEqualPasswordResetToken
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByExpiryDateIsLessThanEqualPasswordResetTokenUsingGET(now?: Date, options?: any) {
            return PasswordResetTokenEntityApiFp(configuration).deleteAllByExpiryDateIsLessThanEqualPasswordResetTokenUsingGET(now, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAllByUsernameIgnoreCasePasswordResetToken
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUsernameIgnoreCasePasswordResetTokenUsingGET(username?: string, options?: any) {
            return PasswordResetTokenEntityApiFp(configuration).deleteAllByUsernameIgnoreCasePasswordResetTokenUsingGET(username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteByTokenPasswordResetToken
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByTokenPasswordResetTokenUsingGET(token?: string, options?: any) {
            return PasswordResetTokenEntityApiFp(configuration).deleteByTokenPasswordResetTokenUsingGET(token, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deletePasswordResetToken
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePasswordResetTokenUsingDELETE(id: string, options?: any) {
            return PasswordResetTokenEntityApiFp(configuration).deletePasswordResetTokenUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllPasswordResetToken
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPasswordResetTokenUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return PasswordResetTokenEntityApiFp(configuration).findAllPasswordResetTokenUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByIdPasswordResetToken
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdPasswordResetTokenUsingGET(id: string, options?: any) {
            return PasswordResetTokenEntityApiFp(configuration).findByIdPasswordResetTokenUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByTokenAndExpiryDateIsGreaterThanEqualPasswordResetToken
         * @param {Date} [now] now
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTokenAndExpiryDateIsGreaterThanEqualPasswordResetTokenUsingGET(now?: Date, token?: string, options?: any) {
            return PasswordResetTokenEntityApiFp(configuration).findByTokenAndExpiryDateIsGreaterThanEqualPasswordResetTokenUsingGET(now, token, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByTokenPasswordResetToken
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTokenPasswordResetTokenUsingGET(token?: string, options?: any) {
            return PasswordResetTokenEntityApiFp(configuration).findByTokenPasswordResetTokenUsingGET(token, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByUsernameIgnoreCasePasswordResetToken
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUsernameIgnoreCasePasswordResetTokenUsingGET(username?: string, options?: any) {
            return PasswordResetTokenEntityApiFp(configuration).findByUsernameIgnoreCasePasswordResetTokenUsingGET(username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary savePasswordResetToken
         * @param {PasswordResetToken} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePasswordResetTokenUsingPATCH(body: PasswordResetToken, id: string, options?: any) {
            return PasswordResetTokenEntityApiFp(configuration).savePasswordResetTokenUsingPATCH(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary savePasswordResetToken
         * @param {PasswordResetToken} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePasswordResetTokenUsingPOST(body: PasswordResetToken, options?: any) {
            return PasswordResetTokenEntityApiFp(configuration).savePasswordResetTokenUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary savePasswordResetToken
         * @param {PasswordResetToken} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePasswordResetTokenUsingPUT(body: PasswordResetToken, id: string, options?: any) {
            return PasswordResetTokenEntityApiFp(configuration).savePasswordResetTokenUsingPUT(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * PasswordResetTokenEntityApi - object-oriented interface
 * @export
 * @class PasswordResetTokenEntityApi
 * @extends {BaseAPI}
 */
export class PasswordResetTokenEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteAllByExpiryDateIsLessThanEqualPasswordResetToken
     * @param {Date} [now] now
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetTokenEntityApi
     */
    public deleteAllByExpiryDateIsLessThanEqualPasswordResetTokenUsingGET(now?: Date, options?: any) {
        return PasswordResetTokenEntityApiFp(this.configuration).deleteAllByExpiryDateIsLessThanEqualPasswordResetTokenUsingGET(now, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAllByUsernameIgnoreCasePasswordResetToken
     * @param {string} [username] username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetTokenEntityApi
     */
    public deleteAllByUsernameIgnoreCasePasswordResetTokenUsingGET(username?: string, options?: any) {
        return PasswordResetTokenEntityApiFp(this.configuration).deleteAllByUsernameIgnoreCasePasswordResetTokenUsingGET(username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteByTokenPasswordResetToken
     * @param {string} [token] token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetTokenEntityApi
     */
    public deleteByTokenPasswordResetTokenUsingGET(token?: string, options?: any) {
        return PasswordResetTokenEntityApiFp(this.configuration).deleteByTokenPasswordResetTokenUsingGET(token, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deletePasswordResetToken
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetTokenEntityApi
     */
    public deletePasswordResetTokenUsingDELETE(id: string, options?: any) {
        return PasswordResetTokenEntityApiFp(this.configuration).deletePasswordResetTokenUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllPasswordResetToken
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetTokenEntityApi
     */
    public findAllPasswordResetTokenUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return PasswordResetTokenEntityApiFp(this.configuration).findAllPasswordResetTokenUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByIdPasswordResetToken
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetTokenEntityApi
     */
    public findByIdPasswordResetTokenUsingGET(id: string, options?: any) {
        return PasswordResetTokenEntityApiFp(this.configuration).findByIdPasswordResetTokenUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByTokenAndExpiryDateIsGreaterThanEqualPasswordResetToken
     * @param {Date} [now] now
     * @param {string} [token] token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetTokenEntityApi
     */
    public findByTokenAndExpiryDateIsGreaterThanEqualPasswordResetTokenUsingGET(now?: Date, token?: string, options?: any) {
        return PasswordResetTokenEntityApiFp(this.configuration).findByTokenAndExpiryDateIsGreaterThanEqualPasswordResetTokenUsingGET(now, token, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByTokenPasswordResetToken
     * @param {string} [token] token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetTokenEntityApi
     */
    public findByTokenPasswordResetTokenUsingGET(token?: string, options?: any) {
        return PasswordResetTokenEntityApiFp(this.configuration).findByTokenPasswordResetTokenUsingGET(token, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByUsernameIgnoreCasePasswordResetToken
     * @param {string} [username] username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetTokenEntityApi
     */
    public findByUsernameIgnoreCasePasswordResetTokenUsingGET(username?: string, options?: any) {
        return PasswordResetTokenEntityApiFp(this.configuration).findByUsernameIgnoreCasePasswordResetTokenUsingGET(username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary savePasswordResetToken
     * @param {PasswordResetToken} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetTokenEntityApi
     */
    public savePasswordResetTokenUsingPATCH(body: PasswordResetToken, id: string, options?: any) {
        return PasswordResetTokenEntityApiFp(this.configuration).savePasswordResetTokenUsingPATCH(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary savePasswordResetToken
     * @param {PasswordResetToken} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetTokenEntityApi
     */
    public savePasswordResetTokenUsingPOST(body: PasswordResetToken, options?: any) {
        return PasswordResetTokenEntityApiFp(this.configuration).savePasswordResetTokenUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary savePasswordResetToken
     * @param {PasswordResetToken} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordResetTokenEntityApi
     */
    public savePasswordResetTokenUsingPUT(body: PasswordResetToken, id: string, options?: any) {
        return PasswordResetTokenEntityApiFp(this.configuration).savePasswordResetTokenUsingPUT(body, id, options)(this.fetch, this.basePath);
    }

}

/**
 * PaymentControllerApi - fetch parameter creator
 * @export
 */
export const PaymentControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary cancelCustomerSubscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelCustomerSubscriptionUsingDELETE(options: any = {}): FetchArgs {
            const localVarPath = `/v1/payments/customer/subscribe`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary createCustomer
         * @param {CustomerRequest} customerRequest customerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerUsingPOST(customerRequest: CustomerRequest, options: any = {}): FetchArgs {
            // verify required parameter 'customerRequest' is not null or undefined
            if (customerRequest === null || customerRequest === undefined) {
                throw new RequiredError('customerRequest','Required parameter customerRequest was null or undefined when calling createCustomerUsingPOST.');
            }
            const localVarPath = `/v1/payments/customer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CustomerRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(customerRequest || {}) : (customerRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteCustomer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerUsingDELETE(options: any = {}): FetchArgs {
            const localVarPath = `/v1/payments/customer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getStripePublicKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripePublicKeyUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/payments/pk`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary subscribeCustomer
         * @param {CustomerSubscribeRequest} customerSubscribeRequest customerSubscribeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeCustomerUsingPOST(customerSubscribeRequest: CustomerSubscribeRequest, options: any = {}): FetchArgs {
            // verify required parameter 'customerSubscribeRequest' is not null or undefined
            if (customerSubscribeRequest === null || customerSubscribeRequest === undefined) {
                throw new RequiredError('customerSubscribeRequest','Required parameter customerSubscribeRequest was null or undefined when calling subscribeCustomerUsingPOST.');
            }
            const localVarPath = `/v1/payments/customer/subscribe`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CustomerSubscribeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(customerSubscribeRequest || {}) : (customerSubscribeRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateCustomerSubscription
         * @param {CustomerSubscribeRequest} customerSubscribeRequest customerSubscribeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerSubscriptionUsingPUT(customerSubscribeRequest: CustomerSubscribeRequest, options: any = {}): FetchArgs {
            // verify required parameter 'customerSubscribeRequest' is not null or undefined
            if (customerSubscribeRequest === null || customerSubscribeRequest === undefined) {
                throw new RequiredError('customerSubscribeRequest','Required parameter customerSubscribeRequest was null or undefined when calling updateCustomerSubscriptionUsingPUT.');
            }
            const localVarPath = `/v1/payments/customer/subscribe`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CustomerSubscribeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(customerSubscribeRequest || {}) : (customerSubscribeRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateCustomer
         * @param {CustomerRequest} customerRequest customerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerUsingPUT(customerRequest: CustomerRequest, options: any = {}): FetchArgs {
            // verify required parameter 'customerRequest' is not null or undefined
            if (customerRequest === null || customerRequest === undefined) {
                throw new RequiredError('customerRequest','Required parameter customerRequest was null or undefined when calling updateCustomerUsingPUT.');
            }
            const localVarPath = `/v1/payments/customer`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CustomerRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(customerRequest || {}) : (customerRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentControllerApi - functional programming interface
 * @export
 */
export const PaymentControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary cancelCustomerSubscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelCustomerSubscriptionUsingDELETE(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentControllerApiFetchParamCreator(configuration).cancelCustomerSubscriptionUsingDELETE(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary createCustomer
         * @param {CustomerRequest} customerRequest customerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerUsingPOST(customerRequest: CustomerRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentControllerApiFetchParamCreator(configuration).createCustomerUsingPOST(customerRequest, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteCustomer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerUsingDELETE(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentControllerApiFetchParamCreator(configuration).deleteCustomerUsingDELETE(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getStripePublicKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripePublicKeyUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<string> {
            const localVarFetchArgs = PaymentControllerApiFetchParamCreator(configuration).getStripePublicKeyUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary subscribeCustomer
         * @param {CustomerSubscribeRequest} customerSubscribeRequest customerSubscribeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeCustomerUsingPOST(customerSubscribeRequest: CustomerSubscribeRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentControllerApiFetchParamCreator(configuration).subscribeCustomerUsingPOST(customerSubscribeRequest, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateCustomerSubscription
         * @param {CustomerSubscribeRequest} customerSubscribeRequest customerSubscribeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerSubscriptionUsingPUT(customerSubscribeRequest: CustomerSubscribeRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentControllerApiFetchParamCreator(configuration).updateCustomerSubscriptionUsingPUT(customerSubscribeRequest, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateCustomer
         * @param {CustomerRequest} customerRequest customerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerUsingPUT(customerRequest: CustomerRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentControllerApiFetchParamCreator(configuration).updateCustomerUsingPUT(customerRequest, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PaymentControllerApi - factory interface
 * @export
 */
export const PaymentControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary cancelCustomerSubscription
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelCustomerSubscriptionUsingDELETE(options?: any) {
            return PaymentControllerApiFp(configuration).cancelCustomerSubscriptionUsingDELETE(options)(fetch, basePath);
        },
        /**
         * 
         * @summary createCustomer
         * @param {CustomerRequest} customerRequest customerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCustomerUsingPOST(customerRequest: CustomerRequest, options?: any) {
            return PaymentControllerApiFp(configuration).createCustomerUsingPOST(customerRequest, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteCustomer
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCustomerUsingDELETE(options?: any) {
            return PaymentControllerApiFp(configuration).deleteCustomerUsingDELETE(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getStripePublicKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStripePublicKeyUsingGET(options?: any) {
            return PaymentControllerApiFp(configuration).getStripePublicKeyUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary subscribeCustomer
         * @param {CustomerSubscribeRequest} customerSubscribeRequest customerSubscribeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        subscribeCustomerUsingPOST(customerSubscribeRequest: CustomerSubscribeRequest, options?: any) {
            return PaymentControllerApiFp(configuration).subscribeCustomerUsingPOST(customerSubscribeRequest, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateCustomerSubscription
         * @param {CustomerSubscribeRequest} customerSubscribeRequest customerSubscribeRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerSubscriptionUsingPUT(customerSubscribeRequest: CustomerSubscribeRequest, options?: any) {
            return PaymentControllerApiFp(configuration).updateCustomerSubscriptionUsingPUT(customerSubscribeRequest, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateCustomer
         * @param {CustomerRequest} customerRequest customerRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateCustomerUsingPUT(customerRequest: CustomerRequest, options?: any) {
            return PaymentControllerApiFp(configuration).updateCustomerUsingPUT(customerRequest, options)(fetch, basePath);
        },
    };
};

/**
 * PaymentControllerApi - object-oriented interface
 * @export
 * @class PaymentControllerApi
 * @extends {BaseAPI}
 */
export class PaymentControllerApi extends BaseAPI {
    /**
     * 
     * @summary cancelCustomerSubscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public cancelCustomerSubscriptionUsingDELETE(options?: any) {
        return PaymentControllerApiFp(this.configuration).cancelCustomerSubscriptionUsingDELETE(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary createCustomer
     * @param {CustomerRequest} customerRequest customerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public createCustomerUsingPOST(customerRequest: CustomerRequest, options?: any) {
        return PaymentControllerApiFp(this.configuration).createCustomerUsingPOST(customerRequest, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteCustomer
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public deleteCustomerUsingDELETE(options?: any) {
        return PaymentControllerApiFp(this.configuration).deleteCustomerUsingDELETE(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getStripePublicKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public getStripePublicKeyUsingGET(options?: any) {
        return PaymentControllerApiFp(this.configuration).getStripePublicKeyUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary subscribeCustomer
     * @param {CustomerSubscribeRequest} customerSubscribeRequest customerSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public subscribeCustomerUsingPOST(customerSubscribeRequest: CustomerSubscribeRequest, options?: any) {
        return PaymentControllerApiFp(this.configuration).subscribeCustomerUsingPOST(customerSubscribeRequest, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateCustomerSubscription
     * @param {CustomerSubscribeRequest} customerSubscribeRequest customerSubscribeRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public updateCustomerSubscriptionUsingPUT(customerSubscribeRequest: CustomerSubscribeRequest, options?: any) {
        return PaymentControllerApiFp(this.configuration).updateCustomerSubscriptionUsingPUT(customerSubscribeRequest, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateCustomer
     * @param {CustomerRequest} customerRequest customerRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentControllerApi
     */
    public updateCustomerUsingPUT(customerRequest: CustomerRequest, options?: any) {
        return PaymentControllerApiFp(this.configuration).updateCustomerUsingPUT(customerRequest, options)(this.fetch, this.basePath);
    }

}

/**
 * PaymentPlanControllerApi - fetch parameter creator
 * @export
 */
export const PaymentPlanControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getMyPaymentPlan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPaymentPlanUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/payment-plans/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPaymentPlansByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentPlansByCriteriaUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/payment-plans`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updatePaymentPlan
         * @param {string} orgId orgId
         * @param {'DEFAULT' | 'ENTERPRISE' | 'ENTERPRISE_PLUS_SCANNING' | 'STARTER'} type type
         * @param {number} validDays validDays
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentPlanUsingPUT(orgId: string, type: 'DEFAULT' | 'ENTERPRISE' | 'ENTERPRISE_PLUS_SCANNING' | 'STARTER', validDays: number, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling updatePaymentPlanUsingPUT.');
            }
            // verify required parameter 'type' is not null or undefined
            if (type === null || type === undefined) {
                throw new RequiredError('type','Required parameter type was null or undefined when calling updatePaymentPlanUsingPUT.');
            }
            // verify required parameter 'validDays' is not null or undefined
            if (validDays === null || validDays === undefined) {
                throw new RequiredError('validDays','Required parameter validDays was null or undefined when calling updatePaymentPlanUsingPUT.');
            }
            const localVarPath = `/v1/payment-plans`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (validDays !== undefined) {
                localVarQueryParameter['validDays'] = validDays;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentPlanControllerApi - functional programming interface
 * @export
 */
export const PaymentPlanControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary getMyPaymentPlan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPaymentPlanUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentPlan> {
            const localVarFetchArgs = PaymentPlanControllerApiFetchParamCreator(configuration).getMyPaymentPlanUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getPaymentPlansByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentPlansByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PagePaymentPlan> {
            const localVarFetchArgs = PaymentPlanControllerApiFetchParamCreator(configuration).getPaymentPlansByCriteriaUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updatePaymentPlan
         * @param {string} orgId orgId
         * @param {'DEFAULT' | 'ENTERPRISE' | 'ENTERPRISE_PLUS_SCANNING' | 'STARTER'} type type
         * @param {number} validDays validDays
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentPlanUsingPUT(orgId: string, type: 'DEFAULT' | 'ENTERPRISE' | 'ENTERPRISE_PLUS_SCANNING' | 'STARTER', validDays: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PaymentPlan> {
            const localVarFetchArgs = PaymentPlanControllerApiFetchParamCreator(configuration).updatePaymentPlanUsingPUT(orgId, type, validDays, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PaymentPlanControllerApi - factory interface
 * @export
 */
export const PaymentPlanControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary getMyPaymentPlan
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyPaymentPlanUsingGET(options?: any) {
            return PaymentPlanControllerApiFp(configuration).getMyPaymentPlanUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getPaymentPlansByCriteria
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPaymentPlansByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return PaymentPlanControllerApiFp(configuration).getPaymentPlansByCriteriaUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updatePaymentPlan
         * @param {string} orgId orgId
         * @param {'DEFAULT' | 'ENTERPRISE' | 'ENTERPRISE_PLUS_SCANNING' | 'STARTER'} type type
         * @param {number} validDays validDays
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePaymentPlanUsingPUT(orgId: string, type: 'DEFAULT' | 'ENTERPRISE' | 'ENTERPRISE_PLUS_SCANNING' | 'STARTER', validDays: number, options?: any) {
            return PaymentPlanControllerApiFp(configuration).updatePaymentPlanUsingPUT(orgId, type, validDays, options)(fetch, basePath);
        },
    };
};

/**
 * PaymentPlanControllerApi - object-oriented interface
 * @export
 * @class PaymentPlanControllerApi
 * @extends {BaseAPI}
 */
export class PaymentPlanControllerApi extends BaseAPI {
    /**
     * 
     * @summary getMyPaymentPlan
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanControllerApi
     */
    public getMyPaymentPlanUsingGET(options?: any) {
        return PaymentPlanControllerApiFp(this.configuration).getMyPaymentPlanUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getPaymentPlansByCriteria
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanControllerApi
     */
    public getPaymentPlansByCriteriaUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return PaymentPlanControllerApiFp(this.configuration).getPaymentPlansByCriteriaUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updatePaymentPlan
     * @param {string} orgId orgId
     * @param {'DEFAULT' | 'ENTERPRISE' | 'ENTERPRISE_PLUS_SCANNING' | 'STARTER'} type type
     * @param {number} validDays validDays
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanControllerApi
     */
    public updatePaymentPlanUsingPUT(orgId: string, type: 'DEFAULT' | 'ENTERPRISE' | 'ENTERPRISE_PLUS_SCANNING' | 'STARTER', validDays: number, options?: any) {
        return PaymentPlanControllerApiFp(this.configuration).updatePaymentPlanUsingPUT(orgId, type, validDays, options)(this.fetch, this.basePath);
    }

}

/**
 * PaymentPlanEntityApi - fetch parameter creator
 * @export
 */
export const PaymentPlanEntityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteByOrgIdPaymentPlan
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByOrgIdPaymentPlanUsingGET(orgId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/paymentPlans/search/deleteByOrgId`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deletePaymentPlan
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentPlanUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deletePaymentPlanUsingDELETE.');
            }
            const localVarPath = `/paymentPlans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllByCreationDateBetweenAndStripeSubscriptionIdIsNotNullPaymentPlan
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByCreationDateBetweenAndStripeSubscriptionIdIsNotNullPaymentPlanUsingGET(from?: Date, to?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/paymentPlans/search/findAllByCreationDateBetweenAndStripeSubscriptionIdIsNotNull`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllByCreationDateBetweenAndStripeSubscriptionIdIsNullPaymentPlan
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByCreationDateBetweenAndStripeSubscriptionIdIsNullPaymentPlanUsingGET(from?: Date, to?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/paymentPlans/search/findAllByCreationDateBetweenAndStripeSubscriptionIdIsNull`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllByExpirationDateBetweenPaymentPlan
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByExpirationDateBetweenPaymentPlanUsingGET(from?: Date, to?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/paymentPlans/search/findAllByExpirationDateBetween`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any).toISOString();
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllByOrgIdInPaymentPlan
         * @param {Array<string>} [organizations] organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrgIdInPaymentPlanUsingGET(organizations?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/paymentPlans/search/findAllByOrgIdIn`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (organizations) {
                localVarQueryParameter['organizations'] = organizations;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllPaymentPlan
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPaymentPlanUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/paymentPlans`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdPaymentPlan
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdPaymentPlanUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdPaymentPlanUsingGET.');
            }
            const localVarPath = `/paymentPlans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByOrgIdPaymentPlan
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByOrgIdPaymentPlanUsingGET(orgId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/paymentPlans/search/findByOrgId`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (orgId !== undefined) {
                localVarQueryParameter['orgId'] = orgId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePaymentPlan
         * @param {PaymentPlan} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePaymentPlanUsingPATCH(body: PaymentPlan, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePaymentPlanUsingPATCH.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling savePaymentPlanUsingPATCH.');
            }
            const localVarPath = `/paymentPlans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PaymentPlan" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePaymentPlan
         * @param {PaymentPlan} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePaymentPlanUsingPOST(body: PaymentPlan, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePaymentPlanUsingPOST.');
            }
            const localVarPath = `/paymentPlans`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PaymentPlan" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary savePaymentPlan
         * @param {PaymentPlan} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePaymentPlanUsingPUT(body: PaymentPlan, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling savePaymentPlanUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling savePaymentPlanUsingPUT.');
            }
            const localVarPath = `/paymentPlans/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PaymentPlan" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PaymentPlanEntityApi - functional programming interface
 * @export
 */
export const PaymentPlanEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteByOrgIdPaymentPlan
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByOrgIdPaymentPlanUsingGET(orgId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentPlanEntityApiFetchParamCreator(configuration).deleteByOrgIdPaymentPlanUsingGET(orgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deletePaymentPlan
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentPlanUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = PaymentPlanEntityApiFetchParamCreator(configuration).deletePaymentPlanUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllByCreationDateBetweenAndStripeSubscriptionIdIsNotNullPaymentPlan
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByCreationDateBetweenAndStripeSubscriptionIdIsNotNullPaymentPlanUsingGET(from?: Date, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelPaymentPlan> {
            const localVarFetchArgs = PaymentPlanEntityApiFetchParamCreator(configuration).findAllByCreationDateBetweenAndStripeSubscriptionIdIsNotNullPaymentPlanUsingGET(from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllByCreationDateBetweenAndStripeSubscriptionIdIsNullPaymentPlan
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByCreationDateBetweenAndStripeSubscriptionIdIsNullPaymentPlanUsingGET(from?: Date, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelPaymentPlan> {
            const localVarFetchArgs = PaymentPlanEntityApiFetchParamCreator(configuration).findAllByCreationDateBetweenAndStripeSubscriptionIdIsNullPaymentPlanUsingGET(from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllByExpirationDateBetweenPaymentPlan
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByExpirationDateBetweenPaymentPlanUsingGET(from?: Date, to?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelPaymentPlan> {
            const localVarFetchArgs = PaymentPlanEntityApiFetchParamCreator(configuration).findAllByExpirationDateBetweenPaymentPlanUsingGET(from, to, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllByOrgIdInPaymentPlan
         * @param {Array<string>} [organizations] organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrgIdInPaymentPlanUsingGET(organizations?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelPaymentPlan> {
            const localVarFetchArgs = PaymentPlanEntityApiFetchParamCreator(configuration).findAllByOrgIdInPaymentPlanUsingGET(organizations, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllPaymentPlan
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPaymentPlanUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelPaymentPlan> {
            const localVarFetchArgs = PaymentPlanEntityApiFetchParamCreator(configuration).findAllPaymentPlanUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByIdPaymentPlan
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdPaymentPlanUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelPaymentPlan> {
            const localVarFetchArgs = PaymentPlanEntityApiFetchParamCreator(configuration).findByIdPaymentPlanUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByOrgIdPaymentPlan
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByOrgIdPaymentPlanUsingGET(orgId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelPaymentPlan> {
            const localVarFetchArgs = PaymentPlanEntityApiFetchParamCreator(configuration).findByOrgIdPaymentPlanUsingGET(orgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary savePaymentPlan
         * @param {PaymentPlan} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePaymentPlanUsingPATCH(body: PaymentPlan, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelPaymentPlan> {
            const localVarFetchArgs = PaymentPlanEntityApiFetchParamCreator(configuration).savePaymentPlanUsingPATCH(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary savePaymentPlan
         * @param {PaymentPlan} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePaymentPlanUsingPOST(body: PaymentPlan, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelPaymentPlan> {
            const localVarFetchArgs = PaymentPlanEntityApiFetchParamCreator(configuration).savePaymentPlanUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary savePaymentPlan
         * @param {PaymentPlan} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePaymentPlanUsingPUT(body: PaymentPlan, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelPaymentPlan> {
            const localVarFetchArgs = PaymentPlanEntityApiFetchParamCreator(configuration).savePaymentPlanUsingPUT(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PaymentPlanEntityApi - factory interface
 * @export
 */
export const PaymentPlanEntityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteByOrgIdPaymentPlan
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByOrgIdPaymentPlanUsingGET(orgId?: string, options?: any) {
            return PaymentPlanEntityApiFp(configuration).deleteByOrgIdPaymentPlanUsingGET(orgId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deletePaymentPlan
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePaymentPlanUsingDELETE(id: string, options?: any) {
            return PaymentPlanEntityApiFp(configuration).deletePaymentPlanUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllByCreationDateBetweenAndStripeSubscriptionIdIsNotNullPaymentPlan
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByCreationDateBetweenAndStripeSubscriptionIdIsNotNullPaymentPlanUsingGET(from?: Date, to?: Date, options?: any) {
            return PaymentPlanEntityApiFp(configuration).findAllByCreationDateBetweenAndStripeSubscriptionIdIsNotNullPaymentPlanUsingGET(from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllByCreationDateBetweenAndStripeSubscriptionIdIsNullPaymentPlan
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByCreationDateBetweenAndStripeSubscriptionIdIsNullPaymentPlanUsingGET(from?: Date, to?: Date, options?: any) {
            return PaymentPlanEntityApiFp(configuration).findAllByCreationDateBetweenAndStripeSubscriptionIdIsNullPaymentPlanUsingGET(from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllByExpirationDateBetweenPaymentPlan
         * @param {Date} [from] from
         * @param {Date} [to] to
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByExpirationDateBetweenPaymentPlanUsingGET(from?: Date, to?: Date, options?: any) {
            return PaymentPlanEntityApiFp(configuration).findAllByExpirationDateBetweenPaymentPlanUsingGET(from, to, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllByOrgIdInPaymentPlan
         * @param {Array<string>} [organizations] organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrgIdInPaymentPlanUsingGET(organizations?: Array<string>, options?: any) {
            return PaymentPlanEntityApiFp(configuration).findAllByOrgIdInPaymentPlanUsingGET(organizations, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllPaymentPlan
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllPaymentPlanUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return PaymentPlanEntityApiFp(configuration).findAllPaymentPlanUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByIdPaymentPlan
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdPaymentPlanUsingGET(id: string, options?: any) {
            return PaymentPlanEntityApiFp(configuration).findByIdPaymentPlanUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByOrgIdPaymentPlan
         * @param {string} [orgId] orgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByOrgIdPaymentPlanUsingGET(orgId?: string, options?: any) {
            return PaymentPlanEntityApiFp(configuration).findByOrgIdPaymentPlanUsingGET(orgId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary savePaymentPlan
         * @param {PaymentPlan} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePaymentPlanUsingPATCH(body: PaymentPlan, id: string, options?: any) {
            return PaymentPlanEntityApiFp(configuration).savePaymentPlanUsingPATCH(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary savePaymentPlan
         * @param {PaymentPlan} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePaymentPlanUsingPOST(body: PaymentPlan, options?: any) {
            return PaymentPlanEntityApiFp(configuration).savePaymentPlanUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary savePaymentPlan
         * @param {PaymentPlan} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        savePaymentPlanUsingPUT(body: PaymentPlan, id: string, options?: any) {
            return PaymentPlanEntityApiFp(configuration).savePaymentPlanUsingPUT(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * PaymentPlanEntityApi - object-oriented interface
 * @export
 * @class PaymentPlanEntityApi
 * @extends {BaseAPI}
 */
export class PaymentPlanEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteByOrgIdPaymentPlan
     * @param {string} [orgId] orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanEntityApi
     */
    public deleteByOrgIdPaymentPlanUsingGET(orgId?: string, options?: any) {
        return PaymentPlanEntityApiFp(this.configuration).deleteByOrgIdPaymentPlanUsingGET(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deletePaymentPlan
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanEntityApi
     */
    public deletePaymentPlanUsingDELETE(id: string, options?: any) {
        return PaymentPlanEntityApiFp(this.configuration).deletePaymentPlanUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllByCreationDateBetweenAndStripeSubscriptionIdIsNotNullPaymentPlan
     * @param {Date} [from] from
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanEntityApi
     */
    public findAllByCreationDateBetweenAndStripeSubscriptionIdIsNotNullPaymentPlanUsingGET(from?: Date, to?: Date, options?: any) {
        return PaymentPlanEntityApiFp(this.configuration).findAllByCreationDateBetweenAndStripeSubscriptionIdIsNotNullPaymentPlanUsingGET(from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllByCreationDateBetweenAndStripeSubscriptionIdIsNullPaymentPlan
     * @param {Date} [from] from
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanEntityApi
     */
    public findAllByCreationDateBetweenAndStripeSubscriptionIdIsNullPaymentPlanUsingGET(from?: Date, to?: Date, options?: any) {
        return PaymentPlanEntityApiFp(this.configuration).findAllByCreationDateBetweenAndStripeSubscriptionIdIsNullPaymentPlanUsingGET(from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllByExpirationDateBetweenPaymentPlan
     * @param {Date} [from] from
     * @param {Date} [to] to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanEntityApi
     */
    public findAllByExpirationDateBetweenPaymentPlanUsingGET(from?: Date, to?: Date, options?: any) {
        return PaymentPlanEntityApiFp(this.configuration).findAllByExpirationDateBetweenPaymentPlanUsingGET(from, to, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllByOrgIdInPaymentPlan
     * @param {Array<string>} [organizations] organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanEntityApi
     */
    public findAllByOrgIdInPaymentPlanUsingGET(organizations?: Array<string>, options?: any) {
        return PaymentPlanEntityApiFp(this.configuration).findAllByOrgIdInPaymentPlanUsingGET(organizations, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllPaymentPlan
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanEntityApi
     */
    public findAllPaymentPlanUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return PaymentPlanEntityApiFp(this.configuration).findAllPaymentPlanUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByIdPaymentPlan
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanEntityApi
     */
    public findByIdPaymentPlanUsingGET(id: string, options?: any) {
        return PaymentPlanEntityApiFp(this.configuration).findByIdPaymentPlanUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByOrgIdPaymentPlan
     * @param {string} [orgId] orgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanEntityApi
     */
    public findByOrgIdPaymentPlanUsingGET(orgId?: string, options?: any) {
        return PaymentPlanEntityApiFp(this.configuration).findByOrgIdPaymentPlanUsingGET(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary savePaymentPlan
     * @param {PaymentPlan} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanEntityApi
     */
    public savePaymentPlanUsingPATCH(body: PaymentPlan, id: string, options?: any) {
        return PaymentPlanEntityApiFp(this.configuration).savePaymentPlanUsingPATCH(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary savePaymentPlan
     * @param {PaymentPlan} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanEntityApi
     */
    public savePaymentPlanUsingPOST(body: PaymentPlan, options?: any) {
        return PaymentPlanEntityApiFp(this.configuration).savePaymentPlanUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary savePaymentPlan
     * @param {PaymentPlan} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PaymentPlanEntityApi
     */
    public savePaymentPlanUsingPUT(body: PaymentPlan, id: string, options?: any) {
        return PaymentPlanEntityApiFp(this.configuration).savePaymentPlanUsingPUT(body, id, options)(this.fetch, this.basePath);
    }

}

/**
 * ProfileControllerApi - fetch parameter creator
 * @export
 */
export const ProfileControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary listAllFormsOfMetadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllFormsOfMetadataUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/profile`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary profileOptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileOptionsUsingOPTIONS(options: any = {}): FetchArgs {
            const localVarPath = `/profile`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'OPTIONS' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProfileControllerApi - functional programming interface
 * @export
 */
export const ProfileControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary listAllFormsOfMetadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllFormsOfMetadataUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<RepresentationModelobject> {
            const localVarFetchArgs = ProfileControllerApiFetchParamCreator(configuration).listAllFormsOfMetadataUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary profileOptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileOptionsUsingOPTIONS(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
            const localVarFetchArgs = ProfileControllerApiFetchParamCreator(configuration).profileOptionsUsingOPTIONS(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ProfileControllerApi - factory interface
 * @export
 */
export const ProfileControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary listAllFormsOfMetadata
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllFormsOfMetadataUsingGET(options?: any) {
            return ProfileControllerApiFp(configuration).listAllFormsOfMetadataUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary profileOptions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        profileOptionsUsingOPTIONS(options?: any) {
            return ProfileControllerApiFp(configuration).profileOptionsUsingOPTIONS(options)(fetch, basePath);
        },
    };
};

/**
 * ProfileControllerApi - object-oriented interface
 * @export
 * @class ProfileControllerApi
 * @extends {BaseAPI}
 */
export class ProfileControllerApi extends BaseAPI {
    /**
     * 
     * @summary listAllFormsOfMetadata
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public listAllFormsOfMetadataUsingGET(options?: any) {
        return ProfileControllerApiFp(this.configuration).listAllFormsOfMetadataUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary profileOptions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProfileControllerApi
     */
    public profileOptionsUsingOPTIONS(options?: any) {
        return ProfileControllerApiFp(this.configuration).profileOptionsUsingOPTIONS(options)(this.fetch, this.basePath);
    }

}

/**
 * SoonExpiringSubscriptionsControllerApi - fetch parameter creator
 * @export
 */
export const SoonExpiringSubscriptionsControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary notifyForExpiringOrganizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyForExpiringOrganizationsUsingPOST(options: any = {}): FetchArgs {
            const localVarPath = `/v1/expiring-subscriptions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SoonExpiringSubscriptionsControllerApi - functional programming interface
 * @export
 */
export const SoonExpiringSubscriptionsControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary notifyForExpiringOrganizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyForExpiringOrganizationsUsingPOST(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = SoonExpiringSubscriptionsControllerApiFetchParamCreator(configuration).notifyForExpiringOrganizationsUsingPOST(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SoonExpiringSubscriptionsControllerApi - factory interface
 * @export
 */
export const SoonExpiringSubscriptionsControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary notifyForExpiringOrganizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notifyForExpiringOrganizationsUsingPOST(options?: any) {
            return SoonExpiringSubscriptionsControllerApiFp(configuration).notifyForExpiringOrganizationsUsingPOST(options)(fetch, basePath);
        },
    };
};

/**
 * SoonExpiringSubscriptionsControllerApi - object-oriented interface
 * @export
 * @class SoonExpiringSubscriptionsControllerApi
 * @extends {BaseAPI}
 */
export class SoonExpiringSubscriptionsControllerApi extends BaseAPI {
    /**
     * 
     * @summary notifyForExpiringOrganizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SoonExpiringSubscriptionsControllerApi
     */
    public notifyForExpiringOrganizationsUsingPOST(options?: any) {
        return SoonExpiringSubscriptionsControllerApiFp(this.configuration).notifyForExpiringOrganizationsUsingPOST(options)(this.fetch, this.basePath);
    }

}

/**
 * TokenSigningKeyEntityApi - fetch parameter creator
 * @export
 */
export const TokenSigningKeyEntityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteTokenSigningKey
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTokenSigningKeyUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteTokenSigningKeyUsingDELETE.');
            }
            const localVarPath = `/tokenSigningKeys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllByOrderByIdTokenSigningKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrderByIdTokenSigningKeyUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/tokenSigningKeys/search/findAllByOrderById`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllTokenSigningKey
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTokenSigningKeyUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/tokenSigningKeys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdTokenSigningKey
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdTokenSigningKeyUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdTokenSigningKeyUsingGET.');
            }
            const localVarPath = `/tokenSigningKeys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveTokenSigningKey
         * @param {TokenSigningKey} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTokenSigningKeyUsingPATCH(body: TokenSigningKey, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveTokenSigningKeyUsingPATCH.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveTokenSigningKeyUsingPATCH.');
            }
            const localVarPath = `/tokenSigningKeys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TokenSigningKey" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveTokenSigningKey
         * @param {TokenSigningKey} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTokenSigningKeyUsingPOST(body: TokenSigningKey, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveTokenSigningKeyUsingPOST.');
            }
            const localVarPath = `/tokenSigningKeys`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TokenSigningKey" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveTokenSigningKey
         * @param {TokenSigningKey} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTokenSigningKeyUsingPUT(body: TokenSigningKey, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveTokenSigningKeyUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveTokenSigningKeyUsingPUT.');
            }
            const localVarPath = `/tokenSigningKeys/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"TokenSigningKey" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TokenSigningKeyEntityApi - functional programming interface
 * @export
 */
export const TokenSigningKeyEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteTokenSigningKey
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTokenSigningKeyUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TokenSigningKeyEntityApiFetchParamCreator(configuration).deleteTokenSigningKeyUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllByOrderByIdTokenSigningKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrderByIdTokenSigningKeyUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelTokenSigningKey> {
            const localVarFetchArgs = TokenSigningKeyEntityApiFetchParamCreator(configuration).findAllByOrderByIdTokenSigningKeyUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllTokenSigningKey
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTokenSigningKeyUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelTokenSigningKey> {
            const localVarFetchArgs = TokenSigningKeyEntityApiFetchParamCreator(configuration).findAllTokenSigningKeyUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByIdTokenSigningKey
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdTokenSigningKeyUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelTokenSigningKey> {
            const localVarFetchArgs = TokenSigningKeyEntityApiFetchParamCreator(configuration).findByIdTokenSigningKeyUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveTokenSigningKey
         * @param {TokenSigningKey} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTokenSigningKeyUsingPATCH(body: TokenSigningKey, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelTokenSigningKey> {
            const localVarFetchArgs = TokenSigningKeyEntityApiFetchParamCreator(configuration).saveTokenSigningKeyUsingPATCH(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveTokenSigningKey
         * @param {TokenSigningKey} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTokenSigningKeyUsingPOST(body: TokenSigningKey, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelTokenSigningKey> {
            const localVarFetchArgs = TokenSigningKeyEntityApiFetchParamCreator(configuration).saveTokenSigningKeyUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveTokenSigningKey
         * @param {TokenSigningKey} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTokenSigningKeyUsingPUT(body: TokenSigningKey, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelTokenSigningKey> {
            const localVarFetchArgs = TokenSigningKeyEntityApiFetchParamCreator(configuration).saveTokenSigningKeyUsingPUT(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TokenSigningKeyEntityApi - factory interface
 * @export
 */
export const TokenSigningKeyEntityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteTokenSigningKey
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteTokenSigningKeyUsingDELETE(id: string, options?: any) {
            return TokenSigningKeyEntityApiFp(configuration).deleteTokenSigningKeyUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllByOrderByIdTokenSigningKey
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrderByIdTokenSigningKeyUsingGET(options?: any) {
            return TokenSigningKeyEntityApiFp(configuration).findAllByOrderByIdTokenSigningKeyUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllTokenSigningKey
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllTokenSigningKeyUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return TokenSigningKeyEntityApiFp(configuration).findAllTokenSigningKeyUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByIdTokenSigningKey
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdTokenSigningKeyUsingGET(id: string, options?: any) {
            return TokenSigningKeyEntityApiFp(configuration).findByIdTokenSigningKeyUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveTokenSigningKey
         * @param {TokenSigningKey} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTokenSigningKeyUsingPATCH(body: TokenSigningKey, id: string, options?: any) {
            return TokenSigningKeyEntityApiFp(configuration).saveTokenSigningKeyUsingPATCH(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveTokenSigningKey
         * @param {TokenSigningKey} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTokenSigningKeyUsingPOST(body: TokenSigningKey, options?: any) {
            return TokenSigningKeyEntityApiFp(configuration).saveTokenSigningKeyUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveTokenSigningKey
         * @param {TokenSigningKey} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveTokenSigningKeyUsingPUT(body: TokenSigningKey, id: string, options?: any) {
            return TokenSigningKeyEntityApiFp(configuration).saveTokenSigningKeyUsingPUT(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * TokenSigningKeyEntityApi - object-oriented interface
 * @export
 * @class TokenSigningKeyEntityApi
 * @extends {BaseAPI}
 */
export class TokenSigningKeyEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteTokenSigningKey
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenSigningKeyEntityApi
     */
    public deleteTokenSigningKeyUsingDELETE(id: string, options?: any) {
        return TokenSigningKeyEntityApiFp(this.configuration).deleteTokenSigningKeyUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllByOrderByIdTokenSigningKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenSigningKeyEntityApi
     */
    public findAllByOrderByIdTokenSigningKeyUsingGET(options?: any) {
        return TokenSigningKeyEntityApiFp(this.configuration).findAllByOrderByIdTokenSigningKeyUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllTokenSigningKey
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenSigningKeyEntityApi
     */
    public findAllTokenSigningKeyUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return TokenSigningKeyEntityApiFp(this.configuration).findAllTokenSigningKeyUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByIdTokenSigningKey
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenSigningKeyEntityApi
     */
    public findByIdTokenSigningKeyUsingGET(id: string, options?: any) {
        return TokenSigningKeyEntityApiFp(this.configuration).findByIdTokenSigningKeyUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveTokenSigningKey
     * @param {TokenSigningKey} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenSigningKeyEntityApi
     */
    public saveTokenSigningKeyUsingPATCH(body: TokenSigningKey, id: string, options?: any) {
        return TokenSigningKeyEntityApiFp(this.configuration).saveTokenSigningKeyUsingPATCH(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveTokenSigningKey
     * @param {TokenSigningKey} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenSigningKeyEntityApi
     */
    public saveTokenSigningKeyUsingPOST(body: TokenSigningKey, options?: any) {
        return TokenSigningKeyEntityApiFp(this.configuration).saveTokenSigningKeyUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveTokenSigningKey
     * @param {TokenSigningKey} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TokenSigningKeyEntityApi
     */
    public saveTokenSigningKeyUsingPUT(body: TokenSigningKey, id: string, options?: any) {
        return TokenSigningKeyEntityApiFp(this.configuration).saveTokenSigningKeyUsingPUT(body, id, options)(this.fetch, this.basePath);
    }

}

/**
 * UserControllerApi - fetch parameter creator
 * @export
 */
export const UserControllerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary acceptInvitation
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationUsingPOST(token: string, options: any = {}): FetchArgs {
            // verify required parameter 'token' is not null or undefined
            if (token === null || token === undefined) {
                throw new RequiredError('token','Required parameter token was null or undefined when calling acceptInvitationUsingPOST.');
            }
            const localVarPath = `/v1/users/accept-invitation`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary changePassword
         * @param {UserPasswordChangeRequestModel} userRequestModel userRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST(userRequestModel: UserPasswordChangeRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'userRequestModel' is not null or undefined
            if (userRequestModel === null || userRequestModel === undefined) {
                throw new RequiredError('userRequestModel','Required parameter userRequestModel was null or undefined when calling changePasswordUsingPOST.');
            }
            const localVarPath = `/v1/users/change-password`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserPasswordChangeRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(userRequestModel || {}) : (userRequestModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary contactSupport
         * @param {ContactRequestModel} contactRequestModel contactRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSupportUsingPOST1(contactRequestModel: ContactRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'contactRequestModel' is not null or undefined
            if (contactRequestModel === null || contactRequestModel === undefined) {
                throw new RequiredError('contactRequestModel','Required parameter contactRequestModel was null or undefined when calling contactSupportUsingPOST1.');
            }
            const localVarPath = `/v1/users/contact-support`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ContactRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(contactRequestModel || {}) : (contactRequestModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteInvitation
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitationUsingDELETE(username: string, options: any = {}): FetchArgs {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling deleteInvitationUsingDELETE.');
            }
            const localVarPath = `/v1/users/invite`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteMyProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyProfileUsingDELETE(options: any = {}): FetchArgs {
            const localVarPath = `/v1/users/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteUserById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserByIdUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserByIdUsingDELETE.');
            }
            const localVarPath = `/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getAllUsers
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getInvitations
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/v1/users/invitations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getMyOrganizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOrganizationsUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/users/my-orgs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getMyProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyProfileUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/v1/users/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getPermissionsPerFeaturesByUsername
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsPerFeaturesByUsernameUsingGET(username: string, options: any = {}): FetchArgs {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling getPermissionsPerFeaturesByUsernameUsingGET.');
            }
            const localVarPath = `/v1/users/permissions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary getUser
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling getUserUsingGET.');
            }
            const localVarPath = `/v1/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary inviteUser
         * @param {UserInviteRequestModel} userInviteRequestModel userInviteRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserUsingPOST(userInviteRequestModel: UserInviteRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'userInviteRequestModel' is not null or undefined
            if (userInviteRequestModel === null || userInviteRequestModel === undefined) {
                throw new RequiredError('userInviteRequestModel','Required parameter userInviteRequestModel was null or undefined when calling inviteUserUsingPOST.');
            }
            const localVarPath = `/v1/users/invite`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserInviteRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(userInviteRequestModel || {}) : (userInviteRequestModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary registerInvitedUser
         * @param {UserAcceptInvitationRequestModel} userAcceptInvitationRequestModel userAcceptInvitationRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerInvitedUserUsingPOST(userAcceptInvitationRequestModel: UserAcceptInvitationRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'userAcceptInvitationRequestModel' is not null or undefined
            if (userAcceptInvitationRequestModel === null || userAcceptInvitationRequestModel === undefined) {
                throw new RequiredError('userAcceptInvitationRequestModel','Required parameter userAcceptInvitationRequestModel was null or undefined when calling registerInvitedUserUsingPOST.');
            }
            const localVarPath = `/v1/users/register-invited-user`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserAcceptInvitationRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(userAcceptInvitationRequestModel || {}) : (userAcceptInvitationRequestModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary removeUserFromOrganization
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromOrganizationUsingDELETE(username: string, options: any = {}): FetchArgs {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling removeUserFromOrganizationUsingDELETE.');
            }
            const localVarPath = `/v1/users/remove-from-organization`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resetPasswordConfirm
         * @param {UserPasswordResetRequestModel} userPasswordResetRequestModel userPasswordResetRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordConfirmUsingPOST(userPasswordResetRequestModel: UserPasswordResetRequestModel, options: any = {}): FetchArgs {
            // verify required parameter 'userPasswordResetRequestModel' is not null or undefined
            if (userPasswordResetRequestModel === null || userPasswordResetRequestModel === undefined) {
                throw new RequiredError('userPasswordResetRequestModel','Required parameter userPasswordResetRequestModel was null or undefined when calling resetPasswordConfirmUsingPOST.');
            }
            const localVarPath = `/v1/users/reset-password-confirm`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserPasswordResetRequestModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(userPasswordResetRequestModel || {}) : (userPasswordResetRequestModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary resetPassword
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPOST(username: string, options: any = {}): FetchArgs {
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling resetPasswordUsingPOST.');
            }
            const localVarPath = `/v1/users/reset-password`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary setToAllUsersOrgsAuthsAndFeaturesIfNotAdded
         * @param {boolean} [setFullPermissions] setFullPermissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setToAllUsersOrgsAuthsAndFeaturesIfNotAddedUsingPOST(setFullPermissions?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/v1/users/orgs-auths`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (setFullPermissions !== undefined) {
                localVarQueryParameter['setFullPermissions'] = setFullPermissions;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updateMyProfile
         * @param {UserDataModel} userDataModel userDataModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyProfileUsingPATCH(userDataModel: UserDataModel, options: any = {}): FetchArgs {
            // verify required parameter 'userDataModel' is not null or undefined
            if (userDataModel === null || userDataModel === undefined) {
                throw new RequiredError('userDataModel','Required parameter userDataModel was null or undefined when calling updateMyProfileUsingPATCH.');
            }
            const localVarPath = `/v1/users/me`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserDataModel" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(userDataModel || {}) : (userDataModel || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary updatePermissionsPerFeaturesByUsername
         * @param {FeaturesPayload} features features
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePermissionsPerFeaturesByUsernameUsingPUT(features: FeaturesPayload, username: string, options: any = {}): FetchArgs {
            // verify required parameter 'features' is not null or undefined
            if (features === null || features === undefined) {
                throw new RequiredError('features','Required parameter features was null or undefined when calling updatePermissionsPerFeaturesByUsernameUsingPUT.');
            }
            // verify required parameter 'username' is not null or undefined
            if (username === null || username === undefined) {
                throw new RequiredError('username','Required parameter username was null or undefined when calling updatePermissionsPerFeaturesByUsernameUsingPUT.');
            }
            const localVarPath = `/v1/users/permissions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"FeaturesPayload" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(features || {}) : (features || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserControllerApi - functional programming interface
 * @export
 */
export const UserControllerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary acceptInvitation
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationUsingPOST(token: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).acceptInvitationUsingPOST(token, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary changePassword
         * @param {UserPasswordChangeRequestModel} userRequestModel userRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST(userRequestModel: UserPasswordChangeRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).changePasswordUsingPOST(userRequestModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary contactSupport
         * @param {ContactRequestModel} contactRequestModel contactRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSupportUsingPOST1(contactRequestModel: ContactRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).contactSupportUsingPOST1(contactRequestModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteInvitation
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitationUsingDELETE(username: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).deleteInvitationUsingDELETE(username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteMyProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyProfileUsingDELETE(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).deleteMyProfileUsingDELETE(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteUserById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserByIdUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).deleteUserByIdUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getAllUsers
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageUserResponseModel> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getAllUsersUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getInvitations
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PageUserInvitation> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getInvitationsUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getMyOrganizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOrganizationsUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserOrganizations> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getMyOrganizationsUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getMyProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyProfileUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserResponseModel> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getMyProfileUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getPermissionsPerFeaturesByUsername
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsPerFeaturesByUsernameUsingGET(username: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Features> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getPermissionsPerFeaturesByUsernameUsingGET(username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary getUser
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserResponseModel> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).getUserUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary inviteUser
         * @param {UserInviteRequestModel} userInviteRequestModel userInviteRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserUsingPOST(userInviteRequestModel: UserInviteRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).inviteUserUsingPOST(userInviteRequestModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary registerInvitedUser
         * @param {UserAcceptInvitationRequestModel} userAcceptInvitationRequestModel userAcceptInvitationRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerInvitedUserUsingPOST(userAcceptInvitationRequestModel: UserAcceptInvitationRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserResponseModel> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).registerInvitedUserUsingPOST(userAcceptInvitationRequestModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary removeUserFromOrganization
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromOrganizationUsingDELETE(username: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).removeUserFromOrganizationUsingDELETE(username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary resetPasswordConfirm
         * @param {UserPasswordResetRequestModel} userPasswordResetRequestModel userPasswordResetRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordConfirmUsingPOST(userPasswordResetRequestModel: UserPasswordResetRequestModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).resetPasswordConfirmUsingPOST(userPasswordResetRequestModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary resetPassword
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPOST(username: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).resetPasswordUsingPOST(username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary setToAllUsersOrgsAuthsAndFeaturesIfNotAdded
         * @param {boolean} [setFullPermissions] setFullPermissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setToAllUsersOrgsAuthsAndFeaturesIfNotAddedUsingPOST(setFullPermissions?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).setToAllUsersOrgsAuthsAndFeaturesIfNotAddedUsingPOST(setFullPermissions, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updateMyProfile
         * @param {UserDataModel} userDataModel userDataModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyProfileUsingPATCH(userDataModel: UserDataModel, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UserResponseModel> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).updateMyProfileUsingPATCH(userDataModel, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary updatePermissionsPerFeaturesByUsername
         * @param {FeaturesPayload} features features
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePermissionsPerFeaturesByUsernameUsingPUT(features: FeaturesPayload, username: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Features> {
            const localVarFetchArgs = UserControllerApiFetchParamCreator(configuration).updatePermissionsPerFeaturesByUsernameUsingPUT(features, username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserControllerApi - factory interface
 * @export
 */
export const UserControllerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary acceptInvitation
         * @param {string} token token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        acceptInvitationUsingPOST(token: string, options?: any) {
            return UserControllerApiFp(configuration).acceptInvitationUsingPOST(token, options)(fetch, basePath);
        },
        /**
         * 
         * @summary changePassword
         * @param {UserPasswordChangeRequestModel} userRequestModel userRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        changePasswordUsingPOST(userRequestModel: UserPasswordChangeRequestModel, options?: any) {
            return UserControllerApiFp(configuration).changePasswordUsingPOST(userRequestModel, options)(fetch, basePath);
        },
        /**
         * 
         * @summary contactSupport
         * @param {ContactRequestModel} contactRequestModel contactRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        contactSupportUsingPOST1(contactRequestModel: ContactRequestModel, options?: any) {
            return UserControllerApiFp(configuration).contactSupportUsingPOST1(contactRequestModel, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteInvitation
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteInvitationUsingDELETE(username: string, options?: any) {
            return UserControllerApiFp(configuration).deleteInvitationUsingDELETE(username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteMyProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMyProfileUsingDELETE(options?: any) {
            return UserControllerApiFp(configuration).deleteMyProfileUsingDELETE(options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteUserById
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserByIdUsingDELETE(id: string, options?: any) {
            return UserControllerApiFp(configuration).deleteUserByIdUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getAllUsers
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllUsersUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return UserControllerApiFp(configuration).getAllUsersUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getInvitations
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getInvitationsUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return UserControllerApiFp(configuration).getInvitationsUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getMyOrganizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyOrganizationsUsingGET(options?: any) {
            return UserControllerApiFp(configuration).getMyOrganizationsUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getMyProfile
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMyProfileUsingGET(options?: any) {
            return UserControllerApiFp(configuration).getMyProfileUsingGET(options)(fetch, basePath);
        },
        /**
         * 
         * @summary getPermissionsPerFeaturesByUsername
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPermissionsPerFeaturesByUsernameUsingGET(username: string, options?: any) {
            return UserControllerApiFp(configuration).getPermissionsPerFeaturesByUsernameUsingGET(username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary getUser
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserUsingGET(id: string, options?: any) {
            return UserControllerApiFp(configuration).getUserUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary inviteUser
         * @param {UserInviteRequestModel} userInviteRequestModel userInviteRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteUserUsingPOST(userInviteRequestModel: UserInviteRequestModel, options?: any) {
            return UserControllerApiFp(configuration).inviteUserUsingPOST(userInviteRequestModel, options)(fetch, basePath);
        },
        /**
         * 
         * @summary registerInvitedUser
         * @param {UserAcceptInvitationRequestModel} userAcceptInvitationRequestModel userAcceptInvitationRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        registerInvitedUserUsingPOST(userAcceptInvitationRequestModel: UserAcceptInvitationRequestModel, options?: any) {
            return UserControllerApiFp(configuration).registerInvitedUserUsingPOST(userAcceptInvitationRequestModel, options)(fetch, basePath);
        },
        /**
         * 
         * @summary removeUserFromOrganization
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        removeUserFromOrganizationUsingDELETE(username: string, options?: any) {
            return UserControllerApiFp(configuration).removeUserFromOrganizationUsingDELETE(username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary resetPasswordConfirm
         * @param {UserPasswordResetRequestModel} userPasswordResetRequestModel userPasswordResetRequestModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordConfirmUsingPOST(userPasswordResetRequestModel: UserPasswordResetRequestModel, options?: any) {
            return UserControllerApiFp(configuration).resetPasswordConfirmUsingPOST(userPasswordResetRequestModel, options)(fetch, basePath);
        },
        /**
         * 
         * @summary resetPassword
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPasswordUsingPOST(username: string, options?: any) {
            return UserControllerApiFp(configuration).resetPasswordUsingPOST(username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary setToAllUsersOrgsAuthsAndFeaturesIfNotAdded
         * @param {boolean} [setFullPermissions] setFullPermissions
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setToAllUsersOrgsAuthsAndFeaturesIfNotAddedUsingPOST(setFullPermissions?: boolean, options?: any) {
            return UserControllerApiFp(configuration).setToAllUsersOrgsAuthsAndFeaturesIfNotAddedUsingPOST(setFullPermissions, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updateMyProfile
         * @param {UserDataModel} userDataModel userDataModel
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateMyProfileUsingPATCH(userDataModel: UserDataModel, options?: any) {
            return UserControllerApiFp(configuration).updateMyProfileUsingPATCH(userDataModel, options)(fetch, basePath);
        },
        /**
         * 
         * @summary updatePermissionsPerFeaturesByUsername
         * @param {FeaturesPayload} features features
         * @param {string} username username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updatePermissionsPerFeaturesByUsernameUsingPUT(features: FeaturesPayload, username: string, options?: any) {
            return UserControllerApiFp(configuration).updatePermissionsPerFeaturesByUsernameUsingPUT(features, username, options)(fetch, basePath);
        },
    };
};

/**
 * UserControllerApi - object-oriented interface
 * @export
 * @class UserControllerApi
 * @extends {BaseAPI}
 */
export class UserControllerApi extends BaseAPI {
    /**
     * 
     * @summary acceptInvitation
     * @param {string} token token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public acceptInvitationUsingPOST(token: string, options?: any) {
        return UserControllerApiFp(this.configuration).acceptInvitationUsingPOST(token, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary changePassword
     * @param {UserPasswordChangeRequestModel} userRequestModel userRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public changePasswordUsingPOST(userRequestModel: UserPasswordChangeRequestModel, options?: any) {
        return UserControllerApiFp(this.configuration).changePasswordUsingPOST(userRequestModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary contactSupport
     * @param {ContactRequestModel} contactRequestModel contactRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public contactSupportUsingPOST1(contactRequestModel: ContactRequestModel, options?: any) {
        return UserControllerApiFp(this.configuration).contactSupportUsingPOST1(contactRequestModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteInvitation
     * @param {string} username username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteInvitationUsingDELETE(username: string, options?: any) {
        return UserControllerApiFp(this.configuration).deleteInvitationUsingDELETE(username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteMyProfile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteMyProfileUsingDELETE(options?: any) {
        return UserControllerApiFp(this.configuration).deleteMyProfileUsingDELETE(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteUserById
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public deleteUserByIdUsingDELETE(id: string, options?: any) {
        return UserControllerApiFp(this.configuration).deleteUserByIdUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getAllUsers
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getAllUsersUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return UserControllerApiFp(this.configuration).getAllUsersUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getInvitations
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getInvitationsUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return UserControllerApiFp(this.configuration).getInvitationsUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getMyOrganizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getMyOrganizationsUsingGET(options?: any) {
        return UserControllerApiFp(this.configuration).getMyOrganizationsUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getMyProfile
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getMyProfileUsingGET(options?: any) {
        return UserControllerApiFp(this.configuration).getMyProfileUsingGET(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getPermissionsPerFeaturesByUsername
     * @param {string} username username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getPermissionsPerFeaturesByUsernameUsingGET(username: string, options?: any) {
        return UserControllerApiFp(this.configuration).getPermissionsPerFeaturesByUsernameUsingGET(username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary getUser
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public getUserUsingGET(id: string, options?: any) {
        return UserControllerApiFp(this.configuration).getUserUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary inviteUser
     * @param {UserInviteRequestModel} userInviteRequestModel userInviteRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public inviteUserUsingPOST(userInviteRequestModel: UserInviteRequestModel, options?: any) {
        return UserControllerApiFp(this.configuration).inviteUserUsingPOST(userInviteRequestModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary registerInvitedUser
     * @param {UserAcceptInvitationRequestModel} userAcceptInvitationRequestModel userAcceptInvitationRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public registerInvitedUserUsingPOST(userAcceptInvitationRequestModel: UserAcceptInvitationRequestModel, options?: any) {
        return UserControllerApiFp(this.configuration).registerInvitedUserUsingPOST(userAcceptInvitationRequestModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary removeUserFromOrganization
     * @param {string} username username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public removeUserFromOrganizationUsingDELETE(username: string, options?: any) {
        return UserControllerApiFp(this.configuration).removeUserFromOrganizationUsingDELETE(username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary resetPasswordConfirm
     * @param {UserPasswordResetRequestModel} userPasswordResetRequestModel userPasswordResetRequestModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public resetPasswordConfirmUsingPOST(userPasswordResetRequestModel: UserPasswordResetRequestModel, options?: any) {
        return UserControllerApiFp(this.configuration).resetPasswordConfirmUsingPOST(userPasswordResetRequestModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary resetPassword
     * @param {string} username username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public resetPasswordUsingPOST(username: string, options?: any) {
        return UserControllerApiFp(this.configuration).resetPasswordUsingPOST(username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary setToAllUsersOrgsAuthsAndFeaturesIfNotAdded
     * @param {boolean} [setFullPermissions] setFullPermissions
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public setToAllUsersOrgsAuthsAndFeaturesIfNotAddedUsingPOST(setFullPermissions?: boolean, options?: any) {
        return UserControllerApiFp(this.configuration).setToAllUsersOrgsAuthsAndFeaturesIfNotAddedUsingPOST(setFullPermissions, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updateMyProfile
     * @param {UserDataModel} userDataModel userDataModel
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updateMyProfileUsingPATCH(userDataModel: UserDataModel, options?: any) {
        return UserControllerApiFp(this.configuration).updateMyProfileUsingPATCH(userDataModel, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary updatePermissionsPerFeaturesByUsername
     * @param {FeaturesPayload} features features
     * @param {string} username username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserControllerApi
     */
    public updatePermissionsPerFeaturesByUsernameUsingPUT(features: FeaturesPayload, username: string, options?: any) {
        return UserControllerApiFp(this.configuration).updatePermissionsPerFeaturesByUsernameUsingPUT(features, username, options)(this.fetch, this.basePath);
    }

}

/**
 * UserEntityApi - fetch parameter creator
 * @export
 */
export const UserEntityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary countAllByOrganizationsContainsUser
         * @param {Array<string>} [organizations] organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAllByOrganizationsContainsUserUsingGET(organizations?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/users/search/countAllByOrganizationsContains`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (organizations) {
                localVarQueryParameter['organizations'] = organizations;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteByUsernameIgnoreCaseUser
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByUsernameIgnoreCaseUserUsingGET(username?: string, options: any = {}): FetchArgs {
            const localVarPath = `/users/search/deleteByUsernameIgnoreCase`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteUser
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserUsingDELETE.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllByOrganizationsContainsUser
         * @param {string} [activeOrgId] activeOrgId
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrganizationsContainsUserUsingGET(activeOrgId?: string, page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/users/search/findAllByOrganizationsContains`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activeOrgId !== undefined) {
                localVarQueryParameter['activeOrgId'] = activeOrgId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllByOrgsAuthsOrgIdInUser
         * @param {Array<string>} [organizations] organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrgsAuthsOrgIdInUserUsingGET(organizations?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/users/search/findAllByOrgsAuthsOrgIdIn`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (organizations) {
                localVarQueryParameter['organizations'] = organizations;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllByUsernameInIgnoreCaseUser
         * @param {Array<string>} [usernames] usernames
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByUsernameInIgnoreCaseUserUsingGET(usernames?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/users/search/findAllByUsernameInIgnoreCase`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (usernames) {
                localVarQueryParameter['usernames'] = usernames;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllUser
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllUsersByOrganizationsContainsUser
         * @param {string} [activeOrgId] activeOrgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUsersByOrganizationsContainsUserUsingGET(activeOrgId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/users/search/findAllUsersByOrganizationsContains`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activeOrgId !== undefined) {
                localVarQueryParameter['activeOrgId'] = activeOrgId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdUser
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdUserUsingGET.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByUsernameIgnoreCaseUser
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUsernameIgnoreCaseUserUsingGET(username?: string, options: any = {}): FetchArgs {
            const localVarPath = `/users/search/findByUsernameIgnoreCase`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUser
         * @param {User} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserUsingPATCH(body: User, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserUsingPATCH.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveUserUsingPATCH.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"User" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUser
         * @param {User} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserUsingPOST(body: User, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserUsingPOST.');
            }
            const localVarPath = `/users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"User" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUser
         * @param {User} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserUsingPUT(body: User, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveUserUsingPUT.');
            }
            const localVarPath = `/users/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"User" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserEntityApi - functional programming interface
 * @export
 */
export const UserEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary countAllByOrganizationsContainsUser
         * @param {Array<string>} [organizations] organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAllByOrganizationsContainsUserUsingGET(organizations?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = UserEntityApiFetchParamCreator(configuration).countAllByOrganizationsContainsUserUsingGET(organizations, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteByUsernameIgnoreCaseUser
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByUsernameIgnoreCaseUserUsingGET(username?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<number> {
            const localVarFetchArgs = UserEntityApiFetchParamCreator(configuration).deleteByUsernameIgnoreCaseUserUsingGET(username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteUser
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserEntityApiFetchParamCreator(configuration).deleteUserUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllByOrganizationsContainsUser
         * @param {string} [activeOrgId] activeOrgId
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrganizationsContainsUserUsingGET(activeOrgId?: string, page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelUser> {
            const localVarFetchArgs = UserEntityApiFetchParamCreator(configuration).findAllByOrganizationsContainsUserUsingGET(activeOrgId, page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllByOrgsAuthsOrgIdInUser
         * @param {Array<string>} [organizations] organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrgsAuthsOrgIdInUserUsingGET(organizations?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelUser> {
            const localVarFetchArgs = UserEntityApiFetchParamCreator(configuration).findAllByOrgsAuthsOrgIdInUserUsingGET(organizations, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllByUsernameInIgnoreCaseUser
         * @param {Array<string>} [usernames] usernames
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByUsernameInIgnoreCaseUserUsingGET(usernames?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelUser> {
            const localVarFetchArgs = UserEntityApiFetchParamCreator(configuration).findAllByUsernameInIgnoreCaseUserUsingGET(usernames, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllUser
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelUser> {
            const localVarFetchArgs = UserEntityApiFetchParamCreator(configuration).findAllUserUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllUsersByOrganizationsContainsUser
         * @param {string} [activeOrgId] activeOrgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUsersByOrganizationsContainsUserUsingGET(activeOrgId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelUser> {
            const localVarFetchArgs = UserEntityApiFetchParamCreator(configuration).findAllUsersByOrganizationsContainsUserUsingGET(activeOrgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByIdUser
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUser> {
            const localVarFetchArgs = UserEntityApiFetchParamCreator(configuration).findByIdUserUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByUsernameIgnoreCaseUser
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUsernameIgnoreCaseUserUsingGET(username?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUser> {
            const localVarFetchArgs = UserEntityApiFetchParamCreator(configuration).findByUsernameIgnoreCaseUserUsingGET(username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUser
         * @param {User} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserUsingPATCH(body: User, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUser> {
            const localVarFetchArgs = UserEntityApiFetchParamCreator(configuration).saveUserUsingPATCH(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUser
         * @param {User} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserUsingPOST(body: User, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUser> {
            const localVarFetchArgs = UserEntityApiFetchParamCreator(configuration).saveUserUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUser
         * @param {User} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserUsingPUT(body: User, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUser> {
            const localVarFetchArgs = UserEntityApiFetchParamCreator(configuration).saveUserUsingPUT(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserEntityApi - factory interface
 * @export
 */
export const UserEntityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary countAllByOrganizationsContainsUser
         * @param {Array<string>} [organizations] organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countAllByOrganizationsContainsUserUsingGET(organizations?: Array<string>, options?: any) {
            return UserEntityApiFp(configuration).countAllByOrganizationsContainsUserUsingGET(organizations, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteByUsernameIgnoreCaseUser
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByUsernameIgnoreCaseUserUsingGET(username?: string, options?: any) {
            return UserEntityApiFp(configuration).deleteByUsernameIgnoreCaseUserUsingGET(username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteUser
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserUsingDELETE(id: string, options?: any) {
            return UserEntityApiFp(configuration).deleteUserUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllByOrganizationsContainsUser
         * @param {string} [activeOrgId] activeOrgId
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrganizationsContainsUserUsingGET(activeOrgId?: string, page?: number, size?: number, sort?: string, options?: any) {
            return UserEntityApiFp(configuration).findAllByOrganizationsContainsUserUsingGET(activeOrgId, page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllByOrgsAuthsOrgIdInUser
         * @param {Array<string>} [organizations] organizations
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrgsAuthsOrgIdInUserUsingGET(organizations?: Array<string>, options?: any) {
            return UserEntityApiFp(configuration).findAllByOrgsAuthsOrgIdInUserUsingGET(organizations, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllByUsernameInIgnoreCaseUser
         * @param {Array<string>} [usernames] usernames
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByUsernameInIgnoreCaseUserUsingGET(usernames?: Array<string>, options?: any) {
            return UserEntityApiFp(configuration).findAllByUsernameInIgnoreCaseUserUsingGET(usernames, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllUser
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return UserEntityApiFp(configuration).findAllUserUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllUsersByOrganizationsContainsUser
         * @param {string} [activeOrgId] activeOrgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUsersByOrganizationsContainsUserUsingGET(activeOrgId?: string, options?: any) {
            return UserEntityApiFp(configuration).findAllUsersByOrganizationsContainsUserUsingGET(activeOrgId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByIdUser
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserUsingGET(id: string, options?: any) {
            return UserEntityApiFp(configuration).findByIdUserUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByUsernameIgnoreCaseUser
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUsernameIgnoreCaseUserUsingGET(username?: string, options?: any) {
            return UserEntityApiFp(configuration).findByUsernameIgnoreCaseUserUsingGET(username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUser
         * @param {User} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserUsingPATCH(body: User, id: string, options?: any) {
            return UserEntityApiFp(configuration).saveUserUsingPATCH(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUser
         * @param {User} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserUsingPOST(body: User, options?: any) {
            return UserEntityApiFp(configuration).saveUserUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUser
         * @param {User} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserUsingPUT(body: User, id: string, options?: any) {
            return UserEntityApiFp(configuration).saveUserUsingPUT(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * UserEntityApi - object-oriented interface
 * @export
 * @class UserEntityApi
 * @extends {BaseAPI}
 */
export class UserEntityApi extends BaseAPI {
    /**
     * 
     * @summary countAllByOrganizationsContainsUser
     * @param {Array<string>} [organizations] organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEntityApi
     */
    public countAllByOrganizationsContainsUserUsingGET(organizations?: Array<string>, options?: any) {
        return UserEntityApiFp(this.configuration).countAllByOrganizationsContainsUserUsingGET(organizations, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteByUsernameIgnoreCaseUser
     * @param {string} [username] username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEntityApi
     */
    public deleteByUsernameIgnoreCaseUserUsingGET(username?: string, options?: any) {
        return UserEntityApiFp(this.configuration).deleteByUsernameIgnoreCaseUserUsingGET(username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteUser
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEntityApi
     */
    public deleteUserUsingDELETE(id: string, options?: any) {
        return UserEntityApiFp(this.configuration).deleteUserUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllByOrganizationsContainsUser
     * @param {string} [activeOrgId] activeOrgId
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEntityApi
     */
    public findAllByOrganizationsContainsUserUsingGET(activeOrgId?: string, page?: number, size?: number, sort?: string, options?: any) {
        return UserEntityApiFp(this.configuration).findAllByOrganizationsContainsUserUsingGET(activeOrgId, page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllByOrgsAuthsOrgIdInUser
     * @param {Array<string>} [organizations] organizations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEntityApi
     */
    public findAllByOrgsAuthsOrgIdInUserUsingGET(organizations?: Array<string>, options?: any) {
        return UserEntityApiFp(this.configuration).findAllByOrgsAuthsOrgIdInUserUsingGET(organizations, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllByUsernameInIgnoreCaseUser
     * @param {Array<string>} [usernames] usernames
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEntityApi
     */
    public findAllByUsernameInIgnoreCaseUserUsingGET(usernames?: Array<string>, options?: any) {
        return UserEntityApiFp(this.configuration).findAllByUsernameInIgnoreCaseUserUsingGET(usernames, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllUser
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEntityApi
     */
    public findAllUserUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return UserEntityApiFp(this.configuration).findAllUserUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllUsersByOrganizationsContainsUser
     * @param {string} [activeOrgId] activeOrgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEntityApi
     */
    public findAllUsersByOrganizationsContainsUserUsingGET(activeOrgId?: string, options?: any) {
        return UserEntityApiFp(this.configuration).findAllUsersByOrganizationsContainsUserUsingGET(activeOrgId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByIdUser
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEntityApi
     */
    public findByIdUserUsingGET(id: string, options?: any) {
        return UserEntityApiFp(this.configuration).findByIdUserUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByUsernameIgnoreCaseUser
     * @param {string} [username] username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEntityApi
     */
    public findByUsernameIgnoreCaseUserUsingGET(username?: string, options?: any) {
        return UserEntityApiFp(this.configuration).findByUsernameIgnoreCaseUserUsingGET(username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUser
     * @param {User} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEntityApi
     */
    public saveUserUsingPATCH(body: User, id: string, options?: any) {
        return UserEntityApiFp(this.configuration).saveUserUsingPATCH(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUser
     * @param {User} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEntityApi
     */
    public saveUserUsingPOST(body: User, options?: any) {
        return UserEntityApiFp(this.configuration).saveUserUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUser
     * @param {User} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserEntityApi
     */
    public saveUserUsingPUT(body: User, id: string, options?: any) {
        return UserEntityApiFp(this.configuration).saveUserUsingPUT(body, id, options)(this.fetch, this.basePath);
    }

}

/**
 * UserInvitationEntityApi - fetch parameter creator
 * @export
 */
export const UserInvitationEntityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAllByOrganizationUserInvitation
         * @param {string} [activeOrgId] activeOrgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByOrganizationUserInvitationUsingGET(activeOrgId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userInvitations/search/deleteAllByOrganization`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activeOrgId !== undefined) {
                localVarQueryParameter['activeOrgId'] = activeOrgId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAllByUpdatedAtIsLessThanEqualAndIsActiveUserInvitation
         * @param {Date} [date] date
         * @param {boolean} [isActive] isActive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUpdatedAtIsLessThanEqualAndIsActiveUserInvitationUsingGET(date?: Date, isActive?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/userInvitations/search/deleteAllByUpdatedAtIsLessThanEqualAndIsActive`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (date !== undefined) {
                localVarQueryParameter['date'] = (date as any).toISOString();
            }

            if (isActive !== undefined) {
                localVarQueryParameter['isActive'] = isActive;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteUserInvitation
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserInvitationUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserInvitationUsingDELETE.');
            }
            const localVarPath = `/userInvitations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllByOrganizationUserInvitation
         * @param {string} [activeOrgId] activeOrgId
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrganizationUserInvitationUsingGET(activeOrgId?: string, page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userInvitations/search/findAllByOrganization`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activeOrgId !== undefined) {
                localVarQueryParameter['activeOrgId'] = activeOrgId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllByUsernameIgnoreCaseUserInvitation
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByUsernameIgnoreCaseUserInvitationUsingGET(username?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userInvitations/search/findAllByUsernameIgnoreCase`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllUserInvitation
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserInvitationUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userInvitations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdUserInvitation
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserInvitationUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdUserInvitationUsingGET.');
            }
            const localVarPath = `/userInvitations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByOrganizationUserInvitation
         * @param {string} [organization] organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByOrganizationUserInvitationUsingGET(organization?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userInvitations/search/findByOrganization`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (organization !== undefined) {
                localVarQueryParameter['organization'] = organization;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByUsernameIgnoreCaseAndOrganizationUserInvitation
         * @param {string} [activeOrgId] activeOrgId
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUsernameIgnoreCaseAndOrganizationUserInvitationUsingGET(activeOrgId?: string, username?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userInvitations/search/findByUsernameIgnoreCaseAndOrganization`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activeOrgId !== undefined) {
                localVarQueryParameter['activeOrgId'] = activeOrgId;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUserInvitation
         * @param {UserInvitation} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInvitationUsingPATCH(body: UserInvitation, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserInvitationUsingPATCH.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveUserInvitationUsingPATCH.');
            }
            const localVarPath = `/userInvitations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserInvitation" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUserInvitation
         * @param {UserInvitation} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInvitationUsingPOST(body: UserInvitation, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserInvitationUsingPOST.');
            }
            const localVarPath = `/userInvitations`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserInvitation" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUserInvitation
         * @param {UserInvitation} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInvitationUsingPUT(body: UserInvitation, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserInvitationUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveUserInvitationUsingPUT.');
            }
            const localVarPath = `/userInvitations/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserInvitation" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInvitationEntityApi - functional programming interface
 * @export
 */
export const UserInvitationEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAllByOrganizationUserInvitation
         * @param {string} [activeOrgId] activeOrgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByOrganizationUserInvitationUsingGET(activeOrgId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserInvitationEntityApiFetchParamCreator(configuration).deleteAllByOrganizationUserInvitationUsingGET(activeOrgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAllByUpdatedAtIsLessThanEqualAndIsActiveUserInvitation
         * @param {Date} [date] date
         * @param {boolean} [isActive] isActive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUpdatedAtIsLessThanEqualAndIsActiveUserInvitationUsingGET(date?: Date, isActive?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserInvitationEntityApiFetchParamCreator(configuration).deleteAllByUpdatedAtIsLessThanEqualAndIsActiveUserInvitationUsingGET(date, isActive, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteUserInvitation
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserInvitationUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserInvitationEntityApiFetchParamCreator(configuration).deleteUserInvitationUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllByOrganizationUserInvitation
         * @param {string} [activeOrgId] activeOrgId
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrganizationUserInvitationUsingGET(activeOrgId?: string, page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelUserInvitation> {
            const localVarFetchArgs = UserInvitationEntityApiFetchParamCreator(configuration).findAllByOrganizationUserInvitationUsingGET(activeOrgId, page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllByUsernameIgnoreCaseUserInvitation
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByUsernameIgnoreCaseUserInvitationUsingGET(username?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelUserInvitation> {
            const localVarFetchArgs = UserInvitationEntityApiFetchParamCreator(configuration).findAllByUsernameIgnoreCaseUserInvitationUsingGET(username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllUserInvitation
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserInvitationUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelUserInvitation> {
            const localVarFetchArgs = UserInvitationEntityApiFetchParamCreator(configuration).findAllUserInvitationUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByIdUserInvitation
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserInvitationUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserInvitation> {
            const localVarFetchArgs = UserInvitationEntityApiFetchParamCreator(configuration).findByIdUserInvitationUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByOrganizationUserInvitation
         * @param {string} [organization] organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByOrganizationUserInvitationUsingGET(organization?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelUserInvitation> {
            const localVarFetchArgs = UserInvitationEntityApiFetchParamCreator(configuration).findByOrganizationUserInvitationUsingGET(organization, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByUsernameIgnoreCaseAndOrganizationUserInvitation
         * @param {string} [activeOrgId] activeOrgId
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUsernameIgnoreCaseAndOrganizationUserInvitationUsingGET(activeOrgId?: string, username?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserInvitation> {
            const localVarFetchArgs = UserInvitationEntityApiFetchParamCreator(configuration).findByUsernameIgnoreCaseAndOrganizationUserInvitationUsingGET(activeOrgId, username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUserInvitation
         * @param {UserInvitation} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInvitationUsingPATCH(body: UserInvitation, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserInvitation> {
            const localVarFetchArgs = UserInvitationEntityApiFetchParamCreator(configuration).saveUserInvitationUsingPATCH(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUserInvitation
         * @param {UserInvitation} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInvitationUsingPOST(body: UserInvitation, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserInvitation> {
            const localVarFetchArgs = UserInvitationEntityApiFetchParamCreator(configuration).saveUserInvitationUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUserInvitation
         * @param {UserInvitation} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInvitationUsingPUT(body: UserInvitation, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserInvitation> {
            const localVarFetchArgs = UserInvitationEntityApiFetchParamCreator(configuration).saveUserInvitationUsingPUT(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserInvitationEntityApi - factory interface
 * @export
 */
export const UserInvitationEntityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteAllByOrganizationUserInvitation
         * @param {string} [activeOrgId] activeOrgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByOrganizationUserInvitationUsingGET(activeOrgId?: string, options?: any) {
            return UserInvitationEntityApiFp(configuration).deleteAllByOrganizationUserInvitationUsingGET(activeOrgId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAllByUpdatedAtIsLessThanEqualAndIsActiveUserInvitation
         * @param {Date} [date] date
         * @param {boolean} [isActive] isActive
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUpdatedAtIsLessThanEqualAndIsActiveUserInvitationUsingGET(date?: Date, isActive?: boolean, options?: any) {
            return UserInvitationEntityApiFp(configuration).deleteAllByUpdatedAtIsLessThanEqualAndIsActiveUserInvitationUsingGET(date, isActive, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteUserInvitation
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserInvitationUsingDELETE(id: string, options?: any) {
            return UserInvitationEntityApiFp(configuration).deleteUserInvitationUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllByOrganizationUserInvitation
         * @param {string} [activeOrgId] activeOrgId
         * @param {number} [page] 
         * @param {number} [size] 
         * @param {string} [sort] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByOrganizationUserInvitationUsingGET(activeOrgId?: string, page?: number, size?: number, sort?: string, options?: any) {
            return UserInvitationEntityApiFp(configuration).findAllByOrganizationUserInvitationUsingGET(activeOrgId, page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllByUsernameIgnoreCaseUserInvitation
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByUsernameIgnoreCaseUserInvitationUsingGET(username?: string, options?: any) {
            return UserInvitationEntityApiFp(configuration).findAllByUsernameIgnoreCaseUserInvitationUsingGET(username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllUserInvitation
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserInvitationUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return UserInvitationEntityApiFp(configuration).findAllUserInvitationUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByIdUserInvitation
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserInvitationUsingGET(id: string, options?: any) {
            return UserInvitationEntityApiFp(configuration).findByIdUserInvitationUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByOrganizationUserInvitation
         * @param {string} [organization] organization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByOrganizationUserInvitationUsingGET(organization?: string, options?: any) {
            return UserInvitationEntityApiFp(configuration).findByOrganizationUserInvitationUsingGET(organization, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByUsernameIgnoreCaseAndOrganizationUserInvitation
         * @param {string} [activeOrgId] activeOrgId
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUsernameIgnoreCaseAndOrganizationUserInvitationUsingGET(activeOrgId?: string, username?: string, options?: any) {
            return UserInvitationEntityApiFp(configuration).findByUsernameIgnoreCaseAndOrganizationUserInvitationUsingGET(activeOrgId, username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUserInvitation
         * @param {UserInvitation} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInvitationUsingPATCH(body: UserInvitation, id: string, options?: any) {
            return UserInvitationEntityApiFp(configuration).saveUserInvitationUsingPATCH(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUserInvitation
         * @param {UserInvitation} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInvitationUsingPOST(body: UserInvitation, options?: any) {
            return UserInvitationEntityApiFp(configuration).saveUserInvitationUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUserInvitation
         * @param {UserInvitation} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInvitationUsingPUT(body: UserInvitation, id: string, options?: any) {
            return UserInvitationEntityApiFp(configuration).saveUserInvitationUsingPUT(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * UserInvitationEntityApi - object-oriented interface
 * @export
 * @class UserInvitationEntityApi
 * @extends {BaseAPI}
 */
export class UserInvitationEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteAllByOrganizationUserInvitation
     * @param {string} [activeOrgId] activeOrgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationEntityApi
     */
    public deleteAllByOrganizationUserInvitationUsingGET(activeOrgId?: string, options?: any) {
        return UserInvitationEntityApiFp(this.configuration).deleteAllByOrganizationUserInvitationUsingGET(activeOrgId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAllByUpdatedAtIsLessThanEqualAndIsActiveUserInvitation
     * @param {Date} [date] date
     * @param {boolean} [isActive] isActive
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationEntityApi
     */
    public deleteAllByUpdatedAtIsLessThanEqualAndIsActiveUserInvitationUsingGET(date?: Date, isActive?: boolean, options?: any) {
        return UserInvitationEntityApiFp(this.configuration).deleteAllByUpdatedAtIsLessThanEqualAndIsActiveUserInvitationUsingGET(date, isActive, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteUserInvitation
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationEntityApi
     */
    public deleteUserInvitationUsingDELETE(id: string, options?: any) {
        return UserInvitationEntityApiFp(this.configuration).deleteUserInvitationUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllByOrganizationUserInvitation
     * @param {string} [activeOrgId] activeOrgId
     * @param {number} [page] 
     * @param {number} [size] 
     * @param {string} [sort] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationEntityApi
     */
    public findAllByOrganizationUserInvitationUsingGET(activeOrgId?: string, page?: number, size?: number, sort?: string, options?: any) {
        return UserInvitationEntityApiFp(this.configuration).findAllByOrganizationUserInvitationUsingGET(activeOrgId, page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllByUsernameIgnoreCaseUserInvitation
     * @param {string} [username] username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationEntityApi
     */
    public findAllByUsernameIgnoreCaseUserInvitationUsingGET(username?: string, options?: any) {
        return UserInvitationEntityApiFp(this.configuration).findAllByUsernameIgnoreCaseUserInvitationUsingGET(username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllUserInvitation
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationEntityApi
     */
    public findAllUserInvitationUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return UserInvitationEntityApiFp(this.configuration).findAllUserInvitationUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByIdUserInvitation
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationEntityApi
     */
    public findByIdUserInvitationUsingGET(id: string, options?: any) {
        return UserInvitationEntityApiFp(this.configuration).findByIdUserInvitationUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByOrganizationUserInvitation
     * @param {string} [organization] organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationEntityApi
     */
    public findByOrganizationUserInvitationUsingGET(organization?: string, options?: any) {
        return UserInvitationEntityApiFp(this.configuration).findByOrganizationUserInvitationUsingGET(organization, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByUsernameIgnoreCaseAndOrganizationUserInvitation
     * @param {string} [activeOrgId] activeOrgId
     * @param {string} [username] username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationEntityApi
     */
    public findByUsernameIgnoreCaseAndOrganizationUserInvitationUsingGET(activeOrgId?: string, username?: string, options?: any) {
        return UserInvitationEntityApiFp(this.configuration).findByUsernameIgnoreCaseAndOrganizationUserInvitationUsingGET(activeOrgId, username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUserInvitation
     * @param {UserInvitation} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationEntityApi
     */
    public saveUserInvitationUsingPATCH(body: UserInvitation, id: string, options?: any) {
        return UserInvitationEntityApiFp(this.configuration).saveUserInvitationUsingPATCH(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUserInvitation
     * @param {UserInvitation} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationEntityApi
     */
    public saveUserInvitationUsingPOST(body: UserInvitation, options?: any) {
        return UserInvitationEntityApiFp(this.configuration).saveUserInvitationUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUserInvitation
     * @param {UserInvitation} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInvitationEntityApi
     */
    public saveUserInvitationUsingPUT(body: UserInvitation, id: string, options?: any) {
        return UserInvitationEntityApiFp(this.configuration).saveUserInvitationUsingPUT(body, id, options)(this.fetch, this.basePath);
    }

}

/**
 * UserInviteTokenEntityApi - fetch parameter creator
 * @export
 */
export const UserInviteTokenEntityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAllByExpiryDateIsLessThanEqualUserInviteToken
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByExpiryDateIsLessThanEqualUserInviteTokenUsingGET(now?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/userInviteTokens/search/deleteAllByExpiryDateIsLessThanEqual`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (now !== undefined) {
                localVarQueryParameter['now'] = (now as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAllByOrganizationUserInviteToken
         * @param {string} [activeOrgId] activeOrgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByOrganizationUserInviteTokenUsingGET(activeOrgId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userInviteTokens/search/deleteAllByOrganization`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activeOrgId !== undefined) {
                localVarQueryParameter['activeOrgId'] = activeOrgId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAllByUsernameIgnoreCaseAndOrganizationUserInviteToken
         * @param {string} [activeOrgId] activeOrgId
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUsernameIgnoreCaseAndOrganizationUserInviteTokenUsingGET(activeOrgId?: string, username?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userInviteTokens/search/deleteAllByUsernameIgnoreCaseAndOrganization`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activeOrgId !== undefined) {
                localVarQueryParameter['activeOrgId'] = activeOrgId;
            }

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAllByUsernameIgnoreCaseUserInviteToken
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUsernameIgnoreCaseUserInviteTokenUsingGET(username?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userInviteTokens/search/deleteAllByUsernameIgnoreCase`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteUserInviteToken
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserInviteTokenUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserInviteTokenUsingDELETE.');
            }
            const localVarPath = `/userInviteTokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllUserInviteToken
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserInviteTokenUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userInviteTokens`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdUserInviteToken
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserInviteTokenUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdUserInviteTokenUsingGET.');
            }
            const localVarPath = `/userInviteTokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByTokenAndExpiryDateIsGreaterThanEqualUserInviteToken
         * @param {Date} [now] now
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTokenAndExpiryDateIsGreaterThanEqualUserInviteTokenUsingGET(now?: Date, token?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userInviteTokens/search/findByTokenAndExpiryDateIsGreaterThanEqual`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (now !== undefined) {
                localVarQueryParameter['now'] = (now as any).toISOString();
            }

            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUserInviteToken
         * @param {UserInviteToken} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInviteTokenUsingPATCH(body: UserInviteToken, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserInviteTokenUsingPATCH.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveUserInviteTokenUsingPATCH.');
            }
            const localVarPath = `/userInviteTokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserInviteToken" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUserInviteToken
         * @param {UserInviteToken} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInviteTokenUsingPOST(body: UserInviteToken, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserInviteTokenUsingPOST.');
            }
            const localVarPath = `/userInviteTokens`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserInviteToken" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUserInviteToken
         * @param {UserInviteToken} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInviteTokenUsingPUT(body: UserInviteToken, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserInviteTokenUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveUserInviteTokenUsingPUT.');
            }
            const localVarPath = `/userInviteTokens/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserInviteToken" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInviteTokenEntityApi - functional programming interface
 * @export
 */
export const UserInviteTokenEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAllByExpiryDateIsLessThanEqualUserInviteToken
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByExpiryDateIsLessThanEqualUserInviteTokenUsingGET(now?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserInviteTokenEntityApiFetchParamCreator(configuration).deleteAllByExpiryDateIsLessThanEqualUserInviteTokenUsingGET(now, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAllByOrganizationUserInviteToken
         * @param {string} [activeOrgId] activeOrgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByOrganizationUserInviteTokenUsingGET(activeOrgId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserInviteTokenEntityApiFetchParamCreator(configuration).deleteAllByOrganizationUserInviteTokenUsingGET(activeOrgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAllByUsernameIgnoreCaseAndOrganizationUserInviteToken
         * @param {string} [activeOrgId] activeOrgId
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUsernameIgnoreCaseAndOrganizationUserInviteTokenUsingGET(activeOrgId?: string, username?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserInviteTokenEntityApiFetchParamCreator(configuration).deleteAllByUsernameIgnoreCaseAndOrganizationUserInviteTokenUsingGET(activeOrgId, username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAllByUsernameIgnoreCaseUserInviteToken
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUsernameIgnoreCaseUserInviteTokenUsingGET(username?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserInviteTokenEntityApiFetchParamCreator(configuration).deleteAllByUsernameIgnoreCaseUserInviteTokenUsingGET(username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteUserInviteToken
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserInviteTokenUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserInviteTokenEntityApiFetchParamCreator(configuration).deleteUserInviteTokenUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllUserInviteToken
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserInviteTokenUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelUserInviteToken> {
            const localVarFetchArgs = UserInviteTokenEntityApiFetchParamCreator(configuration).findAllUserInviteTokenUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByIdUserInviteToken
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserInviteTokenUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserInviteToken> {
            const localVarFetchArgs = UserInviteTokenEntityApiFetchParamCreator(configuration).findByIdUserInviteTokenUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByTokenAndExpiryDateIsGreaterThanEqualUserInviteToken
         * @param {Date} [now] now
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTokenAndExpiryDateIsGreaterThanEqualUserInviteTokenUsingGET(now?: Date, token?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserInviteToken> {
            const localVarFetchArgs = UserInviteTokenEntityApiFetchParamCreator(configuration).findByTokenAndExpiryDateIsGreaterThanEqualUserInviteTokenUsingGET(now, token, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUserInviteToken
         * @param {UserInviteToken} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInviteTokenUsingPATCH(body: UserInviteToken, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserInviteToken> {
            const localVarFetchArgs = UserInviteTokenEntityApiFetchParamCreator(configuration).saveUserInviteTokenUsingPATCH(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUserInviteToken
         * @param {UserInviteToken} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInviteTokenUsingPOST(body: UserInviteToken, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserInviteToken> {
            const localVarFetchArgs = UserInviteTokenEntityApiFetchParamCreator(configuration).saveUserInviteTokenUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUserInviteToken
         * @param {UserInviteToken} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInviteTokenUsingPUT(body: UserInviteToken, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserInviteToken> {
            const localVarFetchArgs = UserInviteTokenEntityApiFetchParamCreator(configuration).saveUserInviteTokenUsingPUT(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserInviteTokenEntityApi - factory interface
 * @export
 */
export const UserInviteTokenEntityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteAllByExpiryDateIsLessThanEqualUserInviteToken
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByExpiryDateIsLessThanEqualUserInviteTokenUsingGET(now?: Date, options?: any) {
            return UserInviteTokenEntityApiFp(configuration).deleteAllByExpiryDateIsLessThanEqualUserInviteTokenUsingGET(now, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAllByOrganizationUserInviteToken
         * @param {string} [activeOrgId] activeOrgId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByOrganizationUserInviteTokenUsingGET(activeOrgId?: string, options?: any) {
            return UserInviteTokenEntityApiFp(configuration).deleteAllByOrganizationUserInviteTokenUsingGET(activeOrgId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAllByUsernameIgnoreCaseAndOrganizationUserInviteToken
         * @param {string} [activeOrgId] activeOrgId
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUsernameIgnoreCaseAndOrganizationUserInviteTokenUsingGET(activeOrgId?: string, username?: string, options?: any) {
            return UserInviteTokenEntityApiFp(configuration).deleteAllByUsernameIgnoreCaseAndOrganizationUserInviteTokenUsingGET(activeOrgId, username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAllByUsernameIgnoreCaseUserInviteToken
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUsernameIgnoreCaseUserInviteTokenUsingGET(username?: string, options?: any) {
            return UserInviteTokenEntityApiFp(configuration).deleteAllByUsernameIgnoreCaseUserInviteTokenUsingGET(username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteUserInviteToken
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserInviteTokenUsingDELETE(id: string, options?: any) {
            return UserInviteTokenEntityApiFp(configuration).deleteUserInviteTokenUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllUserInviteToken
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserInviteTokenUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return UserInviteTokenEntityApiFp(configuration).findAllUserInviteTokenUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByIdUserInviteToken
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserInviteTokenUsingGET(id: string, options?: any) {
            return UserInviteTokenEntityApiFp(configuration).findByIdUserInviteTokenUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByTokenAndExpiryDateIsGreaterThanEqualUserInviteToken
         * @param {Date} [now] now
         * @param {string} [token] token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByTokenAndExpiryDateIsGreaterThanEqualUserInviteTokenUsingGET(now?: Date, token?: string, options?: any) {
            return UserInviteTokenEntityApiFp(configuration).findByTokenAndExpiryDateIsGreaterThanEqualUserInviteTokenUsingGET(now, token, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUserInviteToken
         * @param {UserInviteToken} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInviteTokenUsingPATCH(body: UserInviteToken, id: string, options?: any) {
            return UserInviteTokenEntityApiFp(configuration).saveUserInviteTokenUsingPATCH(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUserInviteToken
         * @param {UserInviteToken} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInviteTokenUsingPOST(body: UserInviteToken, options?: any) {
            return UserInviteTokenEntityApiFp(configuration).saveUserInviteTokenUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUserInviteToken
         * @param {UserInviteToken} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserInviteTokenUsingPUT(body: UserInviteToken, id: string, options?: any) {
            return UserInviteTokenEntityApiFp(configuration).saveUserInviteTokenUsingPUT(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * UserInviteTokenEntityApi - object-oriented interface
 * @export
 * @class UserInviteTokenEntityApi
 * @extends {BaseAPI}
 */
export class UserInviteTokenEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteAllByExpiryDateIsLessThanEqualUserInviteToken
     * @param {Date} [now] now
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInviteTokenEntityApi
     */
    public deleteAllByExpiryDateIsLessThanEqualUserInviteTokenUsingGET(now?: Date, options?: any) {
        return UserInviteTokenEntityApiFp(this.configuration).deleteAllByExpiryDateIsLessThanEqualUserInviteTokenUsingGET(now, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAllByOrganizationUserInviteToken
     * @param {string} [activeOrgId] activeOrgId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInviteTokenEntityApi
     */
    public deleteAllByOrganizationUserInviteTokenUsingGET(activeOrgId?: string, options?: any) {
        return UserInviteTokenEntityApiFp(this.configuration).deleteAllByOrganizationUserInviteTokenUsingGET(activeOrgId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAllByUsernameIgnoreCaseAndOrganizationUserInviteToken
     * @param {string} [activeOrgId] activeOrgId
     * @param {string} [username] username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInviteTokenEntityApi
     */
    public deleteAllByUsernameIgnoreCaseAndOrganizationUserInviteTokenUsingGET(activeOrgId?: string, username?: string, options?: any) {
        return UserInviteTokenEntityApiFp(this.configuration).deleteAllByUsernameIgnoreCaseAndOrganizationUserInviteTokenUsingGET(activeOrgId, username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAllByUsernameIgnoreCaseUserInviteToken
     * @param {string} [username] username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInviteTokenEntityApi
     */
    public deleteAllByUsernameIgnoreCaseUserInviteTokenUsingGET(username?: string, options?: any) {
        return UserInviteTokenEntityApiFp(this.configuration).deleteAllByUsernameIgnoreCaseUserInviteTokenUsingGET(username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteUserInviteToken
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInviteTokenEntityApi
     */
    public deleteUserInviteTokenUsingDELETE(id: string, options?: any) {
        return UserInviteTokenEntityApiFp(this.configuration).deleteUserInviteTokenUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllUserInviteToken
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInviteTokenEntityApi
     */
    public findAllUserInviteTokenUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return UserInviteTokenEntityApiFp(this.configuration).findAllUserInviteTokenUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByIdUserInviteToken
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInviteTokenEntityApi
     */
    public findByIdUserInviteTokenUsingGET(id: string, options?: any) {
        return UserInviteTokenEntityApiFp(this.configuration).findByIdUserInviteTokenUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByTokenAndExpiryDateIsGreaterThanEqualUserInviteToken
     * @param {Date} [now] now
     * @param {string} [token] token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInviteTokenEntityApi
     */
    public findByTokenAndExpiryDateIsGreaterThanEqualUserInviteTokenUsingGET(now?: Date, token?: string, options?: any) {
        return UserInviteTokenEntityApiFp(this.configuration).findByTokenAndExpiryDateIsGreaterThanEqualUserInviteTokenUsingGET(now, token, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUserInviteToken
     * @param {UserInviteToken} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInviteTokenEntityApi
     */
    public saveUserInviteTokenUsingPATCH(body: UserInviteToken, id: string, options?: any) {
        return UserInviteTokenEntityApiFp(this.configuration).saveUserInviteTokenUsingPATCH(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUserInviteToken
     * @param {UserInviteToken} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInviteTokenEntityApi
     */
    public saveUserInviteTokenUsingPOST(body: UserInviteToken, options?: any) {
        return UserInviteTokenEntityApiFp(this.configuration).saveUserInviteTokenUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUserInviteToken
     * @param {UserInviteToken} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInviteTokenEntityApi
     */
    public saveUserInviteTokenUsingPUT(body: UserInviteToken, id: string, options?: any) {
        return UserInviteTokenEntityApiFp(this.configuration).saveUserInviteTokenUsingPUT(body, id, options)(this.fetch, this.basePath);
    }

}

/**
 * UserLoginSessionEntityApi - fetch parameter creator
 * @export
 */
export const UserLoginSessionEntityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAllByExpiryDateIsLessThanEqualUserLoginSession
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByExpiryDateIsLessThanEqualUserLoginSessionUsingGET(now?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/userLoginSessions/search/deleteAllByExpiryDateIsLessThanEqual`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (now !== undefined) {
                localVarQueryParameter['now'] = (now as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteAllByUserUsernameIgnoreCaseUserLoginSession
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUserUsernameIgnoreCaseUserLoginSessionUsingGET(username?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userLoginSessions/search/deleteAllByUserUsernameIgnoreCase`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteUserLoginSession
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserLoginSessionUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserLoginSessionUsingDELETE.');
            }
            const localVarPath = `/userLoginSessions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllUserLoginSession
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserLoginSessionUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userLoginSessions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdAndExpiryDateIsGreaterThanEqualUserLoginSession
         * @param {string} [id] id
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdAndExpiryDateIsGreaterThanEqualUserLoginSessionUsingGET(id?: string, now?: Date, options: any = {}): FetchArgs {
            const localVarPath = `/userLoginSessions/search/findByIdAndExpiryDateIsGreaterThanEqual`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (now !== undefined) {
                localVarQueryParameter['now'] = (now as any).toISOString();
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdUserLoginSession
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserLoginSessionUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdUserLoginSessionUsingGET.');
            }
            const localVarPath = `/userLoginSessions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByUserIdUserLoginSession
         * @param {string} [userId] userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUserIdUserLoginSessionUsingGET(userId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userLoginSessions/search/findByUserId`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUserLoginSession
         * @param {UserLoginSession} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserLoginSessionUsingPATCH(body: UserLoginSession, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserLoginSessionUsingPATCH.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveUserLoginSessionUsingPATCH.');
            }
            const localVarPath = `/userLoginSessions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserLoginSession" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUserLoginSession
         * @param {UserLoginSession} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserLoginSessionUsingPOST(body: UserLoginSession, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserLoginSessionUsingPOST.');
            }
            const localVarPath = `/userLoginSessions`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserLoginSession" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUserLoginSession
         * @param {UserLoginSession} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserLoginSessionUsingPUT(body: UserLoginSession, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserLoginSessionUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveUserLoginSessionUsingPUT.');
            }
            const localVarPath = `/userLoginSessions/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserLoginSession" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserLoginSessionEntityApi - functional programming interface
 * @export
 */
export const UserLoginSessionEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteAllByExpiryDateIsLessThanEqualUserLoginSession
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByExpiryDateIsLessThanEqualUserLoginSessionUsingGET(now?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserLoginSessionEntityApiFetchParamCreator(configuration).deleteAllByExpiryDateIsLessThanEqualUserLoginSessionUsingGET(now, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteAllByUserUsernameIgnoreCaseUserLoginSession
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUserUsernameIgnoreCaseUserLoginSessionUsingGET(username?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserLoginSessionEntityApiFetchParamCreator(configuration).deleteAllByUserUsernameIgnoreCaseUserLoginSessionUsingGET(username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteUserLoginSession
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserLoginSessionUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserLoginSessionEntityApiFetchParamCreator(configuration).deleteUserLoginSessionUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllUserLoginSession
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserLoginSessionUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelUserLoginSession> {
            const localVarFetchArgs = UserLoginSessionEntityApiFetchParamCreator(configuration).findAllUserLoginSessionUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByIdAndExpiryDateIsGreaterThanEqualUserLoginSession
         * @param {string} [id] id
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdAndExpiryDateIsGreaterThanEqualUserLoginSessionUsingGET(id?: string, now?: Date, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserLoginSession> {
            const localVarFetchArgs = UserLoginSessionEntityApiFetchParamCreator(configuration).findByIdAndExpiryDateIsGreaterThanEqualUserLoginSessionUsingGET(id, now, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByIdUserLoginSession
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserLoginSessionUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserLoginSession> {
            const localVarFetchArgs = UserLoginSessionEntityApiFetchParamCreator(configuration).findByIdUserLoginSessionUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByUserIdUserLoginSession
         * @param {string} [userId] userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUserIdUserLoginSessionUsingGET(userId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserLoginSession> {
            const localVarFetchArgs = UserLoginSessionEntityApiFetchParamCreator(configuration).findByUserIdUserLoginSessionUsingGET(userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUserLoginSession
         * @param {UserLoginSession} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserLoginSessionUsingPATCH(body: UserLoginSession, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserLoginSession> {
            const localVarFetchArgs = UserLoginSessionEntityApiFetchParamCreator(configuration).saveUserLoginSessionUsingPATCH(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUserLoginSession
         * @param {UserLoginSession} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserLoginSessionUsingPOST(body: UserLoginSession, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserLoginSession> {
            const localVarFetchArgs = UserLoginSessionEntityApiFetchParamCreator(configuration).saveUserLoginSessionUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUserLoginSession
         * @param {UserLoginSession} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserLoginSessionUsingPUT(body: UserLoginSession, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserLoginSession> {
            const localVarFetchArgs = UserLoginSessionEntityApiFetchParamCreator(configuration).saveUserLoginSessionUsingPUT(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserLoginSessionEntityApi - factory interface
 * @export
 */
export const UserLoginSessionEntityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteAllByExpiryDateIsLessThanEqualUserLoginSession
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByExpiryDateIsLessThanEqualUserLoginSessionUsingGET(now?: Date, options?: any) {
            return UserLoginSessionEntityApiFp(configuration).deleteAllByExpiryDateIsLessThanEqualUserLoginSessionUsingGET(now, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteAllByUserUsernameIgnoreCaseUserLoginSession
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAllByUserUsernameIgnoreCaseUserLoginSessionUsingGET(username?: string, options?: any) {
            return UserLoginSessionEntityApiFp(configuration).deleteAllByUserUsernameIgnoreCaseUserLoginSessionUsingGET(username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteUserLoginSession
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserLoginSessionUsingDELETE(id: string, options?: any) {
            return UserLoginSessionEntityApiFp(configuration).deleteUserLoginSessionUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllUserLoginSession
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserLoginSessionUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return UserLoginSessionEntityApiFp(configuration).findAllUserLoginSessionUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByIdAndExpiryDateIsGreaterThanEqualUserLoginSession
         * @param {string} [id] id
         * @param {Date} [now] now
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdAndExpiryDateIsGreaterThanEqualUserLoginSessionUsingGET(id?: string, now?: Date, options?: any) {
            return UserLoginSessionEntityApiFp(configuration).findByIdAndExpiryDateIsGreaterThanEqualUserLoginSessionUsingGET(id, now, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByIdUserLoginSession
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserLoginSessionUsingGET(id: string, options?: any) {
            return UserLoginSessionEntityApiFp(configuration).findByIdUserLoginSessionUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByUserIdUserLoginSession
         * @param {string} [userId] userId
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUserIdUserLoginSessionUsingGET(userId?: string, options?: any) {
            return UserLoginSessionEntityApiFp(configuration).findByUserIdUserLoginSessionUsingGET(userId, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUserLoginSession
         * @param {UserLoginSession} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserLoginSessionUsingPATCH(body: UserLoginSession, id: string, options?: any) {
            return UserLoginSessionEntityApiFp(configuration).saveUserLoginSessionUsingPATCH(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUserLoginSession
         * @param {UserLoginSession} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserLoginSessionUsingPOST(body: UserLoginSession, options?: any) {
            return UserLoginSessionEntityApiFp(configuration).saveUserLoginSessionUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUserLoginSession
         * @param {UserLoginSession} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserLoginSessionUsingPUT(body: UserLoginSession, id: string, options?: any) {
            return UserLoginSessionEntityApiFp(configuration).saveUserLoginSessionUsingPUT(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * UserLoginSessionEntityApi - object-oriented interface
 * @export
 * @class UserLoginSessionEntityApi
 * @extends {BaseAPI}
 */
export class UserLoginSessionEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteAllByExpiryDateIsLessThanEqualUserLoginSession
     * @param {Date} [now] now
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLoginSessionEntityApi
     */
    public deleteAllByExpiryDateIsLessThanEqualUserLoginSessionUsingGET(now?: Date, options?: any) {
        return UserLoginSessionEntityApiFp(this.configuration).deleteAllByExpiryDateIsLessThanEqualUserLoginSessionUsingGET(now, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteAllByUserUsernameIgnoreCaseUserLoginSession
     * @param {string} [username] username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLoginSessionEntityApi
     */
    public deleteAllByUserUsernameIgnoreCaseUserLoginSessionUsingGET(username?: string, options?: any) {
        return UserLoginSessionEntityApiFp(this.configuration).deleteAllByUserUsernameIgnoreCaseUserLoginSessionUsingGET(username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteUserLoginSession
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLoginSessionEntityApi
     */
    public deleteUserLoginSessionUsingDELETE(id: string, options?: any) {
        return UserLoginSessionEntityApiFp(this.configuration).deleteUserLoginSessionUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllUserLoginSession
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLoginSessionEntityApi
     */
    public findAllUserLoginSessionUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return UserLoginSessionEntityApiFp(this.configuration).findAllUserLoginSessionUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByIdAndExpiryDateIsGreaterThanEqualUserLoginSession
     * @param {string} [id] id
     * @param {Date} [now] now
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLoginSessionEntityApi
     */
    public findByIdAndExpiryDateIsGreaterThanEqualUserLoginSessionUsingGET(id?: string, now?: Date, options?: any) {
        return UserLoginSessionEntityApiFp(this.configuration).findByIdAndExpiryDateIsGreaterThanEqualUserLoginSessionUsingGET(id, now, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByIdUserLoginSession
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLoginSessionEntityApi
     */
    public findByIdUserLoginSessionUsingGET(id: string, options?: any) {
        return UserLoginSessionEntityApiFp(this.configuration).findByIdUserLoginSessionUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByUserIdUserLoginSession
     * @param {string} [userId] userId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLoginSessionEntityApi
     */
    public findByUserIdUserLoginSessionUsingGET(userId?: string, options?: any) {
        return UserLoginSessionEntityApiFp(this.configuration).findByUserIdUserLoginSessionUsingGET(userId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUserLoginSession
     * @param {UserLoginSession} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLoginSessionEntityApi
     */
    public saveUserLoginSessionUsingPATCH(body: UserLoginSession, id: string, options?: any) {
        return UserLoginSessionEntityApiFp(this.configuration).saveUserLoginSessionUsingPATCH(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUserLoginSession
     * @param {UserLoginSession} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLoginSessionEntityApi
     */
    public saveUserLoginSessionUsingPOST(body: UserLoginSession, options?: any) {
        return UserLoginSessionEntityApiFp(this.configuration).saveUserLoginSessionUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUserLoginSession
     * @param {UserLoginSession} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserLoginSessionEntityApi
     */
    public saveUserLoginSessionUsingPUT(body: UserLoginSession, id: string, options?: any) {
        return UserLoginSessionEntityApiFp(this.configuration).saveUserLoginSessionUsingPUT(body, id, options)(this.fetch, this.basePath);
    }

}

/**
 * UserRequestsCounterEntityApi - fetch parameter creator
 * @export
 */
export const UserRequestsCounterEntityApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteByUsernameIgnoreCaseUserRequestsCounter
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByUsernameIgnoreCaseUserRequestsCounterUsingGET(username?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userRequestsCounters/search/deleteByUsernameIgnoreCase`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary deleteUserRequestsCounter
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRequestsCounterUsingDELETE(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling deleteUserRequestsCounterUsingDELETE.');
            }
            const localVarPath = `/userRequestsCounters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllByUsernameInIgnoreCaseUserRequestsCounter
         * @param {Array<string>} [usernames] usernames
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByUsernameInIgnoreCaseUserRequestsCounterUsingGET(usernames?: Array<string>, options: any = {}): FetchArgs {
            const localVarPath = `/userRequestsCounters/search/findAllByUsernameInIgnoreCase`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (usernames) {
                localVarQueryParameter['usernames'] = usernames;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findAllUserRequestsCounter
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserRequestsCounterUsingGET(page?: number, size?: number, sort?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userRequestsCounters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByIdUserRequestsCounter
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserRequestsCounterUsingGET(id: string, options: any = {}): FetchArgs {
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling findByIdUserRequestsCounterUsingGET.');
            }
            const localVarPath = `/userRequestsCounters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary findByUsernameIgnoreCaseUserRequestsCounter
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUsernameIgnoreCaseUserRequestsCounterUsingGET(username?: string, options: any = {}): FetchArgs {
            const localVarPath = `/userRequestsCounters/search/findByUsernameIgnoreCase`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (username !== undefined) {
                localVarQueryParameter['username'] = username;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUserRequestsCounter
         * @param {UserRequestsCounter} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserRequestsCounterUsingPATCH(body: UserRequestsCounter, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserRequestsCounterUsingPATCH.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveUserRequestsCounterUsingPATCH.');
            }
            const localVarPath = `/userRequestsCounters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserRequestsCounter" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUserRequestsCounter
         * @param {UserRequestsCounter} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserRequestsCounterUsingPOST(body: UserRequestsCounter, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserRequestsCounterUsingPOST.');
            }
            const localVarPath = `/userRequestsCounters`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserRequestsCounter" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary saveUserRequestsCounter
         * @param {UserRequestsCounter} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserRequestsCounterUsingPUT(body: UserRequestsCounter, id: string, options: any = {}): FetchArgs {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError('body','Required parameter body was null or undefined when calling saveUserRequestsCounterUsingPUT.');
            }
            // verify required parameter 'id' is not null or undefined
            if (id === null || id === undefined) {
                throw new RequiredError('id','Required parameter id was null or undefined when calling saveUserRequestsCounterUsingPUT.');
            }
            const localVarPath = `/userRequestsCounters/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UserRequestsCounter" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserRequestsCounterEntityApi - functional programming interface
 * @export
 */
export const UserRequestsCounterEntityApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary deleteByUsernameIgnoreCaseUserRequestsCounter
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByUsernameIgnoreCaseUserRequestsCounterUsingGET(username?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserRequestsCounterEntityApiFetchParamCreator(configuration).deleteByUsernameIgnoreCaseUserRequestsCounterUsingGET(username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary deleteUserRequestsCounter
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRequestsCounterUsingDELETE(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UserRequestsCounterEntityApiFetchParamCreator(configuration).deleteUserRequestsCounterUsingDELETE(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllByUsernameInIgnoreCaseUserRequestsCounter
         * @param {Array<string>} [usernames] usernames
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByUsernameInIgnoreCaseUserRequestsCounterUsingGET(usernames?: Array<string>, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelUserRequestsCounter> {
            const localVarFetchArgs = UserRequestsCounterEntityApiFetchParamCreator(configuration).findAllByUsernameInIgnoreCaseUserRequestsCounterUsingGET(usernames, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findAllUserRequestsCounter
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserRequestsCounterUsingGET(page?: number, size?: number, sort?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CollectionModelUserRequestsCounter> {
            const localVarFetchArgs = UserRequestsCounterEntityApiFetchParamCreator(configuration).findAllUserRequestsCounterUsingGET(page, size, sort, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByIdUserRequestsCounter
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserRequestsCounterUsingGET(id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserRequestsCounter> {
            const localVarFetchArgs = UserRequestsCounterEntityApiFetchParamCreator(configuration).findByIdUserRequestsCounterUsingGET(id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary findByUsernameIgnoreCaseUserRequestsCounter
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUsernameIgnoreCaseUserRequestsCounterUsingGET(username?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserRequestsCounter> {
            const localVarFetchArgs = UserRequestsCounterEntityApiFetchParamCreator(configuration).findByUsernameIgnoreCaseUserRequestsCounterUsingGET(username, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUserRequestsCounter
         * @param {UserRequestsCounter} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserRequestsCounterUsingPATCH(body: UserRequestsCounter, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserRequestsCounter> {
            const localVarFetchArgs = UserRequestsCounterEntityApiFetchParamCreator(configuration).saveUserRequestsCounterUsingPATCH(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUserRequestsCounter
         * @param {UserRequestsCounter} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserRequestsCounterUsingPOST(body: UserRequestsCounter, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserRequestsCounter> {
            const localVarFetchArgs = UserRequestsCounterEntityApiFetchParamCreator(configuration).saveUserRequestsCounterUsingPOST(body, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary saveUserRequestsCounter
         * @param {UserRequestsCounter} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserRequestsCounterUsingPUT(body: UserRequestsCounter, id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<EntityModelUserRequestsCounter> {
            const localVarFetchArgs = UserRequestsCounterEntityApiFetchParamCreator(configuration).saveUserRequestsCounterUsingPUT(body, id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UserRequestsCounterEntityApi - factory interface
 * @export
 */
export const UserRequestsCounterEntityApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary deleteByUsernameIgnoreCaseUserRequestsCounter
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByUsernameIgnoreCaseUserRequestsCounterUsingGET(username?: string, options?: any) {
            return UserRequestsCounterEntityApiFp(configuration).deleteByUsernameIgnoreCaseUserRequestsCounterUsingGET(username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary deleteUserRequestsCounter
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserRequestsCounterUsingDELETE(id: string, options?: any) {
            return UserRequestsCounterEntityApiFp(configuration).deleteUserRequestsCounterUsingDELETE(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllByUsernameInIgnoreCaseUserRequestsCounter
         * @param {Array<string>} [usernames] usernames
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllByUsernameInIgnoreCaseUserRequestsCounterUsingGET(usernames?: Array<string>, options?: any) {
            return UserRequestsCounterEntityApiFp(configuration).findAllByUsernameInIgnoreCaseUserRequestsCounterUsingGET(usernames, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findAllUserRequestsCounter
         * @param {number} [page] page
         * @param {number} [size] size
         * @param {string} [sort] sort
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findAllUserRequestsCounterUsingGET(page?: number, size?: number, sort?: string, options?: any) {
            return UserRequestsCounterEntityApiFp(configuration).findAllUserRequestsCounterUsingGET(page, size, sort, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByIdUserRequestsCounter
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByIdUserRequestsCounterUsingGET(id: string, options?: any) {
            return UserRequestsCounterEntityApiFp(configuration).findByIdUserRequestsCounterUsingGET(id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary findByUsernameIgnoreCaseUserRequestsCounter
         * @param {string} [username] username
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        findByUsernameIgnoreCaseUserRequestsCounterUsingGET(username?: string, options?: any) {
            return UserRequestsCounterEntityApiFp(configuration).findByUsernameIgnoreCaseUserRequestsCounterUsingGET(username, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUserRequestsCounter
         * @param {UserRequestsCounter} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserRequestsCounterUsingPATCH(body: UserRequestsCounter, id: string, options?: any) {
            return UserRequestsCounterEntityApiFp(configuration).saveUserRequestsCounterUsingPATCH(body, id, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUserRequestsCounter
         * @param {UserRequestsCounter} body body
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserRequestsCounterUsingPOST(body: UserRequestsCounter, options?: any) {
            return UserRequestsCounterEntityApiFp(configuration).saveUserRequestsCounterUsingPOST(body, options)(fetch, basePath);
        },
        /**
         * 
         * @summary saveUserRequestsCounter
         * @param {UserRequestsCounter} body body
         * @param {string} id id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveUserRequestsCounterUsingPUT(body: UserRequestsCounter, id: string, options?: any) {
            return UserRequestsCounterEntityApiFp(configuration).saveUserRequestsCounterUsingPUT(body, id, options)(fetch, basePath);
        },
    };
};

/**
 * UserRequestsCounterEntityApi - object-oriented interface
 * @export
 * @class UserRequestsCounterEntityApi
 * @extends {BaseAPI}
 */
export class UserRequestsCounterEntityApi extends BaseAPI {
    /**
     * 
     * @summary deleteByUsernameIgnoreCaseUserRequestsCounter
     * @param {string} [username] username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRequestsCounterEntityApi
     */
    public deleteByUsernameIgnoreCaseUserRequestsCounterUsingGET(username?: string, options?: any) {
        return UserRequestsCounterEntityApiFp(this.configuration).deleteByUsernameIgnoreCaseUserRequestsCounterUsingGET(username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary deleteUserRequestsCounter
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRequestsCounterEntityApi
     */
    public deleteUserRequestsCounterUsingDELETE(id: string, options?: any) {
        return UserRequestsCounterEntityApiFp(this.configuration).deleteUserRequestsCounterUsingDELETE(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllByUsernameInIgnoreCaseUserRequestsCounter
     * @param {Array<string>} [usernames] usernames
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRequestsCounterEntityApi
     */
    public findAllByUsernameInIgnoreCaseUserRequestsCounterUsingGET(usernames?: Array<string>, options?: any) {
        return UserRequestsCounterEntityApiFp(this.configuration).findAllByUsernameInIgnoreCaseUserRequestsCounterUsingGET(usernames, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findAllUserRequestsCounter
     * @param {number} [page] page
     * @param {number} [size] size
     * @param {string} [sort] sort
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRequestsCounterEntityApi
     */
    public findAllUserRequestsCounterUsingGET(page?: number, size?: number, sort?: string, options?: any) {
        return UserRequestsCounterEntityApiFp(this.configuration).findAllUserRequestsCounterUsingGET(page, size, sort, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByIdUserRequestsCounter
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRequestsCounterEntityApi
     */
    public findByIdUserRequestsCounterUsingGET(id: string, options?: any) {
        return UserRequestsCounterEntityApiFp(this.configuration).findByIdUserRequestsCounterUsingGET(id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary findByUsernameIgnoreCaseUserRequestsCounter
     * @param {string} [username] username
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRequestsCounterEntityApi
     */
    public findByUsernameIgnoreCaseUserRequestsCounterUsingGET(username?: string, options?: any) {
        return UserRequestsCounterEntityApiFp(this.configuration).findByUsernameIgnoreCaseUserRequestsCounterUsingGET(username, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUserRequestsCounter
     * @param {UserRequestsCounter} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRequestsCounterEntityApi
     */
    public saveUserRequestsCounterUsingPATCH(body: UserRequestsCounter, id: string, options?: any) {
        return UserRequestsCounterEntityApiFp(this.configuration).saveUserRequestsCounterUsingPATCH(body, id, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUserRequestsCounter
     * @param {UserRequestsCounter} body body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRequestsCounterEntityApi
     */
    public saveUserRequestsCounterUsingPOST(body: UserRequestsCounter, options?: any) {
        return UserRequestsCounterEntityApiFp(this.configuration).saveUserRequestsCounterUsingPOST(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary saveUserRequestsCounter
     * @param {UserRequestsCounter} body body
     * @param {string} id id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserRequestsCounterEntityApi
     */
    public saveUserRequestsCounterUsingPUT(body: UserRequestsCounter, id: string, options?: any) {
        return UserRequestsCounterEntityApiFp(this.configuration).saveUserRequestsCounterUsingPUT(body, id, options)(this.fetch, this.basePath);
    }

}

/**
 * WebMvcLinksHandlerApi - fetch parameter creator
 * @export
 */
export const WebMvcLinksHandlerApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksUsingGET(options: any = {}): FetchArgs {
            const localVarPath = `/actuator`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WebMvcLinksHandlerApi - functional programming interface
 * @export
 */
export const WebMvcLinksHandlerApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksUsingGET(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: { [key: string]: Link; }; }> {
            const localVarFetchArgs = WebMvcLinksHandlerApiFetchParamCreator(configuration).linksUsingGET(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * WebMvcLinksHandlerApi - factory interface
 * @export
 */
export const WebMvcLinksHandlerApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @summary links
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linksUsingGET(options?: any) {
            return WebMvcLinksHandlerApiFp(configuration).linksUsingGET(options)(fetch, basePath);
        },
    };
};

/**
 * WebMvcLinksHandlerApi - object-oriented interface
 * @export
 * @class WebMvcLinksHandlerApi
 * @extends {BaseAPI}
 */
export class WebMvcLinksHandlerApi extends BaseAPI {
    /**
     * 
     * @summary links
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebMvcLinksHandlerApi
     */
    public linksUsingGET(options?: any) {
        return WebMvcLinksHandlerApiFp(this.configuration).linksUsingGET(options)(this.fetch, this.basePath);
    }

}

